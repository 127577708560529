import cx from 'classnames';
import { FC } from 'react';

import { Switch, sprinkles } from 'components/ds';

import * as styles from './styles.css';

type Props = { title: string; isOn?: boolean; onChange?: () => void };

// This component is used to display a section divider with a title
// Can also have a switch to toggle section on and off
export const ConfigSubSectionTitle: FC<Props> = ({ title, onChange, isOn }) => {
  return (
    <div className={styles.subSectionTitle}>
      {onChange ? (
        <Switch className={sprinkles({ marginRight: 'sp1' })} onChange={onChange} switchOn={isOn} />
      ) : null}
      {title}
    </div>
  );
};

// This component is just a wrapper to apply border and spacing to sub section
export const ConfigSubSectionWrapper: FC = ({ children }) => {
  return <div className={styles.subSectionWrapper}>{children}</div>;
};

type SwitchSubSectionProps = {
  label: string;
  switchOn: boolean;
  // Adds border between children
  splitChildren?: boolean;

  onChange: (newValue: boolean) => void;
};

// This component is used to add a sub section that is toggled on/off by a switch
// If there are children spacing and alignment is applied
export const ConfigSubSectionWithSwitch: FC<SwitchSubSectionProps> = ({
  label,
  switchOn,
  onChange,
  children,
  splitChildren,
}) => {
  return (
    <div className={sprinkles({ width: 'fill' })}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Switch onChange={onChange} switchOn={switchOn} />
        <div className={sprinkles({ color: 'contentPrimary', heading: 'h4' })}>{label}</div>
      </div>
      {switchOn && children ? (
        <div
          className={cx(styles.switchSubSection, {
            [styles.switchSubSectionDividers]: splitChildren,
          })}>
          {children}
        </div>
      ) : null}
    </div>
  );
};
