import { Quill } from '@explo-tech/react-quill';

import { ReduxState } from 'reducers/rootReducer';
import { store } from 'store/store';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';

const BlockEmbed = Quill.import('blots/embed');

class EmailImage extends BlockEmbed {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(value: any) {
    const node = super.create();
    if (value.explo_variable) {
      node.setAttribute('explo_variable', value.explo_variable);

      const state: ReduxState = store.getState();
      const { variables } = state.dashboardData;
      node.setAttribute('src', replaceVariablesInString(value.explo_variable, variables));
    } else {
      node.setAttribute('src', value.src);
    }
    node.setAttribute('width', value.width);
    return node;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static value(node: any) {
    const values: Record<string, string> = {
      src: node.getAttribute('src'),
      width: node.getAttribute('width'),
    };

    if (node.getAttribute('explo_variable')) {
      values['explo_variable'] = node.getAttribute('explo_variable');
    }

    return values;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static formats(node: any) {
    // We still need to report unregistered embed formats
    const format: { height?: string; width?: string } = {};
    if (node.hasAttribute('height')) {
      format.height = node.getAttribute('height');
    }
    if (node.hasAttribute('width')) {
      format.width = node.getAttribute('width');
    }
    return format;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format(name: any, value: any) {
    // Handle unregistered embed formats
    if (name === 'height' || name === 'width') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }
}
EmailImage.blotName = 'emailImage';
EmailImage.tagName = 'img';

export default EmailImage;
