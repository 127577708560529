import produce from 'immer';
import { FC } from 'react';

import { Select } from 'components/ds';
import { DateGroupToggleConfig } from 'types/dashboardTypes';
import { TrendGroupingOptions } from 'types/dateRangeTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';

type Props = {
  config: DateGroupToggleConfig;

  updateToggleConfig: (config: DateGroupToggleConfig) => void;
};

export const DateGroupDefaultValuesConfig: FC<Props> = ({ config, updateToggleConfig }) => {
  const groupingOptions = getDateGroupSwitchOptions(config).map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const currDefault = config.defaultGroupingOption ?? TrendGroupingOptions.MONTHLY;

  return (
    <Select
      onChange={(value) => {
        const newConfig = produce(config, (draft) => {
          draft.defaultGroupingOption = value as TrendGroupingOptions;
        });
        updateToggleConfig(newConfig);
      }}
      placeholder="Select Default Option"
      selectedValue={currDefault}
      values={groupingOptions}
    />
  );
};
