import { Tooltip } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { sprinkles, Label } from 'components/ds';
import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { Aggregation, ChartColumnInfo } from 'constants/types';
import { showCustomToast } from 'shared/sharedToasts';
import { AggregationType, OldConfigurableColInfo } from 'types/columnTypes';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { PeriodRangeTypes } from 'types/dateRangeTypes';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { sortBy } from 'utils/standard';

import { DroppedAggregationColumn } from './DroppedAggregationColumn';
import { DroppedBucketColumn } from './DroppedBucketColumn';
import { DroppedColumn } from './DroppedColumn';
import { DroppedPeriodColumn } from './DroppedPeriodColumn';
import { EmptyDroppableSection } from './EmptyDroppableSection';

const useStyles = makeStyles((theme: Theme) => ({
  colIsDragging: {
    border: `1px dashed ${theme.palette.ds.blue} !important`,
  },
  invalidColumnType: {
    backgroundColor: `${theme.palette.ds.lightRed} !important`,
    '& .bp3-button,.bp3-input,.bp3-icon': {
      backgroundColor: `${theme.palette.ds.lightRed} !important`,
    },
  },
  droppedPeriodInvalidColumnPopoverBackground: {
    backgroundColor: `${theme.palette.ds.lightRed} !important`,
  },
  tooltipPopover: {
    fontSize: theme.spacing(3),
    '& .bp3-popover-arrow': { display: 'none' },
    '& .bp3-popover-content': { padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px` },
  },
  tooltip: {
    '& .bp3-popover-target': { width: '100%' },
  },
}));

type Props = {
  canSelectSmartGrouping?: boolean;
  columns: OldConfigurableColInfo[];
  maxCols?: number;
  onColOptionChanged?: (
    option: { id: string; name: string; formula?: string; variableId?: string },
    name?: string,
    aggType?: AggregationType,
  ) => void;
  onColAdded: (newCol: DatasetColumn, oldColName?: string, oldColAggType?: AggregationType) => void;
  onRemoveCol: (col: ChartColumnInfo, aggType?: AggregationType) => void;
  /** If hiddenColumns and onToggleHideCol are provided, renders an eye icon button that lets the user toggle visibility of a column */
  hiddenColumnNames?: Set<string>;
  onToggleHideCol?: (col: ChartColumnInfo, aggType?: AggregationType) => void;
  disableEdits?: boolean;
  allowedTypes?: string[];
  dashboardElements?: DashboardElement[];
  /** Enables variable injection */
  dashboardParams?: Record<string, DashboardParam>;
  includeFirst?: boolean;
  isCollapsibleListAgg?: boolean;
  isBarChartGroupingColumn?: boolean;
  isKpiTrendColumn?: boolean;
  periodRange?: PeriodRangeTypes;
  schema: DatasetSchema;
  required?: boolean;
  label?: string;
};

export default function DroppableColumnSection({
  canSelectSmartGrouping,
  columns,
  maxCols,
  onColOptionChanged,
  hiddenColumnNames,
  onToggleHideCol,
  onRemoveCol,
  onColAdded,
  disableEdits,
  allowedTypes,
  dashboardElements,
  dashboardParams,
  includeFirst,
  isCollapsibleListAgg,
  isBarChartGroupingColumn,
  isKpiTrendColumn,
  periodRange,
  schema,
  required,
  label,
}: Props) {
  const classes = useStyles();

  const onDrop = (column: DatasetColumn) => {
    if (allowedTypes && allowedTypes.indexOf(column.type) < 0) {
      const message = `Only columns of the follow type are allowed for that configuration: ${allowedTypes.join(
        ', ',
      )}`;
      return showCustomToast(message);
    }
    onColAdded(column);
  };

  const [{ isOver, item }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: (monitor.getItem() as ConfigColumnItem)?.data as DatasetColumn,
    }),
    drop: (item: ConfigColumnItem) => onDrop(item.data),
  });

  const isDragging = !!item;
  const replacesColumnOnDrop = maxCols === 1 && isDragging;

  const isInvalidColumn =
    allowedTypes !== undefined && item?.type !== undefined && !allowedTypes?.includes(item.type);
  const invalidColumnWarning = isInvalidColumn
    ? `Only ${allowedTypes.join(', ').toLowerCase()} fields allowed.`
    : undefined;

  // When there is an empty formula, don't want to render EmptyDroppableSection
  const isEmptyFormula = columns.find(
    (col) => col.agg?.id === Aggregation.FORMULA && col.agg?.formula === '',
  );
  const renderEmptySection =
    (maxCols === undefined || columns.length < maxCols) && isEmptyFormula === undefined;

  const isTableConfigDroppedCol = maxCols !== undefined && maxCols > 1;

  const schemaOptions = useMemo(
    () =>
      sortBy(
        allowedTypes ? schema.filter((col) => allowedTypes.includes(col.type)) : schema,
        'name',
      ),
    [allowedTypes, schema],
  );

  const replacesColumnDraggingClass = cx({
    [classes.colIsDragging]: replacesColumnOnDrop,
    [classes.invalidColumnType]: !renderEmptySection && invalidColumnWarning,
  });

  const renderColumn = (colConfig: OldConfigurableColInfo) => {
    const agg = colConfig.agg;
    const column = colConfig.column;
    if (isBarChartGroupingColumn) {
      return (
        <DroppedBucketColumn
          column={colConfig}
          draggingClass={replacesColumnDraggingClass}
          onColAdded={onColAdded}
          onColOptionChanged={(option) => onColOptionChanged?.(option, column.name, agg)}
          onRemoveCol={() => onRemoveCol(column, agg)}
          schema={schemaOptions}
        />
      );
    } else if (isKpiTrendColumn) {
      return (
        <DroppedPeriodColumn
          column={colConfig}
          disableEdits={!!disableEdits}
          draggingClass={replacesColumnDraggingClass}
          draggingPopoverClass={
            invalidColumnWarning ? classes.droppedPeriodInvalidColumnPopoverBackground : undefined
          }
          onColAdded={onColAdded}
          onColOptionChanged={(option) => onColOptionChanged?.(option, column.name)}
          onRemoveCol={() => onRemoveCol(column)}
          periodRange={periodRange}
          schema={schemaOptions}
        />
      );
    } else if (isTableConfigDroppedCol) {
      return (
        <DroppedColumn
          columnName={getColDisplayText(colConfig)}
          icon="cross"
          isHidden={!!hiddenColumnNames?.has(column.name || '')}
          onIconClick={() => onRemoveCol(column, agg)}
          onToggleHide={onToggleHideCol ? () => onToggleHideCol(column, agg) : undefined}
        />
      );
    } else {
      return (
        <DroppedAggregationColumn
          canSelectSmartGrouping={canSelectSmartGrouping}
          column={colConfig}
          dashboardElements={dashboardElements}
          dashboardParams={dashboardParams}
          disableEdits={disableEdits}
          draggingClass={replacesColumnDraggingClass}
          includeFirst={includeFirst}
          isCollapsibleListAgg={isCollapsibleListAgg}
          onColAdded={onColAdded}
          onColOptionChanged={
            onColOptionChanged
              ? (option) => onColOptionChanged(option, column.name, agg)
              : undefined
          }
          onRemoveCol={() => onRemoveCol(column, agg)}
          schema={schemaOptions}
        />
      );
    }
  };

  return (
    <div
      className={sprinkles({
        color: 'gray6',
        padding: 'sp.5',
        margin: 'sp.5',
        marginBottom: !renderEmptySection ? 'sp0' : undefined,
      })}
      ref={drop}>
      {label ? <Label htmlFor="">{label}</Label> : null}
      {columns.map((col, i) => {
        return (
          <Tooltip
            usePortal
            className={classes.tooltip}
            content={invalidColumnWarning}
            isOpen={!renderEmptySection && isOver && invalidColumnWarning !== undefined}
            key={`droppable-column-${i}`}
            popoverClassName={classes.tooltipPopover}
            position="top">
            {renderColumn(col)}
          </Tooltip>
        );
      })}
      {renderEmptySection ? (
        <Tooltip
          usePortal
          className={classes.tooltip}
          content={invalidColumnWarning}
          isOpen={isOver && invalidColumnWarning !== undefined}
          popoverClassName={classes.tooltipPopover}
          position="top">
          <EmptyDroppableSection
            draggingClass={cx({
              [classes.invalidColumnType]: invalidColumnWarning,
            })}
            isValidDrop={!isDragging && !isInvalidColumn ? undefined : !isInvalidColumn}
            onItemSelect={(column) => onDrop(column)}
            required={columns.length === 0 ? required : false}
            // filter out columns that aren't able to be put in this field
            schema={allowedTypes ? schema.filter((col) => allowedTypes.includes(col.type)) : schema}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}
