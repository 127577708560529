import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { BarVisualizationDirection } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { setBarChartOptions } from 'reportBuilderContent/reducers/reportEditingReducer';

import { ToggleButton } from './ToggleButton';

type Props = { visualizationDirection?: BarVisualizationDirection };

export const DirectionSection: FC<Props> = ({ visualizationDirection }) => {
  const dispatch = useDispatch();

  const renderButton = (direction: BarVisualizationDirection) => {
    const isVertical = direction === BarVisualizationDirection.Vertical;

    return (
      <ToggleButton
        icon={isVertical ? 'arrow-up' : 'arrow-right'}
        onClick={() => dispatch(setBarChartOptions({ direction }))}
        // If its undefined, default to vertical
        selected={visualizationDirection === direction || (!visualizationDirection && isVertical)}>
        {isVertical ? 'Vertical' : 'Horizontal'}
      </ToggleButton>
    );
  };

  return (
    <DataPanelSection>
      <DataPanelSubHeader title="Direction" />
      <div
        className={sprinkles({
          paddingX: 'sp2',
          width: 'fill',
          flexItems: 'alignCenter',
          gap: 'sp.25',
        })}>
        {renderButton(BarVisualizationDirection.Vertical)}
        {renderButton(BarVisualizationDirection.Horizontal)}
      </div>
    </DataPanelSection>
  );
};
