import { produce } from 'immer';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  deleteCustomFont,
  fetchCustomFonts,
  uploadCustomFontFile,
} from 'actions/customFontsActions';
import { saveFontConfigActions } from 'actions/styleConfigActions';
import {
  IconButton,
  sprinkles,
  Button,
  Spinner,
  Label,
  Input,
  Select,
  Menu,
  MenuItem,
  MenuLabel,
} from 'components/ds';
import { getOrphanFonts } from 'globalStyles/fontConfigUtils';
import { FontFamilyConfig, GlobalFontConfig } from 'globalStyles/types/FontConfig';
import { ReduxState } from 'reducers/rootReducer';
import { getOrDefault, isError, isLoading } from 'remotedata';
import * as RD from 'remotedata';

import {
  SELECT_OPTIONS,
  TextWeightSelect,
} from '../GlobalCustomStylesPage/TextConfigSection/TextWeightSelect';

export const SettingsCustomFontsSection: FC = () => {
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [draftFontConfig, setDraftFontConfig] = useState<GlobalFontConfig | undefined>(undefined);

  const { fonts, fontConfig } = useSelector(
    (state: ReduxState) => ({
      fonts: state.customFonts.customFonts,
      fontConfig: state.dashboardStyles.fontConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (RD.isIdle(fonts)) dispatch(fetchCustomFonts());
  }, [fonts, dispatch]);

  useEffect(() => {
    if (RD.isSuccess(fontConfig)) setDraftFontConfig(fontConfig.data);
  }, [fontConfig]);

  const fontOptions = useMemo(() => {
    const fontData = getOrDefault(fonts, []);
    return getOrphanFonts(fontData, draftFontConfig || []).map((font) => ({
      label: font,
      value: font,
    }));
  }, [fonts, draftFontConfig]);

  const fontConfigErrors = useMemo(
    () =>
      draftFontConfig?.flatMap((config) => [
        ...(config.fontFamily.trim() === '' ? ['Font Family Name is empty'] : []),
        ...config.fontFaces.flatMap((face) => [
          ...(face.src.trim() === '' ? ['Missing Font Name'] : []),
          ...(face.fontWeight ? [] : ['Missing Font Weight']),
        ]),
      ]) || [],
    [draftFontConfig],
  );

  const handleFile = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      dispatch(
        uploadCustomFontFile({ postData: { file: fileReader.result, fileName: files[0].name } }),
      );
    });
    fileReader.readAsDataURL(files[0]);
  };

  const saveFontConfig = () =>
    draftFontConfig &&
    dispatch(saveFontConfigActions.actionFn({ postData: { font_config: draftFontConfig } }));

  const updateFontConfig = (callback: (draft: GlobalFontConfig) => void) => {
    const updatedFontConfig = produce(draftFontConfig || [], callback);
    setDraftFontConfig(updatedFontConfig);
  };

  const updateFontFamilyConfig = (
    familyId: string,
    callback: (draft: FontFamilyConfig) => void,
  ) => {
    const updatedFontConfig = produce(draftFontConfig || [], (draft) => {
      const fontFamilyConfig = draft.find((config) => config.id === familyId);
      if (fontFamilyConfig) callback(fontFamilyConfig);
    });
    setDraftFontConfig(updatedFontConfig);
  };

  const deleteFont = (font: string) => {
    updateFontConfig((draft) => {
      for (const fontFamilyConfig of draft) {
        const index = fontFamilyConfig.fontFaces.findIndex((config) => config.src === font);
        if (index >= 0) fontFamilyConfig.fontFaces.splice(index, 1);
      }
    });
    dispatch(deleteCustomFont({ postData: { fileName: font } }));
  };

  const createFontFamily = () =>
    updateFontConfig((draft) => {
      // Font family names must start with a letter and cannot contain special characters (like dashes - in uuids)
      const id = uuidv4().replace(/[^a-zA-Z]/g, '');
      draft.push({ id, fontFamily: '', fontFaces: [] });
    });

  const updateFontFamily = (fontFamily: string, newFontFamily: string) =>
    updateFontFamilyConfig(fontFamily, (draft) => {
      draft.fontFamily = newFontFamily;
    });

  const deleteFontFamily = (familyId: string) =>
    updateFontConfig((draft) => {
      const index = draft.findIndex((config) => config.id === familyId);
      if (index >= 0) draft.splice(index, 1);
    });

  const deleteFontFace = (familyId: string, src: string) =>
    updateFontFamilyConfig(familyId, (draft) => {
      const index = draft.fontFaces.findIndex((config) => config.src === src);
      if (index >= 0) draft.fontFaces.splice(index, 1);
    });

  const updateFontSrc = (familyId: string, src: string, newSrc: string) =>
    updateFontFamilyConfig(familyId, (draft) => {
      const fontFaceConfig = draft.fontFaces.find((config) => config.src === src);
      if (fontFaceConfig) fontFaceConfig.src = newSrc;
      else draft.fontFaces.push({ src: newSrc });
    });

  const updateFontWeight = (familyId: string, src: string, fontWeight: number | undefined) =>
    updateFontFamilyConfig(familyId, (draft) => {
      const fontFaceConfig = draft.fontFaces.find((config) => config.src === src);
      if (fontFaceConfig) fontFaceConfig.fontWeight = fontWeight;
      else draft.fontFaces.push({ src, fontWeight });
    });

  const renderEmptyFont = (text: string) => (
    <div className={sprinkles({ body: 'b1', flexItems: 'center' })} style={{ height: 40 }}>
      {text}
    </div>
  );

  const renderCustomFonts = (fonts: string[]) => {
    if (fonts.length === 0) return renderEmptyFont("You haven't uploaded any custom fonts.");

    return (
      <>
        {fonts.map((font) => {
          return (
            <div className={rowClassName} key={font}>
              <div className={sprinkles({ body: 'b1' })}>{font}</div>
              <IconButton
                name="trash"
                onClick={() => deleteFont(font)}
                tooltipProps={{ text: 'Delete Font' }}
                variant="destructive"
              />
            </div>
          );
        })}
      </>
    );
  };

  function renderFontFaceConfig(
    familyId: string,
    src: string,
    fontWeight: number | undefined,
    existingWeights: (number | undefined)[],
  ) {
    return (
      <div
        className={sprinkles({
          padding: 'sp2',
          borderRadius: 8,
          marginLeft: 'sp2',
          backgroundColor: 'elevationLow',
          display: 'flex',
          alignItems: 'flex-end',
          gap: 'sp1',
        })}
        key={src}>
        <div className={sprinkles({ flex: 1 })}>
          <Label htmlFor="" infoText="Upload custom fonts above. Each font can only be used once">
            Font
          </Label>
          <Select
            onChange={(value) => src !== value && updateFontSrc(familyId, src, value)}
            selectedValue={src}
            values={[...fontOptions, { label: src, value: src }]}
          />
        </div>
        <div>
          <Label htmlFor="" infoText="Text thickness. 400 is normal. 700 is bold">
            Weight
          </Label>
          <TextWeightSelect
            onSubmit={(value) => fontWeight !== value && updateFontWeight(familyId, src, value)}
            selectedValue={fontWeight}
            values={SELECT_OPTIONS.filter(
              (o) =>
                parseInt(o.value) === fontWeight || !existingWeights.includes(parseInt(o.value)),
            )}
          />
        </div>
        <IconButton
          name="trash"
          onClick={() => deleteFontFace(familyId, src)}
          tooltipProps={{ text: 'Delete Font Face' }}
          variant="destructive"
        />
      </div>
    );
  }

  const renderFontConfig = () => {
    return (
      <>
        <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
          <div className={sprinkles({ heading: 'h2' })}>Font Families</div>
          <Button onClick={createFontFamily} variant="primary">
            Create a Font Family
          </Button>
        </div>
        {draftFontConfig?.map(({ id, fontFamily, fontFaces }) => {
          return (
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })} key={fontFamily}>
              <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
                <div>
                  <Label htmlFor="">Font Family Name</Label>
                  <Input
                    defaultValue={fontFamily}
                    onSubmit={(value) => fontFamily !== value && updateFontFamily(id, value)}
                  />
                </div>
                <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
                  <Menu
                    trigger={
                      <Button icon="plus" variant="primary">
                        Add Font
                      </Button>
                    }>
                    {fontOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => updateFontSrc(id, option.value, option.value)}>
                        {option.label}
                      </MenuItem>
                    ))}
                    {fontOptions.length === 0 ? (
                      <MenuLabel>All fonts have been assigned to a family</MenuLabel>
                    ) : null}
                  </Menu>
                  <IconButton
                    name="trash"
                    onClick={() => deleteFontFamily(id)}
                    tooltipProps={{ text: 'Delete Font Family' }}
                    variant="destructive"
                  />
                </div>
              </div>
              {fontFaces.map(({ fontWeight, src }) =>
                renderFontFaceConfig(
                  id,
                  src,
                  fontWeight,
                  fontFaces.map((face) => face.fontWeight),
                ),
              )}
            </div>
          );
        })}
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <Button
            disabled={fontConfigErrors.length > 0}
            loading={isLoading(fontConfig)}
            onClick={saveFontConfig}
            tooltipProps={{
              text: draftFontConfig
                ? 'Save changes to custom fonts'
                : 'Make changes to font families before saving',
            }}
            variant="primary">
            Save Changes
          </Button>
          {fontConfigErrors.length > 0 ? (
            <div className={errorClassName}>{fontConfigErrors[0]}</div>
          ) : null}
          {isError(fontConfig) ? (
            <div className={errorClassName}>{fontConfig.error.toString()}</div>
          ) : null}
        </div>
      </>
    );
  };

  if (!fonts) return <></>;

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ heading: 'h2' })}>Custom Fonts</div>
        <Button
          disabled={!RD.isSuccess(fonts)}
          onClick={() => inputFile.current?.click()}
          variant="primary">
          Upload a Font
        </Button>
      </div>
      <RD.RemoteComponent
        Error={() => renderEmptyFont('Error loading custom fonts')}
        Loading={() => <Spinner />}
        Success={renderCustomFonts}
        data={fonts}
      />
      {renderFontConfig()}
      <input
        accept=".otf,.woff,.ttf,.woff2"
        id="file"
        onChange={(event) => handleFile(event.target.files)}
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
};

const rowClassName = sprinkles({ flexItems: 'alignCenterBetween' });

const errorClassName = sprinkles({ body: 'b3', color: 'error' });
