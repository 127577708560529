import { FC } from 'react';

import ReportAiVisualizationImg from 'constants/images/report-builder/report-ai-visualization.gif';
import ReportAiImg from 'constants/images/report-builder/report-ai.gif';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Callout } from 'pages/ReportBuilder/Onboarding/Blocks/Callout';

import { ImageAndText } from '../Blocks/ImageAndText';

export const ReportAIDoc: FC = () => (
  <>
    <ImageAndText alt="Report AI" img={ReportAiImg}>
      <EmbedText heading="h3">Create visualizations with AI</EmbedText>
      <EmbedText body="b2">
        Kick start your views with the AI visualization center! You can generate tables, charts, and
        visualizations from prompts without needing to set it up in the interface.
      </EmbedText>
    </ImageAndText>
    <EmbedText body="b2">
      You can be as specific as you want. For example, you can enter the prompt: ”Transactions over
      time” Alternatively, you can be more granular: ”Transaction broken down by category over the
      last year”
    </EmbedText>
    <Callout>
      Tip: Try out using key terms to help make your prompts more specific! For example: Bar charts,
      line charts, group by, or any of your specific column names
    </Callout>
    <ImageAndText alt="Report AI Visualization" img={ReportAiVisualizationImg}>
      <EmbedText body="b2">Refine details before creating a new view in your report.</EmbedText>
    </ImageAndText>
  </>
);
