import { EmailBody } from 'reportBuilderContent/exportTypes';

import { defineEmbedPostAction } from './actionUtils';
import { ACTION } from './types';

// A cadence should either be attached to a report or a built in
type ReportBuilderEmailCadenceResource = { customer_report_id: number } | { built_in_id: string };

export type ReportBuilderEmailCadence = {
  id: string;
} & EmailBody &
  ReportBuilderEmailCadenceResource;

type CreateReportBuilderEmailCadenceBody = {
  resource_embed_id: string;
  environment_tag_id: number | null;
  email: EmailBody;
} & ReportBuilderEmailCadenceResource;

export const {
  actionFn: createReportBuilderEmailCadence,
  successAction: createReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<
  CreateReportBuilderEmailCadenceBody,
  { email: ReportBuilderEmailCadence }
>(ACTION.CREATE_CUSTOMER_REPORT_EMAIL, 'customer_reports', 'create_email');

export const {
  actionFn: updateReportBuilderEmailCadence,
  successAction: updateReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<
  { email_id: string; email: EmailBody },
  { email: ReportBuilderEmailCadence }
>(ACTION.UPDATE_CUSTOMER_REPORT_EMAIL, 'customer_reports', 'update_email');

export const {
  actionFn: deleteReportBuilderEmailCadence,
  successAction: deleteReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<{ email_id: string; report_id: string | number }, { id: string }>(
  ACTION.DELETE_CUSTOMER_REPORT_EMAIL,
  'customer_reports',
  'delete_email',
);

type ListReportBuilderEmailCadencesBody = {
  resource_embed_id: string;
  environment_tag_id: number | null;
} & ReportBuilderEmailCadenceResource;

export const {
  actionFn: listReportBuilderEmailCadences,
  successAction: listReportBuilderEmailCadencesSuccess,
} = defineEmbedPostAction<
  ListReportBuilderEmailCadencesBody,
  { emails: ReportBuilderEmailCadence[] }
>(ACTION.LIST_CUSTOMER_REPORT_EMAILS, 'customer_reports', 'emails');
