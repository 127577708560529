import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ExploreEmailCadence } from 'actions/teamActions';

import { ACTION } from './types';

type CreateDashboardEmailBody = {
  dashboard_template_id: number;
  from_email: string;
  subject: string;
  hour: number | undefined;
  minute: number | undefined;
  day_of_week: number | undefined;
  week_of_month: number | undefined;
  timezone: string | undefined;
};

export const { actionFn: createDashboardEmail, successAction: createDashboardEmailSuccess } =
  defineAPIPostAction<CreateDashboardEmailBody, { cadence: ExploreEmailCadence }>(
    ACTION.CREATE_DASHBOARD_EMAIL,
    'emails',
    'create_email_cadence',
    'POST',
  );

type DeleteDashboardEmailData = { dashboard_template_id: number };

export const { actionFn: deleteDashboardEmail, successAction: deleteDashboardEmailSuccess } =
  defineAPIPostAction<DeleteDashboardEmailData, DeleteDashboardEmailData>(
    ACTION.DELETE_DASHBOARD_EMAIL,
    'emails',
    'delete_email_cadence',
    'POST',
  );

export const { actionFn: editDashboardEmail, successAction: editDashboardEmailSuccess } =
  defineAPIPostAction<CreateDashboardEmailBody, { cadence: ExploreEmailCadence }>(
    ACTION.EDIT_DASHBOARD_EMAIL,
    'emails',
    'edit_email_cadence',
    'POST',
  );

export const { actionFn: fetchEmailCadenceList, ...fetchEmailCadenceListActions } =
  defineAPIAction<{ email_cadence_list: ExploreEmailCadence[] }>(
    ACTION.FETCH_EMAIL_CADENCE_LIST,
    'emails',
    'get_email_cadence_list',
    'GET',
  );

type SendEmailBody = {
  dashboard_template_id: number;
  email: string;
  subject: string;
  customer_id: number | undefined;
};

export const { actionFn: sendTestEmail } = defineAPIPostAction<SendEmailBody, {}>(
  ACTION.SEND_TEST_EMAIL,
  'emails',
  'send_test_email',
  'POST',
);

type UploadImageData = {
  url: string;
};

type UploadImageBody = {
  file: string | ArrayBuffer | null;
  extension: string | undefined;
};

export const { actionFn: uploadImage } = defineAPIPostAction<UploadImageBody, UploadImageData>(
  ACTION.UPLOAD_IMAGE,
  'emails',
  'upload_image',
  'POST',
);
