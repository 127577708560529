export type EmbeddedDashboardType =
  | 'embedded'
  | 'iframe'
  | 'shared'
  | 'portal'
  | 'email'
  | 'pdf'
  | 'preview';

export function shouldUseUrlParams(
  embedType: EmbeddedDashboardType | undefined,
  updateUrlParams?: boolean,
): boolean {
  return (updateUrlParams || embedType !== 'embedded') && embedType !== undefined;
}
