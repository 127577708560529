import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import { EmbedIdConversionBody, getResourceIdFromEmbedId } from 'actions/embedActions';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ROUTES, ROUTE_PROVIDERS } from 'constants/routes';
import { ResourceType } from 'types/exploResource';

export const EmbedPageComponent: FunctionComponent = () => {
  const {
    params: { embedId },
  } = useRouteMatch<{ embedId: string }>();

  const history = useHistory();
  const dispatch = useDispatch();

  dispatch(
    getResourceIdFromEmbedId(
      { id: embedId },
      (data: EmbedIdConversionBody) => {
        const convertedId = data.convertedId;
        const resourceType = data.resourceType;
        if (resourceType === ResourceType.DASHBOARD) {
          history.push(ROUTE_PROVIDERS.DASHBOARD(convertedId));
        } else if (resourceType === ResourceType.REPORT) {
          // Default to the datasets page for report builders.
          history.push(ROUTE_PROVIDERS.REPORT_BUILDER(convertedId, DEFAULT_REPORT_BUILDER_VIEW));
        } else {
          // Should not receive a folder type. Return the user to the home page.
          history.push(ROUTES.HOME_APP_PAGE);
        }
      },
      () => {
        history.push(ROUTES.HOME_APP_PAGE);
      },
    ),
  );
  return <ExploLoadingSpinner />;
};

const DEFAULT_REPORT_BUILDER_VIEW = 'datasets';
