import { FC } from 'react';

import { QueryDebuggingInformation } from 'actions/responseTypes';
import { Intent, Tag, sprinkles } from 'components/ds';

type Props = {
  queryInformation: QueryDebuggingInformation | undefined;
  dashboardEditor?: boolean;
};

const tagClassName = sprinkles({
  marginX: 'sp1.5',
  marginTop: 'sp1.5',
  marginBottom: 'sp0',
  paddingX: 'sp.5',
  paddingY: 'sp1',
});
const containerClassName = sprinkles({ display: 'flex', flexWrap: 'wrap' });

const dashboardContainerClassName = sprinkles({ flexItems: 'alignCenter', gap: 'sp1' });

export const QueryRuntime: FC<Props> = ({ queryInformation, dashboardEditor }) => {
  if (!queryInformation) return null;
  const timing = queryInformation._query_timing;

  const tags: JSX.Element[] = [];
  const createTag = (text: string) =>
    tags.push(
      <Tag
        className={dashboardEditor ? sprinkles({ whiteSpace: 'nowrap' }) : tagClassName}
        intent={dashboardEditor ? Intent.NONE : Intent.ACTIVE}
        key={text}
        leftIcon={dashboardEditor ? 'clock-reg' : 'clock'}>
        {text}
      </Tag>,
    );

  if (timing.time_to_connect)
    createTag(
      `${timing.time_to_connect}s connection time${
        timing.time_to_ssh ? ` (${timing.time_to_ssh}s ssh tunneling time)` : ''
      }`,
    );
  if (timing.time_to_run) createTag(`${timing.time_to_run}s querying time`);
  if (timing.time_to_process) createTag(`${timing.time_to_process}s processing time`);
  if (timing.total_time) createTag(`${timing.total_time}s total runtime`);

  if (timing.cache_hit !== undefined)
    tags.push(
      <Tag className={tagClassName} intent={Intent.NONE} key="cache-hit">
        {timing.cache_hit ? 'cache hit' : 'cache miss'}
      </Tag>,
    );

  return tags.length === 0 ? (
    <Tag className={tagClassName} intent={Intent.ERROR} leftIcon="circle-exclamation">
      Unable to calculate runtime stats
    </Tag>
  ) : (
    <div className={dashboardEditor ? dashboardContainerClassName : containerClassName}>{tags}</div>
  );
};
