import { createReducer } from '@reduxjs/toolkit';

import { addCustomerSuccess, editCustomersSuccess } from 'actions/customerActions';
import { fetchEndUserListActions } from 'actions/endUserActions';
import { EndUser } from 'actions/teamActions';
import * as RD from 'remotedata';

interface EndUserReducerState {
  endUsers: RD.ResponseData<EndUser[]>;
}

const endUserReducerInitialState: EndUserReducerState = {
  endUsers: RD.Idle(),
};

export const endUserReducer = createReducer(endUserReducerInitialState, (builder) => {
  builder
    .addCase(fetchEndUserListActions.requestAction, (state) => {
      state.endUsers = RD.Loading();
    })
    .addCase(fetchEndUserListActions.errorAction, (state) => {
      state.endUsers = RD.Error('Error Loading End Users');
    })
    .addCase(fetchEndUserListActions.successAction, (state, { payload }) => {
      state.endUsers = RD.Success(payload.end_users);
    })
    .addCase(addCustomerSuccess, (state, { payload }) => {
      RD.update(state.endUsers, (users) => users.push(...payload.customer_portal_users));
    })
    .addCase(editCustomersSuccess, (state, { payload }) => {
      state.endUsers = RD.map(state.endUsers, (users) => {
        const endUserList = users.filter((user) => user.group_id !== payload.customer.id);
        endUserList.push(...payload.customer_portal_users);
        return endUserList;
      });
    })
    .addDefaultCase((state) => state);
});
