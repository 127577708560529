import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';

type Props = {
  dashboardName: string;
  iconUrl?: string;
  onSignOut: () => void;
};

export const PortalHeader: FC<Props> = ({ dashboardName, iconUrl, onSignOut }) => {
  return (
    <div className={rootClass}>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {iconUrl ? (
          <img
            alt="logo for explo"
            className={sprinkles({ display: 'block', marginRight: 'sp1', height: 32 })}
            src={iconUrl}
          />
        ) : null}
        <div className={sprinkles({ heading: 'h4' })}>{dashboardName}</div>
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <EmbedButton onClick={onSignOut} variant="primary">
          Sign Out
        </EmbedButton>
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  height: 48,
  backgroundColor: 'white',
  flexItems: 'alignCenterBetween',
  paddingX: 'sp3',
  borderBottom: 2,
  borderColor: 'outline',
});
