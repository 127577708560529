import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createGetRequestConfig } from 'actions/actionUtils';
import { Customer, SummaryCustomer, switchCustomer } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { EXPLO_EUG_STORAGE_KEY } from 'constants/customerConstants';
import { ReduxState } from 'reducers/rootReducer';
import { makeThunkRequest } from 'utils/thunkUtils';

export type FetchCustomerData = {
  customer: Customer;
};

type FetchCustomerQueryParams = {
  customer_id: number | null;
  get_default?: boolean;
};

export const fetchCustomer = createAsyncThunk<
  FetchCustomerData,
  FetchCustomerQueryParams,
  { state: ReduxState }
>(ACTION.FETCH_CUSTOMER, async (queryParams, { dispatch }) =>
  makeThunkRequest(
    createGetRequestConfig('customers/get_customer/', 'GET', queryParams),
    'Error getting customer',
    { onSuccess: (response) => dispatch(switchCustomer(response as FetchCustomerData)) },
  ),
);

type FetchCustomerParentData = { customer_parent: SummaryCustomer | null };

export const fetchCustomerParent = createAsyncThunk<
  FetchCustomerParentData,
  number,
  { state: ReduxState }
>(ACTION.FETCH_CUSTOMER_PARENT, async (customerId) =>
  makeThunkRequest(
    createGetRequestConfig(`customers/${customerId}/get_customer_parent/`, 'GET'),
    'Error getting parent customer',
  ),
);

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const initializeCustomerSelectorThunk =
  (parseUrl?: boolean): Thunk =>
  (dispatch) => {
    dispatch(parseUrl ? fetchCustomerFromUrlThunk() : checkLocalStorageThunk());
  };

const checkLocalStorageThunk = (): Thunk => (dispatch) => {
  const eugKey = localStorage.getItem(EXPLO_EUG_STORAGE_KEY);
  const id = parseInt(eugKey || '');

  dispatch(
    fetchCustomer(
      !isNaN(id)
        ? { customer_id: id, get_default: true }
        : { customer_id: null, get_default: true },
    ),
  );
};

const fetchCustomerFromUrlThunk = (): Thunk => (dispatch) => {
  const queryParams = new URLSearchParams(location.search);
  const id = parseInt(queryParams.get('customer') ?? '');

  dispatch(
    !isNaN(id) ? fetchCustomer({ customer_id: id, get_default: true }) : checkLocalStorageThunk(),
  );
};

export const switchCustomerThunk =
  (customer_id: number): Thunk =>
  (dispatch, getState) => {
    const cachedCustomer = getState().customers.cachedCustomers[String(customer_id)];
    dispatch(cachedCustomer ? switchCustomer(cachedCustomer) : fetchCustomer({ customer_id }));
  };
