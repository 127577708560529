import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Tag, sprinkles } from 'components/ds';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

export type Props = { id: string };

export const ScheduledEmailsTag: FC<Props> = ({ id }) => {
  const emailCounts = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.emailCounts,
  );

  const numEmails = useMemo(() => emailCounts[id], [emailCounts, id]);

  if (!numEmails) return null;
  return (
    <Tag
      className={sprinkles({ backgroundColor: 'elevationLow', gap: 'sp.5' })}
      leftIcon="envelope">{`${numEmails} scheduled email${numEmails === 1 ? '' : 's'}`}</Tag>
  );
};
