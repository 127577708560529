import { defineAPIPostAction, defineAPIAction } from './actionUtils';
import { ACTION } from './types';

type FetchCustomFontsData = {
  fonts: string[];
};

export const { actionFn: fetchCustomFonts, ...fetchCustomFontsActions } =
  defineAPIAction<FetchCustomFontsData>(ACTION.FETCH_CUSTOM_FONTS, 'fonts', '', 'GET');

type UploadCustomFontFileBody = {
  file: string | ArrayBuffer | null;
  fileName: string;
};

export const { actionFn: uploadCustomFontFile, successAction: uploadCustomFontSuccess } =
  defineAPIPostAction<UploadCustomFontFileBody, {}>(
    ACTION.UPLOAD_CUSTOM_FONT,
    'fonts',
    'upload_font',
    'POST',
  );

type DeleteCustomFontBody = { fileName: string };

export const { actionFn: deleteCustomFont, successAction: deleteCustomFontSuccess } =
  defineAPIPostAction<DeleteCustomFontBody, {}>(
    ACTION.DELETE_CUSTOM_FONT,
    'fonts',
    'delete_font',
    'POST',
  );
