import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { updateDataPanelDataset } from 'reducers/dashboardEditConfigReducer';
import { setEditDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';

import { DataPanelModal } from './dataPanelModal';

export const EditDataPanelModal: FC<{}> = () => {
  const dispatch = useDispatch();

  const { editDataPanelState, datasets } = useSelector(
    (state: ReduxState) => ({
      editDataPanelState: state.dashboardInteractions.editDataPanelState,
      datasets: getEditableDatasets(state),
    }),
    shallowEqual,
  );

  if (!editDataPanelState) return null;

  return (
    <DataPanelModal
      headerText="Select a table. This will clear your data panel's current configuration."
      initialDatasetId={editDataPanelState.selectedDatasetId}
      onClose={() => dispatch(setEditDataPanelState(null))}
      onConfirm={(selectedDatasetId) => {
        const dataset = datasets?.[selectedDatasetId ?? ''];
        if (!dataset) return;

        dispatch(updateDataPanelDataset(dataset.id));
      }}
      opType={editDataPanelState.opType}
      primaryButtonText="Edit"
      title="Edit underlying dataset"
    />
  );
};
