import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { ToggleButton } from 'pages/ReportBuilder/ReportView/DataPanel/ToggleButton';

type ToggleOption = { icon?: IconName; label: string; isSelected: boolean };

type Props = {
  title: string;
  onToggle: () => void;
  options: [ToggleOption, ToggleOption];
};

export const ToggleSection: FC<Props> = ({ title, options, onToggle }) => (
  <DataPanelSection>
    <DataPanelSubHeader title={title} />
    <div className={toggleButtonClass}>
      {options.map((option) => (
        <ToggleButton
          icon={option.icon}
          key={option.label}
          onClick={() => !option.isSelected && onToggle()}
          selected={option.isSelected}>
          {option.label}
        </ToggleButton>
      ))}
    </div>
  </DataPanelSection>
);

const toggleButtonClass = sprinkles({
  paddingX: 'sp2',
  width: 'fill',
  flexItems: 'alignCenter',
  gap: 'sp.25',
});
