import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportConfig } from 'actions/customerReportActions';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { GlobalStylesContext } from 'globalStyles';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { ModalViews } from 'pages/ReportBuilder/ModalViews';
import { ReportName } from 'pages/ReportBuilder/ReportName';
import { ReportSaveStatus } from 'pages/ReportBuilder/ReportSaveStatus';
import { ReportAIButton } from 'pages/ReportBuilder/ReportView/ReportAIButton';
import { ReportDescription } from 'pages/ReportBuilder/ReportView/ReportDescription';
import { ViewTabs } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs';
import { updateBuiltInReportSettings } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { openDataModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import * as styles from './BuiltInReportView.css';

type Props = {
  builtInConfig: BuiltInReportConfig;
  currentConfig: CustomerReportConfig;
  isSaved: boolean;
};

export const BuiltInReportView: FC<Props> = ({ builtInConfig, currentConfig, isSaved }) => {
  const dispatch = useDispatch();

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const globalStyleVars = useMemo(
    () => getGlobalStyleVars(globalStyleConfig, { forReportBuilder: true }),
    [globalStyleConfig],
  );

  return (
    <GlobalStylesContext.Provider value={{ globalStyleConfig, globalStyleVars }}>
      <div className={styles.container} style={globalStyleVars}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp1', overflow: 'hidden' })}>
              <div className={styles.headerText}>
                <ReportName
                  onSubmit={(value) => {
                    dispatch(
                      updateBuiltInReportSettings({ builtInId: builtInConfig.id, name: value }),
                    );
                  }}
                  value={builtInConfig.name}
                />
                <ReportSaveStatus isSaved={isSaved} />
              </div>
              {builtInConfig.description ? (
                <ReportDescription description={builtInConfig.description} key={builtInConfig.id} />
              ) : null}
            </div>
            <div className={styles.headerButtons}>
              <ReportAIButton datasetId={builtInConfig.config.dataInfo?.datasetId} />
              <EmbedButton
                icon="pencil"
                onClick={() => dispatch(openDataModal())}
                variant="secondary">
                Edit data
              </EmbedButton>
            </div>
          </div>
        </div>

        <ViewTabs
          dataInfo={currentConfig.dataInfo}
          savedViews={builtInConfig?.config.views || []}
          views={currentConfig.views || []}
        />

        <ModalViews
          dataInfo={currentConfig.dataInfo}
          isIframe={false}
          reportName={builtInConfig.name || ''}
        />
      </div>
    </GlobalStylesContext.Provider>
  );
};
