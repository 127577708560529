import { AnyAction, createAsyncThunk, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { createApiRequestConfig } from 'actions/actionUtils';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { ErrorResponse } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { changesMadeWhileSaving, setSaveError } from 'reducers/resourceSavingReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { isSuccess } from 'remotedata';
import { ResourcePageType } from 'types/exploResource';
import { removeUnsavedDashboardConfigFields } from 'utils/dashboardUtils';
import { makeThunkRequest } from 'utils/thunkUtils';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const saveResourceConfig =
  (resourceType: ResourcePageType): Thunk =>
  (dispatch, getState) => {
    const { isSaving, changesWhileSaving } = getState().resourceSaving;
    if (isSaving) dispatch(changesMadeWhileSaving(true));
    else {
      if (changesWhileSaving) dispatch(changesMadeWhileSaving(false));
      if (resourceType === ResourcePageType.EXPLORE) dispatch(saveExploreDraft());
      else if (resourceType === ResourcePageType.REPORT_BUILDER) dispatch(saveReportBuilderDraft());
    }
  };

type EditResponse = { edit_version_number: number };

export const saveExploreDraft = createAsyncThunk<EditResponse, undefined, { state: ReduxState }>(
  ACTION.SAVE_DASHBOARD_DRAFT,
  async (_, { getState, dispatch }) => {
    const { dashboardEditConfig, dashboard } = getState();
    const { config, versionInfo } = dashboardEditConfig;
    const dashboardId = RD.getOrDefault(dashboard.currentDashboard, undefined)?.id;

    let requestConfig: AxiosRequestConfig | null = null;

    if (config && versionInfo && dashboardId) {
      const postData = {
        config: removeUnsavedDashboardConfigFields(config),
        version_number: versionInfo.version_number,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `dashboards/${dashboardId}/save_draft/`;

      requestConfig = createApiRequestConfig(url, 'POST', postData);
    }

    return makeThunkRequest(requestConfig, 'Error saving dashboard draft', {
      onError: (response) => handleSaveError(dispatch, response),
    });
  },
);

export const saveReportBuilderDraft = createAsyncThunk<
  EditResponse | { new_version: ReportBuilderVersion },
  undefined,
  { state: ReduxState }
>(ACTION.SAVE_REPORT_BUILDER_DRAFT, async (_, { getState, dispatch }) => {
  const { reportBuilderEdit, reportBuilder } = getState();
  const { config, versionInfo } = reportBuilderEdit;
  const reportBuilderId = RD.getOrDefault(reportBuilder.currentReportBuilder, undefined)?.id;

  let requestConfig: AxiosRequestConfig | null = null;

  if (isSuccess(config) && versionInfo && reportBuilderId) {
    const sharedData = { config: config.data };
    if (versionInfo.is_draft) {
      const postData = {
        ...sharedData,
        version_number: versionInfo.version_number,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `report_builder/${reportBuilderId}/save_draft/`;
      requestConfig = createApiRequestConfig(url, 'POST', postData);
    } else {
      const url = `report_builder/${reportBuilderId}/create_new_version/`;
      requestConfig = createApiRequestConfig(url, 'POST', sharedData);
    }
  }

  return makeThunkRequest(requestConfig, 'Error saving report builder draft', {
    onError: (response) => handleSaveError(dispatch, response),
  });
});

export const createReportBuilderDraft = createAsyncThunk<
  { new_version: ReportBuilderVersion },
  { config: ReportBuilderConfig },
  { state: ReduxState }
>(ACTION.CREATE_REPORT_BUILDER_DRAFT, async ({ config }, { getState, dispatch }) => {
  const { reportBuilder } = getState();
  const reportBuilderId = RD.getOrDefault(reportBuilder.currentReportBuilder, undefined)?.id;

  let requestConfig: AxiosRequestConfig | null = null;

  if (reportBuilderId) {
    requestConfig = createApiRequestConfig(
      `report_builder/${reportBuilderId}/create_new_version/`,
      'POST',
      { config },
    );
  }

  return makeThunkRequest(requestConfig, 'Error creating report builder draft', {
    onError: (response) => handleSaveError(dispatch, response),
  });
});

const handleSaveError = (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  response: ErrorResponse,
) =>
  dispatch(
    setSaveError(
      response.error_msg ??
        'Something went wrong saving your draft. Please refresh the page and try again.',
    ),
  );
