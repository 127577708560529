import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';

enum RemoteData {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type IdleStatus = { status: RemoteData.IDLE };
type LoadingStatus = { status: RemoteData.LOADING };
type SuccessStatus<T> = { status: RemoteData.SUCCESS; data: T };
type ErrorStatus = { status: RemoteData.ERROR; error: string };

export type ResponseData<T> = IdleStatus | LoadingStatus | SuccessStatus<T> | ErrorStatus;

export function Idle(): IdleStatus {
  return { status: RemoteData.IDLE };
}

export function Loading(): LoadingStatus {
  return { status: RemoteData.LOADING };
}

export function Success<T>(data: T): SuccessStatus<T> {
  return {
    status: RemoteData.SUCCESS,
    data,
  };
}

export function Error(error: string): ErrorStatus {
  return { status: RemoteData.ERROR, error };
}

// helper functions

export function isLoading(
  responseData: ResponseData<unknown> | undefined,
  idleIsLoading = false,
): boolean {
  return responseData?.status === RemoteData.LOADING || (idleIsLoading && isIdle(responseData));
}

export function isLoadingMulti(
  responseDataList: ResponseData<unknown>[],
  idleIsLoading = false,
): boolean {
  return responseDataList.some((responseData) => isLoading(responseData, idleIsLoading));
}

export function isIdle(responseData: ResponseData<unknown> | undefined): boolean {
  return responseData?.status === RemoteData.IDLE;
}

export function isSuccess<T>(
  responseData: ResponseData<T> | undefined,
): responseData is SuccessStatus<T> {
  return responseData?.status === RemoteData.SUCCESS;
}

export function isError(
  responseData: ResponseData<unknown> | undefined,
): responseData is ErrorStatus {
  return responseData?.status === RemoteData.ERROR;
}

export function hasNotReturned(
  responseData: ResponseData<unknown> | undefined,
): responseData is undefined | IdleStatus | LoadingStatus {
  return !responseData || isIdle(responseData) || isLoading(responseData);
}

export function update<T>(responseData: ResponseData<T>, func: (data: T) => void): void {
  if (responseData.status !== RemoteData.SUCCESS) return;
  func(responseData.data);
}

export function map<T, K>(responseData: ResponseData<T>, func: (data: T) => K): ResponseData<K> {
  if (responseData.status !== RemoteData.SUCCESS) return responseData;
  return Success(func(responseData.data));
}

export function getError(responseData: ResponseData<unknown> | undefined): string | undefined {
  if (!isError(responseData)) return;
  return responseData.error ?? 'Error loading data';
}

export function getOrDefault<T>(responseData: ResponseData<T> | undefined, defaultValue: T): T {
  return isSuccess(responseData) ? responseData.data : defaultValue;
}

// Remote Component

interface RemoteComponentProps<T> {
  data: ResponseData<T>;
  Success: (data: T) => JSX.Element | null;
  Loading?: () => JSX.Element;
  Error?: (error: string) => JSX.Element;
}

export function append<T, K>(dataA: ResponseData<T>, dataB: ResponseData<K>): ResponseData<[T, K]> {
  if (isError(dataA)) return Error(dataA.error);
  if (isError(dataB)) return Error(dataB.error);

  if (isSuccess(dataA) && isSuccess(dataB)) return Success([dataA.data, dataB.data]);
  return Loading();
}

export function RemoteComponent<T>({
  data,
  Success,
  Loading,
  Error,
}: RemoteComponentProps<T>): JSX.Element | null {
  switch (data.status) {
    case RemoteData.LOADING:
      if (Loading !== undefined) return Loading();
      return <ExploLoadingSpinner />;
    case RemoteData.ERROR:
      if (Error !== undefined) return Error(data.error);
      break;
    case RemoteData.SUCCESS:
      return Success(data.data);
  }
  return null;
}
