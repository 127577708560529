import { FC } from 'react';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Switch, Input, BooleanToggle } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  BAR_CHART_TYPES,
  HORIZONTAL_OPERATION_TYPES,
  OPERATION_TYPES,
  YAxisFormat,
} from 'constants/types';

type Props = {
  configInputClass: string;
  isNormalized: boolean;
  yAxisFormat: YAxisFormat | undefined;

  updateAxisFormat: (yAxisFormat: YAxisFormat) => void;
  visualizationType: OPERATION_TYPES;
};

export const SharedYAxisConfigs: FC<Props> = ({
  configInputClass,
  isNormalized,
  updateAxisFormat,
  yAxisFormat,
  visualizationType,
}) => {
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const isHeatMap = visualizationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;

  const renderValueFormatSection = () => {
    if (isHeatMap) return null;

    return (
      <>
        <NumberFormatToggle
          disableAbbr
          disableTime
          className={configInputClass}
          label="Value Format"
          labelHelpText={
            isNormalized ? 'Formatting will only apply to the data tooltip' : undefined
          }
          selectedFormat={selectedFormat.id}
          updateFormat={(numberFormat) => updateAxisFormat({ numberFormat: { id: numberFormat } })}
        />
        <Switch
          className={configInputClass}
          label="Show Axis Decimals"
          onChange={() => updateAxisFormat({ showDecimals: !yAxisFormat?.showDecimals })}
          switchOn={yAxisFormat?.showDecimals}
        />
        {yAxisFormat?.showDecimals ? (
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={String(yAxisFormat?.decimalPlaces ?? 2)}
            label={{
              text: 'Decimal Places',
              infoText:
                "Formatting will only apply to the data tooltip unless 'Show Axis Decimals' is enabled.",
            }}
            onSubmit={(newValue: string) => {
              const intValue = parseInt(newValue);
              updateAxisFormat({ decimalPlaces: intValue || 0 });
            }}
          />
        ) : null}
      </>
    );
  };

  const renderAxisPlacement = () => {
    if (
      !BAR_CHART_TYPES.has(visualizationType) &&
      visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2
    )
      return;
    const isHorizontal = HORIZONTAL_OPERATION_TYPES.has(visualizationType);
    return (
      <BooleanToggle
        className={configInputClass}
        falseText={isHorizontal ? 'Bottom' : 'Left'}
        label="Axis placement"
        onValueChange={(oppositeAligned) => updateAxisFormat({ oppositeAligned })}
        selectedValue={!!yAxisFormat?.oppositeAligned}
        trueText={isHorizontal ? 'Top' : 'Right'}
      />
    );
  };

  const renderAxisFormat = () => {
    if (isHeatMap) return null;

    return (
      <>
        <Switch
          className={configInputClass}
          label="Axis Labels"
          onChange={() => updateAxisFormat({ hideAxisLabels: !yAxisFormat?.hideAxisLabels })}
          switchOn={!yAxisFormat?.hideAxisLabels}
        />
        <Switch
          className={configInputClass}
          label="Grid Lines"
          onChange={() => updateAxisFormat({ hideGridLines: !yAxisFormat?.hideGridLines })}
          switchOn={!yAxisFormat?.hideGridLines}
        />
      </>
    );
  };

  const renderNumberScaleSection = () => {
    if (isHeatMap) return null;

    return (
      <>
        <Switch
          className={configInputClass}
          label="Use Logarithmic Scale"
          onChange={() => {
            let min = yAxisFormat?.min;
            // log scales do not allow minimumValue of <= 0
            if (min !== undefined && min <= 0) min = undefined;

            updateAxisFormat({ useLogScale: !yAxisFormat?.useLogScale, min });
          }}
          switchOn={yAxisFormat?.useLogScale}
        />
        <RangeInput
          className={configInputClass}
          endVal={String(yAxisFormat?.max ?? '')}
          onNewRange={(newStart?: string, newEnd?: string) => {
            const min = parseFloat(newStart ?? '');
            const max = parseFloat(newEnd ?? '');

            const validIfLog = !(yAxisFormat?.useLogScale && min <= 0);

            updateAxisFormat({
              min: min >= 0 && validIfLog ? min : undefined,
              max: max >= 0 ? max : undefined,
            });
          }}
          startVal={String(yAxisFormat?.min ?? '')}
        />
      </>
    );
  };

  if (visualizationType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP)
    return renderValueFormatSection();

  return (
    <>
      <Switch
        className={configInputClass}
        label="Axis Title"
        onChange={() => updateAxisFormat({ showTitle: !yAxisFormat?.showTitle })}
        switchOn={yAxisFormat?.showTitle}
      />
      {yAxisFormat?.showTitle ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={yAxisFormat?.title}
          onSubmit={(newValue) => updateAxisFormat({ title: newValue })}
        />
      ) : null}
      {renderValueFormatSection()}
      {renderNumberScaleSection()}
      {renderAxisPlacement()}
      {renderAxisFormat()}
      <Switch
        className={configInputClass}
        label="Reverse Axis"
        onChange={() => updateAxisFormat({ reverseAxis: !yAxisFormat?.reverseAxis })}
        switchOn={yAxisFormat?.reverseAxis}
      />
    </>
  );
};
