import { FC } from 'react';

import { PlotLineType } from 'constants/types';

import { Select, sprinkles } from './ds';

type Props = { currentType: PlotLineType | undefined; onChange: (newType: PlotLineType) => void };

export const PlotLineSelect: FC<Props> = ({ currentType, onChange }) => {
  return (
    <Select
      className={sprinkles({ flex: 1 })}
      label="Line Type"
      onChange={(lineType) => onChange(lineType as PlotLineType)}
      selectedValue={currentType ?? PlotLineType.SOLID}
      values={PLOT_LINE_OPTIONS}
    />
  );
};

const PLOT_LINE_OPTIONS: { value: string; label: string }[] = [
  { value: PlotLineType.SOLID, label: 'Solid' },
  { value: PlotLineType.DASH, label: 'Dashes' },
  { value: PlotLineType.SHORT_DASH, label: 'Short Dashes' },
  { value: PlotLineType.LONG_DASH, label: 'Long dashes' },
  { value: PlotLineType.DOT, label: 'Dots' },
  { value: PlotLineType.SHORT_DOT, label: 'Short Dots' },
];
