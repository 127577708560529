import { FC } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';

import * as styles from './TableOfContentsItem.css';

type Props = {
  title: string;
  description: string;
  img: string;
  onClick: () => void;
};

export const TableOfContentsItem: FC<Props> = ({ title, description, img, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <img alt={title} className={styles.img} src={img} />
      <div className={styles.text}>
        <EmbedText heading="h3">{title}</EmbedText>
        <EmbedText body="b2">{description}</EmbedText>
      </div>
    </div>
  );
};
