import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { sprinkles, IconButton } from 'components/ds';

type Props = {
  text: string;
  isOpen: boolean;
  toggleIsOpen: () => void;
  headerElement?: ReactNode;
};

export const DatasetQueryPanel: FC<Props> = ({
  text,
  isOpen,
  toggleIsOpen,
  headerElement,
  children,
}) => {
  return (
    <div className={cx(panelContainerClass, sprinkles({ flex: isOpen ? 1 : undefined }))}>
      <div className={headerContainerClass} onClick={toggleIsOpen}>
        <div className={sprinkles({ heading: 'h3', color: 'contentSecondary' })}>{text}</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2', overflowX: 'hidden' })}>
          <div className={sprinkles({ overflowX: 'auto' })}>{headerElement}</div>
          <IconButton name={isOpen ? 'caret-down' : 'caret-right'} />
        </div>
      </div>
      {isOpen ? children : null}
    </div>
  );
};

const headerContainerClass = sprinkles({
  padding: 'sp2',
  flexItems: 'alignCenterBetween',
  cursor: 'pointer',
  gap: 'sp1',
});

const panelContainerClass = sprinkles({
  flexItems: 'column',
  border: 1,
  borderColor: 'outline',
  borderRadius: 8,
});
