import { IconName, Intent, Icon } from '@blueprintjs/core';
import { FC } from 'react';

import { NonIdealState } from 'components/ChartLayout/NonIdealState';
import { EmbedSpinner } from 'components/embed';

type Props = {
  className?: string;
  loading?: boolean;
  instructionsNeedConfiguration?: boolean;
  fullHeight?: boolean;
  requiredVarsNotSet?: boolean;
};

export const NeedsConfigurationPanel: FC<Props> = ({
  className,
  fullHeight,
  instructionsNeedConfiguration,
  loading,
  requiredVarsNotSet,
}) => {
  let icon: IconName | JSX.Element;
  let title: string | undefined;

  if (instructionsNeedConfiguration) {
    icon = 'cog';
    title = 'Configure the chart to get started';
  } else if (requiredVarsNotSet) {
    icon = 'multi-select';
    title = 'A dropdown or datepicker is required to load this chart. Please select an option.';
  } else if (loading) {
    icon = <EmbedSpinner fillContainer size="lg" />;
  } else {
    icon = <Icon icon="error" intent={Intent.DANGER} />;
    title = 'Something went wrong. Please refresh the page and try again';
  }

  return <NonIdealState className={className} fullHeight={fullHeight} icon={icon} title={title} />;
};
