import { FC, useState, useMemo, useEffect } from 'react';

import { TableDataset } from 'actions/dataSourceActions';
import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { DATE_TYPES, NUMBER_TYPES } from 'constants/dataConstants';
import { FidoTableView } from 'reducers/fidoReducer';

type Props = {
  table: TableDataset | FidoTableView;
  // Used to filter down schema for search
  searchQuery: string;
};

/**
 * Displays the columns in a schema table
 */
export const SchemaTableViewer: FC<Props> = ({ table, searchQuery }) => {
  const [isOpen, setIsOpen] = useState(false);

  const trimmedQuery = searchQuery.trim();
  const schema = useMemo(() => {
    if (!table.schema) return [];
    const copiedSchema = [...table.schema];
    const filteredSchema = trimmedQuery
      ? copiedSchema.filter((col) => col.name.toLowerCase().includes(trimmedQuery))
      : copiedSchema;
    return filteredSchema.sort((a, b) => a.name.localeCompare(b.name));
  }, [table.schema, trimmedQuery]);

  useEffect(() => {
    // Only open sections with relevant search results. Close otherwise
    setIsOpen(!!trimmedQuery && schema.length > 0);
  }, [schema.length, trimmedQuery]);

  const getIconFromColumnType = (elementType: string) =>
    DATE_TYPES.has(elementType)
      ? 'calendar'
      : NUMBER_TYPES.has(elementType)
      ? 'report-builder-number'
      : elementType === 'BOOLEAN'
      ? 'tick'
      : 'report-builder-string';

  const tableName = 'table_name' in table ? table.table_name : table.tableName;

  return (
    <ConfigSection
      icon="table"
      isOpen={isOpen}
      onOpen={setIsOpen}
      title={tableName}
      variant="header2">
      {schema?.map((col, index) => (
        <PanelListItem
          copiable
          key={index}
          leftIcon={getIconFromColumnType(col.type)}
          name={col.name}
        />
      ))}
    </ConfigSection>
  );
};
