import { FC, useState } from 'react';

import { IconButton } from '../IconButton/index';
import { sprinkles } from '../sprinkles.css';

type SectionProps = {
  title: string;
  headerClass: string;
  textContentClass: string;
};

// TODO(zifanxiang): Consider consolidating this with PanelComponents ModalSection.
export const ModalSection: FC<SectionProps> = ({
  title,
  headerClass,
  textContentClass,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div className={headerClass}>
        {title}
        <IconButton
          name={isOpen ? 'chevron-down' : 'chevron-right'}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </div>
      {isOpen ? <div className={textContentClass}>{children}</div> : null}
    </div>
  );
};
