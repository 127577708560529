import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { forwardRef, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReportView } from 'actions/customerReportActions';
import { EditableText } from 'components/EditableText';
import { Props as TooltipProps } from 'components/ds/Tooltip';
import { OPERATION_TYPES } from 'constants/types';
import * as styles from 'pages/ReportBuilder/ReportView/Tabs/ViewTabItem.css';
import { ViewTabMenu } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabMenu';
import { OPERATION_ICON_MAP } from 'pages/ReportBuilder/constants';
import {
  createView,
  deleteView,
  getCanDeleteView,
  setCurrentView,
  updateViewName,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

interface Props {
  view: CustomerReportView;
  savedViews: CustomerReportView[]; // Views that have been saved to the DB
}

export const SortableViewTab = forwardRef<HTMLDivElement, Props>(function SortableViewTab(
  { view, savedViews },
  ref,
) {
  const [isRenaming, setIsRenaming] = useState(false);

  const dispatch = useDispatch();
  const { currentViewId, canDelete } = useSelector(
    (state: ReportBuilderReduxState) => ({
      currentViewId: state.reportEditing.currentView,
      canDelete: getCanDeleteView(state.reportEditing),
    }),
    shallowEqual,
  );

  const isSelected = currentViewId === view.id;
  const isSaved = useMemo(() => {
    const savedView = savedViews.find((savedView) => savedView.id === view.id);
    return savedView && shallowEqual(view, savedView);
  }, [savedViews, view]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: view.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  const tooltipProps = isSaved
    ? undefined
    : ({
        text: 'Unsaved View',
        side: 'bottom',
      } as TooltipProps);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cx(styles.tab, isSelected ? styles.tabSelected : styles.tabDefault)}>
      <EditableText
        icon={OPERATION_ICON_MAP[view.visualization || OPERATION_TYPES.VISUALIZE_TABLE]}
        isRenaming={isRenaming}
        onRename={(name) => dispatch(updateViewName({ viewId: view.id, name: name }))}
        onRenamingChange={setIsRenaming}
        onSingleClick={() => dispatch(setCurrentView(view.id))}
        placeholder="Untitled View"
        ref={ref}
        tooltipProps={tooltipProps}
        triggerChildren={isSaved ? undefined : ' •'}
        value={view.name}>
        <ViewTabMenu
          canDelete={canDelete}
          onDelete={() => dispatch(deleteView(view.id))}
          onDuplicate={() => dispatch(createView({ ...view, name: `${view.name} Copy` }))}
          onRename={() => setIsRenaming(true)}
        />
      </EditableText>
    </div>
  );
});

interface ViewTabProps {
  view: CustomerReportView;
}

/** Dummy version of ViewTab meant to be shown when dragging */
export const ViewTab = forwardRef<HTMLDivElement, ViewTabProps>(function ViewTab({ view }, ref) {
  return (
    <div className={cx(styles.tab, styles.tabSelected)} ref={ref}>
      <EditableText placeholder="Untitled View" value={view.name}>
        <ViewTabMenu
          canDelete={false}
          onDelete={() => null}
          onDuplicate={() => null}
          onRename={() => null}
        />
      </EditableText>
    </div>
  );
});
