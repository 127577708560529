import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { sprinkles, Input } from 'components/ds';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { findMatchingAgg } from 'utils/aggUtils';
import { getColorColumns } from 'utils/colorColUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  firstColOnly?: boolean;
};

export const DataLabelsConfig: FC<Props> = ({ visualizationType, instructions, firstColOnly }) => {
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2TwoDimensionChartInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  let aggCols = instructions.aggColumns;
  if (firstColOnly) aggCols = aggCols?.slice(0, 1);

  return (
    <div className={rootClass}>
      <div className={sprinkles({ body: 'b3', color: 'contentSecondary' })}>
        This affects how data is displayed on the legend and interactive tooltip.
      </div>
      {getColorColumns(instructions).map((col) => {
        const label = getColDisplayText(col) ?? '';
        return (
          <Input
            showInputButton
            defaultValue={col.column.friendly_name || label}
            key={`data-label-color-${col.column.name}`}
            label={{ text: label, variableInput: true }}
            onSubmit={(newValue) => {
              const newInstructions = produce(instructions, (draft) => {
                const changedCol = draft.colorColumnOptions?.find(
                  (newCol) => newCol.column.name === col.column.name,
                );
                if (changedCol) changedCol.column.friendly_name = newValue;
              });

              updateInstructions(newInstructions);
            }}
          />
        );
      })}

      {aggCols?.map((col) => {
        const label = getColDisplayText(col) ?? '';
        return (
          <Input
            showInputButton
            defaultValue={col.column.friendly_name || label}
            key={`data-label-agg-${col.column.name}`}
            label={{ text: label, variableInput: true }}
            onSubmit={(newValue) => {
              const newInstructions = produce(instructions, (draft) => {
                const changedCol = findMatchingAgg(draft.aggColumns, col.column.name, col.agg);

                if (changedCol) changedCol.column.friendly_name = newValue;
              });
              updateInstructions(newInstructions);
            }}
          />
        );
      })}
    </div>
  );
};

const rootClass = sprinkles({
  marginY: 'sp1.5',
  paddingX: 'sp1.5',
  flexItems: 'column',
  gap: 'sp1.5',
});
