import { FC, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { EmbedDataGrid } from 'components/embed';
import { UseEmbedColumnsParams } from 'components/embed/EmbedDataGrid/useEmbedColumns';
import { SortInfo } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportDataGrid } from 'pages/ReportBuilder/ReportView/ReportChart/ReportDataGrid';
import { getDataGridSort } from 'pages/ReportBuilder/ReportView/ReportChart/utils';
import { useReportColumns } from 'pages/ReportBuilder/ReportView/useReportColumns';
import { isSuccess, isLoading } from 'remotedata';
import {
  getCurrentViewData,
  getCurrentColorTracker,
  getDrilldownData,
  getDrilldownConfig,
  setDrilldownPage,
  updateDrilldownSort,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { getSchemaAndColConfigs } from 'utils/customerReportUtils';

import { GRID_ROW_HEIGHT } from '../constants';

type Props = {
  dataInfo: CustomerReportDataInfo;
  view: CustomerReportView;
};

export const DrilldownContent: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { versionConfig, viewData, colorCategoryTracker, drilldownData, drilldownConfig } =
    useSelector(
      (state: ReportBuilderReduxState) => ({
        versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
        viewData: getCurrentViewData(state.reportEditing),
        colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
        drilldownData: getDrilldownData(state.reportEditing),
        drilldownConfig: getDrilldownConfig(state.reportEditing),
      }),
      shallowEqual,
    );

  const dataset = versionConfig?.datasets[dataInfo.datasetId];

  const drilldownConfigs = useMemo(
    () =>
      getSchemaAndColConfigs(
        dataset,
        dataset?.schema?.map((col) => col.name) || [],
        view?.hiddenColumns || [],
        EMPTY_SCHEMA_INFO,
      ),
    [view?.hiddenColumns, dataset],
  );

  const drilldownPaginatorProps: PaginatorProps = useMemo(() => {
    const totalRowCount = isSuccess(drilldownData.rowCount)
      ? drilldownData.rowCount.data
      : undefined;

    return {
      totalRowCount,
      currentPage: drilldownData.page,
      loading: isLoading(drilldownData.rowCount),
      goToPage: ({ page }) => dispatch(setDrilldownPage(page)),
    };
  }, [drilldownData.rowCount, drilldownData.page, dispatch]);

  const drilldownSortInfo = useMemo(
    () => getDataGridSort(drilldownConfig?.sort),
    [drilldownConfig?.sort],
  );

  const handleSortDrilldownColumn = useCallback(
    (sort: SortInfo[]) => dispatch(updateDrilldownSort(sort)),
    [dispatch],
  );

  const drilldownColumnParams: UseEmbedColumnsParams = useMemo(
    () => ({
      rows: drilldownData.rows || [],
      schema: drilldownConfigs.schema,
      shouldTruncateText: true,
      disableCustomStyles: true,
      onSortColumn: handleSortDrilldownColumn,
      colorTracker: colorCategoryTracker,
      containerRef,
      columnConfigs: drilldownConfigs.columnConfigs,
    }),
    [
      drilldownData.rows,
      drilldownConfigs.schema,
      drilldownConfigs.columnConfigs,
      handleSortDrilldownColumn,
      colorCategoryTracker,
    ],
  );

  const drilldownColumns = useReportColumns(drilldownColumnParams);

  const renderError = (error: string) => {
    return (
      <div className={errorDivStyles}>
        <EmbedText body="b1" color="contentTertiary">
          {error}
        </EmbedText>
      </div>
    );
  };

  return (
    <>
      {drilldownData.error ? (
        renderError(drilldownData.error)
      ) : !drilldownConfig?.filters?.length ? (
        // Render the normal grid when there are no filters
        <ReportDataGrid
          containerRef={containerRef}
          dataset={dataset}
          reportData={viewData}
          view={view}
        />
      ) : (
        <EmbedDataGrid
          {...drilldownColumnParams}
          {...drilldownConfigs}
          columns={drilldownColumns}
          key={view.id} // Resizing a column in one view affects the widths of columns in other views. Set key to rerender when changing views
          loading={drilldownData.isLoading}
          paginatorProps={drilldownPaginatorProps}
          rowHeight={GRID_ROW_HEIGHT}
          rows={drilldownData.rows || []}
          sortInfo={drilldownSortInfo}
        />
      )}
    </>
  );
};

const EMPTY_SCHEMA_INFO = {
  groupBys: [],
  aggs: [],
  columnGroupBys: [],
  scatterPlotGrouping: undefined,
};

const errorDivStyles = sprinkles({
  borderTop: 1,
  borderColor: 'outline',
  flexItems: 'center',
  height: 'fill',
});
