import { FC, useMemo } from 'react';

import { DateElemConfig, DATE_FILTER_TYPE, DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';

import { getUpdateConfigFunc } from '../utils';

import { DatePickerConfig } from './DatePickerConfig';
import { RelativeDatesDropdownConfig } from './RelativeDatesDropdownConfig';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateValuesConfig: FC<Props> = ({ config, dateFilterType, updateDateConfig }) => {
  const updateConfig = useMemo(
    () => getUpdateConfigFunc(config, updateDateConfig),
    [config, updateDateConfig],
  );

  const shouldRenderDatePickerConfig =
    !config.excludeDatePicker || dateFilterType === DASHBOARD_ELEMENT_TYPES.DATEPICKER;
  const shouldRenderDropdownListsConfig =
    dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER && !!config.includeRangeDropdown;
  const shouldRenderHeaders = shouldRenderDatePickerConfig && shouldRenderDropdownListsConfig;

  return (
    <>
      {shouldRenderDatePickerConfig ? (
        <DatePickerConfig
          config={config}
          shouldRenderHeader={shouldRenderHeaders}
          updateConfigFn={updateConfig}
        />
      ) : null}
      {shouldRenderDropdownListsConfig ? (
        <RelativeDatesDropdownConfig
          config={config}
          shouldRenderHeader={shouldRenderHeaders}
          updateConfigFn={updateConfig}
        />
      ) : null}
    </>
  );
};
