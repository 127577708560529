import marked from 'marked';

interface Props {
  markdownText: string;
}

const Markdown = (props: Props) => {
  const { markdownText } = props;
  const rawMarkup = marked(markdownText);

  return <div dangerouslySetInnerHTML={{ __html: rawMarkup }} />;
};

export default Markdown;
