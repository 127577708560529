import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  updateFilterValue,
  updateFilterValueSource,
  updateFilterValueVariable,
} from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { FilterValueInput } from 'components/FilterValueInput';
import { sprinkles } from 'components/ds';
import { FilterClause, FilterValueSourceType, FilterValueType } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import DropdownSelect from 'shared/DropdownSelect';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { filterForValidFilterElementsBasedOnType } from 'utils/dashboardUtils';
import { filterDataPanelsForValidDrilldownVarsBasedOnType } from 'utils/drilldownUtils';
import { parseVariableId } from 'utils/variableUtils';

const VALUE_SOURCE_TYPE_OPTIONS = {
  [FilterValueSourceType.INPUT]: {
    name: 'Manual',
    id: FilterValueSourceType.INPUT,
  },
  [FilterValueSourceType.VARIABLE]: {
    name: 'Variable',
    id: FilterValueSourceType.VARIABLE,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    gap: 4,
  },
  varOrValDropdown: {
    marginRight: -1,

    '& .bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  variableDropdown: {
    '& .bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: 190,
    },
  },
  inputFilter: {
    width: `194px !important`,
  },
}));

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  dashboardElements?: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  dataPanels: DataPanelTemplate[];
  datasets: Record<string, Dataset>;
  operatorDropdownClass?: string;
  filterValue?: FilterValueType;
};

export const FilterConfigMenu: FC<Props> = ({
  clauseIdx,
  operatorDropdownClass,
  clause,
  dashboardElements,
  dashboardParams,
  dataPanels,
  datasets,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { archetypeProperties, showArchetypeVariables, variables } = useSelector(
    (state: ReduxState) => ({
      archetypeProperties: state.teamData.data?.archetype_properties,
      showArchetypeVariables: state.currentUser.team?.feature_flags.use_archetype_variable_filters,
      variables: state.dashboardData.variables,
    }),
    shallowEqual,
  );
  const { filterValueVariableProperty, filterValueVariableId, filterValue } = clause;
  const selectedOperator = clause.filterOperation?.id;

  const drilldownVarOptions = filterDataPanelsForValidDrilldownVarsBasedOnType(
    dataPanels,
    selectedOperator,
    datasets,
    variables,
  );

  const variableOptions = filterForValidFilterElementsBasedOnType(
    dashboardElements,
    dashboardParams,
    selectedOperator,
    showArchetypeVariables ? archetypeProperties : undefined,
  ).concat(drilldownVarOptions);

  const selectedSourceType = clause.filterValueSource || FilterValueSourceType.INPUT;
  const selectedVarId = filterValueVariableProperty
    ? `${filterValueVariableId}.${filterValueVariableProperty}`
    : filterValueVariableId;

  return (
    <div className={cx(sprinkles({ display: 'flex', alignItems: 'flex-start' }), classes.root)}>
      <DropdownSelect
        fillWidth
        minimal
        usePortal
        containerClassName={cx(classes.varOrValDropdown, operatorDropdownClass)}
        disabled={
          variableOptions.length === 0 && selectedSourceType === FilterValueSourceType.INPUT
        }
        filterable={false}
        noSelectionText="Var"
        onChange={(newValue) =>
          dispatch(
            updateFilterValueSource({
              index: clauseIdx,
              newSource: newValue.id as FilterValueSourceType,
            }),
          )
        }
        options={[
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.INPUT],
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.VARIABLE],
        ]}
        selectedItem={VALUE_SOURCE_TYPE_OPTIONS[selectedSourceType]}
      />
      {selectedSourceType === FilterValueSourceType.INPUT && (
        <div className={classes.inputFilter}>
          <FilterValueInput
            disabled={!clause.filterColumn}
            filterOperator={selectedOperator}
            filterValue={filterValue}
            onFilterValueUpdate={(value) =>
              dispatch(updateFilterValue({ index: clauseIdx, value }))
            }
          />
        </div>
      )}
      {selectedSourceType === FilterValueSourceType.VARIABLE && (
        <DropdownSelect
          fillWidth
          minimal
          usePortal
          containerClassName={classes.variableDropdown}
          filterable={false}
          noSelectionText="Select Variable"
          onChange={({ id }) => {
            const { variableId, property } = parseVariableId(id);
            dispatch(updateFilterValueVariable({ index: clauseIdx, variableId, property }));
          }}
          options={variableOptions}
          selectedItem={
            selectedVarId && variableOptions.findIndex((v) => v.id === selectedVarId) !== -1
              ? { id: selectedVarId, name: selectedVarId }
              : undefined
          }
        />
      )}
    </div>
  );
};
