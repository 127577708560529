import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { Customer } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { makeThunkRequest } from 'utils/thunkUtils';

import { deleteDataSourceInFido, deleteNamespace, updateNamespace } from './fidoThunks';

export const syncSchema =
  (
    args: {
      edited_schemas: ParentSchema[];
    },
    onSuccess: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => void,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { currentUser, fido } = getState();

    const fidoSuccessCallback = () => {
      const { fidoDaos } = fido;

      if (!RD.isSuccess(fidoDaos)) return;

      fidoDaos.data.namespaces.forEach((n) => {
        const schema = args.edited_schemas.find((s) => s.fido_id === n.fido_id);

        // delete case
        if (!schema) dispatch(deleteNamespace({ namespaceId: n.fido_id ?? '' }));
        // edit case
        else if (schema?.name)
          dispatch(updateNamespace({ namespace: { name: schema.name, id: n.fido_id ?? '' } }));
      });
    };

    dispatch(
      syncSchemaThunk({
        args,
        onSuccess: () => {
          if (currentUser.team?.feature_flags.use_fido) fidoSuccessCallback();
          onSuccess();
        },
        onError,
      }),
    );
  };

export const syncSchemaThunk = createAsyncThunk<
  {
    parent_schemas: ParentSchema[];
    data_sources: DataSource[];
    customers: Customer[];
    error_msg: string;
  },
  {
    args: {
      edited_schemas: ParentSchema[];
    };
    onSuccess: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => void;
  },
  { state: ReduxState }
>(ACTION.SYNC_PARENT_SCHEMA, async ({ args, onSuccess, onError }) =>
  makeThunkRequest(
    createApiRequestConfig('schema/sync_schema/', 'POST', args),
    'Error updating schema',
    { onSuccess, onError },
  ),
);

export const deleteDataSource =
  (
    id: number,
    onSuccess: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => void,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { currentUser, fido } = getState();

    const fidoSuccessCallback = () => {
      const { fidoDaos } = fido;

      if (!RD.isSuccess(fidoDaos)) return;

      const dataSource = fidoDaos.data.dataSources.find((d) => d.id === id);

      if (!dataSource?.fido_id) return;

      const namespace = fidoDaos.data.namespaces.find((n) => n.id === dataSource.parent_schema_id);

      if (!namespace?.fido_id) return;

      dispatch(
        deleteDataSourceInFido({
          dataSourceId: dataSource.fido_id,
          namespaceId: namespace.fido_id,
        }),
      );
    };

    dispatch(
      deleteDataSourceThunk({
        id,
        onSuccess: () => {
          if (currentUser.team?.feature_flags.use_fido) fidoSuccessCallback();
          onSuccess();
        },
        onError,
      }),
    );
  };

export const deleteDataSourceThunk = createAsyncThunk<
  {},
  {
    id: number;
    onSuccess: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => void;
  },
  { state: ReduxState }
>(ACTION.DELETE_DATA_SOURCE, async ({ id, onSuccess, onError }) =>
  makeThunkRequest(
    createApiRequestConfig(`datasources/${id}/`, 'DELETE', undefined),
    'Error deleting data source',
    { onSuccess, onError },
  ),
);
