import { useState, FC } from 'react';
import { useSelector } from 'react-redux';

import { ReportBuilder } from 'actions/reportBuilderActions';
import { VersionControlModal } from 'components/VersionControlModal';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, sprinkles } from 'components/ds';
import { SavingInfo } from 'pages/dashboardPage/SavingInfo';
import { ReduxState } from 'reducers/rootReducer';
import { EditResourceBannerDropdown } from 'shared/ExploResource/EditResourceBannerDropdown';
import { SIDE_PANE_HEADER_HEIGHT } from 'shared/ExploResource/constants';
import { Breadcrumb, ResourcePageType } from 'types/exploResource';

type Props = {
  reportBuilder: ReportBuilder;
  breadcrumbs: Breadcrumb[];
};

const resourceBannerClass = sprinkles({
  borderBottom: 1,
  borderRight: 1,
  borderColor: 'gray7',
});

export const Header: FC<Props> = ({ reportBuilder, breadcrumbs }) => {
  const versionInfo = useSelector((state: ReduxState) => state.reportBuilderEdit.versionInfo);

  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const renderVersionControlModal = () => {
    if (!isVersionModalOpen) return null;

    return (
      <VersionControlModal
        closeModal={() => setIsVersionModalOpen(false)}
        pageType={ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  return (
    <div className={rootClass} style={{ height: SIDE_PANE_HEADER_HEIGHT }}>
      <EditResourceBannerDropdown
        breadcrumbs={breadcrumbs}
        className={resourceBannerClass}
        pageType={ResourcePageType.REPORT_BUILDER}
        resource={reportBuilder}
      />
      <div className={editContainerClass}>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          <div className={sprinkles({ marginRight: 'sp1' })}>Viewing as</div>
          <ViewingAsCustomerSelector parseUrl />
        </div>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          {versionInfo ? (
            <>
              <SavingInfo
                isDraft={versionInfo.is_draft}
                resourceType={ResourcePageType.REPORT_BUILDER}
                versionNumber={versionInfo.version_number}
              />
              <Button onClick={() => setIsVersionModalOpen(true)} variant="secondary">
                Version Control
              </Button>
            </>
          ) : null}
        </div>
      </div>
      {renderVersionControlModal()}
    </div>
  );
};

const rootClass = sprinkles({
  width: 'fill',
  display: 'flex',
  backgroundColor: 'white',
  borderBottom: 1,
  borderColor: 'gray7',
});

const editContainerClass = sprinkles({
  flex: 1,
  flexItems: 'alignCenterBetween',
  paddingX: 'sp1.5',
});
