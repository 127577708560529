import { FC, useMemo } from 'react';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { ExplorePivotTable } from 'components/embed/EmbedPivotTable/ExplorePivotTable';
import {
  PIVOT_SUMMARY_COLUMN_HEADER,
  extractSummaryRows,
  ensureProperPivotColumnSorting,
} from 'components/embed/EmbedPivotTable/pivotUtils';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { DataPanelData } from 'types/dataPanelTemplate';
import { getExplorePivotSchemaAndConfigs } from 'utils/V2ColUtils';
import { isPivotTableV2ReadyToDisplay } from 'utils/dataPanelConfigUtils';
import { keyBy } from 'utils/standard';

import { NeedsConfigurationPanel } from '../../needsConfigurationPanel';

type Props = {
  instructions: V2PivotTableInstructions | undefined;
  data: DataPanelData | undefined;
};

export const PivotTableV2: FC<Props> = ({ instructions, data }) => {
  const { schema, columnConfigs, pivotColumns, groupByColumns } = useMemo(
    () => getExplorePivotSchemaAndConfigs(instructions),
    [instructions],
  );

  const nameSchemaMap = useMemo(() => keyBy(schema || [], 'name'), [schema]);

  const isRowLimitReached = useMemo(
    () => (data?.rows || [])?.length >= PIVOT_ROW_LIMIT,
    [data?.rows],
  );

  const { keyPathToSummary, rows } = useMemo(() => {
    const hasRowInstructions = groupByColumns?.length;
    const hasColumnInstructions = !!instructions?.colGroupBys?.length && !!pivotColumns?.length;
    const hasAggInstructions = !!instructions?.aggregations?.length;
    if (
      !data?.rows?.length ||
      !hasRowInstructions ||
      !hasColumnInstructions ||
      !hasAggInstructions
    ) {
      return { keyPathToSummary: {}, rows: data?.rows };
    }

    const { keyPathToSummary, rows } = instructions?.includeRollup
      ? extractSummaryRows(data.rows, nameSchemaMap, groupByColumns, columnConfigs)
      : { keyPathToSummary: {}, rows: data.rows };

    const needsToSortPivotColumns = hasColumnInstructions && hasAggInstructions;

    return {
      keyPathToSummary,
      rows: needsToSortPivotColumns
        ? ensureProperPivotColumnSorting(
            rows,
            pivotColumns[0],
            instructions.colGroupBys[0],
            nameSchemaMap,
            groupByColumns,
          )
        : rows,
    };
  }, [
    data?.rows,
    nameSchemaMap,
    groupByColumns,
    columnConfigs,
    pivotColumns,
    instructions?.includeRollup,
    instructions?.colGroupBys,
    instructions?.aggregations?.length,
  ]);

  if (!isPivotTableV2ReadyToDisplay(instructions)) {
    return <NeedsConfigurationPanel instructionsNeedConfiguration />;
  }

  return (
    <ExplorePivotTable
      columnConfigs={columnConfigs}
      groupByColumns={groupByColumns ?? []}
      includeRollup={!!instructions?.includeRollup}
      isRowLimitReached={isRowLimitReached}
      keyPathToSummary={keyPathToSummary}
      loading={data?.loading ?? true}
      pivotColumns={pivotColumns ?? []}
      rows={rows}
      schema={schema}
      summaryColumnName={instructions?.summaryColumnName || PIVOT_SUMMARY_COLUMN_HEADER}
    />
  );
};
