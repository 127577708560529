import { FC } from 'react';

import LoadingSpinner from 'images/loading_spinner.gif';

import { sprinkles } from './ds';

export const ExploLoadingSpinner: FC = () => {
  return (
    <div className={sprinkles({ height: 'fillViewport', width: 'fill', flexItems: 'center' })}>
      <img
        alt="loading spinner"
        className={sprinkles({ zIndex: 2 })}
        src={LoadingSpinner}
        style={{ width: 75 }}
      />
    </div>
  );
};
