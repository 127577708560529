import { FC } from 'react';

import { InfoCard } from 'components/InfoCard';
import { SettingHeader } from 'components/SettingHeader';
import { sprinkles } from 'components/ds';
import { DashboardButtonElemConfig, DashboardElementConfig } from 'types/dashboardTypes';

import { EmbedButtonConfigPanel } from './EmbedButtonConfigPanel';

type Props = {
  config: DashboardButtonElemConfig;

  updateConfig: (newConfig: DashboardElementConfig) => void;
};

export const RefreshButtonConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="General" />
      <div className={sprinkles({ marginY: 'sp1.5' })}>
        <div className={sprinkles({ paddingX: 'sp1.5' })}>
          <InfoCard
            error
            text={
              <div>
                <b>Warning:</b> This component may cause undesired behavior if the cache is enabled.
                For more information, visit{' '}
                <a href="https://docs.explo.co/dashboard-features/caching/caching#how-caching-works-in-practice">
                  this page.
                </a>
              </div>
            }
          />
        </div>
        <EmbedButtonConfigPanel
          config={config as DashboardButtonElemConfig}
          defaultText="Refresh Dashboard"
          updateConfig={updateConfig}
        />
      </div>
    </div>
  );
};
