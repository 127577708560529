import { User } from 'actions/userActions';

type CalendlyScripts = { script: HTMLScriptElement; style: HTMLLinkElement };

export function addCalendlyScripts(): CalendlyScripts {
  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.async = true;
  script.type = 'text/javascript';
  document.body.appendChild(script);

  const style = document.createElement('link');
  style.href = 'https://assets.calendly.com/assets/external/widget.css';
  style.rel = 'stylesheet';
  document.body.appendChild(style);

  return { script, style };
}

export function removeCalendlyScripts({ script, style }: CalendlyScripts): void {
  document.body.removeChild(script);
  document.body.removeChild(style);
}

function getCalendlyInfo({ first_name, last_name, email }: User): string {
  const name = encodeURIComponent(`${first_name} ${last_name}`);
  return `?name=${name}&email=${email}`;
}

export function openActivationCalendly(user: User): void {
  if (window.Calendly) {
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/tryexplo/explo-activation-sync' + getCalendlyInfo(user),
    });
  } else {
    // The Calendly is set up to redirect back to https://app.explo.co/settings/billing.
    window.open('https://explo.co/sales-activate-plan', '_blank');
  }
}
