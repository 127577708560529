import parse from 'url-parse';

import { SelectedReportType, ReportType } from 'reportBuilderContent/reducers/types';

import { ReportBuilderTab } from '../HomeView/constants';

const REPORT_KEY = 'report';
const VIEW_KEY = 'view';
const HOME_TAB_KEY = 'homeTab';

export const goToReportView = (
  selectedReport: SelectedReportType | null,
  viewId: string | null,
) => {
  if (selectedReport?.type !== ReportType.CUSTOMER_REPORT || !window?.location) return;
  const url = new URL(window.location.href);
  url.searchParams.set(REPORT_KEY, String(selectedReport.id));
  url.searchParams.set(VIEW_KEY, String(viewId));
  url.searchParams.delete(HOME_TAB_KEY);
  window.history.replaceState(null, '', url);
};

const getQueryParams = () => parse(window.location.href, true).query;

export const goToHomePageTab = (tabId: ReportBuilderTab) => {
  if (!window?.location) return;
  const url = new URL(window.location.href);
  url.searchParams.delete(REPORT_KEY);
  url.searchParams.delete(VIEW_KEY);
  url.searchParams.set(HOME_TAB_KEY, tabId);
  window.history.replaceState(null, '', url);
};

export const getRBStateFromURL = () => {
  const queryParams = getQueryParams();
  const state: {
    homeTab?: string | undefined;
    reportId?: number | undefined;
    viewId?: string | undefined;
  } = {};

  if (queryParams[HOME_TAB_KEY]) {
    state.homeTab = parsePossibleJSON(queryParams[HOME_TAB_KEY]);
  } else if (queryParams[REPORT_KEY]) {
    state.reportId = parsePossibleJSON(queryParams[REPORT_KEY]);
    if (queryParams[VIEW_KEY]) {
      state.viewId = parsePossibleJSON(queryParams[VIEW_KEY]);
    }
  }

  return state;
};

const parsePossibleJSON = (s: string) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return s;
  }
};
