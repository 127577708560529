import { QueryDebuggingInformation } from 'actions/responseTypes';
import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  PivotOperationInstructions,
  SortOrder,
  VisualizeOperationGeneralFormatOptions,
  VisualizeOperationInstructions,
} from 'constants/types';
import { ResponseData } from 'remotedata';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';

interface BaseOperation {
  operation_type: OPERATION_TYPES;
}

export type FilterOperation = BaseOperation & {
  instructions: FilterOperationInstructions;
};

export type PivotOperation = BaseOperation & {
  instructions: PivotOperationInstructions;
};

export type VisualizeOperation = BaseOperation & {
  instructions: VisualizeOperationInstructions;
  generalFormatOptions?: VisualizeOperationGeneralFormatOptions;
};

export interface SortInfo_DEPRECATED {
  column_name: string;
  order: SortOrder;
}

export interface AdHocOperationInstructions {
  currentPage?: number;
  sortInfo?: SortInfo_DEPRECATED | SortInfo_DEPRECATED[];
  filterInfo?: FilterOperationInstructions;
}

export interface DataPanelTemplate {
  // BE Model
  id: string;
  provided_id: string; // provided id to reference data panel as variable
  container_id?: string;
  table_id: string | number;
  filter_op: FilterOperation;
  group_by_op: PivotOperation;
  visualize_op: VisualizeOperation;
}

export interface DataPanelData {
  // Set in FE
  loading: boolean;
  outstandingSecondaryDataRequests: number;
  adHocOperationInstructions?: AdHocOperationInstructions;
  secondaryData?: DatasetRow[];
  // Attached from BE
  error?: string;
  schema?: DatasetSchema;
  rows?: DatasetRow[];
  totalRowCount?: ResponseData<number>;
  sourceType?: string;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
  queryInformation?: QueryDebuggingInformation;
  // Used to protect against race conditions
  loadingRequestId?: string;
}

export const initDataPanelData = (loading = false): DataPanelData => ({
  loading,
  outstandingSecondaryDataRequests: 0,
});

export interface DataPanelResponse {
  // Attached in BE
  _schema?: DatasetSchema;
  _rows?: DatasetRow[];
  _error?: string;
  _total_row_count?: number;
  _source_type?: string;
  _unsupported_operations?: DatabaseUnsupportedOperations[];
}
