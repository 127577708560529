import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUserTeam } from 'actions/teamActions';
import { pageView } from 'analytics/exploAnalytics';
import { sprinkles, Button } from 'components/ds';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import {
  SETTINGS_SUB_SECTION,
  SETTINGS_SUB_SECTIONS_PERMISSION_MAP,
  SUB_SECTION_ROUTE_MAP,
} from 'constants/settingsPageConstants';
import { ReduxState } from 'reducers/rootReducer';
import {
  addCalendlyScripts,
  removeCalendlyScripts,
  openActivationCalendly,
} from 'utils/calendlyUtils';

import { SettingsBillingSection } from './SettingsBillingSection';
import { SettingsInternationalizationSection } from './SettingsInternationalizationSection';
import { SettingsAccessGroupsSection } from './settingsAccessGroupsSection';
import { SettingsAnalyticsSection } from './settingsAnalyticsSection';
import { SettingsAttributesSection } from './settingsAttributesSection';
import { SettingsCustomFontsSection } from './settingsCustomFontsSection';
import { SettingsDomainAllowlistSection } from './settingsDomainAllowlistSection';
import { SettingsEmailSection } from './settingsEmailSection';
import { SettingsEnvironmentsSection } from './settingsEnvironmentsSection';
import { SettingsGroupTagsSection } from './settingsGroupTagsSection';
import { SettingsLeftNav } from './settingsLeftNav';
import { SettingsMembersSection } from './settingsMembersSection';
import { SettingsProfileSection } from './settingsProfileSection';
import { SettingsShareLinkSection } from './settingsShareLinkSection';

export const SettingsPage: FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ subsectionId: string }>();

  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  useEffect(() => dispatch(fetchUserTeam()), [dispatch]);

  useEffect(() => {
    pageView('Settings');
    const scripts = addCalendlyScripts();
    return () => removeCalendlyScripts(scripts);
  }, []);

  const renderBody = () => {
    let component = <SettingsProfileSection />;
    let subSection = SETTINGS_SUB_SECTION.PROFILE;

    switch (params.subsectionId) {
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.PROFILE]:
        component = <SettingsProfileSection />;
        subSection = SETTINGS_SUB_SECTION.PROFILE;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.MEMBERS]:
        component = <SettingsMembersSection />;
        subSection = SETTINGS_SUB_SECTION.MEMBERS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.API_TOKENS]:
        component = <SettingsAccessGroupsSection />;
        subSection = SETTINGS_SUB_SECTION.API_TOKENS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES]:
        component = <SettingsAttributesSection />;
        subSection = SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.GROUP_TAGS]:
        component = <SettingsGroupTagsSection />;
        subSection = SETTINGS_SUB_SECTION.GROUP_TAGS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.CUSTOM_FONTS]:
        component = <SettingsCustomFontsSection />;
        subSection = SETTINGS_SUB_SECTION.CUSTOM_FONTS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.ENVIRONMENTS]:
        component = <SettingsEnvironmentsSection />;
        subSection = SETTINGS_SUB_SECTION.ENVIRONMENTS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.ANALYTICS]:
        component = <SettingsAnalyticsSection />;
        subSection = SETTINGS_SUB_SECTION.ANALYTICS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING]:
        component = <SettingsDomainAllowlistSection />;
        subSection = SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.INTERNATIONALIZATION]:
        component = <SettingsInternationalizationSection />;
        subSection = SETTINGS_SUB_SECTION.INTERNATIONALIZATION;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.PORTALS_AND_SHARE_LINKS]:
        component = <SettingsShareLinkSection />;
        subSection = SETTINGS_SUB_SECTION.PORTALS_AND_SHARE_LINKS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.EMAILS]:
        component = <SettingsEmailSection />;
        subSection = SETTINGS_SUB_SECTION.EMAILS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.BILLING]:
        component = <SettingsBillingSection />;
        subSection = SETTINGS_SUB_SECTION.BILLING;
        break;
    }

    const permissionFn = SETTINGS_SUB_SECTIONS_PERMISSION_MAP[subSection];

    if (permissionFn && !permissionFn(currentUser.permissions)) return <SettingsProfileSection />;

    return component;
  };

  return (
    <div
      className={sprinkles({
        backgroundColor: 'white',
        parentContainer: 'fill',
        flexItems: 'column',
      })}>
      {currentUser.team?.payment_plan === PLAN_TYPES.DEACTIVATED ? (
        <div className={bannerClass}>
          <p className={sprinkles({ marginBottom: 'sp0' })}>
            Your team&rsquo;s workspace has been deactivated. Please contact sales to continue using
            Explo.
          </p>
          <Button
            icon="messages"
            onClick={() => openActivationCalendly(currentUser)}
            variant="primary">
            Contact Sales
          </Button>
        </div>
      ) : null}
      <div className={sprinkles({ display: 'flex', flex: 1, overflow: 'hidden' })}>
        <SettingsLeftNav selectedSubSection={params.subsectionId} />
        <div
          className={sprinkles({
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'center',
          })}>
          <div
            className={sprinkles({ padding: 'sp5', width: 'fill', height: 'fitContent' })}
            style={{ maxWidth: 720 }}>
            {renderBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

const bannerClass = sprinkles({
  flexItems: 'center',
  backgroundColor: 'blue10',
  color: 'white',
  padding: 'sp1',
  width: 'fill',
  gap: 'sp2',
});
