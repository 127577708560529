import { FC } from 'react';

import { sprinkles, Tag, Icon } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/DataSelection/PreviewColumns.css';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { FilterableColumn } from 'utils/customerReportUtils';

type Props = {
  onBack: () => void;
  columns: FilterableColumn[];
  datasetName: string;
};

export const PreviewColumns: FC<Props> = ({ datasetName, onBack, columns }) => {
  const renderColumn = (col: FilterableColumn) => {
    return (
      <div className={styles.column} key={col.name}>
        <div className={styles.columnInfo}>
          <Icon
            color="contentTertiary"
            name={SCHEMA_TYPE_ICON_MAP[col.type] || 'calendar'}
            size="sm"
          />
          <EmbedText body="b1" className={styles.colName}>
            {col.display}
          </EmbedText>
        </div>
        {col.description ? (
          <div className={sprinkles({ marginTop: 'sp1' })}>
            <EmbedText body="b2" color="contentTertiary">
              {col.description}
            </EmbedText>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.columnsCol}>
      <div className={styles.columnsHeading}>
        <EmbedButton
          color="contentTertiary"
          icon="arrow-left"
          onClick={onBack}
          variant="tertiary"
        />
        <EmbedText color="contentTertiary" heading="h3">
          {datasetName}
        </EmbedText>
        <Tag className={sprinkles({ body: 'b2', color: 'contentTertiary' })}>
          {String(columns.length)} columns
        </Tag>
      </div>
      <div className={styles.columnList}>{columns.map(renderColumn)}</div>
    </div>
  );
};
