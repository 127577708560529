import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DATE_ELEMENTS } from 'constants/dashboardConstants';
import { deleteVariablesThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { FILTER_OPS_DATE_PICKER, FILTER_OPS_DATE_RANGE_PICKER } from 'types/filterOperations';

import * as styles from '../styles.css';
import { getUpdateConfigFunc, isFilterUsedInDataPanel } from '../utils';

type Props = {
  config: DateElemConfig;
  dataPanels?: Record<string, DataPanel>;
  dateFilterType: DATE_FILTER_TYPE;
  elementName: string;

  updateDateConfig: (newConfig: DateElemConfig) => void;
  updateType: (newType: DATE_FILTER_TYPE) => void;
};

export const DateFilterTypeConfig: FC<Props> = ({
  config,
  dataPanels,
  dateFilterType,
  elementName,
  updateDateConfig,
  updateType,
}) => {
  const dispatch = useDispatch();

  const { includeRangeDropdown, excludeDatePicker } = config;

  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  const isFilterUsed = useMemo(() => {
    return isFilterUsedInDataPanel(dataPanels, elementName, (operationId) => {
      if (dateFilterType === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
        if (!FILTER_OPS_DATE_PICKER.has(operationId)) return false;
      } else if (!FILTER_OPS_DATE_RANGE_PICKER.has(operationId)) {
        return false;
      }
      return true;
    });
  }, [elementName, dateFilterType, dataPanels]);

  const renderOption = (title: string, includeRange: boolean, excludePicker: boolean) => {
    const isSelected =
      !!includeRangeDropdown === includeRange && !!excludeDatePicker === excludePicker;
    return (
      <div
        className={cx(styles.option, { [styles.selectedOption]: isSelected })}
        onClick={() =>
          updateConfig((draft) => {
            draft.includeRangeDropdown = includeRange;
            draft.excludeDatePicker = excludePicker;
          })
        }>
        {title}
      </div>
    );
  };

  return (
    <>
      <div className={cx(styles.optionRow, styles.configInput)}>
        {DATE_ELEMENTS.map((dateType) => {
          const isSelected = dateFilterType === dateType;
          const isDisabled = !isSelected && isFilterUsed;
          return (
            <div
              className={cx(styles.option, {
                [styles.selectedOption]: isSelected,
                [styles.disabledOption]: isDisabled,
              })}
              key={`option-${dateType}`}
              onClick={() => {
                if (dateFilterType === dateType || isDisabled) return;
                dispatch(deleteVariablesThunk([elementName]));
                updateType(dateType);
              }}>
              {dateType === DASHBOARD_ELEMENT_TYPES.DATEPICKER ? 'Single Date' : 'Date Range'}
            </div>
          );
        })}
      </div>
      {dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER ? (
        <>
          <div className={styles.headerClass}>Selection UI</div>
          <div className={styles.optionRow}>
            {renderOption('Calendar Selector', false, false)}
            {renderOption('Pre-set Dropdown', true, true)}
            {renderOption('Both', true, false)}
          </div>
        </>
      ) : null}
    </>
  );
};
