import { Layout } from '@explo-tech/react-grid-layout';
import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { EnvironmentTag } from 'actions/environmentTagActions';
import { OPERATION_TYPES } from 'constants/types';
import {
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  VIEW_MODE,
  DASHBOARD_LAYOUT_CONFIG,
} from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import { ACTION } from './types';

type CreateDashboardDataPanelArgs = {
  id: string;
  newLayout: Layout[];
  datasetId: string;
  vizType: OPERATION_TYPES;
  containerId?: string;
  name?: string;
};

export const createDashboardDataPanel = createAction<CreateDashboardDataPanelArgs>(
  ACTION.CREATE_DASHBOARD_DATA_PANEL,
);

type DuplicateDashboardItemArgs = {
  dashboardItem: DataPanelTemplate | DashboardElement;
  itemType: DASHBOARD_ELEMENT_TYPES;
  dashId: number;
};

export const duplicateDashboardItem = createAction<DuplicateDashboardItemArgs>(
  ACTION.DUPLICATE_DASHBOARD_ITEM,
);

export const createDashboardDataset = createAction<{
  name: string;
  dashId: number;
  parentSchemaId: number;
}>(ACTION.CREATE_DATASET);

export const deleteDataPanel = createAction<{ id: string }>(ACTION.DELETE_DATA_PANEL);

type UpdateElementConfigArgs = {
  elementId: string;
  newElementType?: DASHBOARD_ELEMENT_TYPES;
  config?: DashboardElementConfig;
};

export const updateElementConfig = createAction<UpdateElementConfigArgs>(
  ACTION.UPDATE_ELEMENT_CONFIG,
);

export const updateElementLocation = createAction<{
  elementId: string;
  newLocation: DASHBOARD_LAYOUT_CONFIG;
}>(ACTION.UPDATE_ELEMENT_LOCATION);

type ToggleFilterLinkArgs = {
  dataPanelId: string;
  elementId: string;
};

export const toggleFilterLink = createAction<ToggleFilterLinkArgs>(ACTION.TOGGLE_FILTER_LINK);

export const deleteDashboardElement = createAction<{
  elementId: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
}>(ACTION.DELETE_DASHBOARD_ELEMENT);

export const {
  actionFn: createNewDashboardVersion,
  successAction: createNewDashboardVersionSuccess,
} = defineAPIPostAction<
  { configuration?: DashboardVersionConfig },
  {
    dashboard_version: DashboardVersion;
  }
>(ACTION.CREATE_NEW_DASHBOARD_VERSION, 'dashboards', 'create_new_dashboard_version', 'POST');

type SwitchEditingDashboardVersionArgs = {
  dashboardVersion: DashboardVersion;
};

export const switchCurrentlyEditingDashboardVersion =
  createAction<SwitchEditingDashboardVersionArgs>(
    ACTION.SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION,
  );

type PublishNewDashboardVersionBody = {
  config: DashboardVersionConfig;
  version_number: number;
  change_comments: string;
};

type PublishNewDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
};

export const {
  actionFn: publishNewDashboardVersion,
  successAction: publishNewDashboardVersionSuccess,
} = defineAPIPostAction<PublishNewDashboardVersionBody, PublishNewDashboardVersionResponse>(
  ACTION.PUBLISH_NEW_DASHBOARD_VERSION,
  'dashboards',
  'publish_new_dashboard_version',
  'POST',
);

type FetchDashboardVersionsResponse = {
  versions: DashboardVersion[];
};

export const {
  actionFn: fetchDashboardVersions,
  requestAction: fetchDashboardVersionsRequest,
  successAction: fetchDashboardVersionsSuccess,
} = defineAPIAction<FetchDashboardVersionsResponse>(
  ACTION.FETCH_DASHBOARD_VERSIONS,
  'dashboards',
  'list_versions',
  'GET',
);

type DashboardVersionForPreview = Pick<DashboardVersion, 'id' | 'version_number' | 'is_draft'>;

export type FetchDashboardVersionsForPreviewResponse = {
  tags: EnvironmentTag[];
  versions: DashboardVersionForPreview[];
};

export const { actionFn: fetchDashboardVersionsForPreview } =
  defineAPIAction<FetchDashboardVersionsForPreviewResponse>(
    ACTION.FETCH_DASHBOARD_VERSIONS_FOR_PREVIEW,
    'dashboards',
    'list_versions_for_preview',
    'GET',
  );

export const saveDashboardElementUpdates = createAction<{
  id: string;
  config?: DashboardElementConfig;
  name?: string;
}>(ACTION.SAVE_DASHBOARD_ELEMENT_UPDATES);

type CreateDashboardElementArgs = {
  id: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
  newLayout: Layout[];
  containerId?: string;
};

export const createDashboardElement = createAction<CreateDashboardElementArgs>(
  ACTION.CREATE_DASHBOARD_ELEMENT,
);

export const clearDashboardConfigReducer = createAction(ACTION.CLEAR_DASHBOARD_CONFIG_REDUCER);

export const {
  actionFn: deleteCurrentDashboardDraft,
  successAction: deleteCurrentDashboardDraftSuccess,
} = defineAPIPostAction<{ version_number: number }, {}>(
  ACTION.DELETE_CURRENT_DASHBOARD_DRAFT,
  'dashboards',
  'delete_draft',
  'POST',
);

type ToggleElemVisibilityData = {
  id: string;
  layoutType: VIEW_MODE;
};

export const toggleElementVisibilityForSecondaryLayout = createAction<ToggleElemVisibilityData>(
  ACTION.TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT,
);

// if removeElem is false, it is an addition action
export const updateElementContainerLocation = createAction<{
  elementId: string;
  containerId: string;
  removeElem?: boolean;
  isDataPanel?: boolean;
}>(ACTION.UPDATE_ELEMENT_CONTAINER_LOCATION);
