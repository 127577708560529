import { makeStyles, Theme } from '@material-ui/core/styles';

import { GlobalStyleConfig } from 'globalStyles/types';

export const TABLE_ROW_HEIGHT = 30;
export const ROW_LEVEL_ACTIONS_ROW_HEIGHT = 50;

const useBaseDataTableStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  tableTheme: {
    width: '100%',

    '& .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header': {
      cursor: 'pointer',
    },
    '& .bp3-table-menu': {
      backgroundColor: theme.palette.ds.grey200,
    },
    '& .bp3-table-row-headers .bp3-table-header': {
      backgroundColor: theme.palette.ds.white,
    },

    '& .bp3-table-header:hover > .bp3-table-column-name': {
      backgroundColor: 'inherit',
    },

    '& .bp3-table-quadrant': {
      backgroundColor: 'transparent',
    },
    '& .bp3-table-truncated-text': {
      width: '100%',
    },
  },
  tableCell: (styleConfigAndProps: GlobalStyleConfig) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    '&.imageCell .bp3-table-truncated-text': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    '&.firstCell': {
      paddingLeft: styleConfigAndProps.container.padding.default,
    },

    '&.LEFT_ALIGN': {
      '& .bp3-table-truncated-text': {
        justifyContent: 'flex-start',
      },
    },
    '&.CENTER_ALIGN': {
      '& .bp3-table-truncated-text': {
        justifyContent: 'center',
      },
      textAlign: 'center',
    },
    '&.RIGHT_ALIGN': {
      '& .bp3-table-truncated-text': {
        justifyContent: 'flex-end',
      },
      textAlign: 'right',
    },
  }),
  columnHeaderCell: (styleConfigAndProps: GlobalStyleConfig) => ({
    paddingRight: 1,
    height: TABLE_ROW_HEIGHT,

    '&.firstCell .bp3-table-column-name-text': {
      paddingLeft: styleConfigAndProps.container.padding.default,
    },

    '& .bp3-table-column-name': {
      height: '100% !important',
    },
  }),
  tableContext: (styleConfig: GlobalStyleConfig) => ({
    '& .bp3-table-quadrant-scroll-container': {
      '&::-webkit-scrollbar': {
        borderLeft: `1px solid ${styleConfig.container.outline.color}`,
        width: 10,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: styleConfig.text.secondaryColor,
      },
    },
  }),
}));

export default useBaseDataTableStyles;
