import cx from 'classnames';
import { forwardRef } from 'react';

import { Input, Props } from 'components/ds/Input';

import * as styles from './index.css';

export type EmbedInputProps = Props & { minimalStyle?: boolean };

export const EmbedInput = forwardRef<HTMLInputElement, EmbedInputProps>(
  ({ className, minimalStyle, ...props }, ref) => {
    return (
      <Input
        className={cx(minimalStyle ? styles.minimalInput : styles.input, className)}
        ref={ref}
        {...props}
      />
    );
  },
);

EmbedInput.displayName = 'EmbedInput';
