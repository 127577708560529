import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, sprinkles } from 'components/ds';
import { INTRODUCING_REPORT_BUILDER_ICON } from 'components/ds/Icon/IntroductingReportBuilderIcon';
import dashboardsBackground from 'images/app/dashboardsBackground.png';
import { ReduxState } from 'reducers/rootReducer';
import {
  openActivationCalendly,
  addCalendlyScripts,
  removeCalendlyScripts,
} from 'utils/calendlyUtils';

import * as styles from './styles.css';

type Props = { pageTitle: 'Report Builder' | 'Dashboards' };

export const PromotionalPage: FC<Props> = ({ pageTitle }) => {
  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  useEffect(() => {
    const scripts = addCalendlyScripts();
    return () => removeCalendlyScripts(scripts);
  }, []);

  return (
    <div
      className={styles.promoPageRoot}
      style={{ backgroundImage: `url(${dashboardsBackground})` }}>
      <div className={styles.promoPageInfoRoot}>
        {INTRODUCING_REPORT_BUILDER_ICON}
        <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp4', paddingTop: 'sp2' })}>
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp1' })}>
            <div className={sprinkles({ heading: 'h2' })}>Introducing {pageTitle}</div>
            <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>
              Powerful, self-service reporting for your customers
            </div>
          </div>
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2' })}>
            <Button
              linkIcon
              to="https://www.loom.com/share/8c333f33d6394640af8786c9485a6846"
              variant="secondary">
              Watch a video
            </Button>
            <Button onClick={() => openActivationCalendly(currentUser)}>Request Access</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
