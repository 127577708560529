import produce from 'immer';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox, Icon, Popover, sprinkles } from 'components/ds';
import { FilterClause } from 'constants/types';
import { updateFilterConditionalConfig } from 'reducers/dashboardEditConfigReducer';
import { DataPanel } from 'types/exploResource';

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  panels: DataPanel[];
};

export const ConditionalFilterConfigMenu: FC<Props> = ({ panels, clause, clauseIdx }) => {
  const dispatch = useDispatch();

  const selectedPanelIds = useMemo(
    () => new Set(clause.conditionalFilterConfig?.chartsConditionalOn ?? []),
    [clause.conditionalFilterConfig?.chartsConditionalOn],
  );

  const selectedText = useMemo(() => {
    const selectedPanels = panels.filter((panel) => selectedPanelIds.has(panel.id));
    if (selectedPanels.length === 0) return 'No KPIs selected';
    if (selectedPanels.length === 1) return selectedPanels[0].provided_id;

    return `${selectedPanels.length} KPIs`;
  }, [selectedPanelIds, panels]);

  const updateConditionedPanel = (panelId: string) => {
    const newConfig = produce(clause.conditionalFilterConfig ?? {}, (draft) => {
      if (!draft.chartsConditionalOn) draft.chartsConditionalOn = [];
      if (selectedPanelIds.has(panelId)) {
        draft.chartsConditionalOn = draft.chartsConditionalOn.filter((id) => id !== panelId);
      } else {
        draft.chartsConditionalOn.push(panelId);
      }
    });
    dispatch(updateFilterConditionalConfig({ idx: clauseIdx, config: newConfig }));
  };

  return (
    <Popover
      align="start"
      className={sprinkles({ width: 'fill' })}
      trigger={
        <div className={triggerClass} style={{ paddingRight: 10 }}>
          {selectedText}
          <Icon name="caret-down" size="sm" />
        </div>
      }
      width="trigger">
      <div className={menuClass} style={{ maxHeight: 168 }}>
        {panels.map((panel) => (
          <div className={panelOptionClass} key={panel.id}>
            <Checkbox
              isChecked={selectedPanelIds.has(panel.id)}
              label={panel.provided_id}
              onChange={() => updateConditionedPanel(panel.id)}
            />
          </div>
        ))}
      </div>
    </Popover>
  );
};

const triggerClass = sprinkles({
  backgroundColor: 'blue3',
  borderRadius: 3,
  width: 'fill',
  color: 'blue9',
  paddingLeft: 'sp1',
  height: 32,
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
  cursor: 'pointer',
});

const menuClass = sprinkles({
  paddingY: 'sp1',
  paddingX: 'sp2',
  flexItems: 'column',
  backgroundColor: 'white',
  overflowY: 'auto',
  borderRadius: 8,
});

const panelOptionClass = sprinkles({
  height: 36,
  minHeight: 36,
  flexItems: 'alignCenter',
  gap: 'sp1.5',
  overflow: 'hidden',
});
