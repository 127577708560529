import { Draft } from 'immer';
import { FC, useMemo } from 'react';

import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';
import { sprinkles, Checkbox } from 'components/ds';
import { DateElemConfig } from 'types/dashboardTypes';
import { DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES } from 'types/dateRangeTypes';
import { getSortedDateRangesWithVisibility, toggleRangeVisibility } from 'utils/dateRangeUtils';

import * as styles from '../styles.css';

interface Props {
  config: DateElemConfig;
  shouldRenderHeader: boolean;
  updateConfigFn: (updateFunc: (draft: Draft<DateElemConfig>) => void) => void;
}

export const RelativeDatesDropdownConfig: FC<Props> = ({
  config,
  shouldRenderHeader,
  updateConfigFn,
}) => {
  const orderedRanges = useMemo(() => getSortedDateRangesWithVisibility(config), [config]);

  return (
    <>
      {shouldRenderHeader ? (
        <div className={styles.headerClass}>Pre-set Dropdown Values</div>
      ) : null}
      <SortableList
        getIdFromElem={(item) => item.range}
        onListUpdated={(newList) =>
          updateConfigFn((draft) => {
            draft.defaultRangesOrder = newList.map((item) => item.range);
          })
        }
        sortableItems={orderedRanges}>
        {orderedRanges.map(({ range, isVisible }) => {
          return (
            <SortableListItem key={range} sortId={range}>
              <div className={styles.sortableRangeOption}>
                <SortableListItemDragHandle className={sprinkles({ color: 'contentSecondary' })} />
                <Checkbox
                  isChecked={isVisible}
                  onChange={() =>
                    updateConfigFn((draft) => {
                      toggleRangeVisibility(range, isVisible, draft);
                    })
                  }
                />
                <div>{DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[range] ?? range}</div>
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
    </>
  );
};
