// French [fr]

const numbers = {
  decimal: ',',
  thousands: '\u00a0',
  grouping: [3],
  percent: '\u202f%',
};

export default numbers;
