import { useState, FC } from 'react';

import { Button, IconButton, sprinkles } from 'components/ds';

type Props = {
  onDelete: () => void;
  loading?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  fillWidth?: boolean;
  children?: string; // Overrides the "Delete" button text
};

export const DeleteConfirmationButton: FC<Props> = ({
  onDelete,
  loading,
  disabled,
  tooltipText,
  fillWidth,
  children,
}) => {
  const [isDeleteToggled, setIsDeleteToggled] = useState(false);

  return (
    <div className={sprinkles({ flexItems: 'center' })} style={{ minWidth: 72 }}>
      {isDeleteToggled && !loading ? (
        <div className={sprinkles({ gap: 'sp1', flexItems: 'center', width: 'fill' })}>
          <IconButton
            fillWidth={fillWidth}
            name="cross"
            onClick={() => setIsDeleteToggled(false)}
            variant="secondary"
          />
          <IconButton fillWidth={fillWidth} name="trash" onClick={onDelete} variant="destructive" />
        </div>
      ) : (
        <Button
          disabled={disabled}
          fillWidth={fillWidth}
          loading={loading}
          onClick={() => setIsDeleteToggled(true)}
          tooltipProps={tooltipText ? { text: tooltipText } : undefined}
          variant="destructive">
          {children || 'Delete'}
        </Button>
      )}
    </div>
  );
};
