import { FC, useMemo } from 'react';

import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { Modal, ModalSection } from 'components/ds';
import { DashboardVersion } from 'types/dashboardVersion';
import { ResourcePageType } from 'types/exploResource';
import {
  ResourceBaseInfo,
  VersionComparatorResult,
  compareDashboardVersions,
  compareReportBuilderVersions,
} from 'utils/resourceVersionComparator';

import { HEADER_CLASS, TEXT_CONTENT_CLASS } from './index.css';
import { ResourceItemComparisonComponent } from './resourceItemComparisonComponent';

interface Props {
  currentVersion: DashboardVersion | ReportBuilderVersion;
  previousVersion: DashboardVersion | ReportBuilderVersion;
  resourceType: ResourcePageType;
  onCloseCallback: () => void;
}

export const VersionComparisonModal: FC<Props> = ({
  previousVersion,
  currentVersion,
  resourceType,
  onCloseCallback,
}) => {
  const versionComparisonResult: VersionComparatorResult = useMemo(() => {
    return resourceType === ResourcePageType.EXPLORE
      ? compareDashboardVersions(
          previousVersion as DashboardVersion,
          currentVersion as DashboardVersion,
        )
      : compareReportBuilderVersions(
          previousVersion as ReportBuilderVersion,
          currentVersion as ReportBuilderVersion,
        );
  }, [previousVersion, currentVersion, resourceType]);

  const renderModalSection = (
    title: string,
    sectionItems: ResourceBaseInfo[],
  ): JSX.Element | null => {
    if (sectionItems.length === 0) {
      // Hide the section if there are no items.
      return null;
    }

    return (
      <ModalSection headerClass={HEADER_CLASS} textContentClass={TEXT_CONTENT_CLASS} title={title}>
        {sectionItems.map((item) => {
          return (
            <ResourceItemComparisonComponent
              key={`${item.id}`}
              resourceName={item.name}
              resourcePrefix={item.typePrefix}
            />
          );
        })}
      </ModalSection>
    );
  };

  return (
    <Modal isOpen onBack={onCloseCallback} size="large" title="Version Comparison">
      {renderModalSection('Added Items', versionComparisonResult.addedItems)}
      {renderModalSection('Deleted Items', versionComparisonResult.deletedItems)}
      {renderModalSection('Modified Items', versionComparisonResult.modifiedItems)}
    </Modal>
  );
};
