import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import MultiSelect from 'components/multiSelect';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { DashboardVariable, DashboardVariableMap, SelectElemConfig } from 'types/dashboardTypes';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';

type Props = {
  config: SelectElemConfig;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  onNewValueSelect: OnNewValueSelectFunc;
  value: DashboardVariable;
  disabled?: boolean;
  openElementToLeft: boolean;
  variables: DashboardVariableMap;
  isInContainer?: boolean;
};

export const DashboardMultiSelectElement: FC<Props> = ({
  config,
  value,
  datasetData,
  datasetNamesToId,
  disabled,
  openElementToLeft,
  onNewValueSelect,
  variables,
  isInContainer,
}) => {
  const dispatch = useDispatch();

  const options = useMemo(
    () => constructOptions(config.valuesConfig, datasetData),
    [config.valuesConfig, datasetData],
  );

  const selectedOptions = useMemo(() => {
    if (!Array.isArray(value) || !value.length) return [];
    const selectedSet = new Set<string | number | undefined>();

    // Value is a list of lists so need a different check
    if (Array.isArray(value[0])) {
      value.forEach((val) => selectedSet.add(val.toString()));
      return options.filter((option) => selectedSet.has(option.value?.toString()));
    }
    value.forEach((val) => selectedSet.add(val as string | number));

    return options.filter((option) => selectedSet.has(option.value));
  }, [options, value]);

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div>
      <MultiSelect
        isEmbed
        disableOnNoItems={config.disableOnNoItems}
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables, datasetNamesToId, datasetData)}
        label={label}
        openElementToLeft={openElementToLeft}
        options={options}
        placeholder={config.placeholderText}
        selectedItems={selectedOptions}
        updateSelectedValues={(newValues) => {
          onNewValueSelect(newValues, { length: newValues?.length ?? 0 });
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.MULTISELECT_SELECTED));
        }}
        usePortal={isInContainer}
      />
    </div>
  );
};
