import produce from 'immer';
import { FC } from 'react';

import { BooleanToggle } from 'components/ds';
import { SwitchElementConfig } from 'types/dashboardTypes';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchDefaultValueConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  return (
    <BooleanToggle
      falseText="Off"
      onValueChange={(defaultOn) => {
        const newConfig = produce(config, (draft) => {
          draft.defaultOn = defaultOn;
        });
        updateSwitchConfig(newConfig);
      }}
      selectedValue={!!config.defaultOn}
      trueText="On"
    />
  );
};
