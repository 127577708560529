import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { vars } from 'components/ds';
import { BaseListItem } from 'pages/ReportBuilder/HomeView/BaseListItem';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';
import { openBuiltInThunk } from 'reportBuilderContent/thunks/reportThunks';

interface Props {
  builtIn: BuiltInReportConfig;
}

export const BuiltInListItem: FC<Props> = ({ builtIn }) => {
  const dispatch = useDispatch();

  const favoriteBuiltIns = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.favoriteBuiltIns,
  );

  return (
    <BaseListItem
      description={builtIn.description}
      id={builtIn.id}
      isStarred={favoriteBuiltIns.includes(builtIn.id)}
      key={builtIn.id}
      menuOptions={[]}
      modified={builtIn.modified}
      name={builtIn.name}
      onClick={() => dispatch(openBuiltInThunk(builtIn))}
      reportInfo={{ type: ReportType.BUILT_IN, id: builtIn.id }}
      tags={[{ backgroundColor: vars.colors.activeSubdued, children: 'Built In' }]}
    />
  );
};
