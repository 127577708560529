import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { forwardRef } from 'react';

import * as styles from './index.css';

type Props = { className?: string };

export const MenuSeparator = forwardRef<HTMLDivElement, Props>(function MenuSeparator(
  { className },
  ref,
) {
  return <DropdownMenu.Separator className={cx(styles.menuSeparator, className)} ref={ref} />;
});
