import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { sprinkles, Button } from 'components/ds';

enum STATUS {
  IDLE,
  LOADING,
  SUCCESS,
  FAILURE,
}

type Props = {
  // Path to send the unsubscribe request to. Shouldn't being with a leading /
  unsubscribePath: string;
};

export const UnsubscribeEmailBasePage: FC<Props> = ({ unsubscribePath }) => {
  const { email, emailId } = useParams<{ email: string; emailId: string }>();
  const [status, setStatus] = useState(STATUS.IDLE);

  const unsubscribe = () => {
    fetch(process.env.REACT_APP_API_URL + unsubscribePath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ email, email_id: emailId }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success === 1) setStatus(STATUS.SUCCESS);
        else setStatus(STATUS.FAILURE);
      })
      .catch(() => setStatus(STATUS.FAILURE));
  };

  const getText = () => {
    if (status === STATUS.SUCCESS) return <div>You&rsquo;ve been unsubscribed.</div>;
    if (status === STATUS.FAILURE) return <div>Error unsubscribing email</div>;
    return <div>Are you sure you want to unsubscribe {email} from receiving this email?</div>;
  };

  return (
    <div className={sprinkles({ flexItems: 'centerColumn', height: 'fillViewport' })}>
      {getText()}
      {status === STATUS.IDLE || status === STATUS.LOADING ? (
        <Button
          className={sprinkles({ marginTop: 'sp3' })}
          loading={status === STATUS.LOADING}
          onClick={unsubscribe}>
          Unsubscribe
        </Button>
      ) : null}
    </div>
  );
};
