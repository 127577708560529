import { TooltipOptions, TitleOptions } from 'highcharts';

export const sharedTooltipConfigs: TooltipOptions = {
  padding: 0,
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: '#FFFFFF00',
  shadow: false,
  useHTML: true,
  outside: true,
  followPointer: true,
};

export const sharedTitleConfig: TitleOptions = { text: undefined };
