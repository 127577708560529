import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DATABASES, SUPPORTED_FIDO_DATA_SOURCES } from 'pages/ConnectDataSourceFlow/constants';
import { ReduxState } from 'reducers/rootReducer';

import { FidoBigQueryCredentialsSection } from './FidoBigQueryCredentialsSection';
import { FidoJdbcCredentialsSection } from './FidoJdbcCredentialsSection';
import { FidoSnowflakeCredentialsSection } from './FidoSnowflakeCredentialsSection';

export const FidoCredentialsSection: FC = () => {
  const type = useSelector((state: ReduxState) => state.fidoDataSourceConfig.dataSourceConfig.type);

  if (!type || !SUPPORTED_FIDO_DATA_SOURCES.includes(type)) {
    return null;
  }

  switch (type) {
    case DATABASES.AZURE:
    case DATABASES.SQLSERVER:
    case DATABASES.REDSHIFT:
    case DATABASES.POSTGRES:
    case DATABASES.MYSQL:
      return <FidoJdbcCredentialsSection />;
    case DATABASES.BIGQUERY:
      return <FidoBigQueryCredentialsSection />;
    case DATABASES.SNOWFLAKE:
      return <FidoSnowflakeCredentialsSection />;
    default:
      return null;
  }
};
