import { Draft } from 'immer';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles } from 'components/ds';
import { dataPanelLinkUpdatedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import DropdownSelect from 'shared/DropdownSelect';
import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';
import { FilterOperator } from 'types/filterOperations';
import { getOperatorOptionsForFilter } from 'utils/filterOperations';

import { getUpdateConfigFunc } from './utils';

type Props = {
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
  additionalConfigUpdateFn: (
    config: Draft<DashboardElementConfig>,
    filterOperator: FilterOperator,
  ) => void;
};

export const OperatorConfig: FC<Props> = ({ element, updateConfig, additionalConfigUpdateFn }) => {
  const dispatch = useDispatch();

  const { config, name, element_type } = element;
  const [operator, setOperator] = useState(config.operator);

  useEffect(() => {
    if (config.operator === operator) return;
    setOperator(config.operator);
    const dataPanelIds: string[] = [];
    Object.values(config.datasetLinks ?? {}).forEach((links) => {
      links.dataPanels?.forEach((dp) => dataPanelIds.push(dp));
    });
    dispatch(dataPanelLinkUpdatedThunk(name, dataPanelIds));
  }, [config, name, operator, dispatch]);

  const update = getUpdateConfigFunc(config, updateConfig);

  const options = getOperatorOptionsForFilter(element_type);

  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
      <div className={sprinkles({ body: 'b3', color: 'contentPrimary', marginRight: 'sp1' })}>
        Field
      </div>
      <DropdownSelect
        fillWidth
        ignoreCustomStyles
        minimal
        showCancelBtn
        containerClassName={sprinkles({ flex: 1 })}
        filterable={false}
        noSelectionText="Select an operator..."
        onCancelClick={() => update((draft) => (draft.operator = undefined))}
        onChange={(item) =>
          update((draft) => {
            const filterOperator = item.id as FilterOperator;
            draft.operator = filterOperator;
            additionalConfigUpdateFn(draft, filterOperator);
          })
        }
        options={options}
        selectedItem={operator ? options.find((op) => op.id === operator) : undefined}
      />
      <div className={sprinkles({ body: 'b3', color: 'contentPrimary', marginLeft: 'sp1' })}>
        Value
      </div>
    </div>
  );
};
