import { FC, useState } from 'react';

import { Tabs, sprinkles, AlertModal } from 'components/ds';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { VariablesPanel } from 'pages/dashboardPage/EditDashboardLeftPanel/VariablesPanel';

type Props = {
  dashboardId: number | undefined;
  selectedDatasetId: number;
  onSelectSchema: (schemaId: number) => void;
};

export enum METADATA_VIEWS {
  SCHEMA = 'Schema',
  VARIABLES = 'Variables',
}

const MetadataViewTabs = Object.values(METADATA_VIEWS);

export const DatasetMetadataPanel: FC<Props> = ({
  dashboardId,
  selectedDatasetId,
  onSelectSchema,
}) => {
  const [metadataView, setMetadataView] = useState(METADATA_VIEWS.SCHEMA);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [swapSchemaId, setSwapSchemaId] = useState(selectedDatasetId);

  return (
    <div className={baseContainerClass} style={{ minWidth: 320 }}>
      <Tabs
        onTabSelect={(tabId) => setMetadataView(tabId as METADATA_VIEWS)}
        selectedTabId={metadataView}
        tabs={MetadataViewTabs}
      />
      {metadataView === METADATA_VIEWS.SCHEMA ? (
        <SchemaViewer
          onSelectSchema={(newSchemaId) => {
            setSwapSchemaId(newSchemaId);
            setIsModalOpen(true);
          }}
          selectedDatasetSchemaId={selectedDatasetId}
        />
      ) : dashboardId ? (
        <VariablesPanel readOnly dashboardId={dashboardId} />
      ) : (
        <div className={sprinkles({ flexItems: 'alignCenter', body: 'b1' })}>
          No selected dashboard.
        </div>
      )}
      <AlertModal
        actionButtonProps={{
          onClick: () => {
            onSelectSchema(swapSchemaId);
            setIsModalOpen(false);
          },
          text: 'Save',
          variant: 'primary',
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Are you sure you want to change the schema?">
        If you have created charts using this dataset and you change the schema, your charts will no
        longer load the correct data.
      </AlertModal>
    </div>
  );
};

const baseContainerClass = sprinkles({
  flexItems: 'column',
  border: 1,
  borderColor: 'outline',
  borderRadius: 8,
  height: 'fill',
});
