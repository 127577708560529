import { FC } from 'react';

import { sprinkles } from 'components/ds';

interface Props {
  resourcePrefix: string;
  resourceName: string;
}

export const ResourceItemComparisonComponent: FC<Props> = ({ resourcePrefix, resourceName }) => {
  return (
    <div className={sprinkles({ marginY: 'sp1.5' })}>
      <span className={sprinkles({ paddingRight: 'sp1' })}>{resourcePrefix}</span>
      <span className={sprinkles({ color: 'contentSecondary' })}>{resourceName} </span>
    </div>
  );
};
