import {
  TestConnectionResponse,
  NamespaceResponse,
  DataSourceResponse,
  BigQuery,
  BigQueryAuthentication,
  JdbcAuthentication,
  SSHTunnel,
  SnowflakeAuthentication,
  Snowflake,
} from '@explo-tech/fido-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataSourceConfiguration } from 'actions/dataSourceActions';
import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import * as RD from 'remotedata';
import {
  getUpdatableDataSourceConfig,
  getUpdatableSSHConfig,
} from 'utils/fido/dataSources/fidoDataSourceShims';
import { FidoDataSourceConfig, JdbcConfig } from 'utils/fido/dataSources/types';

import {
  createNamespace,
  testConnectionUsingFido,
  createDataSourceInFido,
  createEmbeddoDataSource,
  retestConnectionUsingFido,
} from './thunks/connectDataSourceThunks';

// the compiler gets really weird with all of these, likely we need to figure out a better solution here
export type PartialDataSourceConfig = Partial<Omit<BigQuery, 'authentication'>> &
  Partial<Omit<JdbcConfig, 'authentication'>> &
  Partial<Omit<Snowflake, 'authentication'>>;
export type PartialAuthConfig = Partial<
  BigQueryAuthentication & JdbcAuthentication & SnowflakeAuthentication
>;
export type PartialSSHConfig = Partial<Omit<SSHTunnel, 'authentication'>>;
// the compiler is being REALLY weird with this one, so just hard-coding the values
export type PartialSSHAuthConfig = Partial<{
  username: string;
  privateKey: string;
  '@type': 'tenant' | 'vendor';
}>;

interface FidoDataSourceConfigReducer {
  finalConfig?: FidoDataSourceConfig;
  dataSourceConfig: { config: PartialDataSourceConfig; type?: DATABASES; auth: PartialAuthConfig };
  sshConfig: {
    config: PartialSSHConfig;
    auth: PartialSSHAuthConfig;
    useSsh: boolean;
  };
  testConnectionResponse: RD.ResponseData<TestConnectionResponse>;
  createNamespaceResponse: RD.ResponseData<NamespaceResponse>;
  createDataSourceResponse: RD.ResponseData<DataSourceResponse>;
  // Don't need to know actual value, just if it was successful or not
  connectDataSourceUsingFidoResponse: RD.ResponseData<boolean>;
  isConfigDraft: boolean;
  connectingError?: string;
  isUpdating?: boolean;
}

const initialState: FidoDataSourceConfigReducer = {
  dataSourceConfig: { config: {}, auth: {} },
  sshConfig: { config: {}, auth: {}, useSsh: false },
  testConnectionResponse: RD.Idle(),
  createNamespaceResponse: RD.Idle(),
  createDataSourceResponse: RD.Idle(),
  connectDataSourceUsingFidoResponse: RD.Idle(),
  isConfigDraft: true,
};

const fidoDataSourceConfigSlice = createSlice({
  name: 'fidoDataSourceConfigReducer',
  initialState,
  reducers: {
    resetFidoDataSourceConfigReducer: () => initialState,
    setInitialConfigForUpdate: (
      state,
      { payload }: PayloadAction<{ config: DataSourceConfiguration; type: DATABASES }>,
    ) => {
      state.isConfigDraft = false;
      state.isUpdating = true;

      const dataSourceConfig = getUpdatableDataSourceConfig(payload.config, payload.type);
      if (!dataSourceConfig) return;
      state.dataSourceConfig = {
        config: dataSourceConfig.dataSourceConfig,
        type: payload.type,
        auth: dataSourceConfig.authConfig,
      };

      const sshConfig = getUpdatableSSHConfig(payload.config, payload.type);
      state.sshConfig.useSsh = !!sshConfig;
      if (!sshConfig) return;
      state.sshConfig.config = sshConfig.sshConfig;
      state.sshConfig.auth = sshConfig.sshAuthConfig;
    },
    setDataSourceType: (state, { payload }: PayloadAction<DATABASES>) => {
      // TODO should we start clearing state when they make breaking updates e.g. changing the data source or auth type
      state.dataSourceConfig.type = payload;
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setDataSourceConfig: (state, { payload }: PayloadAction<PartialDataSourceConfig>) => {
      state.dataSourceConfig.config = { ...state.dataSourceConfig.config, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setAuthentication: (state, { payload }: PayloadAction<PartialAuthConfig>) => {
      state.dataSourceConfig.auth = { ...state.dataSourceConfig.auth, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setUseSsh: (state, { payload }: PayloadAction<boolean>) => {
      state.sshConfig.useSsh = payload;
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setSSHConfig: (state, { payload }: PayloadAction<PartialSSHConfig>) => {
      state.sshConfig.config = { ...state.sshConfig.config, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setSSHAuthConfig: (state, { payload }: PayloadAction<PartialSSHAuthConfig>) => {
      state.sshConfig.auth = { ...state.sshConfig.auth, ...payload };
      state.isConfigDraft = true;
      state.testConnectionResponse = RD.Idle();
    },
    setFinalDataSourceConfig: (state, { payload }: PayloadAction<FidoDataSourceConfig>) => {
      state.finalConfig = payload;
      state.isConfigDraft = false;
    },
    resetRetestConnectionResponse: (state) => {
      state.testConnectionResponse = RD.Idle();
    },
    setConnectingError: (state, { payload }: PayloadAction<string>) => {
      state.connectingError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(testConnectionUsingFido.pending, (state) => {
        state.testConnectionResponse = RD.Loading();
        state.createNamespaceResponse = RD.Idle();
        state.createDataSourceResponse = RD.Idle();
        state.connectDataSourceUsingFidoResponse = RD.Idle();
      })
      .addCase(testConnectionUsingFido.fulfilled, (state, { payload }) => {
        state.testConnectionResponse = RD.Success(payload);
      })
      .addCase(testConnectionUsingFido.rejected, (state, { error }) => {
        state.testConnectionResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createNamespace.pending, (state) => {
        state.createNamespaceResponse = RD.Loading();
      })
      .addCase(retestConnectionUsingFido.pending, (state) => {
        state.testConnectionResponse = RD.Loading();
      })
      .addCase(retestConnectionUsingFido.fulfilled, (state, { payload }) => {
        state.testConnectionResponse = RD.Success(payload);
      })
      .addCase(retestConnectionUsingFido.rejected, (state, { error }) => {
        state.testConnectionResponse = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(createNamespace.fulfilled, (state, { payload }) => {
        state.createNamespaceResponse = RD.Success(payload);
        state.connectingError = undefined;
      })
      .addCase(createNamespace.rejected, (state, { error }) => {
        state.createNamespaceResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createDataSourceInFido.pending, (state) => {
        state.createDataSourceResponse = RD.Loading();
      })
      .addCase(createDataSourceInFido.fulfilled, (state, { payload }) => {
        state.createDataSourceResponse = RD.Success(payload);
      })
      .addCase(createDataSourceInFido.rejected, (state, { error }) => {
        state.createDataSourceResponse = RD.Error(error.message ?? 'Something went wrong');
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      })
      .addCase(createEmbeddoDataSource.pending, (state) => {
        state.connectDataSourceUsingFidoResponse = RD.Loading();
      })
      .addCase(createEmbeddoDataSource.fulfilled, () => {
        return { ...initialState, connectDataSourceUsingFidoResponse: RD.Success(true) };
      })
      .addCase(createEmbeddoDataSource.rejected, (state, { error }) => {
        state.connectDataSourceUsingFidoResponse = RD.Error(
          error.message ?? 'Something went wrong',
        );
        state.connectingError = error.message ?? 'Something went wrong';
      });
  },
});

export const {
  setFinalDataSourceConfig,
  setDataSourceConfig,
  setDataSourceType,
  setAuthentication,
  setSSHConfig,
  setSSHAuthConfig,
  setUseSsh,
  setConnectingError,
  setInitialConfigForUpdate,
  resetFidoDataSourceConfigReducer,
  resetRetestConnectionResponse,
} = fidoDataSourceConfigSlice.actions;

export const fidoDataSourceConfigReducer = fidoDataSourceConfigSlice.reducer;
