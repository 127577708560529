import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { pageView } from 'analytics/exploAnalytics';
import { ViewType } from 'components/PageHeader';
import { listReportFolderContents } from 'reducers/thunks/resourceThunks';
import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';
import { ResourceListPageWrapper } from 'shared/ExploResource/ResourceListPageWrapper';
import {
  RESOURCES_PER_CARD_PAGE,
  RESOURCES_PER_LIST_PAGE,
  VIEW_TYPE_REPORT_BUILDER_KEY,
} from 'shared/ExploResource/constants';
import { ResourcePageType } from 'types/exploResource';

export const ReportBuilderListPage: FC = () => {
  const dispatch = useDispatch();
  const {
    params: { folderId },
  } = useRouteMatch<{ folderId: string }>();

  const [currPage, setCurrPage] = useState(1);
  const [viewType, setViewType] = useLocalStorage<ViewType>(
    VIEW_TYPE_REPORT_BUILDER_KEY,
    ViewType.List,
  );

  useEffect(() => {
    document.title = 'Explo | Report Builder';
    pageView('Home App (Report Builders)');
  });

  useEffect(() => {
    const maybeId = parseInt(folderId);
    const id = isNaN(maybeId) ? undefined : maybeId;
    const resourcesPerPage =
      viewType === ViewType.List ? RESOURCES_PER_LIST_PAGE : RESOURCES_PER_CARD_PAGE;
    dispatch(listReportFolderContents({ id, page: currPage, page_size: resourcesPerPage }));
  }, [folderId, dispatch, viewType, currPage]);

  return (
    <ResourceListPageWrapper
      currPage={currPage}
      pageType={ResourcePageType.REPORT_BUILDER}
      setCurrPage={setCurrPage}
      setViewType={setViewType}
      viewType={viewType}>
      <ResourceListPage pageType={ResourcePageType.REPORT_BUILDER} />
    </ResourceListPageWrapper>
  );
};
