import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { sendJSEventThunk } from 'reducers/thunks/customEventThunks';
import { EventButtonElemConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';

type Props = {
  config: EventButtonElemConfig;
  datasetNamesToId: Record<string, string>;
};

export const DashboardEventElement: FC<Props> = ({ config, datasetNamesToId }) => {
  const dispatch = useDispatch();

  const buttonText = useStringWithVariablesReplaced(
    config.buttonText || 'JS Event',
    datasetNamesToId,
  );

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <EmbedButton
        fillWidth
        disabled={!config.jsEvent}
        onClick={() => config.jsEvent && dispatch(sendJSEventThunk(config.jsEvent))}
        variant="primary">
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <div className={sprinkles({ truncateText: 'ellipsis', flex: 1 })}>{buttonText}</div>
        </div>
      </EmbedButton>
    </div>
  );
};
