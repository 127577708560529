import produce from 'immer';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { updateWidgetType } from 'actions/chatWidgetActions';
import {
  listTeamDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchSupportedDataSources,
  ParentSchema,
  TestDataSourceConnectionData,
} from 'actions/dataSourceActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { sendPing } from 'actions/pingActions';
import { fetchAccessGroups } from 'actions/rolePermissionActions';
import { AccessGroup } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { Poller } from 'components/JobQueue/Poller';
import { Jobs } from 'components/JobQueue/types';
import { sprinkles } from 'components/ds';
import { WIDGET_TYPES } from 'constants/hubspotConstants';
import { ROUTES } from 'constants/routes';
import { PingTypes } from 'constants/types';
import { getTeamDataSources } from 'reducers/dataSourceReducer';
import {
  setFinalDataSourceConfig,
  setDataSourceType,
  setConnectingError,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  testConnectionUsingFido,
  createNamespace,
  createDataSourceInFido,
} from 'reducers/thunks/connectDataSourceThunks';
import { getNamespaces } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { showSuccessToast } from 'shared/sharedToasts';
import { buildFinalDataSourceConfigForFido } from 'utils/fido/dataSources/utils';
import { parseJsonFields } from 'utils/general';
import { updatePageSpecificChatWidget } from 'utils/hubspotUtils';

import { EnterCredentials } from './StepPages/EnterCredentials';
import { GettingStarted } from './StepPages/GettingStarted';
import { ReviewConfiguration, TestConnectionStatus } from './StepPages/ReviewConfiguration';
import { SecurityConfiguration } from './StepPages/SecurityConfiguration';
import { SelectDatabase } from './StepPages/SelectDatabase';
import {
  ConnectDataSourceStep,
  ERROR_CONNECTING_MSG,
  NEW_SCHEMA_CREATED_SUCCESS,
  SUPPORTED_FIDO_DATA_SOURCES,
} from './constants';
import { DBConnectionConfig } from './types';
import { pingDataSourceConnectionMsg } from './utils';

type Props = PropsFromRedux & RouteComponentProps<{}>;

type State = {
  currentStep: ConnectDataSourceStep;
  connectionConfig: DBConnectionConfig;
  selectedSchema?: ParentSchema;
  testingConnectionStatus: RD.ResponseData<TestConnectionStatus>;
  isConnectingToDataSource: boolean;
  accessGroups?: AccessGroup[];
  selectedAccessGroupIds: number[];
  isNewSchema?: boolean;

  awaitedJobs: Record<string, Jobs>;
};

class ConnectDataSourceFlow extends Component<Props, State> {
  state: State = {
    currentStep: ConnectDataSourceStep.GETTING_STARTED,
    connectionConfig: {},
    awaitedJobs: {},
    selectedAccessGroupIds: [],
    testingConnectionStatus: RD.Idle(),
    isConnectingToDataSource: false,
  };

  constructor(props: Props) {
    super(props);

    props.listTeamDataSources();
    if (RD.isIdle(props.supportedDataSources)) props.fetchSupportedDataSources();
    props.fetchAccessGroups(undefined, ({ access_groups }) => {
      this.setState({ accessGroups: access_groups });
      if (access_groups.length === 1)
        this.setState({ selectedAccessGroupIds: [access_groups[0].id] });
    });
    props.fetchUsedParentSchemas();
  }

  componentDidMount() {
    const { widget } = this.props;
    if (widget.conversationStarted) return;

    updatePageSpecificChatWidget(widget.isOpen);
    this.props.updateWidgetType({ widgetType: WIDGET_TYPES.CONNECT_DATA });
  }

  componentDidUpdate() {
    const { getNamespaces, readyToFetchNameSpaces } = this.props;
    if (readyToFetchNameSpaces) getNamespaces();
  }

  render() {
    const { awaitedJobs } = this.state;

    return (
      <div className={sprinkles({ parentContainer: 'fill' })}>
        <Poller
          awaitedJobs={awaitedJobs}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        {this.renderCurrentStep()}
      </div>
    );
  }

  renderCurrentStep = () => {
    const { dataSources, useFido, fidoDataSourceConfig, allParentSchemas } = this.props;
    const {
      currentStep,
      connectionConfig,
      isConnectingToDataSource,
      selectedSchema,
      accessGroups,
      selectedAccessGroupIds,
      testingConnectionStatus,
    } = this.state;

    const {
      testConnectionResponse,
      connectDataSourceUsingFidoResponse,
      isConfigDraft,
      connectingError,
    } = fidoDataSourceConfig;

    switch (currentStep) {
      case ConnectDataSourceStep.GETTING_STARTED:
        return (
          <GettingStarted
            accessGroups={accessGroups}
            config={connectionConfig}
            existingDataSources={dataSources ?? []}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
            parentSchemas={allParentSchemas}
            selectedAccessGroupIds={selectedAccessGroupIds}
            selectedSchema={selectedSchema}
            setSelectedAccessGroupIds={(accessGroupIds) =>
              this.setState({
                selectedAccessGroupIds: accessGroupIds,
              })
            }
            setSelectedSchema={this.setSelectedSchema}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.SELECT_DB:
        return (
          <SelectDatabase
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.GETTING_STARTED)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.ENTER_CREDS:
        return (
          <EnterCredentials
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SECURITY)}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.SECURITY:
        return (
          <SecurityConfiguration
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            onNextClicked={() => this.testConnectionCredentials()}
            testingConnLoading={
              useFido ? RD.isLoading(testConnectionResponse) : RD.isLoading(testingConnectionStatus)
            }
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.REVIEW: {
        const sharedReviewProps = {
          config: connectionConfig,
          existingDataSources: dataSources ?? [],
          onBackClicked: () => this.updateStep(ConnectDataSourceStep.SECURITY),
          selectedAccessGroupIds: selectedAccessGroupIds,
          selectedSchema: selectedSchema,
          setSelectedAccessGroupIds: (accessGroupIds: number[]) =>
            this.setState({ selectedAccessGroupIds: accessGroupIds }),
          setSelectedSchema: this.setSelectedSchema,
          parentSchemas: RD.getOrDefault(allParentSchemas, []),
          updateConfig: this.updateConfig,
        };

        if (useFido) {
          const testingConnection =
            !isConfigDraft && connectingError ? RD.Error(connectingError) : testConnectionResponse;
          return (
            <ReviewConfiguration
              {...sharedReviewProps}
              isConnectingToDataSource={RD.isLoading(connectDataSourceUsingFidoResponse)}
              onNextClicked={() =>
                RD.isSuccess(testConnectionResponse) && !isConfigDraft
                  ? this.connectDataSource()
                  : this.testConnectionCredentials()
              }
              testingConnection={testingConnection}
            />
          );
        }
        return (
          <ReviewConfiguration
            {...sharedReviewProps}
            isConnectingToDataSource={isConnectingToDataSource}
            onNextClicked={() =>
              RD.isSuccess(testingConnectionStatus)
                ? this.connectDataSource()
                : this.testConnectionCredentials()
            }
            testingConnection={testingConnectionStatus}
          />
        );
      }
    }
  };

  updateStep = (newStep: ConnectDataSourceStep) => {
    this.setState({ currentStep: newStep });
  };

  updateConfig = (newConfig: DBConnectionConfig) => {
    this.setState({ connectionConfig: newConfig, testingConnectionStatus: RD.Idle() });
  };

  setSelectedSchema = (schema: ParentSchema, isNew?: boolean) => {
    const { connectionConfig } = this.state;
    const { setDataSourceType } = this.props;
    const defaultSourceType = isNew
      ? connectionConfig.selectedDataSource
      : this.getDefaultSourceType(schema);

    this.setState({
      selectedSchema: schema,
      connectionConfig: {
        ...connectionConfig,
        selectedDataSource: defaultSourceType,
        selectedDataSourceIsLocked: defaultSourceType && !isNew,
      },
      isNewSchema: isNew,
    });

    if (defaultSourceType !== undefined) setDataSourceType(defaultSourceType.name);
  };

  getDefaultSourceType = ({ id }: ParentSchema) => {
    const { dataSources, supportedDataSources, useFido } = this.props;
    if (!RD.isSuccess(supportedDataSources)) return;

    const dataSource = dataSources.find((ds) => ds.parent_schema_id === id);
    if (!dataSource) return;

    return supportedDataSources.data.find((ds) => {
      return useFido ? ds.name === dataSource.source_type : ds.type === dataSource.source_type;
    });
  };

  testConnectionCredentials = () => {
    const {
      useFido,
      fidoDataSourceConfig,
      testConnectionUsingFido,
      testDataSourceConnection,
      setFinalDataSourceConfig,
      shouldUseJobQueue,
    } = this.props;

    const { connectionConfig } = this.state;
    const { dataSourceConfig, sshConfig } = fidoDataSourceConfig;

    if (useFido) {
      const finalDataSourceConfig = buildFinalDataSourceConfigForFido(
        dataSourceConfig,
        sshConfig,
        true,
      );
      if (!finalDataSourceConfig) {
        setConnectingError('There was an error with your data source configuration');
        return;
      }

      setFinalDataSourceConfig(finalDataSourceConfig);

      testConnectionUsingFido({
        configuration: finalDataSourceConfig,
        onSuccess: () => {
          this.updateStep(ConnectDataSourceStep.REVIEW);
          this.sendTestConnectionResultPing(true);
        },
        onError: () => {
          this.updateStep(ConnectDataSourceStep.REVIEW);
          this.sendTestConnectionResultPing(false);
        },
      });

      return;
    }

    if (!connectionConfig.selectedDataSource) return;

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig || {},
    );
    if (error !== undefined) {
      this.setState({ testingConnectionStatus: RD.Error(error?.toString()) });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    if (connectionConfig.name === undefined) {
      this.onTestConnectDataSourceError('Data Source name must be defined.');
      return;
    }
    this.setState({ testingConnectionStatus: RD.Loading() });

    const postData = {
      name: connectionConfig.name,
      type: connectionConfig.selectedDataSource.type,
      configuration: parsedConfig,
    };

    if (!shouldUseJobQueue)
      testDataSourceConnection({ postData }, this.onTestConnectDataSourceSuccess, (response) =>
        this.onTestConnectDataSourceError(response.error_msg),
      );
    else {
      this.bulkEnqueueJobs([
        {
          job_type: ACTION.TEST_DATA_SOURCE_CONNECTION,
          job_args: postData,
          onSuccess: this.onTestConnectDataSourceSuccess,
          onError: this.onTestConnectDataSourceError,
        },
      ]);
      this.sendTestPing(this.getTestPingMsg(false, true));
    }
  };

  getTestPingMsg = (isError?: boolean, isJobQueue?: boolean) => {
    const { useFido, currentUser } = this.props;
    const { name, selectedDataSource } = this.state.connectionConfig;
    return pingDataSourceConnectionMsg(
      currentUser,
      name,
      selectedDataSource?.type,
      isError,
      useFido,
      false,
      isJobQueue,
    );
  };

  sendTestPing = (message: string) => {
    {
      const { sendPing } = this.props;
      sendPing({ postData: { message, message_type: PingTypes.PING_DATASOURCE_TESTING } });
    }
  };

  sendTestConnectionResultPing = (isSuccess: boolean) => {
    this.sendTestPing(
      isSuccess ? this.getTestPingMsg() : `<!channel> ${this.getTestPingMsg(true)}`,
    );
  };

  onTestConnectDataSourceSuccess = (data: TestDataSourceConnectionData) => {
    this.setState({
      testingConnectionStatus: RD.Success({
        numberOfTables: data.num_tables,
        quantification: data.quantification,
      }),
    });
    this.updateStep(ConnectDataSourceStep.REVIEW);
    this.sendTestConnectionResultPing(true);
  };

  onTestConnectDataSourceError = (error: string | undefined) => {
    this.setState({
      testingConnectionStatus: RD.Error(error || ERROR_CONNECTING_MSG),
    });
    this.updateStep(ConnectDataSourceStep.REVIEW);
    this.sendTestConnectionResultPing(false);
  };

  onConnectDataSourceSuccess = () => {
    const { isNewSchema } = this.state;
    this.props.history.push(ROUTES.DATA_PAGE);
    if (isNewSchema) showSuccessToast(NEW_SCHEMA_CREATED_SUCCESS);
  };

  connectDataSource = () => {
    const {
      createNamespace,
      createDataSourceInFido,
      connectDataSource,
      useFido,
      fidoDataSourceConfig,
      setConnectingError,
    } = this.props;
    const { connectionConfig, selectedSchema, selectedAccessGroupIds, isNewSchema } = this.state;
    const { finalConfig } = fidoDataSourceConfig;

    if (useFido) {
      if (
        !selectedSchema?.id ||
        !connectionConfig.name ||
        !finalConfig ||
        !connectionConfig.providedId
      ) {
        setConnectingError(ERROR_CONNECTING_MSG);
        return;
      }

      const dataSourceName = connectionConfig.name;

      if (isNewSchema) {
        return createNamespace({
          schemaName: selectedSchema.name,
          dataSourceName,
          externalId: connectionConfig.providedId,
          accessGroupIds: selectedAccessGroupIds,
          finalConfig,
          onSuccess: this.onConnectDataSourceSuccess,
        });
      }
      if (!selectedSchema.fido_id) {
        setConnectingError(`There is an error with your selected schema`);
        return;
      } else
        return createDataSourceInFido({
          namespaceId: selectedSchema.fido_id,
          namespaceName: selectedSchema.name,
          dataSourceRequest: {
            dataSource: {
              name: dataSourceName,
              externalId: connectionConfig.providedId,
              id: uuidv4(),
              namespaceId: selectedSchema.fido_id,
              configuration: finalConfig,
            },
          },
          accessGroupIds: selectedAccessGroupIds,
          onSuccess: () => this.props.history.push(ROUTES.DATA_PAGE),
        });
    }

    if (
      !connectionConfig.selectedDataSource ||
      !selectedSchema ||
      selectedAccessGroupIds.length === 0
    )
      return;

    this.setState({ isConnectingToDataSource: true });

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig || {},
    );
    if (error !== undefined) {
      this.setState({
        testingConnectionStatus: RD.Error(error?.toString()),
        isConnectingToDataSource: false,
      });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    if (connectionConfig.name === undefined) {
      this.setState({
        testingConnectionStatus: RD.Error('Data source name must be defined.'),
        isConnectingToDataSource: false,
      });
      return;
    }

    connectDataSource(
      {
        postData: {
          name: connectionConfig.name,
          provided_id: connectionConfig.providedId,
          type: connectionConfig.selectedDataSource.type,
          configuration: parsedConfig,
          schema: selectedSchema,
          access_group_ids: selectedAccessGroupIds,
        },
      },
      this.onConnectDataSourceSuccess,
      (response) =>
        this.setState({
          testingConnectionStatus: RD.Error(response?.error_msg || ERROR_CONNECTING_MSG),
          isConnectingToDataSource: false,
        }),
    );
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobs } = this.props;

    if (jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobs?.({ jobs: jobMap }, (jobs) =>
      this.setState((currentState) => {
        return {
          awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
            ...draft,
            ...jobs,
          })),
        };
      }),
    );
  };
}

const mapStateToProps = (state: ReduxState) => {
  const useFido = state.currentUser.team?.feature_flags.use_fido;
  const type = state.fidoDataSourceConfig.dataSourceConfig.type;
  const usedParentSchemas = state.parentSchemas.usedParentSchemas;
  return {
    supportedDataSources: state.dataSource.supportedDataSources,
    dataSources: getTeamDataSources(state),
    widget: state.widget,
    currentUser: state.currentUser,
    shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
    useFido: useFido && (!type || SUPPORTED_FIDO_DATA_SOURCES.includes(type)),
    fidoDataSourceConfig: state.fidoDataSourceConfig,
    fidoDaos: state.fido.fidoDaos,
    readyToFetchNameSpaces: useFido
      ? RD.isSuccess(usedParentSchemas) && RD.isIdle(state.fido.fidoDaos)
      : false,
    allParentSchemas: usedParentSchemas,
  };
};

const mapDispatchToProps = {
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchUsedParentSchemas,
  getNamespaces,
  listTeamDataSources,
  fetchAccessGroups,
  updateWidgetType,
  sendPing,
  bulkEnqueueJobs,
  testConnectionUsingFido,
  createNamespace,
  createDataSourceInFido,
  setConnectingError,
  setFinalDataSourceConfig,
  setDataSourceType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ConnectDataSourceFlow));
