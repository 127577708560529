import { useContext, FC } from 'react';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { EmbedButton, EmbedPopover } from 'components/embed';
import { UserTransformedSchema } from 'constants/types';

import { TransformationSettingsMenu } from './TransformationSettingsMenu';

type Props = {
  isDrilldownModal: boolean;
  loading: boolean;
  userTransformedSchema: UserTransformedSchema;
  updateUserTransformedSchema: (newSchema: UserTransformedSchema) => void;
};

export const TransformationSettingsButton: FC<Props> = ({
  loading,
  userTransformedSchema,
  updateUserTransformedSchema,
  isDrilldownModal,
}) => {
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);

  return (
    <EmbedPopover
      align="end"
      portalContainerId={isDrilldownModal ? undefined : dashboardLayoutTagId}
      trigger={<EmbedButton disabled={loading} icon="gear" variant="tertiary" />}>
      <TransformationSettingsMenu
        schema={userTransformedSchema}
        updateSchema={updateUserTransformedSchema}
      />
    </EmbedPopover>
  );
};
