import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import { CustomerPermissionsSetter } from 'components/CustomerPermissions/Setter';
import { Modal, sprinkles } from 'components/ds';
import {
  setEditableSectionModal,
  updateEditableSectionChartPermissions,
} from 'reducers/dashboardEditConfigReducer';
import { EditableSectionChart } from 'types/dashboardVersionConfig';

type Props = { chart: EditableSectionChart };

export const EditChartPermissionsModal: FC<Props> = ({ chart }) => {
  const dispatch = useDispatch();

  const [permissions, setPermissions] = useImmer(chart.permissions);

  const onClose = () => dispatch(setEditableSectionModal(null));

  return (
    <Modal
      isOpen
      contentClassName={sprinkles({ paddingX: 'sp2', paddingY: 'sp1' })}
      onClose={onClose}
      primaryButtonProps={{
        text: 'Save',
        onClick: () => dispatch(updateEditableSectionChartPermissions(permissions)),
      }}
      size="medium"
      title={`Edit ${chart.name} Permissions`}>
      <CustomerPermissionsSetter permissions={permissions} setPermissions={setPermissions} />
    </Modal>
  );
};
