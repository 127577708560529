import cx from 'classnames';
import { FC, useEffect, useState } from 'react';

import { sprinkles } from 'components/ds';

import { EmbedText } from '../EmbedText';

type Props = {
  description: string;
};

export const ReportDescription: FC<Props> = ({ description }) => {
  const [descriptionRef, setDescriptionRef] = useState<HTMLDivElement | null>(null);

  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!descriptionRef) return;
    setShowMoreButton(descriptionRef.scrollWidth > descriptionRef.offsetWidth);
  }, [descriptionRef]);

  const renderShowMore = () => (
    <span
      className={sprinkles({ marginLeft: 'sp1', cursor: 'pointer' })}
      onClick={() => setShowMore(!showMore)}>
      <EmbedText body="b2" color="active">
        Show {showMore ? 'Less' : 'More'}
      </EmbedText>
    </span>
  );

  return (
    <div className={sprinkles({ width: 'fill', display: 'flex' })}>
      <div
        className={cx(sprinkles({ flex: 1, truncateText: 'ellipsis' }), {
          [sprinkles({ whiteSpace: 'pre-line' })]: showMore,
        })}
        ref={setDescriptionRef}>
        <EmbedText body="b2" color="contentTertiary">
          {description}
        </EmbedText>
        {showMore ? renderShowMore() : null}
      </div>
      {showMoreButton && !showMore ? renderShowMore() : null}
    </div>
  );
};
