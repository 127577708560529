import cx from 'classnames';
import { FC, useState } from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, EditableInput } from 'react-color/lib/components/common';
import tinycolor from 'tinycolor2';

import { sprinkles, Icon, APP_PORTAL_ID, Popover } from 'components/ds';

import * as styles from './index.css';

type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * optional class to put on the button
   */
  btnClassName?: string;
  /**
   * selected hex code
   */
  color: string;
  /**
   * color palette to show as pre-selected options
   */
  colorPalette: string[];
  fillWidth?: boolean;
  onColorChange: (hex: string) => void;
  onCancel?: () => void;
  usePortal?: boolean;
  size?: number;
};

const ColorPicker: FC<Props> = ({
  className,
  color,
  onColorChange,
  colorPalette,
  fillWidth,
  btnClassName,
  onCancel,
  usePortal = true,
  size,
}) => {
  const colorObj = tinycolor(color);
  const [newColor, setNewColor] = useState(color);
  const [hsl, setHsl] = useState(colorObj.toHsl());
  const [hsv, setHsv] = useState(colorObj.toHsv());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const update = (color: any) => {
    const parsedColor = tinycolor(color);
    setHsl(parsedColor.toHsl());
    setHsv(parsedColor.toHsv());
    setNewColor(parsedColor.toHexString());
  };

  const renderHuePointer = () => <div className={styles.huePointer} />;
  const renderSaturationPointer = () => <div className={styles.saturationPointer} />;

  const triggerSizeStyles = size ? { height: size, width: size, minWidth: size } : undefined;

  const trigger = (
    <div
      className={cx(styles.buttonRoot, btnClassName, {
        [sprinkles({ border: 1, borderColor: 'gray7' })]: colorObj.getBrightness() > 200,
      })}
      style={{ backgroundColor: color, ...triggerSizeStyles }}>
      {onCancel ? (
        <div
          className={styles.cancelButton}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}>
          <Icon className={sprinkles({ color: 'active' })} name="cross" size="sm" />
        </div>
      ) : null}
    </div>
  );

  return (
    <Popover
      className={cx(className, { [sprinkles({ width: 'fill' })]: fillWidth })}
      onOpenChange={(open) => !open && onColorChange(newColor)}
      portalContainerId={usePortal ? APP_PORTAL_ID : undefined}
      trigger={trigger}>
      <div className={containerClass}>
        <div className={styles.saturationBlock}>
          <Saturation
            //@ts-ignore
            hsl={hsl}
            hsv={hsv}
            onChange={update}
            pointer={renderSaturationPointer}
          />
        </div>
        <div className={sprinkles({ padding: 'sp1' })}>
          <div className={styles.hueBlock}>
            <Hue
              direction="horizontal"
              // @ts-ignore
              hsl={hsl}
              onChange={update}
              pointer={renderHuePointer}
            />
          </div>
          <div className={styles.paletteContainer}>
            {colorPalette.map((paletteColor, index) => (
              <div
                className={styles.colorPaletteBox}
                key={`${paletteColor}-${index}`}
                onClick={() => update(paletteColor)}
                style={{ backgroundColor: paletteColor }}
              />
            ))}
          </div>
          <div className={styles.inputContainer}>
            <EditableInput onChange={update} value={newColor} />
          </div>
        </div>
      </div>
    </Popover>
  );
};

const containerClass = sprinkles({
  overflow: 'hidden',
  width: 'fill',
  borderRadius: 4,
  backgroundColor: 'white',
  boxShadow: 'md',
});

export const ColorPickerButton = CustomPicker(ColorPicker);
