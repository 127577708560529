import { FC } from 'react';

import { Tag } from 'components/ds';
import { Props as TagProps } from 'components/ds/Tag';
import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';

import { ScheduledEmailsTag } from '../ScheduledEmailsTag';
import { StarStatus, Props as StarProps } from '../StarStatus';

import * as styles from './BaseListItem.css';

type ListItemTagProps = Omit<TagProps, 'children'> & { children?: string };

type Props = {
  name: string;
  description?: string;
  modified: string; // Datetime when it was modified
  onClick: () => void;
  tags?: ListItemTagProps[];
  menuOptions: DropdownOption[];
  menuDisabled?: boolean;
  menuLoading?: boolean;
  id: string;
} & StarProps;

export const BaseListItem: FC<Props> = ({
  name,
  description,
  onClick,
  modified,
  tags,
  menuOptions,
  menuDisabled,
  menuLoading,
  isStarred,
  reportInfo,
  id,
}) => {
  return (
    <div className={styles.baseListItem} onClick={(e) => !e.defaultPrevented && onClick()}>
      <div className={styles.nameContainer}>
        <StarStatus isStarred={isStarred} reportInfo={reportInfo} />
        <EmbedText className={styles.text} color="contentPrimary" heading="h4">
          {name}
        </EmbedText>
      </div>
      <EmbedText body="b2" className={styles.description} color="contentTertiary">
        {description}
      </EmbedText>
      <div className={styles.actionContainer}>
        <EmbedText
          body="b3"
          className={styles.text}
          color="contentTertiary">{`Last edited ${getTimezoneAwareDate(
          modified,
        ).toRelative()}`}</EmbedText>
        {tags?.map((tagProps) => (
          <Tag key={tagProps.children} {...tagProps} />
        ))}
        <ScheduledEmailsTag id={id} />
        <div onClick={(e) => e.preventDefault()}>
          <EmbeddedDropdownMenu
            disabled={menuDisabled || menuLoading || !menuOptions.length}
            menuOptions={menuOptions}>
            <EmbedButton
              className={styles.dropdownIcon}
              disabled={menuDisabled || !menuOptions.length}
              icon="ellipsis-vertical"
              loading={menuLoading}
              variant="tertiary"
            />
          </EmbeddedDropdownMenu>
        </div>
      </div>
    </div>
  );
};
