import { ComponentType, FC, forwardRef } from 'react';
import { SizeMe } from 'react-sizeme';

const DEFAULT_REFRESH_RATE = 250; // milliseconds, react-sizeme default is 16
const DEFAULT_MONITOR_WIDTH = true;
const DEFAULT_MONITOR_HEIGHT = false;

export interface WithWidthProps {
  monitorWidth?: boolean;
  monitorHeight?: boolean;
  refreshRate?: number;
  width?: number;
}

export const withWidth = <P extends object>(
  Component: ComponentType<P>,
): FC<P & WithWidthProps> => {
  // @ts-ignore
  // eslint-disable-next-line react/display-name
  return forwardRef((props, ref) => {
    const {
      monitorWidth = DEFAULT_MONITOR_WIDTH,
      monitorHeight = DEFAULT_MONITOR_HEIGHT,
      refreshRate = DEFAULT_REFRESH_RATE,
    } = props;
    return (
      <SizeMe monitorHeight={monitorHeight} monitorWidth={monitorWidth} refreshRate={refreshRate}>
        {({ size }) => <Component ref={ref} width={size.width} {...props} />}
      </SizeMe>
    );
  });
};
