import { DateTime } from 'luxon';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePickerInput } from 'components/DatePickerInput';
import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { Button, Select, Input, sprinkles } from 'components/ds';
import {
  BOOLEAN,
  STRING,
  SchemaDataType,
  SchemaDataTypeIcon,
  TIMESTAMP,
} from 'constants/dataConstants';
import { deleteDashboardParam } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { updateVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { sortBy } from 'utils/standard';

import { ManageCustomVariableModal } from './ManageCustomVariableModal';
import * as styles from './styles.css';

type Props = {
  dashboardId: number;
  variables: DashboardVariableMap;
  searchQuery: string;
  readOnly?: boolean;
};

export const CustomVariables: FC<Props> = ({ dashboardId, variables, searchQuery, readOnly }) => {
  const dispatch = useDispatch();

  // Null means its creating a new param
  const [editingParam, setEditingParam] = useState<DashboardParam | null | undefined>();

  const customParams = useSelector(
    (state: ReduxState) => state.dashboardEditConfig.config?.params ?? {},
  );

  const orderedParams = useMemo(
    () =>
      sortBy(
        Object.values(customParams).filter(
          (param) => searchQuery === undefined || param.name.toLowerCase().includes(searchQuery),
        ),
        (param) => param.name.toLowerCase(),
      ),
    [customParams, searchQuery],
  );

  const updateVariable = (varName: string, newValue: DashboardVariable) =>
    dispatch(updateVariableThunk({ varName, newValue }));

  const renderRightElement = ({ type, name }: DashboardParam, value: DashboardVariable) => {
    if (readOnly) return JSON.stringify(value);
    if (type === TIMESTAMP)
      return (
        <DatePickerInput
          showCancelBtn
          className={styles.rightElementStyle}
          onNewValueSelect={(newValue) => updateVariable(name, (newValue as DateTime) || undefined)}
          selectedValue={
            typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
          }
          showTimeSelect={false}
        />
      );
    if (type === BOOLEAN) {
      return (
        <Select
          className={styles.rightElementStyle}
          onCancel={() => updateVariable(name, undefined)}
          onChange={(newValue) => updateVariable(name, newValue)}
          selectedValue={value as string}
          values={[{ value: 'true' }, { value: 'false' }]}
        />
      );
    }
    const isString = type === STRING;
    return (
      <Input
        className={styles.rightElementStyle}
        defaultValue={value as string}
        onSubmit={(newVal) => {
          if (!newVal.trim()) return updateVariable(name, undefined);
          let newValue: number | string | undefined = newVal;
          if (!isString) {
            const numValue = Number(newVal);
            newValue = isNaN(numValue) ? undefined : numValue;
          }
          updateVariable(name, newValue);
        }}
        type={isString ? 'text' : 'number'}
      />
    );
  };

  return (
    <ConfigSection
      defaultOpen
      icon="brackets"
      infoText="Variables set below will not persist on refresh or when embedded. Only default values set for these variables will persist."
      title="Embed variables"
      variant="header2">
      {!readOnly ? (
        <div className={sprinkles({ padding: 'sp2' })}>
          <Button fillWidth icon="plus" onClick={() => setEditingParam(null)}>
            Add an embed variable
          </Button>
        </div>
      ) : null}
      {orderedParams.map((param) => {
        return (
          <PanelListItem
            copiable={readOnly}
            key={param.id}
            leftIcon={SchemaDataTypeIcon[param.type as SchemaDataType]}
            name={param.name}
            onDelete={readOnly ? undefined : () => dispatch(deleteDashboardParam(param.id))}
            onEdit={readOnly ? undefined : () => setEditingParam(param)}
            rightElement={renderRightElement(param, variables[param.name])}
          />
        );
      })}
      {editingParam !== undefined ? (
        <ManageCustomVariableModal
          dashboardId={dashboardId}
          onClose={() => setEditingParam(undefined)}
          param={editingParam}
        />
      ) : null}
    </ConfigSection>
  );
};
