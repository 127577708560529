import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { CollapsibleGroup } from 'components/CollapsibleGroup';
import { Select, sprinkles } from 'components/ds';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import {
  DEFAULT_Y_AXIS_CHART_INDEX,
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  getYAxisFormatIndex,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { isEqual } from 'utils/standard';

import { MultiYAxisConfig } from './MultiYAxisConfig';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  isForHorizontalChart?: boolean;
};

export const YAxisGroupConfig: FC<Props> = ({
  visualizationType,
  instructions,
  isForHorizontalChart,
}) => {
  const dispatch = useDispatch();

  const axisName = isForHorizontalChart ? 'X-Axis' : 'Y-Axis';

  return (
    <div className={configRootClass}>
      {instructions.yAxisFormats?.map((yAxisFormat, index) => {
        return (
          <CollapsibleGroup
            className={sprinkles({ marginX: 'sp1.5', marginBottom: 'sp1.5' })}
            isNotDeletable={instructions?.yAxisFormats?.length === 1}
            key={index}
            onDelete={() => {
              if (!instructions.yAxisFormats) return;
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.yAxisFormats) return;
                const deletedYAxisFormatId = draft.yAxisFormats[index].id;
                draft.yAxisFormats?.splice(index, 1);
                draft.aggColumns
                  ?.filter((aggColumn) => aggColumn.yAxisFormatId === deletedYAxisFormatId)
                  .forEach((aggColumn) => {
                    aggColumn.yAxisFormatId = draft.yAxisFormats?.[DEFAULT_Y_AXIS_CHART_INDEX]?.id;
                  });
              });
              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            title={getAxisName(index, axisName)}>
            <MultiYAxisConfig
              instructions={instructions}
              key={yAxisFormat?.id}
              visualizationType={visualizationType}
              yAxisFormatId={yAxisFormat?.id}
            />
          </CollapsibleGroup>
        );
      })}
      {
        <div
          className={sprinkles({
            body: 'section',
            color: 'contentPrimary',
            marginY: 'sp1',
            paddingX: 'sp1.5',
          })}>
          Assign Axes
        </div>
      }
      {instructions.aggColumns?.map((aggColumn) => {
        const header = `${aggColumn.column.friendly_name || getColDisplayText(aggColumn)}`;
        return (
          <Select
            className={configInputClass}
            key={header}
            label={header}
            onChange={(value) => {
              const newInstructions = produce(instructions, (draft) => {
                const newAggColumn = (draft.aggColumns || []).find((newAggColumn) =>
                  isEqual(aggColumn, newAggColumn),
                );
                if (!newAggColumn) return;
                newAggColumn.yAxisFormatId = value;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            placeholder={`Select ${axisName}`}
            selectedValue={getAxisSelectedItem(aggColumn.yAxisFormatId, instructions)}
            values={(instructions.yAxisFormats ?? []).map((yAxisFormat, index) => ({
              value: yAxisFormat.id ?? String(index),
              label: getAxisName(index, axisName),
            }))}
          />
        );
      })}
    </div>
  );
};

const getAxisSelectedItem = (
  yAxisFormatId?: string,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const calculatedYAxisFormatIndex = yAxisFormatId
    ? getYAxisFormatIndex(yAxisFormatId, instructions)
    : DEFAULT_Y_AXIS_FORMAT_INDEX;
  return calculatedYAxisFormatIndex === -1 ? undefined : yAxisFormatId;
};
const getAxisName = (yAxisFormatIndex: number, axisName: string) => {
  return `${axisName} #${yAxisFormatIndex + 1}`;
};
