/**
 * Controls which customers has access to an object
 * If allCustomers is true, the object in will be available to all customers regardless of customerIds
 */
export interface CustomerPermissionsForObject {
  /** If true, this is visible to all customers */
  allCustomers: boolean;
  /** Object is visible to specific customer Ids */
  customerIds: number[];
  /** Object is visible to specific group tag Ids */
  groupTagIds?: number[];
}

export const initCustomerPermissionsForObject = (): CustomerPermissionsForObject => ({
  allCustomers: true,
  customerIds: [],
  groupTagIds: [],
});
