import { FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { DatasetModal } from 'components/DatasetModal';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { editDatasetNameThunk } from 'reducers/thunks/dashboardDataThunks/modifyDatasetThunks';
import { getDatasetName } from 'utils/naming';

type Props = {
  onClose: () => void;
  datasetConfigs: Record<string, Dataset>;
  editDatasetId: string | null;
};

export const EditDatasetModal: FC<Props> = ({ datasetConfigs, editDatasetId, onClose }) => {
  const dispatch = useDispatch();
  const parentSchemas = useSelector(getParentSchemasList);

  const editDataset = editDatasetId && datasetConfigs[editDatasetId];

  const defaultParentSchema = useMemo(
    () =>
      !editDataset
        ? undefined
        : parentSchemas?.find(
            (schema) =>
              schema.id === editDataset.parent_schema_id ||
              schema.fido_id === String(editDataset.parent_schema_id),
          ),
    [editDataset, parentSchemas],
  );

  const currentDatasetNames = useMemo(
    () =>
      new Set(
        Object.values(datasetConfigs).map((datasetConfig) => getDatasetName(datasetConfig, false)),
      ),
    [datasetConfigs],
  );

  if (!editDataset) return null;

  const editingDatasetName = getDatasetName(datasetConfigs[editDataset.id], false);

  const handleError = (newVal?: string) => {
    return {
      error:
        newVal && currentDatasetNames.has(newVal) && editingDatasetName !== newVal
          ? 'There is already a dataset with this name. Please choose another name for the dataset.'
          : undefined,
    };
  };

  return (
    <DatasetModal
      hideSchemaDropdown
      isOpen
      buttonTitle="Save"
      datasetName={editingDatasetName}
      defaultParentSchema={defaultParentSchema}
      errorState={handleError}
      onClose={onClose}
      onSubmit={(name) => {
        dispatch(editDatasetNameThunk(editDataset.id, name));
      }}
      title="Edit Dataset"
    />
  );
};
