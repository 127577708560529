import { FC } from 'react';

import { sprinkles } from 'components/ds';

type Props = {
  primaryContent?: JSX.Element;
  description?: React.ReactChild;
  icon?: JSX.Element;
  secondaryContent?: JSX.Element;
  title: React.ReactNode;
};

export const DatasetEditorNonIdealState: FC<Props> = ({
  primaryContent,
  description,
  icon,
  secondaryContent,
  title,
}) => {
  return (
    <div className={scrollContainerClassName}>
      <div className={containerClassName}>
        {icon ?? null}
        <div className={sprinkles({ heading: 'h3' })}>{title}</div>
        {description ? <div className={sprinkles({ body: 'b2' })}>{description}</div> : null}
        {primaryContent ?? null}
        {secondaryContent ?? null}
      </div>
    </div>
  );
};

const scrollContainerClassName = sprinkles({ height: 'fill', overflowY: 'auto' });

const containerClassName = sprinkles({
  flexItems: 'centerColumn',
  color: 'contentSecondary',
  textAlign: 'center',
  gap: 'sp1.5',
  padding: 'sp3',
  height: 'fill',
});
