import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SizeMe } from 'react-sizeme';

import { DatasetDataObject } from 'actions/datasetActions';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import DropdownSelect from 'shared/DropdownSelect';
import { SelectElemConfig, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';

type Props = {
  config: SelectElemConfig;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  onNewValueSelect: OnNewValueSelectFunc;
  value?: DashboardVariable;
  disabled?: boolean;
  openElementToLeft: boolean;
  variables?: DashboardVariableMap;
  isInContainer?: boolean;
};

export const DashboardDropdownElement: FC<Props> = ({
  config,
  value,
  onNewValueSelect,
  datasetNamesToId,
  datasetData,
  disabled,
  openElementToLeft,
  variables,
  isInContainer,
}) => {
  const dispatch = useDispatch();

  const options = constructOptions(config.valuesConfig, datasetData);
  let selectedOption = options.find((option) => option.value === value);

  // If the selected value is not in the options, it is coming from a default variable.
  // In this case create your own option for this
  if (value && !selectedOption) {
    selectedOption = { id: String(value), name: String(value) };
  }

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  const renderDropdownSelect = (maxWidth?: number | null) => {
    return (
      <DropdownSelect
        fillWidth
        isEmbed
        minimal
        disableOnNoItems={config.disableOnNoItems}
        disabled={disabled}
        filterPlaceholderText={config.filterPlaceholderText || 'Filter...'}
        filterable={!config.disableSearch}
        label={label}
        labelHelpText={resolveTooltipVariables(config, variables, datasetNamesToId, datasetData)}
        maxWidth={maxWidth ?? undefined}
        noSelectionText={config.placeholderText || ''}
        onCancelClick={() => {
          onNewValueSelect(undefined, { display: undefined });
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
        }}
        onChange={(item) => {
          onNewValueSelect(item.value, { display: item.name });
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
        }}
        openElementToLeft={openElementToLeft}
        options={options}
        selectedItem={selectedOption}
        showCancelBtn={!config.disableCancel}
        usePortal={isInContainer}
      />
    );
  };

  return (
    /* Don't need the width if not using portal */
    isInContainer ? (
      <SizeMe>{({ size }) => renderDropdownSelect(size.width)}</SizeMe>
    ) : (
      renderDropdownSelect()
    )
  );
};
