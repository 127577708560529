import { FC, useState } from 'react';

import { Icon, sprinkles } from './ds';

type Props = {
  className?: string;
  title: string;
};

export const CollapsibleMenu: FC<Props> = ({ children, title, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={className}>
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          color: 'gray11',
          cursor: 'pointer',
          backgroundColor: isExpanded ? 'gray3' : undefined,
          body: 'b3',
        })}
        onClick={() => setIsExpanded(!isExpanded)}>
        <Icon
          className={sprinkles({ margin: 'sp.5', color: 'gray11' })}
          name={isExpanded ? 'chevron-down' : 'chevron-right'}
        />
        {title}
      </div>
      {isExpanded ? (
        <div className={sprinkles({ padding: 'sp2', backgroundColor: 'gray1', borderRadius: 4 })}>
          {children}
        </div>
      ) : null}
    </div>
  );
};
