import cx from 'classnames';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  DatasetConfig,
  DatasetData,
  updateDatasetDrilldownColConfig,
} from 'actions/datasetActions';
import { BooleanFormatConfig } from 'components/ColumnFormatConfigs/BooleanFormatConfig';
import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { StringFormatConfig } from 'components/ColumnFormatConfigs/StringFormatConfig';
import { sprinkles, Icon } from 'components/ds';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  DisplayOptions,
  NumberDisplayOptions,
  StringDisplayOptions,
  OPERATION_TYPES,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { DatasetSchema } from 'types/datasets';

import { ColumnFormatItem } from './ColumnFormatItem';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import * as styles from './styles.css';

type Props = {
  datasetConfig: DatasetConfig;
  datasetData: DatasetData;
  datasetSchema: DatasetSchema | undefined;
};

export const DrilldownDatasetColumnsFormatConfig: FC<Props> = ({
  datasetConfig,
  datasetData,
  datasetSchema,
}) => {
  const dispatch = useDispatch();

  const { selectedDrilldownColumn, globalStyleConfig, newGridEnabled } = useSelector(
    (state: ReduxState) => ({
      selectedDrilldownColumn: state.cssLinks.selectedDrilldownColumn,
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      newGridEnabled: !!state.currentUser?.team?.feature_flags.enable_new_grid,
    }),
    shallowEqual,
  );

  const renderClickColumnToEditFormatting = () => (
    <>
      <Icon name="hand-pointer" />
      <div className={sprinkles({ body: 'b3', textAlign: 'center' })}>
        Click on any column to edit formatting
      </div>
    </>
  );

  const drilldownConfigs = datasetConfig.drilldownColumnConfigs;
  const selectedDrilldownColumnConfig =
    drilldownConfigs && selectedDrilldownColumn
      ? drilldownConfigs[selectedDrilldownColumn.name]
      : undefined;

  const displayFormatting = selectedDrilldownColumnConfig?.displayFormatting;

  const updateFormatting = (updates: DisplayOptions) => {
    if (!selectedDrilldownColumn || !drilldownConfigs) return;

    const newFormatting = displayFormatting ? { ...displayFormatting, ...updates } : updates;
    const colName = selectedDrilldownColumn.name;

    dispatch(
      updateDatasetDrilldownColConfig({
        datasetId: datasetConfig.id,
        colName,
        displayFormatting: newFormatting,
      }),
    );
  };

  const renderColFormattingType = () => {
    if (!selectedDrilldownColumn || !drilldownConfigs) return null;

    if (selectedDrilldownColumn.type === BOOLEAN) {
      return (
        <BooleanFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as BooleanDisplayOptions}
          globalStyleConfig={globalStyleConfig}
          isNewDataGrid={newGridEnabled}
          updateBooleanOptions={updateFormatting}
        />
      );
    }
    if (selectedDrilldownColumn.type === STRING) {
      if (!datasetSchema) return null;
      return (
        <StringFormatConfig
          column={selectedDrilldownColumn}
          dataPanelData={datasetData.rows ?? []}
          displayOptions={displayFormatting as StringDisplayOptions}
          globalStyleConfig={globalStyleConfig}
          originalSchema={datasetSchema}
          updateStringOptions={updateFormatting}
        />
      );
    }
    if (DATE_TYPES.has(selectedDrilldownColumn.type)) {
      return (
        <DateFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as DateDisplayOptions}
          operationType={OPERATION_TYPES.VISUALIZE_TABLE}
          updateDateOptions={updateFormatting}
        />
      );
    }
    if (NUMBER_TYPES.has(selectedDrilldownColumn.type)) {
      return (
        <NumberFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as NumberDisplayOptions}
          operationType={OPERATION_TYPES.VISUALIZE_TABLE}
          updateNumberOptions={updateFormatting}
        />
      );
    }
    return null;
  };

  const noIncludedColumns =
    drilldownConfigs && !Object.values(drilldownConfigs)?.filter((col) => col.isIncluded).length;

  const formatConfigWidth = styles.FORMATTING_CONFIG_ROOT_WIDTH;
  return (
    <div
      className={styles.formattingConfigRoot}
      style={{
        width: formatConfigWidth,
        minWidth: formatConfigWidth,
      }}>
      <div className={sprinkles({ heading: 'h4' })}>Format</div>
      <div
        className={cx(
          styles.formattingTabWrapperClass,
          sprinkles({ justifyContent: selectedDrilldownColumn ? 'flex-start' : 'center' }),
        )}>
        {noIncludedColumns ? (
          <DatasetEditorNonIdealState
            description="Select some columns to get started."
            title="No visible columns"
          />
        ) : selectedDrilldownColumn ? (
          <ColumnFormatItem
            colName={selectedDrilldownColumnConfig?.displayName || selectedDrilldownColumn.name}
            updateColumnInfo={(updates) => {
              if (!updates.name || !drilldownConfigs) return;

              dispatch(
                updateDatasetDrilldownColConfig({
                  datasetId: datasetConfig.id,
                  colName: selectedDrilldownColumn.name,
                  displayName: updates.name,
                }),
              );
            }}>
            {renderColFormattingType()}
          </ColumnFormatItem>
        ) : (
          renderClickColumnToEditFormatting()
        )}
      </div>
    </div>
  );
};
