import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { sprinkles, Button } from './ds';

type Props = {
  text: string;
};

export const ErrorState: FC<Props> = ({ text }) => {
  const history = useHistory();

  return (
    <div className={sprinkles({ flexItems: 'centerColumn', margin: 'auto' })}>
      <p>{text}</p>
      <Button onClick={() => history.push('/home')} variant="secondary">
        Click here to go back to the homepage.
      </Button>
    </div>
  );
};
