import { FC, forwardRef } from 'react';

import { Button } from 'components/ds';
import { LinkProps } from 'components/ds/Button';

export type Props = Omit<LinkProps, 'variant'> & {
  // Determines style of the link
  variant?: 'primary' | 'secondary';
};

export const Link: FC<Props> = forwardRef<HTMLAnchorElement, Props>(
  ({ className, children, fillWidth, to, variant, ...props }, ref) => {
    return (
      <Button
        {...props}
        linkIcon
        className={className}
        fillWidth={fillWidth}
        parentRef={ref}
        to={to}
        variant={variant === 'primary' ? 'secondary' : 'tertiary'}>
        {children}
      </Button>
    );
  },
);

Link.displayName = 'Link';
