import { FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { DatasetModal } from 'components/DatasetModal';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { createDatasetThunk } from 'reducers/thunks/dashboardDataThunks/modifyDatasetThunks';
import { getDatasetName } from 'utils/naming';

type Props = {
  onClose: () => void;
  datasetConfigs: Record<string, Dataset>;
  dashboardId: number;
};

export const CreateDatasetModal: FC<Props> = ({ datasetConfigs, onClose, dashboardId }) => {
  const dispatch = useDispatch();

  const parentSchemas = useSelector(getParentSchemasList);

  const currentDatasetNames = useMemo(
    () =>
      new Set(
        Object.values(datasetConfigs).map((datasetConfig) => getDatasetName(datasetConfig, false)),
      ),
    [datasetConfigs],
  );

  const handleError = (newDatasetName?: string) => ({
    error:
      newDatasetName && currentDatasetNames.has(newDatasetName)
        ? 'There is already a dataset with this name. Please choose another name for the dataset.'
        : undefined,
  });

  return (
    <DatasetModal
      isOpen
      buttonTitle="Create"
      defaultParentSchema={parentSchemas[0]}
      errorState={handleError}
      onClose={onClose}
      onSubmit={(name, parentSchemaId) =>
        dispatch(createDatasetThunk(name, parentSchemaId, dashboardId))
      }
      title="Create Dataset Option"
    />
  );
};
