import * as Redux from 'redux';

import {
  updateConversationStarted,
  updateIsWidgetOpen,
  updateWidgetType,
} from 'actions/chatWidgetActions';
import { WIDGET_TYPES } from 'constants/hubspotConstants';

export const updatePageSpecificChatWidget = (isWidgetOpen: boolean | undefined) => {
  if (isWidgetOpen === undefined && window.HubSpotConversations) {
    // this util is only called on mount of a specific page - if a user navigates to a specific page and
    // a different specific widget is already open - close it and open the corresponding widget for that page
    window.HubSpotConversations.widget.remove();
    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
  }
  setWidgetStyle('initial');
};

export const onWidgetClick = (
  currentWidgetType: WIDGET_TYPES | undefined,
  isWidgetOpen: boolean | undefined,
  dispatch: Redux.Dispatch,
) => {
  const pathMatchesWidgetType =
    (currentWidgetType === WIDGET_TYPES.CONNECT_DATA && window.location.pathname === '/connect') ||
    (currentWidgetType === WIDGET_TYPES.SYNC_TABLES &&
      window.location.pathname.includes('/sync-tables/'));

  if (isWidgetOpen && !(currentWidgetType === WIDGET_TYPES.GENERAL) && !pathMatchesWidgetType) {
    // on click, if a page specific widget is open but the user is not on that page,
    // we want to end that conversation, remove the widget, and reset isWidgetOpen back
    // to undefined
    window.HubSpotConversations.widget.remove();
    dispatch(updateIsWidgetOpen({ isWidgetOpen: undefined }));
    dispatch(updateConversationStarted({ conversationStarted: false }));
    return;
  }

  if (isWidgetOpen === undefined) {
    window.HubSpotConversations.widget.load({ widgetOpen: true });
    window.HubSpotConversations.widget.open();
    dispatch(updateWidgetType({ widgetType: WIDGET_TYPES.GENERAL }));
  }
  // on click, if the widget is not defined, we want to open the general widget
  dispatch(updateIsWidgetOpen({ isWidgetOpen: !isWidgetOpen }));
  setWidgetStyle(!isWidgetOpen ? 'initial' : 'none');
};

// this sets the CSS of the widget based on if the widget should be hidden or visible
const setWidgetStyle = (displayValue: string) => {
  const widget = document.getElementById(
    'hubspot-messages-iframe-container',
  ) as HTMLDivElement | null;
  if (widget === null) return;

  widget.style.setProperty('display', displayValue, 'important');
};
