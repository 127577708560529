import {
  AggregateProperty,
  Aggregation,
  And,
  Computation,
  Filter,
  SortDirection,
  SourceProperty,
} from '@explo-tech/fido-api';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { CustomerReportAgg } from 'actions/customerReportActions';
import {
  FilterOperationInstructions,
  GROUPED_OPERATION_TYPES,
  GROUPED_STACKED_OPERATION_TYPES,
  GradientType,
  OPERATION_TYPES,
  SortAxis,
  SortOrder,
  StringDisplayFormat,
  StringUrlDisplayFormat,
  V2BoxPlotInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeCollapsibleListInstructions,
  VisualizeGeospatialChartInstructions,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { getMappedAggs, ViewRequestParams } from 'reportBuilderContent/thunks/utils';
import { SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { DatasetColumn } from 'types/datasets';
import { PeriodComparisonRangeTypes, TrendGroupingOptions } from 'types/dateRangeTypes';
import { DataPanel } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';
import { getKpiDateRanges } from 'utils/dateTimeUtils';

import {
  getAdHocFilterInfo,
  getAggregationOrFormula,
  getEmptyComputation,
  getGrouping,
  getScatterPlotSourceProperty,
  getTrendGrouping,
  processFilter,
  processSort,
} from './fidoInstructionShimUtils';
import { canPivotTableSummarize } from './fidoShims';

export const dataTableRowCountPropertyId = 'row_count';

export const generateFidoVisualizeTableInstructions = (
  instructions: VisualizeTableInstructions,
) => {
  const computation = getEmptyComputation();

  const selectedPropertyIds = new Set();
  const usedTargetPropertyIds = new Set();

  // embeddo just does a select * if base schema list isn't supported, so for now
  // we'll just do the same
  if (instructions.baseSchemaList?.length) {
    const baseSchema = [...instructions.baseSchemaList];
    const orderedNames: DatasetColumn[] = [];

    // first order the columns with the ordered column names first
    instructions.orderedColumnNames?.forEach((colName) => {
      const colIndex = baseSchema.findIndex((baseCol) => baseCol.name === colName);

      if (colIndex >= 0) {
        const col = baseSchema.splice(colIndex, 1);
        orderedNames.push(col[0]);
      }
    });

    orderedNames.concat(baseSchema).forEach((col) => {
      const schemaChange = instructions.changeSchemaList.find((change) => change.col === col.name);

      if (!schemaChange || (schemaChange.keepCol && !usedTargetPropertyIds.has(col.name))) {
        const property: SourceProperty = {
          propertyId: col.name,
          targetPropertyId: col.name,
          '@type': 'source',
        };

        computation.properties.push(property);
        usedTargetPropertyIds.add(col.name);
        selectedPropertyIds.add(col.name);
      }
    });

    if (instructions.shouldVisuallyGroupByFirstColumn) {
      computation.sorts.push({
        propertyId: instructions.baseSchemaList[0].name,
        sortDirection: SortDirection.ASC,
      });
    }
  }

  // get the row count separately so that we don't block rendering data for the row count to come back
  const rowCountProperty: AggregateProperty = {
    propertyId: null,
    targetPropertyId: dataTableRowCountPropertyId,
    '@type': 'aggregate',
    aggregation: Aggregation.COUNT,
  };
  const rowCountComputation = getEmptyComputation();
  rowCountComputation.properties.push(rowCountProperty);

  const computations = [computation, rowCountComputation];

  const schemaDisplayOptions = instructions.schemaDisplayOptions;
  const gradientComputation = getEmptyComputation();

  if (schemaDisplayOptions) {
    Object.keys(schemaDisplayOptions).forEach((colName) => {
      const options = schemaDisplayOptions[colName];

      if (!options || !selectedPropertyIds.has(colName)) return;

      if ('gradientType' in options && options.gradientType !== GradientType.NONE) {
        let targetPropertyId = colName + '_min';
        if (!usedTargetPropertyIds.has(targetPropertyId)) {
          const property: AggregateProperty = {
            '@type': 'aggregate',
            propertyId: colName,
            targetPropertyId,
            aggregation: Aggregation.MIN,
          };

          gradientComputation.properties.push(property);
          usedTargetPropertyIds.add(targetPropertyId);
        }

        targetPropertyId = colName + '_avg';
        if (!usedTargetPropertyIds.has(targetPropertyId)) {
          const property: AggregateProperty = {
            '@type': 'aggregate',
            propertyId: colName,
            targetPropertyId,
            aggregation: Aggregation.AVG,
          };

          gradientComputation.properties.push(property);
          usedTargetPropertyIds.add(targetPropertyId);
        }

        targetPropertyId = colName + '_max';
        if (!usedTargetPropertyIds.has(targetPropertyId)) {
          const property: AggregateProperty = {
            '@type': 'aggregate',
            propertyId: colName,
            targetPropertyId,
            aggregation: Aggregation.MAX,
          };

          gradientComputation.properties.push(property);
          usedTargetPropertyIds.add(targetPropertyId);
        }
      } else if (
        'displayTypeOptions' in options &&
        options.displayTypeOptions?.useColumnMaxForProgressBarGoal
      ) {
        const targetPropertyId = colName + '_max';
        if (!usedTargetPropertyIds.has(targetPropertyId)) {
          const property: AggregateProperty = {
            '@type': 'aggregate',
            propertyId: colName,
            targetPropertyId,
            aggregation: Aggregation.MAX,
          };

          gradientComputation.properties.push(property);
          usedTargetPropertyIds.add(targetPropertyId);
        }
      }

      if (
        'urlColumnName' in options &&
        options.urlColumnName &&
        options.urlFormat === StringUrlDisplayFormat.COLUMN &&
        options.format === StringDisplayFormat.LINK &&
        !usedTargetPropertyIds.has(options.urlColumnName)
      ) {
        const property: SourceProperty = {
          propertyId: options.urlColumnName,
          targetPropertyId: options.urlColumnName,
          '@type': 'source',
        };

        computation.properties.push(property);
        usedTargetPropertyIds.add(options.urlColumnName);
      }
    });
  }

  if (gradientComputation.properties.length > 0) {
    computations.push(gradientComputation);
  }

  return computations;
};

export const generateFidoTwoDimensionChartInstructions = (
  instructions: V2TwoDimensionChartInstructions,
  operationType: OPERATION_TYPES,
  timezone: string,
) => {
  const computation = getEmptyComputation();

  const { aggColumns, groupingColumn, categoryColumn, colorColumnOptions } = instructions;

  if (!aggColumns || !categoryColumn) return null;

  const seenTargetPropertyIds = new Set();

  aggColumns.forEach((col, i) => {
    const property = getAggregationOrFormula(col, i);

    if (property) {
      computation.properties.push(property);
      seenTargetPropertyIds.add(property.targetPropertyId);
    }
  });

  if (
    instructions.xAxisFormat?.sortColumns &&
    instructions.xAxisFormat.sortAxis === SortAxis.COLUMN
  ) {
    instructions.xAxisFormat.sortColumns.forEach((col, i) => {
      const property = getAggregationOrFormula(col, i);

      // if the user is sorting on a column that's already selected as part of the data, we don't
      // need to additionally select it here
      if (property && !seenTargetPropertyIds.has(property.targetPropertyId))
        computation.properties.push(property);
    });
  }

  // grouping has a strict ordering. groupingColumn is used for grouped stack
  // bar charts, so it gets grouped on first in the query. Then, we group by
  // the x axis value. Finally, we group by the color categories, which are
  // set in the chart config
  if (
    groupingColumn &&
    (GROUPED_OPERATION_TYPES.has(operationType) ||
      GROUPED_STACKED_OPERATION_TYPES.has(operationType))
  ) {
    const grouping = getGrouping(groupingColumn, timezone);
    if (grouping) computation.groupings.push(grouping);
  }

  const xAxisGroup = getGrouping(categoryColumn, timezone);
  if (xAxisGroup) computation.groupings.push(xAxisGroup);

  if (colorColumnOptions) {
    colorColumnOptions.forEach(({ column, bucket, selected }) => {
      // undefined is the default, selected state
      if (selected === false) return;

      const grouping = getGrouping({ column, bucket }, timezone);
      if (grouping) computation.groupings.push(grouping);
    });
  }

  computation.groupings.forEach((grouping) =>
    computation.sorts.push({
      propertyId: grouping.targetPropertyId ?? grouping.propertyId,
      sortDirection: SortDirection.ASC,
    }),
  );

  return [computation];
};

export const generateFidoGeospatialChartInstructions = (
  instructions: VisualizeGeospatialChartInstructions,
) => {
  const computation = getEmptyComputation();
  const { latitudeColumn, longitudeColumn, tooltipColumns } = instructions;

  if (!latitudeColumn || !longitudeColumn) return null;

  let property: SourceProperty = {
    '@type': 'source',
    propertyId: latitudeColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  property = {
    '@type': 'source',
    propertyId: longitudeColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  tooltipColumns?.forEach((column) => {
    const tooltipProperty: SourceProperty = {
      '@type': 'source',
      propertyId: column.name ?? '',
      targetPropertyId: null,
    };
    computation.properties.push(tooltipProperty);
  });
  return [computation];
};

export const generateFidoScatterPlotInstructions = (instructions: V2ScatterPlotInstructions) => {
  const computation = getEmptyComputation();

  const { xAxisColumn, groupingColumn, yAxisColumn } = instructions;

  if (!xAxisColumn || !yAxisColumn) return null;

  // ordering matters for these
  let property: SourceProperty = {
    '@type': 'source',
    propertyId: xAxisColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  property = {
    '@type': 'source',
    propertyId: yAxisColumn.name ?? '',
    targetPropertyId: null,
  };
  computation.properties.push(property);

  if (groupingColumn) {
    property = {
      '@type': 'source',
      propertyId: groupingColumn.name ?? '',
      targetPropertyId: null,
    };
    computation.properties.push(property);
  }

  return [computation];
};

export const generateFidoKPIInstructions = (instructions: V2KPIChartInstructions) => {
  const computation = getEmptyComputation();

  const { aggColumn } = instructions;

  if (!aggColumn) return null;

  const property = getAggregationOrFormula(aggColumn);
  if (!property) return null;

  computation.properties.push(property);

  return [computation];
};

const generateFidoKPITrendInstructions = (
  instructions: V2KPITrendInstructions,
  timezone: string,
) => {
  // calculates the actual grouped trend data that populates the main body of the chart
  const trendComputation = getEmptyComputation();

  const { aggColumn, periodColumn, periodComparisonRange, trendGrouping } = instructions;

  if (!aggColumn || !periodColumn) return null;

  const property = getAggregationOrFormula(aggColumn);

  if (!property) return null;

  trendComputation.properties.push(property);

  const grouping = getTrendGrouping(
    {
      column: periodColumn?.column,
      grouping: (trendGrouping ?? TrendGroupingOptions.WEEKLY) as TrendGroupingOptions,
    },
    timezone,
  );
  if (grouping) trendComputation.groupings.push(grouping);

  trendComputation.groupings.forEach((grouping) =>
    trendComputation.sorts.push({
      propertyId: grouping.targetPropertyId ?? grouping.propertyId,
      sortDirection: SortDirection.ASC,
    }),
  );

  const { currentPeriod, previousPeriod } = getKpiDateRanges(
    periodColumn,
    periodComparisonRange ?? PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
  );

  const entirePeriodFilter = processFilter(
    {
      filterClauses: [
        {
          filterColumn: {
            name: periodColumn.column.name || '',
            friendly_name: periodColumn.column.friendly_name,
            type: periodColumn.column.type || 'DATE',
          },
          filterValue: {
            startDate: (previousPeriod?.startDate ?? currentPeriod.startDate).toISO(),
            endDate: currentPeriod.endDate.toISO(),
          },
          filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
        },
      ],
      matchOnAll: false,
    },
    timezone,
  );
  if (entirePeriodFilter) trendComputation.filter = entirePeriodFilter;

  // calculates the aggregate data over the current period that populates the header of the chart
  const currentPeriodAggregateComputation = getEmptyComputation();
  currentPeriodAggregateComputation.properties.push({
    ...property,
    targetPropertyId: 'current_period_agg',
  });

  const currentPeriodFilter = processFilter(
    {
      filterClauses: [
        {
          filterColumn: {
            name: periodColumn.column.name || '',
            friendly_name: periodColumn.column.friendly_name,
            type: periodColumn.column.type || 'DATE',
          },
          filterValue: {
            startDate: currentPeriod.startDate.toISO(),
            endDate: currentPeriod.endDate.toISO(),
          },
          filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
        },
      ],
      matchOnAll: false,
    },
    timezone,
  );
  if (currentPeriodFilter) currentPeriodAggregateComputation.filter = currentPeriodFilter;

  const computations = [trendComputation, currentPeriodAggregateComputation];

  // calculates the aggregate data over the comparison period that populates the header of the chart
  if (periodComparisonRange !== PeriodComparisonRangeTypes.NO_COMPARISON && previousPeriod) {
    const previousPeriodAggregateComputation = getEmptyComputation();
    previousPeriodAggregateComputation.properties.push({
      ...property,
      targetPropertyId: 'previous_period_agg',
    });

    const previousPeriodFilter = processFilter(
      {
        filterClauses: [
          {
            filterColumn: {
              name: periodColumn.column.name || '',
              friendly_name: periodColumn.column.friendly_name,
              type: periodColumn.column.type || 'DATE',
            },
            filterValue: {
              startDate: previousPeriod.startDate.toISO(),
              endDate: previousPeriod.endDate.toISO(),
            },
            filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
          },
        ],
        matchOnAll: false,
      },
      timezone,
    );
    if (previousPeriodFilter) previousPeriodAggregateComputation.filter = previousPeriodFilter;
    computations.push(previousPeriodAggregateComputation);
  }

  return computations;
};

export const generateFidoCollapsibleListInstructions = (
  instructions: VisualizeCollapsibleListInstructions,
  timezone: string,
) => {
  const computation = getEmptyComputation();

  const { aggregations, rowColumns } = instructions;

  if (!aggregations || !rowColumns) return null;

  rowColumns.forEach((col) => {
    const grouping = getGrouping(col, timezone);
    if (grouping) computation.groupings.push(grouping);
  });

  aggregations.forEach((col, i) => {
    const property = getAggregationOrFormula(col, i);

    if (property) computation.properties.push(property);
  });

  return [computation];
};

export const generateFidoPivotTableInstructions = (
  instructions: VisualizePivotTableInstructions,
  timezone: string,
) => {
  const computation = getEmptyComputation();

  const { rowColumn, colColumn, aggregation } = instructions;

  if (!rowColumn || !colColumn || !aggregation) return null;

  const rows = getGrouping(rowColumn, timezone);
  if (rows) computation.groupings.push(rows);

  const property = getAggregationOrFormula(aggregation);
  if (property) computation.properties.push(property);

  const cols = getGrouping(colColumn, timezone);
  if (cols) computation.groupings.push(cols);

  return [computation];
};

export const generateFidoPivotTableV2Instructions = (
  instructions: V2PivotTableInstructions,
  timezone: string,
) => {
  const computation = getEmptyComputation();

  const { rowGroupBys, colGroupBys, rowSortOrder, aggregations } = instructions;

  aggregations.forEach((agg) => {
    const property = getAggregationOrFormula(agg);
    if (property) computation.properties.push(property);
  });

  rowGroupBys.forEach((row) => {
    const grouping = getGrouping(row, timezone);
    if (grouping) computation.groupings.push(grouping);
  });

  if (aggregations.length > 0) {
    colGroupBys.forEach((col) => {
      const grouping = getGrouping(col, timezone);
      if (grouping) computation.groupings.push(grouping);
    });
  }

  const sortDirection = rowSortOrder === SortOrder.DESC ? SortDirection.DESC : SortDirection.ASC;
  computation.groupings.forEach((grouping) =>
    computation.sorts.push({
      propertyId: grouping.targetPropertyId ?? grouping.propertyId,
      sortDirection,
    }),
  );

  computation.includeRollup = instructions.includeRollup && canPivotTableSummarize(instructions);

  return [computation];
};

export const generateFidoBoxPlotInstructions = (
  instructions: V2BoxPlotInstructions,
  timezone: string,
) => {
  const computation = getEmptyComputation();
  const boxPlotPercentileValues = [0.25, 0.5, 0.75];

  const { groupingColumn, calcColumns } = instructions;

  if (!groupingColumn || !calcColumns) return null;

  const grouping = getGrouping(groupingColumn, timezone);
  if (grouping) computation.groupings.push(grouping);

  calcColumns.forEach((col) => {
    boxPlotPercentileValues.forEach((v) => {
      const aggregation: AggregateProperty = {
        '@type': 'aggregate',
        propertyId: col.name ?? '',
        // our box plot expects the 50th percentile to be called median
        targetPropertyId: v == 0.5 ? `${col.name ?? ''}_median` : null,
        aggregation: Aggregation.PERCENTILE,
        aggregationOption: {
          decimalValue: v,
        },
      };
      computation.properties.push(aggregation);
    });
    const min: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.name ?? '',
      targetPropertyId: null,
      aggregation: Aggregation.MIN,
    };
    computation.properties.push(min);
    const max: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: col.name ?? '',
      targetPropertyId: null,
      aggregation: Aggregation.MAX,
    };
    computation.properties.push(max);
  });

  computation.groupings.forEach((grouping) =>
    computation.sorts.push({
      propertyId: grouping.targetPropertyId ?? grouping.propertyId,
      sortDirection: SortDirection.ASC,
    }),
  );

  return [computation];
};

/*
 * This function:
 * - returns the generated computation in the case the operation type has been implemented and valid instructions are present
 * - returns null in the case that the operation type is implemented but invalid instructions are provided
 * - throws in the case that the operation type is not implemented (should never happen)
 */
export const generateComputations = (
  { visualize_op: visualizeOp, filter_op: filterOp }: Pick<DataPanel, 'visualize_op' | 'filter_op'>,
  adHocInstructions: {
    sortInfo: SortInfo_DEPRECATED[] | undefined;
    filterInfo: FilterOperationInstructions | undefined;
  },
  timezone: string,
):
  | {
      primaryComputation: Computation;
      secondaryComputations?: Computation[];
    }
  | undefined
  | null => {
  let computations: Computation[] | null = null;

  switch (visualizeOp.operation_type) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
      if (!visualizeOp.instructions.VISUALIZE_TABLE) return null;
      computations = generateFidoVisualizeTableInstructions(
        visualizeOp.instructions.VISUALIZE_TABLE,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
    case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
    case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
    case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
      if (!visualizeOp.instructions.V2_TWO_DIMENSION_CHART) return null;
      computations = generateFidoTwoDimensionChartInstructions(
        visualizeOp.instructions.V2_TWO_DIMENSION_CHART,
        visualizeOp.operation_type,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
      if (!visualizeOp.instructions.V2_SCATTER_PLOT) return null;
      computations = generateFidoScatterPlotInstructions(visualizeOp.instructions.V2_SCATTER_PLOT);
      break;
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
    case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      if (!visualizeOp.instructions.V2_KPI) return null;
      computations = generateFidoKPIInstructions(visualizeOp.instructions.V2_KPI);
      break;
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
      if (!visualizeOp.instructions.V2_KPI_TREND) return null;
      computations = generateFidoKPITrendInstructions(
        visualizeOp.instructions.V2_KPI_TREND,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      if (!visualizeOp.instructions.VISUALIZE_COLLAPSIBLE_LIST) return null;
      computations = generateFidoCollapsibleListInstructions(
        visualizeOp.instructions.VISUALIZE_COLLAPSIBLE_LIST,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      if (!visualizeOp.instructions.VISUALIZE_PIVOT_TABLE) return null;
      computations = generateFidoPivotTableInstructions(
        visualizeOp.instructions.VISUALIZE_PIVOT_TABLE,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      if (!visualizeOp.instructions.VISUALIZE_PIVOT_TABLE_V2) return null;
      computations = generateFidoPivotTableV2Instructions(
        visualizeOp.instructions.VISUALIZE_PIVOT_TABLE_V2,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      if (!visualizeOp.instructions.V2_BOX_PLOT) return null;
      computations = generateFidoBoxPlotInstructions(
        visualizeOp.instructions.V2_BOX_PLOT,
        timezone,
      );
      break;
    case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      if (!visualizeOp.instructions.VISUALIZE_GEOSPATIAL_CHART) return null;
      computations = generateFidoGeospatialChartInstructions(
        visualizeOp.instructions.VISUALIZE_GEOSPATIAL_CHART,
      );
      break;
    default:
      throw Error('Computation generation for this operation type is not implemented.');
  }

  if (!computations) return null;

  const { sortInfo, filterInfo } = adHocInstructions;

  // we don't want to apply adhoc sorts to secondary computations, like for kpi trends
  const primaryComputation = computations[0];

  if (sortInfo) {
    // have to do some shimming here to go from SortInfo_DEPRECATED to SortInfo
    processSort(
      sortInfo.map((colInfo) => ({ column: { name: colInfo.column_name }, order: colInfo.order })),
      primaryComputation,
    );
  }

  const configuredFilter = processFilter(filterOp?.instructions, timezone);
  const adHocFilter = processFilter(getAdHocFilterInfo(filterInfo, visualizeOp), timezone);

  const computedFilters: Filter[] = [];
  if (configuredFilter) computedFilters.push(configuredFilter);
  if (adHocFilter) computedFilters.push(adHocFilter);

  computations.forEach((c) => {
    const computationFilters = c.filter ? [...computedFilters, c.filter] : computedFilters;

    if (computationFilters.length === 1) {
      c.filter = computationFilters[0];
    } else if (computationFilters.length > 1) {
      const andFilter: And = {
        values: computationFilters,
        '@type': 'and',
      };
      c.filter = andFilter;
    }
  });

  return {
    primaryComputation,
    ...(computations.length > 1 ? { secondaryComputations: computations.slice(1) } : {}),
  };
};

export const generateReportBuilderComputations = (
  { aggs, group_bys: groupBys, sort: sorts, filters, visualization }: Partial<ViewRequestParams>,
  customAggs: CustomerReportAgg[] | undefined,
  timezone: string,
  shouldFetchRowCount?: boolean,
): {
  dataComputation: Computation;
  rowCountComputation: Computation | null;
} => {
  const dataComputation = getEmptyComputation();

  if (visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    groupBys?.forEach((groupBy) => {
      dataComputation.properties.push(getScatterPlotSourceProperty(groupBy));
    });
  } else {
    const mappedAggs = aggs && getMappedAggs(aggs, customAggs);
    mappedAggs?.forEach((aggColumn) => {
      const property = getAggregationOrFormula(aggColumn);

      if (property) dataComputation.properties.push(property);
    });

    groupBys?.forEach((groupBy) => {
      const grouping = getGrouping(groupBy, timezone);
      if (grouping) dataComputation.groupings.push(grouping);
    });
  }

  if (sorts) processSort(sorts, dataComputation);

  const computedFilter: Filter | null = filters
    ? processFilter({ filterClauses: filters, matchOnAll: true }, timezone)
    : null;

  dataComputation.filter = computedFilter;

  let rowCountComputation: Computation | null = null;

  if (shouldFetchRowCount) {
    // get the row count separately so that we don't block rendering data for the row count to come back
    const rowCountProperty: AggregateProperty = {
      propertyId: null,
      targetPropertyId: 'row_count',
      '@type': 'aggregate',
      aggregation: Aggregation.COUNT,
    };
    rowCountComputation = getEmptyComputation();
    rowCountComputation.properties.push(rowCountProperty);
    rowCountComputation.filter = computedFilter;
  }

  return { dataComputation, rowCountComputation };
};
