import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';

type Props = {
  disabled?: boolean;
  onBack: () => void;
  onNext: () => void;
  secondary?: boolean;
  text: string;
};

export const StepFooter: FC<Props> = ({ disabled, onBack, onNext, secondary, text }) => {
  return (
    <div className={rootClass}>
      <EmbedButton icon="arrow-left" onClick={onBack} variant="tertiary" />
      <EmbedButton
        disabled={disabled}
        onClick={onNext}
        variant={secondary ? 'tertiary' : 'primary'}>
        {text}
      </EmbedButton>
    </div>
  );
};
const rootClass = sprinkles({
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: 'sp2',
  paddingY: 'sp1.5',
  borderTop: 1,
  borderColor: 'gray7',
});
