import { createSuccessAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { PLAN_TYPES, TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { FeatureFlags, Entitlements, Configuration } from 'flags/flags';
import { GlobalFontConfig, GlobalStyleConfig } from 'globalStyles/types';

import { Role } from './rolePermissionActions';
import { TeamFidoConfig } from './teamActions';

export const logInUserSuccess = createSuccessAction<FetchProfileData>(ACTION.LOGIN_USER);

export type UserPermissions = Record<string, string[]>;

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_team: boolean;
  team: UserTeam | null;
  logged_in: boolean;
  is_superuser: boolean;

  email_verified: boolean;
  is_sso_account: boolean;
  can_create_team: boolean;
  permissions: UserPermissions;
  last_seen_app_update_id?: number;
}

export interface FetchProfileData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_team: boolean;
  team: UserTeam | null;
  email_verified: boolean;
  is_sso_account: boolean;
  is_superuser: boolean;
  can_create_team: boolean | undefined;
  roles: Role[];
  fido_token: string | null;
  profile_data: {
    last_seen_app_update_id?: number;
  } | null;
}

interface UserTeam {
  id: number;
  team_name: string;
  invite_code: string;
  style_config_v2: GlobalStyleConfig | null;
  font_config: GlobalFontConfig | null;
  default_currency_code: string;
  default_locale_code: string;
  use_browser_locale: boolean;
  payment_plan: PLAN_TYPES;
  trial_status: TRIAL_STATUS;
  trial_end_date: string | null;
  configuration: Configuration;
  feature_flags: FeatureFlags;
  entitlements: Entitlements;
  roles: Role[];
  send_emails_as: string;
  fido_config: TeamFidoConfig | null;
  additional_style_configs: Record<string, GlobalStyleConfig>;
}

export interface TeamMember {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  // for now we only technically support one role per user, but leaving this
  //  as a list so that this doesn't have to be an api change in the future
  role_names: string[];
}

// Requests

type UpdateUserNameBody = {
  first_name: string;
  last_name: string;
  id: number;
};

type UpdateUserNameData = {
  first_name: string;
  last_name: string;
};

export const { actionFn: updateUserName, successAction: updateUserNameSuccess } =
  defineAPIPostAction<UpdateUserNameBody, UpdateUserNameData>(
    ACTION.CHANGE_USER_PROFILE_NAME,
    'settings',
    'update_user_name',
    'POST',
  );

type UpdateLastSeenAppUpdateIdBody = {
  last_seen_id: number;
};

export const {
  actionFn: updateLastSeenAppUpdateId,
  successAction: updateLastSeenAppUpdateIdSuccess,
} = defineAPIPostAction<UpdateLastSeenAppUpdateIdBody, {}>(
  ACTION.CHANGE_LAST_SEEN_APP_UPDATE_ID,
  'settings',
  'change_last_seen_app_update_id',
  'POST',
);
