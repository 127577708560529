import cx from 'classnames';

import { Tooltip, sprinkles, Icon, InfoIcon } from 'components/ds';

export type Props = {
  text: string;
  className?: string;
  helpText?: string | JSX.Element;
  variableInputLabel?: boolean;
};

export default function InputLabel({ text, className, helpText, variableInputLabel }: Props) {
  return (
    <div
      className={cx(
        sprinkles({
          flexItems: 'alignCenter',
          color: 'gray12',
          marginBottom: 'sp.5',
          body: 'b3',
          gap: 'sp.5',
        }),
        className,
      )}
      style={{ height: 16 }}>
      <div className={sprinkles({ truncateText: 'ellipsis' })}>{text}</div>
      {helpText ? <InfoIcon text={helpText} /> : null}
      {variableInputLabel ? (
        <Tooltip text="This field can take in variables">
          <Icon className={sprinkles({ color: 'gray8' })} name="code" size="sm" />
        </Tooltip>
      ) : null}
    </div>
  );
}
