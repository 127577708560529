import { FC, useState, ReactNode } from 'react';

import { sprinkles, IconButton } from 'components/ds';

type Props = {
  title: ReactNode;
  defaultOpen?: boolean;
  onDelete?: () => void;
  children: ReactNode;
};

export const CardConfig: FC<Props> = ({ title, defaultOpen, onDelete, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className={containerStyle}>
      <div
        className={sprinkles({ flexItems: 'alignCenterBetween', paddingX: 'sp2' })}
        style={{ height: 44 }}>
        <div className={sprinkles({ flexItems: 'alignCenter', overflowX: 'hidden' })}>
          <IconButton
            name={isOpen ? 'caret-down' : 'caret-right'}
            onClick={() => setIsOpen((prev) => !prev)}
          />
          <div className={headerStyle}>{title}</div>
        </div>
        {onDelete ? <IconButton name="trash-can" onClick={onDelete} /> : null}
      </div>
      {isOpen ? children : null}
    </div>
  );
};

const containerStyle = sprinkles({
  flexItems: 'column',
  backgroundColor: 'elevationMid',
  width: 'fill',
  borderRadius: 8,
});

const headerStyle = sprinkles({
  body: 'section',
  flexItems: 'alignCenter',
  gap: 'sp.5',
  flex: 1,
  overflowX: 'hidden',
});
