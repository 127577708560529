export const getLocalMotionValue = <T>(id: string, defaultValue: T): T => {
  const saved = localStorage.getItem(id);
  if (saved == null) return defaultValue;

  try {
    return JSON.parse(saved).value ?? defaultValue;
  } catch {
    return defaultValue;
  }
};

export const setLocalMotionValue = <T>(id: string, value: T) => {
  localStorage.setItem(id, JSON.stringify({ value }));
};
