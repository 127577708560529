import { FC, useEffect, useState } from 'react';

import { sprinkles, Icon, InfoIcon, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';

import * as styles from './styles.css';

type BaseProps = {
  icon?: IconName;
  title: string;
  defaultOpen?: boolean;
  variant: 'header1' | 'header2';
  infoText?: string;
};

type InternalStateProps = {
  defaultOpen?: boolean;
  isOpen?: never;
  onOpen?: never;
};

type ExternalStateProps = {
  isOpen: boolean;
  onOpen: (isOpen: boolean) => void;
  defaultOpen?: never;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

type Props = BaseProps & InteractionProps;

export const ConfigSection: FC<Props> = ({
  defaultOpen,
  children,
  icon,
  title,
  isOpen,
  onOpen,
  variant,
  infoText,
}) => {
  const [currIsOpen, setCurrIsOpen] = useState(defaultOpen ?? false);

  useEffect(() => {
    if (isOpen === undefined) return;
    setCurrIsOpen(isOpen);
  }, [isOpen]);

  const isH1 = variant === 'header1';

  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div
        className={isH1 ? styles.sectionHeaderH1 : styles.sectionHeaderH2}
        onClick={() => {
          if (onOpen) onOpen(!currIsOpen);
          else setCurrIsOpen((prev) => !prev);
        }}>
        <div className={styles.sectionTitleContainer}>
          {icon ? <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} /> : null}
          <Tooltip delayDuration={DEFAULT_DELAY} text={title}>
            <div className={sprinkles({ heading: isH1 ? 'h3' : 'h4', color: 'contentPrimary' })}>
              {title}
            </div>
          </Tooltip>
          {infoText ? <InfoIcon text={infoText} /> : null}
        </div>
        <Tooltip delayDuration={DEFAULT_DELAY} text={currIsOpen ? 'Collapse' : 'Expand'}>
          <Icon name={currIsOpen ? 'chevron-down' : 'chevron-right'} />
        </Tooltip>
      </div>
      {currIsOpen ? children : null}
    </div>
  );
};
