import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { fetchDashboard } from 'actions/dashboardActions';
import { fetchDashboardVersions } from 'actions/dashboardV2Actions';
import { fetchUserTeam } from 'actions/teamActions';
import { pageView } from 'analytics/exploAnalytics';
import { ErrorState } from 'components/ErrorState';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';
import { EditDashboardPage } from 'pages/dashboardPage/editDashboardPage';
import { setExploreInteractionsInfo } from 'reducers/dashboardInteractionsReducer';
import { clearFolderDashboards } from 'reducers/dashboardReducer';
import { clearFolders } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { VIEW_MODE } from 'types/dashboardTypes';
import { useLoadEditMetadata } from 'utils/hookUtils';

type Props = Pick<WithWidthProps, 'width'>;

type MatchParams = {
  dashboardId: string;
};

const DashboardPageBase: FC<Props> = ({ width }) => {
  const dispatch = useDispatch();

  const [initialViewIds, setInitialViewIds] = useState<string[]>();

  const {
    currentDashboard,
    currentUser,
    dashboardConfigLoaded,
    dashboardVersionInfo,
    inEditMode,
    teamData,
    viewMode,
  } = useSelector(
    (state: ReduxState) => ({
      currentDashboard: state.dashboard.currentDashboard,
      currentUser: state.currentUser,
      dashboardConfigLoaded: !!state.dashboardEditConfig.config,
      dashboardVersionInfo: state.dashboardEditConfig.versionInfo,
      teamData: state.teamData.data,
      inEditMode: state.dashboardInteractions.interactionsInfo.isEditing,
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
    }),
    shallowEqual,
  );

  const {
    params: { dashboardId },
  } = useRouteMatch<MatchParams>();

  const updateEditModeUrlHash = useCallback(
    (newViewMode?: VIEW_MODE) => {
      switch (newViewMode ?? viewMode) {
        case VIEW_MODE.PDF:
          window.location.hash = '#edit_pdf';
          break;
        case VIEW_MODE.EMAIL:
          window.location.hash = '#edit_email';
          break;
        case VIEW_MODE.MOBILE:
          window.location.hash = '#edit_mobile';
          break;
        default:
          window.location.hash = '#edit';
      }
    },
    [viewMode],
  );

  const metadataLoading = useLoadEditMetadata(initialViewIds);

  useEffect(() => {
    const hash = window.location.hash;

    dispatch(
      fetchDashboard({ id: dashboardId }, (data) => {
        document.title = `Explo | ${data.dashboard.name}`;

        if (hash === '#edit' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.DEFAULT }));
        } else if (hash === '#edit_pdf' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.PDF }));
        } else if (hash === '#edit_email' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.EMAIL }));
        } else if (hash === '#edit_mobile' && data.dashboard_version.is_draft) {
          dispatch(setExploreInteractionsInfo({ isEditing: true, viewMode: VIEW_MODE.MOBILE }));
        } else {
          window.location.hash = '';
        }

        setInitialViewIds(
          Object.values(data.dashboard_version.configuration.datasets ?? {})
            .map((dataset) => dataset.fido_id ?? '')
            .filter((id) => !!id),
        );
      }),
    );

    dispatch(fetchDashboardVersions({ id: dashboardId }));

    // clear folders so if we click back to a folder via the breadcrumbs,
    // we don't show a flicker of the stale stored folder
    dispatch(clearFolders());
    dispatch(clearFolderDashboards());

    pageView('Dashboard');
  }, [currentUser.team?.id, dashboardId, dispatch]);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  const prevInEditMode = usePrevious(inEditMode);
  const prevViewMode = usePrevious(viewMode);

  useEffect(() => {
    if (prevInEditMode !== inEditMode) {
      if (inEditMode) updateEditModeUrlHash(viewMode);
      else window.location.hash = '';
      return;
    }
    if (prevViewMode === viewMode || !inEditMode) return;
    updateEditModeUrlHash(viewMode);
  }, [inEditMode, viewMode, updateEditModeUrlHash, prevInEditMode, prevViewMode]);

  useEffect(() => {
    dispatch(
      setExploreInteractionsInfo({
        disableFiltersWhileLoading: RD.getOrDefault(currentDashboard, undefined)
          ?.disable_filters_while_loading,
        supportEmail: teamData?.support_email ?? undefined,
      }),
    );
  }, [dispatch, currentDashboard, teamData?.support_email]);

  if (RD.isError(currentDashboard)) return <ErrorState text={currentDashboard.error} />;

  if (
    !RD.isSuccess(currentDashboard) ||
    !dashboardVersionInfo ||
    metadataLoading ||
    !dashboardConfigLoaded
  )
    return <ExploLoadingSpinner />;

  return (
    <EditDashboardPage
      dashboard={currentDashboard.data}
      dashboardVersionInfo={dashboardVersionInfo}
      width={width}
    />
  );
};

export const DashboardPage = withWidth(DashboardPageBase);
