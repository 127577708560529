import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { BarVisualizationGrouping } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { setBarChartOptions } from 'reportBuilderContent/reducers/reportEditingReducer';

import { ToggleButton } from './ToggleButton';

type Props = { visualizationGrouping?: BarVisualizationGrouping };

export const GroupingSection: FC<Props> = ({ visualizationGrouping }) => {
  const dispatch = useDispatch();

  const renderButton = (grouping: BarVisualizationGrouping) => {
    const isGrouped = grouping === BarVisualizationGrouping.Grouped;

    return (
      <ToggleButton
        icon={isGrouped ? 'ellipsis' : 'ellipsis-vertical'}
        onClick={() => dispatch(setBarChartOptions({ grouping }))}
        // If its undefined, default to grouped
        selected={visualizationGrouping === grouping || (!visualizationGrouping && isGrouped)}>
        {isGrouped ? 'Grouped' : 'Stacked'}
      </ToggleButton>
    );
  };

  return (
    <DataPanelSection>
      <DataPanelSubHeader title="Grouping" />
      <div
        className={sprinkles({
          paddingX: 'sp2',
          width: 'fill',
          flexItems: 'alignCenter',
          gap: 'sp.25',
        })}>
        {renderButton(BarVisualizationGrouping.Grouped)}
        {renderButton(BarVisualizationGrouping.Stacked)}
      </div>
    </DataPanelSection>
  );
};
