import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { FC } from 'react';

import { Icon } from '../Icon';
import { sprinkles } from '../sprinkles.css';

import { MenuItem } from './MenuItem';
import * as styles from './index.css';

import { Props } from './index';

type MenuSubProps = Omit<Props, 'align' | 'side' | 'trigger'> & {
  trigger: string;
};

export const MenuSub: FC<MenuSubProps> = ({
  className,
  width,
  isOpen,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  onOpenChange,
}) => {
  const content = (
    <DropdownMenu.SubContent
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      sideOffset={sideOffset}>
      {children}
    </DropdownMenu.SubContent>
  );

  return (
    <DropdownMenu.Sub defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      <DropdownMenu.SubTrigger asChild className={styles.menuItem}>
        <MenuItem>
          {trigger}
          <Icon className={sprinkles({ color: 'contentTertiary' })} name="chevron-right" />
        </MenuItem>
      </DropdownMenu.SubTrigger>
      {portalContainerId ? (
        <DropdownMenu.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </DropdownMenu.Portal>
      ) : (
        content
      )}
    </DropdownMenu.Sub>
  );
};
