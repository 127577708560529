import { Draft } from 'immer';
import { DateTime } from 'luxon';
import { FC } from 'react';

import { DatePickerInput } from 'components/DatePickerInput';
import { Switch } from 'components/ds';
import { DateElemConfig } from 'types/dashboardTypes';
import { dateTimeFromISOString } from 'utils/dateUtils';

import * as styles from '../styles.css';

interface Props {
  config: DateElemConfig;
  updateConfigFn: (updateFunc: (draft: Draft<DateElemConfig>) => void) => void;
}

export const ExactDatesConfig: FC<Props> = ({ config, updateConfigFn }) => {
  const minValue = config.minValue;
  const maxValue = config.maxValue;
  return (
    <>
      <Switch
        className={styles.configInput}
        label="Include Time Selection"
        onChange={() => updateConfigFn((draft) => (draft.hideTimeSelect = !draft.hideTimeSelect))}
        switchOn={!config.hideTimeSelect}
      />
      <DatePickerInput
        showCancelBtn
        className={styles.configInput}
        label="Start Date"
        onNewValueSelect={(newValue) =>
          updateConfigFn((draft) => (draft.minValue = (newValue as DateTime) || undefined))
        }
        selectedValue={
          typeof minValue === 'string' ? dateTimeFromISOString(minValue) : config.minValue
        }
        showTimeSelect={!config.hideTimeSelect}
      />
      <DatePickerInput
        showCancelBtn
        className={styles.configInput}
        label="End Date"
        onNewValueSelect={(newValue) =>
          updateConfigFn((draft) => (draft.maxValue = (newValue as DateTime) || undefined))
        }
        selectedValue={
          typeof maxValue === 'string' ? dateTimeFromISOString(maxValue) : config.maxValue
        }
        showTimeSelect={!config.hideTimeSelect}
      />
    </>
  );
};
