import { FC } from 'react';

import DataSelectionImg from 'constants/images/report-builder/data-selection.gif';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ImageAndText } from 'pages/ReportBuilder/Onboarding/Blocks/ImageAndText';

export const DataSelectionDoc: FC = () => {
  return (
    <ImageAndText alt="Data Selection" img={DataSelectionImg}>
      <EmbedText body="b2">
        Choose what data to include in your report by specifying a dataset. You can see a preview of
        the columns of the dataset before you select one. The data you select will be set for all
        views in your report, so if you need to utilize data from another view, it may make sense to
        create an additional report with the given dataset.
      </EmbedText>
    </ImageAndText>
  );
};
