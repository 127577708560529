import { FC, useEffect, useState } from 'react';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { INTEGER_DATA_TYPE, STRING, UNSIGNED_INTEGER } from 'constants/dataConstants';
import { FilterValueType } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { FilterableColumn } from 'utils/customerReportUtils';
import { titleCase } from 'utils/graphUtils';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';

import * as styles from './styles.css';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  onChange: (value: FilterValueType) => void;
};

export const NumberAndStringFilter: FC<Props> = ({ clause, column, onChange }) => {
  const [valueString, setValueString] = useState(String(getFilterValue(clause) ?? ''));
  const [value, setValue] = useState(getFilterValue(clause));

  const isStringCol = column.type === STRING;
  const isUnsignedIntCol = column.type === UNSIGNED_INTEGER;
  const isIntCol = column.type === INTEGER_DATA_TYPE || isUnsignedIntCol;

  useEffect(() => {
    if (valueString === '') return setValue(undefined);
    if (isStringCol) return setValue(valueString);

    const parsedValue = isIntCol ? parseInt(valueString) : parseFloat(valueString);

    // number type=input mostly shouldn't allow numbers, but we have this check just in case
    if (!isNaN(parsedValue)) setValue(parsedValue);
  }, [isIntCol, isStringCol, valueString]);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <div className={inputContainerStyle}>
      <input
        className={styles.valueInput}
        onChange={(e) => setValueString(e.target.value)}
        placeholder={`Search ${getColTypeDisplay(column.type)}`}
        step={isIntCol ? 1 : undefined}
        type={isStringCol ? 'text' : 'number'}
        value={value === undefined ? '' : valueString}
      />
      <EmbedText body="b3" color="contentSecondary">
        {titleCase(column.type)}
      </EmbedText>
    </div>
  );
};

// If the user switches between "multiselect" filtering and single filtering, we have to reset the value
function getFilterValue(clause?: CustomerReportFilter) {
  if (Array.isArray(clause?.filterValue)) return;
  return clause?.filterValue as string | number | undefined;
}

const inputContainerStyle = sprinkles({
  flex: 1,
  paddingX: 'sp2',
  marginTop: 'sp2',
  flexItems: 'column',
  gap: 'sp1',
});
