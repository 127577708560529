import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  createReportBuilderDraft,
  saveExploreDraft,
  saveReportBuilderDraft,
} from './thunks/resourceSaveThunks';

interface SavingReducerState {
  isSaving: boolean;
  changesWhileSaving: boolean;
  errorSaving: boolean;
  errorMessage?: string;
  debounceSaving: boolean;
}

const initialState: SavingReducerState = {
  isSaving: false,
  changesWhileSaving: false,
  errorSaving: false,
  debounceSaving: false,
};

const resourceSavingSlice = createSlice({
  name: 'resourceSaving',
  initialState,
  reducers: {
    clearSavingReducer: () => {
      return initialState;
    },
    changesMadeWhileSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.changesWhileSaving = payload;
    },
    setSaveError: (state, { payload }: PayloadAction<string>) => {
      state.errorMessage = payload;
    },
    setDebounceSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.debounceSaving = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          saveExploreDraft.pending,
          saveReportBuilderDraft.pending,
          createReportBuilderDraft.pending,
        ),
        (state) => {
          state.isSaving = true;
          state.errorMessage = undefined;
          state.errorSaving = false;
        },
      )
      .addMatcher(
        isAnyOf(
          saveExploreDraft.rejected,
          saveReportBuilderDraft.rejected,
          createReportBuilderDraft.rejected,
        ),
        (state) => {
          state.errorSaving = true;
          state.isSaving = false;
          state.debounceSaving = false;
        },
      )
      .addMatcher(
        isAnyOf(
          saveExploreDraft.fulfilled,
          saveReportBuilderDraft.fulfilled,
          createReportBuilderDraft.fulfilled,
        ),
        (state) => {
          state.isSaving = false;
          state.debounceSaving = false;
        },
      );
  },
});

export const resourceSavingReducer = resourceSavingSlice.reducer;
export const { clearSavingReducer, changesMadeWhileSaving, setSaveError, setDebounceSaving } =
  resourceSavingSlice.actions;
