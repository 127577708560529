import {
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  TIMESTAMP,
  DATETIME,
  DATE,
  BOOLEAN,
  DOUBLE,
} from 'constants/dataConstants';

export enum FilterOperator {
  STRING_IS = 'STRING_IS',
  STRING_IS_NOT = 'STRING_IS_NOT',
  STRING_IS_IN = 'STRING_IS_IN',
  STRING_IS_NOT_IN = 'STRING_IS_NOT_IN',
  STRING_CONTAINS = 'STRING_CONTAINS',
  STRING_DOES_NOT_CONTAIN = 'STRING_DOES_NOT_CONTAIN',
  NUMBER_EQ = 'NUMBER_EQ',
  NUMBER_NEQ = 'NUMBER_NEQ',
  NUMBER_LT = 'NUMBER_LT',
  NUMBER_GT = 'NUMBER_GT',
  NUMBER_LTE = 'NUMBER_LTE',
  NUMBER_GTE = 'NUMBER_GTE',
  NUMBER_IS_IN = 'NUMBER_IS_IN',
  NUMBER_IS_NOT_IN = 'NUMBER_IS_NOT_IN',
  NUMBER_IS_BETWEEN = 'NUMBER_IS_BETWEEN',
  DATE_IS = 'DATE_IS',
  DATE_IS_NOT = 'DATE_IS_NOT',
  DATE_IS_BETWEEN = 'DATE_IS_BETWEEN',
  DATE_LT = 'DATE_LT',
  DATE_LTE = 'DATE_LTE',
  DATE_GT = 'DATE_GT',
  DATE_GTE = 'DATE_GTE',
  DATE_PREVIOUS = 'DATE_PREVIOUS',
  DATE_NEXT = 'DATE_NEXT',
  DATE_TODAY = 'DATE_TODAY',
  BOOLEAN_IS_TRUE = 'BOOLEAN_IS_TRUE',
  BOOLEAN_IS_FALSE = 'BOOLEAN_IS_FALSE',
  BOOLEAN_IS = 'BOOLEAN_IS',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
}

export interface FilterOperatorWithSupport {
  id: FilterOperator;
  name: string;
  selectionValue: string;
  supported_column_types: Set<string>;
}

export const FILTER_OPERATOR_TYPES_BY_ID: Record<FilterOperator, FilterOperatorWithSupport> = {
  STRING_IS: {
    id: FilterOperator.STRING_IS,
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_NOT: {
    id: FilterOperator.STRING_IS_NOT,
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_IN: {
    id: FilterOperator.STRING_IS_IN,
    name: 'is in',
    selectionValue: 'is in...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_NOT_IN: {
    id: FilterOperator.STRING_IS_NOT_IN,
    name: 'is not in',
    selectionValue: 'is not in...',
    supported_column_types: new Set([STRING]),
  },
  STRING_CONTAINS: {
    id: FilterOperator.STRING_CONTAINS,
    name: 'contains',
    selectionValue: 'contains...',
    supported_column_types: new Set([STRING]),
  },
  STRING_DOES_NOT_CONTAIN: {
    id: FilterOperator.STRING_DOES_NOT_CONTAIN,
    name: 'does not contain',
    selectionValue: 'does not contain...',
    supported_column_types: new Set([STRING]),
  },
  NUMBER_EQ: {
    id: FilterOperator.NUMBER_EQ,
    name: '=',
    selectionValue: '=',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_NEQ: {
    id: FilterOperator.NUMBER_NEQ,
    name: '≠',
    selectionValue: '≠',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_LT: {
    id: FilterOperator.NUMBER_LT,
    name: '<',
    selectionValue: '<',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_GT: {
    id: FilterOperator.NUMBER_GT,
    name: '>',
    selectionValue: '>',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_LTE: {
    id: FilterOperator.NUMBER_LTE,
    name: '≤',
    selectionValue: '≤',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_GTE: {
    id: FilterOperator.NUMBER_GTE,
    name: '≥',
    selectionValue: '≥',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_IS_IN: {
    id: FilterOperator.NUMBER_IS_IN,
    name: 'is in',
    selectionValue: 'is in...',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_IS_NOT_IN: {
    id: FilterOperator.NUMBER_IS_NOT_IN,
    name: 'is not in',
    selectionValue: 'is not in...',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  NUMBER_IS_BETWEEN: {
    id: FilterOperator.NUMBER_IS_BETWEEN,
    name: 'is between',
    selectionValue: 'is between',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT, DOUBLE]),
  },
  DATE_IS: {
    id: FilterOperator.DATE_IS,
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_NOT: {
    id: FilterOperator.DATE_IS_NOT,
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_BETWEEN: {
    id: FilterOperator.DATE_IS_BETWEEN,
    name: 'is between',
    selectionValue: 'is between...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LT: {
    id: FilterOperator.DATE_LT,
    name: 'is before',
    selectionValue: 'is before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LTE: {
    id: FilterOperator.DATE_LTE,
    name: 'is on or before',
    selectionValue: 'is on or before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GT: {
    id: FilterOperator.DATE_GT,
    name: 'is after',
    selectionValue: 'is after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GTE: {
    id: FilterOperator.DATE_GTE,
    name: 'is on or after',
    selectionValue: 'is on or after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_PREVIOUS: {
    id: FilterOperator.DATE_PREVIOUS,
    name: 'previous',
    selectionValue: 'previous...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_NEXT: {
    id: FilterOperator.DATE_NEXT,
    name: 'next',
    selectionValue: 'next...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_TODAY: {
    id: FilterOperator.DATE_TODAY,
    name: 'is today',
    selectionValue: 'is today',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },

  BOOLEAN_IS_TRUE: {
    id: FilterOperator.BOOLEAN_IS_TRUE,
    name: 'is true',
    selectionValue: 'is true',
    supported_column_types: new Set([BOOLEAN]),
  },
  BOOLEAN_IS_FALSE: {
    id: FilterOperator.BOOLEAN_IS_FALSE,
    name: 'is false',
    selectionValue: 'is false',
    supported_column_types: new Set([BOOLEAN]),
  },
  BOOLEAN_IS: {
    id: FilterOperator.BOOLEAN_IS,
    name: 'is',
    selectionValue: 'is',
    supported_column_types: new Set([BOOLEAN]),
  },
  IS_EMPTY: {
    id: FilterOperator.IS_EMPTY,
    name: 'is empty',
    selectionValue: 'is empty',
    supported_column_types: new Set([
      STRING,
      INTEGER_DATA_TYPE,
      FLOAT,
      TIMESTAMP,
      DATETIME,
      DATE,
      BOOLEAN,
      DOUBLE,
    ]),
  },
  IS_NOT_EMPTY: {
    id: FilterOperator.IS_NOT_EMPTY,
    name: 'is not empty',
    selectionValue: 'is not empty',
    supported_column_types: new Set([
      STRING,
      INTEGER_DATA_TYPE,
      FLOAT,
      TIMESTAMP,
      DATETIME,
      DATE,
      BOOLEAN,
      DOUBLE,
    ]),
  },
};

export const FILTER_OPS_NO_VALUE = new Set([
  FilterOperator.BOOLEAN_IS_TRUE,
  FilterOperator.BOOLEAN_IS_FALSE,
  FilterOperator.BOOLEAN_IS,
  FilterOperator.IS_EMPTY,
  FilterOperator.IS_NOT_EMPTY,
  FilterOperator.DATE_TODAY,
]);

export const FILTER_OPS_DATE_PICKER = new Set([
  FilterOperator.DATE_IS,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.DATE_LT,
  FilterOperator.DATE_LTE,
  FilterOperator.DATE_GT,
  FilterOperator.DATE_GTE,
]);

export const FILTER_OPS_DATE_RANGE_PICKER = new Set([FilterOperator.DATE_IS_BETWEEN]);

export const FILTER_OPS_RELATIVE_PICKER = new Set([
  FilterOperator.DATE_PREVIOUS,
  FilterOperator.DATE_NEXT,
]);

export const FILTER_OPS_MULTISELECT = new Set([
  FilterOperator.STRING_IS_IN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.NUMBER_IS_IN,
  FilterOperator.NUMBER_IS_NOT_IN,
]);

export const FILTER_OPS_STRING = new Set([
  FilterOperator.STRING_IS,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.STRING_CONTAINS,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
]);

export const FILTER_OPS_NUMBER = new Set([
  FilterOperator.NUMBER_EQ,
  FilterOperator.NUMBER_GT,
  FilterOperator.NUMBER_GTE,
  FilterOperator.NUMBER_LT,
  FilterOperator.NUMBER_LTE,
  FilterOperator.NUMBER_NEQ,
]);

export const FILTER_OPS_NUMBER_RANGE = new Set([FilterOperator.NUMBER_IS_BETWEEN]);

export const FILTER_OPERATORS = Object.values(FILTER_OPERATOR_TYPES_BY_ID);

export const FILTER_OPS_EQ = new Set([
  FilterOperator.STRING_IS,
  FilterOperator.NUMBER_EQ,
  FilterOperator.DATE_IS,
  FilterOperator.NUMBER_NEQ,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.DATE_TODAY,
]);

export const FILTER_OPS_BOOLEAN = new Set([
  FilterOperator.BOOLEAN_IS_FALSE,
  FilterOperator.BOOLEAN_IS_TRUE,
  FilterOperator.BOOLEAN_IS,
]);

export const FILTER_OPS_IN = new Set([
  FilterOperator.STRING_IS_IN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.NUMBER_IS_IN,
  FilterOperator.NUMBER_IS_NOT_IN,
]);

export const FILTER_OPS_SINGLE_VALUE_RANGE = new Set([
  FilterOperator.DATE_LT,
  FilterOperator.DATE_LTE,
  FilterOperator.DATE_GT,
  FilterOperator.DATE_GTE,
  FilterOperator.NUMBER_GT,
  FilterOperator.NUMBER_GTE,
  FilterOperator.NUMBER_LT,
  FilterOperator.NUMBER_LTE,
  FilterOperator.DATE_NEXT,
  FilterOperator.DATE_PREVIOUS,
]);

export const FILTER_OPS_MULTI_VALUE_RANGE = new Set([
  FilterOperator.NUMBER_IS_BETWEEN,
  FilterOperator.DATE_IS_BETWEEN,
]);

export const FILTER_OPS_EMPTY = new Set([FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY]);

export const FILTER_OPS_CONTAINS = new Set([
  FilterOperator.STRING_CONTAINS,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
]);

export const FILTER_OPS_NEGATED = new Set([
  FilterOperator.NUMBER_NEQ,
  FilterOperator.NUMBER_IS_NOT_IN,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.IS_NOT_EMPTY,
]);
