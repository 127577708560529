import cx from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toggleFilterLink } from 'actions/dashboardV2Actions';
import { sprinkles, Checkbox } from 'components/ds';
import { dataPanelLinkUpdatedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { DataPanelLink } from 'utils/filterLinking';

type Props = { dataPanelId: string; link: DataPanelLink };

export const FilterLink: FC<Props> = ({ dataPanelId, link }) => {
  const dispatch = useDispatch();

  const [isApplied, setIsApplied] = useState(link.applied);

  const operatorInfo = FILTER_OPERATOR_TYPES_BY_ID[link.operator];

  useEffect(() => {
    if (link.applied === isApplied) return;
    setIsApplied(link.applied);
    dispatch(dataPanelLinkUpdatedThunk(link.elementName, [dataPanelId]));
  }, [link, dataPanelId, isApplied, dispatch]);

  return (
    <div
      className={cx(
        rootClass,
        sprinkles({
          backgroundColor: isApplied ? 'lightBlue' : 'gray2',
          color: isApplied ? 'blue11' : 'gray12',
        }),
      )}
      style={{ height: 32 }}>
      <div className={sprinkles({ flex: 2, marginRight: 'sp.5', truncateText: 'ellipsis' })}>
        {link.column}
      </div>
      <div className={sprinkles({ flex: 1, marginRight: 'sp.5', truncateText: 'ellipsis' })}>
        {operatorInfo.selectionValue}
      </div>
      <div className={sprinkles({ flex: 2, truncateText: 'ellipsis' })}>{link.elementName}</div>
      <div
        className={cx(
          sprinkles({ paddingX: 'sp1', height: 'fill', flexItems: 'alignCenter', borderLeft: 1 }),
          sprinkles({ borderColor: isApplied ? 'blue5' : 'gray7' }),
        )}>
        <Checkbox
          isChecked={isApplied}
          onChange={() => dispatch(toggleFilterLink({ dataPanelId, elementId: link.elementId }))}
        />
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'alignCenter',
  marginBottom: 'sp1',
  borderRadius: 4,
  paddingLeft: 'sp1',
  fontWeight: 500,
});
