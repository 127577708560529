import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  addCustomerSuccess,
  deleteCustomersSuccess,
  editCustomersSuccess,
  fetchFilteredCustomersSuccess,
  fetchFilteredCustomersRequest,
  fetchFilteredCustomersError,
  fetchGroupTagsSuccess,
  fetchGroupTagsRequest,
  fetchGroupTagsError,
  addGroupTagSuccess,
  deleteGroupTagSuccess,
  renameGroupTagSuccess,
  generateEmbedJwtSuccess,
  generateEmbedJwtError,
  fetchHierarchyMetadataRequest,
  fetchHierarchyMetadataError,
  fetchHierarchyMetadataSuccess,
  fetchSelectorCustomersRequest,
  fetchSelectorCustomersError,
  fetchSelectorCustomersSuccess,
  fetchCustomerPotentialParentsRequest,
  fetchCustomerPotentialParentsError,
  fetchCustomerPotentialParentsSuccess,
} from 'actions/customerActions';
import {
  Customer,
  SummaryCustomer,
  GroupTag,
  HierarchyLevel,
  HierarchyLevelCountInfo,
  switchCustomer,
} from 'actions/teamActions';
import { EXPLO_EUG_STORAGE_KEY } from 'constants/customerConstants';
import * as RD from 'remotedata';

import {
  updateHierarchyWithNewCustomer,
  updateHierarchyWithDeletedCustomer,
  updateHierarchyWithEditedCustomer,
  updateInheritedFields,
} from './customerHierarchyUtils';
import { FetchCustomerData, fetchCustomer, fetchCustomerParent } from './thunks/customerThunks';
import { syncSchemaThunk } from './thunks/schemaManagementThunks';

type HierarchyMetadata = {
  levels: HierarchyLevel[];
  levelCounts: HierarchyLevelCountInfo[];
};

export interface CustomersReducerState {
  cachedCustomers: Record<string, FetchCustomerData>; // Keeps track of recently selected customers to avoid refetching them
  selectedGroupId: number | null;
  groupTags: RD.ResponseData<GroupTag[]>;
  selectorCustomersStatus: RD.ResponseData<boolean>; // Stored status of fetching customers for customer selector, important for loading ui
  fetchCustomerStatus: RD.ResponseData<boolean>; // Keeps track of status for fetching a customer. Important for initial load.
  selectorCustomers: Customer[]; // Stored customers to be rendered in customer selector, needs to be stored separately so still can view while loading
  filteredCustomerData: RD.ResponseData<{ customers: Customer[]; totalCount: number }>;
  hierarchyMetadata: RD.ResponseData<HierarchyMetadata>;
  customerPotentialParentsStatus: RD.ResponseData<boolean>;
  customerPotentialParents: SummaryCustomer[];
  selectedParent?: SummaryCustomer;
  selectedCustomerJwt?: RD.ResponseData<string | undefined>;
}

export const getSelectedCustomer = createSelector(
  (state: CustomersReducerState) => state.selectedGroupId,
  (state: CustomersReducerState) => state.cachedCustomers,
  (selectedGroupId, recentlySelectedCustomers) => {
    if (selectedGroupId === null) return null;

    return recentlySelectedCustomers[String(selectedGroupId)]?.customer ?? null;
  },
);

const initialState: CustomersReducerState = {
  cachedCustomers: {},
  selectedGroupId: null,
  groupTags: RD.Idle(),
  selectorCustomersStatus: RD.Idle(),
  fetchCustomerStatus: RD.Idle(),
  selectorCustomers: [],
  filteredCustomerData: RD.Idle(),
  hierarchyMetadata: RD.Idle(),
  customerPotentialParents: [],
  customerPotentialParentsStatus: RD.Idle(),
  selectedCustomerJwt: RD.Idle(),
};

const customersSlice = createSlice({
  name: 'customersReducer',
  initialState,
  reducers: {
    clearPotentialParents: (state) => {
      state.customerPotentialParents = [];
      state.selectedParent = undefined;
    },
    selectCustomerParent: (state, { payload }: PayloadAction<SummaryCustomer | undefined>) => {
      state.selectedParent = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.fetchCustomerStatus = RD.Loading();
      })
      .addCase(fetchCustomer.fulfilled, (state) => {
        state.fetchCustomerStatus = RD.Success(true);
      })
      .addCase(fetchCustomer.rejected, (state) => {
        state.fetchCustomerStatus = RD.Error('Something went wrong');
      })
      .addCase(fetchCustomerParent.pending, (state) => {
        state.selectedParent = undefined;
      })
      .addCase(fetchCustomerParent.fulfilled, (state, { payload }) => {
        if (payload.customer_parent) {
          state.selectedParent = payload.customer_parent;
        }
      })
      .addCase(fetchFilteredCustomersRequest, (state) => {
        state.filteredCustomerData = RD.Loading();
      })
      .addCase(fetchFilteredCustomersError, (state) => {
        state.filteredCustomerData = RD.Error('Error Loading Customers');
      })
      .addCase(fetchFilteredCustomersSuccess, (state, { payload }) => {
        state.filteredCustomerData = RD.Success({
          customers: payload.results,
          totalCount: payload.count,
        });
      })
      .addCase(fetchSelectorCustomersRequest, (state) => {
        state.selectorCustomersStatus = RD.Loading();
      })
      .addCase(fetchSelectorCustomersError, (state) => {
        state.selectorCustomersStatus = RD.Error('Error Loading Selector Customers');
      })
      .addCase(fetchSelectorCustomersSuccess, (state, { payload }) => {
        state.selectorCustomersStatus = RD.Success(true);
        state.selectorCustomers = payload.results;
      })
      .addCase(fetchCustomerPotentialParentsRequest, (state) => {
        state.customerPotentialParentsStatus = RD.Loading();
      })
      .addCase(fetchCustomerPotentialParentsError, (state) => {
        state.customerPotentialParentsStatus = RD.Error('Error Loading Selector Customers');
      })
      .addCase(fetchCustomerPotentialParentsSuccess, (state, { payload }) => {
        state.customerPotentialParentsStatus = RD.Success(true);
        state.customerPotentialParents = payload.results;
      })
      .addCase(fetchHierarchyMetadataRequest, (state) => {
        state.hierarchyMetadata = RD.Loading();
      })
      .addCase(fetchHierarchyMetadataError, (state) => {
        state.hierarchyMetadata = RD.Error('Error Loading Hierarchy Metadata');
      })
      .addCase(fetchHierarchyMetadataSuccess, (state, { payload }) => {
        const metadata = {
          levels: payload.hierarchy_levels,
          levelCounts: payload.hierarchy_level_counts,
        };
        state.hierarchyMetadata = RD.Success(metadata);
      })
      .addCase(addCustomerSuccess, (state, { payload }) => {
        state.filteredCustomerData = RD.map(state.filteredCustomerData, (customerInfo) => {
          customerInfo.customers.unshift(payload.customer);
          customerInfo.totalCount = customerInfo.totalCount + 1;
          return customerInfo;
        });
        updateHierarchyWithNewCustomer(state, payload.customer);
      })
      .addCase(deleteCustomersSuccess, (state, { payload }) => {
        if (!payload.id) return;
        let hierarchyGroupId: number | undefined;
        let parentProvidedId: string | undefined;
        let accessGroupId: number | undefined;
        state.filteredCustomerData = RD.map(state.filteredCustomerData, (groups) => {
          const customer = groups.customers.find((c) => c.id === payload.id);
          hierarchyGroupId = customer?.hierarchy_level_id;
          parentProvidedId = customer?.parent_provided_id;
          accessGroupId = customer?.access_group_id; // parent access groups should always match child access groups
          return {
            customers: groups.customers.filter((group) => group.id !== payload.id),
            totalCount: groups.totalCount - 1,
          };
        });

        updateHierarchyWithDeletedCustomer(
          state,
          payload.id,
          hierarchyGroupId,
          parentProvidedId,
          accessGroupId,
        );
      })
      .addCase(editCustomersSuccess, (state, { payload }) => {
        if (state.cachedCustomers[payload.customer.id]) {
          // Need to clear this customer from cache to force a jwt refetch
          delete state.cachedCustomers[payload.customer.id];
          if (payload.customer.id === state.selectedGroupId) {
            state.fetchCustomerStatus = RD.Idle();
          }
        }

        let oldHierarchyGroupId: number | undefined;

        // Update filtered customer list
        RD.update(state.filteredCustomerData, (groups) => {
          const userGroup = groups.customers.find((endUser) => endUser.id === payload.customer.id);
          const oldParent = groups.customers.find(
            (customer) =>
              customer.provided_id === userGroup?.parent_provided_id &&
              customer.access_group_id === userGroup?.access_group_id,
          );
          const newParent = groups.customers.find(
            (customer) =>
              customer.provided_id === payload.customer.parent_provided_id &&
              customer.access_group_id === payload.customer.access_group_id,
          );
          if (userGroup) {
            userGroup.name = payload.customer.name;
            userGroup.parent_schema_datasource_mapping =
              payload.customer.parent_schema_datasource_mapping;
            userGroup.provided_id = payload.customer.provided_id;
            userGroup.access_group_id = payload.customer.access_group_id;
            userGroup.is_demo_group = payload.customer.is_demo_group;
            userGroup.properties = payload.customer.properties;
            userGroup.group_tags = payload.customer.group_tags;
            userGroup.emails = payload.customer.emails;
            oldHierarchyGroupId = userGroup.hierarchy_level_id;
            userGroup.hierarchy_level_id = payload.customer.hierarchy_level_id;
            userGroup.parent_provided_id = payload.customer.parent_provided_id;
            userGroup.children_count = payload.customer.children_count;
            userGroup.computed_group_tags = payload.customer.computed_group_tags;
            userGroup.parent_names = payload.customer.parent_names;
          }
          // Update the inherited fields of each child
          payload.updated_children.forEach((updatedChild) => {
            const childCustomer = groups.customers.find(
              (endUser) => endUser.id === updatedChild.id,
            );
            if (childCustomer) updateInheritedFields(childCustomer, updatedChild);
          });

          // Update Children Counts
          if (
            newParent?.provided_id === oldParent?.provided_id &&
            newParent?.access_group_id === oldParent?.access_group_id
          )
            return;
          if (newParent) newParent.children_count = newParent.children_count + 1;
          if (oldParent) oldParent.children_count = oldParent.children_count - 1;
        });

        updateHierarchyWithEditedCustomer(state, payload.customer, oldHierarchyGroupId);
      })
      .addCase(syncSchemaThunk.fulfilled, (state) => {
        state.cachedCustomers = {};
        state.selectorCustomers = [];
        state.selectorCustomersStatus = RD.Idle();
      })
      .addCase(switchCustomer, (state, { payload }) => {
        const customerId = payload.customer.id;
        const customerIdKey = String(customerId);

        state.selectedGroupId = customerId;
        localStorage.setItem(EXPLO_EUG_STORAGE_KEY, customerIdKey);
        if (!state.cachedCustomers[customerIdKey]) {
          state.cachedCustomers[customerIdKey] = payload;
        }
      })
      .addCase(fetchGroupTagsRequest, (state) => {
        state.groupTags = RD.Loading();
      })
      .addCase(fetchGroupTagsError, (state) => {
        state.groupTags = RD.Error('Error Loading Group Tags');
      })
      .addCase(fetchGroupTagsSuccess, (state, { payload }) => {
        state.groupTags = RD.Success(payload.group_tags);
      })
      .addCase(addGroupTagSuccess, (state, { payload }) => {
        state.groupTags = RD.map(state.groupTags, (groupTags) => {
          groupTags.push(payload.group_tag);
          return groupTags;
        });
      })
      .addCase(deleteGroupTagSuccess, (state, { payload }) => {
        state.groupTags = RD.map(state.groupTags, (groupTags) =>
          groupTags.filter((tag) => tag.id !== payload.group_tag_id),
        );
      })
      .addCase(renameGroupTagSuccess, (state, { payload }) => {
        const newGroupTag = payload.group_tag;
        RD.update(state.groupTags, (groupTags) => {
          const changedTag = groupTags.find((tag) => tag.id === newGroupTag.id);
          if (!changedTag) return;
          changedTag.name = newGroupTag.name;
        });
      })
      .addCase(generateEmbedJwtSuccess, (state, { payload }) => {
        state.selectedCustomerJwt = RD.Success(payload.jwt);
      })
      .addCase(generateEmbedJwtError, (state) => {
        state.selectedCustomerJwt = RD.Error(
          'Could not generate JWT for given dashboard and customer',
        );
      });
  },
});

export const { clearPotentialParents, selectCustomerParent } = customersSlice.actions;

export const customersReducer = customersSlice.reducer;
