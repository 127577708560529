import { Ace } from 'ace-builds';
import ace from 'ace-builds/src-noconflict/ace';
import {
  keyWordCompleter,
  setCompleters,
  snippetCompleter,
  textCompleter,
} from 'ace-builds/src-noconflict/ext-language_tools';
import { FC, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import ResizeObserver from 'react-resize-observer';

import { sprinkles } from 'components/ds';
import { createDebouncedFn } from 'utils/general';

// Load mode and theme for Ace Editor
ace.config.setModuleUrl('ace/mode/sql', 'file-loader!ace-builds/src-min-noconflict/mode-sql');
ace.config.setModuleUrl('ace/theme/xcode', 'file-loader!ace-builds/src-min-noconflict/theme-xcode');
import 'ace-builds/src-min-noconflict/mode-sql';
import 'ace-builds/src-min-noconflict/theme-xcode';

const debounceFn = createDebouncedFn(500);

type Props = {
  tableNames: string[];
  columnNames: string[];
  query: string;
  onChange: (newQuery: string) => void;
  onChangeDraft: (newQuery: string) => void;
  minHeight?: number;
};

export const SqlEditor: FC<Props> = ({
  tableNames,
  columnNames,
  query,
  onChange,
  onChangeDraft,
  minHeight,
}) => {
  const [dimensions, setDimensions] = useState<{ width: string; height: string }>({
    width: '100%',
    height: '100%',
  });

  useEffect(() => {
    const completer = {
      getCompletions: (
        editor: Ace.Editor,
        session: Ace.EditSession,
        pos: Ace.Point,
        prefix: string,
        callback: Ace.CompleterCallback,
      ) => {
        const keywords = session.getMode().getKeywords() as string[];
        callback(null, [
          ...tableNames.map((tableName) => ({
            caption: tableName,
            value: tableName,
            meta: 'Table',
            score: 100,
          })),
          ...columnNames.map((columnName) => {
            const colParts = columnName.split('.');
            return {
              caption: columnName,
              // take the last part, since BQ has multiple parts in a table
              value: colParts[colParts.length - 1],
              meta: 'Column',
              score: 100,
            };
          }),
          ...keywords.map((word) => ({
            caption: word.toUpperCase(),
            value: word.toUpperCase(),
            meta: 'keyword',
            score: 100,
          })),
        ]);
      },
    };

    // Reset ace completers whenever table/column names change
    setCompleters([snippetCompleter, textCompleter, keyWordCompleter, completer]);
  }, [tableNames, columnNames]);

  return (
    <div
      className={sprinkles({ parentContainer: 'fill', position: 'relative' })}
      style={{ minHeight }}>
      <AceEditor
        height={dimensions.height}
        mode="sql"
        onChange={(value: string) => {
          if (value !== undefined) {
            // OnChange updates currentQuery as you type, onChangeDraft updates queryDraft in the background
            onChange(value);
            debounceFn(() => onChangeDraft(value));
          }
        }}
        setOptions={{
          //@ts-ignore
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
        }}
        theme="xcode"
        value={query}
        width={dimensions.width}
      />
      <ResizeObserver
        onResize={(rect) => {
          setDimensions({
            width: `${rect.width}px`,
            height: `${rect.height}px`,
          });
        }}
      />
    </div>
  );
};
