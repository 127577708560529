import { BaseCol } from 'types/columnTypes';

// IDs use for indexing reportEditing.reportData
export const DATASET_DATA_PREFIX = `DATASET_`;
const DRILLDOWN_DATA_PREFIX = `DRILLDOWN_`;
const AGG_DATA_PREFIX = `AGG_`;
const TOTAL_DATA_PREFIX = `TOTAL_`;

export const getDrilldownDataId = (viewId: string) => `${DRILLDOWN_DATA_PREFIX}${viewId}`;
export const getDatasetDataId = (datasetId: string) => `${DATASET_DATA_PREFIX}${datasetId}`;
export const getAiDataId = () => 'AI';
export const getAggDataId = (datasetId: string) => `${AGG_DATA_PREFIX}${datasetId}`;
export const getTotalDataId = (viewId: string) => `${TOTAL_DATA_PREFIX}${viewId}`;
export const getDistinctDataId = (column: BaseCol) => `DISTINCT_${column.name}_${column.type}`;
