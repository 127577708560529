import { FC } from 'react';

import { Input, Label, sprinkles } from 'components/ds';
import { GlobalStyleComponentTextConfig } from 'globalStyles/types';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { ConfigSectionHeader } from 'pages/GlobalCustomStylesPage/configSectionHeader';

type Props = {
  config: GlobalStyleComponentTextConfig;
  header: string;
  updateConfig: (newConfig: Partial<GlobalStyleComponentTextConfig>) => void;
};

export const ComponentTextConfig: FC<Props> = ({ config, header, updateConfig }) => {
  return (
    <div>
      <ConfigSectionHeader isSubTitle title={header} />
      <div className={sprinkles({ display: 'flex', gap: 'sp3' })}>
        <Input
          fillWidth
          className={sprinkles({ flex: 1 })}
          defaultValue={`${config.size}px`}
          label="Text Size"
          onSubmit={(sizeString) => {
            const size = Number(sizeString.replace('px', ''));

            if (isNaN(size) || size < 1) return;
            updateConfig({ size });
          }}
          style={{ width: 55 }}
        />

        <div className={sprinkles({ flex: 1 })}>
          <Label htmlFor="">Color</Label>
          <ColorPicker fill color={config.color} onClose={(color) => updateConfig({ color })} />
        </div>
      </div>
    </div>
  );
};
