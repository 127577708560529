import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { useEffect, useState, ReactNode, FC } from 'react';

import * as styles from './index.css';

type BaseProps = {
  className?: string;
  children?: ReactNode;
  /**
   * width of menu content
   */
  width?: 'small' | 'large';
  /**
   * Specify a container element id to portal the content into.
   */
  portalContainerId?: string;
  /**
   * The preferred side of the anchor to render against when open. Will be reversed when collisions occur and avoidCollisions is enabled.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   */
  align?: 'start' | 'center' | 'end';
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number;
  /**
   * The offset from the start or end align options
   */
  alignOffset?: number;
  /**
   * Whether the menu is disabled.
   */
  disabled?: boolean;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  /**
   * The controlled open state of the menu. Must be used in conjunction with onOpenChange.
   */
  isOpen: boolean;
  /**
   * Event handler called when the open state of the menu changes.
   */
  onOpenChange: (open: boolean) => void;
};

type InternalStateProps = {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  /**
   * The open state of the menu when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * Trigger to open and close menu
   */
  trigger: ReactNode;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Menu: FC<Props> = ({
  className,
  width,
  isOpen,
  align,
  side,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  disabled,
  onOpenChange,
}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  const handleOpenChange = (open: boolean) => {
    setMenuOpen(open);
    onOpenChange?.(open);
  };

  const content = (
    <DropdownMenu.Content
      align={align}
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      onInteractOutside={() => setMenuOpen(false)}
      side={side}
      sideOffset={sideOffset}>
      {children}
    </DropdownMenu.Content>
  );

  return (
    <DropdownMenu.Root
      defaultOpen={defaultOpen}
      modal={false} // Fixes issue of opening modals from dropdown menus
      onOpenChange={handleOpenChange}
      open={menuOpen}>
      {trigger ? (
        <DropdownMenu.Trigger asChild disabled={disabled}>
          {trigger}
        </DropdownMenu.Trigger>
      ) : null}
      {portalContainerId ? (
        <DropdownMenu.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </DropdownMenu.Portal>
      ) : (
        content
      )}
    </DropdownMenu.Root>
  );
};
