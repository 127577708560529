import validator from 'validator';

import { every } from 'utils/standard';

import type { EditorCustomer } from './customerContent';

export const isEditorGroupValid = (editorGroup: EditorCustomer): boolean => {
  const endUsersAreValid = every(editorGroup.endUsers, (user) => validator.isEmail(user.email));
  const isPortalValid = editorGroup.selectedDashboardId ? !!editorGroup.endUsers?.length : true;
  const arePropertiesNonEmpty = every(
    Object.values(editorGroup.properties),
    ({ key, value }) => key !== '' && value !== '',
  );
  return (
    endUsersAreValid &&
    isPortalValid &&
    !!editorGroup.name?.trim() &&
    !!editorGroup.providedId?.trim() &&
    editorGroup.accessGroupId !== -1 &&
    arePropertiesNonEmpty
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getStringifiedValue = (val: any) => {
  let stringifiedVal;
  try {
    stringifiedVal = JSON.stringify(val);
  } catch {
    stringifiedVal = val;
  }
  return stringifiedVal;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const formatPropertiesForSubmit = (properties: Record<string, any>): Record<string, any> => {
  const props: Record<string, any> = {};
  Object.values(properties).forEach(({ key, value }) => {
    // Parse value - should be stringified JSON so we want this to throw if it fails
    props[key] = JSON.parse(value);
  });

  return props;
};
