import cx from 'classnames';
import { PureComponent } from 'react';

import { ChartMenu } from 'components/ChartMenu';
import { ProgressBar } from 'components/ProgressBar';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { sprinkles, Tooltip, vars } from 'components/ds';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  ColorSettings,
  DEFAULT_NO_DATA_FONT_SIZE,
  OPERATION_TYPES,
  V2KPIChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { TEXT_ELEM_HORIZ_ALIGNMENTS, TITLE_VALUE_ARRANGEMENTS } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { aggReady } from 'utils/dataPanelConfigUtils';
import { isNumber } from 'utils/standard';

import * as styles from './SingleNumberChart.css';
import { formatValue, getAxisNumericalValue } from './utils';

type Props = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2KPIChartInstructions;
  dataPanelTemplateId: string;
  schema: DatasetSchema;
  hideChartMenu?: boolean;
  infoTooltipText?: string;
  operationType: OPERATION_TYPES;
  newDataLoading?: boolean;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  processString: (s: string) => string;
  globalStyleConfig: GlobalStyleConfig;
  // If empty, defaults to GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base
  kpiTitleClassName?: string;
  // If empty, defaults to GLOBAL_STYLE_CLASSNAMES.text.kpiValue.base
  kpiValueClassName?: string;
};

class BaseSingleNumberChart extends PureComponent<Props> {
  instructionsReadyToDisplay = () => aggReady(this.props.instructions?.aggColumn);

  hasFixedTitle = () => {
    const { instructions } = this.props;
    return (
      instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER ||
      instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
    );
  };

  render() {
    const { instructions, loading } = this.props;

    if (!this.instructionsReadyToDisplay()) {
      return <NeedsConfigurationPanel fullHeight instructionsNeedConfiguration loading={loading} />;
    }

    const hasNoData = this.isNoData();
    const numberValue = hasNoData ? this.renderEmptyValue() : this.renderNumberValue();
    const bodyContainerClassName = styles.bodyContainer({
      verticalContentAlignment: instructions?.generalFormat?.vertical_content_alignment,
    });

    if (instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW) {
      return (
        <>
          {this.renderDrilldownHeader()}
          <div className={bodyContainerClassName}>
            {numberValue}
            {this.renderTitle()}
            {this.renderSubtitle()}
            {this.renderProgressBar()}
          </div>
        </>
      );
    }

    if (this.hasFixedTitle()) {
      return (
        <>
          {this.renderDrilldownHeader()}
          <div>
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          <div className={bodyContainerClassName}>
            {numberValue}
            {this.renderProgressBar()}
          </div>
        </>
      );
    }

    // in the default case, position the title above the label and make the title non-fixed
    return (
      <>
        {this.renderDrilldownHeader()}
        <div className={bodyContainerClassName}>
          {this.renderTitle()}
          {this.renderSubtitle()}
          {numberValue}
          {this.renderProgressBar()}
        </div>
      </>
    );
  }

  renderProgressBar = () => {
    const { previewData, schema, instructions, operationType } = this.props;
    if (
      operationType !== OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ||
      this.isNoData() ||
      schema?.length === 0 ||
      !previewData
    )
      return null;

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;
    const formattedValue = this.getFormattedNumberValue();

    const tooltipText = `${formattedValue} ${this.getUnits(true) ?? `/ ${formattedValue}`}`;
    const color =
      instructions?.valueFormat?.progressBarColor ||
      getCategoricalColors(this.props.globalStyleConfig)[0];

    return (
      <Tooltip align="center" side="bottom" text={tooltipText}>
        <div>
          <ProgressBar color={color} value={value / denominator} />
        </div>
      </Tooltip>
    );
  };

  renderTitle = () => {
    const { infoTooltipText, newDataLoading, generalOptions, processString, instructions } =
      this.props;
    if (generalOptions?.headerConfig?.isHeaderHidden) return null;

    const hasDrilldown = this.shouldRenderDrilldownHeader();
    const titleValueArrangement = instructions?.generalFormat?.title_value_arrangement;
    const alignment = instructions?.generalFormat?.alignment;

    const isFixedLeft = titleValueArrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT;
    const isBelow = titleValueArrangement === TITLE_VALUE_ARRANGEMENTS.BELOW;

    // Use 2 spans to center the title and value
    // Each span shrinks completely before the next one starts shrinking:
    // 1. Left span shrinks 1st because it has flexGrow: 1 and no explicit width
    // 2. Title shrinks 2nd because it has a much larger flexShrink than the right span
    // 3. Right span shrinks last because we need space for the drilldown button
    return (
      <div
        className={cx(
          styles.headerTitleContainer({ titleValueArrangement, alignment }),
          this.props.kpiTitleClassName,
        )}>
        {isFixedLeft || isBelow || !hasDrilldown ? null : <span className={styles.titleLeft} />}
        <span className={styles.titleContainer}>
          <span className={styles.title} style={this.getTitleColorStyle()}>
            {processString(this.getTitle())}
          </span>
          {infoTooltipText ? <EmbedInfoIcon noMargin text={infoTooltipText} /> : null}
          {newDataLoading ? <EmbedSpinner size="md" /> : null}
        </span>
        {isBelow || !hasDrilldown ? null : <span className={styles.titleRight} />}
      </div>
    );
  };

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  getTitle = () => {
    const { instructions, generalOptions } = this.props;
    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;
    return aggColumn?.friendly_name || aggColumn?.name || '';
  };

  renderSubtitle = () => {
    const { instructions, processString } = this.props;
    const generalFormat = instructions?.generalFormat;

    if (!generalFormat?.subtitle) return null;

    const alignment = this.hasFixedTitle()
      ? instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
        ? TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER
        : TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
      : generalFormat?.alignment;

    return (
      <div
        className={cx(styles.valueContainer({ alignment }), embedSprinkles({ body: 'secondary' }))}>
        {processString(generalFormat.subtitle)}
      </div>
    );
  };

  renderNumberValue = () => {
    const { instructions, generalOptions, loading } = this.props;

    if (loading) return this.renderLoadingState();

    const value = this.getFormattedNumberOrPctValue();
    const imageUrl = instructions?.valueFormat?.imageUrl;
    const linkFormat = generalOptions?.linkFormat;
    const units = this.getUnits();
    const showUnits = units !== undefined && units !== '' && !this.isKPIProgressPctValue();
    const valueClass = this.props.kpiValueClassName ?? embedSprinkles({ otherText: 'kpiValue' });

    return (
      <div className={styles.valueContainer({ alignment: instructions?.generalFormat?.alignment })}>
        {imageUrl ? this.renderImage(imageUrl) : null}
        <span
          className={cx(
            {
              [sprinkles({ fontStyle: 'italic' })]: instructions?.valueFormat?.italic,
            },
            valueClass,
          )}
          style={{
            ...this.getValueColorStyle(this.getRawValue()),
            fontWeight: instructions?.valueFormat?.bold ? 600 : undefined,
          }}>
          {value}
        </span>
        {showUnits && (
          <span
            className={cx(valueClass, {
              [sprinkles({ marginLeft: 'sp1' })]: instructions?.valueFormat?.unitPadding,
            })}
            style={this.getGoalUnitsColorStyle()}>
            {units}
          </span>
        )}
        <UrlClickThroughButton linkFormat={linkFormat} />
      </div>
    );
  };

  renderImage = (url: string) => {
    return (
      <img
        alt=""
        className={sprinkles({ marginRight: 'sp1', height: 36, width: 36 })}
        src={this.props.processString(url)}
      />
    );
  };

  renderDrilldownHeader = () => {
    if (!this.shouldRenderDrilldownHeader()) return null;

    return (
      <div
        className={sprinkles({ positionAbsolute: 'topRight', zIndex: 'base' })}
        onClick={(e) => e.stopPropagation()}>
        <ChartMenu enableDrilldownModal dataPanelId={this.props.dataPanelTemplateId} />
      </div>
    );
  };

  getTitleColorStyle = () => {
    const color = this.props.instructions?.generalFormat?.titleColor;
    return color ? { color } : undefined;
  };

  getValueColorStyle = (rawValue: number) => {
    const { instructions, processString } = this.props;
    const colorFormat = instructions?.colorFormat;

    if (colorFormat?.colorSettingType === ColorSettings.CONSTANT && colorFormat.constantColor) {
      return { color: colorFormat.constantColor };
    } else if (
      colorFormat?.colorSettingType === ColorSettings.CONDITIONAL &&
      colorFormat.conditionalTriggerValue
    ) {
      const multipliedValue = rawValue * (instructions?.valueFormat?.multiplyFactor ?? 1);
      const triggerValue = processString(colorFormat.conditionalTriggerValue);

      let triggerValueNum = parseFloat(triggerValue);

      if (isNaN(triggerValueNum)) triggerValueNum = 0;

      if (multipliedValue >= triggerValueNum) {
        return { color: colorFormat.conditionalPositiveColor || vars.colors.green9 };
      } else {
        return { color: colorFormat.conditionalNegativeColor || vars.colors.red9 };
      }
    }
  };

  getGoalUnitsColorStyle = () => {
    const { instructions } = this.props;

    if (instructions?.colorFormat?.applyColorToProgressGoal) {
      return this.getValueColorStyle(this.getRawValue());
    }
  };

  isKPIProgressPctValue = () => {
    const { instructions, operationType } = this.props;

    return (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      instructions?.valueFormat?.progressShowPct
    );
  };

  renderLoadingState = () => {
    const alignment = this.props.instructions?.generalFormat?.alignment;
    return (
      <div className={styles.valueContainer({ alignment })}>
        <EmbedSpinner size="lg" />
      </div>
    );
  };

  renderEmptyValue = () => {
    const { generalOptions, processString } = this.props;
    const processedText = processString(generalOptions?.noDataState?.noDataText || '');
    const maybeNoDataNumber = parseFloat(processedText);
    const noDataStyle = {
      fontSize: generalOptions?.noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
    };
    const colorStyle = isNaN(maybeNoDataNumber) ? {} : this.getValueColorStyle(maybeNoDataNumber);
    return (
      <div
        className={cx(
          styles.valueContainer({ alignment: this.props.instructions?.generalFormat?.alignment }),
          embedSprinkles({ body: 'primary' }),
        )}
        style={{ ...noDataStyle, ...colorStyle }}>
        {processedText || 'No Data'}
      </div>
    );
  };

  getUnits = (forceDenominator?: boolean) => {
    const { operationType, instructions } = this.props;

    const valueFormat = instructions?.valueFormat ?? {};

    if (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      (forceDenominator || !valueFormat.progressHideGoal)
    ) {
      const denominator =
        instructions?.valueFormat?.progressGoal &&
        formatValue({
          value: this.getProgressValue(),
          decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
          formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
          significantDigits: instructions?.valueFormat.significantDigits,
          hasCommas: true,
        });

      // If it is a progress bar but the number format is Percent, don't show the denominator
      // if it is 100%, because 30% / 100% doesn't make sense. But if its another value then show.
      if (
        valueFormat.numberFormat?.id === V2_NUMBER_FORMATS.PERCENT.id &&
        parseFloat(denominator as string) === 100
      ) {
        return valueFormat.units;
      }

      return denominator ? `/ ${denominator} ${valueFormat?.units || ''}` : valueFormat?.units;
    }

    return valueFormat?.units;
  };

  getRawValue = () => {
    const { previewData, schema } = this.props;

    return getAxisNumericalValue(previewData[0][schema[0].name]);
  };

  isNoData = () => {
    const { previewData, generalOptions, loading } = this.props;
    if (loading) return false;
    if (!previewData || previewData.length === 0) return true;

    const value = this.getRawValue();

    if (!isNumber(value) || isNaN(value)) return true;

    return generalOptions?.noDataState?.isZeroNoData ? value === 0 : false;
  };

  shouldRenderDrilldownHeader = () => {
    const { generalOptions, hideChartMenu } = this.props;
    return !!generalOptions?.enableRawDataDrilldown && !hideChartMenu && !this.isNoData();
  };

  getFormattedNumberValue = () => {
    const { instructions } = this.props;

    const value = this.getRawValue();

    return formatValue({
      value,
      decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
      formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
      multiplier: instructions?.valueFormat?.multiplyFactor,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
      customDurationFormat: instructions?.valueFormat?.customDurationFormat,
      significantDigits: instructions?.valueFormat?.significantDigits,
    });
  };

  getFormattedNumberOrPctValue = () => {
    const { instructions } = this.props;

    if (!this.isKPIProgressPctValue()) return this.getFormattedNumberValue();

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;

    let ratio = value / denominator;
    if (denominator === 0) {
      ratio = value === 0 ? 0 : 1;
    }

    return formatValue({
      value: ratio,
      decimalPlaces: instructions?.valueFormat?.pctDecimalPlaces ?? 2,
      formatId: V2_NUMBER_FORMATS.PERCENT.id,
    });
  };

  getProgressValue = () => {
    const { instructions, processString } = this.props;

    const valueString = processString(instructions?.valueFormat?.progressGoal?.toString() || '100');
    const valueNum = parseFloat(valueString);
    return isNaN(valueNum) ? 0 : valueNum;
  };
}

export const SingleNumberChart = BaseSingleNumberChart;
