import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import { keyBy } from 'utils/standard';

import AthenaIcon from './images/athena.png';
import AzureSynapseIcon from './images/azure-synapse.png';
import AzureIcon from './images/azure.png';
import BigqueryIcon from './images/bigquery-icon.svg';
import ClickhouseIcon from './images/clickhouse.png';
import CockroachDBIcon from './images/cockroach-db-icon.png';
import CrateDBIcon from './images/cratedb.svg';
import CubeCloudIcon from './images/cube-logo.png';
import DatabricksIcon from './images/databricks.png';
import MaterializeIcon from './images/materialize-icon.png';
import MySQLIcon from './images/mysql-icon.svg';
import ParadeDBIcon from './images/parade-db.png';
import PostgresIcon from './images/postgresql-icon.svg';
import RedshiftIcon from './images/redshift-icon.svg';
import RocksetIcon from './images/rockset.png';
import SingleStoreIcon from './images/single-store.svg';
import SnowflakeIcon from './images/snowflake-icon.svg';
import SqlServerIcon from './images/sqlserver-icon.svg';
import SupabaseIcon from './images/supabase.png';
import TimescaleIcon from './images/timescale.png';

type IntegrationsData = {
  source_type: string;
  sourceType: DATABASES;
  datasourceIconImg: string;
  credentialsLink?: string;
};

export const DefaultIcon = PostgresIcon;

// When adding new db support, also update ConnectDataSourceFlow/constants with new DB and default port mapping
export const integrationsInfo: { [id: string]: IntegrationsData } = {
  postgres: {
    source_type: 'postgres',
    sourceType: DATABASES.POSTGRES,
    datasourceIconImg: PostgresIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/postgres',
  },
  mysql: {
    source_type: 'mysql',
    sourceType: DATABASES.MYSQL,
    datasourceIconImg: MySQLIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/mysql',
  },
  snowflake: {
    source_type: 'snowflake',
    sourceType: DATABASES.SNOWFLAKE,
    datasourceIconImg: SnowflakeIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/snowflake',
  },
  redshift: {
    source_type: 'redshift',
    sourceType: DATABASES.REDSHIFT,
    datasourceIconImg: RedshiftIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/redshift',
  },
  bigquery: {
    source_type: 'big_query',
    sourceType: DATABASES.BIGQUERY,
    datasourceIconImg: BigqueryIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/big-query',
  },
  microsoft_sql_server: {
    source_type: 'microsoft_sql_server',
    sourceType: DATABASES.SQLSERVER,
    datasourceIconImg: SqlServerIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/microsoft-sql-server',
  },
  rockset: {
    source_type: 'rockset',
    sourceType: DATABASES.ROCKSET,
    datasourceIconImg: RocksetIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/rockset',
  },
  azure: {
    source_type: 'azure_sql_db',
    sourceType: DATABASES.AZURE,
    datasourceIconImg: AzureIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/azure',
  },
  azureSynapse: {
    source_type: 'azure_synapse',
    sourceType: DATABASES.AZURESYNAPSE,
    datasourceIconImg: AzureSynapseIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/azure',
  },
  clickhouse: {
    source_type: 'clickhouse',
    sourceType: DATABASES.CLICKHOUSE,
    datasourceIconImg: ClickhouseIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/clickhouse',
  },
  databricks: {
    source_type: 'databricks',
    sourceType: DATABASES.DATABRICKS,
    datasourceIconImg: DatabricksIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/databricks',
  },
  athena: {
    source_type: 'athena',
    sourceType: DATABASES.ATHENA,
    datasourceIconImg: AthenaIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/athena',
  },
  supabase: {
    source_type: 'supabase',
    sourceType: DATABASES.SUPABASE,
    datasourceIconImg: SupabaseIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/supabase',
  },
  cubeCloud: {
    source_type: 'cube_cloud',
    sourceType: DATABASES.CUBECLOUD,
    datasourceIconImg: CubeCloudIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/cube',
  },
  timescale: {
    source_type: 'timescale',
    sourceType: DATABASES.TIMESCALE,
    datasourceIconImg: TimescaleIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/timescale',
  },
  crateDB: {
    source_type: 'crate_db',
    sourceType: DATABASES.CRATEDB,
    datasourceIconImg: CrateDBIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/cratedb',
  },
  cockroachDB: {
    source_type: 'cockroach_db',
    sourceType: DATABASES.COCKROACH,
    datasourceIconImg: CockroachDBIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  singleStore: {
    source_type: 'single_store',
    sourceType: DATABASES.SINGLESTORE,
    datasourceIconImg: SingleStoreIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  materialize: {
    source_type: 'materialize',
    sourceType: DATABASES.MATERIALIZE,
    datasourceIconImg: MaterializeIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  paradeDB: {
    source_type: 'parade_db',
    sourceType: DATABASES.PARADE_DB,
    datasourceIconImg: ParadeDBIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-data-sources/data-source-types/parade_db',
  },
};

export const dataSourceByType = keyBy(Object.values(integrationsInfo), 'sourceType');

/**
 * TODO: deprecate this after fido is turned on
 */
export const dataSourceByOldType = keyBy(Object.values(integrationsInfo), 'source_type');
