import { BaseCol } from 'types/columnTypes';

export const COL_LIST_SECTION_ID = 'col_list_section';
export const ROWS_SECTION_ID = 'rows_section';
export const SCATTER_PLOT_GROUPING_SECTION_ID = 'scatter_plot_grouping_section';
export const AGGS_SECTION_ID = 'aggs_section';
export const COLS_SECTION_ID = 'cols_section';

export interface DataPanelData {
  id: string;
  section: string;
  column?: BaseCol;
}
