import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, Menu, MenuActionItem } from 'components/ds';
import { duplicateDataPanelToEditableSection } from 'reducers/dashboardEditConfigReducer';
import { showSuccessToast } from 'shared/sharedToasts';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { createDashboardItemId } from 'utils/dashboardUtils';

import { duplicateToMainDashboardAction } from './dataPanelConfigActions';
import {
  DEFAULT_TOAST_DURATION,
  DUPLICATE_BUTTON_TOOLTIP_TEXT,
  getDuplicatedToEditableSectionToastText,
} from './dataPanelConfigConstants';

interface Props {
  dataPanel: DataPanelTemplate;
  dashboardId: number;
}

export const DataPanelDuplicationOptionsMenu: FC<Props> = ({ dataPanel, dashboardId }) => {
  const dispatch = useDispatch();
  const [triggerButtonDisabled, setTriggerButtonDisabled] = useState(false);

  const triggerButton = (
    <IconButton
      disabled={triggerButtonDisabled}
      name={triggerButtonDisabled ? 'tick' : 'copy'}
      tooltipProps={{ text: DUPLICATE_BUTTON_TOOLTIP_TEXT }}
    />
  );
  return (
    <Menu disabled={triggerButtonDisabled} trigger={triggerButton}>
      <MenuActionItem
        onSelect={() =>
          dispatch(
            duplicateToMainDashboardAction(
              dataPanel,
              dashboardId,
              () => setTriggerButtonDisabled(true),
              () => setTriggerButtonDisabled(false),
            ),
          )
        }
        text="Duplicate to main section"
      />
      <MenuActionItem
        onSelect={() => {
          dispatch(
            duplicateDataPanelToEditableSection({
              dataPanelId: dataPanel.id,
              newId: createDashboardItemId(dashboardId),
            }),
          );

          // TODO(zifanxiang): Consider scrolling the user to the editable section and showing them
          // the new added chart in the left panel.

          showSuccessToast(
            getDuplicatedToEditableSectionToastText(dataPanel.provided_id),
            DEFAULT_TOAST_DURATION,
          );
        }}
        text="Duplicate to editable section"
      />
    </Menu>
  );
};
