import cx from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { sprinkles } from 'components/ds';

import * as styles from './index.css';

export type BreadcrumbsHierarchyItem = {
  /**
   * Text to show in breadcrumb item.
   */
  text: string;
  /**
   * Link for internal path to navigate to when breadcrumb item is clicked.
   */
  link?: string;
  onClick?: () => void;
};

export type Props = {
  /**
   * A list of breadcrumb items to display linking to other places in the navigation items.
   * Each item can optionally have a `link` property.
   */
  items: BreadcrumbsHierarchyItem[];
  /**
   * Optional class name for style overrides
   */
  className?: string;
};

export const Breadcrumbs: FC<Props> = ({ items, className }) => {
  return (
    <div className={cx(styles.baseContainer, className)}>
      {items.map((item, index) => (
        <span key={index}>
          {item.link ? (
            <Link className={styles.linkItem} style={{ textDecoration: 'none' }} to={item.link}>
              {item.text}
            </Link>
          ) : (
            <span
              className={item?.onClick ? styles.linkItem : styles.displayItem}
              onClick={item?.onClick}>
              {item.text}
            </span>
          )}
          {index !== items.length - 1 && <span className={styles.divider}> / </span>}
        </span>
      ))}
    </div>
  );
};

/**
 * Folder Breadcrumbs are styled based on their position in the list:
 *   - The first and last breadcrumbs have no maximum width, allowing them to shrink or expand based on available space.
 *   - Middle breadcrumbs are constrained to a maximum width of 80 pixels.
 *   - Text is truncated with ellipsis to prevent overflow.
 *
 */
export const FolderBreadcrumbs: FC<Props> = ({ items }) => (
  <div style={{ display: 'flex', overflow: 'hidden' }}>
    {items.map((item, index) => {
      const isLastItem = index === items.length - 1;
      const maxWidth = index === 0 || isLastItem ? undefined : 80;
      return (
        <span
          className={sprinkles({ display: 'flex', overflow: 'hidden', maxWidth, minWidth: 0 })}
          key={index}>
          <span
            className={cx(
              item?.onClick ? styles.linkItem : styles.displayItem,
              sprinkles({ truncateText: 'ellipsis', maxWidth, minWidth: 0 }),
            )}
            onClick={item?.onClick}>
            {item.text}
          </span>
          {!isLastItem && (
            <span className={cx(styles.divider, sprinkles({ marginX: 'sp.5' }))}>/</span>
          )}
        </span>
      );
    })}
  </div>
);
