import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { BooleanToggle, Switch, sprinkles, Input, Label } from 'components/ds';
import { BoxPlotFormat, OPERATION_TYPES, V2BoxPlotInstructions } from 'constants/types';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { ReduxState } from 'reducers/rootReducer';
import { titleCase } from 'utils/graphUtils';
import { cloneDeep } from 'utils/standard';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2BoxPlotInstructions;
};

export const BoxPlotConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const { calcColumns, boxPlotFormat } = instructions;
  const dispatch = useDispatch();
  const context = useContext(GlobalStylesContext);
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateBoxPlotFormat = (boxPlotFormat: BoxPlotFormat) => {
    const newInstructions = cloneDeep(instructions);
    newInstructions.boxPlotFormat = {
      ...newInstructions.boxPlotFormat,
      ...boxPlotFormat,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      {calcColumns?.map((col, idx) => (
        <>
          <div className={configInputClass}>
            <div className={sprinkles({ fontSize: 12, fontWeight: 600 })}>
              {titleCase(col.name || `Column ${idx + 1}`)}
            </div>
            <Input
              showInputButton
              defaultValue={String(
                boxPlotFormat?.seriesLabelByColumn?.[col.name || ''] || `Series ${idx + 1}`,
              )}
              label="Series label"
              onSubmit={(newValue) =>
                updateBoxPlotFormat({
                  seriesLabelByColumn: {
                    ...instructions.boxPlotFormat?.seriesLabelByColumn,
                    ...(col.name && { [col.name]: newValue }),
                  },
                })
              }
            />
          </div>
          <div
            className={sprinkles({
              flexItems: 'alignCenterBetween',
              marginBottom: 'sp1',
              paddingX: 'sp1.5',
            })}>
            <div className={sprinkles({ width: 'fill' })}>
              <Label htmlFor="">Fill color</Label>
              <ColorPickerButton
                fillWidth
                color={
                  instructions.boxPlotFormat?.fillColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.categoricalPalette.hue1
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor) => {
                  updateBoxPlotFormat({
                    fillColorByColumn: {
                      ...instructions.boxPlotFormat?.fillColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  });
                }}
              />
            </div>
            <div className={sprinkles({ marginLeft: 'sp1.5', width: 'fill' })}>
              <Label htmlFor="">Median color</Label>
              <ColorPickerButton
                fillWidth
                color={
                  instructions.boxPlotFormat?.medianColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.categoricalPalette.hue2
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor) =>
                  updateBoxPlotFormat({
                    medianColorByColumn: {
                      ...instructions.boxPlotFormat?.medianColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  })
                }
              />
            </div>
          </div>
        </>
      ))}
      <BooleanToggle
        className={configInputClass}
        falseText="Horizontal"
        label="Orientation"
        onValueChange={(isVertical) => updateBoxPlotFormat({ isVertical })}
        selectedValue={!!boxPlotFormat?.isVertical}
        trueText="Vertical"
      />
      <Switch
        className={configInputClass}
        label="Show whisker"
        onChange={() => updateBoxPlotFormat({ hideWhisker: !boxPlotFormat?.hideWhisker })}
        switchOn={!boxPlotFormat?.hideWhisker}
      />
    </div>
  );
};
