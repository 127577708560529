import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { Switch, Input } from 'components/ds';
import {
  DEFAULT_NO_DATA_FONT_SIZE,
  NoDataConfig as noDataConfig,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
  isKpiChart: boolean;
  isDataTable: boolean;
};

export const NoDataConfig: FC<Props> = ({ generalFormatOptions, isKpiChart, isDataTable }) => {
  const dispatch = useDispatch();

  const noDataState = generalFormatOptions.noDataState;

  const updateNoDataState = (noDataUpdates: noDataConfig) => {
    const newFormat = produce(generalFormatOptions, (draft) => {
      draft.noDataState = { ...draft?.noDataState, ...noDataUpdates };
    });

    dispatch(updateGeneralFormatOptions(newFormat));
  };

  return (
    <div className={configRootClass}>
      {isKpiChart ? (
        <Switch
          className={configInputClass}
          label="Zero Is No Data"
          onChange={() => updateNoDataState({ isZeroNoData: !noDataState?.isZeroNoData })}
          switchOn={generalFormatOptions?.noDataState?.isZeroNoData}
        />
      ) : (
        <Switch
          className={configInputClass}
          label="Hide Chart Icon"
          onChange={() => updateNoDataState({ hideChartIcon: !noDataState?.hideChartIcon })}
          switchOn={noDataState?.hideChartIcon}
        />
      )}
      {isDataTable ? (
        <Switch
          className={configInputClass}
          label="Hide Filter and Download Buttons"
          onChange={() =>
            updateNoDataState({
              hideFilterAndDownloadButtons: !noDataState?.hideFilterAndDownloadButtons,
            })
          }
          switchOn={noDataState?.hideFilterAndDownloadButtons}
        />
      ) : null}
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={generalFormatOptions.noDataState?.noDataText || 'No Data'}
        label="No Data Text"
        onSubmit={(newValue: string) => updateNoDataState({ noDataText: newValue })}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(
          generalFormatOptions.noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
        )}
        label="Text Size"
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          if (!isNaN(intValue)) updateNoDataState({ noDataFontSize: intValue });
        }}
      />
    </div>
  );
};
