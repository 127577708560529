import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from './types';

export interface EnvironmentTag {
  id: number;
  team_id: number;
  dashboard_versions_by_dashboard?: Record<number, number>;
  report_builder_mapping?: Record<number, number>;
  name: string;
  is_prod: boolean;
  color_hex: string;
}

export const { actionFn: fetchEnvironmentTags, ...fetchEnvironmentTagsActions } = defineAPIAction<{
  tags: EnvironmentTag[];
}>(ACTION.FETCH_ENVIRONMENT_TAGS, 'environment_tags', '', 'GET');

export const { actionFn: createEnvironmentTag, successAction: createEnvironmentTagSuccess } =
  defineAPIPostAction<{ name: string; hex: string | undefined }, { tag: EnvironmentTag }>(
    ACTION.CREATE_ENVIRONMENT_TAG,
    'environment_tags',
    'create_environment_tag',
    'POST',
  );

type UpdateEnvironmentTagBody =
  | { color_hex: string }
  | { name: string }
  | ({ version_id: number } & ({ dashboard_id: number } | { report_builder_id: number }));

export const { actionFn: updateEnvironmentTag, successAction: updateEnvironmentTagSuccess } =
  defineAPIPostAction<UpdateEnvironmentTagBody & { tag_id: number }, { tag: EnvironmentTag }>(
    ACTION.UPDATE_ENVIRONMENT_TAG,
    'environment_tags',
    'update_environment_tag',
    'POST',
  );

export type EnvTagUsage = { dashboard_names: string[]; report_builder_names: string[] };

export const { actionFn: getEnvironmentTagUsage } = defineAPIAction<EnvTagUsage>(
  ACTION.GET_ENVIRONMENT_TAG_USAGE,
  'environment_tags',
  'get_usage',
  'GET',
);

export const { actionFn: deleteEnvironmentTag, successAction: deleteEnvironmentTagSuccess } =
  defineAPIAction(ACTION.DELETE_ENVIRONMENT_TAG, 'environment_tags', '', 'DELETE');
