export const DEFAULT_TIME_FORMAT = 'DD days HH:mm:ss';
export const DEFAULT_DURATION_FORMAT = "dd 'days' hh 'hours' mm 'minutes'";

export const DURATION_INFO_TEXT_COMPONENT = (
  <div>
    Your value will be read as time in seconds and formatted using{' '}
    <a href="https://moment.github.io/luxon/api-docs/index.html#durationtoformat">
      Luxon duration format options.
    </a>{' '}
    To include extra text, wrap it in single quotes, e.g., dd &lsquo;days&rsquo; hh
    &lsquo;hours&rsquo;. For example, if your value is 70 and your custom format is &quot;mm
    &lsquo;minutes&rsquo; ss &lsquo;seconds&rsquo;&quot;, the output would be: 01 minute 10 seconds.
  </div>
);
