import { FC, useContext } from 'react';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { Icon, Tooltip, sprinkles } from 'components/ds';

export type Props = {
  text: string;
  // Some uses already have margins set by gap or other ways
  noMargin?: boolean;
};

export const EmbedInfoIcon: FC<Props> = ({ text, noMargin }) => {
  const context = useContext(DashboardLayoutContext);

  return (
    <Tooltip portalContainerId={context.dashboardLayoutTagId} text={text}>
      <Icon
        className={sprinkles({ color: 'gray8', marginLeft: noMargin ? undefined : 'sp1' })}
        name="circle-info"
        size="sm"
      />
    </Tooltip>
  );
};
