import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

export const DateFormatDescriptiveText: FC = () => {
  return (
    <div
      className={cx(
        sprinkles({
          flexItems: 'alignCenter',
          flexWrap: 'wrap',
          body: 'b3',
          marginTop: 'sp.5',
        }),
        configInputClass,
      )}>
      Learn more about date formats
      <a
        className={sprinkles({
          color: 'active',
          fontWeight: 600,
          marginLeft: 'sp.5',
        })}
        href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens"
        rel="noopener noreferrer"
        target="_blank">
        here
      </a>
    </div>
  );
};
