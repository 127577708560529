import { FC } from 'react';

import exploLogoWatermark from 'images/app/exploLogoWatermark.png';

import * as styles from './DemoWatermark.css';

export const DemoWatermark: FC = () => {
  return (
    <>
      <img className={styles.watermarkLogo} src={exploLogoWatermark} />
      <div className={styles.watermarkText}>This is a demo account.</div>
    </>
  );
};
