import { defineAPIAction } from 'actions/actionUtils';
import { ResourceSearchResult } from 'types/exploResource';

import { ACTION } from './types';

export const {
  actionFn: searchResources,
  requestAction: searchResourcesRequest,
  errorAction: searchResourcesError,
  successAction: searchResourcesSuccess,
} = defineAPIAction<{ results: ResourceSearchResult[] }>(
  ACTION.SEARCH_RESOURCES,
  'resources',
  'search',
  'GET',
);
