import cx from 'classnames';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Icon, Spinner, sprinkles } from 'components/ds';
import { DASHBOARD_CLASS_NAME, DATA_PANEL_ERROR_CLASS_NAME } from 'constants/exportConstants';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import { getOrDefault } from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { fetchEmbeddedData } from 'reportBuilderContent/thunks/embeddedDataThunks';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { getGroupByName } from 'utils/V2ColUtils';
import { getFilterClauseValueText } from 'utils/customerReportUtils';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { uniq } from 'utils/standard';

import * as styles from './Export.css';
import { GRID_ROW_HEIGHT } from './constants';

type Props = {
  title?: string;
  view: CustomerReportView;
  dataset: ReportBuilderDataset;
};

export const ExportChart: FC<Props> = ({ title, view, dataset }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const viewData = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.reportData[view.id],
  );

  useEffect(() => {
    // page is undefined so row count is fetched
    dispatch(fetchEmbeddedData(undefined, dataset, view, view.id, true));
  }, [dispatch, dataset, view]);

  const validFilters = useMemo(
    () => view.filters?.filter((clause) => !isFilterClauseIncomplete(clause)),
    [view.filters],
  );

  const totalCount = getOrDefault(viewData?.rowCount, 0);
  const rowCount = viewData?.rows?.length;

  const height = useMemo(() => {
    // We shouldn't be using Data Grid because it requires a fixed height
    // So we need to hackily calculate the height based on the number of rows and other content
    const isTable = isTableVisualization(view.visualization);
    if (!isTable || !rowCount) return '100vh';

    const titleHeight = 60;
    const headerHeight = ((view.columnGroupBys?.length || 0) + 1) * 42;
    const totalsHeight = Object.keys(view.totals || {}).length ? GRID_ROW_HEIGHT : 20;
    const paginatorHeight = view.aggregations?.length || view.groupBys?.length ? 0 : 48;
    const numRows = view.groupBys?.length
      ? view.groupBys?.reduce(
          ([acc, multiply, prevGroupLength], groupBy) => {
            const values = uniq(viewData?.rows?.map((row) => row[getGroupByName(groupBy)]));
            const nextPermutations = multiply * values.length;
            return [acc + nextPermutations, nextPermutations, prevGroupLength];
          },
          [0, 1, 0],
        )[0]
      : rowCount || 1;
    return numRows * GRID_ROW_HEIGHT + headerHeight + paginatorHeight + totalsHeight + titleHeight;
  }, [view, rowCount, viewData]);

  return (
    <div className={styles.reportContainer} ref={containerRef} style={{ height }}>
      <div className={styles.reportHeader}>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          <EmbedText heading="h2">
            {title ? `${title}: ` : null}
            {view.name}
          </EmbedText>
          {viewData?.rows && totalCount > viewData?.rows.length ? (
            <EmbedText body="b2" color="contentTertiary">
              Displaying {rowCount} of {totalCount} rows
            </EmbedText>
          ) : null}
        </div>
        {validFilters ? (
          <div className={styles.filterContainer}>
            {validFilters.map((filter) => {
              return (
                <div className={styles.filterTag} key={filter.id}>
                  <Icon name="filter" />
                  <EmbedText body="b3">
                    {filter.filterColumn.name}&nbsp;{getFilterClauseValueText(filter)}
                  </EmbedText>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {viewData?.isLoading ? (
        <Spinner />
      ) : viewData?.rows ? (
        <div className={cx(DASHBOARD_CLASS_NAME, styles.chartContainer)}>
          <ReportChart
            containerRef={containerRef}
            dataset={dataset}
            reportData={viewData}
            view={view}
          />
        </div>
      ) : (
        <EmbedText body="b3" className={DATA_PANEL_ERROR_CLASS_NAME} color="contentTertiary">
          {viewData?.error || 'View data not loaded'}
        </EmbedText>
      )}
    </div>
  );
};
