import cx from 'classnames';
import { FC } from 'react';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { DashboardVariableMap, ImageElemConfig } from 'types/dashboardTypes';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';

type Props = {
  config: ImageElemConfig;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
};

export const DashboardImageElement: FC<Props> = ({
  config,
  variables,
  datasetData,
  datasetNamesToId,
}) => {
  if (!config.imageUrl) {
    return (
      <div
        className={cx(
          rootClass,
          sprinkles({ fontStyle: 'italic' }),
          embedSprinkles({ body: 'primary' }),
        )}>
        Enter an image URL
      </div>
    );
  }

  return (
    <div className={cx(rootClass, sprinkles({ flexItems: 'center' }))}>
      <img
        alt="dashboard custom"
        className={cx(
          config.fitEntireImage ? fitEntireClass : imageClass,
          embedSprinkles({ borderRadius: 'container' }),
        )}
        src={replaceVariablesInString(config.imageUrl, variables, datasetNamesToId, datasetData)}
      />
    </div>
  );
};

const fitEntireClass = sprinkles({ maxWidth: 'fill', maxHeight: 'fill' });
const imageClass = sprinkles({ parentContainer: 'fill', zIndex: 0, objectFit: 'cover' });
const rootClass = sprinkles({ parentContainer: 'fill', overflow: 'hidden', position: 'relative' });
