import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/utils';
import { ReduxState } from 'reducers/rootReducer';

import { getCurrentTheme } from '../../reducers/dashboardStylesReducer';
import { getOrDefault, hasNotReturned } from '../../remotedata';

import { DashboardPage } from './dashboardPage';

export const DashboardPageWrapper: FC = () => {
  const { team, globalStyleConfig, fontConfig } = useSelector((state: ReduxState) => ({
    fontConfig: state.dashboardStyles.fontConfig,
    globalStyleConfig: getCurrentTheme(state.dashboardStyles),
    team: state.currentUser.team,
  }));

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [fontConfig, globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {() => <DashboardPage />}
    </GlobalStylesProvider>
  );
};
