import cx from 'classnames';
import { FC } from 'react';

import { Icon, sprinkles, Colors } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { KPITrendDisplayFormat, KPIValueFormat } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { formatValue } from 'pages/dashboardPage/charts/utils';

interface Props {
  globalStyleConfig: GlobalStyleConfig;
  /**
   * This should be how much the trend changed as either an absolute (e.g. 400,000) or percentage (e.g. 0.4) value
   */
  trendChangeVal?: number;
  /**
   * This is shown after the percent change text e.g. +40% <Comparison Period>
   */
  trendChangeValLabel: string;
  trendChangeValFormatId: string;
  displayFormat?: KPITrendDisplayFormat;
  valueFormat?: KPIValueFormat;
  noDataPrevPeriod: boolean;
  currentValue: number;
}

export const TrendChange: FC<Props> = ({
  globalStyleConfig,
  trendChangeVal,
  trendChangeValFormatId,
  trendChangeValLabel,
  displayFormat,
  valueFormat,
  noDataPrevPeriod,
  currentValue,
}) => {
  const { showTrendChangePeriodLabel, trendColorsReversed, useTrendTag } = displayFormat || {};

  if (trendChangeVal === undefined) return <></>;

  const isNegativeChange = trendChangeVal < 0 || (noDataPrevPeriod && currentValue < 0);
  const isPositiveChange = trendChangeVal > 0 || (noDataPrevPeriod && currentValue > 0);

  let backgroundColor: Colors = 'gray5';
  let trendColor: Colors = 'contentPrimary';
  if (isNegativeChange) {
    backgroundColor = trendColorsReversed ? 'green5' : 'red5';
    trendColor = trendColorsReversed ? 'green11' : 'red11';
  } else if (isPositiveChange) {
    backgroundColor = trendColorsReversed ? 'red5' : 'green5';
    trendColor = trendColorsReversed ? 'red11' : 'green11';
  }

  const isPercent = trendChangeValFormatId === V2_NUMBER_FORMATS.PERCENT.id;
  const defaultDecimalPlaces = isPercent ? 1 : 0;
  const formattedTrendChangeVal = formatValue({
    value: Math.abs(trendChangeVal),
    decimalPlaces: displayFormat?.trendDecimalPlaces ?? defaultDecimalPlaces,
    formatId: trendChangeValFormatId,
    hasCommas: true,
    customTimeFormat: valueFormat?.timeCustomerFormat,
    customDurationFormat: valueFormat?.customDurationFormat,
    timeFormatId: valueFormat?.timeFormat?.id,
    significantDigits: valueFormat?.significantDigits ?? 3,
    multiplier: isPercent ? 1 : valueFormat?.multiplyFactor,
  });
  const displayValue = noDataPrevPeriod ? <Icon name="infinity" /> : formattedTrendChangeVal;

  return (
    <div
      className={cx(
        sprinkles({
          flexItems: 'center',
          fontWeight: 500,
          color: trendColor,
        }),
        {
          [sprinkles({
            paddingX: 'sp2',
            paddingY: 'sp1',
            borderRadius: 2,
            backgroundColor,
          })]: useTrendTag,
        },
        embedSprinkles({ body: 'primaryWithoutColor' }),
      )}>
      <Icon
        name={isNegativeChange ? 'caret-down' : 'caret-up'}
        style={{ width: globalStyleConfig.text.textSize, height: globalStyleConfig.text.textSize }}
      />
      {displayValue}
      {valueFormat?.units && !isPercent ? (
        <span className={sprinkles({ marginLeft: valueFormat?.unitPadding ? 'sp.5' : 'sp0' })}>
          {valueFormat?.units}
        </span>
      ) : null}
      {showTrendChangePeriodLabel ? (
        <span className={sprinkles({ marginLeft: 'sp.5' })}>{trendChangeValLabel}</span>
      ) : null}
    </div>
  );
};
