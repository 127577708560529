import { createContext } from 'react';

import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';

import { DEFAULT_GLOBAL_STYLE_CONFIG } from './constants';
import { GlobalStyleConfig } from './types';

export const GlobalStylesContext = createContext<{
  globalStyleConfig: GlobalStyleConfig;
  globalStylesClassName?: string;
  globalStyleVars: Record<string, string>;
}>({
  globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
  globalStyleVars: getGlobalStyleVars(DEFAULT_GLOBAL_STYLE_CONFIG),
});
