import { createSlice, Reducer } from '@reduxjs/toolkit';

import * as actions from 'actions/reportBuilderActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { moveEntry, listReportFolderContents } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { Breadcrumb, ResourceType } from 'types/exploResource';

import { cloneResourceThunk } from './thunks/versionManagementThunks';

interface ReportBuilderReducerState {
  currentReportBuilder: RD.ResponseData<CurrentReportBuilder>;
  reportBuilders: ReportBuilder[];
}

interface CurrentReportBuilder extends ReportBuilder {
  breadcrumbs: Breadcrumb[];
}

const reportBuilderReducerInitialState: ReportBuilderReducerState = {
  currentReportBuilder: RD.Idle(),
  reportBuilders: [],
};

const reportBuilderSlice = createSlice({
  name: 'reportBuilder',
  initialState: reportBuilderReducerInitialState,
  reducers: {
    clearReportBuilders: (state) => {
      state.reportBuilders = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(listReportFolderContents.fulfilled, (state, { payload }) => {
        state.reportBuilders = payload.resources;
      })
      .addCase(actions.createReportBuilderSuccess, (state, { payload }) => {
        state.reportBuilders.push(payload.report_builder);
        state.currentReportBuilder = RD.Success({
          ...payload.report_builder,
          // breadcrumbs are loaded imminently when we fetch the dashboard
          breadcrumbs: [],
        });
      })
      .addCase(actions.deleteReportBuilderSuccess, (state, { payload }) => {
        state.reportBuilders = state.reportBuilders.filter((rb) => rb.id !== payload.id);
      })
      .addCase(actions.fetchReportBuilderActions.successAction, (state, { payload }) => {
        state.currentReportBuilder = RD.Success({
          ...payload.report_builder,
          breadcrumbs: payload.breadcrumbs,
        });
      })
      .addCase(actions.fetchReportBuilderActions.requestAction, (state) => {
        state.currentReportBuilder = RD.Loading();
      })
      .addCase(actions.fetchReportBuilderActions.errorAction, (state) => {
        state.currentReportBuilder = RD.Error('Error fetching report builder');
      })
      .addCase(actions.renameReportBuilderSuccess, (state, { payload }) => {
        const reportBuilder = state.reportBuilders.find((elem) => elem.id === payload.id);
        if (reportBuilder) reportBuilder.name = payload.name;

        if (
          !RD.isSuccess(state.currentReportBuilder) ||
          state.currentReportBuilder.data.id !== payload.id
        )
          return;
        state.currentReportBuilder.data.name = payload.name;
      })
      .addCase(cloneResourceThunk.fulfilled, (state, { payload, meta }) => {
        const { new_resource } = payload;
        if (meta.arg.isExplore || 'dashboard_attributes' in new_resource) return;

        state.currentReportBuilder = RD.Success({
          ...new_resource,
          // breadcrumbs are loaded imminently when we fetch the dashboard
          breadcrumbs: [],
        });
        state.reportBuilders.push(new_resource);
      })
      .addCase(moveEntry.fulfilled, (state, { payload }) => {
        if (payload.entry.type !== ResourceType.REPORT) return;
        state.reportBuilders = state.reportBuilders.filter(
          ({ entry_id }) => entry_id !== payload.entry.id,
        );
        if (RD.isSuccess(state.currentReportBuilder)) {
          state.currentReportBuilder.data.breadcrumbs = payload.breadcrumbs;
        }
      })
      .addCase(actions.updateReportDefaultTimezoneSuccess, (state, { payload }) => {
        if (RD.isSuccess(state.currentReportBuilder)) {
          state.currentReportBuilder.data.default_timezone = payload.postData.default_timezone;
        }
      }),
});

export const { clearReportBuilders } = reportBuilderSlice.actions;

// see dashboardStylesReducer for this bullshit
export const reportBuilderReducer =
  reportBuilderSlice.reducer as Reducer<ReportBuilderReducerState>;
