import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import DropdownSelect from 'shared/DropdownSelect';
import { DashboardVariable, TimePeriodDropdownElemConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';

type Props = {
  config: TimePeriodDropdownElemConfig;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value?: number;
  disabled?: boolean;
  openElementToLeft: boolean;
  isInContainer?: boolean;
  datasetNamesToId: Record<string, string>;
};

const DashboardTimePeriodDropdownElement: FC<Props> = ({
  config,
  value,
  onNewValueSelect,
  disabled,
  openElementToLeft,
  isInContainer,
  datasetNamesToId,
}) => {
  const dispatch = useDispatch();

  const options = config.values.map((option) => ({
    id: String(option.value),
    value: option.value,
    name: option.name,
  }));
  let selectedOption = options.find((option) => option.value === value);

  // If the selected value is not in the options, it is coming from a default variable.
  // In this case create your own option for this
  if (!selectedOption && value !== undefined) {
    selectedOption = { id: String(value), value, name: String(value) };
  }

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <DropdownSelect
      fillWidth
      isEmbed
      minimal
      disabled={disabled}
      filterable={!!config.enableSearch}
      label={label}
      labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
      noSelectionText={config.placeholderText || ''}
      onCancelClick={() => {
        onNewValueSelect(undefined);
        dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
      }}
      onChange={(item) => {
        onNewValueSelect(item.value);
        dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
      }}
      openElementToLeft={openElementToLeft}
      options={options}
      selectedItem={selectedOption}
      showCancelBtn={config.enableCancel}
      usePortal={isInContainer}
    />
  );
};

export default DashboardTimePeriodDropdownElement;
