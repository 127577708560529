import { createContext } from 'react';

import { uniqueId } from 'utils/standard';

type DashboardLayoutContextType = {
  dashboardLayoutTagId: string;
};

const DashboardLayoutContext = createContext<DashboardLayoutContextType>({
  dashboardLayoutTagId: uniqueId('explo-dashboard'),
});

export default DashboardLayoutContext;
