export enum ReportBuilderStep {
  START = 'Configure your report',
  NAME_REPORT = 'Name your report',
  SELECT_CONTENT = 'Select content',
  PREVIEW = 'Preview',
  EXPORT = 'Export',
}
export const REPORT_BUILDER_STEP_ORDER = [
  ReportBuilderStep.START,
  ReportBuilderStep.NAME_REPORT,
  ReportBuilderStep.SELECT_CONTENT,
  ReportBuilderStep.PREVIEW,
  ReportBuilderStep.EXPORT,
];
