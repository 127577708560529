import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { AlertModal, sprinkles } from 'components/ds';
import { deleteReportBuilderCustomAggregation } from 'reducers/reportBuilderEditReducer';

type Props = {
  datasetId: string;
  aggId: string;

  onClose: () => void;
};

export const DeleteCustomAggModal: FC<Props> = ({ datasetId, aggId, onClose }) => {
  const dispatch = useDispatch();

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        onClick: () => dispatch(deleteReportBuilderCustomAggregation({ datasetId, aggId })),
      }}
      onClose={onClose}
      title="Are you sure you want to delete this custom aggregation?">
      <div
        className={sprinkles({
          paddingX: 'sp3',
          body: 'b2',
          color: 'gray12',
        })}>
        It will be removed from any built-ins and customer reports that use it. Once you&rsquo;ve
        deleted it you won&rsquo;t be able to retrieve it.
      </div>
    </AlertModal>
  );
};
