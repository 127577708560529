import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import {
  setAuthentication,
  setDataSourceConfig,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';

import { CredentialsInput } from './CredentialsInput';

export const FidoSnowflakeCredentialsSection: FC = () => {
  const dispatch = useDispatch();

  const {
    dataSourceConfig: { type: dataSourceType, config: dataSourceConfig, auth: authConfig },
    isUpdating,
  } = useSelector(
    (state: ReduxState) => ({
      dataSourceConfig: state.fidoDataSourceConfig.dataSourceConfig,
      isUpdating: state.fidoDataSourceConfig.isUpdating,
    }),
    shallowEqual,
  );

  if (!dataSourceType || dataSourceType != DATABASES.SNOWFLAKE) {
    return null;
  }

  return (
    <>
      <CredentialsInput
        label="Username"
        placeholder="e.g. analytics"
        updateConfig={(user) => dispatch(setDataSourceConfig({ user }))}
        value={dataSourceConfig?.user}
      />

      <CredentialsInput
        label="Account"
        placeholder="e.g. AAA.us-central1.gcp"
        updateConfig={(account) => dispatch(setDataSourceConfig({ account }))}
        value={dataSourceConfig?.account}
      />

      <CredentialsInput
        helpTooltip="This is optional depending on your account setup"
        label="Schema"
        placeholder="e.g. snowflake_schema"
        updateConfig={(schema) => dispatch(setDataSourceConfig({ schema }))}
        value={dataSourceConfig?.schema}
      />

      <CredentialsInput
        helpTooltip="This is optional depending on your account setup"
        label="Database"
        placeholder="e.g. snowflake_data"
        updateConfig={(database) => dispatch(setDataSourceConfig({ database }))}
        value={dataSourceConfig?.database}
      />

      <CredentialsInput
        helpTooltip="The password is associated with the user above."
        label="Password"
        optional={isUpdating}
        placeholder={isUpdating ? 'password will be unmodified if left blank' : undefined}
        type="password"
        updateConfig={(password) => dispatch(setAuthentication({ password: password || null }))}
        value={authConfig.password}
      />
    </>
  );
};
