import { Action } from 'reducers/rootReducer';

// all the loading states are default true, however that can be changed here
const loadingReducerInitialState = {};

export default (state = loadingReducerInitialState, action: Action) => {
  const { type } = action;
  if (!type) return state;

  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};
