import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Jobs } from 'components/JobQueue/types';

import { enqueueReportBuilderJobsThunk } from '../thunks/jobThunks';

interface ReportBuilderJobState {
  jobs: Record<string, Jobs>; // Job Queue
}

const initialState: ReportBuilderJobState = {
  jobs: {},
};

const reportBuilderJobSlice = createSlice({
  name: 'reportBuilderJob',
  initialState,
  reducers: {
    setCompletedJobs: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((jobId) => {
        if (jobId in state.jobs) delete state.jobs[jobId];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(enqueueReportBuilderJobsThunk.fulfilled, (state, { payload }) => {
      state.jobs = { ...state.jobs, ...payload };
    });
  },
});

export const reportBuilderJobReducer = reportBuilderJobSlice.reducer;

export const { setCompletedJobs } = reportBuilderJobSlice.actions;
