import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listCustomerReports } from 'actions/customerReportActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { Spinner, sprinkles } from 'components/ds';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import * as RD from 'remotedata';
import {
  setTheme,
  setTimezone,
  setVariables,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { useSetupAnalytics } from 'utils/analyticsUtils';
import { loadLocale } from 'utils/localizationUtils';
import { getQueryVariables, getValueOrDefault } from 'utils/variableUtils';

import { ReportBuilder } from './ReportBuilder';

type Props = {
  customerToken: string | undefined;
  embedType: EmbeddedDashboardType;
  environment: string | undefined;
  reportBuilderEmbedId: string | undefined;
  embedJwt: string | undefined;
  localeCode?: string; // Only for embedded component since iFrame comes from params
  timezone?: string; // Only for embedded component since iFrame comes from params
  theme?: string; // Only for embedded component since iFrame comes from params
  currencyCode?: string; // Only for embedded component since iFrame comes from params
  embeddedVariables?: DashboardVariableMap;
};

export const ReportBuilderWrapper: FC<Props> = ({
  customerToken,
  embeddedVariables,
  environment,
  reportBuilderEmbedId,
  embedType,
  embedJwt,
  localeCode,
  timezone,
  theme,
  currencyCode,
}) => {
  const dispatch = useDispatch();

  const reportBuilder = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.reportBuilder,
  );

  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables(embedType));

  useEffect(() => {
    const postData = { environment: environment ?? null, resource_embed_id: reportBuilderEmbedId };
    dispatch(
      listCustomerReports({ customerToken, jwt: embedJwt, postData }, (data) => {
        loadLocale({
          passedCurrencyCode: getValueOrDefault('currency_code', currencyCode),
          passedLocaleCode: getValueOrDefault('locale_code', localeCode),
          teamCurrencyCode: data.team.default_currency_code,
          teamLocaleCode: data.team.default_locale_code,
          useBrowserLocale: data.team.use_browser_locale,
        });
      }),
    );
  }, [
    dispatch,
    customerToken,
    embedJwt,
    environment,
    reportBuilderEmbedId,
    currencyCode,
    localeCode,
  ]);

  useEffect(() => {
    dispatch(setVariables({ ...urlVariables, ...embeddedVariables }));
  }, [dispatch, embeddedVariables, urlVariables]);

  useEffect(() => {
    const passedTimezone = getValueOrDefault('timezone', timezone);
    if (passedTimezone) dispatch(setTimezone(passedTimezone));
  }, [dispatch, timezone]);

  useEffect(() => {
    const passedTheme = getValueOrDefault('theme', theme);
    if (typeof passedTheme === 'string') dispatch(setTheme(passedTheme));
  }, [theme, dispatch]);

  const analyticsReady = useSetupAnalytics({
    environment,
    embedType,
    pageViewEvent: REPORTED_ANALYTIC_ACTION_TYPES.ANALYTICS_HUB_VIEWED,
  });

  return (
    <RD.RemoteComponent
      Error={(error) => <div className={errorClassName}>{error}</div>}
      Loading={() => <Spinner fillContainer />}
      Success={() => <ReportBuilder isIframe={embedType === 'iframe'} />}
      data={analyticsReady || RD.isError(reportBuilder) ? reportBuilder : RD.Loading()}
    />
  );
};

const errorClassName = sprinkles({
  parentContainer: 'fill',
  flexItems: 'center',
  heading: 'h1',
  color: 'contentPrimary',
});
