import cx from 'classnames';
import { FC } from 'react';

import { Input, sprinkles } from 'components/ds';

type Props = {
  className?: string;
  startLabel?: string;
  endLabel?: string;
  startVal?: string;
  endVal?: string;
  onNewRange: (start?: string, end?: string) => void;
  usesVariables?: boolean;
};

export const RangeInput: FC<Props> = ({
  className,
  onNewRange,
  startLabel = 'Minimum Value',
  endLabel = 'Maximum Value',
  startVal,
  endVal,
  usesVariables,
}) => {
  return (
    <div className={cx(sprinkles({ flexItems: 'alignCenterBetween' }), className)}>
      <Input
        fillWidth
        showInputButton
        className={sprinkles({ marginRight: 'sp1' })}
        defaultValue={String(startVal ?? '')}
        label={{ text: startLabel, variableInput: usesVariables }}
        onSubmit={(newStartString) => onNewRange(newStartString || undefined, endVal)}
        placeholder="Min"
      />
      <Input
        fillWidth
        showInputButton
        defaultValue={String(endVal ?? '')}
        label={{ text: endLabel, variableInput: usesVariables }}
        onSubmit={(newEndString) => onNewRange(startVal || undefined, newEndString)}
        placeholder="Max"
      />
    </div>
  );
};
