import GridLayout, { Layout } from '@explo-tech/react-grid-layout';
import cx from 'classnames';
import { FC } from 'react';
import { SizeMe } from 'react-sizeme';

import { Icon, sprinkles, vars } from 'components/ds';
import Markdown from 'components/markdown';
import * as previews from 'constants/dashboardPreviews';
import { OPERATION_TYPES } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  ApplyFilterElemConfig,
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  TextDashboardElemConfig,
} from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { getEmptySectionHeight } from 'utils/editableSectionUtils';
import { isDashboardElement } from 'utils/exploResourceUtils';
import { getSortedGridItems } from 'utils/layoutUtils';

import * as styles from './ResourcePreview.css';

type Props = {
  layout: Layout[];
  dataPanels: DataPanel[];
  elements: DashboardElement[];
  globalStyleConfig: GlobalStyleConfig;
  editableSectionTitle?: string;
  // container info
  containerId?: string;
  cols?: number;
  useZoom?: boolean;
};

const MARGIN = 5;
const ROW_HEIGHT = 20;

export const ResourcePreview: FC<Props> = ({
  layout,
  dataPanels,
  elements,
  globalStyleConfig,
  editableSectionTitle,
  containerId,
  cols,
  useZoom,
}) => {
  const { base } = globalStyleConfig;

  const isNotContainer = containerId === undefined;

  const viewDPHeader = ({ visualize_op }: DataPanel) => {
    if (visualize_op.generalFormatOptions?.headerConfig?.isHeaderHidden) return null;

    const textAlign =
      visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
        ? 'center'
        : 'initial';
    return (
      <div className={styles.dpTitle} style={{ textAlign }}>
        {visualize_op.generalFormatOptions?.headerConfig?.title ?? 'Title'}
      </div>
    );
  };

  const viewDataPanel = (dp: DataPanel) => {
    return (
      <div
        className={cx(styles.dataPanelContainer, {
          [styles.borderStyle]: isNotContainer,
        })}
        key={dp.id}>
        {viewDPHeader(dp)}
        {
          <div className={sprinkles({ flexItems: 'center', flex: 1, overflow: 'hidden' })}>
            {getIconFromOpType(dp.visualize_op.operation_type)}
          </div>
        }
      </div>
    );
  };

  const wrapElem = (label: string, element: JSX.Element) => {
    return (
      <div className={styles.elemContainer}>
        <div className={styles.label}>{label}</div>
        {element}
      </div>
    );
  };
  const viewElement = (elem: DashboardElement) => {
    let elemDiv;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const label = (elem.config as any).label ?? '';
    switch (elem.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT: {
        const elemConfig = elem.config as TextDashboardElemConfig;
        const text = elemConfig.text ?? 'No text currently configured';
        const textSize = elemConfig.textSize || 'SMALL';
        const fontSize = textSize === 'LARGE' ? 9 : textSize === 'MEDIUM' ? 7 : 5;
        elemDiv = (
          <div className={styles.textElem} style={{ fontSize }}>
            <Markdown markdownText={text} />
          </div>
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.SPACER: {
        elemDiv = <div />;
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT: {
        const currIcon =
          elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT ? 'cross' : 'caret-down';
        elemDiv = wrapElem(
          label,
          <div className={styles.inputElem}>
            <Icon className={styles.downArrow} name={currIcon} size="xs" />
          </div>,
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        elemDiv = wrapElem(
          label,
          <div className={styles.toggleElem}>
            <div className={styles.toggleOpt}>A</div>
            <div className={styles.toggleOpt}>B</div>
            <div className={styles.toggleOpt}>C</div>
          </div>,
        );
        break;

      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        elemDiv = (
          <div className={styles.switchWrapper}>
            <div className={styles.label} style={{ marginRight: 4 }}>
              {label}
            </div>
            {previews.SWITCH(base.actionColor.buttonColor ?? base.actionColor.default)}
          </div>
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        elemDiv = (
          <div className={styles.elemContainer}>
            <div className={styles.elemButton}>
              {(elem.config as ApplyFilterElemConfig).buttonText ?? 'Apply Filters'}
            </div>
          </div>
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        elemDiv = (
          <div className={styles.elemContainer}>
            <div className={styles.elemButton}>{label}</div>
          </div>
        );
        break;

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        elemDiv = wrapElem(label, <div className={styles.inputElem} />);
        break;
      case DASHBOARD_ELEMENT_TYPES.CONTAINER: {
        const containerConfig = elem.config as ContainerElemConfig;
        const layoutElem = layout.find((l) => l.i === elem.id);
        elemDiv = (
          <div className={styles.container}>
            <ResourcePreview
              cols={layoutElem?.w}
              containerId={elem.id}
              dataPanels={dataPanels}
              elements={elements}
              globalStyleConfig={globalStyleConfig}
              layout={containerConfig.layout}
            />
          </div>
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        elemDiv = (
          <div className={styles.image}>
            <div className={styles.imageWrapper}>{previews.IMAGE}</div>
          </div>
        );
    }
    return <div key={elem.id}>{elemDiv}</div>;
  };
  const filteredLayout = layout.filter((elem) => elem.y < 10);

  const filteredDps = dataPanels.filter((dp) =>
    containerId ? dp.container_id === containerId : !dp.container_id,
  );
  const filteredElems = elements.filter((elem) =>
    containerId ? elem.container_id === containerId : !elem.container_id,
  );

  const gridItems = getSortedGridItems([...filteredDps, ...filteredElems], filteredLayout);

  const gridLayoutChildren = gridItems.map((item) => {
    if (isDashboardElement(item)) return viewElement(item);
    return viewDataPanel(item);
  });

  const renderEditableSection = () => {
    if (editableSectionTitle === undefined) return null;
    const emptySectionHeight = getEmptySectionHeight(MARGIN, ROW_HEIGHT) / 2;

    return (
      <div className={sprinkles({ paddingX: 'sp.5', paddingY: 'sp.5' })}>
        <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
          <div className={styles.header}>{editableSectionTitle}</div>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.25' })}>
            <div className={cx(styles.elemButton, sprinkles({ padding: 'sp.5' }))}>Edit</div>
            <div className={cx(styles.elemButton, sprinkles({ padding: 'sp.5' }))}>Add</div>
          </div>
        </div>
        <div
          className={sprinkles({ marginTop: 'sp1', borderRadius: 4, flexItems: 'center' })}
          style={{ border: `2px dashed ${vars.colors.outline}`, height: emptySectionHeight }}>
          <div className={cx(styles.dpTitle, styles.header)}>Editable Section</div>
        </div>
      </div>
    );
  };

  return (
    <SizeMe>
      {({ size }) => (
        <div
          className={cx(sprinkles({ overflow: 'hidden', pointerEvents: 'none' }), {
            [embedSprinkles({ backgroundColor: 'background', color: 'primaryFont' })]:
              isNotContainer,
          })}
          style={{ height: isNotContainer ? 200 : undefined, zoom: useZoom ? 0.5 : undefined }}>
          <GridLayout
            cols={cols ?? base.numColumns}
            isDraggable={false}
            isResizable={false}
            layout={layout}
            margin={[MARGIN, MARGIN]}
            rowHeight={ROW_HEIGHT}
            useCSSTransforms={false}
            width={size.width ?? undefined}>
            {gridLayoutChildren}
          </GridLayout>
          {renderEditableSection()}
        </div>
      )}
    </SizeMe>
  );
};

function getIconFromOpType(opType: OPERATION_TYPES): JSX.Element | undefined {
  switch (opType) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
    case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      return previews.TABLE;
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      return previews.LINE_CHART;
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      return previews.AREA_CHART;
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      return previews.PIE_CHART;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      return previews.VERTICAL_BAR_CHART;
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
      return previews.HORIZONTAL_BAR_CHART;
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      return previews.DONUT_CHART;
    case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
      return previews.SPIDER_CHART;
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
      return previews.KPI_TREND;
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      return previews.FUNNEL_CHART;
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      return previews.BOX_PLOT_CHART;
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
      return previews.HEATMAP_CHART;
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      return previews.COMBO_CHART;
    case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
    case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      return previews.MAP_CHART;
    case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      return previews.KPI_PROGRESS;
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      return previews.KPI;
    // TODO: Get preview Icon from Carly for Sankey

    default:
      return undefined;
  }
}
