import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { keyBy } from 'utils/standard';

import { getDataPanelDatasetId } from './exploResourceUtils';
import { getSelectFilterDatasetId } from './filterUtils';
import { getDatasetName } from './naming';

export function getDataPanelsUsingDataset(dataPanels: DataPanel[], datasetId: string) {
  return dataPanels
    .filter((dp) => datasetId === getDataPanelDatasetId(dp))
    .map((dp) => dp.provided_id);
}

export function getElementsUsingDataset(
  elements: DashboardElement[],
  datasetId: string,
  elementId?: string,
) {
  return elements
    .filter((elem) => {
      if (!SELECT_ELEMENT_SET.has(elem.element_type) || elem.id === elementId) return false;

      return getSelectFilterDatasetId(elem.config as SelectElemConfig) === datasetId;
    })
    .map((element) => element.name);
}

export const getDatasetsByName = (
  datasets: Record<string, ResourceDataset>,
): Record<string, ResourceDataset> =>
  keyBy(Object.values(datasets), (dataset) => getDatasetName(dataset, false));

export const getDatasetNamesToId = (
  datasets: Record<string, ResourceDataset>,
): Record<string, string> => {
  const datasetNamesToId: Record<string, string> = {};
  Object.values(datasets).forEach((dataset) => {
    datasetNamesToId[getDatasetName(dataset, false)] = dataset.id;
  });
  return datasetNamesToId;
};
