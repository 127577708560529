import { FC, useMemo } from 'react';

import { Switch } from 'components/ds';
import MultiSelect from 'components/multiSelect';
import { INPUT_TYPE } from 'constants/types';
import DropdownSelect from 'shared/DropdownSelect';
import {
  DASHBOARD_ELEMENT_TYPES,
  SelectElemConfig,
  SELECT_FILTER_TYPE,
} from 'types/dashboardTypes';
import { constructManualOptions } from 'utils/dropdownUtils';

import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SelectElemConfig;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig, reRequestRows: boolean) => void;
};

export const SelectDefaultValueConfig: FC<Props> = ({ config, selectType, updateSelectConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  const defaultValue = config.valuesConfig.manualDefaultValue;

  const isQuery = config.valuesConfig.valuesSource === INPUT_TYPE.QUERY;
  const isMultiSelect = selectType === DASHBOARD_ELEMENT_TYPES.MULTISELECT;

  const selectedOptions = useMemo(() => {
    if (isQuery || !isMultiSelect || typeof defaultValue !== 'string') return;

    try {
      const selectedSet: Set<string | number> = new Set();
      const valueList = JSON.parse(defaultValue) as string[] | number[];
      valueList.forEach((val) => selectedSet.add(val));
      return selectedSet;
    } catch {
      return;
    }
  }, [isMultiSelect, defaultValue, isQuery]);

  const options = useMemo(
    () => (isQuery ? [] : constructManualOptions(config.valuesConfig)),
    [config.valuesConfig, isQuery],
  );

  if (isQuery) {
    return (
      <Switch
        label={'Default to first value on page load'}
        onChange={() =>
          updateConfig((draft) => {
            draft.valuesConfig.queryDefaultFirstValue = !config.valuesConfig.queryDefaultFirstValue;
          }, true)
        }
        switchOn={config.valuesConfig.queryDefaultFirstValue}
      />
    );
  }

  if (isMultiSelect) {
    const selectedItems = selectedOptions?.size
      ? options.filter((opt) => opt.value !== undefined && selectedOptions.has(opt.value))
      : [];

    return (
      <MultiSelect
        options={options}
        selectedItems={selectedItems}
        updateSelectedValues={(newValues) =>
          updateConfig(
            (draft) =>
              (draft.valuesConfig.manualDefaultValue =
                newValues != null ? JSON.stringify(newValues) : undefined),
          )
        }
      />
    );
  }

  return (
    <DropdownSelect
      fillWidth
      ignoreCustomStyles
      minimal
      showCancelBtn
      filterable={false}
      noSelectionText="Select a default value"
      onCancelClick={() =>
        updateConfig((draft) => (draft.valuesConfig.manualDefaultValue = undefined))
      }
      onChange={(item) =>
        updateConfig((draft) => (draft.valuesConfig.manualDefaultValue = item.value as string))
      }
      options={options}
      selectedItem={
        defaultValue != null ? options.find((opt) => opt.value === defaultValue) : undefined
      }
    />
  );
};
