import {
  ALIGN_TOP_ICON,
  ALIGN_LEFT_ICON,
  ALIGN_BOTTOM_ICON,
  ALIGN_RIGHT_ICON,
  ALIGN_VERT_CENTER_ICON,
  ALIGN_HORIZ_CENTER_ICON,
  ALIGN_TITLE_ABOVE_ICON,
  ALIGN_TITLE_BELOW_ICON,
  ALIGN_TITLE_FIXED_LEFT_ICON,
  ALIGN_TITLE_FIXED_CENTER_ICON,
} from 'constants/iconConstants';
import {
  TextElemHorizAlignments,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  VerticalContentAlignments,
  VERTICAL_CONTENT_ALIGNMENTS,
  TITLE_VALUE_ARRANGEMENTS,
  TitleValueArrangements,
} from 'types/dashboardTypes';

export const KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS: Record<
  string,
  { id: TextElemHorizAlignments; icon: JSX.Element }
> = {
  LEFT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT,
    icon: ALIGN_LEFT_ICON,
  },
  CENTER_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER,
    icon: ALIGN_HORIZ_CENTER_ICON,
  },
  RIGHT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT,
    icon: ALIGN_RIGHT_ICON,
  },
};

export const KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS = [
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.LEFT_ALIGN,
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.CENTER_ALIGN,
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

export const KPI_VERT_CONTENT_ALIGNMENT_FORMATS: Record<
  string,
  { id: VerticalContentAlignments; icon: JSX.Element }
> = {
  TOP: {
    id: VERTICAL_CONTENT_ALIGNMENTS.TOP,
    icon: ALIGN_TOP_ICON,
  },
  CENTER: {
    id: VERTICAL_CONTENT_ALIGNMENTS.CENTER,
    icon: ALIGN_VERT_CENTER_ICON,
  },
  BOTTOM: {
    id: VERTICAL_CONTENT_ALIGNMENTS.BOTTOM,
    icon: ALIGN_BOTTOM_ICON,
  },
};

export const KPI_ORDERED_VERT_CONTENT_ALIGNMENTS = [
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS.TOP,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS.CENTER,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS.BOTTOM,
];

export const KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS: Record<
  string,
  { id: TitleValueArrangements; icon: JSX.Element }
> = {
  ABOVE: {
    id: TITLE_VALUE_ARRANGEMENTS.ABOVE,
    icon: ALIGN_TITLE_ABOVE_ICON,
  },
  BELOW: {
    id: TITLE_VALUE_ARRANGEMENTS.BELOW,
    icon: ALIGN_TITLE_BELOW_ICON,
  },
  FIXED_LEFT: {
    id: TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT,
    icon: ALIGN_TITLE_FIXED_LEFT_ICON,
  },
  FIXED_CENTER: {
    id: TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER,
    icon: ALIGN_TITLE_FIXED_CENTER_ICON,
  },
};

export const KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS = [
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.ABOVE,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.BELOW,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.FIXED_LEFT,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.FIXED_CENTER,
];
