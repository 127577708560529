import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReportedAnalyticsAction } from 'actions/teamActions';
import getFingerprintUser from 'analytics/fingerprint';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { PAGE_EVENTS, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import {
  clearAnalyticsSlice,
  setVisitorId,
  AnalyticsEmbedType,
  setAnalyticsVars,
} from 'reducers/analyticsReducer';
import { AllStates } from 'reducers/rootReducer';
import { DashboardVariable } from 'types/dashboardTypes';
import { map } from 'utils/standard';

import { getEnvironment } from './environmentUtils';

const JS_EVENT_NAME = 'analyticsEventFired';

interface PostData {
  event_name: REPORTED_ANALYTIC_ACTION_TYPES;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any;
}

const sendAnalyticsEvent = (
  eventType: REPORTED_ANALYTIC_ACTION_TYPES,
  userId: string,
  analyticsToken: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any,
  shouldSendJSEvents?: boolean,
) => {
  // only send off analytics events in production
  if (!process.env.REACT_APP_ENVIRONMENT || getEnvironment() !== 'production') return;

  const postData: PostData = {
    event_name: eventType,
    user_id: userId,
    properties,
    custom_properties,
  };

  const isPageEvent = PAGE_EVENTS.has(eventType);

  const url = isPageEvent ? 'page_event' : 'track_event';

  sendAnalyticsEventHelper(url, postData, analyticsToken);
  if (shouldSendJSEvents) sendAnalyticsJSEventHelper(postData);
};

type DashboardInfo =
  | { dashboard_template_id: number; dashboard_template_name: string }
  | { blueprint_id: number; blueprint_name: string }
  | { hub_id: number; hub_name: string };

export type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  customer_id: number;
  customer_name: string;
  customer_provided_id: string;
  customer_is_demo: boolean;
} & DashboardInfo;

export type Metadata = Record<string, number | string | null | undefined>;

export const sendAnalyticsEventWrapper = (
  userId: string,
  dashboardType: string,
  embedSource: string,
  analyticsToken: string | null | undefined,
  eventName: REPORTED_ANALYTIC_ACTION_TYPES,
  analyticsMetadata: AnalyticsMetadata | undefined,
  metadata: Metadata | undefined,
  customProperties: Metadata | undefined,
  environment: string | undefined,
  shouldSendJSEvents?: boolean,
) => {
  const properties = {
    dashboard_type: dashboardType,
    embed_source: embedSource,
    environment,
    ...analyticsMetadata,
    ...metadata,
  };

  sendAnalyticsEvent(
    eventName,
    userId,
    analyticsToken,
    properties,
    customProperties,
    shouldSendJSEvents,
  );
};

export const isValidHttpUrl = (s: string) => {
  let url;

  try {
    url = new URL(s);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const sendAnalyticsEventHelper = (
  url: string,
  postData: PostData,
  analytics_token: string | null | undefined,
) => {
  axios({
    url: `${process.env.REACT_APP_API_URL}analytics/${url}/`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Analytics-Token': analytics_token,
    },
    data: postData,
  }).catch(() => {
    // swallow error
    return;
  });
};

export const isAnalyticsEventSelected = (actions: ReportedAnalyticsAction[], actionName: string) =>
  new Set(map(actions, 'name')).has(actionName);

const sendAnalyticsJSEventHelper = (detail: PostData) => {
  const isIframe =
    'embed_source' in detail.properties ? detail.properties.embed_source === 'iframe' : false;
  if (isIframe) {
    window.parent.postMessage({ event: JS_EVENT_NAME, detail }, '*');
  } else {
    window.dispatchEvent(new CustomEvent(JS_EVENT_NAME, { detail }));
  }
};

export const getEmbedSource = (embedType: EmbeddedDashboardType | 'chart') =>
  embedType === 'shared' || embedType === 'chart' ? 'share' : embedType;

type AnalyticsProps = {
  pageViewEvent: REPORTED_ANALYTIC_ACTION_TYPES;
  environment: string | undefined;
  embedType: AnalyticsEmbedType;
  isProduction?: DashboardVariable;
  isStrict?: boolean;
  analyticsProperties?: Metadata;
};

export function useSetupAnalytics({
  environment,
  embedType,
  pageViewEvent,
  isProduction,
  isStrict,
  analyticsProperties,
}: AnalyticsProps) {
  const dispatch = useDispatch();

  const { analyticsMetadata, analyticsToken, visitorId, shouldSendJSEvents, reportActions } =
    useSelector(
      (state: AllStates) => ({
        analyticsMetadata: state.analytics.analyticsMetadata,
        analyticsToken: state.analytics.analyticsToken,
        visitorId: state.analytics.visitorId,
        shouldSendJSEvents: state.analytics.shouldSendJSEvents ?? false,
        reportActions: state.analytics.reportActions ?? [],
      }),
      shallowEqual,
    );

  const noAnalyticsNeeded = shouldNotSendAnalytics(embedType);

  // use this to stop unselected JS events from firing since they never hit the backend
  const isPageEventSelected = useMemo(
    () => isAnalyticsEventSelected(reportActions, pageViewEvent),
    [reportActions, pageViewEvent],
  );

  useEffect(() => {
    return () => {
      dispatch(clearAnalyticsSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAnalyticsVars({ embedType, environment, analyticsProperties }));
  }, [dispatch, embedType, environment, analyticsProperties]);

  useEffect(() => {
    if (noAnalyticsNeeded || !analyticsMetadata || !analyticsToken) return;
    const sendPageViewAnalytics = async () => {
      const fingerprintUser = await getFingerprintUser();
      dispatch(setVisitorId(fingerprintUser.visitorId));
      sendAnalyticsEvent(
        pageViewEvent,
        fingerprintUser.visitorId,
        analyticsToken,
        {
          ...analyticsMetadata,
          is_strict: isStrict ? { isStrict: true } : undefined,
          is_production: isProduction ? isProduction === 'true' : undefined,
          environment,
          embed_source: getEmbedSource(embedType),
        },
        analyticsProperties,
        shouldSendJSEvents && isPageEventSelected,
      );
    };

    sendPageViewAnalytics();
  }, [
    dispatch,
    pageViewEvent,
    analyticsMetadata,
    embedType,
    environment,
    analyticsToken,
    noAnalyticsNeeded,
    isProduction,
    isStrict,
    analyticsProperties,
    shouldSendJSEvents,
    isPageEventSelected,
  ]);

  return noAnalyticsNeeded || visitorId !== null;
}

const allowedAnalyticsSet: Set<AnalyticsEmbedType> = new Set([
  'embedded',
  'iframe',
  'shared',
  'chart',
  'portal',
]);

export function shouldNotSendAnalytics(embedType: AnalyticsEmbedType): boolean {
  return !allowedAnalyticsSet.has(embedType);
}
