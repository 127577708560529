import { INTERNAL_EVENT } from 'types/customEventTypes';

export const saveResourceConfig = () => {
  setTimeout(() => {
    window.dispatchEvent(new CustomEvent(INTERNAL_EVENT.SAVE_RESOURCE_CONFIG));
  }, 200);
};

export const saveMapViewState = () => {
  window.dispatchEvent(new CustomEvent(INTERNAL_EVENT.SAVE_MAP_VIEW_STATE));
};
