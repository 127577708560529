import { FC } from 'react';

import { Switch, Input } from 'components/ds';
import { TextInputElemConfig } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: TextInputElemConfig;

  updateInputConfig: (config: TextInputElemConfig) => void;
};

export const TextInputAdjustDisplayConfig: FC<Props> = ({ config, updateInputConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateInputConfig);

  return (
    <>
      <Input
        showInputButton
        className={styles.configInput}
        defaultValue={config.label}
        label={{ text: 'Label', variableInput: true }}
        onSubmit={(newValue) => updateConfig((draft) => (draft.label = newValue))}
      />
      <Input
        showInputButton
        className={styles.configInput}
        defaultValue={config.placeholderText}
        label="Placeholder Text"
        onSubmit={(newValue) => updateConfig((draft) => (draft.placeholderText = newValue))}
      />
      <Switch
        className={styles.configInput}
        label="Show Search Icon"
        onChange={() => updateConfig((draft) => (draft.showSearchIcon = !draft.showSearchIcon))}
        switchOn={config.showSearchIcon}
      />
    </>
  );
};
