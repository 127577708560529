import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { sprinkles } from 'components/ds';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { DatasetItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem';
import {
  clearDuplicateColumns,
  createReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { createComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';
import { useQuery } from 'utils/routerUtils';
import { sortBy } from 'utils/standard';

import { EditorLeftColumn } from '../EditorLeftColumn';

import { DatasetConfig } from './DatasetConfig';
import { useColorCategoryTracker } from './useColorCategoryTracker';

const rootClass = sprinkles({ display: 'flex', flex: 1, overflow: 'hidden', height: 'fill' });

const configMenuClass = sprinkles({
  backgroundColor: 'gray1',
  borderRight: 1,
  borderColor: 'gray7',
  flexItems: 'column',
  height: 'fill',
  overflow: 'hidden',
});

const tableViewClass = sprinkles({
  flex: 1,
  flexItems: 'column',
  overflow: 'hidden',
  backgroundColor: 'gray2',
});

const emptyContainerClass = sprinkles({ flexItems: 'center', height: 'fill' });

type Props = {
  config: ReportBuilderConfig;
  reportBuilderId: number;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

export const DatasetEditor: FC<Props> = ({ config, reportBuilderId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);

  const selectedDataset = selectedDatasetInfo ? config.datasets[selectedDatasetInfo.id] : undefined;
  const { datasetData, globalStyleConfig, shouldUseFido, schemas } = useSelector(
    (state: ReduxState) => ({
      datasetData: selectedDatasetInfo
        ? state.reportBuilderEdit.datasetData[selectedDatasetInfo.id]
        : undefined,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
    }),
    shallowEqual,
  );

  const colorCategoryTracker = useColorCategoryTracker(
    globalStyleConfig,
    selectedDataset?.columnConfigs,
    datasetData?.rows,
  );

  const sortedDatasets = useMemo(
    () => sortBy(Object.values(config.datasets), (dataset) => dataset.name),
    [config.datasets],
  );

  const switchSelectedDataset = useCallback(
    (datasetId: string, isNew?: boolean) => {
      dispatch(fetchAppDataset({ datasetId, switchedToDataset: true }));

      history.replace(`/report-builder/${reportBuilderId}/datasets?id=${datasetId}`);
      setSelectedDatasetInfo({ id: datasetId, isNew: isNew ?? false });
    },
    [dispatch, history, reportBuilderId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || sortedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? sortedDatasets[0];
    switchSelectedDataset(dataset.id);
  }, [query, config.datasets, switchSelectedDataset, selectedDatasetInfo, sortedDatasets]);

  useEffect(() => {
    if (!selectedDatasetInfo || !datasetData?.duplicateColumns) return;
    dispatch(clearDuplicateColumns(selectedDatasetInfo.id));
    showDuplicateColumnNameToast(datasetData.duplicateColumns, true);
  }, [dispatch, datasetData?.duplicateColumns, selectedDatasetInfo]);

  const schema = useMemo(
    () => selectedDataset && getReportBuilderSchema(datasetData?.schema, selectedDataset, true),
    [datasetData?.schema, selectedDataset],
  );

  const handleAddDataset = useCallback(
    (name: string, parentSchemaId: number) => {
      const newId = uuidv4();

      if (shouldUseFido) {
        dispatch(
          createComputedView({
            name,
            namespace: RD.getOrDefault(schemas.usedParentSchemas, []).find(
              (s) => s.id === parentSchemaId,
            ),
            resourceType: ResourcePageType.REPORT_BUILDER,
            datasetId: newId,
            onSuccess: () => switchSelectedDataset(newId, true),
          }),
        );
      } else {
        dispatch(createReportBuilderDataset({ id: newId, name, parentSchemaId }));
        switchSelectedDataset(newId, true);
      }
    },
    [dispatch, schemas.usedParentSchemas, shouldUseFido, switchSelectedDataset],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      return (
        selectedDataset &&
        dispatch(
          fetchAppDataset({
            datasetId: selectedDataset.id,
            page,
          }),
        )
      );
    },
    [dispatch, selectedDataset],
  );

  const noDatasetSelectedDiv = () => {
    return (
      <div className={emptyContainerClass} style={{ fontSize: 20 }}>
        Select a Dataset
      </div>
    );
  };
  return (
    <div className={rootClass}>
      <EditorLeftColumn>
        <div
          className={sprinkles({ width: 'fill', padding: 'sp1', flexItems: 'column', gap: 'sp1' })}>
          <AddDatasetButton datasets={sortedDatasets} onSubmit={handleAddDataset} />
          {sortedDatasets.map((dataset) => {
            const isSelected = dataset.id === selectedDatasetInfo?.id;
            return (
              <DatasetItem
                dataset={dataset}
                isSelected={isSelected}
                key={dataset.id}
                onClick={() => {
                  if (isSelected) return;
                  switchSelectedDataset(dataset.id);
                }}
              />
            );
          })}
        </div>
      </EditorLeftColumn>
      <div className={configMenuClass} style={{ width: 545, minWidth: 545 }}>
        {selectedDataset ? (
          <DatasetConfig dataset={selectedDataset} isNew={selectedDatasetInfo?.isNew ?? false} />
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      <div className={tableViewClass}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />
            <DatasetPreviewTable
              colorTracker={colorCategoryTracker}
              currentQuery={selectedDataset.queryDraft ?? selectedDataset.query}
              dataset={selectedDataset}
              error={datasetData?.error}
              handlePageChange={handlePageChange}
              isLoading={!!datasetData?.loading}
              rowCount={datasetData?.rowCount}
              rows={datasetData?.rows}
              schema={schema}
              unsupportedOperations={datasetData?.unsupportedOperations}
            />
          </>
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
    </div>
  );
};
