import { createSlice } from '@reduxjs/toolkit';

import {
  deleteCustomFontSuccess,
  fetchCustomFontsActions,
  uploadCustomFontSuccess,
} from 'actions/customFontsActions';
import * as RD from 'remotedata';
import { sortBy } from 'utils/standard';

interface CustomFontsReducerState {
  customFonts: RD.ResponseData<string[]>;
}

const initialState: CustomFontsReducerState = { customFonts: RD.Idle() };

const customFontsSlice = createSlice({
  name: 'customFonts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomFontsActions.requestAction, (state) => {
        state.customFonts = RD.Loading();
      })
      .addCase(fetchCustomFontsActions.errorAction, (state) => {
        state.customFonts = RD.Error('Error loading custom fonts');
      })
      .addCase(fetchCustomFontsActions.successAction, (state, { payload }) => {
        state.customFonts = RD.Success(sortFonts(payload.fonts));
      })
      .addCase(uploadCustomFontSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.customFonts)) return;

        state.customFonts.data = sortFonts([...state.customFonts.data, payload.postData.fileName]);
      })
      .addCase(deleteCustomFontSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.customFonts)) return;

        const fileName = payload.postData.fileName;
        state.customFonts.data = state.customFonts.data.filter((font) => font !== fileName);
      });
  },
});

const sortFonts = (fonts: string[]): string[] => sortBy(fonts, (font) => font.toLowerCase());

export const customFontsReducer = customFontsSlice.reducer;
