import { DateTime } from 'luxon';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { DatePickerInput } from 'components/DatePickerInput';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { getConfigDateLimits } from 'pages/dashboardPage/dashboardElement/datePickerUtil';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { DatepickerElemConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';

type Props = {
  config: DatepickerElemConfig;
  value: string | DateTime | undefined;
  onNewValueSelect: (newValue: DateTime | undefined) => void;
  disabled?: boolean;
  openElementToLeft?: boolean;
  timezone: string;
  isInContainer?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  datasetNamesToId: Record<string, string>;
};

export const DashboardDatepickerElement: FC<Props> = ({
  config,
  value,
  onNewValueSelect,
  disabled,
  openElementToLeft,
  isInContainer,
  timezone,
  maxDate,
  minDate,
  datasetNamesToId,
}) => {
  const dispatch = useDispatch();
  const context = useContext(DashboardLayoutContext);
  const portalId = isInContainer ? context.dashboardLayoutTagId : undefined;

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  const { minDate: configMinDate, maxDate: configMaxDate } = getConfigDateLimits(config, timezone);

  return (
    <div>
      <DatePickerInput
        isEmbed
        disabled={disabled}
        label={label}
        labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
        maxDate={maxDate ?? configMaxDate}
        minDate={minDate ?? configMinDate}
        onNewValueSelect={(date) => {
          // this is a date, not a date time, so leaving it in UTC
          onNewValueSelect((date as DateTime).toUTC());
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        openElementToLeft={openElementToLeft}
        portalId={portalId}
        selectedValue={
          typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
        }
        showCancelBtn={!config.disableCancel}
        showTimeSelect={!config.hideTimeSelect}
      />
    </div>
  );
};
