export interface DatasetColumn {
  name: string;
  type: string;
  friendly_name?: string;
}

export type DatasetSchema = DatasetColumn[];

export type DatasetRow = Record<string, string | number>;

export enum DatabaseUnsupportedOperations {
  PAGINATION = 'pagination',
}
