import { createReducer } from '@reduxjs/toolkit';

import {
  fetchEmbedSecretsActions,
  createEmbedSecretSuccess,
  deleteEmbedSecretSuccess,
  EmbedSecret,
} from 'actions/embedSecretActions';
import * as RD from 'remotedata';

interface EmbedSecretReducerState {
  embedSecrets: RD.ResponseData<EmbedSecret[]>;
}

const initialState: EmbedSecretReducerState = {
  embedSecrets: RD.Idle(),
};

export const embedSecretsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchEmbedSecretsActions.requestAction, (state) => {
      state.embedSecrets = RD.Loading();
    })
    .addCase(fetchEmbedSecretsActions.errorAction, (state) => {
      state.embedSecrets = RD.Error('Error Loading Embed Secrets');
    })
    .addCase(fetchEmbedSecretsActions.successAction, (state, { payload }) => {
      state.embedSecrets = RD.Success(payload.embed_secrets);
    })
    .addCase(createEmbedSecretSuccess, (state, { payload }) => {
      RD.update(state.embedSecrets, (secrets) =>
        secrets.push({
          ...payload.embed_secret,
          access_group_id: payload.postData.access_group_id,
        }),
      );
    })
    .addCase(deleteEmbedSecretSuccess, (state, { payload }) => {
      state.embedSecrets = RD.map(state.embedSecrets, (secrets) =>
        secrets.filter((secret) => secret.id !== payload.id),
      );
    })
    .addDefaultCase((state) => state),
);
