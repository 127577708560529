import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';
import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReportGroupBy, CustomerReportVisualization } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { IconName } from 'components/ds/Icon';
import { SCATTER_Y_AXIS_TYPES } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';
import { AddColumnMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddColumnMenu';
import {
  AtLeastOneAlert,
  NoDataAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { GroupByListItem } from 'pages/ReportBuilder/ReportView/DataPanel/GroupByListItem';
import { COLUMN_GROUP_BY_REQUIRED_VISUALIZATION_TYPES } from 'pages/ReportBuilder/constants';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { getVisualizationName } from 'pages/ReportBuilder/utils/visualizationUtils';
import {
  clearPrevColumnGroupBys,
  getCurrentViewRemovedColumnGroupBys,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { PivotAgg } from 'types/dateRangeTypes';
import { DraggableColumnInfo, getGroupByUniqueId } from 'utils/customerReportUtils';

import { DataPanelList } from './DataPanelList';
import { COLS_SECTION_ID, DataPanelData } from './constants';

type Props = {
  bucketsByCol: Record<string, Set<PivotAgg>>;
  columnConfigs?: ReportBuilderColConfigs;
  columnGroupBys: CustomerReportGroupBy[];
  disabled?: boolean;
  columns: DraggableColumnInfo[];
  operationType: CustomerReportVisualization;
  max?: number;
  onAddColumn: (column: DraggableColumnInfo) => void;
};

export const ColumnGroupBySection: FC<Props> = ({
  bucketsByCol,
  columnConfigs,
  columnGroupBys,
  disabled,
  columns,
  operationType,
  max,
  onAddColumn,
}) => {
  const dispatch = useDispatch();
  const { removedColumnGroupBys, sourceType } = useSelector(
    (state: ReportBuilderReduxState) => ({
      removedColumnGroupBys: getCurrentViewRemovedColumnGroupBys(state.reportEditing),
      sourceType: state.reportEditing.reportSourceType,
    }),
    shallowEqual,
  );

  const { over, isOver, setNodeRef, active } = useDroppable(DROPPABLE_ARGS);

  const selectedColumns = useMemo(() => columnGroupBys.map((agg) => agg.column), [columnGroupBys]);

  const sortableIds = useMemo(
    () => columnGroupBys.map((item) => `${COLS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [columnGroupBys],
  );

  const isScatterPlot = operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2;
  const isHeatMap = operationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;

  const allowedColumns = useMemo(
    () => (isScatterPlot ? columns.filter((c) => SCATTER_Y_AXIS_TYPES.includes(c.type)) : columns),
    [columns, isScatterPlot],
  );

  const isOverContainer = isOverSection(COLS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === COLS_SECTION_ID;

  const hasMax = max != null && columnGroupBys.length >= max;
  const visualizationName = getVisualizationName(operationType);
  const numColumnGroupBys = columnGroupBys.length;
  const numRemovedColumnGroupBys = removedColumnGroupBys
    ? removedColumnGroupBys.length - numColumnGroupBys
    : 0;

  const isAllowedColDragging = isScatterPlot
    ? SCATTER_Y_AXIS_TYPES.includes(active?.data.current?.column?.type)
    : true;

  const errorCases = disabled || hasMax || !isAllowedColDragging;
  const { title, icon } = getColumnGroupBysTitleAndIcon(operationType);

  const isPopoverOpen = isOverContainer && !isOverSelf && errorCases;
  const popoverText = useMemo(() => {
    if (hasMax) return `${visualizationName} can have up to ${max} grouping`;
    // Sanity check: if scatter plot or heat map are disabled its because of max, which is already handled
    if (disabled && !isScatterPlot && !isHeatMap) return 'Requires Values and Group Bys';
    if (!isAllowedColDragging)
      return `Only ${SCATTER_Y_AXIS_TYPES.join(', ').toLowerCase()} fields allowed.`;
  }, [disabled, hasMax, isAllowedColDragging, isHeatMap, isScatterPlot, max, visualizationName]);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={COLS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon={icon}
        popoverChildren={isPopoverOpen ? popoverText : undefined}
        title={title}>
        <AddColumnMenu
          columnConfigs={columnConfigs}
          columns={allowedColumns}
          disabled={disabled || hasMax}
          onAddColumn={onAddColumn}
          selectedColumns={selectedColumns}
          tooltipText={disabled || hasMax ? popoverText : undefined}
        />
      </DataPanelSubHeader>

      {numRemovedColumnGroupBys > 0 ? (
        <RemovedDataAlert
          name="grouping"
          numRemoved={numRemovedColumnGroupBys}
          onDismiss={() => dispatch(clearPrevColumnGroupBys())}
          visualizationName={visualizationName}
        />
      ) : null}

      {operationType &&
      COLUMN_GROUP_BY_REQUIRED_VISUALIZATION_TYPES.has(operationType) &&
      columnGroupBys.length === 0 ? (
        <AtLeastOneAlert name="grouping" visualizationName={visualizationName} />
      ) : null}

      {columnGroupBys.length === 0 ? <NoDataAlert name="grouping" /> : null}

      {/* ignore disabled for scatter and heat map since no requirement restrictions on dragging columns out*/}
      {columnGroupBys.map((columnGroupBy) => (
        <GroupByListItem
          bucketsByCol={bucketsByCol}
          columnConfigs={columnConfigs}
          disabled={!isScatterPlot && !isHeatMap && disabled}
          groupBy={columnGroupBy}
          key={getGroupByUniqueId(columnGroupBy)}
          section={COLS_SECTION_ID}
          sourceType={sourceType}
        />
      ))}
    </DataPanelList>
  );
};

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: COLS_SECTION_ID,
  data: { id: COLS_SECTION_ID, section: COLS_SECTION_ID } as DataPanelData,
};

function getColumnGroupBysTitleAndIcon(operationType: CustomerReportVisualization): {
  title: string;
  icon: IconName;
} {
  switch (operationType) {
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
      return { title: 'Y-Axis Grouping', icon: 'up-long' };
    default:
      return { title: 'Breakdown', icon: 'table-columns' };
  }
}
