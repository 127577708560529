import cx from 'classnames';
import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import {
  EmbedButton,
  EmbedModal,
  EmbedModalContent,
  EmbedModalHeader,
  EmbedSpinner,
} from 'components/embed';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext, GlobalStylesProvider } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { PanelError } from 'pages/dashboardPage/DashboardDatasetView/PanelError';
import { ReduxState } from 'reducers/rootReducer';
import { ComponentSection } from 'reducers/styleConfigReducer';

import { Aggregation, OPERATION_TYPES } from '../../../constants/types';
import { SingleNumberChart } from '../../dashboardPage/charts/singleNumberChart';

type Props = {
  styleConfig: GlobalStyleConfig;
};

export const ComponentPreview: FC<Props> = ({ styleConfig }) => (
  <GlobalStylesProvider globalStyleConfig={styleConfig}>
    {(globalStylesClassName) => (
      <ComponentPreviewWrapper globalStylesClassName={globalStylesClassName} />
    )}
  </GlobalStylesProvider>
);

type WrapperProps = {
  globalStylesClassName: string;
};

const ComponentPreviewWrapper: FC<WrapperProps> = ({ globalStylesClassName }) => {
  const { globalStyleVars, globalStyleConfig } = useContext(GlobalStylesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openComponentSection = useSelector(
    (state: ReduxState) => state.styleConfig.openComponentSection,
  );

  useEffect(() => {
    setIsModalOpen(false);
  }, [openComponentSection]);

  const renderComponentSection = (openSection: ComponentSection) => {
    switch (openSection) {
      case ComponentSection.Spinner:
        return (
          <div className={chartClassName}>
            <EmbedSpinner fillContainer />
          </div>
        );
      case ComponentSection.Button:
        return (
          <div className={chartClassName}>
            <EmbedButton variant="primary">Primary Button</EmbedButton>
            <EmbedButton variant="secondary">Secondary Button</EmbedButton>
            <EmbedButton icon="report-builder-number" variant="tertiary" />
          </div>
        );
      case ComponentSection.PanelError:
        return <PanelError description="description" title="error message" />;
      case ComponentSection.SingleNumber:
        return (
          <div className={singleNumberClassName}>
            <SingleNumberChart
              dataPanelTemplateId=""
              generalOptions={undefined}
              globalStyleConfig={globalStyleConfig}
              instructions={{
                aggColumn: {
                  agg: { id: Aggregation.AVG },
                  column: { name: 'price', type: 'number' },
                },
                generalFormat: {
                  subtitle: 'Subtitle text',
                  showTooltip: true,
                  tooltipText: 'Tooltip text',
                },
              }}
              operationType={OPERATION_TYPES.VISUALIZE_NUMBER_V2}
              previewData={[{ price: 123 }]}
              processString={(s) => s}
              schema={[{ name: 'price', type: 'number' }]}
            />
          </div>
        );
      case ComponentSection.Modal:
        return (
          <div className={chartClassName}>
            <EmbedButton onClick={() => setIsModalOpen(true)} variant="primary">
              Open Modal
            </EmbedButton>
            <EmbedModal
              isIframe={false}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              size="small">
              <EmbedModalHeader title="Check Modal Overlay" />
              <EmbedModalContent
                className={sprinkles({ paddingX: 'sp3', paddingBottom: 'sp2' })}
                size="small">
                <EmbedText body="b2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. At urna condimentum mattis
                  pellentesque id nibh. Consequat ac felis donec et odio pellentesque.
                </EmbedText>
              </EmbedModalContent>
            </EmbedModal>
          </div>
        );
    }
  };

  return (
    <div
      className={cx(
        globalStylesClassName,
        rootClass,
        embedSprinkles({ backgroundColor: 'background' }),
      )}
      style={globalStyleVars}>
      {openComponentSection ? renderComponentSection(openComponentSection) : null}
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'centerColumn',
  parentContainer: 'fill',
  gap: 'sp4',
  padding: 'sp8',
  backgroundColor: 'elevationLow',
});

const baseChartClassName = cx(
  sprinkles({ flexItems: 'center' }),
  embedSprinkles({
    backgroundColor: 'containerFill',
    borderRadius: 'container',
  }),
  GLOBAL_STYLE_CLASSNAMES.container.outline.border,
  GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
);

const chartClassName = cx(
  baseChartClassName,
  sprinkles({ parentContainer: 'fill', flex: 1, gap: 'sp1' }),
  embedSprinkles({ padding: 'container' }),
);

const singleNumberClassName = cx(baseChartClassName, embedSprinkles({ padding: 'singleNumber' }));
