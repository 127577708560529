import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Switch, Tabs } from 'components/ds';
import { toggleEditableSection } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';

import { DisabledLayoutPanel } from '../DisabledLayoutPanel';

import { ChartsConfig } from './ChartsConfig';
import { EditableSectionModals } from './Modals';
import { SectionSettings } from './SectionSettings';
import * as styles from './styles.css';

type Props = { dashboardId: number };

enum ConfigTabs {
  CHARTS = 'Charts',
  SETTINGS = 'Settings',
}
const TabNames = Object.values(ConfigTabs);

export const EditableSectionConfig: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const [tabId, setTabId] = useState<string>(ConfigTabs.CHARTS);

  const { config, selectedChartId, isEditingDefaultLayout } = useSelector(
    (state: ReduxState) => ({
      config: state.dashboardEditConfig.config?.editable_section,
      selectedChartId: state.dashboardInteractions.selectedItem?.id,
      isEditingDefaultLayout: state.dashboardInteractions.isEditingEditableSection,
    }),
    shallowEqual,
  );

  // If a chart is selected want to set tab to charts tab unless editing default layout
  const previousSelectedChart = usePrevious(selectedChartId);
  useEffect(() => {
    if (!isEditingDefaultLayout && !previousSelectedChart && selectedChartId)
      setTabId(ConfigTabs.CHARTS);
  }, [selectedChartId, isEditingDefaultLayout, previousSelectedChart]);

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        Customer Configured Section
        <Switch onChange={() => dispatch(toggleEditableSection())} switchOn={config?.enabled} />
      </div>
      <Tabs onTabSelect={setTabId} selectedTabId={tabId} tabs={TabNames} />
      <div
        className={styles.sectionBody}
        onClick={() => selectedChartId && dispatch(clearSelectedItemThunk())}>
        {config?.enabled ? (
          tabId === ConfigTabs.CHARTS ? (
            <ChartsConfig dashboardId={dashboardId} />
          ) : (
            <SectionSettings settings={config.settings} />
          )
        ) : (
          <DisabledLayoutPanel
            buttonText="Turn on Customer Editable Section"
            onEnable={() => dispatch(toggleEditableSection())}
            title="Add a customer editable section to the bottom of your dashboard"
          />
        )}
      </div>
      <EditableSectionModals />
    </div>
  );
};
