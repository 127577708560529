import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { updateElementLocation } from 'actions/dashboardV2Actions';
import * as configStyles from 'components/FilterConfigs/styles.css';
import { SettingHeader } from 'components/SettingHeader';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { Switch, Tabs, sprinkles, Input, Label } from 'components/ds';
import {
  DASHBOARD_ELEMENT_TYPE_TO_NAME,
  ELEM_TYPE_TO_ICON,
  STICKY_ELEMENTS,
} from 'constants/dashboardConstants';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { DisabledLayoutPanel } from 'pages/dashboardPage/EditDashboardLeftPanel/DisabledLayoutPanel';
import {
  toggleStickyHeader,
  updateStickyHeader,
  updateStickyHeaderElementOrder,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { DASHBOARD_LAYOUT_CONFIG } from 'types/dashboardTypes';
import { DashboardStickyHeaderConfig, DashboardHeaderLocation } from 'types/dashboardVersionConfig';
import { partition } from 'utils/standard';

import { DraggableHeaderElemOption } from './DraggableHeaderElemOption';
import { HeaderElemOptionSimple } from './HeaderElemOptionSimple';
import * as styles from './index.css';

enum HeaderTab {
  SETUP = 'Setup',
  STYLES = 'Styles',
}

const TabNames = Object.values(HeaderTab);

type Props = {
  dashboardId: number;
};

export const PageHeaderConfig: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const { elements, config } = useSelector(
    (state: ReduxState) => ({
      elements: state.dashboardEditConfig.config?.elements,
      config: state.dashboardEditConfig.config?.dashboard_page_layout_config?.stickyHeader,
    }),
    shallowEqual,
  );
  const [tabId, setTabId] = useState<string>(HeaderTab.SETUP);

  const updateElemLocation = useCallback(
    (elementId: string, newLocation: DASHBOARD_LAYOUT_CONFIG) =>
      dispatch(updateElementLocation({ elementId, newLocation })),
    [dispatch],
  );

  const [headerElems, bodyElems] = useMemo(() => {
    return partition(
      Object.values(elements ?? {}).filter((elem) => STICKY_ELEMENTS.has(elem.element_type)),
      (elem) => elem.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER,
    );
  }, [elements]);

  const updateConfig = (updates: Partial<DashboardStickyHeaderConfig>) =>
    dispatch(updateStickyHeader({ ...config, ...updates }));

  const isBelowHeader = config?.filterLocations === DashboardHeaderLocation.BELOW_HEADER;

  const sortedSelectedColumns = headerElems.sort((elem1, elem2) =>
    config?.headerContentOrder
      ? config.headerContentOrder.indexOf(elem1.id) - config.headerContentOrder.indexOf(elem2.id)
      : 0,
  );

  const SetupTab = () => (
    <>
      <SettingHeader
        name="Title"
        switchProps={{
          isOn: !config?.headerDisabled,
          onChange: () => updateConfig({ headerDisabled: !config?.headerDisabled }),
        }}
      />
      {config?.headerDisabled ? null : (
        <div className={sprinkles({ padding: 'sp1.5' })}>
          <Input
            defaultValue={config?.headerName || 'Dashboard'}
            onSubmit={(newValue) => updateConfig({ headerName: newValue })}
          />
        </div>
      )}
      <SettingHeader name="Included in Header" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <SortableList
          getIdFromElem={(item) => item.id}
          onListUpdated={(newList) =>
            dispatch(updateStickyHeaderElementOrder(newList.map((elem) => elem.id)))
          }
          sortableItems={sortedSelectedColumns}>
          {sortedSelectedColumns.map((elem) => (
            <SortableListItem key={elem.id} sortId={elem.id}>
              <DraggableHeaderElemOption
                actionIcon="cross"
                elemId={elem.id}
                elemType={elem.element_type}
                key={`filter-header-${elem.id}`}
                name={elem.name}
                onActionClicked={() =>
                  updateElemLocation(elem.id, DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY)
                }
              />
            </SortableListItem>
          ))}
        </SortableList>
      </div>
      <SettingHeader name="Excluded from Header" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        {bodyElems.map((elem) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={elem.id}
            icon={ELEM_TYPE_TO_ICON[elem.element_type]}
            key={`filter-header-${elem.id}`}
            name={elem.name}
            onActionClicked={() => updateElemLocation(elem.id, DASHBOARD_LAYOUT_CONFIG.HEADER)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[elem.element_type]}
          />
        ))}
      </div>
    </>
  );

  const StyleTab = () => (
    <>
      <SettingHeader name="Container" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <Label htmlFor="">Background Color</Label>
        <ColorPicker
          fill
          color={config?.backgroundColor || '#FFFFFF'}
          onClose={(newColor) => updateConfig({ backgroundColor: newColor })}
        />
        <Switch
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Bottom Border"
          onChange={() => updateConfig({ disableBottomBorder: !config?.disableBottomBorder })}
          switchOn={!config?.disableBottomBorder}
        />
        <Switch
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Bottom Shadow"
          onChange={() => updateConfig({ disableBottomShadow: !config?.disableBottomShadow })}
          switchOn={!config?.disableBottomShadow}
        />
      </div>
      <SettingHeader name="Header Text" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <Label htmlFor="">Color</Label>
        <ColorPicker
          fill
          color={config?.headerTextColor || '#182026'}
          onClose={(newColor) => updateConfig({ headerTextColor: newColor })}
        />
        <Input
          className={sprinkles({ marginTop: 'sp1.5' })}
          defaultValue={`${config?.headerTextSize || 24}px`}
          label="Font Size"
          onSubmit={(newTextSizeString) => {
            const newTextSize = Number(newTextSizeString.replace('px', ''));

            if (isNaN(newTextSize) || newTextSize < 1) return;

            updateConfig({ headerTextSize: newTextSize });
          }}
        />
      </div>
      <SettingHeader name="Filters" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <Switch
          className={sprinkles({ marginBottom: 'sp1.5' })}
          label="Use Expandable Filter Row"
          onChange={() =>
            updateConfig({ enabledExpandableFilterRow: !config?.enabledExpandableFilterRow })
          }
          switchOn={config?.enabledExpandableFilterRow}
        />
        <div className={sprinkles({ body: 'b2', marginBottom: 'sp1', color: 'gray12' })}>
          Location
        </div>

        <div className={configStyles.optionRow}>
          <div
            className={cx(configStyles.option, {
              [configStyles.selectedOption]: isBelowHeader,
            })}
            onClick={() => updateConfig({ filterLocations: DashboardHeaderLocation.BELOW_HEADER })}>
            {config?.headerDisabled || config?.enabledExpandableFilterRow
              ? 'Left Aligned'
              : 'Below Header'}
          </div>
          <div
            className={cx(configStyles.option, {
              [configStyles.selectedOption]: !config?.filterLocations || !isBelowHeader,
            })}
            onClick={() =>
              updateConfig({ filterLocations: DashboardHeaderLocation.RIGHT_ALIGNED })
            }>
            Right Aligned
          </div>
        </div>
        <Switch
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Stretch Filters to Available Space"
          onChange={() => updateConfig({ enableStretchFilters: !config?.enableStretchFilters })}
          switchOn={config?.enableStretchFilters}
        />
      </div>
    </>
  );

  const enabledState = () => (
    <>
      <Tabs onTabSelect={setTabId} selectedTabId={tabId} tabs={TabNames} />
      <div className={styles.headerConfigContainer}>
        {tabId === HeaderTab.SETUP ? SetupTab() : StyleTab()}
      </div>
    </>
  );

  return (
    <>
      <SettingHeader
        name="Dashboard Header"
        switchProps={{
          isOn: !!config?.enabled,
          onChange: () => updateConfig({ enabled: !config?.enabled }),
        }}
      />
      {config?.enabled ? (
        enabledState()
      ) : (
        <DisabledLayoutPanel
          buttonText="Turn on Dashboard Header"
          onEnable={() => dispatch(toggleStickyHeader(dashboardId))}
          title="Add a header to the top of your dashboard"
        />
      )}
    </>
  );
};
