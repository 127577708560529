export const validatePassword = (password1: string, password2: string) => {
  if (password1 !== password2) return 'Passwords do not match.';
  else if (password1.length < 8) return 'Password should contain at least 8 characters.';
  else if (!/^(?=(.*[a-z]){1,})/.test(password1))
    return 'Password should contain at least 1 lowercase letter.';
  else if (!/^(?=(.*[A-Z]){1,})/.test(password1))
    return 'Password should contain at least 1 uppercase letter.';
  else if (!/^(?=(.*[0-9]){1,})/.test(password1))
    return 'Password should contain at least 1 number.';
  else if (!/^(?=(.*[!?@#$%^&*()\-__+.]))/.test(password1))
    return 'Password should contain at least 1 special character.';

  return '';
};
