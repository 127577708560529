import { forwardRef, ReactNode } from 'react';

import { dialogFooter } from 'components/ds/Modal/index.css';

type Props = {
  children: ReactNode;
};

export const EmbedModalFooter = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <div className={dialogFooter} ref={ref}>
      {children}
    </div>
  );
});

EmbedModalFooter.displayName = 'EmbedModalFooter';
