import { ParentSchema, DataSource, DataSourcePropertyOptions } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { AccessGroupDataSourcesForSchemaMap } from 'constants/types';
import { DBConnectionConfig } from 'pages/ConnectDataSourceFlow/types';
import { map, some } from 'utils/standard';

export const getAccessGroupDataSourcesForSchema = (
  dataSources: DataSource[],
  parentSchemaId: number,
  accessGroup: AccessGroup,
) => {
  // data sources available to this data visibility group + schema
  const availableDataSources: number[] = [];

  if (accessGroup?.data_source_ids) {
    dataSources.forEach((dataSource) => {
      if (
        parentSchemaId === dataSource.parent_schema_id &&
        accessGroup.data_source_ids.includes(dataSource.id)
      ) {
        availableDataSources.push(dataSource.id);
      }
    });
  }

  const defaultDataSource = availableDataSources.find((dataSourceId) =>
    accessGroup?.default_data_source_ids.includes(dataSourceId),
  );
  return { availableDataSourceIds: availableDataSources, defaultDataSourceId: defaultDataSource };
};

export const getAccessGroupDataSourcesForSchemasMap = (
  dataSources: DataSource[] | undefined,
  parentSchemas: ParentSchema[],
  accessGroups: AccessGroup[] | undefined,
) => {
  if (!dataSources || !accessGroups) return;

  const schemaInfoInit: AccessGroupDataSourcesForSchemaMap = {};

  parentSchemas.forEach((parentSchema) => {
    accessGroups.forEach((accessGroup) => {
      const dataSourceInfo = getAccessGroupDataSourcesForSchema(
        dataSources,
        parentSchema.id,
        accessGroup,
      );

      schemaInfoInit[parentSchema.id] = {
        ...schemaInfoInit[parentSchema.id],
        [accessGroup.id]: dataSourceInfo,
      };
    });
  });
  return schemaInfoInit;
};

export const schemasHaveDuplicateNames = (schemas: ParentSchema[]) => {
  return schemas.length !== new Set(map(schemas, 'name')).size;
};

export const getAccessGroupsToUpdate = (accessGroupMap: Record<number, number[]>) => {
  const updates = Object.entries(accessGroupMap);

  if (updates.length === 0) return null;

  return updates.map(([accessGroupId, newDefaultsIds]) => ({
    access_group_id: Number(accessGroupId),
    default_data_source_ids: newDefaultsIds,
  }));
};

export const hasValidConfiguration = (
  config: DBConnectionConfig,
  properties: DataSourcePropertyOptions,
) => {
  const hasUnmetDependencies = some(Object.entries(properties), ([propertyName, property]) => {
    return (
      property.depends_on !== undefined &&
      !property.optional &&
      config.dataSourceConfig?.[property.depends_on] &&
      !Object.keys(config.dataSourceConfig).includes(propertyName)
    );
  });
  if (hasUnmetDependencies) return false;

  if (
    config.dataSourceConfig &&
    some(
      Object.entries(config.dataSourceConfig),
      ([key, value]) =>
        ((typeof value === 'string' && value === '') ||
          (typeof value === 'number' && isNaN(value))) &&
        !properties?.[key]?.optional,
    )
  )
    return false;

  return config.name !== '' && config.providedId !== '';
};

export const hasConfigUpdates = (config: DBConnectionConfig) => {
  const hasMetadataUpdates = some(
    Object.entries(config),
    ([key, value]) => key != 'dataSourceConfig' && value !== undefined,
  );

  if (hasMetadataUpdates) return true;

  return (
    config.dataSourceConfig &&
    some(Object.values(config.dataSourceConfig), (value) => value !== undefined)
  );
};
