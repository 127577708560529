import produce, { Draft } from 'immer';

import { FilterValueSourceType } from 'constants/types';
import { DataPanel } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';

export function getUpdateConfigFunc<T>(
  config: T,
  updateElemConfig: (config: T, reRequestRows: boolean) => void,
) {
  return (updateFunc: (config: Draft<T>) => void, reRequestRows = false) => {
    const newConfig = produce(config, (draft) => {
      updateFunc(draft);
    });
    updateElemConfig(newConfig, reRequestRows);
  };
}

export function isFilterUsedInDataPanel(
  dataPanels: Record<string, DataPanel> | undefined,
  elementName: string,
  checkOperationMatch: (operationId: FilterOperator) => boolean,
) {
  if (!dataPanels) return false;

  return !!Object.values(dataPanels).find((dp) => {
    const filterClauses = dp.filter_op?.instructions.filterClauses;
    if (!filterClauses) return false;

    return !!filterClauses.find((clause) => {
      const operationId = clause.filterOperation?.id;

      // Check operations match select type
      if (!operationId || !checkOperationMatch(operationId)) return false;

      return (
        clause.filterValueSource === FilterValueSourceType.VARIABLE &&
        clause.filterValueVariableId === elementName
      );
    });
  });
}
