import cx from 'classnames';
import { FC, forwardRef } from 'react';

import { sprinkles } from 'components/ds';
import { IconName, Icon } from 'components/ds/Icon';
import * as styles from 'components/resource/EmbeddedDropdownMenu/styles.css';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';

type Props = {
  className?: string;
  selectedName?: string;
  placeholder?: string;
  icon?: IconName;
};

export const EmbeddedDropdownButton: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ icon, selectedName, placeholder, className, ...props }, ref) => {
    return (
      <div {...props} className={cx(styles.dropdownButton, className)} ref={ref}>
        {icon ? (
          <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
        ) : undefined}
        <EmbedText
          body="b1"
          className={cx(
            styles.dropdownButtonText,
            selectedName ? undefined : sprinkles({ color: 'contentTertiary' }),
          )}>
          {selectedName ?? placeholder}
        </EmbedText>
        <Icon className={sprinkles({ color: 'contentSecondary' })} name="caret-down" size="sm" />
      </div>
    );
  },
);

EmbeddedDropdownButton.displayName = 'EmbeddedDropdownButton';
