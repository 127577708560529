import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import * as customerReportActions from 'actions/customerReportActions';
import { FetchCustomerReportsTeamData } from 'actions/customerReportActions';
import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import {
  createReportBuilderEmailCadenceSuccess,
  deleteReportBuilderEmailCadenceSuccess,
} from 'actions/reportBuilderEmailCadenceActions';
import { EmbedReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { saveGlobalStylesSuccess } from 'actions/styleConfigActions';
import { logInUserSuccess } from 'actions/userActions';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { GlobalFontConfig, GlobalStyleConfig } from 'globalStyles/types';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { DRAFT_REPORT_ID } from 'pages/ReportBuilder/constants';
import * as navUtils from 'pages/ReportBuilder/utils/navigationUtils';
import * as RD from 'remotedata';
import {
  createCustomerReport,
  favoriteBuiltIn,
  saveCustomerReport,
  favoriteCustomerReport,
} from 'reportBuilderContent/thunks/reportThunks';
import { DashboardVariableMap } from 'types/dashboardTypes';

import { clearSelectedReport, createDraftCustomerReport } from './reportEditingReducer';

type RequestInfo = {
  customerToken: string | undefined;
  embedId: string | undefined;
  embedJwt: string | undefined;
  versionNumber: number;
  envTagId: number | null;
};

export interface EmbeddedReportBuilderReducerState {
  reportBuilder: RD.ResponseData<EmbedReportBuilder>;
  reportBuilderVersion: EmbedReportBuilderVersion | null;
  reports: customerReportActions.CustomerReport[];
  fontConfig: RD.ResponseData<GlobalFontConfig>;
  team: FetchCustomerReportsTeamData | null;
  customerName: string | null;
  requestInfo: RequestInfo;
  selectedTab: ReportBuilderTab;
  favoriteBuiltIns: string[]; // List of Built-In Report Ids
  variables: DashboardVariableMap;
  timezone: string | null;
  styleConfig: GlobalStyleConfig;
  theme: string | null;
  additionalStyleConfigOptions: Record<string, GlobalStyleConfig | undefined>;
  emailCounts: Record<string, number>;

  // For editing built ins and previewing reports
  isInApp: boolean;
  isAiEnabled: boolean;
  isPreview: boolean;

  // Unique portal for each instance of report builder
  portalId: string;
}

const getInitialState = (): EmbeddedReportBuilderReducerState => ({
  reportBuilder: RD.Idle(),
  reportBuilderVersion: null,
  reports: [],
  fontConfig: RD.Idle(),
  selectedTab: ReportBuilderTab.ALL_REPORTS,
  team: null,
  customerName: null,
  timezone: null,
  styleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
  theme: null,
  additionalStyleConfigOptions: {},
  emailCounts: {},
  requestInfo: {
    customerToken: undefined,
    embedId: undefined,
    embedJwt: undefined,
    versionNumber: -1,
    envTagId: null,
  },
  favoriteBuiltIns: [],
  variables: {},
  isInApp: false,
  isAiEnabled: false,
  isPreview: false,
  portalId: uuidv4(),
});

const embeddedReportBuilderSlice = createSlice({
  name: 'embeddedReportBuilder',
  initialState: getInitialState(),
  reducers: {
    clearEmbeddedReportBuilderReducer: (state) => {
      // styleConfig and fontConfig shouldn't be reset since it's only reloaded when the page loads (user logs in)
      return { ...getInitialState(), styleConfig: state.styleConfig, fontConfig: state.fontConfig };
    },
    setVersionForInAppPreview: (state, { payload }: PayloadAction<EmbedReportBuilderVersion>) => {
      state.reportBuilderVersion = payload;
      state.isInApp = true;
    },
    setIsPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.isPreview = payload;
    },
    setSelectedTab: (state, { payload }: PayloadAction<{ tab: ReportBuilderTab }>) => {
      state.selectedTab = payload.tab;
      if (!state.isInApp) navUtils.goToHomePageTab(payload.tab);
    },
    setVariables: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.variables = payload;
    },
    setTimezone: (state, { payload }: PayloadAction<string>) => {
      state.timezone = payload;
    },
    setTheme: (state, { payload }: PayloadAction<string>) => {
      state.theme = payload;
    },
    setAiEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isAiEnabled = payload;
    },
    updateSelectedReportName: (
      state,
      { payload }: PayloadAction<{ report_id: number; name: string }>,
    ) => {
      const report = state.reports.find(({ id }) => id === payload.report_id);
      if (report) report.name = payload.name;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerReportActions.listCustomerReportsRequest, (state) => {
        state.reportBuilder = RD.Loading();
      })
      .addCase(customerReportActions.listCustomerReportsSuccess, (state, { payload }) => {
        const { report_builder } = payload;
        state.reportBuilder = RD.Success(report_builder);
        state.reportBuilderVersion = payload.report_builder_version;
        state.reports = payload.reports;
        state.team = payload.team;
        state.customerName = payload.customer.name;
        state.isAiEnabled = payload.team.entitlements.report_builder_ai_enabled;
        state.favoriteBuiltIns = payload.metadata.favorite_built_ins;
        state.requestInfo = {
          customerToken: payload.customerToken,
          embedId: payload.report_builder.embed_id ?? payload.postData.resource_embed_id,
          embedJwt: payload.jwt,
          versionNumber: payload.report_builder_version.version_number,
          envTagId: payload.environment_tag_id,
        };
        if (payload.style_config) state.styleConfig = payload.style_config;
        if (payload.additional_style_configs)
          state.additionalStyleConfigOptions = payload.additional_style_configs;
        if (payload.font_config) state.fontConfig = RD.Success(payload.font_config);
        state.emailCounts = payload.email_counts;
      })
      .addCase(customerReportActions.listCustomerReportsError, (state, { payload }) => {
        state.reportBuilder = RD.Error(
          payload.errorData?.error_msg || 'Error Loading Report Builder',
        );
      })
      .addCase(createCustomerReport.fulfilled, (state, { payload }) => {
        state.reports.push(payload.report);
      })
      .addCase(createDraftCustomerReport, (state, { payload }) => {
        state.reports.push({
          name: '',
          config: payload,
          is_starred: false,
          modified: '',
          id: DRAFT_REPORT_ID,
        });
      })
      .addCase(favoriteCustomerReport.fulfilled, (state, { payload }) => {
        state.reports.forEach((report) => {
          if (report.id === payload.report_id) report.is_starred = !report.is_starred;
        });
      })
      .addCase(createReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        let reportId;
        if ('customer_report_id' in payload.postData) {
          reportId = payload.postData.customer_report_id.toString();
        }
        if ('built_in_id' in payload.postData) {
          reportId = payload.postData.built_in_id;
        }
        if (reportId !== undefined) {
          if (state.emailCounts[reportId] === undefined) state.emailCounts[reportId] = 1;
          else state.emailCounts[reportId] = state.emailCounts[reportId] + 1;
        }
      })
      .addCase(deleteReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        const reportId = payload.postData.report_id.toString();
        if (reportId !== undefined && state.emailCounts[reportId] !== undefined) {
          if (state.emailCounts[reportId] > 1) {
            state.emailCounts[reportId] = state.emailCounts[reportId] - 1;
          } else {
            delete state.emailCounts[reportId];
          }
        }
      })
      .addCase(customerReportActions.deleteCustomerReportSuccess, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report_id);
      })
      .addCase(saveCustomerReport.fulfilled, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report.id);
        state.reports.push(payload.report);
      })
      .addCase(customerReportActions.updateCustomerReportNameSuccess, (state, { payload }) => {
        const report = state.reports.find(({ id }) => id === payload.report_id);
        if (report) report.name = payload.name;
      })
      .addCase(clearSelectedReport, (state) => {
        // filter out unsaved reports
        state.reports = state.reports.filter((r) => r.id !== DRAFT_REPORT_ID);
        if (!state.isInApp) navUtils.goToHomePageTab(state.selectedTab);
      })
      .addCase(favoriteBuiltIn.fulfilled, (state, { payload }) => {
        state.favoriteBuiltIns = payload.favorite_built_ins;
      })
      .addCase(logInUserSuccess, (state, { payload }) => {
        if (!payload.team) return;
        state.styleConfig = getBaseGlobalStyles(payload.team.style_config_v2);
        state.fontConfig = RD.Success(payload.team.font_config || []);
        state.additionalStyleConfigOptions = payload.team.additional_style_configs ?? {};
      })
      .addCase(saveGlobalStylesSuccess, (state, { payload }) => {
        state.styleConfig = payload.config_v2;
      });
  },
});

export const getCurrentTheme = createSelector(
  (state: EmbeddedReportBuilderReducerState) => state.additionalStyleConfigOptions,
  (state: EmbeddedReportBuilderReducerState) => state.styleConfig,
  (state: EmbeddedReportBuilderReducerState) => state.theme,
  (additionalStyleConfigOptions, globalStyleConfig, theme) =>
    (theme ? additionalStyleConfigOptions[theme] : undefined) ?? globalStyleConfig,
);

export const getOrderedBuiltIns = createSelector(
  (state: EmbeddedReportBuilderReducerState) => state.reportBuilderVersion?.config.builtInReports,
  (state: EmbeddedReportBuilderReducerState) => state.reportBuilderVersion?.config.builtInOrder,
  (builtInReports, builtInOrder) => {
    if (!builtInOrder || !builtInReports) return Object.values(builtInReports || {});
    return builtInOrder.map((id) => builtInReports[id]).filter((report) => !!report);
  },
);

export const {
  setSelectedTab,
  setVersionForInAppPreview,
  clearEmbeddedReportBuilderReducer,
  setVariables,
  setTheme,
  setAiEnabled,
  setIsPreview,
  setTimezone,
  updateSelectedReportName,
} = embeddedReportBuilderSlice.actions;

export const embeddedReportBuilderReducer = embeddedReportBuilderSlice.reducer;
