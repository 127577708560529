import { Icon, Tooltip, Position, Classes } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateSelectedChart } from 'actions/dataPanelConfigActions';
import { OP_TYPE_TO_BP3_ICON, VIZ_TO_SECTION, VIZ_TO_NAME } from 'constants/dataConstants';
import { OPERATION_TYPES, CHART_VISUALIZATION_OPERATIONS } from 'constants/types';
import { groupBy } from 'utils/standard';

const visualizationsBySection = groupBy(CHART_VISUALIZATION_OPERATIONS, (op) => VIZ_TO_SECTION[op]);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    padding: theme.spacing(4),
    paddingBottom: 0,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  },
  sectionContainer: {
    paddingBottom: theme.spacing(4),
  },
  sectionName: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    rowGap: '12px',
    columnGap: '12px',
  },
  chartOption: {
    backgroundColor: theme.palette.ds.grey200,
    border: `1px solid ${theme.palette.ds.grey200}`, // so that the hover doesn't take space
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    '&.selected': {
      boxShadow: '0 4px 6px rgb(0 0 0 / 12%)',
      border: '1px solid #a3e0ff',
    },

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 4px 6px rgb(0 0 0 / 12%)',
      border: '1px solid #a3e0ff',
    },

    '&.disabled': {
      cursor: 'default',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey200}`, // so that the hover doesn't take space
      filter: 'grayscale(100%)',
      opacity: '50%',
    },
  },
  chartTooltip: {
    width: '100%',
  },
}));

type Props = {
  dataPanelId: string;
  selectedChartType: OPERATION_TYPES;
};

export const ChartSelectPopover: FC<Props> = ({ dataPanelId, selectedChartType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      {Object.keys(visualizationsBySection).map((section) => (
        <div className={classes.sectionContainer} key={`select-chart-type-section-${section}`}>
          <div className={classes.sectionName}>{section}</div>
          <div className={classes.optionsContainer}>
            {visualizationsBySection[section].map((chartType) => {
              // TODO: Remove this when we are ready to release the density map
              if (chartType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP) return;
              return (
                <Tooltip
                  content={VIZ_TO_NAME[chartType]}
                  key={`select-chart-type-option-${chartType}`}
                  openOnTargetFocus={false}
                  position={Position.BOTTOM}
                  targetClassName={classes.chartTooltip}>
                  <div
                    className={cx(classes.chartOption, Classes.POPOVER_DISMISS, {
                      selected: chartType === selectedChartType,
                    })}
                    onClick={() =>
                      dispatch(
                        updateSelectedChart({
                          id: dataPanelId,
                          from: selectedChartType,
                          to: chartType,
                        }),
                      )
                    }>
                    <Icon icon={OP_TYPE_TO_BP3_ICON[chartType]} iconSize={24} />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
