import { FC, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import * as styles from 'pages/ReportBuilder/ReportView/ReportChart/ReportSingleNumberChart.css';
import { customerReportToSingleNumberChartInstructions } from 'pages/ReportBuilder/utils/visualizationUtils';
import { SingleNumberChart } from 'pages/dashboardPage/charts/singleNumberChart';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { DatasetColumn } from 'types/datasets';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';

type Props = {
  aggregations?: CustomerReportAgg[];
  currentTableData: ReportData;
  schema: DatasetColumn[];
  columnConfigs?: ReportBuilderColConfigs;
};

export const ReportSingleNumberChart: FC<Props> = ({
  aggregations,
  currentTableData: { isLoading, rows },
  schema,
  columnConfigs,
}) => {
  const { variables, globalStyleConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () => customerReportToSingleNumberChartInstructions(aggregations, columnConfigs),
    [aggregations, columnConfigs],
  );

  const missingText = useMemo(
    () => (!aggregations?.length ? 'Values' : undefined),
    [aggregations?.length],
  );

  if (missingText)
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_NUMBER_V2}>
        Missing {missingText}
      </InvalidData>
    );

  return (
    <SingleNumberChart
      dataPanelTemplateId=""
      generalOptions={{}}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      kpiTitleClassName={styles.kpiTitle}
      kpiValueClassName={styles.kpiValue}
      loading={isLoading}
      operationType={OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2}
      previewData={rows ?? []}
      processString={(s) => replaceVariablesInString(s, variables)}
      schema={schema}
    />
  );
};
