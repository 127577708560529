import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { EmbedInfoIcon } from '../EmbedInfoIcon';

export type Props = {
  helpText?: string;
  className?: string;
  noBottomMargin?: boolean;
};

export const EmbedFilterLabel: FC<Props> = ({ className, children, helpText, noBottomMargin }) => {
  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'alignCenter', marginBottom: noBottomMargin ? undefined : 'sp.5' }),
        embedSprinkles({ otherText: 'filterLabel' }),
        className,
      )}
      style={{ height: 16 }}>
      <span className={sprinkles({ truncateText: 'ellipsis' })}>{children}</span>
      {helpText ? <EmbedInfoIcon noMargin={!children} text={helpText} /> : null}
    </div>
  );
};
