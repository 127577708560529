import { createAsyncThunk } from '@reduxjs/toolkit';

import { JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { makeEnqueueJobsThunkRequest } from 'utils/thunkUtils';

import { ReportBuilderReduxState } from '../reducers/rootReducer';

/**
 * Use anytime you want to enqueue a job to the job queue in Report Builder
 * Allows us to easily track jobs with the ReportBuilderPoller
 */
export const enqueueReportBuilderJobsThunk = createAsyncThunk<
  Record<string, Jobs>,
  Record<string, JobDefinition>,
  { state: ReportBuilderReduxState }
>(ACTION.ENQUEUE_REPORT_BUILDER_JOBS, async (jobs, { dispatch, getState }) => {
  const {
    requestInfo: { customerToken, embedJwt },
  } = getState().embeddedReportBuilder;
  return makeEnqueueJobsThunkRequest(jobs, dispatch, customerToken, embedJwt);
});
