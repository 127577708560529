import { FC } from 'react';

import { RawEmbedSecret } from 'actions/embedSecretActions';
import { AlertModal } from 'components/ds';
import InputLabel from 'shared/InputLabel';
import { showSuccessToast } from 'shared/sharedToasts';

import * as styles from './settingsEmbedSecretsSection.css';

type Props = {
  embedSecret: RawEmbedSecret | undefined;
  closeModal: () => void;
};

export const SettingsCreatedEmbedSecretModal: FC<Props> = ({ embedSecret, closeModal }: Props) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    showSuccessToast('Copied To Clipboard');
  };

  if (!embedSecret) return null;

  const renderModalBody = () => {
    return (
      <div className={styles.modalContentContainer}>
        Make sure to copy both values now as you will not be able to view the secret again.
        <div className={styles.row} key={embedSecret.id}>
          <div className={styles.container}>
            <InputLabel className={styles.modalRow} text="Secret Key ID" />
            <div className={styles.textContainer} onClick={() => copyToClipboard(embedSecret.id)}>
              <div className={styles.valueText}>{embedSecret.id}</div>
              <div className={styles.copy}>Copy</div>
            </div>
            <InputLabel className={styles.modalRow} text="Secret Key Value" />
            <div
              className={styles.textContainer}
              onClick={() => copyToClipboard(embedSecret.value)}>
              <div className={styles.valueText}>{embedSecret.value}</div>
              <div className={styles.copy}>Copy</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AlertModal
      actionButtonProps={{
        text: 'OK',
        onClick: closeModal,
        variant: 'primary',
      }}
      isOpen={!!embedSecret}
      onClose={closeModal}
      title="Embed secret created">
      {renderModalBody()}
    </AlertModal>
  );
};
