import { FC } from 'react';

import { sprinkles, Icon } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';

export const Callout: FC = ({ children }) => (
  <div className={container}>
    <Icon color="contentTertiary" name="lightbulb-on" size="sm" />
    <EmbedText body="b2">{children}</EmbedText>
  </div>
);

const container = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp2',
  padding: 'sp2',
  borderRadius: 8,
  backgroundColor: 'elevationMid',
});
