import { VersionInfo } from 'types/exploResource';

import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { ReportBuilderConfig } from './reportBuilderConfigActions';
import { ACTION } from './types';

export interface ReportBuilderVersion extends VersionInfo {
  id: number;
  report_builder_id: number;
  config: ReportBuilderConfig;
  created: string;
  modified: string;
  version_saved_at: string | null;
  published_by_id: number | null;
}

export type EmbedReportBuilderVersion = Pick<ReportBuilderVersion, 'config' | 'version_number'>;

type FetchReportBuilderVersionsResponse = {
  versions: ReportBuilderVersion[];
};

export const {
  actionFn: fetchReportBuilderVersions,
  requestAction: fetchReportBuilderVersionsRequest,
  successAction: fetchReportBuilderVersionsSuccess,
  errorAction: fetchReportBuilderVersionsError,
} = defineAPIAction<FetchReportBuilderVersionsResponse>(
  ACTION.FETCH_REPORT_BUILDER_VERSIONS,
  'report_builder',
  'get_versions',
  'GET',
);

type PublishNewReportBuilderVersionBody = {
  config: ReportBuilderConfig;
  version_number: number;
  change_comments: string;
};

type PublishNewReportBuilderVersionResponse = {
  version: ReportBuilderVersion;
};

export const {
  actionFn: publishNewReportBuilderVersion,
  successAction: publishNewReportBuilderVersionSuccess,
} = defineAPIPostAction<PublishNewReportBuilderVersionBody, PublishNewReportBuilderVersionResponse>(
  ACTION.PUBLISH_NEW_REPORT_BUILDER_VERSION,
  'report_builder',
  'publish_new_version',
  'POST',
);

export const {
  actionFn: deleteCurrentReportBuilderDraft,
  successAction: deleteCurrentReportBuilderDraftSuccess,
} = defineAPIPostAction<{ version_number: number }, { version_number: number }>(
  ACTION.DELETE_CURRENT_REPORT_BUILDER_DRAFT,
  'report_builder',
  'delete_draft',
  'POST',
);
