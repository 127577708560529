import { createSlice } from '@reduxjs/toolkit';

import {
  createDashboardEmailSuccess,
  deleteDashboardEmailSuccess,
  editDashboardEmailSuccess,
  fetchEmailCadenceListActions,
} from 'actions/emailActions';
import { ExploreEmailCadence } from 'actions/teamActions';
import * as RD from 'remotedata';
import { sortBy } from 'utils/standard';

interface EmailReducerState {
  cadences: RD.ResponseData<ExploreEmailCadence[]>;
}

const emailReducerInitialState: EmailReducerState = { cadences: RD.Idle() };

const emailCadenceSlice = createSlice({
  name: 'emailCadences',
  initialState: emailReducerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailCadenceListActions.requestAction, (state) => {
        state.cadences = RD.Loading();
      })
      .addCase(fetchEmailCadenceListActions.errorAction, (state) => {
        state.cadences = RD.Error('Error loading email cadences');
      })
      .addCase(fetchEmailCadenceListActions.successAction, (state, { payload }) => {
        state.cadences = RD.Success(sortBy(payload.email_cadence_list, 'id'));
      })
      .addCase(createDashboardEmailSuccess, (state, { payload }) => {
        RD.update(state.cadences, (cadences) => cadences.push(payload.cadence));
      })
      .addCase(deleteDashboardEmailSuccess, (state, { payload }) => {
        state.cadences = RD.map(state.cadences, (cadences) =>
          cadences.filter(
            (emailCadence) => emailCadence.dashboard_template_id !== payload.dashboard_template_id,
          ),
        );
      })
      .addCase(editDashboardEmailSuccess, (state, { payload }) => {
        RD.update(state.cadences, (cadences) => {
          const cadence = cadences.find(
            (emailCadence) =>
              emailCadence.dashboard_template_id === payload.cadence.dashboard_template_id,
          );
          if (!cadence) return;

          cadence.hour = payload.cadence.hour;
          cadence.minute = payload.cadence.minute;
          cadence.subject = payload.cadence.subject;
          cadence.from_email = payload.cadence.from_email;
          cadence.day_of_week = payload.cadence.day_of_week;
          cadence.week_of_month = payload.cadence.week_of_month;
          cadence.timezone = payload.cadence.timezone;
          cadence.custom_url = payload.cadence.custom_url;
          cadence.disable_link = payload.cadence.disable_link;
        });
      });
  },
});

export const emailCadenceReducer = emailCadenceSlice.reducer;
