import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import produce from 'immer';
import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { sprinkles } from 'components/ds';
import { EmbedCheckbox, EmbedInput } from 'components/embed';
import { DraggableReportBuilderRow, ItemTypes } from 'constants/dragAndDrop';
import { UserTransformedSchema } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { shiftItemsInList } from 'utils/general';

import { StepFooter } from '../StepFooter';
import { StepHeader } from '../StepHeader';

import * as styles from './styles.css';

type Props = {
  isPivot?: boolean;
  onBack: () => void;
  onNext: () => void;
  setUserTransformedSchema: (schema: UserTransformedSchema) => void;
  userTransformedSchema: UserTransformedSchema;
};

export default function SelectContentStep({
  isPivot,
  onBack,
  onNext,
  userTransformedSchema,
  setUserTransformedSchema,
}: Props) {
  const selectedColumns = userTransformedSchema.filter((col) => col.isVisible).length;

  const renderHeaderCol = (text: string) => {
    <div className={sprinkles({ flex: 1, marginY: 'sp1', marginLeft: 'sp4.5' })}>{text}</div>;
  };

  return (
    <div
      className={cx(
        sprinkles({ parentContainer: 'fill', flexItems: 'column' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <StepHeader text="Select content" />
      <div className={headerContainerClass}>
        {renderHeaderCol('Column Selected')}
        {renderHeaderCol('Column Available')}
      </div>
      <div className={sprinkles({ flex: 1, display: 'flex' })} style={{ minHeight: 0 }}>
        <div className={columnsContainerClass}>
          {userTransformedSchema.map((col, index) => {
            if (isPivot && index === 0) {
              return (
                <TransformationRow
                  isPivot
                  index={index}
                  isDraggable={false}
                  key={JSON.stringify(col)}
                  schema={userTransformedSchema}
                  updateSchema={setUserTransformedSchema}
                />
              );
            }
            if (!col.isVisible) return null;
            return (
              <TransformationRow
                isDraggable
                index={index}
                key={JSON.stringify(col)}
                schema={userTransformedSchema}
                updateSchema={setUserTransformedSchema}
              />
            );
          })}
        </div>
        <div className={columnsContainerClass}>
          {userTransformedSchema.map((col, index) => {
            if (isPivot && index === 0) return null;
            if (col.isVisible) return null;
            return (
              <TransformationRow
                index={index}
                isDraggable={false}
                key={JSON.stringify(col)}
                schema={userTransformedSchema}
                updateSchema={setUserTransformedSchema}
              />
            );
          })}
        </div>
      </div>
      <StepFooter
        disabled={isPivot ? !(selectedColumns > 1) : !(selectedColumns > 0)}
        onBack={onBack}
        onNext={onNext}
        text="Next"
      />
    </div>
  );
}

const headerContainerClass = sprinkles({
  backgroundColor: 'gray1',
  borderColor: 'gray2',
  borderTop: 1,
  borderBottom: 1,
  display: 'flex',
});

const columnsContainerClass = sprinkles({
  flex: 1,
  marginX: 'sp2',
  marginTop: 'sp1',
  overflowY: 'scroll',
});

type TransformationRowProps = {
  index: number;
  isDraggable: boolean;
  isPivot?: boolean;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

function TransformationRow({
  index,
  isDraggable,
  isPivot,
  schema,
  updateSchema,
}: TransformationRowProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.REPORT_BUILDER_ROW, data: { index } },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (!isPivot) return;
    const newSchema = produce(schema, (draft) => {
      draft[0].isVisible = true;
    });
    updateSchema(newSchema);
  });

  const [, drop] = useDrop({
    accept: ItemTypes.REPORT_BUILDER_ROW,
    drop: (item: DraggableReportBuilderRow) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.data.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const newSchema = produce(schema, (draft) => {
        shiftItemsInList(draft, dragIndex, hoverIndex);
      });

      updateSchema(newSchema);
      item.data.index = hoverIndex;
    },
  });

  const column = schema[index];
  const opacity = isDragging ? 0 : 1;

  if (isDraggable) drag(drop(ref));

  return (
    <div
      className={sprinkles({
        cursor: isDraggable ? 'move' : undefined,
        paddingLeft: isDraggable ? undefined : 'sp2',
      })}
      ref={ref}
      style={{ opacity }}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', paddingY: 'sp.5' })}>
        {isDraggable && (
          <Icon
            className={embedSprinkles({ color: 'secondaryFont' })}
            icon="drag-handle-vertical"
          />
        )}
        <EmbedCheckbox
          disabled={isPivot}
          isChecked={column.isVisible}
          onChange={() => {
            const newSchema = produce(schema, (draft) => {
              draft[index].isVisible = !column.isVisible;
            });
            updateSchema(newSchema);
          }}
        />
        <EmbedInput
          className={styles.transformationRowInput}
          defaultValue={column.friendly_name}
          onSubmit={(newColumnName) => {
            const newSchema = produce(schema, (draft) => {
              draft[index].friendly_name = newColumnName;
            });

            updateSchema(newSchema);
          }}
        />
      </div>
    </div>
  );
}
