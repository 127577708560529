import { FC, useState, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { DeleteConfirmationButton } from 'components/DeleteConfirmationButton';
import { sprinkles, Button, Tag, Input, IconButton, InfoIcon } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorToast } from 'shared/sharedToasts';
import { keyBy, partition } from 'utils/standard';

import * as styles from '../styles.css';
import { getStringifiedValue } from '../utils';

import type { CustomerContentProps } from '.';

type Props = Pick<CustomerContentProps, 'editorGroup' | 'setEditorGroup'>;

type DisplayInfo = {
  isDisabled: boolean;
  showLabel: boolean;
  isKey: boolean;
};

export const PropertiesSection: FC<Props> = ({ editorGroup, setEditorGroup }) => {
  const { teamData, selectedParent } = useSelector(
    (state: ReduxState) => ({
      teamData: state.teamData.data,
      selectedParent: state.customers.selectedParent,
    }),
    shallowEqual,
  );

  const [showInheritedProperties, setShowInheritedProperties] = useState(false);
  const [showArchetypeProperties, setShowArchetypeProperties] = useState(false);

  const [archetypePropertiesByName, archetypePropertyNames] = useMemo(() => {
    const propMap = keyBy(teamData?.archetype_properties, 'name');
    return [propMap, new Set(Object.keys(propMap))];
  }, [teamData?.archetype_properties]);

  const [archetypeProps, customProperties] = useMemo(() => {
    const [archProps, customProps] = partition(Object.values(editorGroup.properties), ({ key }) =>
      archetypePropertyNames.has(key),
    );
    const filteredArchetypeProps = archProps.filter(
      ({ key }) => !archetypePropertiesByName[key]?.deprecated,
    );
    return [filteredArchetypeProps, customProps];
  }, [editorGroup, archetypePropertiesByName, archetypePropertyNames]);

  const [archetypeInheritedProps, customInheritedProperties] = useMemo(() => {
    const [archInheritedProps, customInheritedProps] = partition(
      Object.entries(selectedParent?.computed_properties ?? []),
      ([key]) => archetypePropertyNames.has(key),
    );

    const filteredArchInheritedProps = archInheritedProps.filter(
      ([key]) => !archetypePropertiesByName[key]?.deprecated,
    );
    return [filteredArchInheritedProps, customInheritedProps];
  }, [selectedParent, archetypePropertyNames, archetypePropertiesByName]);

  const renderPropertyInput = (
    id: string | undefined,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    originalVal: any,
    displayInfo: DisplayInfo,
  ) => {
    let displayVal;
    try {
      displayVal = typeof originalVal === 'object' ? JSON.stringify(originalVal) : originalVal;
    } catch {
      displayVal = originalVal;
    }

    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2', width: 'fill' })}>
        {displayInfo.showLabel ? (
          <div className={sprinkles({ heading: 'h4', gap: 'sp.5', flexItems: 'alignCenter' })}>
            {displayInfo.isKey ? 'Keys' : 'Values'}
            <InfoIcon
              text={
                displayInfo.isKey
                  ? 'These are keys in a JSON object. It will be interpreted as a string.'
                  : 'These are values in a JSON object. They must be valid JSON.'
              }
              tooltipSide="right"
            />
          </div>
        ) : null}
        <Input
          fillWidth
          defaultValue={displayVal}
          disabled={displayInfo.isDisabled}
          onSubmit={
            id
              ? (newVal) => {
                  if (displayInfo.isKey && archetypePropertyNames.has(newVal)) {
                    showErrorToast('Cannot have key with same name as archetype variable.');
                    return;
                  }
                  setEditorGroup((draft) => {
                    draft.properties[id][displayInfo.isKey ? 'key' : 'value'] = newVal;
                  });
                }
              : () => null
          }
          placeholder={displayInfo.isKey ? 'example_key' : '"example_value"'}
        />
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
      <div className={sprinkles({ heading: 'h3', flexItems: 'column', gap: 'sp1' })}>
        Properties
        <div className={sprinkles({ color: 'contentSecondary', body: 'b3' })}>
          Properties can be used as variables in queries. Learn more about customer-specific
          variables{' '}
          <a href="https://docs.explo.co/creating-dashboards/variables/types-of-variables/customer-object#custom-properties">
            here
          </a>
          .
        </div>
      </div>

      <div className={sprinkles({ gap: 'sp2', flexItems: 'column' })}>
        {customProperties.length === 0 ? (
          <div className={styles.emptyItemsText} style={{ justifyContent: 'flex-start' }}>
            No properties configured for this customer.
          </div>
        ) : null}
        {customProperties.map(({ id, key, value }, index) => {
          return (
            <div className={styles.propertyRow} key={`custom-${id}key-${key}-value-${value}`}>
              {renderPropertyInput(id, key, {
                isDisabled: false,
                isKey: true,
                showLabel: index === 0,
              })}
              {renderPropertyInput(id, value, {
                isDisabled: false,
                isKey: false,
                showLabel: index === 0,
              })}
              <DeleteConfirmationButton
                onDelete={() => {
                  setEditorGroup((draft) => {
                    const oldProps = draft.properties;
                    if (id in oldProps) delete oldProps[id];
                  });
                }}
              />
            </div>
          );
        })}
        <Button
          onClick={() => {
            setEditorGroup((draft) => {
              const newId = `prop-${uuidv4()}`;
              draft.properties[newId] = { key: '', value: '', id: newId };
            });
          }}
          variant="secondary">
          Add New Property
        </Button>
      </div>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
        {customInheritedProperties.length > 0 ? (
          <>
            <div className={sprinkles({ gap: 'sp.5', heading: 'h4', flexItems: 'alignCenter' })}>
              <IconButton
                name={showInheritedProperties ? 'caret-down' : 'caret-right'}
                onClick={() => setShowInheritedProperties((prev) => !prev)}
              />
              Show Inherited Properties
              <InfoIcon
                text="These are properties that are being inherited from the parent customer(s). They cannot be edited."
                tooltipSide="right"
              />
            </div>
            {showInheritedProperties ? (
              <div className={styles.collapsiblePropertySection}>
                {customInheritedProperties.map(([key, value], index) => {
                  return (
                    <div className={styles.propertyRow} key={`inherited-key-${key}-value-${value}`}>
                      {renderPropertyInput(undefined, key, {
                        isDisabled: true,
                        isKey: true,
                        showLabel: index === 0,
                      })}
                      {renderPropertyInput(undefined, getStringifiedValue(value), {
                        isDisabled: true,
                        isKey: false,
                        showLabel: index === 0,
                      })}
                      <Tag className={styles.inheritedTag}>Inherited</Tag>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </>
        ) : null}
        {archetypeProps.length + archetypeInheritedProps.length > 0 ? (
          <>
            <div className={sprinkles({ gap: 'sp.5', heading: 'h4', flexItems: 'alignCenter' })}>
              <IconButton
                name={showArchetypeProperties ? 'caret-down' : 'caret-right'}
                onClick={() => setShowArchetypeProperties((prev) => !prev)}
              />
              Show Archetype Variables
              <InfoIcon
                text={
                  <div>
                    Archetype variables can be used in queries. They are not directly editable.
                    Learn more about archetype variables{' '}
                    <a
                      className={sprinkles({ color: 'blue5' })}
                      href="https://docs.explo.co/creating-dashboards/variables/types-of-variables/customer-object#archetype-variables">
                      here
                    </a>
                    .
                  </div>
                }
                tooltipSide="right"
              />
            </div>
            {showArchetypeProperties ? (
              <div className={styles.collapsiblePropertySection}>
                {archetypeProps.map(({ key, value }, index) => {
                  return (
                    <div className={styles.propertyRow} key={`archetype-key-${key}-value-${value}`}>
                      {renderPropertyInput(undefined, key, {
                        isDisabled: true,
                        isKey: true,
                        showLabel: index === 0,
                      })}
                      {renderPropertyInput(undefined, value, {
                        isDisabled: true,
                        isKey: false,
                        showLabel: index === 0,
                      })}
                      <Tag backgroundColor="white" className={styles.inheritedTag}></Tag>
                    </div>
                  );
                })}
                {archetypeInheritedProps.map(([key, value]) => {
                  return (
                    <div
                      className={styles.propertyRow}
                      key={`inherited-archetype-key-${key}-value-${value}`}>
                      {renderPropertyInput(undefined, key, {
                        isDisabled: true,
                        isKey: true,
                        showLabel: false,
                      })}
                      {renderPropertyInput(undefined, getStringifiedValue(value), {
                        isDisabled: true,
                        isKey: false,
                        showLabel: false,
                      })}
                      <Tag className={styles.inheritedTag}>Inherited</Tag>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
