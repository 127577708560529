import { createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { DashboardStates } from 'reducers/rootReducer';
import { makeThunkRequest } from 'utils/thunkUtils';

type FetchShareIdBodyShared = {
  is_strict_viewing_mode: boolean;
  password?: string;
  username?: string;
};

type EmbedFetchShareIdBody = FetchShareIdBodyShared & {
  dashboard_embed_id: string;
  environment: string | undefined;
  version_number: number;
};

type FetchShareIdBody = FetchShareIdBodyShared & {
  dashboard_template_id: number;
  user_group_id: number;
};

export const fetchShareIdThunk = createAsyncThunk<
  {
    share_id: string;
    share_link_url: string | null;
  },
  { password?: string; username?: string; isStrictViewingMode?: boolean },
  { state: DashboardStates }
>(
  ACTION.FETCH_SHARE_ID,
  async ({ password, username, isStrictViewingMode }, { getState }) => {
    const requestInfo = getState().dashboardLayout.requestInfo;

    const sharedArgs: FetchShareIdBodyShared = {
      is_strict_viewing_mode: isStrictViewingMode === true,
      ...(password && { password }),
      ...(username && { username }),
    };

    let customerToken, jwt, url;
    let args: FetchShareIdBody | EmbedFetchShareIdBody;
    if (requestInfo.type === 'embedded') {
      url = 'embed/get_share_id/';
      args = {
        ...sharedArgs,
        dashboard_embed_id: requestInfo.resourceEmbedId,
        environment: requestInfo.environment,
        version_number: requestInfo.versionNumber,
      };
      customerToken = requestInfo.customerToken;
      jwt = requestInfo.jwt;
    } else {
      url = 'dashboards/get_share_id/';
      args = {
        ...sharedArgs,
        dashboard_template_id: requestInfo.resourceId,
        // Condition below checks this
        user_group_id: requestInfo.customerId as number,
      };
    }

    const requestConfig = createApiRequestConfig(url, 'POST', args, customerToken, jwt);

    return makeThunkRequest(requestConfig, 'Error fetching share link');
  },
  {
    condition: (_, { getState }) => {
      const requestInfo = getState().dashboardLayout.requestInfo;
      return requestInfo.type !== 'app' || !!requestInfo.customerId;
    },
  },
);
