import cx from 'classnames';
import { FC } from 'react';

import { Intent, Tag, sprinkles } from 'components/ds';
import { BETA_DB_SET } from 'pages/ConnectDataSourceFlow/constants';

import * as styles from './index.css';

export type Props = {
  // image to show in the panel
  imgUrl: string;
  // text to show underneath the image
  dataSourceName: string;
  // if true, shows the button in a selected state
  selected: boolean;
  // if provided, button won't be selectable and will be styled as disabled
  disabled: boolean;
  // handler for when the button is clicked
  onClick?: () => void;
};

export const DataSourceButton: FC<Props> = ({
  imgUrl,
  dataSourceName,
  selected,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={cx(styles.root, { selected, disabled })}
      data-testid={`panel-${dataSourceName}`}
      onClick={() => !disabled && onClick?.()}>
      {imgUrl ? (
        <div className={styles.imageContainer}>
          <img
            alt={`${dataSourceName} logo`}
            className={sprinkles({ width: 'fill' })}
            src={imgUrl}
          />
        </div>
      ) : null}
      <div className={sprinkles({ color: 'gray10', fontSize: 14 })}>{dataSourceName}</div>
      {BETA_DB_SET.has(dataSourceName) ? (
        <div className={sprinkles({ position: 'absolute', top: 'sp.5', right: 'sp.5' })}>
          <Tag intent={Intent.ACTIVE} inverted={selected}>
            Beta
          </Tag>
        </div>
      ) : null}
    </div>
  );
};
