import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEditableSectionUsage } from 'actions/dashboardActions';
import { AlertModal, sprinkles } from 'components/ds';
import { setEditableSectionModal } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { deleteEditableSectionChartThunk } from 'reducers/thunks/editableSectionThunks';
import * as RD from 'remotedata';
import { createListString } from 'utils/general';

type Props = { chartId: string; chartName: string };

export const DeleteChartModal: FC<Props> = ({ chartId, chartName }) => {
  const dispatch = useDispatch();

  const dashboardId = useSelector(
    (state: ReduxState) => RD.getOrDefault(state.dashboard.currentDashboard, undefined)?.id,
  );

  const [usageInfo, setUsageInfo] = useState<
    RD.ResponseData<{ count: number; customerNames: string[] }>
  >(RD.Loading());

  useEffect(() => {
    if (!dashboardId) return;
    dispatch(
      getEditableSectionUsage(
        { id: dashboardId, queryParams: { data_panel_id: chartId } },
        ({ count, customer_names }) =>
          setUsageInfo(RD.Success({ count, customerNames: customer_names })),
        () => setUsageInfo(RD.Error('Failed to fetch usage')),
      ),
    );
  }, [dispatch, chartId, dashboardId]);

  const getBody = () => {
    if (RD.isError(usageInfo) || !dashboardId) return 'Failed to fetch usage count';
    if (!RD.isSuccess(usageInfo)) return 'Loading chart usage...';
    const { count, customerNames } = usageInfo.data;

    if (count === 0) return 'This chart is not used in any customer editable sections.';
    const text =
      // Just an arbitrary number of customers to show, if more then too long and better to show count
      count < 11 ? (
        `This chart is used by ${createListString(customerNames)} in their editable section${
          count > 1 ? 's' : ''
        }`
      ) : (
        <>
          This chart is used by <b>{count}</b> customers in their editable sections
        </>
      );

    return (
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {text}
        <br />
        <br />
        Once the deletion is published, these customers will see an empty panel saying &quot;This
        chart is no longer available&quot;. Are you sure you want to delete this chart?
      </div>
    );
  };

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled: !RD.isSuccess(usageInfo),
        onClick: () => dispatch(deleteEditableSectionChartThunk(chartId)),
      }}
      onClose={() => dispatch(setEditableSectionModal(null))}
      title={`Are you sure you want to delete ${chartName}?`}>
      {getBody()}
    </AlertModal>
  );
};
