import { defineAPIAction } from 'actions/actionUtils';
import { EndUser } from 'actions/teamActions';

import { ACTION } from './types';

type FetchEndUserListData = {
  end_users: EndUser[];
};

export const { actionFn: fetchEndUserList, ...fetchEndUserListActions } =
  defineAPIAction<FetchEndUserListData>(ACTION.FETCH_END_USER_LIST, 'end_users', '', 'GET');
