import cx from 'classnames';
import { FC, useMemo } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import * as styles from './FilterDropdown.css';

type Props = {
  items: { id: string; name: string }[];
  selectedItem?: { id: string; name: string };
  placeholder?: string;
  triggerClassName?: string;

  onSelect: (id: string) => void;
};

export const FilterDropdown: FC<Props> = ({
  items,
  selectedItem,
  placeholder,
  triggerClassName,
  onSelect,
}) => {
  const menuOptions = useMemo(() => items.map((i) => ({ value: i.id, name: i.name })), [items]);

  return (
    <EmbeddedDropdownMenu
      fillContainer
      className={styles.filterMenuRoot}
      menuOptions={menuOptions}
      onClick={onSelect}>
      <div className={cx(triggerClassName, styles.dropdownButton)}>
        <span
          className={cx(
            styles.selectedName,
            selectedItem ? undefined : sprinkles({ color: 'contentTertiary' }),
          )}>
          {selectedItem?.name ?? placeholder}
        </span>
        <Icon name="caret-down" size="sm" />
      </div>
    </EmbeddedDropdownMenu>
  );
};
