import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MoveResources } from 'components/MoveResources';
import { AlertModal, APP_PORTAL_ID, Menu, MenuActionItem, MenuSeparator } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { deleteFolder, renameFolder } from 'reducers/thunks/resourceThunks';
import { ResourcePageType, Folder } from 'types/exploResource';
import { getResourceNameErrorMessage } from 'utils/exploResourceUtils';

import * as styles from './styles.css';

type FolderModal = 'rename' | 'delete' | 'move';

type Props = {
  trigger: JSX.Element;
  pageType: ResourcePageType;
  folder: Folder;

  setLoadingStateForFolder?: (isLoading: boolean) => void;
};

export const FolderConfigurationMenu: FC<Props> = ({
  folder,
  pageType,
  setLoadingStateForFolder,
  trigger,
}) => {
  const dispatch = useDispatch();

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;
  const uniqueNames = useSelector((state: ReduxState) => getUniqueNames(state, isExploreProduct));

  const [openModal, setOpenModal] = useState<FolderModal>();

  const onDeleteForAllUsers = () => {
    setLoadingStateForFolder?.(true);
    setOpenModal(undefined);
    dispatch(deleteFolder(folder.id));
  };

  const deleteFolderDisabled = folder.num_resources > 0;
  return (
    <>
      <Menu
        align="end"
        className={styles.resourceConfigMenu}
        portalContainerId={APP_PORTAL_ID}
        trigger={trigger}>
        <MenuActionItem iconName="edit" onSelect={() => setOpenModal('rename')} text="Rename" />
        <MenuActionItem iconName="arrow-right" onSelect={() => setOpenModal('move')} text="Move" />
        <>
          <MenuSeparator />
          <MenuActionItem
            isDestructive
            disabled={deleteFolderDisabled}
            iconName="trash"
            onSelect={() => setOpenModal('delete')}
            text="Delete"
            tooltipProps={
              deleteFolderDisabled
                ? {
                    text: 'Cannot delete non-empty folders',
                    portalContainerId: APP_PORTAL_ID,
                    side: 'bottom',
                  }
                : undefined
            }
          />
        </>
      </Menu>
      {openModal === 'move' ? (
        <MoveResources
          modalOpen
          closeModal={() => setOpenModal(undefined)}
          isExploreProduct={isExploreProduct}
          portalContainerId={APP_PORTAL_ID}
          resource={folder}
        />
      ) : undefined}
      <TextFieldModal
        buttonName="Save"
        closeModal={() => setOpenModal(undefined)}
        getErrorMessage={(input) => getResourceNameErrorMessage(input, uniqueNames, folder.name)}
        modalOpen={openModal === 'rename'}
        modalTitle={`Rename ${isExploreProduct ? 'Dashboard' : 'Report Builder'}`}
        onSubmit={(name) => {
          setLoadingStateForFolder?.(true);
          setOpenModal(undefined);
          dispatch(
            renameFolder({
              id: folder.id,
              name,
              onSuccess: () => setLoadingStateForFolder?.(false),
            }),
          );
        }}
        portalContainerId={APP_PORTAL_ID}
        resourceName={folder.name}
        textFieldPlaceholder="Dashboard name"
      />
      <AlertModal
        actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteForAllUsers }}
        isOpen={openModal === 'delete'}
        onClose={() => setOpenModal(undefined)}
        portalContainerId={APP_PORTAL_ID}
        title={`Are you sure you want to delete this ${
          isExploreProduct ? 'dashboard' : 'report builder'
        }?`}
      />
    </>
  );
};
