import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Export } from 'pages/ReportBuilder/Export';
import { useQuery } from 'utils/routerUtils';
import { getValueOrDefault } from 'utils/variableUtils';

type MatchParams = {
  reportBuilderEmbedId: string;
  customerToken: string;
};

export const ExportReportBuilderPage: FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const query = useQuery();

  const reportBuilderId = params.reportBuilderEmbedId;
  const customerToken = params.customerToken;
  const environment = query.get('environment') || undefined;
  const theme = query.get('theme') || undefined;
  const embedJwt = query.get('embedJwt') || undefined;
  const datasetId = query.get('dataset_id') || undefined;
  const builtInId = query.get('built_in_id') || undefined;
  const customerReportId = query.get('customer_report_id') || undefined;
  const title = query.get('title') || undefined;
  const timezone = getValueOrDefault('timezone');
  const variables = useJsonQueryParam(query, 'variables');
  const viewOverrides = useJsonQueryParam(query, 'view_overrides');

  return (
    <Export
      builtInId={builtInId}
      customerReportId={customerReportId}
      customerToken={customerToken}
      datasetId={datasetId}
      embedJwt={embedJwt}
      embedType="pdf"
      environment={environment}
      reportBuilderEmbedId={reportBuilderId}
      theme={theme}
      timezone={timezone}
      title={title}
      variables={variables}
      viewOverrides={viewOverrides}
    />
  );
};

const useJsonQueryParam = (query: URLSearchParams, param: string) => {
  const queryParam = query.get(param);
  const value = useMemo(() => {
    try {
      return queryParam ? JSON.parse(queryParam) : undefined;
    } catch {
      return;
    }
  }, [queryParam]);
  return value;
};
