import { Layout } from 'react-grid-layout';

import { Customer, EmbedCustomer } from 'actions/teamActions';
import { DASHBOARD_ROW_HEIGHT } from 'constants/dashboardConstants';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';
import { VIEW_MODE } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { maxBy, pick, isEqual, sortBy } from 'utils/standard';

import { isDataPanelConfigReady } from './dataPanelConfigUtils';

// React grid layout adds more things as defaults that don't need to be saved
export type SimpleLayout = Pick<Layout, 'i' | 'x' | 'y' | 'w' | 'h' | 'minH'>[];

const defaultHeight = 4;

export const getEmptySectionHeight = (margin: number, rowHeight = DASHBOARD_ROW_HEIGHT): number =>
  rowHeight * defaultHeight + margin * (defaultHeight - 1);

// Finds first open space to insert it, if no space puts it at bottom
export const addChartToLayout = (
  id: string,
  opType: OPERATION_TYPES,
  layout: SimpleLayout,
  numColumns: number,
): void => {
  const takenGridPoints = getTakenGridPoints(layout);
  const maxElem = maxBy(layout, ({ y, h }) => y + h);
  const maxY = maxElem ? maxElem.h + maxElem.y : 0;

  const [width, minH] =
    opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
      ? [Math.ceil(numColumns / 4), 2]
      : [Math.ceil(numColumns / 2), 3];

  let x = undefined;
  let y = undefined;
  for (let yCord = 0; yCord < maxY; yCord++) {
    for (let xCord = 0; xCord <= numColumns - width; xCord++) {
      if (takenGridPoints.has(`${xCord},${yCord}`)) continue;
      if (allChartIsValid(xCord, yCord, width, takenGridPoints)) {
        x = xCord;
        y = yCord;
        break;
      }
    }
    if (x !== undefined && y !== undefined) break;
  }
  if (x === undefined) x = 0;
  if (y === undefined) y = maxY;

  layout.push({ i: id, x, y, h: defaultHeight, w: width, minH });
};

const allChartIsValid = (
  x: number,
  y: number,
  width: number,
  takenGridPoints: Set<string>,
): boolean => {
  for (let xCord = x; xCord < x + width; xCord++) {
    for (let yCord = y; yCord < y + defaultHeight; yCord++) {
      if (takenGridPoints.has(`${xCord},${yCord}`)) return false;
    }
  }

  return true;
};

const getTakenGridPoints = (layout: Layout[]) => {
  const gridSet = new Set<string>();
  layout.forEach(({ x, y, w, h }) => {
    for (let xCord = x; xCord < x + w; xCord++) {
      for (let yCord = y; yCord < y + h; yCord++) {
        gridSet.add(`${xCord},${yCord}`);
      }
    }
  });
  return gridSet;
};

export const didLayoutChange = (oldLayout: Layout[], newLayout: Layout[]): boolean => {
  const cleanedOld = cleanLayout(oldLayout, true);
  const cleanedNew = cleanLayout(newLayout, true);
  return !isEqual(cleanedOld, cleanedNew);
};

export const cleanLayout = (layout: Layout[], sort = false): SimpleLayout => {
  const cleanedLayout = layout.map((elem) => pick(elem, ['i', 'x', 'y', 'w', 'h', 'minH']));
  return sort ? sortBy(cleanedLayout, (elem) => elem.x + elem.y * 1000) : cleanedLayout;
};

export const shouldRenderEditableSection = (
  layout: Layout[] | undefined,
  viewMode: VIEW_MODE,
): boolean => {
  // These are the only two states where you can edit.
  // So if in any other view and layout is empty no need to render section
  return !!layout?.length || canEditEditableSection(viewMode);
};

export const canEditEditableSection = (viewMode: VIEW_MODE): boolean =>
  viewMode === VIEW_MODE.DEFAULT || viewMode === VIEW_MODE.SHARE;

export const filterChartsForCustomer = (
  charts: Record<string, EditableSectionChart> | undefined,
  customer: Customer | EmbedCustomer | undefined,
): EditableSectionChart[] => {
  // Customer should always be set but need to check
  if (!charts || !customer) return [];

  return sortBy(
    Object.values(charts).filter((chart) => isChartAvailableToCustomer(chart, customer)),
    (chart) => chart.name.toLowerCase(),
  );
};

export const isChartAvailableToCustomer = (
  { permissions, data_panel: dp }: EditableSectionChart,
  customer: Customer | EmbedCustomer,
): boolean => {
  // If not ready can't be added
  if (!isDataPanelConfigReady(dp.visualize_op)) return false;

  // If is embedded then check is done on BE
  if (!('group_tags' in customer)) return true;

  if (permissions.allCustomers) return true;

  // If customer is included it can be added
  if (permissions.customerIds.includes(customer.id)) return true;

  // If no group tag ids don't bother checking
  if (!permissions.groupTagIds?.length) return false;
  return !!customer.group_tags.find((tag) => {
    const tagIdx = permissions.groupTagIds?.indexOf(tag.group_tag_id);
    return tagIdx !== undefined && tagIdx !== -1;
  });
};

// Chart ID must have been created with createEditableSectionItemId
export const isChartInstanceOfTemplate = (chartId: string, template: EditableSectionChart) =>
  isChartInstanceOfDataPanel(chartId, template.data_panel);

// Chart ID must have been created with createEditableSectionItemId
export const isChartInstanceOfDataPanel = (chartId: string, dataPanel: DataPanelTemplate) =>
  chartId.startsWith(dataPanel.id);

/**
 * Returns whether the editable section chart id is generated from the given template id.
 */
export const isChartIdGeneratedFromTemplateId = (chartId: string, templateId: string) =>
  chartId.startsWith(templateId);

export const editableSectionChartToEventChart = (chart: EditableSectionChart) => ({
  id: chart.data_panel.id,
  name: chart.name,
  type: VIZ_TO_NAME[chart.data_panel.visualize_op.operation_type],
});
