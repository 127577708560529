import { FC, useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';

import { sprinkles, Input, Button, Icon } from 'components/ds';
import { ItemTypes } from 'constants/dragAndDrop';
import { showWarningToast } from 'shared/sharedToasts';
import { TimePeriodDropdownElemConfig } from 'types/dashboardTypes';

import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: TimePeriodDropdownElemConfig;

  updateDropdownConfig: (config: TimePeriodDropdownElemConfig) => void;
};

export const TimePeriodValuesConfig: FC<Props> = ({ config, updateDropdownConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateDropdownConfig);

  return (
    <>
      <div
        className={sprinkles({
          display: 'flex',
          marginBottom: 'sp1.5',
          body: 'b3',
          color: 'gray12',
        })}
        style={{ paddingLeft: 20, paddingRight: 34 }}>
        <div className={sprinkles({ flex: 1, marginRight: 'sp.5' })}>Minutes</div>
        <div className={sprinkles({ flex: 2 })}>Name</div>
      </div>
      {config.values.map((option, idx) => {
        return (
          <DraggableRow
            config={config}
            idx={idx}
            key={String(option.value)}
            option={option}
            updateConfig={updateConfig}
          />
        );
      })}
      <Button
        fillWidth
        icon="plus"
        onClick={() => {
          const currentValues = new Set(config.values.map((option) => option.value));
          // Find the next hour value not already being used
          let newValue = 120;
          while (currentValues.has(newValue)) {
            newValue += 60;
          }

          updateConfig((draft) =>
            draft.values.push({ value: newValue, name: `Last ${Math.floor(newValue / 60)} Hours` }),
          );
        }}
        variant="secondary">
        Add Option
      </Button>
    </>
  );
};

type RowProps = {
  config: TimePeriodDropdownElemConfig;
  idx: number;
  option: { value: number; name: string };

  updateConfig: (updateFunc: (config: TimePeriodDropdownElemConfig) => void) => void;
};

const DraggableRow: FC<RowProps> = ({ option, idx, updateConfig, config }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.TIME_PERIOD_DROPDOWN_OPTION, data: { index: idx } },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TIME_PERIOD_DROPDOWN_OPTION,
    hover: (
      item: { data: { index: number }; type: ItemTypes.TIME_PERIOD_DROPDOWN_OPTION },
      monitor,
    ) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.data.index;
      const hoverIndex = idx;

      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      updateConfig((draft) => {
        const prevLoc = draft.values[hoverIndex];
        draft.values[hoverIndex] = draft.values[dragIndex];
        draft.values[dragIndex] = prevLoc;
      });
      item.data.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      className={sprinkles({ display: 'flex', alignItems: 'center', marginBottom: 'sp1.5' })}
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}>
      <Icon className={sprinkles({ cursor: 'grab' })} name="vertical-grip" />
      <Input
        className={sprinkles({ marginX: 'sp.5', flex: 1 })}
        defaultValue={String(option.value)}
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          if (isNaN(intValue) || intValue === option.value) return;

          const valueIdx = config.values.findIndex((opt) => opt.value === intValue);
          if (valueIdx > -1) {
            showWarningToast('Duplicate values are not allowed');
            return;
          }
          updateConfig((draft) => (draft.values[idx].value = intValue));
        }}
      />
      <Input
        className={sprinkles({ flex: 2, marginRight: 'sp.5' })}
        defaultValue={option.name}
        onSubmit={(newValue) => updateConfig((draft) => (draft.values[idx].name = newValue))}
      />
      <Button
        icon="trash"
        onClick={() => updateConfig((draft) => draft.values.splice(idx, 1))}
        variant="destructive"
      />
    </div>
  );
};
