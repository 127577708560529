import produce from 'immer';
import { FC } from 'react';

import { SettingHeader } from 'components/SettingHeader';
import { Input, sprinkles } from 'components/ds';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { DashboardElementConfig, EventButtonElemConfig } from 'types/dashboardTypes';

type Props = {
  config: EventButtonElemConfig;

  updateConfig: (newConfig: DashboardElementConfig) => void;
};

export const EventButtonConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  const updateButtonConfig = (update: (config: EventButtonElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="General" />
      <div className={sprinkles({ marginY: 'sp1.5', gap: 'sp1', flexItems: 'column' })}>
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={config.buttonText ? config.buttonText : 'JS Event'}
          label={{ text: 'Button Text', variableInput: true }}
          onSubmit={(newValue) => updateButtonConfig((draft) => (draft.buttonText = newValue))}
        />
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={config.jsEvent}
          label={{
            text: 'JS Event Name',
            infoText: "This is the event name we'll send to your application.",
          }}
          onSubmit={(newValue) => updateButtonConfig((draft) => (draft.jsEvent = newValue))}
        />
      </div>
    </div>
  );
};
