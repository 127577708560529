import { Layout } from '@explo-tech/react-grid-layout';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CustomerEditableSection, EmbedDashboard } from 'actions/dashboardActions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import { embedFetchDashboardActions } from 'actions/embedActions';
import { EmbedTeam, EmbedCustomer } from 'actions/teamActions';
import { VisualizeTableInstructions } from 'constants/types';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import * as RD from 'remotedata';
import { CustomerDashboardState } from 'types/customerDashboardStateTypes';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { isElementHidden } from 'utils/variableUtils';

import { saveEditableSection } from './thunks/editableSectionThunks';
import { clearEmptyPanels } from './utils';

interface EmbedDashboardReducer {
  dashboard: RD.ResponseData<EmbedDashboard>;
  dashboardVersion: DashboardVersion | null;
  team: EmbedTeam | null;
  customer: EmbedCustomer | null;
  customerEditableSection: CustomerEditableSection | null;
  currentEditableSectionLayout?: Layout[];
  customerDashboardState: CustomerDashboardState | null;

  // Keep track of elements/charts that should be hidden in dashboard
  hiddenElements: string[];
}

const initialState: EmbedDashboardReducer = {
  dashboard: RD.Idle(),
  dashboardVersion: null,
  team: null,
  customer: null,
  customerEditableSection: null,
  hiddenElements: [],
  customerDashboardState: null,
};

function updateConfiguration(
  state: EmbedDashboardReducer,
  updateFunc: (configuration: DashboardVersionConfig) => void,
): void {
  if (!state.dashboardVersion) return;

  updateFunc(state.dashboardVersion.configuration);
}

const embedDashboardSlice = createSlice({
  name: 'embedDashboard',
  initialState,
  reducers: {
    embedUpdateTableColWidths: (state, { payload }: PayloadAction<EmbedUpdateColWidths>) => {
      updateConfiguration(state, (configuration) => {
        const editingDPT = configuration.data_panels[payload.dataPanelTemplateId];

        if (!editingDPT?.visualize_op.instructions) return;
        editingDPT.visualize_op.instructions.VISUALIZE_TABLE = payload.visualizeInstructions;
      });
    },
    updateEmbedEditableSectionLayout: (state, { payload }: PayloadAction<Layout[]>) => {
      state.currentEditableSectionLayout = payload;
    },
    setHiddenElements: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.hiddenElements = [];
      Object.entries(payload).forEach(([varName, value]) => {
        const [elemName, elemExtraVar] = varName.split('.');
        if (elemExtraVar === 'hide' && isElementHidden(value)) state.hiddenElements.push(elemName);
      });
    },
    toggleElementVisibility: (
      state,
      { payload }: PayloadAction<{ varName: string; value: DashboardVariable }>,
    ) => {
      const [elemName, elemExtraVar] = payload.varName.split('.');
      if (elemExtraVar !== 'hide') return;

      if (isElementHidden(payload.value)) {
        state.hiddenElements.push(elemName);
      } else {
        state.hiddenElements = state.hiddenElements.filter((element) => element !== elemName);
      }
    },
    updateCustomerDashboardState: (state, { payload }: PayloadAction<CustomerDashboardState>) => {
      state.customerDashboardState = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(embedFetchDashboardActions.requestAction, () => {
        return { ...initialState, dashboard: RD.Loading() };
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        state.dashboard = RD.Success(payload.dashboard_template);
        state.dashboardVersion = payload.dashboard_version;
        state.team = payload.team;
        state.customer = payload.customer;
        state.customerEditableSection = payload.customer_editable_section;
        state.customerDashboardState = payload.customer_dashboard_state;

        state.currentEditableSectionLayout =
          payload.customer_editable_section?.config.layout ??
          payload.dashboard_version.configuration.editable_section?.default_layout;

        if (state.dashboardVersion) clearEmptyPanels(state.dashboardVersion.configuration);
      })
      .addCase(embedFetchDashboardActions.errorAction, (state, { payload }) => {
        state.dashboard = RD.Error(payload.errorData?.detail ?? 'Error loading dashboard');
      })
      .addCase(updateDrilldownDataPanel, (state, { payload }) => {
        updateConfiguration(state, (config) => {
          if (payload === undefined) {
            if (!(DRILLDOWN_DATA_PANEL_ID in config.data_panels)) return;
            delete config.data_panels[DRILLDOWN_DATA_PANEL_ID];
          } else {
            payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
            config.data_panels[DRILLDOWN_DATA_PANEL_ID] = payload.dataPanel as DataPanelTemplate;
          }
        });
      })
      .addCase(saveEditableSection.fulfilled, (state, { payload }) => {
        state.customerEditableSection = payload.customer_editable_section;
      });
  },
});

export const embedDashboardReducer = embedDashboardSlice.reducer;
export const {
  embedUpdateTableColWidths,
  updateEmbedEditableSectionLayout,
  setHiddenElements,
  toggleElementVisibility,
  updateCustomerDashboardState,
} = embedDashboardSlice.actions;

type EmbedUpdateColWidths = {
  visualizeInstructions: VisualizeTableInstructions;
  dataPanelTemplateId: string;
};
