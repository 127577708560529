import cx from 'classnames';
import { FC, useState, useEffect } from 'react';

import { IconButton, sprinkles } from 'components/ds';
import { EmbedButton, EmbedSpinner } from 'components/embed';

import { IconName } from '../Icon';

import * as styles from './paginator.css';

export type PaginatorProps = {
  error?: boolean;
  totalRowCount: number | undefined;
  currentPage: number;
  goToPage: ({ page, offset }: { page: number; offset: number }) => void;
  rowsPerPage?: number;
  isPagingDisabled?: boolean;
  minimal?: boolean;
  loading: boolean;
  isEmbed?: boolean;
};

export type InternalPaginatorProps = {
  className?: string;
  inputClassName?: string; // TODO: Remove this prop once we have Sprinkles variable theming
  currentRowCount: number;
} & PaginatorProps;

export const DEFAULT_ROWS_PER_PAGE = 50;

export const DataGridPaginator: FC<InternalPaginatorProps> = ({
  className,
  inputClassName,
  loading,
  totalRowCount,
  currentRowCount,
  currentPage,
  goToPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isPagingDisabled,
  minimal,
  error,
  isEmbed,
}) => {
  const [pageString, setPageString] = useState(String(currentPage));
  const rowCount = totalRowCount ?? currentRowCount;
  const maxPage = Math.ceil((rowCount ?? 0) / rowsPerPage);
  const offset = currentPage * rowsPerPage;

  useEffect(() => {
    setPageString(String(currentPage));
  }, [currentPage]);

  const inputPage = Math.max(1, Math.min(maxPage, parseInt(pageString)));

  const resultsString =
    currentRowCount === 0
      ? '0 results'
      : `${rowCount.toLocaleString()} result${rowCount > 1 ? 's' : ''}`;

  const renderPageButton = (iconName: IconName, disabled: boolean, nextPage: number) => {
    const onClick = () => goToPage({ page: nextPage, offset });
    if (!isEmbed) return <IconButton disabled={disabled} name={iconName} onClick={onClick} />;
    return <EmbedButton disabled={disabled} icon={iconName} onClick={onClick} variant="tertiary" />;
  };

  return (
    <div
      className={cx(
        styles.paginatorContainer,
        sprinkles({ paddingX: minimal ? 'sp1' : 'sp3', body: className ? undefined : 'b2' }),
        className,
      )}>
      <div className={styles.paginatorRowCount}>
        {error ? (
          'Error loading total results'
        ) : loading ? (
          <>
            <EmbedSpinner className={sprinkles({ marginRight: 'sp1' })} size="md" />
            Loading
          </>
        ) : (
          resultsString
        )}
      </div>
      {error ? null : !isPagingDisabled ? (
        <div className={styles.pageSelector}>
          {renderPageButton('angle-left', currentPage === 1, currentPage - 1)}
          <span>Page</span>
          {/* TODO: Replace with design system input */}
          <input
            className={cx(styles.input, inputClassName)}
            inputMode="numeric"
            max={maxPage}
            min={1}
            onBlur={() => goToPage({ page: inputPage, offset })}
            onChange={(e) => setPageString(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && goToPage({ page: inputPage, offset })}
            pattern="[0-9]*"
            step={1}
            type="text"
            value={pageString}
          />
          <span>{`of ${maxPage}`}</span>
          {renderPageButton('angle-right', currentPage >= maxPage, currentPage + 1)}
        </div>
      ) : (
        <div>Pagination is not supported for this data.</div>
      )}
    </div>
  );
};
