import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { DashboardButtonGroupInput } from 'components/dashboardButtonGroupInput';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { SelectElemConfig, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover':
      {
        zIndex: 0,
      },
  },
}));

type Props = {
  config: SelectElemConfig;
  value?: DashboardVariable;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  disabled?: boolean;
  loading?: boolean;
  variables: DashboardVariableMap;
  onNewValueSelect: OnNewValueSelectFunc;
};

const DashboardToggleElement = ({
  config,
  disabled,
  datasetData,
  datasetNamesToId,
  value,
  onNewValueSelect,
  loading,
  variables,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const options = constructOptions(config.valuesConfig, datasetData);
  let selectedOption = options.find((option) => option.value === value);

  if (value && !selectedOption) {
    selectedOption = { id: String(value), name: String(value) };
  }

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div className={classes.root}>
      <DashboardButtonGroupInput
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables, datasetNamesToId, datasetData)}
        label={label}
        loading={loading}
        onChange={(item) => {
          onNewValueSelect(item?.value, { display: item?.name });
          dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
        }}
        options={options}
        preventUnselect={config.disableCancel}
        selectedItem={selectedOption}
      />
    </div>
  );
};

export default DashboardToggleElement;
