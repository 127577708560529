export enum ACTION {
  // Dashboard
  FETCH_ALL_DATA_SOURCE_TABLES = 'FETCH_ALL_DATA_SOURCE_TABLES',
  SYNC_SOURCE_TABLES = 'SYNC_SOURCE_TABLES',
  FETCH_DATASET_PREVIEW = 'FETCH_DATASET_PREVIEW',
  FETCH_DASHBOARD = 'FETCH_DASHBOARD',
  FETCH_DASHBOARD_LIST = 'FETCH_DASHBOARD_LIST',
  GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT',
  CREATE_DASHBOARD = 'CREATE_DASHBOARD',
  CLONE_DASHBOARD = 'CLONE_DASHBOARD',
  FETCH_DATA_PANEL_TEMPLATE = 'FETCH_DATA_PANEL_TEMPLATE',
  FETCH_SECONDARY_DATA = 'FETCH_SECONDARY_DATA',
  UPDATE_DASHBOARD_LAYOUT = 'UPDATE_DASHBOARD_LAYOUT',
  UPDATE_DASHBOARD_PDF_LAYOUT = 'UPDATE_DASHBOARD_PDF_LAYOUT',
  UPDATE_DASHBOARD_EMAIL_LAYOUT = 'UPDATE_DASHBOARD_EMAIL_LAYOUT',
  UPDATE_DASHBOARD_MOBILE_LAYOUT = 'UPDATE_DASHBOARD_MOBILE_LAYOUT',
  UPDATE_DASHBOARD_EMAIL_TEXT = 'UPDATE_DASHBOARD_EMAIL_TEXT',
  FETCH_DATASET_ROW_COUNT = 'FETCH_DATASET_ROW_COUNT',
  CREATE_FILTER_CLAUSE = 'CREATE_FILTER_CLAUSE',
  CREATE_SORT_CLAUSE = 'CREATE_SORT_CLAUSE',
  DELETE_SORT_CLAUSE = 'DELETE_SORT_CLAUSE',
  SELECT_SORT_COLUMN = 'SELECT_SORT_COLUMN',
  SELECT_SORT_ORDER = 'SELECT_SORT_ORDER',
  DELETE_FILTER_CLAUSE = 'DELETE_FILTER_CLAUSE',
  SELECT_FILTER_COLUMN = 'SELECT_FILTER_COLUMN',
  FETCH_DATA_PANEL_ROW_COUNT = 'FETCH_DATA_PANEL_ROW_COUNT',
  SELECT_FILTER_OPERATOR = 'SELECT_FILTER_OPERATOR',
  UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE',
  UPDATE_FILTER_VALUE_SOURCE = 'UPDATE_FILTER_VALUE_SOURCE',
  UPDATE_FILTER_VALUE_VARIABLE = 'UPDATE_FILTER_VALUE_VARIABLE',
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  FETCH_CUSTOMER = 'FETCH_CUSTOMER',
  FETCH_CUSTOMER_PARENT = 'FETCH_CUSTOMER_PARENT',
  FETCH_FILTERED_CUSTOMERS = 'FETCH_FILTERED_CUSTOMERS',
  FETCH_SELECTOR_CUSTOMERS = 'FETCH_SELECTOR_CUSTOMERS',
  FETCH_CUSTOMERS_BY_ID = 'FETCH_CUSTOMERS_BY_ID',
  FETCH_CUSTOMER_POTENTIAL_PARENTS = 'FETCH_CUSTOMER_POTENTIAL_PARENTS',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  FETCH_GROUP_TAGS = 'FETCH_GROUP_TAGS',
  FETCH_HIERARCHY_METADATA = 'FETCH_HIERARCHY_METADATA',
  ADD_GROUP_TAG = 'ADD_GROUP_TAG',
  DELETE_GROUP_TAG = 'DELETE_GROUP_TAG',
  RENAME_GROUP_TAG = 'RENAME_GROUP_TAG',
  UPDATE_CUSTOMER_GROUP_TAGS = 'UPDATE_CUSTOMER_GROUP_TAGS',
  GENERATE_EMBED_JWT = 'GENERATE_EMBED_JWT',
  UPDATE_SELECTED_CHART = 'UPDATE_SELECTED_CHART',
  UPDATE_VISUALIZE_OP = 'UPDATE_VISUALIZE_OP',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',
  CREATE_DASHBOARD_ELEMENT = 'CREATE_DASHBOARD_ELEMENT',
  RENAME_DASHBOARD = 'RENAME_DASHBOARD',
  ACTIVATE_DISABLED_DASHBOARD = 'ACTIVATE_DISABLED_DASHBOARD',
  SAVE_GLOBAL_STYLES = 'SAVE_GLOBAL_STYLES',
  SAVE_ADDITIONAL_STYLES = 'SAVE_ADDITIONAL_STYLES',
  DELETE_ADDITIONAL_STYLE = 'DELETE_ADDITIONAL_STYLE',
  GET_FONT_CONFIG = 'GET_FONT_CONFIG',
  SAVE_FONT_CONFIG = 'SAVE_FONT_CONFIG',
  FETCH_GOOGLE_FONTS = 'FETCH_GOOGLE_FONTS',
  EDIT_DATASET_NAME = 'EDIT_DATASET_NAME',
  DELETE_DATASET = 'DELETE_DATASET',
  FETCH_DASHBOARD_DATASET_PREVIEW = 'FETCH_DASHBOARD_DATASET_PREVIEW',
  SAVE_DATASET_QUERY = 'SAVE_DATASET_QUERY',
  SAVE_DRAFT_DATASET_QUERY = 'SAVE_DRAFT_DATASET_QUERY',
  UPDATE_ELEMENT_CONFIG = 'UPDATE_ELEMENT_CONFIG',
  UPDATE_ELEMENT_LOCATION = 'UPDATE_ELEMENT_LOCATION',
  TOGGLE_FILTER_LINK = 'TOGGLE_FILTER_LINK',
  DELETE_DASHBOARD_ELEMENT = 'DELETE_DASHBOARD_ELEMENT',
  SAVE_DASHBOARD_ELEMENT_UPDATES = 'SAVE_DASHBOARD_ELEMENT_UPDATES',
  EMBED_FETCH_DASHBOARD = 'EMBED_FETCH_DASHBOARD',
  EMBED_FETCH_DATA_PANEL_TEMPLATE = 'EMBED_FETCH_DATA_PANEL_TEMPLATE',
  EMBED_FETCH_DATA_PANEL_ROW_COUNT = 'EMBED_FETCH_DATA_PANEL_ROW_COUNT',
  EMBED_FETCH_SECONDARY_DATA = 'EMBED_FETCH_SECONDARY_DATA',
  EMBED_FETCH_SHARE_DATA = 'EMBED_FETCH_SHARE_DATA',
  EMBED_FETCH_DASHBOARD_DATASET_PREVIEW = 'EMBED_FETCH_DASHBOARD_DATASET_PREVIEW',
  GET_RESOURCE_ID_FROM_EMBED_ID = 'GET_RESOURCE_ID_FROM_EMBED_ID',
  FETCH_SHARE_ID = 'FETCH_SHARE_ID',
  PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_CONFIRM = 'PASSWORD_RESET_CONFIRM',
  FETCH_EDITOR_DATASET_PREVIEW = 'FETCH_EDITOR_DATASET_PREVIEW',
  FETCH_EDITOR_DATASET_ROW_COUNT = 'FETCH_EDITOR_DATASET_ROW_COUNT',
  LIST_TEAM_DATA_SOURCES = 'LIST_TEAM_DATA_SOURCES',
  CHANGE_USER_PROFILE_NAME = 'CHANGE_USER_PROFILE_NAME',
  CHANGE_LAST_SEEN_APP_UPDATE_ID = 'CHANGE_LAST_SEEN_APP_UPDATE_ID',
  INVITE_TEAMMATE = 'INVITE_TEAMMATE',
  CANCEL_TEAM_INVITE = 'CANCEL_TEAM_INVITE',
  FETCH_SIGNUP_EMAIL = 'FETCH_SIGNUP_EMAIL',
  GET_USER_TEAM_INVITE = 'GET_USER_TEAM_INVITE',
  SAVE_SHARE_METADATA = 'SAVE_SHARE_METADATA',
  SAVE_SEND_EMAILS_AS = 'SAVE_SEND_EMAILS_AS',
  SAVE_FROM_EMAIL_NAME = 'SAVE_FROM_EMAIL_NAME',
  SAVE_SHARE_LINK_TITLE = 'SAVE_SHARE_LINK_TITLE',
  CREATE_DASHBOARD_DATA_PANEL = 'CREATE_DASHBOARD_DATA_PANEL',
  DUPLICATE_DASHBOARD_ITEM = 'DUPLICATE_DASHBOARD_ITEM',
  CREATE_DATASET = 'CREATE_DATASET',
  UPDATE_DATASET_SCHEMA = 'UPDATE_DATASET_SCHEMA',
  UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG = 'UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG',
  UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS = 'UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS',
  FETCH_DATASETS = 'FETCH_DATASETS',
  DELETE_DATA_PANEL = 'DELETE_DATA_PANEL',
  CREATE_NEW_DASHBOARD_VERSION = 'CREATE_NEW_DASHBOARD_VERSION',
  SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION = 'SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION',
  PUBLISH_NEW_DASHBOARD_VERSION = 'PUBLISH_NEW_DASHBOARD_VERSION',
  REVERT_TO_DASHBOARD_VERSION = 'REVERT_TO_DASHBOARD_VERSION',
  FETCH_DASHBOARD_VERSIONS = 'FETCH_DASHBOARD_VERSIONS',
  FETCH_DASHBOARD_VERSIONS_FOR_PREVIEW = 'FETCH_DASHBOARD_VERSIONS_FOR_PREVIEW',
  SAVE_DASHBOARD_DRAFT = 'SAVE_DASHBOARD_DRAFT',
  FETCH_ENVIRONMENT_TAGS = 'FETCH_ENVIRONMENT_TAGS',
  UPDATE_ENVIRONMENT_TAG = 'UPDATE_ENVIRONMENT_TAG',
  CREATE_ENVIRONMENT_TAG = 'CREATE_ENVIRONMENT_TAG',
  DELETE_ENVIRONMENT_TAG = 'DELETE_ENVIRONMENT_TAG',
  GET_ENVIRONMENT_TAG_USAGE = 'GET_ENVIRONMENT_TAG_USAGE',
  CLEAR_DASHBOARD_CONFIG_REDUCER = 'CLEAR_DASHBOARD_CONFIG_REDUCER',
  EDIT_DATA_SOURCE = 'EDIT_DATA_SOURCE',
  DELETE_CURRENT_DASHBOARD_DRAFT = 'DELETE_CURRENT_DASHBOARD_DRAFT',
  DOWNLOAD_DASHBOARD = 'DOWNLOAD_DASHBOARD',
  DOWNLOAD_DATA_PANEL_SCREENSHOT = 'DOWNLOAD_DATA_PANEL_SCREENSHOT',
  DOWNLOAD_DATA_PANEL_SPREADSHEET = 'DOWNLOAD_DATA_PANEL_SPREADSHEET',
  SAVE_CUSTOMER_EDITABLE_SECTION = 'SAVE_CUSTOMER_EDITABLE_SECTION',
  SAVE_CUSTOMER_DASHBOARD_STATE = 'SAVE_CUSTOMER_DASHBOARD_STATE',
  GET_EDITABLE_SECTION_USAGE = 'GET_EDITABLE_SECTION_USAGE',

  SYNC_PARENT_SCHEMA = 'SYNC_PARENT_SCHEMA',
  FETCH_USED_PARENT_SCHEMAS = 'FETCH_USED_PARENT_SCHEMAS',
  FETCH_ALL_SCHEMA_TABLES_FOR_TEAM = 'FETCH_ALL_SCHEMA_TABLES',

  DELETE_DATA_SOURCE = 'DELETE_DATA_SOURCE',

  CREATE_DASHBOARD_EMAIL = 'CREATE_DASHBOARD_EMAIL',
  FETCH_EMAIL_CADENCE_LIST = 'FETCH_EMAIL_CADENCE_LIST',
  EDIT_DASHBOARD_EMAIL = 'EDIT_DASHBOARD_EMAIL',
  DELETE_DASHBOARD_EMAIL = 'DELETE_DASHBOARD_EMAIL',
  SEND_TEST_EMAIL = 'SEND_TEST_EMAIL',

  SAVE_ANALYTICS_CONFIG = 'SAVE_ANALYTICS_CONFIG',
  FETCH_USER_TEAM = 'FETCH_USER_TEAM',

  CREATE_ALLOWLIST_DOMAIN = 'CREATE_ALLOWLIST_DOMAIN',
  DELETE_ALLOWLIST_DOMAIN = 'DELETE_ALLOWLIST_DOMAIN',
  FETCH_ALLOWLIST_DOMAIN = 'FETCH_ALLOWLIST_DOMAIN',

  GOOGLE_OAUTH_VERIFICATION = 'GOOGLE_OAUTH_VERIFICATION',
  END_USER_PORTAL_SIGN_IN = 'END_USER_PORTAL_SIGN_IN',
  END_USER_PORTAL_AUTHENTICATION = 'END_USER_PORTAL_AUTHENTICATION',
  FETCH_END_USER_PORTAL_METADATA = 'FETCH_END_USER_PORTAL_METADATA',
  SAML_SIGN_IN = 'SAML_SIGN_IN',
  SAML_INITIATE_AUTH = 'SAML_INITIATE_AUTH',

  SAVE_DEFAULT_CURRENCY_CODE = 'SAVE_DEFAULT_CURRENCY_CODE',
  SAVE_DEFAULT_LOCALE_CODE = 'SAVE_DEFAULT_LOCALE_CODE',
  SAVE_USE_BROWSER_LOCALE = 'SAVE_USE_BROWSER_LOCALE',

  LOGIN_USER = 'LOGIN_USER',
  REGISTER_USER = 'REGISTER_USER',
  RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
  // when a user logs out, we need to dispatch a separate action to clear redux state
  CLEAR_STATE = 'CLEAR_STATE',

  VERIFY_EMAIL = 'VERIFY_EMAIL',

  ADD_USER_TO_TEAM = 'ADD_USER_TO_TEAM',
  CREATE_TEAM = 'CREATE_TEAM',
  REMOVE_TEAMMATE = 'REMOVE_TEAMMATE',

  SET_TESTIMONIAL_INDEX = 'SET_TESTIMONIAL_INDEX',

  UPDATE_IS_WIDGET_OPEN = 'UPDATE_IS_WIDGET_OPEN',
  UPDATE_WIDGET_TYPE = 'UPDATE_WIDGET_TYPE',
  UPDATE_WIDGET_CONVERSATION_STARTED = 'UPDATE_WIDGET_CONVERSATION_STARTED',

  TEST_DATA_SOURCE_CONNECTION = 'TEST_DATA_SOURCE_CONNECTION',
  TEST_DATA_SOURCE_CONNECTION_USING_FIDO = 'TEST_DATA_SOURCE_CONNECTION_USING_FIDO',
  RETEST_DATA_SOURCE_CONNECTION_USING_FIDO = 'RETEST_DATA_SOURCE_CONNECTION_USING_FIDO',
  UPDATE_DATA_SOURCE_IN_FIDO = 'UPDATE_DATA_SOURCE_IN_FIDO',
  TEST_UPDATED_DATA_SOURCE_CONNECTION = 'TEST_UPDATED_DATA_SOURCE_CONNECTION',
  CONNECT_DATA_SOURCE = 'CONNECT_DATA_SOURCE',
  CREATE_EMBEDDO_DATA_SOURCE = 'CREATE_EMBEDDO_DATA_SOURCE',
  FETCH_SUPPORTED_DATA_SOURCES = 'FETCH_SUPPORTED_DATA_SOURCES',

  UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS = 'UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS',
  UPDATE_DRILLDOWN_DATA_PANEL = 'UPDATE_DRILLDOWN_DATA_PANEL',

  UPDATE_DASHBOARD_DEFAULT_TIMEZONE = 'UPDATE_DASHBOARD_DEFAULT_TIMEZONE',
  UPDATE_DISABLE_FILTERS_WHILE_LOADING = 'UPDATE_DISABLE_FILTERS_WHILE_LOADING',
  UPDATE_DASHBOARD_CACHE_CONFIG = 'UPDATE_DASHBOARD_CACHE_CONFIG',
  UPDATE_DASHBOARD_STATE_PERSISTANCE = 'UPDATE_DASHBOARD_STATE_PERSISTANCE',

  FETCH_DASHBOARD_ATTRIBUTES = 'FETCH_DASHBOARD_ATTRIBUTES',
  CREATE_DASHBOARD_ATTRIBUTE = 'CREATE_DASHBOARD_ATTRIBUTE',
  DELETE_DASHBOARD_ATTRIBUTE = 'DELETE_DASHBOARD_ATTRIBUTE',
  CREATE_ATTRIBUTE_VALUE = 'CREATE_ATTRIBUTE_VALUE',
  DELETE_ATTRIBUTE_VALUE = 'DELETE_ATTRIBUTE_VALUE',
  ASSIGN_DASHBOARD_VALUE = 'ASSIGN_DASHBOARD_VALUE',

  FETCH_END_USER_LIST = 'FETCH_END_USER_LIST',
  CREATE_END_USER = 'CREATE_END_USER',
  DELETE_END_USER = 'DELETE_END_USER',
  EDIT_END_USER = 'EDIT_END_USER',

  UPLOAD_IMAGE = 'UPLOAD_IMAGE',

  CREATE_API_TOKEN = 'CREATE_API_TOKEN',
  REFRESH_API_TOKEN = 'REFRESH_API_TOKEN',
  DELETE_API_TOKEN = 'DELETE_API_TOKEN',
  UPDATE_API_TOKEN = 'UPDATE_API_TOKEN',
  FETCH_ACCESS_GROUP_LIST = 'FETCH_ACCESS_GROUP_LIST',
  FETCH_ACCESS_GROUP_CUSTOMER_COUNTS = 'FETCH_ACCESS_GROUP_CUSTOMER_COUNTS',
  CREATE_ACCESS_GROUP = 'CREATE_ACCESS_GROUP',
  DELETE_ACCESS_GROUP = 'DELETE_ACCESS_GROUP',
  ASSIGN_USER_ROLE = 'ASSIGN_USER_ROLE',
  ASSIGN_PENDING_INVITE_ROLE = 'ASSIGN_PENDING_INVITE_ROLE',
  UPDATE_ACCESS_GROUPS = 'UPDATE_ACCESS_GROUPS',

  UPLOAD_CUSTOM_FONT = 'UPLOAD_CUSTOM_FONT',
  DELETE_CUSTOM_FONT = 'DELETE_CUSTOM_FONT',
  FETCH_CUSTOM_FONTS = 'FETCH_CUSTOM_FONTS',

  UPLOAD_SUPPORT_EMAIL = 'UPLOAD_SUPPORT_EMAIL',

  SEND_PING = 'SEND_PING',
  SWITCH_CUSTOMER = 'SWITCH_CUSTOMER',
  GET_SUPERUSER_ACTIONS = 'GET_SUPERUSER_ACTIONS',
  POST_SUPERUSER_ACTION = 'POST_SUPERUSER_ACTION',
  GET_SUPERUSER_USERS = 'GET_SUPERUSER_USERS',
  GET_SUPERUSER_TEAMS = 'GET_SUPERUSER_TEAMS',
  GET_SUPERUSER_TEAM = 'GET_SUPERUSER_TEAM',
  SUPERUSER_REMOVE_USER = 'SUPERUSER_REMOVE_USER',
  SUPERUSER_UPDATE_TEAM = 'SUPERUSER_UPDATE_TEAM',
  SUPERUSER_EXTEND_TRIAL = 'SUPERUSER_EXTEND_TRIAL',
  SUPERUSER_START_TRIAL = 'SUPERUSER_START_TRIAL',
  SUPERUSER_VERIFY_EMAIL = 'SUPERUSER_VERIFY_EMAIL',

  TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT = 'TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT',
  UPDATE_ELEMENT_CONTAINER_LOCATION = 'UPDATE_ELEMENT_CONTAINER_LOCATION',
  ENQUEUE_LAYOUT_JOBS = 'ENQUEUE_LAYOUT_JOBS',

  SET_SELECTED_DRILLDOWN_COLUMN = 'SET_SELECTED_DRILLDOWN_COLUMN',

  FETCH_REPORT_BUILDER = 'FETCH_REPORT_BUILDER',
  CREATE_REPORT_BUILDER = 'CREATE_REPORT_BUILDER',
  CLONE_REPORT_BUILDER = 'CLONE_REPORT_BUILDER',
  DELETE_REPORT_BUILDER = 'DELETE_REPORT_BUILDER',
  RENAME_REPORT_BUILDER = 'RENAME_REPORT_BUILDER',
  UPDATE_REPORT_BUILDER_DEFAULT_TIMEZONE = 'UPDATE_REPORT_BUILDER_DEFAULT_TIMEZONE',
  FETCH_REPORT_BUILDER_VERSIONS = 'FETCH_REPORT_BUILDER_VERSIONS',
  PUBLISH_NEW_REPORT_BUILDER_VERSION = 'PUBLISH_NEW_REPORT_BUILDER_VERSION',
  DELETE_CURRENT_REPORT_BUILDER_DRAFT = 'DELETE_CURRENT_REPORT_BUILDER_DRAFT',
  SAVE_REPORT_BUILDER_DRAFT = 'SAVE_REPORT_BUILDER_DRAFT',
  CREATE_REPORT_BUILDER_DRAFT = 'CREATE_REPORT_BUILDER_DRAFT',
  REVERT_TO_REPORT_BUILDER_VERSION = 'REVERT_TO_REPORT_BUILDER_VERSION',
  GET_REPORTS_USING_DATASET = 'GET_REPORTS_USING_DATASET',
  ENQUEUE_REPORT_BUILDER_JOBS = 'ENQUEUE_REPORT_BUILDER_JOBS',

  FETCH_REPORT_BUILDER_DATASET_PREVIEW = 'FETCH_REPORT_BUILDER_DATASET_PREVIEW',
  FETCH_REPORT_BUILDER_DATASET_PREVIEW_API = 'FETCH_REPORT_BUILDER_DATASET_PREVIEW_API',
  FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB = 'FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB',
  FETCH_REPORT_BUILDER_DATASET_ROW_COUNT = 'FETCH_REPORT_BUILDER_DATASET_ROW_COUNT',
  FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB = 'FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB',
  FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA = 'FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB',
  FETCH_REPORT_BUILDER_PREVIEW_DISTINCT_COLUMN = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_DISTINCT_COLUMN',

  REVERT_RESOURCE = 'REVERT_RESOURCE',
  FIDO_REVERT_RESOURCE = 'FIDO_REVERT_RESOURCE',
  CLONE_RESOURCE = 'CLONE_RESOURCE',
  FIDO_CLONE_RESOURCE = 'FIDO_CLONE_RESOURCE',
  FETCH_VERSION = 'FETCH_VERSION',

  CHAT_REPORT_BUILDER_VIEW_AI_JOB = 'CHAT_REPORT_BUILDER_VIEW_AI_JOB',
  GENERATE_REPORT_BUILDER_VIEW_AI_JOB = 'GENERATE_REPORT_BUILDER_VIEW_AI_JOB',
  GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB = 'GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB',

  FETCH_CUSTOMER_REPORT = 'FETCH_CUSTOMER_REPORT',
  FETCH_CUSTOMER_REPORTS = 'FETCH_CUSTOMER_REPORTS',
  CREATE_CUSTOMER_REPORT = 'CREATE_CUSTOMER_REPORT',
  SAVE_CUSTOMER_REPORT = 'SAVE_CUSTOMER_REPORT',
  UPDATE_CUSTOMER_REPORT_NAME = 'UPDATE_CUSTOMER_REPORT_NAME',
  DELETE_CUSTOMER_REPORT = 'DELETE_CUSTOMER_REPORT',
  STAR_CUSTOMER_REPORT = 'STAR_CUSTOMER_REPORT',
  FETCH_CUSTOMER_DATASET_PREVIEW = 'FETCH_CUSTOMER_DATASET_PREVIEW',
  FETCH_CUSTOMER_DATASET_PREVIEW_JOB = 'FETCH_CUSTOMER_DATASET_PREVIEW_JOB',
  FETCH_CUSTOMER_REPORT_DATA = 'FETCH_CUSTOMER_REPORT_DATA',
  FETCH_CUSTOMER_REPORT_DATA_JOB = 'FETCH_CUSTOMER_REPORT_DATA_JOB',
  FETCH_CUSTOMER_REPORT_MIN_MAX_COLUMN = 'FETCH_CUSTOMER_REPORT_MIN_MAX_COLUMN',
  FETCH_CUSTOMER_REPORT_ROW_COUNT = 'FETCH_CUSTOMER_REPORT_ROW_COUNT',
  FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB = 'FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB',
  EXPORT_CUSTOMER_REPORT = 'EXPORT_CUSTOMER_REPORT',
  LIST_CUSTOMER_REPORT_EMAILS = 'LIST_CUSTOMER_REPORT_EMAILS',
  CREATE_CUSTOMER_REPORT_EMAIL = 'CREATE_CUSTOMER_REPORT_EMAIL',
  DELETE_CUSTOMER_REPORT_EMAIL = 'DELETE_CUSTOMER_REPORT_EMAIL',
  UPDATE_CUSTOMER_REPORT_EMAIL = 'UPDATE_CUSTOMER_REPORT_EMAIL',
  SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL = 'SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL',
  SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS = 'SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS',

  FETCH_NAMESPACES = 'FETCH_NAMESPACES',
  CREATE_NAMESPACE = 'CREATE_NAMESPACE',
  UPDATE_NAMESPACE = 'UPDATE_NAMESPACE',
  DELETE_NAMESPACE = 'DELETE_NAMESPACE',

  SAVE_DRAFT_COMPUTED_VIEW_QUERY = 'SAVE_DRAFT_COMPUTED_VIEW_QUERY',
  FETCH_FIDO_COMPUTED_VIEW_PREVIEW = 'FETCH_FIDO_COMPUTED_VIEW_PREVIEW',
  FETCH_FIDO_REPORT_BUILDER_QUERY_PREVIEW = 'FETCH_FIDO_REPORT_BUILDER_QUERY_PREVIEW',
  FETCH_FIDO_REPORT_BUILDER_VIEW = 'FETCH_FIDO_REPORT_BUILDER_VIEW',
  EMBED_FETCH_FIDO_REPORT_BUILDER_VIEW = 'EMBED_FETCH_FIDO_REPORT_BUILDER_VIEW',
  FETCH_FIDO_COMPUTED_VIEW_PREVIEW_FOR_CUSTOMER = 'FETCH_FIDO_COMPUTED_VIEW_PREVIEW_FOR_CUSTOMER',
  FETCH_FIDO_COMPUTED_VIEW_PREVIEW_FOR_CUSTOMER_DATASET_MODAL = 'FETCH_FIDO_COMPUTED_VIEW_PREVIEW_FOR_CUSTOMER_DATASET_MODAL',
  FETCH_FIDO_TABLE_VIEW_PREVIEW = 'FETCH_FIDO_TABLE_VIEW_PREVIEW',
  FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET = 'FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET',
  FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET_REPORT_BUILDER = 'FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET_REPORT_BUILDER',
  FETCH_COMPUTED_VIEWS = 'FETCH_COMPUTED_VIEWS',
  FETCH_TABLES = 'FETCH_TABLES',
  SYNC_TABLES = 'SYNC_TABLES',
  SAVE_COMPUTED_VIEW = 'SAVE_COMPUTED_VIEW',
  DELETE_COMPUTED_VIEW = 'DELETE_COMPUTED_VIEW',
  CREATE_COMPUTED_VIEW = 'CREATE_COMPUTED_VIEW',
  CLONE_COMPUTED_VIEW = 'CLONE_COMPUTED_VIEW',
  FETCH_COMPUTED_VIEW_DATA = 'FETCH_COMPUTED_VIEW_DATA',
  FETCH_SUMMARIZED_VIEW_DATA = 'FETCH_SUMMARIZED_VIEW_DATA',
  CREATE_DATA_SOURCE_IN_FIDO = 'CREATE_DATA_SOURCE_IN_FIDO',
  DELETE_DATA_SOURCE_IN_FIDO = 'DELETE_DATA_SOURCE_IN_FIDO',

  TOGGLE_FAVORITE_BUILT_IN = 'TOGGLE_FAVORITE_BUILT_IN',

  FETCH_EMBED_SECRETS = 'FETCH_EMBED_SECRETS',
  CREATE_EMBED_SECRET = 'CREATE_EMBED_SECRET',
  DELETE_EMBED_SECRET = 'DELETE_EMBED_SECRET',

  FETCH_AI_SUGGESTION = 'FETCH_AI_SUGGESTION',

  // Folders
  LIST_DASHBOARD_FOLDER_CONTENTS = 'LIST_DASHBOARD_FOLDER_CONTENTS',
  LIST_REPORT_FOLDER_CONTENTS = 'LIST_REPORT_FOLDER_CONTENTS',
  LIST_FOLDER_HIERARCHY = 'LIST_FOLDER_HIERARCHY',
  CREATE_FOLDER = 'CREATE_FOLDER',
  RENAME_FOLDER = 'RENAME_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  MOVE_ENTRY = 'MOVE_ENTRY',
  GET_RESOURCE_COUNTS = 'GET_RESOURCE_COUNTS',
  SEARCH_RESOURCES = 'SEARCH_RESOURCES',
}
