import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { EXPORT_TYPE_NAME_MAP, ExportType } from 'actions/exportActions';
import { IconButton, Spinner, sprinkles, Tag } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { EmbedButton, EmbedModalContent, EmbedModalFooter } from 'components/embed';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ScheduleManager.css';
import {
  getEmailCadence,
  getEmailScheduleText,
} from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/utils';
import { isError, isLoading, isSuccess } from 'remotedata';
import { EmailCadence } from 'reportBuilderContent/exportTypes';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { sendTestExportThunk } from 'reportBuilderContent/thunks/exportThunks';
import { sortBy } from 'utils/standard';

type Props = {
  emails: EmailCadence[];
  closeModal: () => void;
  editEmail: (emailId: string) => void;
  deleteEmail: (emailId: string) => void;
  openScheduler: () => void;
  loading?: boolean;
};

export function ScheduleManager({
  loading,
  closeModal,
  editEmail,
  deleteEmail,
  emails,
  openScheduler,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { scheduledExportState, supportEmail, exportEmailId } = useSelector(
    (state: ReportBuilderReduxState) => ({
      supportEmail: state.embeddedReportBuilder.team?.support_email,
      scheduledExportState: state.reportEditing.scheduledExport,
      exportEmailId: state.reportEditing.exportEmailId,
    }),
    shallowEqual,
  );

  const hasJob = isLoading(scheduledExportState);

  const renderMenu = (emailId: string) => (
    <EmbeddedDropdownMenu
      menuOptions={[
        {
          icon: 'edit',
          name: 'Edit',
          onClick: () => editEmail(emailId),
        },
        {
          disabled: hasJob,
          icon: 'paper-plane',
          name: 'Send Test',
          onClick: () => dispatch(sendTestExportThunk({ emailId })),
        },
        {
          borderTop: true,
          icon: 'trash' as IconName,
          disabled: hasJob && exportEmailId === emailId,
          name: 'Delete',
          onClick: () => deleteEmail(emailId),
        },
      ]}>
      <IconButton name="ellipsis-vertical" />
    </EmbeddedDropdownMenu>
  );

  return (
    <>
      <EmbedModalContent className={modalContentClassName} size="xlarge">
        <EmbedText body="b3" color="contentPrimary">
          Emails are sent from {supportEmail || DEFAULT_SUPPORT_EMAIL}. Remember to check your spam
          folder if you don&rsquo;t see it in your inbox.
        </EmbedText>
        {loading ? (
          <Spinner fillContainer />
        ) : emails.length === 0 ? (
          <EmbedText color="contentPrimary" heading="h4">
            No Emails Scheduled
          </EmbedText>
        ) : (
          <div className={styles.emailList}>
            {sortBy(emails, 'subject').map((email) => {
              const numRecipients = email.recipients.length;
              return (
                <div className={styles.email} key={email.id}>
                  <EmbedText className={styles.emailSubject} color="contentPrimary" heading="h4">
                    {email.subject}
                  </EmbedText>
                  <EmbedText
                    body="b2"
                    className={styles.emailRecipients}
                    color="contentSecondary">{`${numRecipients} recipient${
                    numRecipients > 1 ? 's' : ''
                  }`}</EmbedText>
                  <div className={styles.emailInfo}>
                    <Tag intent="active">
                      {EXPORT_TYPE_NAME_MAP[email.export_type || ExportType.CSV]}
                    </Tag>
                    <Tag intent="active">{getEmailCadence(email)}</Tag>
                    <EmbedText body="b2" color="contentSecondary">
                      {getEmailScheduleText(email)}
                    </EmbedText>
                  </div>
                  <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
                    {exportEmailId !== email.id ? null : isSuccess(scheduledExportState) ? (
                      <IconButton
                        name="check"
                        tooltipProps={{ text: 'Successfully sent test email' }}
                      />
                    ) : isError(scheduledExportState) ? (
                      <IconButton
                        name="circle-exclamation"
                        tooltipProps={{ text: 'Failed to send test email' }}
                      />
                    ) : hasJob && exportEmailId === email.id ? (
                      <Spinner size="md" />
                    ) : null}
                    {renderMenu(email.id)}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedButton icon="plus" onClick={openScheduler} variant="secondary">
          Schedule an Email
        </EmbedButton>
        <EmbedButton onClick={closeModal} variant="primary">
          Done
        </EmbedButton>
      </EmbedModalFooter>
    </>
  );
}

const modalContentClassName = sprinkles({
  overflow: 'auto',
  paddingX: 'sp3',
  flexItems: 'column',
  gap: 'sp1',
});
