import produce from 'immer';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { SortConfigs } from 'components/ChartConfigs/SortConfigs';
import { SettingHeader } from 'components/SettingHeader';
import { sprinkles } from 'components/ds';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import { DATE_TYPES } from 'constants/dataConstants';
import {
  COLOR_CATEGORY_OPERATION_TYPES,
  GoalLineChartConfig,
  OPERATION_TYPES,
  V2_COLOR_ZONE_OPERATIONS,
  VISUALIZE_TABLE_OPERATIONS_SET,
  YAxisFormat,
} from 'constants/types';
import { addYAxis } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { isNumberTrendTextPanelVisualizationType } from 'pages/dashboardPage/charts/utils/trendUtils';
import { ReduxState } from 'reducers/rootReducer';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { isTimeSeriesVisualization } from 'utils/operationTypeUtils';
import { getChangedSchema } from 'utils/tableSchemaUtils';

import { AreaChartConfig } from './formatSections/AreaChartConfig';
import { BarFunnelChartConfig } from './formatSections/BarFunnelChartConfig';
import { BoxPlotConfig } from './formatSections/BoxPlotConfig';
import { CategorySortingConfig } from './formatSections/CategorySortingConfig';
import { ChartShapeBorderConfig } from './formatSections/ChartShapeBorderConfig';
import { ChoroplethMapConfig } from './formatSections/ChoroplethMapConfig';
import { CollapsibleListConfig } from './formatSections/CollapsibleListConfig';
import { CollapsibleListRowsConfig } from './formatSections/CollapsibleListRowsConfig';
import { ColorCategoryConfig } from './formatSections/ColorCategoryConfig';
import { ColorConfig } from './formatSections/ColorConfig';
import { ComboChartColumnTypeConfig } from './formatSections/ComboChartColumnTypeConfig';
import { DataLabelsConfig } from './formatSections/DataLabelsConfig';
import { FormatTimeSeriesDataConfig } from './formatSections/FormatTimeSeriesDataConfig';
import { FunnelChartConfig } from './formatSections/FunnelChartConfig';
import { HeatMapConfig } from './formatSections/HeatMapConfig';
import { InteractiveTooltipConfig } from './formatSections/InteractiveTooltipConfig';
import { KPIColorConfig } from './formatSections/KPIColorConfig';
import { KPIGeneralConfig } from './formatSections/KPIGeneralConfig';
import { KPIProgressBarConfig } from './formatSections/KPIProgressBarConfig';
import { KPITrendTitleConfig } from './formatSections/KPITrendTitleConfig';
import { KPITrendTrendConfig } from './formatSections/KPITrendTrendConfig';
import { KPIValueConfig } from './formatSections/KPIValueConfig';
import { LegendConfig } from './formatSections/LegendConfig';
import { LineChartConfig } from './formatSections/LineChartConfig';
import { LocationMarkerMapConfig } from './formatSections/LocationMarkerMapConfig';
import { LocationMarkerMapTooltipConfig } from './formatSections/LocationMarkerMapTooltipConfig';
import { MapTooltipConfig } from './formatSections/MapTooltipConfig';
import { MissingDataConfig } from './formatSections/MissingDataConfig';
import { NoDataConfig } from './formatSections/NoDataConfig';
import { PieChartConfig } from './formatSections/PieChartConfig';
import { PivotTableRowsConfig } from './formatSections/PivotTableRowsConfig';
import { SankeyChartConfigs } from './formatSections/SankeyChartConfigs';
import { ScatterPlotConfig } from './formatSections/ScatterPlotConfig';
import { SpiderChartConfig } from './formatSections/SpiderChartConfig';
import { CollapsibleListColumnsConfig } from './formatSections/TableColumnsConfig/CollapsibleListColumnConfig';
import { TableColumnsConfig } from './formatSections/TableColumnsConfig/TableColumnsConfig';
import { TableFooterConfig } from './formatSections/TableFooterConfig';
import { TableLayoutConfig } from './formatSections/TableLayoutConfig';
import { TableStylingConfig } from './formatSections/TableStylingConfig';
import { TooltipConfig } from './formatSections/TooltipConfig';
import { V2PivotColumnsConfig } from './formatSections/V2PivotColumnsConfig';
import { V2PivotTableConfig } from './formatSections/V2PivotTableConfig';
import { VisualizationHeaderConfig } from './formatSections/VisualizationHeaderConfig';
import { XAxisConfig } from './formatSections/XAxisConfig';
import { YAxisConfig } from './formatSections/YAxisConfig';
import { YAxisGroupConfig } from './formatSections/YAxisGroupConfig';
import { GoalLineGroupConfig } from './formatSections/goalLines/GoalLineGroupConfig';

type Props = {
  dataPanel: DataPanelTemplate;
  datasets: Record<string, Dataset>;
  isEditableSection?: boolean;
};

export const FormatConfigTab: FC<Props> = ({ datasets, dataPanel, isEditableSection }) => {
  const dispatch = useDispatch();

  const dataPanelData = useSelector(
    (state: ReduxState) => state.dashboardData.dataPanelData[dataPanel.id],
  );

  const visualizationType = dataPanel.visualize_op.operation_type;
  const data = useMemo(() => dataPanelData?.rows ?? [], [dataPanelData?.rows]);
  const generalFormatOptions = dataPanel.visualize_op.generalFormatOptions;
  const instructions = dataPanel.visualize_op.instructions;
  const schema = useMemo(() => dataPanelData?.schema ?? [], [dataPanelData?.schema]);
  const dataset = datasets[dataPanel.table_id];

  const changedSchema = useMemo(
    () => getChangedSchema(schema, dataPanel.visualize_op),
    [dataPanel.visualize_op, schema],
  );

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const xAxisHeader = <SettingHeader name="X-Axis" />;
  const yAxisHeader = <SettingHeader name="Y-Axis" />;

  const renderVizHeaderConfig = () => {
    const sharedProps = {
      isEditableSection,
      globalStyleConfig,
      options: generalFormatOptions || {},
    };
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
        return;
      case OPERATION_TYPES.VISUALIZE_TABLE:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.VISUALIZE_TABLE}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.VISUALIZE_PIVOT_TABLE_V2 || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.VISUALIZE_PIVOT_TABLE || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.V2_KPI_TREND || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.V2_KPI || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.V2_SCATTER_PLOT || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.V2_BOX_PLOT || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.VISUALIZE_GEOSPATIAL_CHART || {}}
            visualizationType={visualizationType}
          />
        );
      default:
        return (
          <VisualizationHeaderConfig
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            visualizationType={visualizationType}
          />
        );
    }
  };

  const renderSortingConfig = () => {
    const twoDInstructions = instructions.V2_TWO_DIMENSION_CHART || {};
    // Bar charts categorized by date are not sorted by config
    if (DATE_TYPES.has(twoDInstructions.categoryColumn?.column.type ?? '')) return null;

    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <>
            <SettingHeader name="Sorting" />
            <SortConfigs
              chartSchema={schema}
              dataPanelData={data}
              datasetSchema={dataset.schema}
              instructions={twoDInstructions}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <>
            <SettingHeader name="Sorting" />
            <SortConfigs
              isHorizontal
              chartSchema={schema}
              dataPanelData={data}
              datasetSchema={dataset.schema}
              instructions={twoDInstructions}
              visualizationType={visualizationType}
            />
          </>
        );

      default:
        return;
    }
  };

  const renderGroupAxesConfig = (
    isForHorizontalChart: boolean,
    visualizationType: OPERATION_TYPES,
  ) => {
    return (
      <>
        <SettingHeader
          btnProps={{
            icon: 'plus',
            onClick: () =>
              dispatch(addYAxis(instructions.V2_TWO_DIMENSION_CHART || {}, visualizationType)),
          }}
          name={isForHorizontalChart ? 'X-Axes' : 'Y-Axes'}
        />
        <YAxisGroupConfig
          instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
          isForHorizontalChart={isForHorizontalChart}
          visualizationType={visualizationType}
        />
      </>
    );
  };

  const renderXAxisConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return;
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <>
            {xAxisHeader}
            <YAxisConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            {renderTimeSeriesConfig()}
          </>
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return renderGroupAxesConfig(true, visualizationType);
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <>
            {xAxisHeader}
            <XAxisConfig
              enableScrolling
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            {renderTimeSeriesConfig()}
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            {instructions.V2_BOX_PLOT?.boxPlotFormat?.isVertical ? xAxisHeader : yAxisHeader}
            <XAxisConfig
              instructions={instructions.V2_BOX_PLOT || {}}
              isHorizontal={!instructions.V2_BOX_PLOT?.boxPlotFormat?.isVertical}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <>
            {xAxisHeader}
            <XAxisConfig
              instructions={instructions.V2_SCATTER_PLOT || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
        return null;
      default:
        return (
          <>
            {xAxisHeader}
            <XAxisConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            {renderTimeSeriesConfig()}
          </>
        );
    }
  };

  const renderYAxisConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return;
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <>
            {yAxisHeader}
            <XAxisConfig
              enableScrolling
              isHorizontal
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            {instructions.V2_BOX_PLOT?.boxPlotFormat?.isVertical ? yAxisHeader : xAxisHeader}
            <YAxisConfig
              instructions={instructions.V2_BOX_PLOT || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <>
            {yAxisHeader}
            <YAxisConfig
              instructions={instructions.V2_SCATTER_PLOT || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
        return renderGroupAxesConfig(false, visualizationType);
      default:
        return (
          <>
            {yAxisHeader}
            <YAxisConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
    }
  };

  const renderTwoDimensionChartTooltipConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
        return (
          <>
            <SettingHeader name="Tooltip" />
            <TooltipConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderGoalLineConfig = () => {
    let vizInstructions: GoalLineChartConfig | undefined = undefined;
    let yAxisFormats: YAxisFormat[] = [];
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        vizInstructions = instructions.V2_TWO_DIMENSION_CHART || {};
        break;
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        vizInstructions = instructions.V2_TWO_DIMENSION_CHART || {};
        // These 4 chart types allow multiple axes so have to assign goal lines to them
        yAxisFormats = instructions.V2_TWO_DIMENSION_CHART?.yAxisFormats ?? [];
        break;
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        vizInstructions = instructions.V2_BOX_PLOT || {};
        break;
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        vizInstructions = instructions.V2_SCATTER_PLOT || {};
        break;
      default:
        return;
    }

    if (vizInstructions === undefined) return;

    return (
      <>
        <SettingHeader
          btnProps={{
            icon: 'plus',
            onClick: () => {
              const newInstructions = produce(vizInstructions ?? {}, (draft) => {
                if (!draft.goalLines) draft.goalLines = [];
                draft.goalLines.push({
                  labelColor: globalStyleConfig.text.primaryColor,
                  lineColor: globalStyleConfig.base.actionColor.default,
                });
              });
              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            },
          }}
          name="Goal Line"
        />
        <GoalLineGroupConfig
          instructions={vizInstructions}
          visualizationType={visualizationType}
          yAxisFormats={yAxisFormats}
        />
      </>
    );
  };

  const renderTimeSeriesConfig = () => {
    if (isTimeSeriesVisualization(visualizationType, instructions.V2_TWO_DIMENSION_CHART)) {
      return (
        <FormatTimeSeriesDataConfig
          instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
          visualizationType={visualizationType}
        />
      );
    }
  };

  const renderChartSpecificConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <>
            <SettingHeader name="Bar Chart" />
            <ChartShapeBorderConfig
              chartShapeBorderConfig={
                instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.barChart
              }
              updateConfig={(newShapeBorderConfig) =>
                dispatch(
                  updateVisualizeOperation(
                    produce(instructions.V2_TWO_DIMENSION_CHART || {}, (draft) => {
                      draft.chartSpecificFormat = {
                        ...draft.chartSpecificFormat,
                        barChart: {
                          ...draft.chartSpecificFormat?.barChart,
                          ...newShapeBorderConfig,
                        },
                      };
                      return draft;
                    }),
                    visualizationType,
                  ),
                )
              }
            />
            <div className={sprinkles({ paddingX: 'sp1.5' })}>
              <MissingDataConfig
                instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
                visualizationType={visualizationType}
              />
            </div>
          </>
        );
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2: {
        // Only show line options for combo if one of its columns is set to be a line
        const hasLineInCombo =
          visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
          instructions.V2_TWO_DIMENSION_CHART?.aggColumns?.some(
            (agg) =>
              // Default is line
              !agg.column.dataFormat || agg.column.dataFormat === COMBO_CHART_DATA_FORMATS.LINE,
          );
        if (!hasLineInCombo) return null;
        return (
          <>
            <SettingHeader name="Line Chart" />
            <LineChartConfig
              defaultLineWidth={globalStyleConfig.container.lineWidth.default}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      }
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
        return (
          <>
            <SettingHeader name="Area Chart" />
            <LineChartConfig
              defaultLineWidth={globalStyleConfig.container.lineWidth.default}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            <AreaChartConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <>
            <SettingHeader name="Pie Chart" />
            <PieChartConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            <ChartShapeBorderConfig
              chartShapeBorderConfig={
                instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.pieChart
              }
              updateConfig={(newShapeBorderConfig) =>
                dispatch(
                  updateVisualizeOperation(
                    produce(instructions.V2_TWO_DIMENSION_CHART || {}, (draft) => {
                      draft.chartSpecificFormat = {
                        ...draft.chartSpecificFormat,
                        pieChart: {
                          ...draft.chartSpecificFormat?.pieChart,
                          ...newShapeBorderConfig,
                        },
                      };
                      return draft;
                    }),
                    visualizationType,
                  ),
                )
              }
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
        return (
          <>
            <SettingHeader name="Heat Map" />
            <HeatMapConfig
              globalStyleConfig={globalStyleConfig}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            <CategorySortingConfig
              sortedStages={
                instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.heatMap?.sortedXAxis || []
              }
              title="Sorting X-Axis Categories"
              updateInstructions={(sortedXAxis) => {
                const heatMap = instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.heatMap;
                const newInstructions = produce(
                  instructions.V2_TWO_DIMENSION_CHART || {},
                  (draft) => {
                    draft.chartSpecificFormat = {
                      ...draft.chartSpecificFormat,
                      heatMap: {
                        ...heatMap,
                        sortedXAxis,
                      },
                    };
                  },
                );

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
            />
            <CategorySortingConfig
              sortedStages={
                instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.heatMap?.sortedYAxis || []
              }
              title="Sorting Y-Axis Categories"
              updateInstructions={(sortedYAxis) => {
                const heatMap = instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.heatMap;
                const newInstructions = produce(
                  instructions.V2_TWO_DIMENSION_CHART || {},
                  (draft) => {
                    draft.chartSpecificFormat = {
                      ...draft.chartSpecificFormat,
                      heatMap: {
                        ...heatMap,
                        sortedYAxis,
                      },
                    };
                  },
                );

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return (
          <>
            <SettingHeader name="Sankey Chart" />
            <SankeyChartConfigs instructions={instructions.V2_TWO_DIMENSION_CHART || {}} />
          </>
        );

      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
        return (
          <>
            <SettingHeader name="Funnel Chart" />
            <FunnelChartConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
            <CategorySortingConfig
              sortedStages={
                instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.funnelChart
                  ?.sortedStages || []
              }
              updateInstructions={(sortedStages) => {
                const funnelChart =
                  instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.funnelChart;
                const newInstructions = produce(
                  instructions.V2_TWO_DIMENSION_CHART || {},
                  (draft) => {
                    draft.chartSpecificFormat = {
                      ...draft.chartSpecificFormat,
                      funnelChart: {
                        ...funnelChart,
                        sortedStages,
                      },
                    };
                  },
                );

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <>
            <SettingHeader name="Funnel Chart" />
            <BarFunnelChartConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );

      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
        return (
          <>
            <SettingHeader name="General" />
            <KPIGeneralConfig
              instructions={instructions.V2_KPI || {}}
              visualizationType={visualizationType}
            />
            <SettingHeader name="Value" />
            <KPIValueConfig
              instructions={instructions.V2_KPI || {}}
              visualizationType={visualizationType}
            />
            <SettingHeader infoText="Used to set a color for the value of the KPI" name="Color" />
            <KPIColorConfig
              instructions={instructions.V2_KPI || {}}
              visualizationType={visualizationType}
            />
            {visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ? (
              <>
                <SettingHeader name="Progress Bar" />
                <KPIProgressBarConfig
                  instructions={instructions.V2_KPI || {}}
                  visualizationType={visualizationType}
                />
              </>
            ) : null}
          </>
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <>
            <SettingHeader name="Box Plot" />
            <BoxPlotConfig
              instructions={instructions.V2_BOX_PLOT || {}}
              visualizationType={visualizationType}
            />
            <CategorySortingConfig
              sortedStages={instructions.V2_BOX_PLOT?.sortedStages || []}
              updateInstructions={(sortedStages) => {
                const newInstructions = produce(instructions.V2_BOX_PLOT || {}, (draft) => {
                  draft = {
                    ...draft,
                    sortedStages,
                  };
                  return draft;
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <>
            <SettingHeader name="Scatter Plot" />
            <ScatterPlotConfig instructions={instructions.V2_SCATTER_PLOT || {}} />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL: {
        return (
          <>
            {isNumberTrendTextPanelVisualizationType(
              visualizationType,
              instructions.V2_KPI_TREND,
            ) ? (
              <>
                <SettingHeader name="General" />
                <KPITrendTitleConfig
                  instructions={instructions.V2_KPI_TREND || {}}
                  visualizationType={visualizationType}
                />
              </>
            ) : null}
            <SettingHeader name="Value" />
            <KPIValueConfig
              instructions={instructions.V2_KPI_TREND || {}}
              visualizationType={visualizationType}
            />
            <SettingHeader name="Trend" />
            <KPITrendTrendConfig
              instructions={instructions.V2_KPI_TREND || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      }
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        return (
          <>
            <SettingHeader name="Spider Chart" />
            <SpiderChartConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              lineWidth={globalStyleConfig.container.lineWidth.default}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
        return (
          <CollapsibleListConfig
            dptProvidedId={dataPanel.provided_id}
            generalFormatOptions={generalFormatOptions || {}}
            instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
        return (
          <>
            <ChoroplethMapConfig
              dataPanelId={dataPanel.id}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            />
            <MapTooltipConfig
              dataPanelId={dataPanel.id}
              instructions={instructions}
              operationType={OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP: // TODO: Create the isopleth specific config
        return;
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
        return (
          <>
            <LocationMarkerMapConfig
              dataPanelId={dataPanel.id}
              instructions={instructions.VISUALIZE_GEOSPATIAL_CHART || {}}
            />
            <LocationMarkerMapTooltipConfig
              dataPanelId={dataPanel.id}
              instructions={instructions}
              schema={schema}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
        return <V2PivotTableConfig instructions={instructions.VISUALIZE_PIVOT_TABLE_V2} />;
      default:
        return;
    }
  };

  const renderInteractiveTooltipConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <>
            <SettingHeader name="Interactive Tooltip" />
            <InteractiveTooltipConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderLegendFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return null;
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <LegendConfig
            instructions={instructions.V2_BOX_PLOT || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <LegendConfig
            instructions={instructions.V2_SCATTER_PLOT || {}}
            visualizationType={visualizationType}
          />
        );

      default:
        return (
          <LegendConfig
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            visualizationType={visualizationType}
          />
        );
    }
  };

  const renderColorFormat = () => {
    if (V2_COLOR_ZONE_OPERATIONS.has(visualizationType)) {
      return (
        <ColorConfig
          includeZones
          instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
          visualizationType={visualizationType}
        />
      );
    }
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <ColorConfig
            instructions={instructions.V2_SCATTER_PLOT || {}}
            visualizationType={visualizationType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        return (
          <ColorConfig
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            visualizationType={visualizationType}
          />
        );
      default:
        return;
    }
  };

  const renderDataLabelsFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        return (
          <>
            <SettingHeader name="Data Labels" />
            <DataLabelsConfig
              firstColOnly={!!instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions?.length}
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
      case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return (
          <>
            <SettingHeader name="Data Labels" />
            <DataLabelsConfig
              firstColOnly
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      default:
        return;
    }
  };

  const renderColorCategoryConfig = () => {
    if (
      COLOR_CATEGORY_OPERATION_TYPES.has(visualizationType) &&
      instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions?.length
    ) {
      return (
        <>
          <SettingHeader name="Group By Column" />
          <ColorCategoryConfig
            dataPanelProvidedId={dataPanel.provided_id}
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            visualizationType={visualizationType}
          />
        </>
      );
    }
  };

  const renderComboChartColumnTypeFormat = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <>
            <SettingHeader name="Data Format" />
            <ComboChartColumnTypeConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
              visualizationType={visualizationType}
            />
          </>
        );
      default:
        return;
    }
  };

  const tableExportInstructions =
    visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
      ? instructions.VISUALIZE_PIVOT_TABLE || {}
      : instructions.VISUALIZE_TABLE;

  const renderTableStylingConfig = () => {
    const hasStylingConfig = [
      OPERATION_TYPES.VISUALIZE_TABLE,
      OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
      OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
      OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
    ].includes(visualizationType);
    if (!hasStylingConfig) return null;

    const stylingConfigVisualizationType =
      visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
        ? OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
        : OPERATION_TYPES.VISUALIZE_TABLE;

    return (
      <TableStylingConfig
        instructions={tableExportInstructions}
        isRowLevelActionsEnabled={!!instructions.VISUALIZE_TABLE.rowLevelActionConfigs?.length}
        visualizationType={stylingConfigVisualizationType}
      />
    );
  };

  const isTableVisualization =
    visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
    visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE;

  const renderTableFooterConfig = () => {
    // For tables, if the footer is hidden, the export button is also hidden
    if (!isTableVisualization) return null;

    return (
      <TableFooterConfig
        instructions={tableExportInstructions}
        visualizationType={visualizationType}
      />
    );
  };

  const renderTableRowsConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
        return (
          <>
            <SettingHeader name="Rows" />
            <PivotTableRowsConfig
              globalStyleConfig={globalStyleConfig}
              instructions={instructions.VISUALIZE_PIVOT_TABLE || {}}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST: {
        // Use original dataset schema instead of aggregated one to preserve original types
        const rowCols = instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns ?? [];

        const findGroupByColIndex = (groupByColName: string) => {
          return rowCols.findIndex((rowCol) => rowCol.column.name === groupByColName);
        };

        // Filter out unused groupByCols then sort them to match user's selected ordering
        const groupByCols =
          rowCols.length === 0
            ? undefined
            : dataset.schema
                ?.filter((column) => findGroupByColIndex(column.name) >= 0)
                .sort(
                  (groupByCol1, groupByCol2) =>
                    findGroupByColIndex(groupByCol1.name) - findGroupByColIndex(groupByCol2.name),
                );

        return (
          <>
            <SettingHeader name="Rows" />
            <CollapsibleListRowsConfig
              instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
              originalGroupByCols={groupByCols || []}
              schema={changedSchema}
            />
          </>
        );
      }
    }
  };

  const renderTableLayoutConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
        return (
          <>
            <SettingHeader name="Layout" />
            <TableLayoutConfig
              instructions={instructions.VISUALIZE_TABLE || {}}
              schema={changedSchema}
            />
          </>
        );
    }
  };

  const renderTableColumnsConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <>
            <SettingHeader name="Column Formatting" />
            <TableColumnsConfig
              dashboardDatasets={datasets}
              dataPanelData={data}
              globalStyleConfig={globalStyleConfig}
              instructions={instructions.VISUALIZE_TABLE || {}}
              originalSchema={dataset.schema ?? schema}
              schema={changedSchema}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
        return (
          <>
            <SettingHeader name="Column Formatting" />
            <V2PivotColumnsConfig instructions={instructions.VISUALIZE_PIVOT_TABLE_V2} />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
        return (
          <>
            <SettingHeader name="Columns" />
            <TableColumnsConfig
              dashboardDatasets={datasets}
              dataPanelData={data}
              globalStyleConfig={globalStyleConfig}
              instructions={instructions.VISUALIZE_PIVOT_TABLE || {}}
              originalSchema={schema}
              schema={changedSchema}
              visualizationType={visualizationType}
            />
          </>
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
        return (
          <>
            <SettingHeader name="Columns" />
            <CollapsibleListColumnsConfig
              instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
              schema={changedSchema}
            />
          </>
        );
    }
  };

  const renderNoDataConfig = () => {
    if (visualizationType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP) return;
    return (
      <>
        <SettingHeader name="No Data Configuration" />
        <NoDataConfig
          generalFormatOptions={generalFormatOptions || {}}
          isDataTable={VISUALIZE_TABLE_OPERATIONS_SET.has(visualizationType)}
          isKpiChart={
            visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
            visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL ||
            visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
            visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
          }
        />
      </>
    );
  };

  return (
    <div style={{ paddingBottom: 200 }}>
      {renderVizHeaderConfig()}
      {renderSortingConfig()}
      {renderXAxisConfig()}
      {renderYAxisConfig()}
      {renderTwoDimensionChartTooltipConfig()}
      {renderGoalLineConfig()}
      {renderComboChartColumnTypeFormat()}
      {renderChartSpecificConfig()}
      {renderInteractiveTooltipConfig()}
      {renderDataLabelsFormat()}
      {renderLegendFormat()}
      {renderColorCategoryConfig()}
      {renderColorFormat()}
      {renderTableFooterConfig()}
      {renderTableStylingConfig()}
      {renderTableRowsConfig()}
      {renderTableLayoutConfig()}
      {renderTableColumnsConfig()}
      {renderNoDataConfig()}
    </div>
  );
};
