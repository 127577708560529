import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Button, sprinkles } from 'components/ds';
import { setEditingLayout } from 'reducers/dashboardInteractionsReducer';

type Props = {
  buttonText: string;
  title: string;
  onEnable: () => void;
};

export const DisabledLayoutPanel: FC<Props> = ({ buttonText, title, onEnable }) => {
  const dispatch = useDispatch();

  return (
    <div className={sprinkles({ flexItems: 'centerColumn', parentContainer: 'fill' })}>
      <div className={sprinkles({ heading: 'h2', textAlign: 'center', marginBottom: 'sp2' })}>
        {title}
      </div>
      <div className={sprinkles({ marginBottom: 'sp1' })}>
        <Button onClick={onEnable} variant="primary">
          {buttonText}
        </Button>
      </div>

      <div>
        <Button onClick={() => dispatch(setEditingLayout(null))} variant="tertiary">
          Cancel
        </Button>
      </div>
    </div>
  );
};
