import { SELECTABLE_CHARTS } from 'constants/dataConstants';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

export const getSelectableKPIs = (
  dpsById: Record<string, DataPanelTemplate>,
): DataPanelTemplate[] => Object.values(dpsById).filter(isSelectableKPI);

export const isSelectableKPI = (dp: DataPanelTemplate): boolean =>
  SELECTABLE_CHARTS.has(dp.visualize_op.operation_type) &&
  !!dp.visualize_op.instructions.V2_KPI?.actionConfig?.selectionEnabled;
