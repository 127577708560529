import { produce } from 'immer';
import { FC } from 'react';

import { Label, sprinkles } from 'components/ds';
import { getGlobalStyleButtonColors } from 'globalStyles/getGlobalStyleVars/utils';
import { GlobalStyleConfig, GlobalStyleActionColorConfig } from 'globalStyles/types';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const ButtonConfig: FC<Props> = ({ styleConfig, updateConfig }) => {
  const handleUpdateConfig = (updates: Partial<GlobalStyleActionColorConfig>) => {
    const newConfig = produce(styleConfig, (draft) => {
      draft.base.actionColor = { ...draft.base.actionColor, ...updates };
    });
    updateConfig(newConfig);
  };

  const { actionColor } = styleConfig.base;
  const {
    primaryButtonColor,
    primaryButtonTextColor,
    secondaryButtonColor,
    secondaryButtonTextColor,
    tertiaryButtonTextColor,
  } = getGlobalStyleButtonColors(actionColor, styleConfig.text);

  return (
    <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
      <Label htmlFor="">Primary Background Color</Label>
      <ColorPicker
        fill
        color={primaryButtonColor}
        onClose={(buttonColor) => handleUpdateConfig({ buttonColor })}
      />

      <Label htmlFor="">Primary Text Color</Label>
      <ColorPicker
        fill
        color={primaryButtonTextColor}
        onClose={(buttonTextColor) => handleUpdateConfig({ buttonTextColor })}
      />

      <Label htmlFor="" infoText="Report Builder only">
        Secondary Background Color
      </Label>
      <ColorPicker
        fill
        color={secondaryButtonColor}
        onClose={(secondaryButtonColor) => handleUpdateConfig({ secondaryButtonColor })}
      />

      <Label htmlFor="" infoText="Report Builder only">
        Secondary Text Color
      </Label>
      <ColorPicker
        fill
        color={secondaryButtonTextColor}
        onClose={(secondaryButtonTextColor) => handleUpdateConfig({ secondaryButtonTextColor })}
      />

      <Label htmlFor="">Tertiary Text Color</Label>
      <ColorPicker
        fill
        color={tertiaryButtonTextColor}
        onClose={(tertiaryButtonTextColor) => handleUpdateConfig({ tertiaryButtonTextColor })}
      />
    </div>
  );
};
