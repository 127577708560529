import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { MouseEvent, FC } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import * as styles from 'components/resource/EmbeddedDropdownMenu/styles.css';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { LightTooltip } from 'pages/ReportBuilder/ReportView/DataPanel/LightTooltip';

type Props = {
  option: DropdownOption;
  onClick: (option: DropdownOption, event: MouseEvent<HTMLDivElement>) => void;
};

export const EmbeddedDropdownMenuItem: FC<Props> = ({ onClick, option }) => {
  const renderContent = () => (
    <div className={styles.dropdownOptionContent}>
      {option.icon ? <Icon name={option.icon} /> : undefined}
      <EmbedText body="b1" className={cx(styles.name, option.className)}>
        {option.name}
      </EmbedText>
      {option.description ? (
        <EmbedText body="b2" className={cx(styles.description, option.descriptionClassName)}>
          {option.description}
        </EmbedText>
      ) : null}
    </div>
  );

  return (
    <DropdownMenu.Item
      className={cx(styles.dropdownOption, {
        [styles.dropdownOptionBorderTop]: option.borderTop,
        [styles.dropdownOptionDisabled]: option.disabled,
      })}
      onClick={(e) => onClick(option, e)}>
      {option.description ? (
        <LightTooltip
          delayDuration={DEFAULT_DELAY}
          side="left"
          text={
            <div className={styles.tooltip}>
              <EmbedText body="b2" className={styles.tooltipTitle}>
                {option.name}
              </EmbedText>
              <EmbedText body="b2" className={sprinkles({ margin: 'sp0' })}>
                {option.description}
              </EmbedText>
            </div>
          }>
          {renderContent()}
        </LightTooltip>
      ) : (
        renderContent()
      )}
    </DropdownMenu.Item>
  );
};
