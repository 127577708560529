import { FC } from 'react';

import { Select } from 'components/ds';
import { SelectItems } from 'components/ds/Select';

import * as styles from './TextWeightSelect.css';

type Props = {
  selectedValue?: number;
  values?: SelectItems<string>;
  onSubmit: (value?: number) => void;
  disabled?: boolean;
  placeholder?: string;
};

export const TextWeightSelect: FC<Props> = ({
  selectedValue,
  onSubmit,
  disabled,
  placeholder,
  values,
}) => {
  return (
    <Select
      className={styles.select}
      contentWidth="auto"
      disabled={disabled}
      onChange={(value) => {
        if (value === '') onSubmit?.();
        const textWeight = parseInt(value);
        if (isNaN(textWeight) || textWeight % 100 !== 0) return; // Font weights must be multiples of 100
        onSubmit?.(textWeight);
      }}
      placeholder={placeholder}
      selectedValue={selectedValue != null ? selectedValue.toString() : ''}
      values={values || SELECT_OPTIONS}
    />
  );
};

export const SELECT_OPTIONS = [
  { label: 'Default', value: '' },
  { label: '100', value: '100' },
  { label: '200', value: '200' },
  { label: '300', value: '300' },
  { label: '400 (Normal)', value: '400' },
  { label: '500', value: '500' },
  { label: '600', value: '600' },
  { label: '700 (Bold)', value: '700' },
  { label: '800', value: '800' },
  { label: '900', value: '900' },
];
