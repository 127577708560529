import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  saveDatasetQuery,
  saveDraftDatasetQuery,
  updateDashboardDatasetSchema,
} from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { Poller } from 'components/JobQueue/Poller';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { receiveFinishedJobs } from 'reducers/dashboardLayoutReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';

import { DashboardDatasetEditor } from './dashboardDatasetEditor';

export const DashboardDatasetEditorDataFetcher = () => {
  const dispatch = useDispatch();

  const { datasets, parentSchemas, datasetData, awaitedJobs, selectedDatasetId } = useSelector(
    (state: ReduxState) => ({
      shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
      datasets: state.dashboardEditConfig.config?.datasets,
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      awaitedJobs: state.dashboardLayout.awaitedJobs,
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
    }),
    shallowEqual,
  );

  const onSaveQuery = (query: string) => {
    if (!selectedDatasetId) return;

    getUnderlyingData(query, undefined, (data) => {
      const previewData = (data as FetchDashboardDatasetPreviewData).dataset_preview;
      dispatch(
        saveDatasetQuery({
          query: query,
          dataset_id: selectedDatasetId,
          schema: previewData.schema,
        }),
      );
      dispatch(fetchSavedDatasetThunk(selectedDatasetId));

      trackEvent(EVENTS.SAVED_QUERY, {
        dataset_id: selectedDatasetId,
        dataset_query: query,
      });

      showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(previewData.schema));
    });
  };

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftDatasetQuery({ queryDraft: query, dataset_id: selectedDatasetId }));
  };

  const onSelectSchema = (schemaId: number | string) => {
    if (!selectedDatasetId) return;

    dispatch(
      updateDashboardDatasetSchema({
        datasetId: selectedDatasetId,
        newParentSchemaId: schemaId as number,
      }),
    );
  };

  const getUnderlyingData = (
    query: string,
    pageNumber?: number,
    onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
  ) => {
    if (!selectedDatasetId || !datasets) return;

    dispatch(
      fetchEditorDatasetPreviewThunk(
        {
          selectedDatasetId,
          query,
          parentSchemaId: datasets[selectedDatasetId].parent_schema_id,
        },
        pageNumber,
        onSuccess,
      ),
    );
  };

  const selectedDataset = datasets ? datasets[selectedDatasetId ?? ''] : null;
  const data = selectedDatasetId ? datasetData[selectedDatasetId] : undefined;

  return (
    <>
      <DashboardDatasetEditor
        activeDatasetConfig={selectedDataset}
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={selectedDataset?.schema ?? []}
        activeDatasetSchema={data?.schema ?? selectedDataset?.schema ?? []}
        activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
        datasetConfigs={datasets ?? {}}
        fetchData={getUnderlyingData}
        onSave={onSaveQuery}
        onSaveDraft={onSaveQueryDraft}
        onSelectSchema={onSelectSchema}
        parentSchemas={parentSchemas}
        selectedDatasetId={selectedDatasetId}
        setSelectedDatasetId={(datasetId) => {
          dispatch(setSelectedDatasetId(datasetId));

          if (!datasets || !datasetId) return;

          trackEvent(EVENTS.SELECTED_DATASET, {
            dataset_id: datasetId,
            dataset_name: datasets[datasetId].table_name,
          });
        }}
      />
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) dispatch(receiveFinishedJobs(finishedJobIds));

          onComplete();
        }}
      />
    </>
  );
};
