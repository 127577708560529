import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearState } from 'actions/authAction';
import { assignPendingInviteRole, assignUserRole } from 'actions/rolePermissionActions';
import {
  cancelTeamInvite,
  InvitedUser,
  inviteTeammates,
  removeTeammate,
} from 'actions/teamActions';
import { TeamMember } from 'actions/userActions';
import { AlertModal, sprinkles, Button, Select } from 'components/ds';
import {
  DEFAULT_ROLE_OPTIONS,
  PERMISSIONED_ACTIONS,
  PERMISSIONED_ENTITIES,
} from 'constants/roleConstants';
import { ReduxState } from 'reducers/rootReducer';
import { showSuccessToast, showErrorContactSupportToast } from 'shared/sharedToasts';
import { doesUserHavePermission, getTeamMemberRole } from 'utils/permissionUtils';

import * as styles from './styles.css';

type Props = {
  invitedUser?: InvitedUser;
  user?: TeamMember;
};

export const SettingsTeamMemberItem: FC<Props> = ({ user, invitedUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentUser = useSelector((state: ReduxState) => state.currentUser);
  const isCurrentUser = currentUser.id === user?.id;
  const userTeamPermissions = currentUser.permissions[PERMISSIONED_ENTITIES.TEAM];

  const userCanAssignRoles = doesUserHavePermission(
    userTeamPermissions,
    PERMISSIONED_ACTIONS.UPDATE,
  );
  const dispatch = useDispatch();

  const renderProfileIcon = () => {
    return (
      <div className={styles.profileIconSmall}>
        {user
          ? `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`
          : invitedUser
          ? `${invitedUser.email.charAt(0).toUpperCase()}`
          : 'A'}
      </div>
    );
  };

  const renderProfileName = () => {
    if (user) {
      return (
        <div className={nameSectionClass}>
          <div className={styles.memberName}>
            {user.first_name} {user.last_name}
            {isCurrentUser ? ' (You)' : ''}
          </div>
          <div className={sprinkles({ color: 'gray10' })}>{user.email}</div>
        </div>
      );
    } else if (invitedUser) {
      return (
        <div className={nameSectionClass}>
          <div className={sprinkles({ color: 'gray10' })}>{invitedUser.email}</div>
        </div>
      );
    }
  };

  const renderRole = () => {
    const userRole = getTeamMemberRole(user ?? invitedUser);
    if (doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.UPDATE)) {
      return (
        <div className={styles.memberRole}>
          <Select
            onChange={(option) => {
              if (invitedUser)
                dispatch(
                  assignPendingInviteRole({
                    postData: { email: invitedUser.email, role_names: [option] },
                  }),
                );
              if (user)
                dispatch(assignUserRole({ postData: { user_id: user.id, role_names: [option] } }));
            }}
            placeholder="Select a Role"
            selectedValue={userRole}
            values={DEFAULT_ROLE_OPTIONS}
          />
        </div>
      );
    } else return <div className={styles.memberRole}>{userRole}</div>;
  };

  const renderActions = () => {
    return (
      <div
        className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', justifyContent: 'flex-end' })}>
        {invitedUser && doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.CREATE) ? (
          <Button
            onClick={() => {
              if (!currentUser.team) return;

              dispatch(
                inviteTeammates(
                  {
                    postData: {
                      invites: [{ email: invitedUser.email, role_names: invitedUser.role_names }],
                      team_id: currentUser.team.id,
                      inviter: currentUser.first_name,
                      resend: true,
                    },
                  },
                  () => showSuccessToast(`Invites sent successfully to ${invitedUser.email}`),
                  (response) => showErrorContactSupportToast(response.error_msg),
                ),
              );
            }}
            variant="tertiary">
            Resend Invite
          </Button>
        ) : null}
        {!isCurrentUser &&
        doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.DELETE) ? (
          <Button onClick={() => setIsModalOpen(true)} variant="destructive">
            Remove
          </Button>
        ) : null}
      </div>
    );
  };

  const renderConfirmationModal = () => {
    const onRemove = () => {
      if (!currentUser.team) return;
      const team_id = currentUser.team.id;

      if (invitedUser)
        dispatch(cancelTeamInvite({ postData: { team_id, email: invitedUser.email } }));

      if (user) {
        dispatch(removeTeammate({ postData: { user_id: user.id, team_id } }));
        if (isCurrentUser) dispatch(clearState());
      }

      setIsModalOpen(false);
    };

    return (
      <AlertModal
        actionButtonProps={{ text: 'Remove', onClick: onRemove }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Are you sure you want to remove ${user ? user.first_name : invitedUser?.email}?`}>
        {isCurrentUser ? 'This will log you out automatically' : null}
      </AlertModal>
    );
  };

  return (
    <div
      className={sprinkles({ marginTop: 'sp1.5', flexItems: 'alignCenterBetween' })}
      key={`team-user-member-${user?.id}`}>
      <div className={sprinkles({ flex: 1, flexItems: 'alignCenter' })}>
        {renderProfileIcon()}
        {renderProfileName()}
      </div>
      <div
        className={sprinkles({
          flex: 1,
          flexItems: 'alignCenter',
          justifyContent: userCanAssignRoles ? 'space-between' : 'flex-end',
        })}>
        {renderRole()}
        {renderActions()}
      </div>
      {renderConfirmationModal()}
    </div>
  );
};

const nameSectionClass = sprinkles({
  marginLeft: 'sp2',
  flexItems: 'column',
  justifyContent: 'center',
});
