import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  connectDataSourceSuccess,
  DataSource,
  editDataSourceSuccess,
  fetchAllDataSourceTablesActions,
  fetchSupportedDataSourcesActions,
  listTeamDataSourcesActions,
  SupportedDataSource,
} from 'actions/dataSourceActions';
import { DataTable } from 'actions/datasetActions';
import * as RD from 'remotedata';
import { sortBy } from 'utils/standard';

import { ReduxState } from './rootReducer';
import { createEmbeddoDataSource } from './thunks/connectDataSourceThunks';
import { deleteDataSourceThunk, syncSchemaThunk } from './thunks/schemaManagementThunks';
import { fetchAllDataSourceTables } from './thunks/syncSchemaFlowThunks';

interface DataSourcesReducerState {
  // Used for syncing data sources
  allTables: RD.ResponseData<DataTable[]>;
  // Used for showing supported DataSource types
  supportedDataSources: RD.ResponseData<SupportedDataSource[]>;
  // These are the team data sources
  dataSources: RD.ResponseData<DataSource[]>;
}

const dataSourceReducerInitialState: DataSourcesReducerState = {
  allTables: RD.Idle(),
  supportedDataSources: RD.Idle(),
  dataSources: RD.Idle(),
};

const dataSourceSlice = createSlice({
  name: 'dataSource',
  initialState: dataSourceReducerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDataSourceTablesActions.requestAction, (state) => {
        state.allTables = RD.Loading();
      })
      .addCase(fetchAllDataSourceTables.pending, (state) => {
        state.allTables = RD.Loading();
      })
      .addCase(fetchAllDataSourceTablesActions.successAction, (state, { payload }) => {
        state.allTables = RD.Success(payload.schema);
      })
      .addCase(fetchAllDataSourceTables.fulfilled, (state, { payload }) => {
        state.allTables = RD.Success(payload.schema);
      })
      .addCase(fetchAllDataSourceTablesActions.errorAction, (state, { payload }) => {
        state.allTables = RD.Error(
          payload?.error_msg ||
            'Something went wrong and we were unable to connect to the data source.',
        );
      })
      .addCase(fetchAllDataSourceTables.rejected, (state, { error }) => {
        state.allTables = RD.Error(
          error.message || 'Something went wrong and we were unable to connect to the data source.',
        );
      })
      .addCase(fetchSupportedDataSourcesActions.requestAction, (state) => {
        state.supportedDataSources = RD.Loading();
      })
      .addCase(fetchSupportedDataSourcesActions.errorAction, (state) => {
        state.supportedDataSources = RD.Error('Error loading supported data sources');
      })
      .addCase(fetchSupportedDataSourcesActions.successAction, (state, { payload }) => {
        state.supportedDataSources = RD.Success(payload.supported_sources);
      })
      .addCase(listTeamDataSourcesActions.requestAction, (state) => {
        state.dataSources = RD.Loading();
      })
      .addCase(listTeamDataSourcesActions.errorAction, (state) => {
        state.dataSources = RD.Error('Error loading team data sources');
      })
      .addCase(listTeamDataSourcesActions.successAction, (state, { payload }) => {
        state.dataSources = RD.Success(sortBy(payload.dataSources, 'id'));
      })
      .addCase(syncSchemaThunk.fulfilled, (state, { payload }) => {
        state.dataSources = RD.Success(sortBy(payload.data_sources, 'id'));
      })
      .addCase(deleteDataSourceThunk.fulfilled, (state, { meta }) => {
        state.dataSources = RD.map(state.dataSources, (dataSources) =>
          dataSources.filter((ds) => ds.id !== meta.arg.id),
        );
      })
      .addCase(editDataSourceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.dataSources)) return;

        const edited = state.dataSources.data.find((dataSource) => dataSource.id === payload.id);
        if (!edited) return;

        if (payload.data_source.provided_id) edited.provided_id = payload.data_source.provided_id;
        if (payload.data_source.name) edited.name = payload.data_source.name;
        if (payload.data_source.user_viewable_credentials) {
          edited.user_viewable_credentials = payload.data_source.user_viewable_credentials;
        }
        if (payload.postData.access_group_ids) {
          edited.access_groups = payload.data_source.access_groups;
        }
      })
      .addCase(connectDataSourceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.dataSources)) return;

        state.dataSources.data.push(payload.data_source);
      })
      .addCase(createEmbeddoDataSource.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.dataSources)) return;
        state.dataSources.data.push(payload.data_source);
      });
  },
});

// This is used to get the list of team data sources in places where it does
// not care about the loading state
export const getTeamDataSources = createSelector(
  (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.fido.fidoDaos,
  (state: ReduxState) => state.dataSource.dataSources,
  (useFido, fidoDaos, dataSources) =>
    useFido
      ? RD.isSuccess(fidoDaos)
        ? fidoDaos.data.dataSources
        : []
      : RD.getOrDefault(dataSources, []),
);

export const dataSourceReducer = dataSourceSlice.reducer;
