import { FC } from 'react';

import * as styles from 'components/ds/Tabs/index.css';

import { Tooltip } from '../Tooltip';

type Props = { isSelected: boolean; tooltip?: string; onClick: () => void };

export const Tab: FC<Props> = ({ onClick, tooltip, isSelected, children }) => {
  const content = (
    <button className={isSelected ? styles.navTabActive : styles.navTabBase} onClick={onClick}>
      {children}
    </button>
  );

  if (!tooltip) return content;

  return (
    <Tooltip sideOffset={2} text={tooltip}>
      {content}
    </Tooltip>
  );
};
