import cx from 'classnames';
import { FC, forwardRef } from 'react';

import { sprinkles } from 'components/ds';
import { Sprinkles } from 'components/ds/sprinkles.css';

type HeadingType = { heading: Sprinkles['heading']; body?: never };
type BodyType = { body: Sprinkles['body']; heading?: never };

type Props = (HeadingType | BodyType) & {
  className?: string;
  color?: Sprinkles['color'];
};

export const EmbedText: FC<Props> = forwardRef<HTMLSpanElement, Props>(
  ({ children, className, heading, body, color = 'contentPrimary', ...props }, ref) => {
    return (
      <span className={cx(className, sprinkles({ heading, body, color }))} ref={ref} {...props}>
        {children}
      </span>
    );
  },
);

EmbedText.displayName = 'EmbedText';
