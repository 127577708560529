import Highcharts from 'highcharts';
import { FC, RefObject } from 'react';
import { useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { DATE_TYPES } from 'constants/dataConstants';
import { CustomMenuOptionConfig, V2TwoDimensionChartInstructions } from 'constants/types';
import { DashboardStates, ReportBuilderStates } from 'reducers/rootReducer';
import { DrilldownVariable } from 'types/dashboardTypes';

import { HighCharts } from '../highCharts';

import { DrilldownChartMenu } from './DrilldownChartMenu';

interface Props {
  instructions: V2TwoDimensionChartInstructions | undefined;
  chartOptions: Highcharts.Options | undefined;
  customMenuOptions: CustomMenuOptionConfig[] | undefined;
  dataPanelId: string;

  // Used for selecting categories in bar charts
  drilldownVar?: DrilldownVariable;
  setCategorySelect?: (category: string, colorColumn?: string) => void;

  // Used for opening drilldown modals
  underlyingDataEnabled?: boolean;
  selectedColorColName?: string;

  // Used for excluding categories when opening pie chart drilldown
  excludedCategories?: (string | number)[];

  drilldownRef?: RefObject<HTMLDivElement>;
}

export const DrilldownChart: FC<Props> = ({
  instructions,
  chartOptions,
  customMenuOptions,
  dataPanelId,
  selectedColorColName,
  setCategorySelect,
  excludedCategories,
  underlyingDataEnabled,
  drilldownVar,
  drilldownRef,
}) => {
  const chartMenuInfo = useSelector((state: DashboardStates | ReportBuilderStates) =>
    'dashboardLayout' in state ? state.dashboardLayout.chartMenu : null,
  );

  const renderChartMenu = () => {
    if (chartMenuInfo?.chartId !== dataPanelId) return null;

    // Block drilldown for other category for pie chart date columns
    const blockOtherDrilldownForDateType =
      chartMenuInfo.category === 'Other' &&
      DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') &&
      excludedCategories !== undefined;

    return (
      <DrilldownChartMenu
        canViewUnderlyingData={!!underlyingDataEnabled && !blockOtherDrilldownForDateType}
        chartMenuInfo={chartMenuInfo}
        customMenuOptions={customMenuOptions}
        dataPanelId={dataPanelId}
        drilldownVar={drilldownVar}
        excludedCategories={excludedCategories}
        instructions={instructions}
        selectedColorColName={selectedColorColName}
        setCategorySelect={blockOtherDrilldownForDateType ? undefined : setCategorySelect}
      />
    );
  };

  return (
    <div
      className={sprinkles({ position: 'relative', parentContainer: 'fill' })}
      ref={drilldownRef}>
      <HighCharts chartOptions={chartOptions} />
      {renderChartMenu()}
    </div>
  );
};
