import { DatepickerElemConfig } from 'types/dashboardTypes';
import { DATE_RANGE_TYPES } from 'types/dateRangeTypes';
import { getDateMax, getDateMin } from 'utils/dateUtils';

export function getConfigDateLimits(config: DatepickerElemConfig, timezone: string) {
  return config.dateRangeType === DATE_RANGE_TYPES.EXACT
    ? {
        minDate: config.minValue,
        maxDate: config.maxValue,
      }
    : {
        minDate: getDateMin(config.relativeDateRange, timezone),
        maxDate: getDateMax(config.relativeDateRange, timezone),
      };
}
