import cx from 'classnames';
import { useEffect, useState } from 'react';

import ElementGridLayout, {
  PassedProps as ElementGridLayoutProps,
} from 'components/DashboardLayout/ElementGridLayout';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';
import { sprinkles } from 'components/ds';
import { DASHBOARD_ROW_HEIGHT, NUM_MOBILE_COLUMNS } from 'constants/dashboardConstants';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { ContainerElemConfig, VIEW_MODE } from 'types/dashboardTypes';
import { compactLayout, getLayoutFromContainerConfig } from 'utils/layoutUtils';

type Props = {
  elementGridLayoutProps: ElementGridLayoutProps;
  id: string;
  config: ContainerElemConfig;
  isResizing: boolean;
  isMobileView?: boolean;
  viewMode: VIEW_MODE;
} & WithWidthProps;

function DashboardContainerElement({
  elementGridLayoutProps,
  id,
  config,
  isResizing,
  isMobileView,
  viewMode,
  width,
}: Props) {
  const [savedWidth, setSavedWidth] = useState(width);
  /**
   * We don't want to change the width if we are resizing the container so we don't see it jump
   * around when we're finished resizing. We want child elements to stay in place.
   */
  useEffect(() => {
    if (!isResizing) setSavedWidth(width);
  }, [width, isResizing]);

  const layout = getLayoutFromContainerConfig(config, viewMode);

  const layoutElement = elementGridLayoutProps.dashboardLayout.find((l) => l.i === id);

  return (
    <div
      className={cx(
        sprinkles({ parentContainer: 'fill', borderRadius: 8, overflowY: 'hidden' }),
        embedSprinkles({
          backgroundColor: 'containerFill',
          padding: 'container',
          borderRadius: 'container',
        }),
        GLOBAL_STYLE_CLASSNAMES.container.outline.border,
        GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
      )}
      style={{ paddingTop }}>
      <ElementGridLayout
        {...elementGridLayoutProps}
        columns={isMobileView ? NUM_MOBILE_COLUMNS : layoutElement?.w}
        containerId={id}
        containerLayout={layoutElement}
        dashboardLayout={isMobileView ? compactLayout(layout) : layout}
        width={isResizing ? savedWidth : width}
      />
    </div>
  );
}

export default withWidth(DashboardContainerElement);

/**
 * The container takes up an extra row, which minus the 1px border on each side means there will be
 * ROW_HEIGHT - 2 - MARGIN px of extra space on the bottom. By adding this top padding, the content will be
 * vertically centered when the container height is minimized.
 */
const MARGIN = 12;
const paddingTop = (DASHBOARD_ROW_HEIGHT - 2 - MARGIN) / 2;
