import { useEffect } from 'react';

export const useCustomEvent = <T extends keyof WindowEventMap>(
  event: T,
  callback: (args: WindowEventMap[T]) => void,
) => {
  useEffect(() => {
    // Web component
    const receiveEvent: EventListener = (e) => {
      callback(e as WindowEventMap[T]);
    };

    // Iframe (also web component)
    const receiveMessage = (e: MessageEvent) => {
      if (e.data.event !== event) return;
      callback(e.data);
    };

    window.addEventListener(event, receiveEvent);
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener(event, receiveEvent);
      window.removeEventListener('message', receiveMessage);
    };
  }, [callback, event]);
};
