import { Layout } from '@explo-tech/react-grid-layout';

export const COL_UPPER_BOUND = 9999;

export const resolveSecondaryLayout = (
  primaryLayout: Layout[],
  secondaryLayout: Layout[],
  addElemFullWidth?: boolean,
) => {
  const { addedElemIds, removedElemIds } = getChangedLayoutElements(primaryLayout, secondaryLayout);

  if (addedElemIds.size === 0 && removedElemIds.size === 0) return secondaryLayout;

  const addedElements = getElementsById(primaryLayout, addedElemIds);

  let newLayout = secondaryLayout;

  // remove elements first in case it shifts where to add the new elements
  if (removedElemIds.size > 0)
    newLayout = removeElementsFromLayoutById(secondaryLayout, removedElemIds);

  addedElements.forEach((elem) => {
    newLayout = addElementToLayout(secondaryLayout, elem, addElemFullWidth);
  });

  return newLayout;
};

export const getChangedLayoutElements = (primaryLayout: Layout[], secondaryLayout: Layout[]) => {
  const primaryLayoutIds = new Set(primaryLayout.map((elem) => elem.i));
  const secondaryLayoutIds = new Set(secondaryLayout.map((elem) => elem.i));

  const addedElemIds = new Set<string>();
  const removedElemIds = new Set<string>();

  primaryLayoutIds.forEach((id) => {
    if (!secondaryLayoutIds.has(id)) addedElemIds.add(id);
  });

  secondaryLayoutIds.forEach((id) => {
    if (!primaryLayoutIds.has(id)) removedElemIds.add(id);
  });

  return {
    addedElemIds,
    removedElemIds,
  };
};

export const getElementsById = (layout: Layout[], elementIds: Set<string>) => {
  return layout.filter((elem) => elementIds.has(elem.i));
};

export const removeElementsFromLayoutById = (layout: Layout[], elementIds: Set<string>) => {
  return layout.filter((elem) => !elementIds.has(elem.i));
};

export const addElementToLayout = (
  layout: Layout[],
  element: Layout,
  addElemFullWidth?: boolean,
) => {
  const lowerXBound = addElemFullWidth ? 0 : element.x;
  const upperXBound = addElemFullWidth ? COL_UPPER_BOUND : element.x + element.w - 1;
  const maxHeightAboveItem = getLayoutHeightInRows(layout, lowerXBound, upperXBound);

  const elemToAdd = {
    ...element,
    y: maxHeightAboveItem,
    x: addElemFullWidth ? 0 : element.x,
    // setting width to the "infinity" will make it default to the max width of the dashboard
    // which can be variable so we can't hard code it
    w: addElemFullWidth ? COL_UPPER_BOUND : element.w,
  };

  return layout.concat([elemToAdd]);
};

export const getLayoutHeightInRows = (
  layout: Layout[],
  lowerXBound?: number,
  upperXBound?: number,
) => {
  return layout.reduce((prevMax, elem) => {
    if (elem.i === 'null') return prevMax;

    if (lowerXBound !== undefined && upperXBound !== undefined) {
      const elementStartX = elem.x;
      const elementEndX = elem.x + elem.w - 1;
      const elementDoesNotIntersect = elementStartX > upperXBound || elementEndX < lowerXBound;

      if (elementDoesNotIntersect) {
        return prevMax;
      }
    }

    return Math.max(prevMax, elem.h + elem.y);
  }, 0);
};
