import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';

import { ConnectDataSourceStep, CONNECT_STEPS_ORDER } from './constants';

type Props = {
  currentStep: ConnectDataSourceStep;
};

export const ConnectStepByStep: FC<Props> = ({ currentStep }) => {
  const currentStepIds = CONNECT_STEPS_ORDER.indexOf(currentStep);

  return (
    <div>
      <div className={sprinkles({ textAlign: 'right', marginBottom: 'sp3', heading: 'h3' })}>
        Connecting your database
      </div>
      <div className={sprinkles({ flexItems: 'column', alignItems: 'flex-end' })}>
        {CONNECT_STEPS_ORDER.map((step, index) => (
          <Step
            includeStem={index !== CONNECT_STEPS_ORDER.length - 1}
            isCompleted={index < currentStepIds}
            isCurrStep={index === currentStepIds}
            key={step}
            name={step}
          />
        ))}
      </div>
    </div>
  );
};

type StepProps = {
  name: string;
  includeStem: boolean;
  isCompleted: boolean;
  isCurrStep: boolean;
};

const Step: FC<StepProps> = ({ name, isCompleted, isCurrStep, includeStem }) => {
  const highlighted = isCurrStep || isCompleted;

  return (
    <div className={sprinkles({ flexItems: 'column', alignItems: 'flex-end' })}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <div
          className={sprinkles({
            body: 'b3',
            color: highlighted ? 'contentPrimary' : 'contentSecondary',
          })}>
          {name}
        </div>
        <Icon
          className={sprinkles({ color: highlighted ? 'green9' : 'gray7' })}
          name="circle-check"
          style={{ opacity: isCurrStep ? 0.4 : undefined }}
        />
      </div>
      {includeStem ? (
        <div
          className={sprinkles({
            marginRight: 'sp1',
            backgroundColor: isCompleted ? 'green9' : 'gray7',
          })}
          style={{ width: 2, height: 40 }}
        />
      ) : null}
    </div>
  );
};
