import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';
import { Checkbox, Input } from 'components/ds';
import { LinkFormat, VisualizeOperationGeneralFormatOptions } from 'constants/types';

type Props = {
  options: VisualizeOperationGeneralFormatOptions;
};

export const LinkConfig: FC<Props> = ({ options }) => {
  const dispatch = useDispatch();
  const linkFormat = options?.linkFormat ?? {};

  const updateGeneralFormat = (updates: LinkFormat) => {
    const newOptions = produce(options, (draft) => {
      draft.linkFormat = { ...draft.linkFormat, ...updates };
    });

    dispatch(updateGeneralFormatOptions(newOptions));
  };

  return (
    <ConfigSubSectionWithSwitch
      label="Link to a URL"
      onChange={() => updateGeneralFormat({ link: !linkFormat.link })}
      switchOn={!!linkFormat.link}>
      <Input
        defaultValue={linkFormat.url ?? ''}
        label={{
          text: 'URL',
          variableInput: true,
          infoText:
            'Links without http:// or https:// will be treated as relative links and will navigate using the domain that the embed is on. Relative links do not work when using iframe to embed dashboard.',
        }}
        onSubmit={(newValue) => updateGeneralFormat({ url: newValue })}
      />
      <Checkbox
        isChecked={!!linkFormat.openInNewTab}
        label="Open in a new tab"
        onChange={() => updateGeneralFormat({ openInNewTab: !linkFormat.openInNewTab })}
      />
    </ConfigSubSectionWithSwitch>
  );
};
