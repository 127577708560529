import { CellProps } from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';
import { FC } from 'react';

import { Sprinkles, sprinkles } from 'components/ds/sprinkles.css';

type Props = Omit<CellProps, 'onClick'> & {
  alignment: Sprinkles['justifyContent'];
  friendlyName?: string;
  onClick?: (id: string | number) => void;
};

export const ColumnHeader: FC<Props> = ({ alignment, friendlyName, id, name, onClick }) => {
  const handleClick = () => {
    if (!onClick || id === undefined) return;
    onClick(id);
  };

  return (
    <div
      className={cx(containerStyle, sprinkles({ justifyContent: alignment }))}
      onClick={handleClick}
      style={{ display: 'flex' }}>
      <div className={sprinkles({ truncateText: 'ellipsis' })}>{friendlyName || name}</div>
    </div>
  );
};

const containerStyle = sprinkles({
  flexItems: 'alignCenter',
  width: 'fill',
  overflow: 'hidden',
  gap: 'sp.5',
});
