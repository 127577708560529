import { FocusStyleManager } from '@blueprintjs/core';
import { ThemeProvider } from '@material-ui/core/styles';
import 'globalthis/auto'; // Polyfill for Radix on browsers without globalThis
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { initDataDog } from 'analytics/datadog';
import { baseThemeName, APP_PORTAL_ID } from 'components/ds';

import App from './App';
import { store } from './store/store';
import theme from './theme';

initDataDog();
FocusStyleManager.onlyShowFocusOnTabs();

const MainApp = () => {
  return (
    <div
      className={baseThemeName}
      id={APP_PORTAL_ID}
      style={{ fontFamily: 'Inter, sans-serif', lineHeight: 'normal' }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? ''}>
              <App />
            </GoogleOAuthProvider>
          </DndProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
