import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataSource } from 'actions/dataSourceActions';
import { Icon, Spinner, Tag, Menu, MenuActionItem } from 'components/ds';
import { dataSourceByOldType, dataSourceByType, DefaultIcon } from 'constants/dataSourceConstants';
import { ROUTES } from 'constants/routes';
import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import {
  setDataSourceType,
  setInitialConfigForUpdate,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { clearTablePreview } from 'reducers/fidoReducer';
import { ReduxState } from 'reducers/rootReducer';

import { ManageDataSourceSideSheet } from './modals/ManageDataSourceSideSheet';
import * as styles from './styles.css';

type Props = {
  dataSource: DataSource;
  deleteLoading: boolean;
  accessGroupNames: string[];
  schemaHasOneAccessGroup: boolean;
  userCanEdit?: boolean;
  onDelete?: () => void;
  onSyncTables?: () => void;
};

export const DataSourceItem: FC<Props> = ({
  dataSource,
  deleteLoading,
  accessGroupNames,
  schemaHasOneAccessGroup,
  userCanEdit,
  onDelete,
  onSyncTables,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidesheetOpen, setIsSideSheetOpen] = useState(false);
  const useFido = useSelector(
    (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  );

  const dataSourceImg =
    (useFido && dataSource.fido_id ? dataSourceByType : dataSourceByOldType)[dataSource.source_type]
      ?.datasourceIconImg || DefaultIcon;

  const isDefaultForAccessGroup = accessGroupNames.length > 0;

  const renderDefaultTags = () => {
    if (!isDefaultForAccessGroup) return null;
    else {
      return accessGroupNames.map((accessGroupName) => (
        <Tag key={accessGroupName}>
          {schemaHasOneAccessGroup ? 'Default' : accessGroupName + ' default'}
        </Tag>
      ));
    }
  };

  const renderDropdown = () => {
    if (!userCanEdit && !onDelete) return null;
    return (
      <Menu
        align="end"
        isOpen={isDropdownOpen}
        onOpenChange={(open) => {
          if (!deleteLoading) setIsDropdownOpen(open);
        }}
        trigger={
          deleteLoading ? (
            <div className={styles.rightIcon}>
              <Spinner size="md" />
            </div>
          ) : (
            <div className={styles.schemaEllipsisMenu}>
              <Icon name="ellipsis-vertical" />
            </div>
          )
        }
        width="small">
        {userCanEdit ? (
          <MenuActionItem
            onSelect={() => {
              setIsDropdownOpen(false);
              setIsSideSheetOpen(true);
              if (useFido && dataSource.fido_id) {
                dispatch(setDataSourceType(dataSource.source_type as DATABASES));
                dispatch(
                  setInitialConfigForUpdate({
                    config: dataSource.user_viewable_credentials,
                    type: dataSource.source_type as DATABASES,
                  }),
                );
              }
            }}
            text="Edit"
          />
        ) : null}
        {onDelete ? (
          <MenuActionItem
            disabled={isDefaultForAccessGroup}
            onSelect={() => {
              if (isDefaultForAccessGroup) return;
              setIsDropdownOpen(false);
              onDelete();
            }}
            text="Delete"
            tooltipProps={
              isDefaultForAccessGroup ? { text: 'Cannot delete a default data source' } : undefined
            }
          />
        ) : null}
      </Menu>
    );
  };

  return (
    <div className={styles.dataSourceItemContainer}>
      <div
        className={styles.dataSourceItemContentContainer}
        onClick={() => {
          if (useFido && dataSource.fido_id) {
            dispatch(clearTablePreview());
          }
          if (onSyncTables) onSyncTables();
          else history.push(`${ROUTES.DATA_PAGE}/${dataSource.id}`);
        }}>
        <div className={styles.dataSourceIconNameContainer}>
          <div className={styles.dataSourceLogoContainer}>
            <img
              alt={`${dataSource.name} logo`}
              className={styles.dataSourceLogo}
              src={dataSourceImg}
            />
          </div>
          <div className={styles.dataSourceNameIdContainer}>
            <p className={styles.primaryText}>{dataSource.name}</p>
            <p className={styles.secondaryText}>
              {dataSource.provided_id ?? String(dataSource.id)}
            </p>
          </div>
        </div>
        <div className={styles.defaultDBTagContainer}>{renderDefaultTags()}</div>
      </div>
      {renderDropdown()}
      {isSidesheetOpen ? (
        <ManageDataSourceSideSheet
          dataSource={dataSource}
          isOpen={isSidesheetOpen}
          setIsOpen={setIsSideSheetOpen}
        />
      ) : null}
    </div>
  );
};
