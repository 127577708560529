import { enableMapSet } from 'immer';
import Cookies, { CookieAttributes } from 'js-cookie';
import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollToTop } from 'react-router-scroll-to-top';

import 'index.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';
import { sendPing } from 'actions/pingActions';
import { setUser } from 'analytics/datadog';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { NAV_ITEMS } from 'components/Sidebar/constants';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import PrivateRoute from 'components/privateRoute';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ROUTES } from 'constants/routes';
import { PingTypes } from 'constants/types';
import { DEFAULT_GLOBAL_STYLE_CONFIG, GlobalStylesContext } from 'globalStyles';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import ConnectDataSourceFlow from 'pages/ConnectDataSourceFlow/ConnectDataSourceFlow';
import { DashboardPreviewPage } from 'pages/DashboardPreviewPage';
import { DataPage } from 'pages/DataPage';
import EmailDashboardPage from 'pages/EmailDashboardPage';
import { ExportReportBuilderPage } from 'pages/ExportReportBuilderPage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { CustomizeStylesPage } from 'pages/GlobalCustomStylesPage';
import IframeDashboardPage from 'pages/IframeDashboardPage';
import { IframeReportBuilderPage } from 'pages/IframeReportBuilderPage';
import { JoinTeamPage } from 'pages/JoinTeamPage';
import PdfDashboardPage from 'pages/PdfDashboardPage';
import { ReportBuilderEditorPage } from 'pages/ReportBuilderEditor';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { SamlInitiateAuthPage } from 'pages/SamlInitiateAuthPage';
import { SamlSignInPage } from 'pages/SamlSignInPage';
import SharedChartPage from 'pages/SharedChartPage';
import { SignInPage } from 'pages/SignInPage';
import { SignUpPage } from 'pages/SignUpPage';
import SuperuserPage from 'pages/SuperuserPage';
import { SyncDataTablesPage } from 'pages/SyncDataTablesPage';
import { UnsubscribeReportBuilderEmailPage } from 'pages/UnsubscribeReportBuilderEmailPage';
import { AnalyticsPage } from 'pages/analyticsPage';
import { CheckYourEmailPage } from 'pages/checkYourEmailPage';
import { PortalBasePage } from 'pages/customerPortal/portalBasePage';
import { CustomersPage } from 'pages/customersPage/customersPage';
import { DashboardPageWrapper } from 'pages/dashboardPage/dashboardPageWrapper';
import { EmbedPageComponent } from 'pages/embedPage/embedPageComponent';
import HomeAppPage from 'pages/homeAppPage/homeAppPage';
import { ManageDataTablesPageWrapper } from 'pages/manageDataTablesPage/manageDataTablesPageWrapper';
import NotionAnalyticsPage from 'pages/notionAnalyticsPage';
import { ReportBuilderListPage } from 'pages/reportBuilderListPage';
import { SettingsPage } from 'pages/settingsPage/settingsPage';
import SharedDashboardPage from 'pages/sharedDashboardPage';
import TrialExpiredPage from 'pages/trialExpiredPage';
import { VerifyEmailPage } from 'pages/verifyEmailPage';
import { AllStates } from 'reducers/rootReducer';
import { isExternalFacingDashboard } from 'utils/environmentUtils';
import {
  redirectIfInvalidUrl,
  pingCustomerOnlineMessage,
  pingUserWithoutTeamMessage,
} from 'utils/landingPageUtils';
import { isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { canUserModifyResource, doesUserHavePermission } from 'utils/permissionUtils';
import { isExploSuperuser } from 'utils/superuserUtils';

import { logOutUser } from './actions/authAction';
import { logInUserSuccess } from './actions/userActions';
import { fetchProfile } from './auth/userAuth';

enableMapSet();

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type State = {
  loading: boolean;
};

class Routes extends Component<Props, State> {
  state = {
    loading: true,
  };

  componentDidMount() {
    redirectIfInvalidUrl();

    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;
    if (siteTitleElement && window.location.href.indexOf('/share/') === -1) {
      // don't want to set this for shareDashboardPage before setting share page title in that component
      siteTitleElement.innerHTML = 'Explo | Build customer-facing dashboards, lightning fast';
    }

    if (isExternalFacingDashboard()) {
      this.setState({ loading: false });
    } else {
      fetchProfile(
        (user) => {
          this.props.logInUserSuccess(user);

          setUser({
            email: user.email,
            teamId: user.team?.id,
            teamName: user.team?.team_name,
          });

          this.props.sendPing({
            postData: {
              message: pingCustomerOnlineMessage(user),
              message_type:
                user.team?.payment_plan === PLAN_TYPES.LAUNCH
                  ? PingTypes.PING_ONLINE_LAUNCH
                  : PingTypes.PING_ONLINE,
            },
          });
          if (!user.team) {
            this.props.sendPing({
              postData: {
                message: pingUserWithoutTeamMessage(user),
                message_type: PingTypes.PING_USER_WITHOUT_TEAM,
              },
            });
          }

          this.setState({ loading: false });
        },
        () => {
          this.props.logOutUser();
          this.setState({ loading: false });
        },
      );
    }
  }

  render() {
    Cookies.defaults = COOKIE_OPTION_DEFAULTS;
    const renderLoadingComponent = () => (
      <div
        className={sprinkles({ height: 'fillViewport', overflowY: 'auto' })}
        style={{ perspective: '1px' }}>
        <div className={sprinkles({ flexItems: 'center', height: 'fillViewport' })}>
          {isExternalFacingDashboard() ? (
            <EmbedSpinner fillContainer size="xl" />
          ) : (
            <ExploLoadingSpinner />
          )}
        </div>
      </div>
    );

    return this.state.loading ? (
      renderLoadingComponent()
    ) : (
      <GlobalStylesContext.Provider
        value={{
          globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
          globalStyleVars: getGlobalStyleVars(DEFAULT_GLOBAL_STYLE_CONFIG),
        }}>
        <Router>
          <ScrollToTop />
          <div className="explo-routes">
            <Switch>
              <Route
                exact
                component={() => <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />}
                path={ROUTES.HOME}
              />
              <Route exact component={VerifyEmailPage} path={ROUTES.VERIFY_EMAIL} />
              <Route exact component={SamlSignInPage} path={ROUTES.SAML_SIGN_IN} />
              <Route exact component={SamlInitiateAuthPage} path={ROUTES.SAML_INITIATE_AUTH} />
              <Route exact component={SignUpPage} path={ROUTES.SIGN_UP} />
              <Route exact component={IframeDashboardPage} path={ROUTES.IFRAME} />
              <Route exact component={SharedDashboardPage} path={ROUTES.SHARED_DASHBOARD} />
              <Route exact component={SharedDashboardPage} path={ROUTES.SHARED_DASHBOARD_STRICT} />
              <Route
                exact
                component={IframeReportBuilderPage}
                path={ROUTES.REPORT_BUILDER_IFRAME}
              />
              <Route
                exact
                component={ExportReportBuilderPage}
                path={ROUTES.REPORT_BUILDER_EXPORT}
              />
              <Route
                exact
                component={UnsubscribeReportBuilderEmailPage}
                path={ROUTES.UNSUBSCRIBE_REPORT_BUILDER_EMAIL}
              />
              <Route exact component={PortalBasePage} path={ROUTES.END_USER_PORTAL} />
              <Route exact component={SharedChartPage} path={ROUTES.SHARE_CHART} />
              <Route exact component={PdfDashboardPage} path={ROUTES.PDF_DASHBOARD} />
              <Route exact component={EmailDashboardPage} path={ROUTES.EMAIL_DASHBOARD} />
              <Route exact component={NotionAnalyticsPage} path={ROUTES.NOTION_ANALYTICS} />
              <Route exact component={ForgotPasswordPage} path={ROUTES.FORGOT_PASSWORD} />
              <Route exact component={ResetPasswordPage} path={ROUTES.RESET_PASSWORD} />
              <PrivateRoute exact pageComponent={SignInPage} path={ROUTES.LOGIN} />
              <PrivateRoute exact pageComponent={TrialExpiredPage} path={ROUTES.TRIAL_EXPIRED} />
              <PrivateRoute
                exact
                pageComponent={CheckYourEmailPage}
                path={ROUTES.CHECK_YOUR_EMAIL}
              />
              <PrivateRoute exact pageComponent={JoinTeamPage} path={ROUTES.JOIN_TEAM} />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={HomeAppPage}
                path={ROUTES.HOME_APP_PAGE}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={HomeAppPage}
                path={ROUTES.HOME_APP_PAGE_FOLDER}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={HomeAppPage}
                path={ROUTES.HOME_APP_PAGE}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={DashboardPageWrapper}
                path={ROUTES.DASHBOARD_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                pageComponent={DashboardPreviewPage}
                path={ROUTES.DASHBOARD_PREVIEW_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.CUSTOMERS.id}
                pageComponent={CustomersPage}
                path={ROUTES.CUSTOMERS_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              {/* TODO permissions */}
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.ANALYTICS.id}
                pageComponent={AnalyticsPage}
                path={ROUTES.ANALYTICS}
              />
              <PrivateRoute
                exact
                withNavigation
                pageComponent={ConnectDataSourceFlow}
                path={ROUTES.CONNECT_DATA_SOURCE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.CREATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={SyncDataTablesPage}
                path={ROUTES.SYNC_DATA_TABLES}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.UPDATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={SyncDataTablesPage}
                path={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.UPDATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={DataPage}
                path={ROUTES.DATA_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={ManageDataTablesPageWrapper}
                path={ROUTES.MANAGE_DATA_TABLES}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                pageComponent={SettingsPage}
                path={ROUTES.SETTINGS_PAGE}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.STYLES.id}
                pageComponent={CustomizeStylesPage}
                path={ROUTES.GLOBAL_CUSTOM_STYLES_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.TEAM],
                    PERMISSIONED_ACTIONS.UPDATE_CUSTOM_STYLES,
                  )
                }
              />
              {/* TODO permissions */}
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderListPage}
                path={ROUTES.REPORT_BUILDER}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderListPage}
                path={ROUTES.REPORT_BUILDER}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderListPage}
                path={ROUTES.REPORT_BUILDER_FOLDER}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderEditorPage}
                path={ROUTES.REPORT_BUILDER_EDITOR}
                permissionFn={(user) =>
                  isReportBuilderEnabled(user) &&
                  canUserModifyResource(user.permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER])
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.SUPERUSER.id}
                pageComponent={SuperuserPage}
                path={ROUTES.SUPERUSER}
                permissionFn={(user) => isExploSuperuser(user)}
              />
              <PrivateRoute
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={EmbedPageComponent}
                path={ROUTES.EMBED_REFERENCE}
              />
              <PrivateRoute noMatch pageComponent={HomeAppPage} />
            </Switch>
          </div>
        </Router>
      </GlobalStylesContext.Provider>
    );
  }
}

const COOKIE_OPTION_DEFAULTS: CookieAttributes = {
  secure: true,
  sameSite: 'Strict',
};

const mapStateToProps = (state: AllStates) => ({
  currentUser: 'currentUser' in state ? state.currentUser : undefined,
});

const mapDispatchToProps = {
  logInUserSuccess,
  logOutUser,
  sendPing,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
