import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedButton, EmbedInput } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = {
  name: string | undefined;

  onNext: () => void;
  updateReportName: (newName: string) => void;
};

export const NameReportStep: FC<Props> = ({ name, onNext, updateReportName }) => {
  return (
    <div
      className={cx(
        sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <div className={cx(sprinkles({ fontWeight: 600 }), embedSprinkles({ heading: 'h2' }))}>
        Name your report
      </div>
      <EmbedInput
        showInputButton
        className={sprinkles({ marginTop: 'sp2', marginBottom: 'sp3' })}
        defaultValue={name}
        onSubmit={updateReportName}
        placeholder="Report name"
      />
      <EmbedButton onClick={onNext} variant="primary">
        Next
      </EmbedButton>
    </div>
  );
};
