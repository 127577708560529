import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch } from 'components/ds';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  visualizationType: string;
};

export const FormatTimeSeriesDataConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();

  return (
    <div className={configRootClass}>
      <Switch
        className={configInputClass}
        label="Hide data from latest period"
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.chartSpecificFormat = {
              ...draft.chartSpecificFormat,
              timeSeriesDataFormat: {
                ...draft?.chartSpecificFormat?.timeSeriesDataFormat,
                hideLatestPeriodData:
                  !instructions.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData,
              },
            };
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        switchOn={instructions.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData}
      />
    </div>
  );
};
