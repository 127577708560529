import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { EmbedFetchShareData, embedFetchShareData } from 'actions/embedActions';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { EmbedSpinner } from 'components/embed';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  shareId: string;
};

export default function EmailDashboardPage() {
  const dispatch = useDispatch();

  const [shareData, setShareData] = useState<EmbedFetchShareData | null>(null);

  const {
    params: { shareId },
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId }, (shareData) => {
        setShareData(shareData);
      }),
    );
  }, [dispatch, shareId]);

  if (!shareData) return <EmbedSpinner fillContainer size="xl" />;

  return (
    <EmbeddedDashboard
      customerToken={shareData.customer_token}
      dashboardEmbedId={shareData.dashboard_embed_id}
      embedType="email"
      versionNumber={shareData.version_number}
      viewMode={VIEW_MODE.EMAIL}
    />
  );
}
