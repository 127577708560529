import { FC } from 'react';

import { Icon } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert.css';
import { CHART_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';

interface RemovedDataAlertProps {
  name: string;
  numRemoved: number;
  onDismiss: () => void;
  visualizationName: string;
}

export const RemovedDataAlert: FC<RemovedDataAlertProps> = ({
  name,
  numRemoved,
  onDismiss,
  visualizationName,
}) => {
  return (
    <div className={styles.infoStyle}>
      <Icon className={styles.iconInfoStyle} name="circle-info" />
      <EmbedText body="b2">
        {numRemoved} {name}
        {numRemoved === 1 ? '' : 's'} removed to fit {visualizationName}
      </EmbedText>
      <EmbedButton onClick={onDismiss} variant="tertiary">
        Dismiss
      </EmbedButton>
    </div>
  );
};

type AtLeastOneAlertProps = {
  name: string;
  visualizationName: string;
};

export const AtLeastOneAlert: FC<AtLeastOneAlertProps> = ({ visualizationName, name }) => {
  return (
    <div className={styles.warningStyle}>
      <Icon className={styles.iconWarningStyle} name="circle-exclamation" />
      <EmbedText body="b2">
        Add at least one {name} to populate {visualizationName}
      </EmbedText>
    </div>
  );
};

type NoDataAlertProps = {
  name: string;
};

export const NoDataAlert: FC<NoDataAlertProps> = ({ name }) => {
  return (
    <EmbedText body="b2" className={styles.noDataAlert}>
      No {name}s
    </EmbedText>
  );
};

type RowLimitProps = {
  rowCount: number;
};

export const RowLimit: FC<RowLimitProps> = ({ rowCount }) => {
  return (
    <div className={styles.infoStyle}>
      <Icon className={styles.iconInfoStyle} name="circle-info" />
      <EmbedText body="b2">
        {CHART_ROW_LIMIT} rows out of {rowCount} are being displayed for performance
      </EmbedText>
    </div>
  );
};
