import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { forwardRef } from 'react';

import * as styles from './index.css';

export type MenuItemProps = {
  /**
   * When true, menu item has destructive consequences and styling.
   */
  isDestructive?: boolean;
  /**
   * When true, prevents the user from interacting with the item.
   */
  disabled?: boolean;
  /**
   * action text
   */
  text: string;
  /**
   * Event handler called when the user selects an item (via mouse or keyboard).
   * Calling event.preventDefault in this handler will prevent the dropdown menu
   * from closing when selecting that item.
   */
  onSelect?: (e: Event) => void;
};

export const MenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(function MenuItemCustom(
  { className, onSelect, ...props },
  ref,
) {
  return (
    <DropdownMenu.Item
      {...props}
      className={cx(styles.menuItem, className)}
      onSelect={(e) => {
        e.preventDefault();
        onSelect?.(e);
      }}
      ref={ref}
    />
  );
});
