import { FC } from 'react';

import { sprinkles, Icon } from 'components/ds';

type Props = { url: string };

export const DocsLink: FC<Props> = ({ url, children }) => {
  return (
    <a
      className={docsContainerClass}
      href={url}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
      target="_blank">
      <div className={sprinkles({ color: 'contentPrimary' })}>{children}</div>
      <div className={sprinkles({ flexItems: 'alignCenter', color: 'active', gap: 'sp1' })}>
        Docs
        <Icon name="book-blank" />
      </div>
    </a>
  );
};

const docsContainerClass = sprinkles({
  height: 32,
  backgroundColor: 'brandPrimaryLight',
  borderRadius: 8,
  flexItems: 'alignCenterBetween',
  padding: 'sp1.5',
  fontSize: 14,
  fontWeight: 500,
});
