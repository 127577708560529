import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { createFilterThunk } from 'reportBuilderContent/thunks/reportThunks';
import { FilterableColumn } from 'utils/customerReportUtils';

type Props = { columns: FilterableColumn[] };

export const AddFilterButton: FC<Props> = ({ columns }) => {
  const dispatch = useDispatch();

  const menuOptions: DropdownOption[] = useMemo(
    () =>
      columns.map((col) => ({
        name: col.display,
        icon: SCHEMA_TYPE_ICON_MAP[col.type],
        value: col.name,
        onClick: () =>
          dispatch(
            createFilterThunk({
              column: { name: col.name, type: col.type },
              isPostFilter: col.isPostFilter,
            }),
          ),
      })),
    [columns, dispatch],
  );

  if (columns.length === 0) return null;

  return (
    <EmbeddedDropdownMenu canSearch align="start" menuOptions={menuOptions}>
      <EmbedButton icon="plus" variant="secondary">
        Filter
      </EmbedButton>
    </EmbeddedDropdownMenu>
  );
};
