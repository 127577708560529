import { createSlice } from '@reduxjs/toolkit';

import * as RD from 'remotedata';
import {
  GenerateReportBuilderViewAIJobResponse,
  GenerateReportBuilderViewSuggestionsAIJobResponse,
} from 'reportBuilderContent/apiTypes';
import {
  fetchChatAiViewError,
  fetchChatAiViewRequest,
  fetchChatAiViewSuccess,
  fetchGenerateAiViewError,
  fetchGenerateAiViewRequest,
  fetchGenerateAiViewSuccess,
  fetchGenerateAiViewSuggestionError,
  fetchGenerateAiViewSuggestionRequest,
  fetchGenerateAiViewSuggestionSuccess,
} from 'reportBuilderContent/thunks/aiThunks';

import { AIReducerMessage } from './types';

interface ReportAiReducerState {
  datasetId: string;
  messages: AIReducerMessage[];
  conversation: RD.ResponseData<string>;
  canGenerateView: boolean;
  generatedView: RD.ResponseData<GenerateReportBuilderViewAIJobResponse>;
  chartSuggestions: Partial<
    Record<string, RD.ResponseData<GenerateReportBuilderViewSuggestionsAIJobResponse>>
  >;
}

const initialState: ReportAiReducerState = {
  datasetId: '',
  generatedView: RD.Idle(),
  chartSuggestions: {},
  canGenerateView: false,
  messages: [],
  conversation: RD.Idle(),
};

const reportAiReducerSlice = createSlice({
  name: 'ai',
  initialState: initialState,
  reducers: {
    resetMessages(state) {
      state.messages = [];
      state.generatedView = RD.Idle();
      state.conversation = RD.Idle();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatAiViewSuccess, (state, { payload }) => {
        state.messages.push({
          role: 'assistant',
          content: payload.content,
          ...(payload.chart_type ? { metadata: { chartType: payload.chart_type } } : {}),
        });
        state.conversation = RD.Success(payload.content);
      })
      .addCase(fetchChatAiViewError, (state, { payload }) => {
        state.conversation = RD.Error(payload.error);
      })
      .addCase(fetchChatAiViewRequest, (state, action) => {
        const messages = action.payload.postData.messages;
        if (messages.length === 0) return;

        state.messages.push(messages[messages.length - 1]);
        state.conversation = RD.Loading();
        state.generatedView = RD.Idle();
      })
      .addCase(fetchGenerateAiViewSuccess, (state, { payload }) => {
        state.generatedView = RD.Success(payload);
      })
      .addCase(fetchGenerateAiViewError, (state, { payload }) => {
        state.generatedView = RD.Error(payload.error);
      })
      .addCase(fetchGenerateAiViewRequest, (state) => {
        state.generatedView = RD.Loading();
      })
      .addCase(
        fetchGenerateAiViewSuggestionSuccess,
        (state, { payload: { jobArgs, ...payload } }) => {
          state.chartSuggestions[jobArgs.datasetId] = RD.Success(payload);
        },
      )
      .addCase(fetchGenerateAiViewSuggestionError, (state, { payload }) => {
        state.chartSuggestions[payload.job.jobArgs.datasetId] = RD.Error(payload.error);
      })
      .addCase(fetchGenerateAiViewSuggestionRequest, (state, { payload: { postData } }) => {
        state.datasetId = postData.datasetId;
        state.chartSuggestions[postData.datasetId] = RD.Loading();
      });
  },
});

export const reportAiReducer = reportAiReducerSlice.reducer;

export const { resetMessages } = reportAiReducerSlice.actions;
