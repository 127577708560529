import { Icon } from '@blueprintjs/core';

import {
  BOOLEAN,
  DATE,
  DATE_TYPES,
  DECIMAL_TYPES,
  NUMBER_TYPES,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';
import {
  ColumnWidths,
  LegacyWidths,
  DisplayOptions,
  NumberDisplayOptions,
  NumberDisplayDisplayType,
} from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { formatValue } from 'pages/dashboardPage/charts/utils';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { DatasetColumn, DatasetSchema, DatabaseUnsupportedOperations } from 'types/datasets';
import { formatTime, TIME_FORMATS } from 'utils/localizationUtils';
import { times } from 'utils/standard';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';

export const DEFAULT_COL_WIDTH = 100;
const EXTRA_WIDTH_PADDING = 10;

/**
 * @description Generates an array of column widths for Blueprint table
 * @param schema {DatasetSchema} - schema for the table
 * @param columnWidths {ColumnWidths} - object with column widths
 *
 * @returns {Array} Array has values from new columnWidths object and undefined for any columns that aren't provided.
 */
export function getLegacyColumnWidths(
  schema: DatasetSchema,
  columnWidths?: ColumnWidths | LegacyWidths,
): LegacyWidths {
  const numColumns = schema.length;

  // Remove when blueprint table is gone
  if (Array.isArray(columnWidths)) {
    if (numColumns > columnWidths.length) {
      // if columns are added, then we need `columnWidths` variable will have less entries than
      // the # of columns. In that case, Blueprint will crash. Prevent that by padding the array
      // with undefined which defaults the col width for the new columns
      return columnWidths.concat(times(numColumns - columnWidths.length, () => undefined));
    } else {
      return columnWidths.slice(0, numColumns);
    }
  }

  return schema.map((column) => {
    const width = columnWidths?.[column.name];
    return width ? width : undefined;
  });
}

export function getDefaultColumnWidth(
  numColumns: number,
  tableWidth: number,
  hardcodedColumnWidths: LegacyWidths,
): number {
  let currentWidth = tableWidth - EXTRA_WIDTH_PADDING;
  let colsHardcoded = 0;
  hardcodedColumnWidths?.forEach((colWidth) => {
    if (colWidth) {
      currentWidth -= colWidth;
      colsHardcoded += 1;
    }
  });
  if (colsHardcoded >= numColumns) return DEFAULT_COL_WIDTH;
  return Math.max(currentWidth / (numColumns - colsHardcoded), DEFAULT_COL_WIDTH);
}

export function defaultFormatCellData(
  cellData: string | number,
  column: DatasetColumn,
  isDashboardTable?: boolean,
  ignoreInvalidDates?: boolean,
  dateFormat?: string,
) {
  if (DATE_TYPES.has(column.type)) {
    let dataObj = getTimezoneAwareDate(cellData as string);
    if (!dataObj.isValid) {
      return ignoreInvalidDates ? '' : 'Invalid Date';
    }
    if (!isDashboardTable) dataObj = dataObj.toLocal();

    const format =
      dateFormat ??
      (column.type === DATE ? TIME_FORMATS['MM/DD/YYYY'] : TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)']);

    return formatTime(dataObj, format);
  } else if (column.type === BOOLEAN && ['true', 'false'].includes(String(cellData))) {
    return (
      <Icon
        className={embedSprinkles({ color: 'action' })}
        icon={String(cellData) === 'true' ? 'tick' : 'cross'}
      />
    );
  } else if (DECIMAL_TYPES.has(column.type)) {
    return formatValue({
      value: Number(cellData),
      decimalPlaces: 2,
      formatId: V2_NUMBER_FORMATS.NUMBER.id,
      hasCommas: false,
    });
  } else if (NUMBER_TYPES.has(column.type)) {
    return formatValue({
      value: Number(cellData),
      decimalPlaces: 0,
      formatId: V2_NUMBER_FORMATS.NUMBER.id,
      hasCommas: false,
    });
  }
  return String(cellData);
}

export const isTablePagingEnabled = (
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined,
) => !(unsupportedOperations ?? []).includes(DatabaseUnsupportedOperations.PAGINATION);

export const getPaginationDisabledText = (isEmbed: boolean) =>
  isEmbed
    ? 'Pagination is disabled'
    : 'This is a truncated view of this table because this data source does not support pagination';

export const getCellAlignment = (displayOptions: DisplayOptions | undefined, type: string) => {
  const alignment = displayOptions?.alignment;
  // Handle case of manually set alignment
  if (alignment) return alignment;

  if (displayOptions) {
    const { displayType } = displayOptions as NumberDisplayOptions;
    if (displayType === NumberDisplayDisplayType.PROGRESS_BAR)
      // Handle progress bar as a special case
      return TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;
  }

  // Handle cases with no set alignment by data type
  if (NUMBER_TYPES.has(type) || DATE_TYPES.has(type)) {
    return TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT;
  }
  if (type === BOOLEAN) return TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;
  return TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT;
};
