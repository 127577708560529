import cx from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { listTeamDataSources } from 'actions/dataSourceActions';
import {
  EmbedSecret,
  RawEmbedSecret,
  createEmbedSecret,
  deleteEmbedSecret,
  fetchEmbedSecrets,
} from 'actions/embedSecretActions';
import { fetchAllSchemaTables, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import {
  AccessGroupCustomerCountsData,
  createAccessGroup,
  createApiToken,
  deleteAccessGroup,
  deleteApiToken,
  fetchAccessGroupCustomerCounts,
  refreshApiToken,
  updateAccessGroups,
} from 'actions/rolePermissionActions';
import { AccessGroup, ApiToken, fetchUserTeam } from 'actions/teamActions';
import { InfoCard } from 'components/InfoCard';
import {
  AlertModal,
  Button,
  IconButton,
  Input,
  Menu,
  MenuActionItem,
  Tag,
  Tooltip,
  sprinkles,
  CalloutLink,
} from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { ReduxState } from 'reducers/rootReducer';
import { getNamespaces } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { showErrorToast } from 'shared/sharedToasts';
import { isCreateDataVisibilityGroupsDisabled } from 'utils/paymentPlanUtils';
import { groupBy } from 'utils/standard';

import { SettingsAccessGroupsApiTokenSection } from './settingsAccessGroupsApiTokenSection';
import { SettingsAccessGroupsSchemaSection } from './settingsAccessGroupsSchemaSection';
import * as styles from './settingsAccessGroupsSection.css';
import { SettingsCreatedEmbedSecretModal } from './settingsCreatedEmbedSecretModal';
import { SettingsEmbedSecretsSection } from './settingsEmbedSecretsSection';

export const SettingsAccessGroupsSection: FC = () => {
  const dispatch = useDispatch();

  const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
  const [confirmCreateAccessGroupModalOpen, setConfirmCreateAccessGroupModalOpen] = useState(false);
  const [hasConfirmedAccessGroupCreation, setHasConfirmedAccessGroupCreation] = useState(false);
  const [apiConfirmationModalOpen, setApiConfirmationModalOpen] = useState(false);
  const [accessGroupIdBeingEdited, setAccessGroupIdBeingEdited] = useState(-1);
  const [deleteAccessGroupConfirmationModalOpen, setDeleteAccessGroupConfirmationModalOpen] =
    useState(false);
  const [selectedApiToken, setSelectedApiToken] = useState(undefined as ApiToken | undefined);
  const [apiTokenAction, setApiTokenAction] = useState(
    undefined as 'delete' | 'refresh' | undefined,
  );
  const [selectedAccessGroup, setSelectedAccessGroup] = useState(
    undefined as AccessGroup | undefined,
  );
  const [selectedEmbedSecret, setSelectedEmbedSecret] = useState<EmbedSecret | undefined>();
  const [deleteEmbedSecretModalOpen, setDeleteEmbedSecretModalOpen] = useState(false);
  const [createdEmbedSecret, setCreatedEmbedSecret] = useState<RawEmbedSecret | undefined>();
  const [accessGroupCustomerCounts, setAccessGroupCustomerCounts] = useState<
    AccessGroupCustomerCountsData | undefined
  >();

  const { team, dataSources, parentSchemas, embedSecrets, fidoDaos, useFido, schemaTablesMap } =
    useSelector(
      (state: ReduxState) => ({
        team: state.teamData.data,
        dataSources: state.dataSource.dataSources,
        parentSchemas: state.parentSchemas.usedParentSchemas,
        schemaTablesMap: state.parentSchemas.schemaTablesMap,
        embedSecrets: state.embedSecrets.embedSecrets,
        fidoDaos: state.fido.fidoDaos,
        useFido: state.currentUser.team?.feature_flags.use_fido,
      }),
      shallowEqual,
    );

  useEffect(() => {
    dispatch(getNamespaces());
  }, [dispatch, useFido, parentSchemas, dataSources]);

  useEffect(() => {
    if (RD.isIdle(schemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, schemaTablesMap]);

  useEffect(() => {
    if (!accessGroupCustomerCounts)
      dispatch(
        fetchAccessGroupCustomerCounts(undefined, (data) =>
          setAccessGroupCustomerCounts(data.access_groups),
        ),
      );
  }, [accessGroupCustomerCounts, dispatch]);
  useEffect(() => {
    if (!team) dispatch(fetchUserTeam());
  }, [team, dispatch]);
  useEffect(() => {
    if (RD.isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dataSources, dispatch]);
  useEffect(() => {
    if (RD.isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [parentSchemas, dispatch]);
  useEffect(() => {
    if (RD.isIdle(embedSecrets)) dispatch(fetchEmbedSecrets());
  }, [dispatch, embedSecrets]);

  const dataSourcesBySchemaId = useMemo(
    () => groupBy(RD.getOrDefault(dataSources, []), (dataSource) => dataSource.parent_schema_id),
    [dataSources],
  );

  const fidoDataSourcesBySchemaId = useMemo(
    () => groupBy(RD.isSuccess(fidoDaos) ? fidoDaos.data.dataSources : [], 'parent_schema_id'),
    [fidoDaos],
  );

  const namespaces = useMemo(
    () => (RD.isSuccess(fidoDaos) ? fidoDaos.data.namespaces : []),
    [fidoDaos],
  );

  const embedSecretByAccessGroupId = useMemo(
    () => groupBy(RD.getOrDefault(embedSecrets, []), (embedSecret) => embedSecret.access_group_id),
    [embedSecrets],
  );

  if (!team) return <></>;

  const accessGroups = team.access_groups;

  const renderApiTokenConfirmationModal = () => {
    if (!apiConfirmationModalOpen || !selectedApiToken) return <></>;

    const closeModal = () => {
      setApiConfirmationModalOpen(false);
      setSelectedApiToken(undefined);
      setApiTokenAction(undefined);
    };

    const onConfirm = () => {
      closeModal();
      apiTokenAction === 'refresh'
        ? dispatch(refreshApiToken({ postData: { token_id: selectedApiToken.id } }))
        : dispatch(deleteApiToken({ postData: { token_id: selectedApiToken.id } }));
    };

    const isTokenRefresh = apiTokenAction === 'refresh';

    return (
      <AlertModal
        actionButtonProps={{
          text: isTokenRefresh ? 'Refresh' : 'Delete',
          onClick: onConfirm,
          variant: isTokenRefresh ? 'primary' : 'destructive',
        }}
        isOpen={apiConfirmationModalOpen}
        onClose={closeModal}
        title={`Are you sure you want to ${isTokenRefresh ? 'refresh' : 'delete'} this API token?`}
      />
    );
  };

  const renderDeleteAccessGroupConfirmationModal = () => {
    if (
      !deleteAccessGroupConfirmationModalOpen ||
      !selectedAccessGroup ||
      !accessGroupCustomerCounts
    )
      return <></>;

    const [bodyText, confirmButtonText] =
      accessGroupCustomerCounts[selectedAccessGroup.id] ?? 0 === 0
        ? [undefined, 'Delete']
        : [
            'This visibility group has end user groups associated with it and will cause errors if deleted.',
            'Delete Anyway',
          ];

    const closeModal = () => {
      setDeleteAccessGroupConfirmationModalOpen(false);
      setSelectedAccessGroup(undefined);
    };
    const onConfirm = () => {
      closeModal();
      dispatch(deleteAccessGroup({ postData: { access_group_id: selectedAccessGroup.id } }));
    };
    return (
      <AlertModal
        actionButtonProps={{ text: confirmButtonText, onClick: onConfirm }}
        isOpen={deleteAccessGroupConfirmationModalOpen}
        onClose={closeModal}
        title="Are you sure you want to delete this visibility group?">
        {bodyText}
      </AlertModal>
    );
  };

  const renderCreateAccessGroupModal = () => (
    <TextFieldModal
      buttonName="Create"
      closeModal={() => {
        setHasConfirmedAccessGroupCreation(false);
        setCreateAccessGroupModalOpen(false);
      }}
      modalOpen={createAccessGroupModalOpen}
      modalTitle="Create a Visibility Group"
      onSubmit={(name) =>
        dispatch(
          createAccessGroup({ postData: { name } }, undefined, (error) => {
            showErrorToast(error.detail, 7);
          }),
        )
      }
      textFieldPlaceholder="e.g. Developers"
    />
  );

  const renderConfirmCreateAccessGroupModal = () => (
    <AlertModal
      actionButtonProps={{
        text: 'Create Visibility Group',
        onClick: () => {
          setHasConfirmedAccessGroupCreation(true);
          setCreateAccessGroupModalOpen(true);
        },
        variant: 'primary',
      }}
      isOpen={confirmCreateAccessGroupModalOpen}
      onClose={() => setConfirmCreateAccessGroupModalOpen(false)}
      title="Are you sure you want to create another Visibility Group?">
      Managing multiple visibility groups will require you to separately define which customers and
      datasets should be available for each visibility group
    </AlertModal>
  );

  const renderDeleteEmbedSecretConfirmationModal = () => {
    if (!deleteEmbedSecretModalOpen || !selectedEmbedSecret) return null;

    const closeModal = () => {
      setDeleteEmbedSecretModalOpen(false);
      setSelectedEmbedSecret(undefined);
    };

    const onConfirm = () => {
      closeModal();
      dispatch(deleteEmbedSecret({ id: selectedEmbedSecret.id }));
    };

    return (
      <AlertModal
        actionButtonProps={{
          text: 'Delete',
          onClick: onConfirm,
          variant: 'destructive',
        }}
        isOpen={deleteEmbedSecretModalOpen}
        onClose={closeModal}
        title="Are you sure you want to delete this secret?">
        Any embedded dashboards using this secret will no load properly.
      </AlertModal>
    );
  };

  const boldedWord = (word: string) => <span className={styles.bold}>{word}</span>;

  const visibilityGroupHeader = (accessGroup: AccessGroup) => {
    return (
      <div className={styles.accessGroupNameContainer}>
        {accessGroupIdBeingEdited !== accessGroup.id ? (
          <div className={styles.accessGroupName}>{accessGroup.name}</div>
        ) : (
          <Input
            fillWidth
            className={styles.accessGroupName}
            defaultValue={accessGroup.name}
            onSubmit={(newValue) => {
              if (newValue.trim() === '') return;
              const new_access_groups = [{ access_group_id: accessGroup.id, name: newValue }];
              dispatch(updateAccessGroups({ postData: { access_groups: new_access_groups } }));
              setAccessGroupIdBeingEdited(-1);
            }}
          />
        )}
        <Menu trigger={<IconButton name="ellipsis-vertical" />} width="small">
          <MenuActionItem
            onSelect={() => setAccessGroupIdBeingEdited(accessGroup.id)}
            text="Rename"
          />
          <MenuActionItem
            isDestructive
            disabled={accessGroups.length === 1}
            onSelect={() => {
              setSelectedAccessGroup(accessGroup);
              setDeleteAccessGroupConfirmationModalOpen(true);
            }}
            text="Delete"
          />
        </Menu>
      </div>
    );
  };

  const isDataVisibilityButtonGroupsDisabled = isCreateDataVisibilityGroupsDisabled(
    accessGroups.length,
    team.payment_plan,
  );

  return (
    <div>
      <div className={styles.title}>Data Visibility</div>
      <div className={styles.subtitle}>
        Visibility groups configure your embedded experience. Use API tokens to control who can
        access and edit your data, generate embed secrets to securely embed your dashboards, and
        manage your team&rsquo;s access to data sources here.
      </div>
      {accessGroups.length === 1 ? (
        <CalloutLink
          className={sprinkles({ marginBottom: 'sp1' })}
          text="Learn more about how Visibility Groups can be used"
          url="https://docs.explo.co/managing-permissions/visibility-groups"
        />
      ) : (
        <>
          <InfoCard
            className={styles.customerInfo}
            leftIcon="user-group"
            text={
              <>
                Reassign customers to a new visibility group from the {boldedWord('Customers')} tab.
              </>
            }
          />
          <InfoCard
            className={styles.defaultInfo}
            leftIcon="database"
            text={<>Assign default data sources on the {boldedWord('Data')} tab.</>}
          />
        </>
      )}
      {accessGroups.map((accessGroup) => {
        return (
          <div className={styles.accessGroupContainer} key={accessGroup.id}>
            {visibilityGroupHeader(accessGroup)}
            <div className={styles.divider} />
            <div className={styles.sectionName}>API Tokens</div>
            <div>
              {accessGroup.api_tokens.map((apiToken) => (
                <SettingsAccessGroupsApiTokenSection
                  apiToken={apiToken}
                  key={apiToken.id}
                  setApiTokenAction={setApiTokenAction}
                  setConfirmationModalOpen={() => setApiConfirmationModalOpen(true)}
                  setSelectedApiToken={() => setSelectedApiToken(apiToken)}
                />
              ))}
            </div>
            <Button
              className={styles.addButton}
              icon="plus"
              onClick={() =>
                dispatch(createApiToken({ postData: { access_group_id: accessGroup.id } }))
              }
              variant="primary">
              Add an API Token
            </Button>
            <div className={styles.divider} />
            <div className={cx(styles.sectionName, styles.embedSecretsSection)}>
              Embed Secrets
              <Tooltip side="right" text="Click to learn more">
                <a
                  href="https://docs.explo.co/embedding-documentation/embed-secrets"
                  rel="noreferrer"
                  target="_blank">
                  <Tag leftIcon="circle-question-reg" />
                </a>
              </Tooltip>
            </div>
            <div>
              {(embedSecretByAccessGroupId[accessGroup.id] ?? []).map((secret) => (
                <SettingsEmbedSecretsSection
                  embedSecret={secret}
                  key={secret.id}
                  setDeleteModalOpen={() => setDeleteEmbedSecretModalOpen(true)}
                  setSelectedEmbedSecret={() => setSelectedEmbedSecret(secret)}
                />
              ))}
            </div>
            <Button
              className={styles.addButton}
              icon="plus"
              onClick={() =>
                dispatch(
                  createEmbedSecret(
                    { postData: { access_group_id: accessGroup.id } },
                    ({ embed_secret: secret }) => setCreatedEmbedSecret(secret),
                  ),
                )
              }
              variant="primary">
              Create an Embed Secret
            </Button>
            <div className={styles.divider} />
            <div className={styles.sectionName}>Data Source Access</div>
            <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
              {(useFido ? namespaces : RD.getOrDefault(parentSchemas, [])).map((schema) => (
                <SettingsAccessGroupsSchemaSection
                  accessGroup={accessGroup}
                  dataSources={
                    useFido
                      ? fidoDataSourcesBySchemaId[schema.id]
                      : dataSourcesBySchemaId[schema.id]
                  }
                  defaultDataSources={accessGroup.default_data_source_ids}
                  key={schema.id}
                  scheme={schema}
                />
              ))}
            </div>
          </div>
        );
      })}
      <Button
        disabled={isDataVisibilityButtonGroupsDisabled}
        icon="plus"
        onClick={() => {
          hasConfirmedAccessGroupCreation || accessGroups.length > 1
            ? setCreateAccessGroupModalOpen(true)
            : setConfirmCreateAccessGroupModalOpen(true);
        }}
        tooltipProps={
          isDataVisibilityButtonGroupsDisabled
            ? { text: 'Upgrade your plan to add more data visibility groups.' }
            : undefined
        }
        variant="primary">
        Add a Visibility Group
      </Button>
      {renderApiTokenConfirmationModal()}
      {renderConfirmCreateAccessGroupModal()}
      {renderCreateAccessGroupModal()}
      {renderDeleteAccessGroupConfirmationModal()}
      {renderDeleteEmbedSecretConfirmationModal()}
      {renderDeleteEmbedSecretConfirmationModal()}
      <SettingsCreatedEmbedSecretModal
        closeModal={() => setCreatedEmbedSecret(undefined)}
        embedSecret={createdEmbedSecret}
      />
    </div>
  );
};
