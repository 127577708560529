import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { FilterClause } from 'constants/types';
import { updateFilterConditionalConfig } from 'reducers/dashboardEditConfigReducer';
import DropdownSelect from 'shared/DropdownSelect';

const ConditionalOption = { id: 'conditional', name: 'Conditional on...' };
const ConstantOption = { id: 'constant', name: 'Constant' };

const ConditionalFilterOptions = [ConstantOption, ConditionalOption];

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  operatorDropdownClass: string;
};

export const ConditionalFilterMenu: FC<Props> = ({ clauseIdx, operatorDropdownClass, clause }) => {
  const dispatch = useDispatch();

  const isConditional = !!clause.conditionalFilterConfig?.isConditional;

  return (
    <DropdownSelect
      fillWidth
      minimal
      usePortal
      containerClassName={operatorDropdownClass}
      filterable={false}
      onChange={(newValue) =>
        dispatch(
          updateFilterConditionalConfig({
            idx: clauseIdx,
            config: { ...clause, isConditional: newValue.id === ConditionalOption.id },
          }),
        )
      }
      options={ConditionalFilterOptions}
      selectedItem={isConditional ? ConditionalOption : ConstantOption}
    />
  );
};
