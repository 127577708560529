import { FC } from 'react';

import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Input, Switch } from 'components/ds';
import { NUMBER_FORMATS_WITH_DECIMALS, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { KPIValueFormat, OPERATION_TYPES, KPI_NUMBER_TREND_OPERATION_TYPES } from 'constants/types';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';

import { TimeFormatConfig } from './TimeFormatConfig';

type Props = {
  configInputClass: string;
  valueFormat: KPIValueFormat;
  operationType:
    | OPERATION_TYPES.VISUALIZE_NUMBER_V2
    | OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    | OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2
    | OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL;
  updateValueFormat: (generalFormat: KPIValueFormat) => void;
};

export const SharedValueFormatConfig: FC<Props> = ({
  configInputClass,
  operationType,
  valueFormat,
  updateValueFormat,
}) => {
  const selectedFormat = valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedAlignment = valueFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  return (
    <>
      <NumberFormatToggle
        className={configInputClass}
        label="Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateValueFormat({ numberFormat: { id: numberFormat } })}
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <TimeFormatConfig
          configInputClass={configInputClass}
          updateFormat={updateValueFormat}
          valueFormat={valueFormat}
        />
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.significantDigits ?? 3)}
          label="Significant Digits"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(valueFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(valueFormat?.multiplyFactor ?? 1)}
        label="Multiplier"
        onSubmit={(newValue) => {
          const floatValue = parseFloat(newValue);
          updateValueFormat({
            multiplyFactor: isNaN(floatValue) ? undefined : floatValue,
          });
        }}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={valueFormat?.units}
        label="Unit"
        onSubmit={(newValue) => updateValueFormat({ units: newValue })}
      />
      <Switch
        className={configInputClass}
        label="Include Unit Padding"
        onChange={() => updateValueFormat({ unitPadding: !valueFormat?.unitPadding })}
        switchOn={valueFormat?.unitPadding}
      />
      {operationType !== OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ? (
        <>
          <Switch
            className={configInputClass}
            label="Bold Value"
            onChange={() => updateValueFormat({ bold: !valueFormat?.bold })}
            switchOn={valueFormat?.bold}
          />
          <Switch
            className={configInputClass}
            label="Italicize Value"
            onChange={() => updateValueFormat({ italic: !valueFormat?.italic })}
            switchOn={valueFormat?.italic}
          />
          {operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL ? (
            <HorizontalAlignmentToggle
              className={configInputClass}
              selectedAlignment={selectedAlignment}
              updateAlignment={(horizAlignment) => updateValueFormat({ horizAlignment })}
            />
          ) : null}
        </>
      ) : null}
      {!KPI_NUMBER_TREND_OPERATION_TYPES.has(operationType) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={valueFormat?.imageUrl}
          label={{ text: 'Image to Display Next to Value', variableInput: true }}
          onSubmit={(newValue) => updateValueFormat({ imageUrl: newValue })}
          placeholder="Image URL"
        />
      ) : null}
    </>
  );
};
