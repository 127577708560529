import { FC } from 'react';

import { Input } from 'components/ds';
import {
  DASHBOARD_ELEMENT_TYPES,
  SelectElemConfig,
  SELECT_FILTER_TYPE,
} from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SelectElemConfig;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig) => void;
};

export const SelectAdjustDisplayConfig: FC<Props> = ({
  config,
  selectType,
  updateSelectConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  return selectType !== DASHBOARD_ELEMENT_TYPES.TOGGLE ? (
    <Input
      showInputButton
      className={styles.configInput}
      defaultValue={config.placeholderText}
      label="Dropdown Placeholder"
      onSubmit={(newValue) =>
        updateConfig(
          (draft) => (draft.placeholderText = newValue.trim() === '' ? undefined : newValue),
        )
      }
      placeholder="Enter a placeholder"
    />
  ) : null;
};
