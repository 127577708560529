import produce from 'immer';
import { FC } from 'react';

import { InfoCard } from 'components/InfoCard';
import { Button, sprinkles } from 'components/ds';
import {
  ColorFormat,
  ColorZone,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { DEFAULT_PALETTE_COLOR } from 'pages/dashboardPage/charts/utils';
import { uniqueId } from 'utils/standard';

import { ColorZoneList } from './ColorZonesList';

type Props = {
  configInputClass?: string;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions;

  updateColorFormat: (colorFormat: ColorFormat) => void;
};

export const ColorZonesConfig: FC<Props> = ({
  configInputClass,
  instructions,
  updateColorFormat,
}) => {
  const colorFormat = instructions.colorFormat;
  const colorZones = colorFormat?.colorZones || [];

  const updateZoneAtIndex = (index: number, updateZone: (colorZoneDraft: ColorZone) => void) => {
    updateColorFormat({
      colorZones: produce(colorZones, (draft) => {
        updateZone(draft[index]);
      }),
    });
  };

  return (
    <div className={configInputClass}>
      <InfoCard
        className={sprinkles({ marginBottom: 'sp1' })}
        text="Entries should be sorted from lowest to highest. For each zone, the threshold defines the upper-bound of when that color will be used"
      />
      {colorZones.length ? (
        <ColorZoneList
          colorZones={colorZones}
          updateColorZones={(zones) => updateColorFormat({ colorZones: zones })}
          updateZoneAtIndex={updateZoneAtIndex}
        />
      ) : null}

      <Button
        fillWidth
        icon="plus"
        onClick={() => {
          const newColorZone = {
            zoneColor: DEFAULT_PALETTE_COLOR,
            zoneId: uniqueId('color-zone'),
          };
          updateColorFormat({ colorZones: colorZones.concat([newColorZone]) });
        }}
        variant="secondary">
        Add Zone
      </Button>
    </div>
  );
};
