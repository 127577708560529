import { FC } from 'react';

import * as styles from './index.css';

export type Props = {
  children: string;
  categoryColor?: string;
};

export const EmbedCategoryTag: FC<Props> = ({ categoryColor, children }) => {
  return (
    <div className={styles.cellCategory}>
      <div className={styles.categoryTag} style={{ backgroundColor: categoryColor }} />
      {children}
    </div>
  );
};
