import { FC } from 'react';

import { Modal, sprinkles } from 'components/ds';

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  onSync: () => void;
};

export const SyncTablesModal: FC<Props> = ({ modalOpen, closeModal, onSync }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      primaryButtonProps={{ onClick: onSync, text: 'Sync Tables' }}
      secondaryButtonProps={{ onClick: closeModal, text: 'Not Yet' }}
      size="small"
      title="Unsynced Tables">
      <div className={sprinkles({ paddingX: 'sp3' })}>
        The database you&rsquo;re trying to access hasn&rsquo;t be synced yet. To view the tables
        you must first sync the databases in this schema.
      </div>
    </Modal>
  );
};
