import { FC } from 'react';

import { Input } from 'components/ds';

type Props = {
  containerClassName?: string;
  label?: string;
  hideLabels?: boolean; // when there are multiple in a row, want to hide redundant labels
  exactValue?: string;
  disabled?: boolean;
  updateExactValue: (newValue: string) => void;
};

export const ExactValueInput: FC<Props> = ({
  containerClassName,
  label,
  hideLabels,
  exactValue,
  disabled,
  updateExactValue,
}) => {
  return (
    <Input
      className={containerClassName}
      defaultValue={String(exactValue ?? '1')}
      disabled={disabled}
      label={!hideLabels ? { text: label ?? 'Value', variableInput: true } : undefined}
      onSubmit={(newValue) => updateExactValue(newValue.trim())}
    />
  );
};
