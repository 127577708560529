import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Updater } from 'use-immer';

import {
  fetchCustomersFromIds,
  fetchGroupTags,
  fetchSelectorCustomers,
} from 'actions/customerActions';
import { Customer } from 'actions/teamActions';
import { Button, Icon, IconButton, Switch, sprinkles, Select, Spinner } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { SelectItems } from 'components/ds/Select';
import { CUSTOMER_SELECTOR_QUERY_PARAMS } from 'constants/customerConstants';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { CustomerPermissionsForObject } from 'types/permissionTypes';

import * as styles from './styles.css';

type Props = {
  permissions: CustomerPermissionsForObject;
  setPermissions: Updater<CustomerPermissionsForObject>;
};

export const CustomerPermissionsSetter: FC<Props> = ({ permissions, setPermissions }) => {
  const dispatch = useDispatch();

  const [newCustomerId, setNewCustomerId] = useState<number | null>(null);
  const [newGroupId, setNewGroupId] = useState<number | null>(null);
  const [permissionedCustomers, setPermissionedCustomers] = useState<Customer[] | null>(null);

  const { groupTags, selectorCustomers, selectorCustomersStatus } = useSelector(
    (state: ReduxState) => ({
      selectorCustomers: state.customers.selectorCustomers,
      selectorCustomersStatus: state.customers.selectorCustomersStatus,
      groupTags: state.customers.groupTags,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchSelectorCustomers({ queryParams: CUSTOMER_SELECTOR_QUERY_PARAMS }));
  }, [dispatch]);

  useEffect(() => {
    if (RD.isIdle(groupTags)) dispatch(fetchGroupTags());
  }, [dispatch, groupTags]);

  useEffect(() => {
    if (permissionedCustomers === null)
      dispatch(
        fetchCustomersFromIds(
          {
            postData: { ids: permissions.customerIds },
          },
          (customers) => {
            setPermissionedCustomers(customers.results);
          },
        ),
      );
  }, [dispatch, permissionedCustomers, permissions]);

  const onFilter = useCallback(
    (searchString: string) =>
      dispatch(
        fetchSelectorCustomers({
          queryParams: {
            ...CUSTOMER_SELECTOR_QUERY_PARAMS,
            search_string: searchString || undefined,
          },
        }),
      ),
    [dispatch],
  );

  const groupOptions = useMemo(() => {
    if (!RD.isSuccess(groupTags)) return [];

    const filterGroupTagSet = new Set(permissions.groupTagIds);
    const options: SelectItems<string> = [];

    groupTags.data.forEach((tag) => {
      if (filterGroupTagSet.has(tag.id)) return;
      // ID can be the same between a group and a customer so need to prepend
      options.push({
        value: tag.id.toString(),
        label: tag.name,
        icon: 'users',
      });
    });

    return options;
  }, [groupTags, permissions.groupTagIds]);

  const options = useMemo(() => {
    const filterCustomerSet = new Set(permissions.customerIds);
    const options: SelectItems<string> = [];

    selectorCustomers.forEach((customer) => {
      if (filterCustomerSet.has(customer.id)) return;
      options.push({
        value: customer.id.toString(),
        label: customer.name || String(customer.id),
        icon: 'user',
      });
    });

    return options;
  }, [selectorCustomers, permissions.customerIds]);

  const selectedCustomer = useMemo(() => {
    if (!newCustomerId) return;

    return options.find((opt) => opt.value === newCustomerId.toString());
  }, [options, newCustomerId]);

  const selectedGroup = useMemo(() => {
    if (!newGroupId) return;

    return options.find((opt) => opt.value === newGroupId.toString());
  }, [options, newGroupId]);

  const selectedGroupTags = useMemo(() => {
    if (!RD.isSuccess(groupTags) || !permissions.groupTagIds?.length) return [];
    const groupTagIdSet = new Set(permissions.groupTagIds);
    return groupTags.data.filter((tag) => groupTagIdSet.has(tag.id));
  }, [groupTags, permissions.groupTagIds]);

  const renderListItem = (
    name: string,
    id: string | undefined,
    iconName: IconName,
    onDelete: () => void,
  ) => {
    return (
      <div className={customerListItemClass} key={`${iconName}-${id ?? name}`}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', body: 'b2' })}>
          <Icon name={iconName} size="sm" /> {name}{' '}
          {id !== undefined ? (
            <span className={sprinkles({ color: 'contentSecondary' })}>({id})</span>
          ) : null}
        </div>
        <IconButton name="cross" onClick={onDelete} variant="secondary" />
      </div>
    );
  };

  const notVisibleToAnyCustomer =
    permissionedCustomers && permissionedCustomers.length === 0 && selectedGroupTags.length === 0;
  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <Switch
        className={styles.switchStyle}
        label="Visible to all customers"
        onChange={() => {
          setPermissions((draft) => {
            draft.allCustomers = !draft.allCustomers;
          });
        }}
        switchOn={permissions.allCustomers}
      />

      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', width: 'fill' })}>
        <div className={sprinkles({ flex: 1 })}>
          <Select
            disabled={!RD.isSuccess(groupTags)}
            onChange={(id) => {
              const itemId = parseInt(id);
              if (isNaN(itemId)) return;
              setNewGroupId(itemId);
            }}
            placeholder="Select group tag"
            selectedValue={selectedGroup?.value ?? undefined}
            side="bottom"
            values={groupOptions}
          />
        </div>
        <Button
          disabled={!newGroupId || permissionedCustomers === null}
          onClick={() => {
            if (!newGroupId) return;
            setPermissions((draft) => {
              draft.allCustomers = false;
              if (!draft.groupTagIds) {
                draft.groupTagIds = [newGroupId];
                return;
              }

              const groupIdx = draft.groupTagIds.indexOf(newGroupId);
              if (groupIdx === -1) draft.groupTagIds.push(newGroupId);
              else draft.groupTagIds.splice(groupIdx, 1);
            });
            setNewGroupId(null);
          }}
          variant="primary">
          Add
        </Button>
      </div>

      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', width: 'fill' })}>
        <div className={sprinkles({ flex: 1 })}>
          <Select
            filterProps={{
              placeholder: 'Filter for more customers',
              selectedLabel: selectedCustomer?.label,
              onFilter,
              isLoading: RD.isLoading(selectorCustomersStatus),
            }}
            onChange={(id) => {
              const itemId = parseInt(id);
              if (isNaN(itemId)) return;
              setNewCustomerId(itemId);
            }}
            placeholder="Select customer"
            selectedValue={selectedCustomer?.value ?? undefined}
            side="bottom"
            values={options}
          />
        </div>
        <Button
          disabled={!newCustomerId || permissionedCustomers === null}
          onClick={() => {
            if (!newCustomerId) return;
            setPermissions((draft) => {
              draft.allCustomers = false;
              const customerIdx = draft.customerIds.indexOf(newCustomerId);
              if (customerIdx === -1) draft.customerIds.push(newCustomerId);
              else draft.customerIds.splice(customerIdx, 1);
            });
            const customer = selectorCustomers.find((c) => c.id === newCustomerId);
            customer &&
              permissionedCustomers &&
              setPermissionedCustomers([...permissionedCustomers, customer]);

            setNewCustomerId(null);
          }}
          variant="primary">
          Add
        </Button>
      </div>
      <div
        className={sprinkles({
          flexItems: permissions.allCustomers || notVisibleToAnyCustomer ? 'center' : 'column',
          gap: 'sp1',
          overflow: 'auto',
        })}
        style={{ minHeight: 200 }}>
        {permissions.allCustomers ? (
          <div className={emptyCustomerTextClass}>Visible to all customers</div>
        ) : notVisibleToAnyCustomer ? (
          <div className={emptyCustomerTextClass}>Not visible to any customers</div>
        ) : (
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            {permissionedCustomers === null ? (
              <Spinner fillContainer />
            ) : (
              <>
                {selectedGroupTags.map((tag) =>
                  renderListItem(tag.name, undefined, 'users', () =>
                    setPermissions((draft) => {
                      draft.groupTagIds = draft.groupTagIds?.filter((id) => id !== tag.id);
                    }),
                  ),
                )}
                {permissionedCustomers.map((customer) =>
                  renderListItem(customer.name, customer.provided_id, 'user', () => {
                    setPermissions((draft) => {
                      draft.customerIds = draft.customerIds.filter((id) => id !== customer.id);
                    });
                    setPermissionedCustomers((draft) =>
                      (draft ?? []).filter((c) => c.id !== customer.id),
                    );
                  }),
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const emptyCustomerTextClass = sprinkles({
  height: 'fill',
  flexItems: 'center',
  body: 'b2',
  color: 'contentTertiary',
});

const customerListItemClass = sprinkles({
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
});
