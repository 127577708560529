import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { BreadcrumbsHierarchyItem } from 'components/ds/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import { Breadcrumb, ResourcePageType } from 'types/exploResource';

export function useBreadcrumbItems(
  breadcrumbs: Breadcrumb[],
  pageType: ResourcePageType,
): BreadcrumbsHierarchyItem[] {
  const isExploreProduct = pageType === ResourcePageType.EXPLORE;
  const history = useHistory();
  return useMemo(() => {
    const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE : ROUTES.REPORT_BUILDER;

    return breadcrumbs.slice(0, breadcrumbs.length - 1).map(({ id, name }, idx) => {
      const text = idx === 0 ? `All ${isExploreProduct ? 'Dashboards' : 'Reports'}` : name;
      const path = idx === 0 ? rootPath : `${rootPath}/${id}`;
      return {
        text,
        onClick: () => history.push(path),
      };
    });
  }, [breadcrumbs, history, isExploreProduct]);
}
