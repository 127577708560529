import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InfoCard } from 'components/InfoCard';
import { getEditPageDataPanels } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';

import { DatasetLink } from './DatasetLink';
import { constructLinkableDataPanelsMap } from './chartLinkUtil';

type Props = {
  datasets: Record<string, ResourceDataset>;
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const ChartLinkConfig: FC<Props> = ({ datasets, element, updateConfig }) => {
  const dataPanels = useSelector((state: ReduxState) =>
    getEditPageDataPanels(state.dashboardEditConfig),
  );

  const datasetLinkElements = useMemo(() => {
    const datasetIdToDataPanelsMap = constructLinkableDataPanelsMap(dataPanels, element);
    const datasetLinkElems: JSX.Element[] = [];
    Object.values(datasets).forEach((dataset) => {
      const dataPanels = datasetIdToDataPanelsMap[dataset.id] ?? [];
      if (dataPanels.length === 0) return;
      datasetLinkElems.push(
        <DatasetLink
          dataPanels={dataPanels}
          dataset={dataset}
          element={element}
          key={`${element.name}-${dataset.id}`}
          updateConfig={updateConfig}
        />,
      );
    });
    return datasetLinkElems;
  }, [dataPanels, datasets, element, updateConfig]);

  return (
    <>
      <InfoCard
        noTopMargin
        text={
          datasetLinkElements.length > 0
            ? LINK_DATASET_TO_CHART_MESSAGE
            : NO_VALID_DATA_PANELS_MESSAGE
        }
      />
      {datasetLinkElements}
    </>
  );
};

const LINK_DATASET_TO_CHART_MESSAGE =
  'To link a chart, start by selecting a column from the dataset it uses.';

const NO_VALID_DATA_PANELS_MESSAGE = 'No valid charts to link to.';
