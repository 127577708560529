import { FC } from 'react';

import { Input, IconButton, sprinkles } from 'components/ds';

import * as styles from './styles.css';

type Props = {
  className?: string;
  currentPage: number;
  maxPageNumber: number;
  onNewPage: (newPage: string) => void;
};

// This is used for paginating resources and customers page
export const TablePager: FC<Props> = ({ currentPage, maxPageNumber, onNewPage }) => {
  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === maxPageNumber;

  return (
    <div className={rootClass}>
      <IconButton
        disabled={onFirstPage}
        name="chevron-left"
        onClick={() => !onFirstPage && onNewPage(String(currentPage - 1))}
      />
      <span>Page</span>
      <Input
        className={styles.pagerInput}
        defaultValue={String(currentPage)}
        onSubmit={onNewPage}
      />
      <span>{`of ${maxPageNumber}`}</span>
      <IconButton
        disabled={onLastPage}
        name="chevron-right"
        onClick={() => !onLastPage && onNewPage(String(currentPage + 1))}
      />
    </div>
  );
};

const rootClass = sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', whiteSpace: 'nowrap' });
