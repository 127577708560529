import produce from 'immer';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { FetchSecondaryDataBody } from 'actions/dataPanelTemplateAction';
import {
  embedFetchDashboardDatasetPreview,
  embedFetchDataPanel,
  embedFetchDataPanelRowCount,
  embedFetchSecondaryData,
} from 'actions/embedActions';
import { JobDefinition, bulkEnqueueJobs } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Poller } from 'components/JobQueue/Poller';
import { createJob } from 'components/JobQueue/createJob';
import { JobParams, Jobs } from 'components/JobQueue/types';
import { sprinkles } from 'components/ds';
import { DASHBOARD_LOADED_CLASS_NAME } from 'constants/exportConstants';
import { OPERATION_TYPES, ROW_COUNT_OPERATIONS_SET, UserTransformedSchema } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import {
  isDataPanelLoading,
  setDashboardVariables,
  setDataPanelsLoading,
} from 'reducers/dashboardDataReducer';
import { setRequestInfo } from 'reducers/dashboardLayoutReducer';
import { fetchFidoComputationDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import * as RD from 'remotedata';
import { DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { AdHocOperationInstructions, DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  areRequiredUserInputsSet,
  getAsynchronousSecondaryDataInstructions,
  getDashboardTimezone,
  getSynchronousSecondaryDataInstructions,
  prepareDataPanelForFetch,
} from 'utils/dashboardUtils';
import { isDataPanelConfigReady } from 'utils/dataPanelConfigUtils';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { isChartIdGeneratedFromTemplateId } from 'utils/editableSectionUtils';
import { getDataPanelQueryContext, getDatasetIdsForDataPanel } from 'utils/variableUtils';

import { PDFExportTableView } from './PDFExportTableView/PDFExportTableView';

export const ChartLayout: FC<{
  dashboardEmbedId: string;
  dataPanelId: string;
  dashboardConfig: DashboardVersionConfig;
  variables: DashboardVariableMap;
  userTransformedSchema: UserTransformedSchema;
  adHocOperationInstructions: AdHocOperationInstructions;
  reportName: string;
  customerToken: string;
}> = ({
  adHocOperationInstructions,
  dashboardEmbedId,
  dataPanelId,
  variables: rawVariables,
  dashboardConfig,
  reportName,
  userTransformedSchema,
  customerToken,
}) => {
  const dispatch = useDispatch();

  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [hasMounted, setHasMounted] = useState(false);

  // Use the template id if the chart is an editable section chart. Multiple editable section charts
  // can be inserted from a singular template which contains the actual data panel info.
  const editableSection = dashboardConfig.editable_section;
  const editableSectionTemplates = editableSection?.enabled ? editableSection.charts : {};
  const editableSectionTemplateId = Object.keys(editableSectionTemplates).find((templateId) =>
    isChartIdGeneratedFromTemplateId(dataPanelId, templateId),
  );
  if (editableSectionTemplateId) {
    dataPanelId = editableSectionTemplateId;
  }

  const {
    isLoading,
    datasetData,
    dashboardVersionNumber,
    shouldUseJobQueue,
    maxRowsOverride,
    dashboard,
    shouldUseFido,
    requestInfo,
  } = useSelector(
    (state: EmbedReduxState) => ({
      isLoading: isDataPanelLoading(state.dashboardData, dataPanelId),
      datasetData: state.dashboardData.datasetData,
      dashboardVersionNumber: state.embedDashboard.dashboardVersion?.version_number,
      maxRowsOverride: state.embedDashboard.team?.configuration.pdf_max_rows,
      shouldUseJobQueue: !!state.embedDashboard.team?.feature_flags.use_job_queue,
      shouldUseFido: !!state.embedDashboard.team?.feature_flags.use_fido,
      dashboard: state.embedDashboard.dashboard,
      requestInfo: state.dashboardLayout.requestInfo,
    }),
    shallowEqual,
  );

  const { datasets, elements, data_panels: dataPanels } = dashboardConfig;
  const dataPanel = editableSectionTemplateId
    ? editableSectionTemplates[editableSectionTemplateId].data_panel
    : dataPanels[dataPanelId];
  const dashboardTimezone = getDashboardTimezone(RD.getOrDefault(dashboard, undefined));
  const variables = getDataPanelQueryContext(dataPanel, rawVariables);

  // the max number of rows we pull in for a pdf is usually 200 to avoid long
  // load times and timeouts. Now that we're using the job queue, we can
  // increase this, trying out 1000 for now. The number should strike a balance
  // between reasonable load time and showing as much data as possible, while
  // also avoiding something like a pdf with 1m rows. We could also open this
  // configuraton to the customer
  const queryLimit =
    maxRowsOverride != null ? maxRowsOverride : shouldUseJobQueue || shouldUseFido ? 700 : 200;

  const sharedPostData = useMemo(
    () => ({
      // this has to be set based on the parent component
      version_number: dashboardVersionNumber ?? -1,
      resource_embed_id: dashboardEmbedId,
      timezone: dashboardTimezone,
    }),
    [dashboardVersionNumber, dashboardEmbedId, dashboardTimezone],
  );

  const dashboardElements = useMemo(() => Object.values(elements), [elements]);

  const fetchDatasetPreviewData = useCallback(
    (datasetId: string): JobDefinition | undefined => {
      const postData = {
        dataset_id: datasetId,
        query_limit: queryLimit,
        variables,
        customer_id: undefined,
      };

      if (!shouldUseJobQueue)
        dispatch(
          embedFetchDashboardDatasetPreview({
            customerToken,
            postData: {
              ...postData,
              ...sharedPostData,
            },
          }),
        );
      else
        return {
          job_type: ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
          job_args: postData,
        };
    },
    [customerToken, dispatch, queryLimit, sharedPostData, shouldUseJobQueue, variables],
  );

  const fetchDataPanelTemplateSecondaryData = useCallback(
    (
      dataPanelTemplate: DataPanelTemplate,
      afterMainFetch?: boolean,
      sourceType?: string,
    ): (JobDefinition | undefined)[] => {
      const secondaryInstructions = afterMainFetch
        ? getSynchronousSecondaryDataInstructions(dataPanelTemplate, sourceType)
        : getAsynchronousSecondaryDataInstructions(
            dataPanelTemplate,
            datasets[dataPanelTemplate.table_id],
          );

      return secondaryInstructions.map((instructions) => {
        const postData: FetchSecondaryDataBody = {
          dataset_id: dataPanelTemplate.table_id,
          config: prepareDataPanelForFetch(
            variables,
            instructions,
            datasets,
            datasetData,
            dashboardElements,
            dataPanels,
          ),
          is_box_plot:
            instructions.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
          id: dataPanelTemplate.id,
          variables,
          is_secondary_data_query: true,
        };

        if (!shouldUseJobQueue) {
          dispatch(
            embedFetchSecondaryData({
              customerToken,
              postData: { ...postData, ...sharedPostData },
            }),
          );
        } else
          return {
            job_type: ACTION.FETCH_SECONDARY_DATA,
            job_args: postData,
          };
      });
    },
    [
      customerToken,
      dispatch,
      sharedPostData,
      shouldUseJobQueue,
      variables,
      dashboardElements,
      datasets,
      datasetData,
      dataPanels,
    ],
  );

  const fetchDataPanelTemplateData = useCallback(
    (dataPanelTemplate: DataPanelTemplate): JobDefinition | undefined => {
      const postData = {
        dataset_id: dataPanelTemplate.table_id,
        config: prepareDataPanelForFetch(
          variables,
          dataPanelTemplate,
          datasets,
          datasetData,
          dashboardElements,
          dataPanels,
          false,
          dashboardTimezone,
        ),
        id: dataPanelTemplate.id,
        sort_info: adHocOperationInstructions.sortInfo,
        filter_info: adHocOperationInstructions.filterInfo,
        variables,
        query_limit: queryLimit,
        jobId: uuidv4(),
      };

      if (!shouldUseJobQueue)
        dispatch(
          embedFetchDataPanel(
            { customerToken, postData: { ...postData, ...sharedPostData } },
            (response) => {
              fetchDataPanelTemplateSecondaryData(
                dataPanelTemplate,
                true,
                response.data_panel_template._source_type,
              );
            },
          ),
        );
      else
        return {
          job_type: ACTION.FETCH_DATA_PANEL_TEMPLATE,
          job_args: postData,
        };
    },
    [
      customerToken,
      dispatch,
      queryLimit,
      sharedPostData,
      shouldUseJobQueue,
      variables,
      adHocOperationInstructions,
      dashboardElements,
      datasets,
      datasetData,
      fetchDataPanelTemplateSecondaryData,
      dataPanels,
      dashboardTimezone,
    ],
  );

  const fetchDataPanelTemplateRowCount = useCallback(
    (dataPanelTemplate: DataPanelTemplate): JobDefinition | undefined => {
      const postData = {
        dataset_id: dataPanelTemplate.table_id,
        id: dataPanelTemplate.id,
        config: prepareDataPanelForFetch(
          variables,
          dataPanelTemplate,
          datasets,
          datasetData,
          dashboardElements,
          dataPanels,
          false,
          dashboardTimezone,
        ),
        filter_info: adHocOperationInstructions.filterInfo,
        variables,
      };

      if (!shouldUseJobQueue) {
        dispatch(
          embedFetchDataPanelRowCount({
            customerToken,
            postData: { ...postData, ...sharedPostData },
          }),
        );
      } else
        return {
          job_type: ACTION.FETCH_DATA_PANEL_ROW_COUNT,
          job_args: postData,
        };
    },
    [
      customerToken,
      dispatch,
      sharedPostData,
      shouldUseJobQueue,
      variables,
      adHocOperationInstructions,
      dashboardElements,
      datasets,
      datasetData,
      dataPanels,
      dashboardTimezone,
    ],
  );

  const isDashboardLoaded = !(isLoading || Object.keys(awaitedJobs).length > 0);
  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  const renderContent = () => {
    if (!isDashboardLoaded) return null;

    if (
      [OPERATION_TYPES.VISUALIZE_TABLE, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER].includes(
        dataPanel.visualize_op.operation_type,
      ) &&
      isDashboardLoaded
    ) {
      return (
        <PDFExportTableView
          dataPanelTemplate={dataPanel}
          datasetNamesToId={datasetNamesToId}
          defaultUserTransformedSchema={userTransformedSchema}
          reportTitle={
            reportName || dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || ''
          }
          variables={variables}
        />
      );
    } else {
      return (
        <div
          className={sprinkles({
            parentContainer: 'fill',
            flexItems: 'column',
            overflowY: 'auto',
            position: 'relative',
          })}>
          <DashboardDatasetView
            canDownloadDataPanel
            isViewOnly
            dataPanel={dataPanel}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            defaultUserTransformedSchema={userTransformedSchema}
            isInContainer={false}
            pageType={PAGE_TYPE.SHARED}
            variables={variables}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (hasMounted) return;

    setHasMounted(true);

    // hack because this is embedded but we don't currently set request info for ChartLayout
    dispatch(
      setRequestInfo({
        ...requestInfo,
        type: 'embedded',
        embedType: 'iframe',
        environment: undefined,
        customerToken: customerToken,
        resourceEmbedId: dashboardEmbedId,
        timezone: dashboardTimezone,
        useFido: shouldUseFido,
        useJobQueue: shouldUseJobQueue,
      }),
    );
    dispatch(setDashboardVariables(variables));

    if (
      !isDataPanelConfigReady(dataPanel.visualize_op) ||
      !areRequiredUserInputsSet(variables, dataPanel)
    ) {
      dispatch(setDataPanelsLoading({ ids: [dataPanel.id], loading: false }));
      return;
    }

    if (shouldUseFido) {
      dispatch(
        fetchFidoComputationDataThunk(
          prepareDataPanelForFetch(
            variables,
            dataPanel,
            datasets,
            datasetData,
            dashboardElements,
            dataPanels,
            false,
            dashboardTimezone,
          ),
          datasets,
          variables,
          adHocOperationInstructions,
          queryLimit,
        ),
      );
      return;
    }

    const requests = getDatasetIdsForDataPanel(dataPanel, datasets, undefined, true).map(
      (datasetId) => fetchDatasetPreviewData(datasetId),
    );

    requests.push(fetchDataPanelTemplateData(dataPanel));
    requests.push(...fetchDataPanelTemplateSecondaryData(dataPanel));

    if (ROW_COUNT_OPERATIONS_SET.has(dataPanel.visualize_op.operation_type)) {
      requests.push(fetchDataPanelTemplateRowCount(dataPanel));
    }

    const jobDefinitions = requests.filter(
      (jobDefinition): jobDefinition is JobDefinition => !!jobDefinition,
    );

    if (jobDefinitions.length === 0) return;

    jobDefinitions.forEach((jobDefinition) => {
      jobDefinition.job_args = { ...jobDefinition.job_args, ...sharedPostData };
    });

    return dispatch(
      bulkEnqueueJobs(
        {
          jobs: Object.assign(
            {},
            ...jobDefinitions.map((job) => createJob(job as JobParams, job.onSuccess, job.onError)),
          ),
          customerToken,
        },
        (jobs) => {
          setAwaitedJobs(
            produce(awaitedJobs, (draft) => {
              return {
                ...draft,
                ...jobs,
              };
            }),
          );
        },
      ),
    );
  }, [
    awaitedJobs,
    customerToken,
    dashboardElements,
    dataPanel,
    datasetData,
    datasets,
    dispatch,
    fetchDataPanelTemplateData,
    fetchDataPanelTemplateRowCount,
    fetchDataPanelTemplateSecondaryData,
    fetchDatasetPreviewData,
    hasMounted,
    requestInfo,
    sharedPostData,
    shouldUseFido,
    variables,
    dashboardEmbedId,
    dataPanels,
    dashboardTimezone,
    queryLimit,
    adHocOperationInstructions,
    shouldUseJobQueue,
  ]);

  return (
    <div className={isDashboardLoaded ? DASHBOARD_LOADED_CLASS_NAME : ''}>
      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0)
            setAwaitedJobs((currentAwaitedJobs) => {
              const newAwaitedJobs = produce(currentAwaitedJobs, (draft) =>
                finishedJobIds.forEach((jobId) => delete draft[jobId]),
              );
              return newAwaitedJobs;
            });

          onComplete();
        }}
      />
      {renderContent()}
    </div>
  );
};
