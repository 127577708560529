import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ParentSchema, DataSource, Quantification } from 'actions/dataSourceActions';
import { DataSourceButton } from 'components/DataSourceButton';
import { sprinkles, Intent } from 'components/ds';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { ConnectDataSourceContainer } from 'pages/ConnectDataSourceFlow/ConnectDataSourceContainer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import {
  buildDatabaseConfigForFido,
  buildSSHTunnelConfigForFido,
  shouldUseFidoForConnectingOrUpdating,
} from 'utils/fido/dataSources/utils';

import { ConnectDataSourceStep, CONNECTED_BUT_NO_TABLES_MSG } from '../constants';
import { DBConnectionConfig } from '../types';
import { areCredentialsComplete, parseErrorMessage } from '../utils';

import { EnterCredentialsBody } from './EnterCredentials';
import { GettingStartedBody } from './GettingStarted';
import { SecurityConfigurationBody } from './SecurityConfiguration';

export type TestConnectionStatus = { numberOfTables: number; quantification?: Quantification };

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  isConnectingToDataSource: boolean;
  testingConnection: RD.ResponseData<TestConnectionStatus>;
  parentSchemas: ParentSchema[];
  selectedSchema?: ParentSchema;
  setSelectedSchema: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
  selectedAccessGroupIds: number[];
  setSelectedAccessGroupIds: (accessGroupIds: number[]) => void;
};

export const ReviewConfiguration: FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnection,
  isConnectingToDataSource,
  parentSchemas,
  selectedSchema,
  existingDataSources,
  selectedAccessGroupIds,
  setSelectedAccessGroupIds,
  setSelectedSchema,
}: Props) => {
  const { useFido, fidoConfig } = useSelector((state: ReduxState) => {
    return {
      useFido: shouldUseFidoForConnectingOrUpdating(state),
      fidoConfig: state.fidoDataSourceConfig,
    };
  }, shallowEqual);

  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;

  const getBottomBannerConfig = () => {
    if (RD.hasNotReturned(testingConnection)) return;
    if (RD.isSuccess(testingConnection)) {
      const numTables = testingConnection.data.numberOfTables;
      if (numTables === 0) return { intent: Intent.WARNING, text: CONNECTED_BUT_NO_TABLES_MSG };

      const numberOfTables = `${
        testingConnection.data.quantification === Quantification.AtLeast ? 'at least' : ''
      } ${numTables} ${numTables > 1 ? 'tables' : 'table'}`;

      return {
        intent: Intent.SUCCESS,
        text: `We've successfully connected to your database and found ${numberOfTables}, you're ready to go!`,
      };
    }

    return {
      intent: Intent.ERROR,
      text: (
        <>
          <b>{'There was an error testing: '}</b>
          {parseErrorMessage(testingConnection.error)}
        </>
      ),
    };
  };

  return (
    <ConnectDataSourceContainer
      backBtnOnClick={onBackClicked}
      bodyContent={
        <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
          <div className={sectionHeaderClass}>Getting Started</div>
          <GettingStartedBody
            disabled
            config={config}
            existingDataSources={existingDataSources}
            parentSchemas={parentSchemas}
            selectedAccessGroupIds={selectedAccessGroupIds}
            selectedSchema={selectedSchema}
            setSelectedAccessGroupIds={setSelectedAccessGroupIds}
            setSelectedSchema={setSelectedSchema}
            updateConfig={updateConfig}
          />
          <div className={sectionHeaderClass}>Database Type</div>
          <DataSourceButton
            disabled
            dataSourceName={dataSource.name}
            imgUrl={dataSourceByType[dataSource.name].datasourceIconImg}
            selected={config.selectedDataSource?.name === dataSource.name}
          />
          <div className={sectionHeaderClass}>Credentials</div>
          <EnterCredentialsBody config={config} updateConfig={updateConfig} />
          <div className={sectionHeaderClass}>Security</div>
          <SecurityConfigurationBody config={config} updateConfig={updateConfig} />
        </div>
      }
      bottomBannerConfig={getBottomBannerConfig()}
      currentStep={ConnectDataSourceStep.REVIEW}
      headerTitle="Review"
      primaryActionConfig={{
        text: RD.isSuccess(testingConnection) ? 'Connect' : 'Test Connection',
        onClick: onNextClicked,
        loading: isConnectingToDataSource || RD.isLoading(testingConnection),
        disabled: useFido
          ? !buildDatabaseConfigForFido(fidoConfig.dataSourceConfig, true) ||
            !buildSSHTunnelConfigForFido(fidoConfig.sshConfig, true)
          : !areCredentialsComplete(
              dataSource.configuration_schema.properties,
              config.dataSourceConfig || {},
              false,
            ) ||
            !areCredentialsComplete(
              dataSource.configuration_schema.properties,
              config.dataSourceConfig || {},
              true,
            ),
      }}
    />
  );
};

const sectionHeaderClass = sprinkles({ heading: 'h3' });
