import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = { onNext: () => void };

export const StartStep: FC<Props> = ({ onNext }) => {
  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'centerColumn', parentContainer: 'fill', padding: 'sp3' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <div className={cx(sprinkles({ fontWeight: 600 }), embedSprinkles({ heading: 'h2' }))}>
        Configure your report
      </div>
      <div
        className={cx(
          sprinkles({ marginTop: 'sp2', marginBottom: 'sp3' }),
          embedSprinkles({ body: 'secondary' }),
        )}>
        Name your report, select your data, and export to PDF or CSV.
      </div>
      <EmbedButton onClick={onNext} variant="primary">
        Get Started
      </EmbedButton>
    </div>
  );
};
