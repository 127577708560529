import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';

import { EmbedText } from './EmbedText';

type Props = {
  isSaved: boolean;
};

export const ReportSaveStatus: FC<Props> = ({ isSaved }) => {
  if (isSaved) {
    return (
      <div className={sprinkles({ flexItems: 'center' })}>
        <Icon
          className={sprinkles({ marginRight: 'sp.5', color: 'contentTertiary' })}
          name="check"
        />
        <EmbedText body="b1" color="contentTertiary">
          Saved
        </EmbedText>
      </div>
    );
  }

  return (
    <EmbedText body="b1" className={sprinkles({ whiteSpace: 'nowrap' })} color="contentTertiary">
      Unsaved changes
    </EmbedText>
  );
};
