import { Layout } from '@explo-tech/react-grid-layout';
import { ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { AnyAction } from 'redux';

import { createApiRequestConfig } from 'actions/actionUtils';
import { CustomerEditableSection, CustomerEditableSectionConfig } from 'actions/dashboardActions';
import { ACTION } from 'actions/types';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import {
  deleteEditableSectionChart,
  setEditableSectionLayout,
} from 'reducers/dashboardEditConfigReducer';
import { updateEmbedEditableSectionLayout } from 'reducers/embedDashboardReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  sendAddEditableSectionChartEventThunk,
  sendRemoveEditableSectionChartEventThunk,
} from 'reducers/thunks/customEventThunks';
import { getEditableSectionDataPanel } from 'reducers/thunks/dashboardDataThunks/utils';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { createEditableSectionItemId } from 'utils/dashboardUtils';
import {
  addChartToLayout,
  cleanLayout,
  isChartInstanceOfTemplate,
} from 'utils/editableSectionUtils';
import { createDebouncedFn } from 'utils/general';
import { makeThunkRequest } from 'utils/thunkUtils';

import { onNewDataPanelsAddedThunk } from './dashboardDataThunks/requestLogicThunks';
import { deleteDataPanelAftermathThunk } from './dashboardSelectionThunks';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

const debounceFn = createDebouncedFn(2000);

export const deleteEditableSectionChartThunk =
  (dpId: string): Thunk =>
  (dispatch) => {
    dispatch(deleteEditableSectionChart());
    dispatch(deleteDataPanelAftermathThunk(dpId));
  };

export const addChartToEditableSectionThunk =
  (layout: Layout[] | undefined, chart: EditableSectionChart, columns: number): Thunk =>
  (dispatch, getState) => {
    const {
      dashboardInteractions: { interactionsInfo },
      dashboardLayout: { requestInfo },
    } = getState();

    const { data_panel: dp } = chart;
    const newLayout = layout ? cleanLayout([...layout]) : [];
    const chartId = createEditableSectionItemId(dp.id);
    addChartToLayout(chartId, dp.visualize_op.operation_type, newLayout, columns);

    if (requestInfo.type === 'embedded') {
      dispatch(saveEditableSectionThunk({ layout: newLayout }, requestInfo.embedType));
      dispatch(sendAddEditableSectionChartEventThunk(chartId, chart));
    } else {
      const isEditing = interactionsInfo.isEditing;
      dispatch(setEditableSectionLayout({ layout: newLayout, isPreview: !isEditing }));
    }

    const chartDataPanel = getEditableSectionDataPanel(chartId, dp);
    dispatch(onNewDataPanelsAddedThunk([chartDataPanel]));
  };

/**
 * Deletes the first instance of a chartTemplate
 */
export const removeChartTemplateFromEditableSectionThunk =
  (layout: Layout[], chartTemplate: EditableSectionChart): Thunk =>
  (dispatch) => {
    const chart = layout.find((item) => isChartInstanceOfTemplate(item.i, chartTemplate));
    if (chart) dispatch(removeChartFromEditableSectionThunk(layout, chart.i));
  };

/**
 * @param layout
 * @param chartId - Must be layout.i, NOT chart.data_panel.id
 */
export const removeChartFromEditableSectionThunk = (layout: Layout[], chartId: string): Thunk => {
  return (dispatch) => {
    const newLayout = layout.filter((item) => item.i !== chartId);
    dispatch(setEditableSectionLayoutThunk(newLayout));
    dispatch(deleteDataPanelAftermathThunk(chartId));
    dispatch(sendRemoveEditableSectionChartEventThunk(chartId));
  };
};

export const setEditableSectionLayoutThunk =
  (newLayout: Layout[]): Thunk =>
  (dispatch, getState) => {
    const {
      dashboardInteractions: { interactionsInfo },
      dashboardLayout: { requestInfo },
    } = getState();

    const cleanedLayout = cleanLayout(newLayout);

    if (requestInfo.type === 'embedded') {
      dispatch(saveEditableSectionThunk({ layout: cleanedLayout }, requestInfo.embedType));
    } else {
      const isEditing = interactionsInfo.isEditing;
      dispatch(setEditableSectionLayout({ layout: cleanedLayout, isPreview: !isEditing }));
    }
  };

// TODO: Should we send edit version number to not allow overwrites?
type SaveEditableSectionParams = {
  config: CustomerEditableSectionConfig;
  dashboard_embed_id: string;
};

const allowedSaveEditableSectionEmbedTypes: Set<EmbeddedDashboardType> = new Set([
  'embedded',
  'iframe',
  'shared',
  'portal',
]);

const saveEditableSectionThunk =
  (config: CustomerEditableSectionConfig, embedType: EmbeddedDashboardType): Thunk =>
  (dispatch) => {
    dispatch(updateEmbedEditableSectionLayout(config.layout));
    if (!allowedSaveEditableSectionEmbedTypes.has(embedType)) return;

    debounceFn(() => dispatch(saveEditableSection(config)));
  };

export const saveEditableSection = createAsyncThunk<
  { customer_editable_section: CustomerEditableSection },
  CustomerEditableSectionConfig,
  { state: EmbedReduxState }
>(ACTION.SAVE_CUSTOMER_EDITABLE_SECTION, async (config, { getState }) => {
  const { requestInfo } = getState().dashboardLayout;
  let requestConfig: AxiosRequestConfig | null = null;
  if (requestInfo.type === 'embedded') {
    const postData: SaveEditableSectionParams = {
      config,
      dashboard_embed_id: requestInfo.resourceEmbedId,
    };

    requestConfig = createApiRequestConfig(
      'embed/save_editable_section/',
      'POST',
      postData,
      requestInfo.customerToken,
      requestInfo.jwt,
    );
  }

  return makeThunkRequest(requestConfig, 'Error saving editable section');
});
