import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  CustomerReportDataInfo,
  CustomerReportView,
  ScatterPlotView,
} from 'actions/customerReportActions';
import { OPERATION_TYPES } from 'constants/types';
import { AggSection } from 'pages/ReportBuilder/ReportView/DataPanel/AggSection';
import { ColumnGroupBySection } from 'pages/ReportBuilder/ReportView/DataPanel/ColumnGroupBySection';
import { ColumnSection } from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection';
import { RowLimit } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DraggingColumn } from 'pages/ReportBuilder/ReportView/DataPanel/DraggingColumn';
import { GroupBySection } from 'pages/ReportBuilder/ReportView/DataPanel/GroupBySection';
import { ScatterPlotGroupingSection } from 'pages/ReportBuilder/ReportView/DataPanel/ScatterPlotGroupingSection';
import {
  COLUMN_GROUP_BY_VISUALIZATION_TYPES,
  GROUP_BY_VISUALIZATION_TYPES,
} from 'pages/ReportBuilder/constants';
import {
  getAggLimit,
  getGroupByLimit,
  getAggDisabled,
  getGroupByDisabled,
  getColumnGroupByDisabled,
  getColumnGroupByLimit,
  getScatterPlotGroupingDisabled,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import * as RD from 'remotedata';
import {
  getCurrentViewData,
  reorderColumns,
  addGroupBy,
  updateCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { CHART_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { PivotAgg } from 'types/dateRangeTypes';
import { getDraggableColumns, getNewGroupBy, DraggableColumnInfo } from 'utils/customerReportUtils';

import {
  AGGS_SECTION_ID,
  COLS_SECTION_ID,
  ROWS_SECTION_ID,
  DataPanelData,
  COL_LIST_SECTION_ID,
  SCATTER_PLOT_GROUPING_SECTION_ID,
} from './constants';

type Props = { dataInfo: CustomerReportDataInfo; view: CustomerReportView };

export const DataTab: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();
  const [draggingData, setDraggingData] = useState<DataPanelData | undefined>();
  const [draggingOver, setDraggingOver] = useState<DataPanelData | undefined>();

  const { versionConfig, rowCount, sourceType } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      rowCount: getCurrentViewData(state.reportEditing).rowCount,
      sourceType: state.reportEditing.reportSourceType,
    }),
    shallowEqual,
  );

  const dataset = dataInfo ? versionConfig?.datasets[dataInfo.datasetId] : undefined;
  const { columnOrder, hiddenColumns } = view;
  const draggableColumns = useMemo(() => {
    const columns = getDraggableColumns(dataset, columnOrder, hiddenColumns);

    // Remove columns that are not available anymore
    if (columnOrder && columnOrder.length !== columns.length) {
      const updatedColumnOrder = columns.map((c) => c.name);
      dispatch(updateCurrentView({ columnOrder: updatedColumnOrder }));
    }
    return columns;
  }, [dispatch, dataset, columnOrder, hiddenColumns]);

  const draggingColumn = useMemo(
    () =>
      draggingData?.column &&
      draggableColumns.find((col) => col.name === draggingData.column?.name),
    [draggableColumns, draggingData],
  );

  // Used to make sure there are no duplicate view.visualizationGroupings between rows and columns
  const bucketsByCol = useMemo(() => {
    const byCol: Record<string, Set<PivotAgg>> = {};
    const groupBys = (view.groupBys ?? []).concat(view.columnGroupBys ?? []);

    groupBys.forEach(({ column, bucket }) => {
      if (!bucket) return;
      if (column.name in byCol) byCol[column.name].add(bucket);
      else byCol[column.name] = new Set([bucket]);
    });
    return byCol;
  }, [view.groupBys, view.columnGroupBys]);

  const visualization = view.visualization || OPERATION_TYPES.VISUALIZE_TABLE;
  const isScatterPlot = visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2;

  const groupByLimit = getGroupByLimit(visualization, view.aggregations);
  const columnGroupByLimit = getColumnGroupByLimit(visualization);
  const isGroupByDisabled = getGroupByDisabled(visualization, view.aggregations, view.groupBys);

  const scatterPlotGrouping = isScatterPlot
    ? (view as ScatterPlotView).scatterPlotGrouping
    : undefined;

  const isScatterPlotGroupingDisabled = getScatterPlotGroupingDisabled(
    visualization,
    view.groupBys,
    scatterPlotGrouping,
  );

  const aggLimit = getAggLimit(visualization, view.groupBys);
  const isAggDisabled = getAggDisabled(visualization, view.aggregations, view.groupBys);

  const isColGroupBysDisabled = getColumnGroupByDisabled(
    visualization,
    view.aggregations,
    view.groupBys,
    view.columnGroupBys,
    draggingData?.section,
  );

  const isTargetDisabled =
    (draggingOver?.section === ROWS_SECTION_ID && isGroupByDisabled) ||
    (draggingOver?.section === COLS_SECTION_ID && isColGroupBysDisabled) ||
    (draggingOver?.section === AGGS_SECTION_ID && isAggDisabled) ||
    (draggingOver?.section === SCATTER_PLOT_GROUPING_SECTION_ID && isScatterPlotGroupingDisabled);

  const groupByLen = view.groupBys?.length ?? 0;
  const isPivot = !!(groupByLen || view.aggregations?.length);

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    setDraggingData(undefined);
    setDraggingOver(undefined);

    if (!active.data.current || !over?.data.current) return;
    const activeData = active.data.current as DataPanelData;
    const overData = over.data.current as DataPanelData;

    const isSameSection = activeData.section === overData.section;
    const isColumnList = activeData.section === COL_LIST_SECTION_ID;
    const activeId = isColumnList ? activeData.column?.name : activeData.id;
    const overId = isColumnList ? overData.column?.name : overData.id;
    if (isSameSection && (activeId == null || overId == null || activeId === overId)) return;

    const activeColumn = dataset?.schema?.find((col) => col.name === activeData.column?.name);
    dispatch(reorderColumns({ view, activeData, overData, activeColumn }));
  };

  const isAtRowLimit =
    RD.isSuccess(rowCount) &&
    rowCount.data > CHART_ROW_LIMIT &&
    GROUP_BY_VISUALIZATION_TYPES.has(visualization) &&
    !!view.aggregations?.length &&
    !!groupByLen;

  const handleAddGroupBy = (col: DraggableColumnInfo, isColumnGroupBy: boolean) => {
    const groupBy = getNewGroupBy(col, view, isColumnGroupBy, sourceType);
    if (groupBy) dispatch(addGroupBy({ groupBy, isColumnGroupBy }));
  };

  return (
    // Instead of rendering "null", use 0 width so in the embedded component the height of the app doesn't change
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        onDragEnd={handleDragEnd}
        onDragMove={({ over }) => setDraggingOver(over?.data.current as DataPanelData)}
        onDragStart={({ active }) => setDraggingData(active.data?.current as DataPanelData)}>
        {!isScatterPlot && (
          <AggSection
            aggregations={view.aggregations ?? []}
            columns={draggableColumns}
            dataset={dataset}
            disabled={isAggDisabled}
            max={aggLimit}
            operationType={visualization}
          />
        )}
        {GROUP_BY_VISUALIZATION_TYPES.has(visualization) && (
          <GroupBySection
            bucketsByCol={bucketsByCol}
            columnConfigs={dataset?.columnConfigs}
            columns={draggableColumns}
            disabled={isGroupByDisabled}
            groupBys={view.groupBys ?? []}
            max={groupByLimit}
            onAddColumn={(col) => !isGroupByDisabled && handleAddGroupBy(col, false)}
            operationType={visualization}
            scatterPlotGrouping={scatterPlotGrouping}
          />
        )}

        {COLUMN_GROUP_BY_VISUALIZATION_TYPES.has(visualization) ? (
          <ColumnGroupBySection
            bucketsByCol={bucketsByCol}
            columnConfigs={dataset?.columnConfigs}
            columnGroupBys={view.columnGroupBys ?? []}
            columns={draggableColumns}
            disabled={isColGroupBysDisabled}
            max={columnGroupByLimit}
            onAddColumn={(col) => !isColGroupBysDisabled && handleAddGroupBy(col, true)}
            operationType={visualization}
          />
        ) : null}
        {isScatterPlot ? (
          <ScatterPlotGroupingSection
            bucketsByCol={bucketsByCol}
            columnConfigs={dataset?.columnConfigs}
            columns={draggableColumns}
            disabled={isScatterPlotGroupingDisabled}
            groupBys={view.groupBys ?? []}
            scatterPlotGrouping={scatterPlotGrouping}
          />
        ) : null}
        <ColumnSection
          columnConfigs={dataset?.columnConfigs}
          columns={draggableColumns}
          isPivot={isPivot}
          visualization={visualization}
        />
        {isAtRowLimit ? <RowLimit rowCount={rowCount.data} /> : null}
        <DragOverlay dropAnimation={null}>
          {draggingColumn ? (
            <DraggingColumn
              column={draggingColumn}
              columnConfigs={dataset?.columnConfigs}
              disabled={isTargetDisabled}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};
