import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  CustomerReportAgg,
  CustomerReportGroupBy,
  TotalAccumulator,
  TotalAccumulatorData,
} from 'actions/customerReportActions';
import { EmbedPivotTableRow } from 'components/embed/EmbedPivotTable';
import { FLOAT, INTEGER_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import { Aggregation } from 'constants/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DatasetRow, DatasetSchema } from 'types/datasets';
import { getAggColName, getGroupByName } from 'utils/V2ColUtils';

/**
 * Compute aggregated total values for each column in either a pivot table or non-pivot table
 *
 * @param rows - Raw rows
 * @param transformedRows - Rows formatted with renderValue
 * @param schema
 * @param aggregations
 * @param columnGroupBys
 * @param groupBys
 */
export const useTotalAccumulator = (
  rows: DatasetRow[] | undefined,
  transformedRows: EmbedPivotTableRow[] | undefined,
  schema: DatasetSchema,
  aggregations: CustomerReportAgg[] | undefined,
  columnGroupBys: CustomerReportGroupBy[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
) => {
  const showTotals = useSelector(
    (state: ReportBuilderReduxState) =>
      state.embeddedReportBuilder.reportBuilderVersion?.config?.general?.showTotals,
  );

  const columns = useMemo(
    () =>
      showTotals && aggregations?.length
        ? aggregations.map((agg) => ({
            name: getAggColName(agg),
            type: FLOAT,
          }))
        : schema,
    [aggregations, schema, showTotals],
  );

  const groupByNames = useMemo(() => groupBys?.map(getGroupByName), [groupBys]);
  const columnGroupByNames = useMemo(() => columnGroupBys?.map(getGroupByName), [columnGroupBys]);

  const totalAccumulator = useMemo(() => {
    const accumulator: TotalAccumulator = { columns: {}, pivotColumn: undefined };
    if (!rows || !transformedRows || !showTotals) return accumulator;

    // First pass: collect all values for each column
    const cellPaths = new Set<string>();
    for (let i = 0; i < rows.length; i++) {
      // Transformed row is used because column names may be formatted
      const transformedItem = transformedRows[i];
      const groupByCols = getColumnsFromItem(transformedItem, groupByNames) || [];
      const pivotPath = getColumnsFromItem(transformedItem, columnGroupByNames)?.join('') || '';

      // Raw row is used for computing totals because we don't want numbers to be strings
      const item = rows[i];
      for (const col of columns) {
        const value = item[col.name];
        let cellPath = `${pivotPath}${col.name}`;

        // Compute totals for parent group
        // i.e. groupBys=['municipality','neighborhood']
        // cellPath = 'ontario-toronto-birthrate-avg'
        cellPaths.add(cellPath);
        accumulator.columns[cellPath] = computeCellTotals(
          accumulator.columns[cellPath] || {},
          col.type,
          value,
        );

        // 1st loop: cellPath = 'ontario-toronto-birthrate-avg-markham'
        // 2nd loop: cellPath = 'ontario-toronto-birthrate-avg-markham-midtown' (totals not computed)
        for (let i = 0; i < groupByCols.length; i++) {
          const groupByCol = groupByCols[i];
          cellPath += groupByCol;
          cellPaths.add(cellPath);

          // Don't need totals for leaf nodes
          if (i < groupByCols.length - 1)
            accumulator.columns[cellPath] = computeCellTotals(
              accumulator.columns[cellPath] || {},
              col.type,
              value,
            );
        }
      }

      // Compute totals for pivot column
      const isNumeric = groupBys?.every((groupByCol) => NUMBER_TYPES.has(groupByCol.column.type));
      for (const groupBy of groupBys || []) {
        const groupByName = getGroupByName(groupBy);
        const value = item[groupByName];
        accumulator.columns[groupByName] = computeCellTotals(
          accumulator.columns[groupByName] || {},
          groupBy.column.type,
          value,
        );
        cellPaths.add(groupByName);

        accumulator.pivotColumn = computeCellTotals(
          accumulator.pivotColumn || {},
          isNumeric ? FLOAT : STRING,
          value,
        );
      }
    }

    // TODO: Average is disabled for pivots because computing them on the frontend means they're incomplete/misleading
    // Second pass: compute aggregations that need the full set of items
    // for (const cellPath of cellPaths) {
    //   const current = accumulator.columns[cellPath];
    //   if (!current) continue;
    //   accumulator.columns[cellPath] = computeCellGlobalTotals(current);
    // }
    // accumulator.pivotColumn = computeCellGlobalTotals(accumulator.pivotColumn);

    return accumulator;
  }, [rows, transformedRows, showTotals, groupByNames, columnGroupByNames, groupBys, columns]);

  return totalAccumulator;
};

/**
 * Compute totals iteratively
 */
const computeCellTotals = (
  current: TotalAccumulatorData,
  colType: string,
  value?: string | number,
) => {
  if (value == null) return current;

  current[Aggregation.COUNT] = (current[Aggregation.COUNT] || 0) + 1;

  if (!current.items) {
    current.items = new Set([value]);
    current[Aggregation.COUNT_DISTINCT] = 1;
  } else if (!current.items.has(value)) {
    current.items.add(value);
    current[Aggregation.COUNT_DISTINCT] = (current[Aggregation.COUNT_DISTINCT] || 0) + 1;
  }

  if (NUMBER_TYPES.has(colType)) {
    const num = INTEGER_TYPES.has(colType) ? (value as number) : parseFloat(value.toString());
    current[Aggregation.SUM] = (current[Aggregation.SUM] || 0) + num;
    // TODO: These are disabled for pivots because computing them on the frontend means they're incomplete/misleading
    //   current[Aggregation.MIN] = Math.min(current[Aggregation.MIN] || Infinity, num);
    //   current[Aggregation.MAX] = Math.max(current[Aggregation.MAX] || -Infinity, num);
  }
  return current;
};

/** Compute totals that rely on the full set of items (e.g. avg) */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const computeCellGlobalTotals = (current: TotalAccumulatorData) => {
  const count = current[Aggregation.COUNT];
  const sum = current[Aggregation.SUM];
  if (count != null && sum != null) current[Aggregation.AVG] = sum / count;
  delete current.items; // Save some RAM
  return current;
};

const getColumnsFromItem = (item: EmbedPivotTableRow, columnNames: string[] | undefined) =>
  columnNames?.map((col) => String(item[col]));
