import { FC } from 'react';

import { sprinkles, Switch, Input } from 'components/ds';
import { SwitchElementConfig } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchAdjustDisplayConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSwitchConfig);

  return (
    <>
      <Switch
        className={styles.configInput}
        label={{ text: 'Label', variableInput: true }}
        onChange={() => updateConfig((draft) => (draft.showSwitchLabel = !draft.showSwitchLabel))}
        switchOn={config.showSwitchLabel}
      />
      {config.showSwitchLabel ? (
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.label}
          onSubmit={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        />
      ) : null}
      <Switch
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.infoTooltipText}
          onSubmit={(newValue) => updateConfig((draft) => (draft.infoTooltipText = newValue))}
        />
      ) : null}
      <Switch
        className={styles.configInput}
        label="Show on-off label"
        onChange={() => updateConfig((draft) => (draft.showStatusLabel = !config.showStatusLabel))}
        switchOn={config.showStatusLabel}
      />
      {config.showStatusLabel ? (
        <div className={sprinkles({ display: 'flex' })}>
          <Input
            showInputButton
            className={sprinkles({ paddingRight: 'sp.5' })}
            defaultValue={config.onStatusLabel}
            label="On Label"
            onSubmit={(newValue) => updateConfig((draft) => (draft.onStatusLabel = newValue))}
          />
          <Input
            showInputButton
            className={sprinkles({ paddingLeft: 'sp.5' })}
            defaultValue={config.offStatusLabel}
            label="Off Label"
            onSubmit={(newValue) => updateConfig((draft) => (draft.offStatusLabel = newValue))}
          />
        </div>
      ) : null}
    </>
  );
};
