import ReactQuill from '@explo-tech/react-quill';

type UploadFunction = (
  base64: string | ArrayBuffer | null,
  file: File,
) => Promise<string | undefined>;

const selectLocalImage = (quill: ReactQuill, upload: UploadFunction) => {
  // creating a file browser for the user
  const fileSelect = document.createElement('input');
  fileSelect.setAttribute('type', 'file');
  fileSelect.setAttribute('accept', 'image/*');

  fileSelect.onchange = () => {
    if (!fileSelect.files) return;

    const file = fileSelect.files[0];
    readAndUploadFile(quill, upload, file);
  };

  document.body.appendChild(fileSelect);
  fileSelect.click();

  window.requestAnimationFrame(() => {
    if (fileSelect) document.body.removeChild(fileSelect);
  });
};

const readAndUploadFile = (quill: ReactQuill, upload: UploadFunction, file: File) => {
  const fileReader = new FileReader();

  fileReader.addEventListener(
    'load',
    () => {
      upload(fileReader.result, file).then((imageUrl?: string) => {
        // the parent upload function handles failures
        if (imageUrl)
          quill
            .getEditor()
            .insertEmbed(quill.getEditor().getSelection()?.index ?? 0, 'emailImage', {
              src: imageUrl,
            });
      });
    },
    false,
  );

  if (file) {
    fileReader.readAsDataURL(file);
  }
};

export default selectLocalImage;
