import cx from 'classnames';
import { FC, ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';

import * as styles from 'components/embed/EmbedCarousel/index.css';
import { EmbedButton } from 'components/embed/index';

const SCROLL_AMOUNT = 100;

interface Props {
  children: ReactNode;
  className?: string;
  itemsClassName?: string;
  scrollAmount?: number;
}

export const EmbedCarousel: FC<Props> = ({
  children,
  className,
  itemsClassName,
  scrollAmount = SCROLL_AMOUNT,
}) => {
  const tabsListRef = useRef<HTMLDivElement>(null);

  const handleScrollLeft = useCallback(() => {
    tabsListRef.current?.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  }, [scrollAmount]);

  const handleScrollRight = useCallback(() => {
    tabsListRef.current?.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }, [scrollAmount]);

  const [canScroll, setCanScroll] = useState(false);

  const updateCanScroll = () => {
    setCanScroll(
      !!tabsListRef.current && tabsListRef.current.scrollWidth > tabsListRef.current.offsetWidth,
    );
  };

  useLayoutEffect(updateCanScroll, [children]);

  return (
    <div className={cx(styles.carousel, !canScroll && styles.carouselNoScroll, className)}>
      {canScroll && (
        <EmbedButton
          icon="angle-left"
          onClick={handleScrollLeft}
          tooltipProps={{ align: 'end', side: 'bottom', text: 'Scroll left' }}
          variant="primary"
        />
      )}
      <div className={cx(styles.carouselItems, itemsClassName)} ref={tabsListRef}>
        {children}
        <ResizeObserver onResize={updateCanScroll} />
      </div>
      {canScroll && (
        <EmbedButton
          icon="angle-right"
          onClick={handleScrollRight}
          tooltipProps={{ align: 'end', side: 'bottom', text: 'Scroll right' }}
          variant="primary"
        />
      )}
    </div>
  );
};
