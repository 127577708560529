import { TIME_COLUMN_TYPES } from 'constants/dataConstants';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { cloneDeep } from 'utils/standard';

export const formatTwoDimensionalData = (
  previewData: Record<string, string | number>[],
  instructions?: V2TwoDimensionChartInstructions,
) => {
  // for color charts, we hide the latest period directly in the chart component since it requires
  // the data to be processed first
  if (
    TIME_COLUMN_TYPES.has(instructions?.categoryColumn?.column.type ?? '') &&
    (!instructions?.colorColumnOptions || instructions?.colorColumnOptions.length >= 1) &&
    instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData
  ) {
    // Preview data is stored in the redux store, we shouldn't directly modify it.
    const data = cloneDeep(previewData);
    data.pop();
    return data;
  }

  return previewData;
};
