import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { updateElementConfig } from 'actions/dashboardV2Actions';
import { ChartLinkConfig } from 'components/FilterConfigs/ChartLinkConfig';
import { DateDefaultValuesConfig } from 'components/FilterConfigs/DateFilter/DefaultValueConfig';
import { DateEditInteractionsConfig } from 'components/FilterConfigs/DateFilter/EditInteractionsConfig';
import { DateFilterTypeConfig } from 'components/FilterConfigs/DateFilter/FilterTypeConfig';
import { DateValuesConfig } from 'components/FilterConfigs/DateFilter/ValuesConfig';
import { DateGroupFilterTypeConfig } from 'components/FilterConfigs/DateGroupFilter/DateGroupFilterTypeConfig';
import { DateGroupDefaultValuesConfig } from 'components/FilterConfigs/DateGroupFilter/DefaultValueConfig';
import { DateGroupGroupLabelsConfig } from 'components/FilterConfigs/DateGroupFilter/GroupLabelsConfig';
import { DateGroupValuesConfig } from 'components/FilterConfigs/DateGroupFilter/ValuesConfig';
import { LabelConfig } from 'components/FilterConfigs/LabelConfig';
import { OperatorConfig } from 'components/FilterConfigs/OperatorConfig';
import { SelectAdjustDisplayConfig } from 'components/FilterConfigs/SelectFilter/AdjustDisplayConfig';
import { SelectDefaultValueConfig } from 'components/FilterConfigs/SelectFilter/DefaultValueConfig';
import { SelectEditInteractionsConfig } from 'components/FilterConfigs/SelectFilter/EditInteractionsConfig';
import { SelectFilterTypeConfig } from 'components/FilterConfigs/SelectFilter/FilterTypeConfig';
import { SelectValuesConfig } from 'components/FilterConfigs/SelectFilter/ValuesConfig';
import { updateConfigIfSelectElement } from 'components/FilterConfigs/SelectFilter/selectFilterOperatorTypeUpdater';
import { SwitchAdjustDisplayConfig } from 'components/FilterConfigs/SwitchFilter/AdjustDisplayConfig';
import { SwitchDefaultValueConfig } from 'components/FilterConfigs/SwitchFilter/DefaultValueConfig';
import { SwitchValuesConfig } from 'components/FilterConfigs/SwitchFilter/ValuesConfig';
import { TextInputAdjustDisplayConfig } from 'components/FilterConfigs/TextInputFilter/AdjustDisplayConfig';
import { TextInputDefaultValuesConfig } from 'components/FilterConfigs/TextInputFilter/DefaultValueConfig';
import { TextInputValuesConfig } from 'components/FilterConfigs/TextInputFilter/ValuesConfig';
import { TimePeriodDefaultValuesConfig } from 'components/FilterConfigs/TimePeriodFilter/DefaultValueConfig';
import { TimePeriodEditInteractionsConfig } from 'components/FilterConfigs/TimePeriodFilter/EditInteractionsConfig';
import { TimePeriodValuesConfig } from 'components/FilterConfigs/TimePeriodFilter/ValuesConfig';
import { Tabs } from 'components/ds';
import { FILTER_LINK_ELEMENTS } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import {
  DashboardElement,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  DateElemConfig,
  DateGroupToggleConfig,
  SelectElemConfig,
  SwitchElementConfig,
  TextInputElemConfig,
  TimePeriodDropdownElemConfig,
  LabelWithTooltipConfig,
} from 'types/dashboardTypes';
import { isFilterLinked } from 'utils/filterLinking';
import { areFilterValuesComplete } from 'utils/filterUtils';

import { ElementDependencyConfig } from './ElementDependencyConfig';
import { FilterSectionHeader } from './FilterSectionHeader';
import * as styles from './styles.css';

enum FilterTab {
  SETUP = 'Setup',
  PREFERENCES = 'Preferences',
}

const TabNames = Object.values(FilterTab);

type Props = {
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const FilterConfigPanel: FC<Props> = ({ element, updateConfig }) => {
  const dispatch = useDispatch();

  const [tabId, setTabId] = useState<string>(FilterTab.SETUP);

  const { dataPanels, datasets } = useSelector(
    (state: ReduxState) => ({
      datasets: getEditableDatasets(state),
      dataPanels: state.dashboardEditConfig.config?.data_panels,
    }),
    shallowEqual,
  );

  const renderSection = (
    component: JSX.Element | undefined,
    sectionTitle: string,
    isComplete?: boolean,
  ) => {
    if (!component) return null;
    return (
      <>
        <FilterSectionHeader isComplete={isComplete} title={sectionTitle} />
        <div className={styles.filterSectionContainer}>{component}</div>
      </>
    );
  };

  const renderFilterType = () => {
    let filterTypeDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        filterTypeDiv = (
          <SelectFilterTypeConfig
            dataPanels={dataPanels}
            elementName={element.name}
            elementType={element.element_type}
            updateType={(newType) =>
              dispatch(updateElementConfig({ elementId: element.id, newElementType: newType }))
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        filterTypeDiv = (
          <DateFilterTypeConfig
            config={element.config as DateElemConfig}
            dataPanels={dataPanels}
            dateFilterType={element.element_type}
            elementName={element.name}
            updateDateConfig={updateConfig}
            updateType={(newType) =>
              dispatch(updateElementConfig({ elementId: element.id, newElementType: newType }))
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        filterTypeDiv = (
          <DateGroupFilterTypeConfig
            config={element.config as DateGroupToggleConfig}
            updateConfig={updateConfig}
          />
        );
    }

    return renderSection(filterTypeDiv, 'Filter type', true);
  };

  const renderFilterValues = () => {
    let filterValueDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        filterValueDiv = (
          <SelectValuesConfig
            config={element.config as SelectElemConfig}
            datasets={datasets}
            elementFilterOperator={element.config.operator}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        filterValueDiv = (
          <TextInputValuesConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        filterValueDiv = (
          <SwitchValuesConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        filterValueDiv = (
          <DateGroupValuesConfig
            config={element.config as DateGroupToggleConfig}
            updateToggleConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        filterValueDiv = (
          <TimePeriodValuesConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER: {
        const config = element.config as DateElemConfig;
        filterValueDiv = (
          <DateValuesConfig
            config={config}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
      }
    }

    return renderSection(filterValueDiv, 'Set the filter values', areFilterValuesComplete(element));
  };

  const renderChartLinking = () => {
    if (!FILTER_LINK_ELEMENTS.has(element.element_type)) return null;

    return (
      <>
        {renderSection(
          <OperatorConfig
            additionalConfigUpdateFn={(config, filterOperator) => {
              updateConfigIfSelectElement(
                element.element_type,
                filterOperator,
                config as SelectElemConfig,
              );
            }}
            element={element}
            key={element.id}
            updateConfig={updateConfig}
          />,
          'Select an operator',
          element.config.operator !== undefined,
        )}
        {renderSection(
          <ChartLinkConfig datasets={datasets} element={element} updateConfig={updateConfig} />,
          'Select fields to link charts',
          isFilterLinked(element.config),
        )}
      </>
    );
  };

  const renderDefaultValue = () => {
    let defaultValueDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        defaultValueDiv = (
          <SelectDefaultValueConfig
            config={element.config as SelectElemConfig}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        defaultValueDiv = (
          <TextInputDefaultValuesConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        defaultValueDiv = (
          <SwitchDefaultValueConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        defaultValueDiv = (
          <DateGroupDefaultValuesConfig
            config={element.config as DateGroupToggleConfig}
            updateToggleConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        defaultValueDiv = (
          <TimePeriodDefaultValuesConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        defaultValueDiv = (
          <DateDefaultValuesConfig
            config={element.config as DateElemConfig}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
    }
    return renderSection(defaultValueDiv, 'Set the default value');
  };

  const renderAdjustDisplay = () => {
    let adjustDisplayDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        adjustDisplayDiv = (
          <>
            <LabelConfig
              config={element.config as LabelWithTooltipConfig}
              updateLabelConfig={updateConfig}
            />
            <SelectAdjustDisplayConfig
              config={element.config as SelectElemConfig}
              selectType={element.element_type}
              updateSelectConfig={updateConfig}
            />
          </>
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        adjustDisplayDiv = (
          <TextInputAdjustDisplayConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        adjustDisplayDiv = (
          <SwitchAdjustDisplayConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        adjustDisplayDiv = (
          <LabelConfig
            config={element.config as LabelWithTooltipConfig}
            updateLabelConfig={updateConfig}
          />
        );
    }
    return renderSection(adjustDisplayDiv, 'Adjust display');
  };

  const renderGroupLabels = () => {
    if (element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
      const labelComponent = (
        <DateGroupGroupLabelsConfig
          config={element.config as DateGroupToggleConfig}
          updateToggleConfig={updateConfig}
        />
      );
      return renderSection(labelComponent, 'Group Labels');
    }
    return null;
  };

  const renderEditInteractions = () => {
    let editInteractionsDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        editInteractionsDiv = (
          <SelectEditInteractionsConfig
            config={element.config as SelectElemConfig}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        editInteractionsDiv = (
          <TimePeriodEditInteractionsConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        editInteractionsDiv = (
          <DateEditInteractionsConfig
            config={element.config as DateElemConfig}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
    }
    return renderSection(editInteractionsDiv, 'Edit interactions');
  };

  const renderDependencySelection = () => {
    return renderSection(
      <ElementDependencyConfig
        config={element.config}
        elementId={element.id}
        updateElemConfig={updateConfig}
      />,
      'Dependency Selection',
    );
  };

  const renderSetup = () => (
    <>
      {renderFilterType()}
      {renderFilterValues()}
      {renderChartLinking()}
    </>
  );

  const renderPreferences = () => (
    <>
      {renderDefaultValue()}
      {renderAdjustDisplay()}
      {renderGroupLabels()}
      {renderEditInteractions()}
      {renderDependencySelection()}
    </>
  );

  return (
    <>
      <Tabs onTabSelect={setTabId} selectedTabId={tabId} tabs={TabNames} />
      {tabId === FilterTab.SETUP ? renderSetup() : renderPreferences()}
    </>
  );
};
