import { DashboardAttribute } from 'actions/teamActions';
import { ACTION } from 'actions/types';

import { defineAPIAction, defineAPIPostAction } from './actionUtils';

type FetchDashboardAttributesData = {
  dashboard_attributes: DashboardAttribute[];
};

export const { actionFn: fetchDashboardAttributes, ...fetchDashboardAttributesActions } =
  defineAPIAction<FetchDashboardAttributesData>(
    ACTION.FETCH_DASHBOARD_ATTRIBUTES,
    'dashboard_attributes',
    '',
    'GET',
  );

type CreateDashboardAttributeData = {
  dashboard_attribute: DashboardAttribute;
};

export const {
  actionFn: createDashboardAttribute,
  successAction: createDashboardAttributeSuccess,
} = defineAPIPostAction<{ name: string }, CreateDashboardAttributeData>(
  ACTION.CREATE_DASHBOARD_ATTRIBUTE,
  'dashboard_attributes',
  '',
  'POST',
);

export const {
  actionFn: deleteDashboardAttribute,
  successAction: deleteDashboardAttributeSuccess,
} = defineAPIAction(ACTION.DELETE_DASHBOARD_ATTRIBUTE, 'dashboard_attributes', '', 'DELETE');

type CreateAttributeValueBody = { value: string; color_hex: string; attribute_id: number };

type CreateAttributeValueData = {
  value: { id: number; value: string; color_hex: string; attribute_id: number };
};

export const { actionFn: createAttributeValue, successAction: createAttributeValueSuccess } =
  defineAPIPostAction<CreateAttributeValueBody, CreateAttributeValueData>(
    ACTION.CREATE_ATTRIBUTE_VALUE,
    'dashboard_attributes',
    'create_value',
    'POST',
  );

type DeleteAttributeValueBody = {
  id: number;
  attrId: number;
};

export const { actionFn: deleteAttributeValue, successAction: deleteAttributeValueSuccess } =
  defineAPIPostAction<DeleteAttributeValueBody, {}>(
    ACTION.DELETE_ATTRIBUTE_VALUE,
    'dashboard_attributes',
    'delete_value',
    'DELETE',
  );

type AssignDashboardValueBody = {
  template_id: number;
  attribute_id: number;
  value_id: string;
};

export const { actionFn: assignDashboardValue, successAction: assignDashboardValueSuccess } =
  defineAPIPostAction<AssignDashboardValueBody, {}>(
    ACTION.ASSIGN_DASHBOARD_VALUE,
    'dashboard_attributes',
    'assign_value',
    'POST',
  );
