import { FC } from 'react';

import { Button, IconButton, sprinkles } from 'components/ds';
import { Props as TooltipProps } from 'components/ds/Tooltip';

type Props = {
  saveText: string;
  onRevertDraft?: () => void;
  onPreview?: () => void;
  onSave?: () => void;
  onFormat: () => void;
  saveTooltipProps?: TooltipProps;
};

/**
 * Provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const QueryRunButtons: FC<Props> = ({
  saveText,
  onRevertDraft,
  onPreview,
  onSave,
  onFormat,
  saveTooltipProps,
}) => {
  const handleClick = (e: React.MouseEvent, onClickFn: (() => void) | undefined) => {
    if (!onClickFn) return;
    // Prevent query panel from expanding/collapsing when button is clicked
    e.stopPropagation();
    onClickFn();
  };

  return (
    <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
      <IconButton
        disabled={!onRevertDraft}
        name="trashUndo"
        onClick={(e) => handleClick(e, onRevertDraft)}
        tooltipProps={{ text: 'Revert draft' }}
        variant="primary"
      />
      <IconButton
        name="wrench"
        onClick={(e) => handleClick(e, onFormat)}
        tooltipProps={{ text: 'Format SQL (⌘+⇧+F)' }}
        variant="primary"
      />
      <Button
        disabled={!onPreview}
        onClick={(e) => handleClick(e, onPreview)}
        tooltipProps={{ text: 'Preview results without saving' }}
        variant="secondary">
        Preview
      </Button>
      <Button
        disabled={!onSave}
        icon="play"
        onClick={(e) => handleClick(e, onSave)}
        tooltipProps={saveTooltipProps}
        variant="primary">
        {saveText}
      </Button>
    </div>
  );
};
