import { FC } from 'react';

import { Input, Toggle, ToggleItem } from 'components/ds';
import {
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS,
  KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS,
  KPI_ORDERED_VERT_CONTENT_ALIGNMENTS,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS,
  KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS,
} from 'constants/kpiAlignmentConstants';
import { KPIGeneralFormat, V2KPIChartInstructions } from 'constants/types';
import {
  TextElemHorizAlignments,
  TitleValueArrangements,
  VerticalContentAlignments,
} from 'types/dashboardTypes';

type Props = {
  configInputClass: string;
  instructions: V2KPIChartInstructions;

  updateGeneralFormat: (generalFormat: KPIGeneralFormat) => void;
};

export const SharedKPIGeneralConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  updateGeneralFormat,
}) => {
  const selectedHorizAlignment =
    instructions.generalFormat?.alignment || KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.CENTER_ALIGN.id;

  const selectedVertAlignment =
    instructions.generalFormat?.vertical_content_alignment ||
    KPI_VERT_CONTENT_ALIGNMENT_FORMATS.CENTER.id;

  const selectedTitleArrangement =
    instructions?.generalFormat?.title_value_arrangement ||
    KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.ABOVE.id;

  return (
    <>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={instructions.generalFormat?.subtitle}
        label={{ text: 'Description', variableInput: true }}
        onSubmit={(newValue) => updateGeneralFormat({ subtitle: newValue })}
      />
      <Toggle
        className={configInputClass}
        label="Title/Value Arrangement"
        onValueChange={(value) =>
          updateGeneralFormat({ title_value_arrangement: value as TitleValueArrangements })
        }
        selectedValue={selectedTitleArrangement}>
        {KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
      <Toggle
        className={configInputClass}
        label="Vertical Alignment"
        onValueChange={(value) =>
          updateGeneralFormat({ vertical_content_alignment: value as VerticalContentAlignments })
        }
        selectedValue={selectedVertAlignment}>
        {KPI_ORDERED_VERT_CONTENT_ALIGNMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
      <Toggle
        className={configInputClass}
        label="Horizontal Alignment"
        onValueChange={(value) =>
          updateGeneralFormat({ alignment: value as TextElemHorizAlignments })
        }
        selectedValue={selectedHorizAlignment}>
        {KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
    </>
  );
};
