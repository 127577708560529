import { FC } from 'react';

import * as styles from './ImageAndText.css';

type Props = { img: string; alt: string };

export const ImageAndText: FC<Props> = ({ img, alt, children }) => (
  <div className={styles.container}>
    <div className={styles.fitText}>{children}</div>
    <img alt={alt} className={styles.fitImage} src={img} />
  </div>
);
