import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { Icon } from 'components/ds';

import * as styles from './index.css';

export const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  return (
    <div className={styles.container}>
      <div className={styles.error}>
        <Icon name="circle-exclamation-reg" size="lg" />
        <p className={styles.message}>{error.message}</p>
      </div>
    </div>
  );
};
