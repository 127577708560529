import { FC, useState } from 'react';

import { Tabs, sprinkles } from 'components/ds';

import { DataDebugger } from './DataDebugger';
import { QueryDebugger } from './QueryDebugger';

enum DEBUGGER_TABS {
  QUERY = 'Query',
  DATA = 'Data',
}

const TabNames = Object.values(DEBUGGER_TABS);

export const DashboardDebugger: FC = () => {
  const [selectedTabId, setSelectedTabId] = useState<string>(DEBUGGER_TABS.QUERY);

  return (
    <div className={rootClass}>
      <Tabs onTabSelect={setSelectedTabId} selectedTabId={selectedTabId} tabs={TabNames} />
      <div className={sprinkles({ flex: 1, overflowY: 'auto', width: 'fill' })}>
        {selectedTabId === DEBUGGER_TABS.QUERY ? <QueryDebugger /> : <DataDebugger />}
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  overflow: 'hidden',
  flexItems: 'column',
  borderLeft: 1,
  borderColor: 'gray7',
});
