import cx from 'classnames';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsFunnel from 'highcharts/modules/funnel';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsReact from 'highcharts-react-official';
import { FC, useMemo, useState } from 'react';
import ResizeObserver from 'react-resize-observer';

import { sprinkles } from 'components/ds';
import { useBoost } from 'pages/dashboardPage/charts/utils/useBoost';

HighchartsHeatMap(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);
HighchartsSankey(Highcharts);

type Props = {
  chartOptions: Highcharts.Options | undefined;
  className?: string;
};

type ChartSizeType = { width?: number; height?: number };

export const HighCharts: FC<Props> = ({ chartOptions, className }) => {
  const [chartSize, setChartSize] = useState<ChartSizeType>();
  const loadedStart = useMemo(() => Date.now(), []);

  useBoost(chartOptions);
  return (
    <div className={cx(sprinkles({ height: 'fill' }), className)} style={{ overflow: 'hidden' }}>
      <HighchartsReact
        callback={(chart: Highcharts.Chart) => chartCallback(chart, loadedStart)}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        highcharts={Highcharts}
        options={{
          ...chartOptions,
          chart: {
            ...chartOptions?.chart,
            width: chartSize?.width,
            height: chartSize?.height,
          },
          credits: { enabled: false },
        }}
      />
      <ResizeObserver
        onResize={(rect) => setChartSize({ width: rect.width, height: rect.height })}
      />
    </div>
  );
};

const chartCallback = (chart: Highcharts.Chart, loadedStart: number) => {
  window.DD_RUM?.addTiming(
    `${chart.userOptions.chart?.type + '_chart' || 'chart'}_loaded`,
    loadedStart,
  );
};
