import { createReducer } from '@reduxjs/toolkit';

import {
  AdminTeam,
  AdminUser,
  superuserGetTeamsActions,
  superuserGetTeamActions,
  superuserGetTeamUsersActions,
  superuserRemoveUserActions,
  superuserUpdateTeamActions,
} from 'actions/superuserActions';
import * as RD from 'remotedata';

interface SuperuserReducerState {
  team: RD.ResponseData<AdminTeam>;
  users: RD.ResponseData<AdminUser[]>;
  teams: RD.ResponseData<{ team_name: string; id: number }[]>;
}
const initialState: SuperuserReducerState = {
  teams: RD.Idle(),
  team: RD.Idle(),
  users: RD.Idle(),
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(superuserGetTeamsActions.successAction, (state, { payload }) => {
      state.teams = RD.Success(payload.teams);
    })
    .addCase(superuserGetTeamUsersActions.successAction, (state, { payload }) => {
      state.users = RD.Success(payload.users);
    })
    .addCase(superuserGetTeamActions.successAction, (state, { payload }) => {
      state.team = RD.Success(payload.team);
    })
    .addCase(superuserRemoveUserActions.successAction, (state, { payload }) => {
      if (!RD.isSuccess(state.users)) return;

      const users = state.users.data;
      const index = users.findIndex((user) => (user.id = payload.postData.user_id));
      if (index) state.users = RD.Success(users.splice(index, 1));
    })
    .addCase(superuserUpdateTeamActions.successAction, (state, { payload }) => {
      state.team = RD.Success(payload.team);
    });
});
