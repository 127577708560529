import cx from 'classnames';
import { forwardRef, ReactNode } from 'react';

import { ModalSize } from 'components/ds/Modal';
import * as styles from 'components/ds/Modal/index.css';

type Props = {
  size: ModalSize;
  className?: string;
  children: ReactNode;
};

export const EmbedModalContent = forwardRef<HTMLDivElement, Props>(
  ({ size, className, children }, ref) => {
    return (
      <div className={cx(styles.childContent({ size }), className)} ref={ref}>
        {children}
      </div>
    );
  },
);

EmbedModalContent.displayName = 'EmbedModalContent';
