import { Popover } from '@blueprintjs/core';
import cx from 'classnames';
import Color from 'color';
import { FC, useState } from 'react';
import { BlockPicker } from 'react-color';

import { Button, sprinkles, vars } from 'components/ds';

import * as styles from './index.css';

export type Props = {
  color?: string;
  alpha?: number;
  onClose: (color: string) => void;
  fill?: boolean;
  buttonClassName?: string;
  className?: string;
  colors?: string[];
  label?: string;
};

export const ColorPicker: FC<Props> = ({
  color,
  onClose,
  fill,
  alpha,
  buttonClassName,
  className,
  colors = [],
  label,
}) => {
  const [selectedColor, setSelectedColor] = useState(color ?? '');
  const colorObject = new Color(color);

  if (!color) {
    return (
      <Button disabled fillWidth={fill}>
        —
      </Button>
    );
  }

  const colorText = colorObject.hex();
  const alphaText = alpha === undefined ? '' : ` ${alpha * 100}%`;
  const buttonText = label === undefined ? `${colorText}${alphaText}` : label;
  return (
    <Popover
      minimal
      className={cx({ [styles.fill]: fill }, className)}
      onClose={() => onClose(selectedColor)}>
      <Button
        fillWidth
        className={cx(styles.colorButton, buttonClassName)}
        style={{
          backgroundColor: colorObject.hex(),
          color: colorObject.isDark() ? vars.colors.white : vars.colors.black,
        }}>
        {buttonText}
      </Button>
      <BlockPicker
        className={sprinkles({ textTransform: 'uppercase' })}
        color={selectedColor}
        colors={colors}
        onChange={(color) => setSelectedColor(color.hex)}
        triangle="hide"
        // @ts-ignore
        width={180}
      />
    </Popover>
  );
};
