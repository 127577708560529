import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportConfig } from 'actions/customerReportActions';
import { EmbedButton } from 'components/embed';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { DRAFT_REPORT_ID } from 'pages/ReportBuilder/constants';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';
import {
  createCustomerReportThunk,
  saveCustomerReportThunk,
} from 'reportBuilderContent/thunks/reportThunks';

interface Props {
  currentConfig: CustomerReportConfig;
  reportId: number;
  hasUnsavedChanges: boolean;
  reportName: string;
}

export const SaveButton: FC<Props> = ({
  reportId,
  currentConfig,
  hasUnsavedChanges,
  reportName,
}) => {
  const dispatch = useDispatch();

  const isPreview = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.isPreview,
  );

  const menuItems = useMemo(
    () => [
      {
        disabled: isPreview || !hasUnsavedChanges,
        name: 'Save',
        onClick: () => {
          reportId === DRAFT_REPORT_ID
            ? dispatch(createCustomerReportThunk({ name: reportName, config: currentConfig }))
            : dispatch(saveCustomerReportThunk({ report_id: reportId, config: currentConfig }));
        },
      },
      {
        disabled: isPreview,
        name: 'Save as',
        onClick: () =>
          dispatch(openModalType({ type: ReportModalType.SAVE_AS, reportId: reportId })),
      },
    ],
    [currentConfig, dispatch, hasUnsavedChanges, isPreview, reportId, reportName],
  );

  return (
    <EmbeddedDropdownMenu menuOptions={menuItems}>
      <EmbedButton variant="secondary">Save</EmbedButton>
    </EmbeddedDropdownMenu>
  );
};
