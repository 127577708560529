import { v4 as uuidv4 } from 'uuid';

import {
  TEXT_ELEM_ICON,
  DROPDOWN_ELEM_ICON,
  TIME_PERIOD_DROPDOWN_ELEM_ICON,
  DATEPICKER_ELEM_ICON,
  TOGGLE_ELEM_ICON,
  IMAGE_ELEM_ICON,
  CONTAINER_ELEM_ICON,
  SHARE_ELEM_ICON,
  DATA_TABLE_ICON,
  DATA_PANEL_V2_ICON,
  KPI_CHART_ELEM_ICON,
  LINE_CHART_ELEM_ICON,
  AREA_CHART_ELEM_ICON,
  DONUT_CHART_ELEM_ICON,
  FUNNEL_CHART_ELEM_ICON,
  HEAT_MAP_ELEM_ICON,
  CALENDAR_HEATMAP_ELEM_ICON,
  BOX_PLOT_ELEM_ICON,
  SWITCH_ELEM_ICON,
  PIVOT_TABLE_ICON,
  APPLY_BUTTON_ICON,
  REFRESH_BUTTON_ICON,
  EVENT_ELEM_ICON,
  TEXT_INPUT_ELEM_ICON,
  SCATTER_PLOT_ELEM_ICON,
  SPIDER_CHART_ELEM_ICON,
  IFRAME_ELEM_ICON,
  MULTISELECT_ELEM_ICON,
  SPACER_ELEM_ICON,
  CHOROPLETH_MAP_ICON,
  SANKEY_CHART_ELEM_ICON,
  COLLAPSIBLE_LIST_ICON,
} from 'constants/iconConstants';
import { COLUMN_FITS, GradientType, INPUT_TYPE, OPERATION_TYPES } from 'constants/types';
import {
  DashboardElementConfig,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TEXT_ELEM_SIZES,
  TEXT_ELEM_VERTICAL_ALIGNMENTS,
  DASHBOARD_ELEMENT_TYPES,
  SELECT_FILTER_TYPE,
  DATE_FILTER_TYPE,
} from 'types/dashboardTypes';
import { DataPanelTemplate, VisualizeOperation } from 'types/dataPanelTemplate';

// draggableHandle is used by react grid layout to know what to drag off of
export const DRAGGABLE_HANDLE_CLASS = 'draggableHandle';

export const MOBILE_BREAKPOINT_WIDTH = 768;

export const DASHBOARD_ROW_HEIGHT = 50;

export const NUM_MOBILE_COLUMNS = 1;

export const ROWS_PER_PDF_PAGE = 10;
export const PDF_PAGE_HEIGHT = 792;
export const PDF_PAGE_BREAK_HEIGHT = 10;
export const PDF_EDITOR_PAGE_BORDER_WIDTH = 1;
// PDF_EDITOR_MARGIN_SIZE = (PDF_PAGE_HEIGHT - (ROWS_PER_PDF_PAGE * DASHBOARD_ROW_HEIGHT)) / ROWS_PER_PDF_PAGE
export const PDF_EDITOR_MARGIN_SIZE = 29.2;

export const COLOR_CATEGORY_FILTER_SUFFIX = '.EXPLO_COLOR_CATEGORY';
export const NONE_CATEGORY_COLOR_VALUE = '_no_group_by';
export const GENERIC_DROPPING_ID = 'dropping-element';
export const MONTH_SUFFIX = '.calendar_month';
export const YEAR_SUFFIX = '.calendar_year';

export const droppingElementId = (elemType?: string) => {
  if (elemType === undefined) return '';
  return `dropping-element-${elemType}`;
};

export const DEFAULT_DATA_PANEL_WIDTH = 5;
export const DEFAULT_DATA_PANEL_HEIGHT = 4;

export const DRAGGING_ITEM_CONFIG_BY_TYPE: Record<string, { i: string; w: number; h: number }> = {
  [DASHBOARD_ELEMENT_TYPES.DATA_PANEL]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATA_PANEL),
    w: DEFAULT_DATA_PANEL_WIDTH,
    h: DEFAULT_DATA_PANEL_HEIGHT,
  },
  [DASHBOARD_ELEMENT_TYPES.TEXT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TEXT),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.SPACER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.SPACER),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DROPDOWN]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DROPDOWN),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.MULTISELECT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.MULTISELECT),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATEPICKER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATEPICKER),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.EXPORT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.EXPORT),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.IMAGE]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.IMAGE),
    w: 6,
    h: 3,
  },
  [DASHBOARD_ELEMENT_TYPES.TOGGLE]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TOGGLE),
    w: 4,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.SWITCH]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.SWITCH),
    w: 3,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH),
    w: 4,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.CONTAINER]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.CONTAINER),
    w: 5,
    h: 4,
  },
  [DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.TEXT_INPUT]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.TEXT_INPUT),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.IFRAME]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.IFRAME),
    w: 5,
    h: 5,
  },
  [DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON),
    w: 2,
    h: 1,
  },
  [DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON]: {
    i: droppingElementId(DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON),
    w: 2,
    h: 1,
  },
};

const defaultDataPanelDimensions = { w: 5, h: 4 };

export const getDraggingConfig = (draggingElemType: string | null) => {
  if (!draggingElemType) return;
  if (draggingElemType.indexOf('data-panel-') >= 0) {
    const { w, h } = defaultDataPanelDimensions;
    return { i: droppingElementId(draggingElemType), w, h };
  }

  return DRAGGING_ITEM_CONFIG_BY_TYPE[draggingElemType];
};

export const TEXT_ELEM_SIZE_CONFIGS = {
  [TEXT_ELEM_SIZES.SMALL]: {
    value: TEXT_ELEM_SIZES.SMALL,
    label: 'Body',
  },
  [TEXT_ELEM_SIZES.MEDIUM]: {
    value: TEXT_ELEM_SIZES.MEDIUM,
    label: 'Chart Title',
  },
  [TEXT_ELEM_SIZES.LARGE]: {
    value: TEXT_ELEM_SIZES.LARGE,
    label: 'H1',
  },
};

const IGNORE_DEPENDENCIES: DashboardElementConfig = {
  ignoreDependencies: true,
};

export const BASE_CONFIG_BY_DASH_ELEM: Record<DASHBOARD_ELEMENT_TYPES, DashboardElementConfig> = {
  [DASHBOARD_ELEMENT_TYPES.TEXT]: {
    alignmentHoriz: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT,
    alignmentVertical: TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP,
    textColor: '#000',
    textSize: TEXT_ELEM_SIZES.SMALL,
  },
  [DASHBOARD_ELEMENT_TYPES.DROPDOWN]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Numbers',
    valuesConfig: {
      valuesSource: INPUT_TYPE.MANUAL,
      manualValues: '[1, 2, 3]',
      manualDisplays: '["One", "Two", "Three"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Filter',
    values: [
      { value: 15, name: 'Last 15 minutes' },
      { value: 30, name: 'Last 30 minutes' },
      { value: 60, name: 'Last 1 hour' },
      { value: 1440, name: 'Last 24 hours' },
      { value: 10080, name: 'Last 7 days' },
    ],
  },

  [DASHBOARD_ELEMENT_TYPES.MULTISELECT]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Numbers',
    valuesConfig: {
      valuesSource: INPUT_TYPE.MANUAL,
      manualValues: '[1, 2, 3]',
      manualDisplays: '["One", "Two", "Three"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.DATEPICKER]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Date',
    hideTimeSelect: true,
    endDateEndOfDay: true,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Date Range',
    hideTimeSelect: true,
    endDateEndOfDay: true,
  },
  [DASHBOARD_ELEMENT_TYPES.EXPORT]: {
    label: 'Share',
  },
  [DASHBOARD_ELEMENT_TYPES.TOGGLE]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Toggle',
    valuesConfig: {
      valuesSource: INPUT_TYPE.MANUAL,
      manualValues: '["day", "week", "month"]',
      manualDisplays: '["Day", "Week", "Month"]',
    },
  },
  [DASHBOARD_ELEMENT_TYPES.SWITCH]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Switch',
    showSwitchLabel: true,
  },
  [DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH]: {
    ...IGNORE_DEPENDENCIES,
    label: 'Date Grouping',
  },
  [DASHBOARD_ELEMENT_TYPES.CONTAINER]: {
    layout: [],
  },
  [DASHBOARD_ELEMENT_TYPES.IMAGE]: {},
  [DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON]: {},
  [DASHBOARD_ELEMENT_TYPES.TEXT_INPUT]: { ...IGNORE_DEPENDENCIES },
  [DASHBOARD_ELEMENT_TYPES.IFRAME]: {},
  [DASHBOARD_ELEMENT_TYPES.DATA_PANEL]: {},
  [DASHBOARD_ELEMENT_TYPES.SPACER]: {},
  [DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON]: {},
  [DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON]: {},
};

export enum ELEMENT_GROUPS {
  CHARTS = 'Charts',
  CONTROLS = 'Controls',
  EXPORT = 'Export',
  DISPLAY = 'Display',
}

export const DASHBOARD_ELEMENT_TYPE_TO_NAME: Record<DASHBOARD_ELEMENT_TYPES, string> = {
  DATA_PANEL: 'Data Panel',

  DROPDOWN: 'Dropdown',
  TIME_PERIOD_DROPDOWN: 'Time Dropdown',
  MULTISELECT: 'Multiselect',
  DATEPICKER: 'Date Picker',
  DATE_RANGE_PICKER: 'Date Range Picker',
  TOGGLE: 'Toggle',
  DATE_GROUP_SWITCH: 'Date Group Toggle',
  SWITCH: 'Switch',
  APPLY_FILTER_BUTTON: 'Apply Filter Button',
  TEXT_INPUT: 'Text Input',
  EXPORT: 'Share Button',
  TEXT: 'Text',
  SPACER: 'Spacer',
  IMAGE: 'Image',
  CONTAINER: 'Container',
  IFRAME: 'Iframe',
  REFRESH_BUTTON: 'Refresh Button',
  EVENT_BUTTON: 'JS Event Button',
};

export const ELEM_TYPE_TO_ICON: { [id: string]: JSX.Element } = {
  [DASHBOARD_ELEMENT_TYPES.DROPDOWN]: DROPDOWN_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN]: TIME_PERIOD_DROPDOWN_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.MULTISELECT]: MULTISELECT_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.DATEPICKER]: DATEPICKER_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER]: DATEPICKER_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH]: TOGGLE_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.TOGGLE]: TOGGLE_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.SWITCH]: SWITCH_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.TEXT_INPUT]: TEXT_INPUT_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.EXPORT]: SHARE_ELEM_ICON,
  [DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON]: APPLY_BUTTON_ICON,
  [DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON]: REFRESH_BUTTON_ICON,
};

export const DROPPABLE_DASHBOARD_ELEMENT_CONFIGS: {
  id: string;
  icon: JSX.Element;
  name: string;
  group: ELEMENT_GROUPS;
}[] = [
  // CHARTS
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_TABLE}`,
    icon: DATA_TABLE_ICON,
    name: 'Data Table',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_NUMBER_V2}`,
    icon: KPI_CHART_ELEM_ICON,
    name: 'KPI Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}`,
    icon: DATA_PANEL_V2_ICON,
    name: 'Bar Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_LINE_CHART_V2}`,
    icon: LINE_CHART_ELEM_ICON,
    name: 'Line Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_AREA_CHART_V2}`,
    icon: AREA_CHART_ELEM_ICON,
    name: 'Area Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_PIE_CHART_V2}`,
    icon: DONUT_CHART_ELEM_ICON,
    name: 'Pie Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_FUNNEL_V2}`,
    icon: FUNNEL_CHART_ELEM_ICON,
    name: 'Funnel',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2}`,
    icon: HEAT_MAP_ELEM_ICON,
    name: 'Heat Map',
    group: ELEMENT_GROUPS.CHARTS,
  },

  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_SPIDER_CHART}`,
    icon: SPIDER_CHART_ELEM_ICON,
    name: 'Spider Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2}`,
    icon: SCATTER_PLOT_ELEM_ICON,
    name: 'Scatter Plot Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2}`,
    icon: BOX_PLOT_ELEM_ICON,
    name: 'Box Plot',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_PIVOT_TABLE}`,
    icon: PIVOT_TABLE_ICON,
    name: 'Pivot Table',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2}`,
    icon: PIVOT_TABLE_ICON,
    name: 'Pivot Table',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}`,
    icon: COLLAPSIBLE_LIST_ICON,
    name: 'Collapsible List',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP}`,
    icon: CHOROPLETH_MAP_ICON,
    name: 'Map',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_SANKEY_CHART}`,
    icon: SANKEY_CHART_ELEM_ICON,
    name: 'Sankey Chart',
    group: ELEMENT_GROUPS.CHARTS,
  },
  {
    id: `data-panel-${OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP}`,
    icon: CALENDAR_HEATMAP_ELEM_ICON,
    name: 'Calendar Heat Map',
    group: ELEMENT_GROUPS.CHARTS,
  },

  // CONTROLS
  {
    id: DASHBOARD_ELEMENT_TYPES.DROPDOWN,
    icon: DROPDOWN_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.DROPDOWN],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.TOGGLE,
    icon: TOGGLE_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.TOGGLE],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    icon: DATEPICKER_ELEM_ICON,
    name: 'Date',
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.SWITCH,
    icon: SWITCH_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.SWITCH],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
    icon: TEXT_INPUT_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.TEXT_INPUT],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN,
    icon: TIME_PERIOD_DROPDOWN_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
    icon: TOGGLE_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON,
    icon: APPLY_BUTTON_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON,
    icon: REFRESH_BUTTON_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON],
    group: ELEMENT_GROUPS.CONTROLS,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON,
    icon: EVENT_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON],
    group: ELEMENT_GROUPS.CONTROLS,
  },

  // EXPORTS
  {
    id: DASHBOARD_ELEMENT_TYPES.EXPORT,
    icon: SHARE_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.EXPORT],
    group: ELEMENT_GROUPS.EXPORT,
  },

  // DISPLAYS
  {
    id: DASHBOARD_ELEMENT_TYPES.TEXT,
    icon: TEXT_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.TEXT],
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.SPACER,
    icon: SPACER_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.SPACER],
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.IMAGE,
    icon: IMAGE_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.IMAGE],
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.CONTAINER,
    icon: CONTAINER_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.CONTAINER],
    group: ELEMENT_GROUPS.DISPLAY,
  },
  {
    id: DASHBOARD_ELEMENT_TYPES.IFRAME,
    icon: IFRAME_ELEM_ICON,
    name: DASHBOARD_ELEMENT_TYPE_TO_NAME[DASHBOARD_ELEMENT_TYPES.IFRAME],
    group: ELEMENT_GROUPS.DISPLAY,
  },
];

export const EMPTY_DATA_PANEL_STATE = (
  id: string,
  datasetId: string,
  vizType: OPERATION_TYPES,
  providedId: string,
  name?: string,
  containerId?: string,
): DataPanelTemplate => ({
  id,
  provided_id: providedId,
  container_id: containerId,
  table_id: datasetId ?? '',
  filter_op: EMPTY_FILTER_OP_STATE(),
  group_by_op: EMPTY_GROUP_BY_OP_STATE(),
  visualize_op: EMPTY_VISUALIZATION_OP_STATE(vizType, providedId, name),
});

export const EMPTY_FILTER_OP_STATE = () => ({
  operation_type: OPERATION_TYPES.FILTER,
  instructions: {
    matchOnAll: true,
    filterClauses: [],
  },
});

export const EMPTY_GROUP_BY_OP_STATE = () => ({
  operation_type: OPERATION_TYPES.GROUP_BY,
  instructions: { aggregations: [] },
});

const EMPTY_VISUALIZATION_OP_STATE = (
  vizType: OPERATION_TYPES,
  providedId: string,
  name?: string,
): VisualizeOperation => {
  return {
    operation_type: vizType,
    instructions: EMPTY_VISUALIZATION_INSTRUCTIONS(),
    generalFormatOptions: {
      export: {
        isDownloadButtonHidden: false,
        downloadFileName: providedId,
      },
      headerConfig: { title: name },
    },
  };
};

export const EMPTY_VISUALIZATION_INSTRUCTIONS = () => ({
  VISUALIZE_TABLE: {
    columnFit: COLUMN_FITS.FILL,
    changeSchemaList: [],
    schemaDisplayOptions: {},
    shouldTruncateText: true,
  },
  V2_TWO_DIMENSION_CHART: {
    xAxisFormat: { hideTotalValues: true },
    yAxisFormats: [{ id: uuidv4() }],
    chartSpecificFormat: {
      lineChart: { hideMarkers: true },
      heatMap: { gradientType: GradientType.DIVERGING },
    },
    legendFormat: { hideLegend: true },
  },
  V2_KPI: { valueFormat: { unitPadding: true } },
  V2_KPI_TREND: { valueFormat: { unitPadding: true } },
  V2_BOX_PLOT: { legendFormat: { hideLegend: true } },
  V2_SCATTER_PLOT: { legendFormat: { hideLegend: true } },
  VISUALIZE_COLLAPSIBLE_LIST: {},
});

export enum COMBO_CHART_DATA_FORMATS {
  BAR = 'column',
  LINE = 'line',
  DOT = 'dot',
  AREA = 'area',
}

export enum Timezones {
  USER_LOCAL_TIME = 'USER_LOCAL_TIME',
  UTC = 'UTC',
}

export const TIMEZONES = [
  { value: Timezones.USER_LOCAL_TIME, label: 'User Local Time' },
  { value: Timezones.UTC, label: 'UTC' },
];

export const FILTER_ELEMENTS = new Set([
  DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.SWITCH,
  DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
  DASHBOARD_ELEMENT_TYPES.DATEPICKER,
  DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
  DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.TOGGLE,
  DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
]);

export const STICKY_ELEMENTS = new Set([
  DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.SWITCH,
  DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
  DASHBOARD_ELEMENT_TYPES.DATEPICKER,
  DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
  DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.TOGGLE,
  DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
  DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON,
  DASHBOARD_ELEMENT_TYPES.EXPORT,
  DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON,
]);

export const FILTER_LINK_ELEMENTS = new Set([
  DASHBOARD_ELEMENT_TYPES.SWITCH,
  DASHBOARD_ELEMENT_TYPES.DATEPICKER,
  DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
  DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.TOGGLE,
  DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
]);

export const SELECT_ELEMENTS: SELECT_FILTER_TYPE[] = [
  DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  DASHBOARD_ELEMENT_TYPES.TOGGLE,
];

export const SELECT_ELEMENT_SET: Set<DASHBOARD_ELEMENT_TYPES> = new Set(SELECT_ELEMENTS);

export const DATE_ELEMENTS: DATE_FILTER_TYPE[] = [
  DASHBOARD_ELEMENT_TYPES.DATEPICKER,
  DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
];

export const DATE_ELEMENT_SET: Set<DASHBOARD_ELEMENT_TYPES> = new Set(DATE_ELEMENTS);

export const ChartShapeBorderDefaultColor = '#FFFFFF';
export const chartShapeBorderDefaultWidth = 1;

export enum UNSUPPORTED_CHART_ERROR {
  DESCRIPTION = 'Please contact support or delete chart.',
  TITLE = 'The chart is no longer available.',
}

export const STANDARD_PIE_CHART_SIZE = '75%';
