import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';
import { Input } from 'components/ds';
import {
  CustomMenuConfig as CustomMenuConfigType,
  CustomMenuOptionType,
  CustomMenuOptionConfig,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { showErrorToast } from 'shared/sharedToasts';
import { OUTPUT_EVENT } from 'types/customEventTypes';

type Props = {
  options: VisualizeOperationGeneralFormatOptions;
};

const defaultOption: CustomMenuOptionConfig = {
  name: '',
  type: CustomMenuOptionType.CUSTOM_JS_EVENT,
  customJSEventName: '',
};

export const CustomMenuConfig: FC<Props> = ({ options }) => {
  const dispatch = useDispatch();

  const menuConfig = options.customMenu ?? {};
  const firstOption = menuConfig.menuOptions?.[0] ?? defaultOption;

  const updateConfig = (updates: Partial<CustomMenuConfigType>) => {
    const newOptions = produce(options, (draft) => {
      draft.customMenu = { ...draft.customMenu, ...updates };
    });

    dispatch(updateGeneralFormatOptions(newOptions));
  };

  return (
    <ConfigSubSectionWithSwitch
      label="Custom menu javascript event"
      onChange={() => updateConfig({ enabled: !menuConfig.enabled })}
      switchOn={!!menuConfig.enabled}>
      {/* We are only supporting a single custom menu option for now, so this is hardcoding to edit the first option */}
      <Input
        defaultValue={firstOption.name}
        label="Menu Label"
        onSubmit={(newValue) => updateConfig({ menuOptions: [{ ...firstOption, name: newValue }] })}
      />
      <Input
        defaultValue={firstOption.customJSEventName ?? ''}
        label="JS Event Name"
        onSubmit={(newValue) => {
          if (RESERVED_EVENT_NAMES.has(newValue)) return showErrorToast(RESERVED_ERROR_MSG);
          updateConfig({ menuOptions: [{ ...firstOption, customJSEventName: newValue }] });
        }}
      />
    </ConfigSubSectionWithSwitch>
  );
};

const RESERVED_EVENT_NAMES = new Set<string>(Object.values(OUTPUT_EVENT));
const RESERVED_ERROR_MSG = `JS Event Name is already in use. Please choose a different name.`;
