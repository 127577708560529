import { Tooltip } from '@blueprintjs/core';
import { FC } from 'react';

import { ProgressBar } from 'components/ProgressBar';
import { sprinkles } from 'components/ds';

type Props = {
  value: number;
  progressBarGoal?: number;
  disableTooltip?: boolean;
  formattedValue: string;
  backgroundColor: string;
  color: string;
};

export const TableProgressBar: FC<Props> = ({
  value,
  progressBarGoal,
  disableTooltip,
  formattedValue,
  backgroundColor,
  color,
}) => {
  const bar = (
    <div className={sprinkles({ flexItems: 'alignCenter' })}>
      <div className={sprinkles({ marginRight: 'sp.5', body: 'b3' })}>{formattedValue}</div>
      <div className={sprinkles({ flex: 1 })}>
        <ProgressBar
          backgroundColor={backgroundColor}
          color={color}
          height={12}
          value={progressBarGoal ? value / progressBarGoal : 0}
        />
      </div>
    </div>
  );

  if (disableTooltip) {
    return bar;
  } else {
    return (
      <Tooltip
        content={`${value} / ${progressBarGoal}`}
        targetClassName={sprinkles({ width: 'fill' })}>
        {bar}
      </Tooltip>
    );
  }
};
