import { ViewState } from 'react-map-gl';

import { BoundaryFormat, BoundaryType, MapStyles } from 'types/maps';

export const INITIAL_VIEW_STATE: Partial<ViewState> = {
  latitude: 40,
  longitude: -100,
  zoom: 2,
};

export const MARKER_SIZE = 20;
export const MARKER_COLOR = 'red';
export const MARKER_OUTLINE_COLOR = 'black';
export const MARKER_OUTLINE_WIDTH = 15;

export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/light-v9';

export const MAP_STYLES_SELECT_VALUES = [
  { label: 'Light', value: MapStyles.LIGHT },
  { label: 'Dark', value: MapStyles.DARK },
  { label: 'Satellite', value: MapStyles.SATELLITE },
  { label: 'Streets', value: MapStyles.STREETS },
  { label: 'Outdoors', value: MapStyles.OUTDOORS },
];

export const MAP_STYLE_TO_MAPBOX_URL: Record<string, string> = {
  [MapStyles.LIGHT]: 'mapbox://styles/mapbox/light-v9',
  [MapStyles.DARK]: 'mapbox://styles/mapbox/dark-v11',
  [MapStyles.OUTDOORS]: 'mapbox://styles/mapbox/outdoors-v12',
  [MapStyles.STREETS]: 'mapbox://styles/mapbox/streets-v12',
  [MapStyles.SATELLITE]: 'mapbox://styles/mapbox/satellite-v9',
};

export const BOUNDARY_FORMAT_TO_MATCH: Record<BoundaryFormat, string> = {
  [BoundaryFormat.COUNTRY_NAME]: 'ADMIN',
  [BoundaryFormat.COUNTRY_CODE_3]: 'ISO_A3',
  [BoundaryFormat.COUNTRY_CODE_2]: 'ISO_A2',
  [BoundaryFormat.STATE_NAME]: 'NAME',
  [BoundaryFormat.STATE_CODE]: 'STUSPS',
  [BoundaryFormat.ZIP_CODE]: 'GEOID20',
};

export const BOUNDARY_TYPE_TO_SOURCE_LAYER: Record<BoundaryType, string> = {
  [BoundaryType.COUNTRY]: 'countries-7y5d7i',
  [BoundaryType.STATE]: 'states-7b1ijy',
  [BoundaryType.ZIP]: 'zip-code-tabulation-area-4z2gk5',
};

export const BOUNDARY_TYPE_TO_TILESET: Record<BoundaryType, string> = {
  [BoundaryType.COUNTRY]: 'mapbox://explo-engineering.25n43xod',
  [BoundaryType.STATE]: 'mapbox://explo-engineering.6xc1rpi4',
  [BoundaryType.ZIP]: 'mapbox://explo-engineering.cpr00u0h',
};
