import { FC, useState, useMemo } from 'react';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { DatasetModal } from 'components/DatasetModal';
import { sprinkles, Button } from 'components/ds';

type Props = {
  datasets: ReportBuilderDataset[];

  onSubmit: (name: string, parentSchemaId: number) => void;
};

export const AddDatasetButton: FC<Props> = ({ datasets, onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const currentDatasetNames = useMemo(
    () => new Set(datasets.map((dataset) => dataset.name)),
    [datasets],
  );

  const getModalError = (newVal: string) => ({
    error: currentDatasetNames.has(newVal)
      ? 'There is already a dataset with this name. Please choose another name for the dataset.'
      : undefined,
  });

  const renderAddModal = () => {
    if (!modalOpen) return null;

    return (
      <DatasetModal
        isOpen
        buttonTitle="Create new Dataset"
        errorState={getModalError}
        onClose={() => setModalOpen(false)}
        onSubmit={onSubmit}
        title="New Dataset"
      />
    );
  };

  return (
    <>
      <Button
        fillWidth
        className={sprinkles({ marginBottom: 'sp1' })}
        icon="plus"
        onClick={() => setModalOpen(true)}
        variant="primary">
        New Dataset
      </Button>
      {renderAddModal()}
    </>
  );
};
