import { FC } from 'react';

import { User } from 'actions/userActions';
import { Icon, sprinkles, IconButton } from 'components/ds';
import { IconName } from 'components/ds/Icon';

type PermissionObject = {
  user?: User;
};

export type Props = {
  icon: IconName;
  title: string;
  description: string;
  docsLink: string;
  // Can be used to disable updates from certain teams or users, see usage in the ResourceListPage
  permissionFn?: (permissionObject: PermissionObject) => boolean;
};

// New updates should be added as props from this component to the APP_UPDATE_MAP
export const BaseAppUpdate: FC<Props> = ({ icon, title, description, docsLink }) => {
  return (
    <div className={baseContainerClass}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2' })}>
        <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          <div className={sprinkles({ heading: 'h3', color: 'contentPrimary' })}>{title}</div>
          <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>{description}</div>
        </div>
      </div>
      <IconButton name="arrow-right" to={docsLink} variant="primary" />
    </div>
  );
};

const baseContainerClass = sprinkles({
  flexItems: 'alignCenterBetween',
  paddingX: 'sp3',
  paddingY: 'sp2',
  gap: 'sp2',
  width: 'fill',
  borderTop: 1,
  borderColor: 'outline',
});
