import { FC } from 'react';

import { EmbedInput, EmbedFilterLabel } from 'components/embed';
import { DashboardVariable, TextInputElemConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { isValidForm } from 'utils/isValidFormUtils';

type Props = {
  config: TextInputElemConfig;
  disabled?: boolean;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value: DashboardVariable;
  datasetNamesToId: Record<string, string>;
};

export const DashboardTextInputElement: FC<Props> = ({
  config,
  disabled,
  onNewValueSelect,
  value,
  datasetNamesToId,
}) => {
  const label = useStringWithVariablesReplaced(config.label || '', datasetNamesToId);

  return (
    <>
      <EmbedFilterLabel>{label}</EmbedFilterLabel>
      <EmbedInput
        showInputButton
        defaultValue={
          value && isValidForm(config.limitEntriesToNumbers, String(value)) ? String(value) : ''
        }
        disabled={disabled}
        leftIcon={config.showSearchIcon ? 'search' : undefined}
        onSubmit={(newValue) => {
          if (isValidForm(config.limitEntriesToNumbers, newValue))
            onNewValueSelect(newValue ? newValue : undefined);
        }}
        placeholder={config.placeholderText}
      />
    </>
  );
};
