import cx from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sprinkles } from 'components/ds';
import {
  SETTINGS_LEFT_MENU_SECTIONS,
  SETTINGS_SUB_SECTIONS_PERMISSION_MAP,
  SUB_SECTION_ROUTE_MAP,
} from 'constants/settingsPageConstants';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  selectedSubSection: string;
};

export const SettingsLeftNav: FC<Props> = (props) => {
  const history = useHistory();

  const permissions = useSelector((state: ReduxState) => state.currentUser.permissions);

  return (
    <div className={rootClass} style={{ width: 280 }}>
      <div className={headerClass}>Settings</div>
      <div>
        {SETTINGS_LEFT_MENU_SECTIONS.map((section) => {
          const subSections: JSX.Element[] = [];

          section.subsections.forEach((subsection) => {
            const permissionFn = SETTINGS_SUB_SECTIONS_PERMISSION_MAP[subsection];

            if (permissionFn && !permissionFn(permissions)) return;

            subSections.push(
              <div
                className={cx(
                  subSectionClass,
                  SUB_SECTION_ROUTE_MAP[subsection] === props.selectedSubSection
                    ? sprinkles({ color: 'blue9', backgroundColor: 'lightBlue' })
                    : unselectedItemClass,
                )}
                key={subsection}
                onClick={() => history.push(`/settings/${SUB_SECTION_ROUTE_MAP[subsection]}`)}
                style={{ height: 30 }}>
                {subsection}
              </div>,
            );
          });

          if (subSections.length === 0) return null;

          return (
            <div
              className={sprinkles({ marginX: 'sp2.5', marginBottom: 'sp2' })}
              key={section.groupName}>
              <div className={groupNameClass}>{section.groupName}</div>
              {subSections}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  height: 'fill',
  borderRight: 1,
  borderColor: 'outline',
  overflowY: 'auto',
});

const headerClass = sprinkles({
  marginLeft: 'sp3',
  marginTop: 'sp4',
  marginBottom: 'sp3',
  heading: 'h2',
});

const groupNameClass = sprinkles({
  marginBottom: 'sp.5',
  color: 'gray10',
  fontWeight: 600,
  paddingY: 'sp.5',
  paddingX: 'sp1',
});

const subSectionClass = sprinkles({
  fontWeight: 400,
  cursor: 'pointer',
  paddingX: 'sp1',
  borderRadius: 2,
  flexItems: 'alignCenter',
});

const unselectedItemClass = sprinkles({
  color: { default: 'gray10', hover: 'blue8' },
  backgroundColor: { default: 'white', hover: 'lightBlue' },
  borderRadius: 4,
});
