import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Switch, Input, sprinkles } from 'components/ds';
import { updateEditableSectionSettings } from 'reducers/dashboardEditConfigReducer';
import { setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { EditableSectionSettings } from 'types/dashboardVersionConfig';

type Props = { settings: EditableSectionSettings };

export const SectionSettings: FC<Props> = ({ settings }) => {
  const dispatch = useDispatch();

  const isEditingDefaultLayout = useSelector(
    (state: ReduxState) => state.dashboardInteractions.isEditingEditableSection,
  );

  useEffect(() => {
    return () => {
      dispatch(setIsEditingEditableSection(false));
    };
  }, [dispatch]);

  const isTooltipOn = settings.tooltipText !== undefined;

  return (
    <>
      <div className={sprinkles({ marginBottom: 'sp2', flexItems: 'center', width: 'fill' })}>
        <Button
          fillWidth
          onClick={() => dispatch(setIsEditingEditableSection(!isEditingDefaultLayout))}
          variant={isEditingDefaultLayout ? 'secondary' : 'primary'}>
          {isEditingDefaultLayout ? 'Done Editing Default Layout' : 'Edit Default Layout '}
        </Button>
      </div>
      <Input
        showInputButton
        className={sprinkles({ marginBottom: 'sp2' })}
        defaultValue={settings.title}
        label={{ text: 'Section title', variableInput: true }}
        onSubmit={(newTitle) => dispatch(updateEditableSectionSettings({ title: newTitle }))}
      />
      <Switch
        label="Descriptive Tooltip"
        onChange={() =>
          dispatch(updateEditableSectionSettings({ tooltipText: isTooltipOn ? undefined : '' }))
        }
        switchOn={isTooltipOn}
      />
      {isTooltipOn ? (
        <Input
          showInputButton
          className={sprinkles({ marginTop: 'sp.5' })}
          defaultValue={settings.tooltipText}
          onSubmit={(tooltipText) => dispatch(updateEditableSectionSettings({ tooltipText }))}
        />
      ) : null}
    </>
  );
};
