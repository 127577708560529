import { FC, useState } from 'react';

import { Dashboard } from 'actions/dashboardActions';
import { APP_PORTAL_ID, AlertModal, Select, sprinkles } from 'components/ds';

type Props = {
  activateDashboard: (disableDashboardId: number) => void;
  closeModal: () => void;
  dashboardList: Dashboard[];
  modalOpen: boolean;
  selectedDashboardId: number;
};

export const ActivateDisabledDashboardModal: FC<Props> = ({
  activateDashboard,
  closeModal,
  dashboardList,
  modalOpen,
  selectedDashboardId,
}) => {
  const [selectedDisableDashboardId, setSelectedDisableDashboardId] = useState<number>();

  const options = dashboardList
    .filter((dashboard) => !dashboard.disabled && dashboard.id !== selectedDashboardId)
    .map((dashboard) => ({
      value: String(dashboard.id),
      label: dashboard.name,
    }));

  return (
    <AlertModal
      actionButtonProps={{
        text: 'Activate',
        disabled: !selectedDisableDashboardId,
        onClick: () => selectedDisableDashboardId && activateDashboard(selectedDisableDashboardId),
      }}
      isOpen={modalOpen}
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      title="Activate Dashboard">
      <div className={sprinkles({ paddingX: 'sp3' })}>
        <div className={sprinkles({ body: 'b2', color: 'contentPrimary' })}>
          On your current plan, you may only have 3 active dashboards at a time. In order to
          activate this dashboard, please select a dashboard you would like to deactivate.
        </div>
        <Select
          className={sprinkles({ marginTop: 'sp1' })}
          onChange={(id) => setSelectedDisableDashboardId(parseInt(id))}
          placeholder="Select a dashboard to deactivate"
          selectedValue={selectedDisableDashboardId?.toString()}
          values={options}
        />
      </div>
    </AlertModal>
  );
};
