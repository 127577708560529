import { ExploreEmailCadence } from 'actions/teamActions';
import { EmailCadenceTime, EMAIL_FREQUENCY } from 'constants/types';

export const getFrequency = (emailCadence?: ExploreEmailCadence) => {
  if (!emailCadence) return;

  if (emailCadence.day_of_week !== undefined && emailCadence.day_of_week !== null) {
    if (emailCadence.week_of_month !== undefined && emailCadence.week_of_month !== null)
      return EMAIL_FREQUENCY.MONTHLY;
    return EMAIL_FREQUENCY.WEEKLY;
  }
  return EMAIL_FREQUENCY.DAILY;
};

export const getHour = (emailCadence?: ExploreEmailCadence) => {
  if (emailCadence?.hour === undefined) return;

  return emailCadence.hour % 12 === 0 ? 12 : emailCadence.hour % 12;
};

export const getMilitaryTimeHour = (time?: EmailCadenceTime) => {
  if (!time || !time.hour) return;

  return time.isPm ? (time.hour % 12) + 12 : time.hour % 12;
};
