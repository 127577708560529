import { createErrorAction, createRequestAction, createSuccessAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { FilterOperationInstructions } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';

import { ExportUrlResponse } from './responseTypes';

export type ExportScreenshotType = ExportType.PDF | ExportType.Image;
export type ExportSpreadsheetType = ExportType.CSV | ExportType.XLSX;

export enum ExportType {
  CSV = 'csv',
  XLSX = 'xlsx',
  PDF = 'pdf',
  Image = 'image',
}

export const EXPORT_TYPE_NAME_MAP: Record<ExportType, string> = {
  [ExportType.PDF]: 'PDF (.pdf)',
  [ExportType.Image]: 'Image (.png)',
  [ExportType.CSV]: 'CSV (.csv)',
  [ExportType.XLSX]: 'Excel (.xlsx)',
};

export type DownloadDashboardBody = {
  version_number: number | undefined;
  download_file_name: string;
  variable_query: string;
  export_type: ExportType.Image | ExportType.PDF;
  email?: string;
  timezone?: string;
};

export const downloadDashboardRequest = createRequestAction<{ postData: DownloadDashboardBody }>(
  ACTION.DOWNLOAD_DASHBOARD,
);
export const downloadDashboardSuccess = createSuccessAction<ExportUrlResponse>(
  ACTION.DOWNLOAD_DASHBOARD,
);
export const downloadDashboardError = createErrorAction(ACTION.DOWNLOAD_DASHBOARD);

export type DownloadPanelFunc = (email?: string) => void;

type DownloadDataPanelIdArg = { data_panel_template_id: string };

export type DownloadDataPanelBody = DownloadDataPanelIdArg & {
  download_file_name: string;
  version_number: number;
  variable_query: string;
  export_type: ExportScreenshotType;
  email?: string;
  timezone?: string;
};

export const downloadDataPanelScreenshotRequest = createRequestAction<{
  postData: DownloadDataPanelBody;
}>(ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT);

export const downloadDataPanelScreenshotSuccess = createSuccessAction<
  ExportUrlResponse & { postData: DownloadDataPanelIdArg }
>(ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT);

export const downloadDataPanelScreenshotError = createErrorAction<{
  postData: DownloadDataPanelIdArg;
}>(ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT);

export type DownloadSpreadsheetFunc = (fileFormat: ExportSpreadsheetType, email?: string) => void;

type DownloadDataPanelSpreadsheetIdArg = { id: string; file_format: ExportSpreadsheetType };

export type DownloadDataPanelSpreadsheetBody = DownloadDataPanelSpreadsheetIdArg & {
  config: DataPanel;
  variables: DashboardVariableMap;
  sort_info: SortInfo_DEPRECATED | undefined;
  filter_info: FilterOperationInstructions | undefined;
  export_file_name: string;
  email?: string;
  timezone?: string;
};

export const downloadDataPanelSpreadsheetRequest = createRequestAction<{
  postData: DownloadDataPanelSpreadsheetBody;
}>(ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET);

export const downloadDataPanelSpreadsheetSuccess = createSuccessAction<
  ExportUrlResponse & { postData: DownloadDataPanelSpreadsheetIdArg }
>(ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET);

export const downloadDataPanelSpreadsheetError = createErrorAction<{
  postData: { id: string };
}>(ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET);
