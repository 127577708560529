import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { CustomerPermissionsSetter } from 'components/CustomerPermissions/Setter';
import { sprinkles, Modal, Input } from 'components/ds';
import * as styles from 'pages/ReportBuilder/ModalViews/EditBuiltInModal.css';
import { getSelectedBuiltIn, updateBuiltInReportSettings } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import InputLabel from 'shared/InputLabel';
import {
  CustomerPermissionsForObject,
  initCustomerPermissionsForObject,
} from 'types/permissionTypes';

export const EditBuiltInModal: FC = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [permissions, setPermissions] = useImmer<CustomerPermissionsForObject | null>(null);

  const selectedBuiltIn = useSelector((state: ReduxState) =>
    getSelectedBuiltIn(state.reportBuilderEdit),
  );

  useEffect(() => {
    if (!selectedBuiltIn) return;
    setName(selectedBuiltIn.name);
    setDescription(selectedBuiltIn.description);
    setPermissions(selectedBuiltIn.permissions ?? initCustomerPermissionsForObject());
  }, [selectedBuiltIn, setPermissions]);

  const handleClose = () => dispatch(closeReportModal());

  const handleSubmit = useCallback(() => {
    if (!selectedBuiltIn || !permissions) return;

    dispatch(closeReportModal());
    dispatch(
      updateBuiltInReportSettings({
        builtInId: selectedBuiltIn.id,
        name,
        description,
        permissions,
      }),
    );
  }, [selectedBuiltIn, dispatch, name, description, permissions]);

  if (!selectedBuiltIn || !permissions) return null;

  return (
    <Modal
      isOpen
      onClose={handleClose}
      primaryButtonProps={{
        text: 'Save',
        disabled: !name,
        onClick: handleSubmit,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: handleClose,
      }}
      size="large"
      title={`${selectedBuiltIn.name} Settings`}>
      <div className={styles.modalContainer}>
        <div className={sprinkles({ heading: 'h3' })}>General</div>
        <div>
          <InputLabel text="Name" />
          <Input onChange={setName} placeholder="My Built In" value={name} />
        </div>
        <div>
          <InputLabel text="Description" />
          <textarea
            className={styles.textarea}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            rows={6}
            value={description}
          />
        </div>
        <div className={sprinkles({ heading: 'h3' })}>Report Permissions</div>
        <div>
          {
            // @ts-ignore
            <CustomerPermissionsSetter permissions={permissions} setPermissions={setPermissions} />
          }
        </div>
      </div>
    </Modal>
  );
};
