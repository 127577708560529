import { FC, useState } from 'react';

import { ReportBuilderColConfig, ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Icon, Input } from 'components/ds';
import { ColumnFormatting } from 'pages/ReportBuilderEditor/DatasetEditor/ColumnFormatting';
import * as styles from 'pages/ReportBuilderEditor/DatasetEditor/DatasetColumnFormatItem.css';
import { DatasetColumn } from 'types/datasets';

type Props = {
  columnConfig: ReportBuilderColConfig;
  col: DatasetColumn;
  dataset: ReportBuilderDataset;
  updateColumnInfo: (updates: { name?: string; description?: string }) => void;
};

export const DatasetColumnFormatItem: FC<Props> = ({
  updateColumnInfo,
  col,
  dataset,
  columnConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.columnFormatHeader} onClick={() => setIsOpen(!isOpen)}>
        <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size="md" />
        <div className={styles.columnFormatName}>{columnConfig.name}</div>
        {columnConfig.description ? (
          <div className={styles.columnFormatDescription}>{columnConfig.description}</div>
        ) : null}
      </div>
      {isOpen ? (
        <div className={styles.columnFormatBody}>
          <Input
            fillWidth
            defaultValue={columnConfig.name}
            label="Name"
            onSubmit={(newName) => updateColumnInfo({ name: newName })}
          />
          <Input
            fillWidth
            defaultValue={columnConfig.description}
            label="Description"
            onSubmit={(newDescription) => updateColumnInfo({ description: newDescription })}
          />
          <ColumnFormatting
            column={col}
            dataset={dataset}
            displayFormatting={columnConfig.displayFormatting}
          />
        </div>
      ) : null}
    </div>
  );
};
