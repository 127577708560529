import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import { logOutUser, resendEmailVerification } from 'actions/authAction';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { Button, sprinkles } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { showSuccessToast } from 'shared/sharedToasts';

import { EMAIL_LOCAL_STORAGE_KEY } from './SignInPage';

export const CheckYourEmailPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useLocalStorage(EMAIL_LOCAL_STORAGE_KEY, '');

  if (email === '') history.push(ROUTES.LOGIN);

  return (
    <OnboardingFlowPage
      helpLinks={[
        {
          name: 'Log Out',
          onClick: () => {
            // we shouldn't actually be logged in on this page, but log out just for safety
            // and then make sure we navigate to the log in page
            dispatch(logOutUser());
            history.push(ROUTES.LOGIN);
          },
        },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      title="Verify your email!">
      <>
        <div>
          You have received an email from Explo asking you to verify your email. Click the link in
          the email to verify your email and access the platform.
        </div>
        <Button
          fillWidth
          className={sprinkles({ marginTop: 'sp2' })}
          data-testid="send-verification-button"
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            dispatch(
              resendEmailVerification(
                { postData: { email: email } },
                () => {
                  showSuccessToast('New verification email sent!');
                  setEmail('');
                  setIsLoading(false);
                },
                () => setIsLoading(false),
              ),
            );
          }}>
          Resend Verification Email
        </Button>
      </>
    </OnboardingFlowPage>
  );
};
