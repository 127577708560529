import { AlignValue, VerticalAlignValue, OptionsLayoutValue } from 'highcharts';

import { LegendPosition } from 'constants/types';

type LegendPositionConfig = {
  align: AlignValue;
  verticalAlign: VerticalAlignValue;
  layout: OptionsLayoutValue;
  y?: number;
};

export const LEGEND_CONFIG_BY_POS: Record<string, LegendPositionConfig> = {
  [LegendPosition.BOTTOM]: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
  },
  [LegendPosition.TOP]: {
    align: 'center',
    verticalAlign: 'top',
    layout: 'horizontal',
  },
  [LegendPosition.LEFT]: {
    align: 'left',
    verticalAlign: 'top',
    layout: 'vertical',
    y: 15,
  },
  [LegendPosition.RIGHT]: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    y: 15,
  },
  [LegendPosition.AUTO]: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    y: 15,
  },
};
