import produce from 'immer';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { InfoCard } from 'components/InfoCard';
import { Checkbox, sprinkles, Switch } from 'components/ds';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardElementConfig } from 'types/dashboardTypes';

type Props = {
  config: DashboardElementConfig;
  elementId: string;

  updateElemConfig: (newConfig: DashboardElementConfig) => void;
};

const configInputClass = sprinkles({ marginBottom: 'sp1.5' });

export const ElementDependencyConfig: FC<Props> = ({ config, elementId, updateElemConfig }) => {
  const elements = useSelector((state: ReduxState) => state.dashboardEditConfig.config?.elements);
  const updateConfig = (updateFunc: (config: DashboardElementConfig) => void) => {
    const newConfig = produce(config, (draft) => {
      updateFunc(draft);
    });
    updateElemConfig(newConfig);
  };

  return (
    <>
      <Switch
        className={configInputClass}
        label="Enable Dependency Selection"
        onChange={() =>
          updateConfig((draft) => {
            draft.ignoreDependencies = !draft.ignoreDependencies;
            if (draft.ignoreDependencies) draft.dependencyElementIds = {};
          })
        }
        switchOn={!config.ignoreDependencies}
      />
      {!config.ignoreDependencies ? (
        <>
          <InfoCard
            className={sprinkles({ marginBottom: 'sp1.5' })}
            text="You may create a dependency between this element and other elements on the dashboard. When this element is dependent on an element selected below, it will be disabled until a value is selected by the user."
          />
          {Object.values(elements ?? {}).map((element) => {
            if (element.id === elementId || !FILTER_ELEMENTS.has(element.element_type)) return null;

            const isSelected = config.dependencyElementIds?.[element.id] ?? false;

            return (
              <div className={sprinkles({ display: 'flex', marginBottom: 'sp1' })} key={element.id}>
                <Checkbox
                  isChecked={isSelected}
                  onChange={() =>
                    updateConfig((draft) => {
                      if (!draft.dependencyElementIds) draft.dependencyElementIds = {};
                      draft.dependencyElementIds[element.id] =
                        !draft.dependencyElementIds[element.id];
                    })
                  }
                />
                <div className={sprinkles({ marginLeft: 'sp1.5', color: 'gray11', body: 'b2' })}>
                  {element.name}
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );
};
