export const TOAST_TIMEOUT = 5;
export const DS_SELECTION_TEXT = 'Select a default data source';

export const NO_AVAILABLE_DS_TEXT =
  'This visibility group has no associated data sources for this schema which can result in errors while loading data.';

export const NO_DEFAULT_DS_TEXT =
  'This visibility group does not have a default data source selected. If no default is selected, this may result in errors loading data.';

export const DUPLICATE_SCHEMA_NAME_TEXT = 'Two schema cannot have the same name';

export const VIZ_GROUP_EDITING_ERROR_TEXT =
  'Something went wrong editing your visibility group. Please try again';
