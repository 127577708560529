import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SharedXAxisConfigs } from 'components/ChartConfigs/XAxisConfigs';
import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions | V2ScatterPlotInstructions;
  isHorizontal?: boolean;
  enableScrolling?: boolean;
};

export const XAxisConfig: FC<Props> = ({
  visualizationType,
  instructions,
  isHorizontal,
  enableScrolling,
}) => {
  const dispatch = useDispatch();

  const updateXAxisFormat = (xAxisUpdates: XAxisFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.xAxisFormat = {
        ...draft.xAxisFormat,
        ...xAxisUpdates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <SharedXAxisConfigs
        configInputClass={configInputClass}
        enableScrolling={enableScrolling}
        instructions={instructions}
        isHorizontal={isHorizontal}
        operationType={visualizationType}
        updateXAxisFormat={updateXAxisFormat}
      />
    </div>
  );
};
