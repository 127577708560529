import { TIME_COLUMN_TYPES } from 'constants/dataConstants';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';

const V2_CHART_TIME_SERIES_VIZ_OPS = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
]);

export const isTimeSeriesVisualization = (
  visualizeOperation: string | OPERATION_TYPES,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const isVizTimeSeries = V2_CHART_TIME_SERIES_VIZ_OPS.has(visualizeOperation as OPERATION_TYPES);
  const isTimeSeriesColumn = TIME_COLUMN_TYPES.has(instructions?.categoryColumn?.column.type ?? '');
  return isVizTimeSeries && isTimeSeriesColumn;
};
