import { DashboardVariable } from 'types/dashboardTypes';

export enum INTERNAL_EVENT {
  SAVE_RESOURCE_CONFIG = 'saveResourceConfigEvent',
  SAVE_MAP_VIEW_STATE = 'SaveMapViewState',
}

// Events sent from Explo's embed to our customers
export enum OUTPUT_EVENT {
  UPDATE_VARIABLE = 'sendVariableUpdatedEvent',

  DASHBOARD_LOADED = 'dashboardLoaded',
  DASHBOARD_READY_TO_LOAD = 'dashboardReadyToLoad',
  DASHBOARD_UPDATED = 'dashboardUpdated',

  EDITABLE_SECTION_LOADED = 'editableSectionUpdated',
  EDITABLE_SECTION_CHART_ADDED = 'editableSectionChartAdded',
  EDITABLE_SECTION_CHART_REMOVED = 'editableSectionChartRemoved',
}

// Events sent from our customers to Explo's embed
export enum INPUT_EVENT {
  UPDATE_VARIABLE = 'updateExploDashboardVariable',

  ADD_EDITABLE_SECTION_CHART = 'addEditableSectionChart',
  REMOVE_EDITABLE_SECTION_CHART = 'removeEditableSectionChart',
  TOGGLE_EDITABLE_SECTION = 'toggleEditableSection',
}

export type UpdateVariablePayload = { varName: string; value: DashboardVariable };
export type AddEditableSectionChartPayload = { chartTemplateId: string }; // Maps to chart.dataPanel.id internally
export type RemoveEditableSectionChartPayload = { chartId: string }; // Maps to chart.i
export type ToggleEditableSectionPayload = { canEdit: boolean };

declare global {
  interface WindowEventMap {
    [INPUT_EVENT.UPDATE_VARIABLE]: CustomEvent<UpdateVariablePayload>;
    [INPUT_EVENT.ADD_EDITABLE_SECTION_CHART]: CustomEvent<AddEditableSectionChartPayload>;
    [INPUT_EVENT.REMOVE_EDITABLE_SECTION_CHART]: CustomEvent<RemoveEditableSectionChartPayload>;
    [INPUT_EVENT.TOGGLE_EDITABLE_SECTION]: CustomEvent<ToggleEditableSectionPayload>;
  }
}
