import * as RadixPopover from '@radix-ui/react-popover';
import { FC, useState, useRef, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { searchResources } from 'actions/resourceActions';
import { Icon, IconButton, sprinkles, FolderBreadcrumbs } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { clearSearch } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { ResourceType } from 'types/exploResource';
import { createDebouncedFn } from 'utils/general';

import * as styles from './index.css';

type Props = {
  isExploreProduct: boolean;
  resetPage: () => void;
};

const debounceFn = createDebouncedFn(400);

// backend search resources always returns at most 20 items
const MAX_VALUES = 20;

export const SearchResources: FC<Props> = ({ resetPage, isExploreProduct }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef<HTMLDivElement>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState<string>();
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const type = isExploreProduct ? ResourceType.DASHBOARD : ResourceType.REPORT;
  const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE : ROUTES.REPORT_BUILDER;

  const { searchResults, isLoading } = useSelector(
    (state: ReduxState) => ({
      searchResults: state.folder.searchResults,
      isLoading: RD.isLoading(state.folder.searchResultsStatus),
    }),
    shallowEqual,
  );

  const onReset = useCallback(() => {
    dispatch(clearSearch());
    setIsSearchBarExpanded(false);
    setInputValue(undefined);
  }, [dispatch]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        if (searchResultsRef.current && searchResultsRef.current.contains(e.target as Node)) {
          return;
        }
        onReset();
      }
    };

    document.body.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onReset]);

  const handleFiltering = (name: string) => {
    debounceFn(() => {
      if (name) {
        dispatch(searchResources({ queryParams: { name, type } }));
      } else {
        dispatch(clearSearch());
      }
      setInputValue(name);
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <RadixPopover.Root
        onOpenChange={(isOpen) => setIsSearchBarExpanded(!isOpen)}
        open={!!inputValue && isSearchBarExpanded}>
        <RadixPopover.Anchor className={styles.anchor}>
          {isSearchBarExpanded ? (
            <div className={styles.openSearchBar} ref={inputRef}>
              <input
                autoFocus
                className={styles.input}
                onBlur={({ target }) => target.focus()}
                onChange={(event) => handleFiltering(event.currentTarget.value)}
                placeholder={`Search by name across all ${type}s`}
              />
              <IconButton
                className={styles.cross}
                name="cross"
                onClick={onReset}
                variant="tertiary"
              />
            </div>
          ) : (
            <IconButton
              name="search"
              onClick={() => setIsSearchBarExpanded(true)}
              variant="primary"
            />
          )}
        </RadixPopover.Anchor>
        {searchResults.length === 0 && isLoading ? null : (
          <RadixPopover.Content
            className={styles.content}
            collisionPadding={{ top: 24, right: 16 }}
            ref={searchResultsRef}>
            {searchResults.map(({ id, name, type, breadcrumbs }) => (
              <div
                className={styles.resource}
                key={`${name}-${id.toString()}`}
                onClick={() => {
                  if (type === ResourceType.FOLDER) {
                    history.push(`${rootPath}/${id}`);
                    resetPage();
                  } else if (type === ResourceType.DASHBOARD) {
                    history.push(`/dashboard/${id}#edit`);
                  } else {
                    history.push(`/report-builder/${id}/datasets`);
                  }
                  onReset();
                }}>
                <Icon
                  className={sprinkles({ color: 'contentTertiary' })}
                  name={type === ResourceType.FOLDER ? ResourceType.FOLDER : 'window-maximize'}
                  size="md"
                />
                <div className={styles.itemInfo}>
                  <div className={sprinkles({ truncateText: 'ellipsis' })}>{name}</div>
                  <FolderBreadcrumbs
                    items={breadcrumbs.map(({ name }) => ({ text: name, onClick: undefined }))}
                  />
                </div>
              </div>
            ))}
            {searchResults.length === 0 ? (
              <div className={styles.resultsInfo}>No results</div>
            ) : null}
            {searchResults.length >= MAX_VALUES ? (
              <div className={styles.resultsInfo}>Filter for more results</div>
            ) : null}
          </RadixPopover.Content>
        )}
      </RadixPopover.Root>
    </div>
  );
};
