import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardVariable,
  DashboardVariableMap,
} from 'types/dashboardTypes';
import { getListOfExtraVarsForElement } from 'utils/extraVariableUtils';
import { sortBy } from 'utils/standard';
import { getVariableIcon } from 'utils/variableUtils';

type Props = { variables: DashboardVariableMap; searchQuery: string };

export const FilterVariables: FC<Props> = ({ variables, searchQuery }) => {
  const elements = useSelector(
    (state: ReduxState) => state.dashboardEditConfig.config?.elements ?? {},
  );

  const filterElems = useMemo(
    () =>
      sortBy(
        Object.values(elements).filter(
          (elem) =>
            FILTER_ELEMENTS.has(elem.element_type) &&
            (searchQuery === undefined || elem.name.toLowerCase().includes(searchQuery)),
        ),
        (elem) => elem.name.toLowerCase(),
      ),
    [elements, searchQuery],
  );

  const viewVariable = (
    varName: string,
    value: DashboardVariable,
    elementType?: DASHBOARD_ELEMENT_TYPES,
  ) => (
    <PanelListItem
      copiable
      key={varName}
      leftIcon={getVariableIcon(value, elementType)}
      name={varName}
      rightElement={JSON.stringify(value)}
    />
  );

  return (
    <ConfigSection
      defaultOpen={!!filterElems.length}
      icon="filters"
      title="Filter variables"
      variant="header2">
      {filterElems.map(({ element_type, name }) => {
        const value = variables[name];
        if (element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
          const valueObj = (value ?? { startDate: undefined, endDate: undefined }) as Record<
            string,
            DashboardVariable
          >;
          return Object.keys(valueObj).map((key) =>
            viewVariable(`${name}.${key}`, valueObj[key], element_type),
          );
        }
        return [
          viewVariable(name, value, element_type),
          getListOfExtraVarsForElement(name, element_type).map((extraVar) => {
            const extraValue = variables[extraVar];
            return extraValue !== undefined ? viewVariable(extraVar, extraValue) : null;
          }),
        ];
      })}
    </ConfigSection>
  );
};
