import { Props } from './BaseAppUpdate';

/* New updates should be added here with an id greater than all present ids

Here are some examples:
export const APP_UPDATE_MAP: Record<number, Props> = {
  0: {
    docsLink: 'https://docs.explo.co/creating-dashboards/debugger-panel',
    title: 'Updated SQL Editor',
    description: 'New dataset editor for editing dashboards!',
    icon: 'table',
  },
  1: {
    docsLink: 'https://docs.explo.co/creating-report-builders/datasets',
    title: 'Report Builder Only',
    description: 'New updates for report builder only!,
    icon: 'gear',
    permissionFn: (permissionObject) =>
      permissionObject.user ? isReportBuilderEnabled(permissionObject.user) : false,
  },
};
*/
export const APP_UPDATE_MAP: Record<number, Props> = {
  0: {
    docsLink: 'https://docs.explo.co/creating-dashboards/dataset-editor',
    icon: 'database',
    title: 'Check out the new dashboard dataset editor',
    description:
      'The dashboard dataset editor has been revamped and moved into the left configuration panel.',
  },
};
