import { FC, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import { createFilterClause } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { ItemTypes, ConfigColumnItem } from 'constants/dragAndDrop';
import { FilterClause } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { filterDpsWithDrilldowns } from 'utils/drilldownUtils';
import { getPossibleLinksForDataPanel } from 'utils/filterLinking';
import { getSelectableKPIs } from 'utils/selectableKpiUtils';

import { EmptyDroppableSection } from '../vizConfigs/droppable/EmptyDroppableSection';

import { DroppedFilterColumn } from './DroppedFilterColumn';
import { FilterLink } from './FilterLink';

type Props = {
  clauses: FilterClause[];
  dataPanel: DataPanelTemplate;
  datasets: Record<string, Dataset>;
  dashboardElements: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  schema: DatasetSchema;
};

export const DroppableFilterColumnSection: FC<Props> = ({
  clauses,
  dataPanel,
  datasets,
  dashboardElements,
  dashboardParams,
  schema,
}: Props) => {
  const dispatch = useDispatch();

  const dataPanelsById = useSelector(
    (state: ReduxState) => state.dashboardEditConfig.config?.data_panels,
  );

  const dpsWithDrilldown = useMemo(
    () => filterDpsWithDrilldowns(Object.values(dataPanelsById ?? {})),
    [dataPanelsById],
  );

  const selectableKPIs = useMemo(() => getSelectableKPIs(dataPanelsById ?? {}), [dataPanelsById]);

  const [{ isDragging }, drop] = useDrop({
    accept: ItemTypes.CONFIGURATION_COLUMN,
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
    }),
    drop: (item: ConfigColumnItem) => {
      dispatch(createFilterClause(item.data));
    },
  });

  const validFilters = clauses.filter((clause) => clause.filterColumn);

  return (
    <div className={rootClass} ref={drop}>
      {validFilters.map((clause, i) => (
        <DroppedFilterColumn
          clause={clause}
          clauseIdx={i}
          dashboardElements={dashboardElements}
          dashboardParams={dashboardParams}
          datasets={datasets}
          dpsWithDrilldown={dpsWithDrilldown}
          key={`filter-clause-${i}-${clause.filterColumn?.name}-${dataPanel.id}`}
          schema={schema}
          selectableKPIs={selectableKPIs}
        />
      ))}
      {getPossibleLinksForDataPanel(dataPanel, datasets, dashboardElements).map((link) => (
        <FilterLink
          dataPanelId={dataPanel.id}
          key={`${dataPanel.id}-${link.elementName}`}
          link={link}
        />
      ))}
      <EmptyDroppableSection
        isValidDrop={isDragging ? true : undefined}
        onItemSelect={(column) => dispatch(createFilterClause(column))}
        schema={schema}
      />
    </div>
  );
};

const rootClass = sprinkles({
  color: 'contentTertiary',
  paddingY: 'sp.5',
  paddingX: 'sp1',
  margin: 'sp.5',
});
