import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';
import { sprinkles } from 'components/ds';
import { KPIActionConfig, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';

type Props = {
  vizType: OPERATION_TYPES;
  instructions: V2KPIChartInstructions | undefined;
};

export const CardActionsConfig: FC<Props> = ({ instructions, vizType }) => {
  const dispatch = useDispatch();

  const isSelectionEnabled = !!instructions?.actionConfig?.selectionEnabled;

  const updateActionConfig = (updates: Partial<KPIActionConfig>) => {
    const newInstructions = produce(instructions ?? {}, (draft) => {
      if (!draft.actionConfig) draft.actionConfig = {};
      draft.actionConfig = { ...draft.actionConfig, ...updates };
    });

    dispatch(updateVisualizeOperation(newInstructions, vizType));
  };

  return (
    <ConfigSubSectionWithSwitch
      splitChildren
      label="Use as a filter value"
      onChange={() => updateActionConfig({ selectionEnabled: !isSelectionEnabled })}
      switchOn={isSelectionEnabled}>
      <div className={sprinkles({ body: 'b3', color: 'contentSecondary' })}>
        Now you can use this KPI as a reference when adding conditional filters to charts and tables
        on your dashboard
      </div>
    </ConfigSubSectionWithSwitch>
  );
};
