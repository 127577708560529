import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { DashboardStates } from 'reducers/rootReducer';
import { makeEnqueueJobsThunkRequest, getAdditionalArgs } from 'utils/thunkUtils';

export const enqueueDashboardJobsThunk = createAsyncThunk<
  Record<string, Jobs>,
  { jobs: JobDefinition[] },
  { state: DashboardStates }
>(ACTION.ENQUEUE_LAYOUT_JOBS, async ({ jobs }, { dispatch, getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  let customerToken, jwt;
  const jobArgs = getAdditionalArgs(requestInfo);
  if (requestInfo.type === 'embedded') {
    customerToken = requestInfo.customerToken;
    jwt = requestInfo.jwt;
  }

  const jobMap: Record<string, JobDefinition> = {};
  jobs.forEach((job) => {
    job.job_args = { ...job.job_args, ...jobArgs };
    jobMap[uuidv4()] = job;
  });

  return makeEnqueueJobsThunkRequest(jobMap, dispatch, customerToken, jwt);
});
