import { FC } from 'react';

import { Label, Select, sprinkles } from 'components/ds';
import { IconSize } from 'components/ds/Icon';
import { ICON_SIZES } from 'globalStyles/constants';
import { GlobalStyleComponentIconConfig } from 'globalStyles/types';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { ConfigSectionHeader } from 'pages/GlobalCustomStylesPage/configSectionHeader';

type Props = {
  config: GlobalStyleComponentIconConfig;
  header: string;
  updateConfig: (newConfig: Partial<GlobalStyleComponentIconConfig>) => void;
};

export const ComponentIconConfig: FC<Props> = ({ config, header, updateConfig }) => {
  return (
    <div>
      <ConfigSectionHeader isSubTitle title={header} />
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
        <Select
          label="Size"
          onChange={(value) => updateConfig({ size: value as IconSize })}
          selectedValue={config.size}
          values={ICON_SIZES}
        />
        <div>
          <Label htmlFor="">Color</Label>
          <ColorPicker fill color={config.color} onClose={(color) => updateConfig({ color })} />
        </div>
      </div>
    </div>
  );
};
