import { useState, FC } from 'react';

import { SettingHeader } from 'components/SettingHeader';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { Icon, sprinkles, IconButton, Input } from 'components/ds';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  sortedStages: string[];
  updateInstructions: (stages: string[] | undefined) => void;
  title?: string;
};

export const CategorySortingConfig: FC<Props> = ({ sortedStages, updateInstructions, title }) => {
  const [isAddingStage, setIsAddingStage] = useState(sortedStages === undefined);
  const stages = sortedStages ? [...sortedStages] : [];

  return (
    <>
      <SettingHeader
        btnProps={{ icon: 'plus', onClick: () => setIsAddingStage(true) }}
        name={title ?? 'Sorting Categories'}
      />
      <div className={configRootClass}>
        <div className={configInputClass}>
          <div className={infoBoxClass}>
            Stages must match the values in your data. Excluded stages will be shown at the end of
            the chart. This will override any sorting applied to the data for this chart.
          </div>

          {stages.length !== 0 ? (
            <SortableList
              getIdFromElem={(item) => item}
              onListUpdated={(newList) => updateInstructions(newList)}
              sortableItems={stages}>
              {stages.map((stage, i) => (
                <SortableListItem key={stage} sortId={stage}>
                  <SortableStage
                    deleteStage={() => {
                      stages.splice(i, 1);
                      updateInstructions(stages.length === 0 ? undefined : stages);
                    }}
                    key={stage}
                    stage={stage}
                    updateStageName={(value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue !== '' && !stages.includes(trimmedValue)) {
                        stages.splice(i, 1, trimmedValue);
                        updateInstructions(stages);
                      }
                    }}
                  />
                </SortableListItem>
              ))}
            </SortableList>
          ) : null}
          {isAddingStage ? (
            <div className={rowContainerClass}>
              <div className={rowClass}>
                <Icon name="vertical-grip" />
                <Input
                  fillWidth
                  onSubmit={(value) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue !== '' && !stages.includes(trimmedValue)) {
                      stages.push(trimmedValue);
                      updateInstructions(stages);
                    }
                    setIsAddingStage(false);
                  }}
                  placeholder="Stage"
                />
                <IconButton
                  name="trash"
                  onClick={() => setIsAddingStage(false)}
                  variant="secondary"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const infoBoxClass = sprinkles({
  backgroundColor: 'gray2',
  borderRadius: 4,
  body: 'b2',
  color: 'gray12',
  padding: 'sp1',
});

type SortableStageProps = {
  stage: string;

  deleteStage: () => void;
  updateStageName: (name: string) => void;
};

const SortableStage: FC<SortableStageProps> = ({ deleteStage, stage, updateStageName }) => {
  return (
    <div className={rowContainerClass}>
      <div className={rowClass}>
        <SortableListItemDragHandle />
        <Input fillWidth defaultValue={stage} onSubmit={updateStageName} placeholder="Stage" />
        <IconButton name="trash" onClick={deleteStage} variant="secondary" />
      </div>
    </div>
  );
};

const rowClass = sprinkles({ flexItems: 'alignCenterBetween', paddingX: 'sp.5' });

const rowContainerClass = sprinkles({
  backgroundColor: 'gray2',
  borderRadius: 4,
  marginY: 'sp1',
  paddingY: 'sp.5',
});
