import { TooltipFormatterContextObject, YAxisOptions } from 'highcharts';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetDataObject } from 'actions/datasetActions';
import { ChartTooltip } from 'components/embed';
import { PointData } from 'components/embed/ChartTooltip';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  AggedChartColumnInfo,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2TwoDimensionChartInstructions,
  V2ScatterPlotInstructions,
  YAxisFormat,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  formatLabel,
  formatValue,
  getColorPalette,
  getLabelStyle,
  yAxisFormat,
} from 'pages/dashboardPage/charts/utils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getColDisplayText } from 'utils/dataPanelColUtils';

import { getGoalLines } from './goalLineUtils';

type YAxisInstructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

export const getSingleYAxisInstructions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions: YAxisInstructions | undefined,
  variables: DashboardVariableMap,
  datasetNamesToId: Record<string, string>,
  datasetData: DatasetDataObject,
  isNormalized?: boolean,
): YAxisOptions => {
  // Historical context: When we migrated V2TwoDimensionChartInstructions over to yAxisFormats, the yAxisFormat field for
  // old charts wasn't deleted as a precaution. This means that a boxplot/scatter plot not only must have a yAxisFormat field, it must also
  // not have a yAxisFormats field, otherwise the instructions could actually be V2TwoDimensionChartInstructions for a
  // pre-multi-y-axis chart.
  const isNewYAxisFormat =
    instructions && 'yAxisFormat' in instructions && !('yAxisFormats' in instructions);
  // Box and scatter plots are the only charts that get yAxisFormat from somewhere other than V2TwoDimensionChartInstructions.yAxisFormats
  const yAxisFormat = isNewYAxisFormat
    ? (instructions as V2BoxPlotInstructions | V2ScatterPlotInstructions).yAxisFormat
    : (instructions as V2TwoDimensionChartInstructions)?.yAxisFormats?.[
        DEFAULT_Y_AXIS_FORMAT_INDEX
      ];

  return buildYAxisOptions(
    globalStyleConfig,
    instructions,
    yAxisFormat,
    undefined,
    variables,
    datasetNamesToId,
    datasetData,
    { isNormalized },
  );
};

export const getMultiYAxisInstructions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions: V2TwoDimensionChartInstructions | undefined,
  variables: DashboardVariableMap,
  datasetNamesToId: Record<string, string>,
  datasetData: DatasetDataObject,
): Array<YAxisOptions> => {
  if (!instructions?.yAxisFormats) return [];
  const colors = getColorPalette(globalStyleConfig, instructions?.colorFormat);

  return instructions.yAxisFormats.map((yAxisFormat) => {
    const colorOverrideIndex = instructions?.aggColumns?.findIndex((aggColumn) => {
      return (
        aggColumn.agg.id === yAxisFormat?.colorFromAggColumn?.agg.id &&
        aggColumn.column.name === yAxisFormat?.colorFromAggColumn?.column.name
      );
    });
    const colorOverride =
      colorOverrideIndex !== undefined && colorOverrideIndex >= 0
        ? colors?.[colorOverrideIndex % colors.length]
        : undefined;
    return buildYAxisOptions(
      globalStyleConfig,
      instructions,
      yAxisFormat,
      colorOverride,
      variables,
      datasetNamesToId,
      datasetData,
      { hasMultipleYAxis: (instructions.yAxisFormats?.length ?? 0) > 1 },
    );
  });
};

/**
 * This function gets the index of the Y-Axis that we will assign the aggColumn data to in the chart.
 */
export const getYAxisChartIndex = (
  yAxisFormatId?: string,
  canUseMultiYAxis?: boolean,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const index = getYAxisFormatIndex(yAxisFormatId, instructions);
  if (!canUseMultiYAxis || !yAxisFormatId) return DEFAULT_Y_AXIS_CHART_INDEX;
  // If the index for the chart isn't found, default it to 0 so the chart doesn't crash.
  return index >= 0 ? index : DEFAULT_Y_AXIS_CHART_INDEX;
};

export const DEFAULT_Y_AXIS_CHART_INDEX = 0;
export const DEFAULT_Y_AXIS_FORMAT_INDEX = 0;

export const getYAxisFormatIndex = (
  yAxisFormatId?: string,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  return (instructions?.yAxisFormats ?? []).findIndex(
    (yAxisFormat) => yAxisFormat.id === yAxisFormatId,
  );
};

export const getYAxisFormat = (
  yAxisFormatId?: string,
  instructions?: YAxisInstructions,
  visualizationType?: string,
) => {
  if (
    visualizationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2 ||
    visualizationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2
  ) {
    return (instructions as V2BoxPlotInstructions | V2ScatterPlotInstructions).yAxisFormat;
  }

  // Instructions must be for 2d charts at this point
  const twoDInstructions = instructions as V2TwoDimensionChartInstructions;
  // Non multi-Y-Axis enabled charts won't deal with yAxisFormatId so we need to default their YAxisFormat to the first one.
  if (!yAxisFormatId) return twoDInstructions?.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];

  const yAxisFormatIndex = getYAxisFormatIndex(yAxisFormatId, twoDInstructions);
  return twoDInstructions?.yAxisFormats?.[yAxisFormatIndex];
};

export const CAN_USE_MULTI_Y_AXIS_OPS = new Set([
  // Don't allow multi Y-Axis on 100% charts or stacked charts since the two formats are a dichotomy.
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
]);

type BuildYAxisOptions = {
  isNormalized?: boolean;
  hasMultipleYAxis?: boolean;
};

const buildYAxisOptions = (
  globalStyleConfig: GlobalStyleConfig,
  instructions: YAxisInstructions | undefined,
  yAxisFormatData: YAxisFormat | undefined,
  colorOverride: string | undefined,
  variables: DashboardVariableMap,
  datasetNamesToId: Record<string, string>,
  datasetData: DatasetDataObject,
  { isNormalized, hasMultipleYAxis }: BuildYAxisOptions = {},
): YAxisOptions => {
  const { valueFormatId, decimalPlaces } = getValueFormat(yAxisFormatData);
  const yAxisDecimals = yAxisFormatData?.showDecimals ? decimalPlaces : 0;
  const labelPadding = (instructions?.xAxisFormat?.labelPadding ?? 10) * 0.01;

  return {
    allowDecimals: yAxisFormatData?.showDecimals,
    ...yAxisFormat(globalStyleConfig, yAxisFormatData, colorOverride),
    min: yAxisFormatData?.min ?? null,
    max:
      // in a 100% bar chart, label padding doesn't work so we need to allot space by defining the max
      yAxisFormatData?.max ??
      (isNormalized && !instructions?.xAxisFormat?.hideTotalValues ? 110 : null),
    maxPadding:
      // Add 10% padding to axis if labels are shown or allow config override.
      // 0.05 is highcharts default so using that for no label padding
      instructions?.xAxisFormat?.hideTotalValues ? 0.05 : labelPadding,
    startOnTick: yAxisFormatData?.min === null,
    endOnTick: yAxisFormatData?.max === null,
    stackLabels: {
      enabled: !instructions?.xAxisFormat?.hideTotalValues,
      crop: false,
      overflow: 'allow',
      // TODO: Explore ways of formatting the stack labels for Multi Y-Axis Options
      formatter: function () {
        return formatValue({
          value: this.cumulative || 0,
          decimalPlaces: yAxisDecimals,
          formatId: valueFormatId,
          hasCommas: true,
        });
      },
      style: {
        textOutline: 'none',
        ...getLabelStyle(globalStyleConfig, 'primary'),
      },
    },
    labels: {
      // TODO: Explore ways of formatting the stack labels for Multi Y-Axis Options
      formatter: function () {
        const numberValue = this.value as number;
        // if the data is sufficiently small, then highcharts breaks the ticks into decimals,
        // which become duplicated integers if showDecimals isn't configured. With this, the lines
        // still appear (which is probably arguably nice for viewing the data), but the labels
        // aren't duplicated
        if (!yAxisFormatData?.showDecimals && !Number.isInteger(numberValue)) return '';

        return isNormalized
          ? formatValue({
              value: numberValue / 100 || 0,
              decimalPlaces: yAxisDecimals,
              formatId: V2_NUMBER_FORMATS.PERCENT.id,
              hasCommas: true,
            })
          : formatValue({
              value: numberValue || 0,
              decimalPlaces: yAxisDecimals,
              formatId: valueFormatId,
              hasCommas: true,
            });
      },
      style: getLabelStyle(globalStyleConfig, 'secondary', colorOverride),
      enabled: !yAxisFormatData?.hideAxisLabels,
      allowOverlap: false,
    },
    gridLineWidth: yAxisFormatData?.hideGridLines ? 0 : 1,
    opposite: yAxisFormatData?.oppositeAligned === true,
    ...getGoalLines(instructions?.goalLines, variables, datasetNamesToId, datasetData, {
      setMin: yAxisFormatData?.min,
      setMax: yAxisFormatData?.max,
      yAxisId: hasMultipleYAxis ? yAxisFormatData?.id : undefined,
    }),
  };
};

export const getValueFormat = (yAxisFormat?: YAxisFormat) => {
  return {
    valueFormatId: yAxisFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
    decimalPlaces: yAxisFormat?.showDecimals ? yAxisFormat?.decimalPlaces ?? 2 : 0,
  };
};

export const getYAxisFormatById = (
  yAxisFormats: YAxisFormat[] | undefined,
  yAxisFormatID: string | undefined,
) => {
  if (!yAxisFormats || !yAxisFormatID) return undefined;
  const format = yAxisFormats.filter((format) => format.id === yAxisFormatID);
  if (format.length === 0) return undefined;
  return format[0];
};

export const addYAxis = (
  instructions: V2TwoDimensionChartInstructions,
  visualizationType: OPERATION_TYPES,
) => {
  const newInstructions = produce(instructions, (draft) => {
    if (!draft.yAxisFormats) draft.yAxisFormats = [];
    draft.yAxisFormats.push({ id: uuidv4() });
  });

  return updateVisualizeOperation(newInstructions, visualizationType);
};

export const updateYAxisFormat = (
  newYAxisFormat: YAxisFormat,
  instructions: YAxisInstructions,
  visualizationType?: string,
): YAxisInstructions => {
  if (
    visualizationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2 ||
    visualizationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2
  ) {
    return produce(instructions as V2BoxPlotInstructions | V2ScatterPlotInstructions, (draft) => {
      draft.yAxisFormat = newYAxisFormat;
    });
  }

  // All 2d viz charts will use V2TwoDimensionChartInstructions.yAxisFormats
  return produce(instructions as V2TwoDimensionChartInstructions, (draft) => {
    if (!draft.yAxisFormats) {
      draft.yAxisFormats = [newYAxisFormat];
      return;
    }
    const yAxisFormatIndex = getYAxisFormatIndex(newYAxisFormat.id, draft);
    draft.yAxisFormats[yAxisFormatIndex] = newYAxisFormat;
  });
};

export const getAggColDisplayName = (aggColumn: AggedChartColumnInfo) => {
  return aggColumn.column.friendly_name || getColDisplayText(aggColumn) || '';
};

export const createYAxisBaseTooltip = ({
  tooltipFormatter,
  globalStyleConfig,
  instructions,
  includePercent,
  showSelectedOnly,
}: {
  tooltipFormatter: TooltipFormatterContextObject;
  globalStyleConfig: GlobalStyleConfig;
  instructions: V2TwoDimensionChartInstructions | undefined;
  includePercent: boolean | undefined;
  showSelectedOnly?: boolean;
}) => {
  const selectedPoints: PointData[] = [];
  const points = tooltipFormatter.point.series.chart.axes.flatMap((axis, index) => {
    if (axis.isXAxis) return [];

    const { valueFormatId, decimalPlaces } = getValueFormat(
      // axes includes the x-axis, which is first, so we need to account for that
      instructions?.yAxisFormats?.[index - 1],
    );

    return axis.series.flatMap((s) => {
      const dataPoint = s.data.find((d) => d.category === tooltipFormatter.point.category);
      if (!dataPoint) return [];

      const isSelected =
        s.name === tooltipFormatter.series.name &&
        dataPoint.y === tooltipFormatter.point.y &&
        String(dataPoint.color) === String(tooltipFormatter.point.color);

      const pointData = {
        color: String(dataPoint.color),
        name: s.name,
        value: dataPoint.y,
        selected: isSelected,
        format: {
          formatId: valueFormatId,
          decimalPlaces,
        },
      };
      if (showSelectedOnly && isSelected) selectedPoints.push({ ...pointData, selected: false });
      return pointData;
    });
  });
  return (
    <ChartTooltip
      globalStyleConfig={globalStyleConfig}
      header={formatLabel(
        tooltipFormatter.point.category,
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      )}
      includePct={includePercent}
      points={points}
      selectedPoints={showSelectedOnly ? selectedPoints : undefined}
    />
  );
};
