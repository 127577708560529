import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { EmbedButton } from 'components/embed';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DashboardElementView } from 'pages/dashboardPage/dashboardElement/dashboardElementView';
import { EditingLayout, setEditingLayout } from 'reducers/dashboardInteractionsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { selectItemOnDashboardThunk } from 'reducers/thunks/dashboardSelectionThunks';
import { DashboardElement, DashboardVariableMap, VIEW_MODE } from 'types/dashboardTypes';
import { DashboardStickyHeaderConfig, DashboardHeaderLocation } from 'types/dashboardVersionConfig';
import { ResourceDataset } from 'types/exploResource';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { keyBy } from 'utils/standard';

import * as styles from './styles.css';

type Props = {
  config?: DashboardStickyHeaderConfig;
  dashboardElements: DashboardElement[];
  datasets: Record<string, ResourceDataset>;
  variables: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

export const DashboardStickyHeader: FC<Props> = ({
  config,
  dashboardElements,
  variables,
  viewMode,
  datasets,
}) => {
  const dispatch = useDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const elementsById = useMemo(() => keyBy(dashboardElements, 'id'), [dashboardElements]);

  const elementNamesById = useMemo(() => {
    const byId: Record<string, string> = {};
    dashboardElements.forEach((elem) => (byId[elem.id] = elem.name));
    return byId;
  }, [dashboardElements]);

  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  const { selectedItemId, editableDashboard, hoverElementId, datasetData } = useSelector(
    (state: DashboardStates) => ({
      selectedItemId: state.dashboardInteractions.selectedItem?.id,
      editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
      hoverElementId: state.dashboardInteractions.linkedElementHoverId,
      datasetData: state.dashboardData.datasetData,
    }),
    shallowEqual,
  );

  const headerText = useMemo(() => {
    if (!config || config.headerDisabled) {
      return null;
    }
    return (
      <div
        className={cx(styles.headerText, {
          [styles.headerTextMobile]: viewMode === VIEW_MODE.MOBILE,
        })}
        style={{ color: config.headerTextColor, fontSize: config.headerTextSize }}>
        {config?.headerName?.length
          ? replaceVariablesInString(config.headerName, variables, datasetNamesToId, datasetData)
          : 'Dashboard'}
      </div>
    );
  }, [config, datasetData, datasetNamesToId, variables, viewMode]);

  if (!config?.enabled) return null;

  const isBelowHeader = config.filterLocations === DashboardHeaderLocation.BELOW_HEADER;

  // if 'belowHeader' is selected, whether there is a header or not, the filters are left aligned
  // so do 'flex-start'
  const filterRowContainer = cx(
    styles.filterRowContainer,
    isBelowHeader ? styles.filterRowContainerLeftAligned : styles.filterRowContainerRightAligned,
  );

  // If the header is disabled, we don't have to put the header below, but rather just left align it
  const filterRowBelowHeader = config.enabledExpandableFilterRow
    ? filtersOpen
    : isBelowHeader && !config.headerDisabled;

  const renderElem = (elem: DashboardElement, isVerticalStack?: boolean) => (
    <div
      className={cx({
        selected: selectedItemId === elem.id || hoverElementId === elem.id,
        [styles.hoverElement]: editableDashboard,
        [styles.verticalElemContainer]: isVerticalStack,
      })}
      key={`header-elem-${elem.id}`}
      onClick={(e) => {
        if (editableDashboard && selectedItemId !== elem.id) {
          dispatch(selectItemOnDashboardThunk(elem.id, { type: elem.element_type }));
        }
        e.stopPropagation();
      }}
      style={
        config.enableStretchFilters
          ? { flex: '1 0 auto', minWidth: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH }
          : {
              minWidth: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH,
              width: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH,
            }
      }>
      <div className={styles.elementIdTag}>{elem.name}</div>
      <DashboardElementView
        elementStartsOnRightSide
        dashboardElement={elem}
        datasetNamesToId={datasetNamesToId}
        editableDashboard={editableDashboard}
        elementNamesById={elementNamesById}
        variables={variables}
      />
    </div>
  );

  const renderElemsRow = () => {
    if (!config.headerContentOrder) return <></>;

    return config.headerContentOrder.map((elemId) => {
      // in the off chance that an element ID is present in the config.headerContentOrder which has been removed from the dashboard
      // we check that it isn't undefined so that we don't error, as a fallback
      if (!elementsById[elemId]) return null;
      return renderElem(elementsById[elemId]);
    });
  };

  const getNumFiltersSet = () => {
    if (!config.headerContentOrder) return 0;

    return config.headerContentOrder.reduce(
      (total, elemId) => total + (variables[elementsById[elemId].name] === undefined ? 0 : 1),
      0,
    );
  };

  const elemsRow = renderElemsRow();

  const numFiltersSet = getNumFiltersSet();
  const numFiltersSetText = numFiltersSet === 0 ? '' : `• ${numFiltersSet}`;

  const filterExpandButton = (
    <div
      className={cx(
        styles.filterRowContainer,
        isBelowHeader
          ? styles.filterRowContainerLeftAligned
          : styles.filterRowContainerRightAligned,
        styles.moreButtonRow,
      )}>
      <EmbedButton
        icon="filter-list"
        onClick={() => setFiltersOpen(!filtersOpen)}
        variant="primary">
        {filtersOpen ? `Hide Filters ${numFiltersSetText}` : `Show Filters ${numFiltersSetText}`}
      </EmbedButton>
    </div>
  );

  const rightAlignedFilterRow = (
    <div className={filterRowContainer}>{!filterRowBelowHeader ? elemsRow : null}</div>
  );

  return (
    <div
      className={cx(styles.headerContainer, {
        [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: !config.disableBottomBorder,
        [GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow]: !config.disableBottomShadow,
        [styles.editableHeaderContainer]: editableDashboard,
      })}
      onClick={(e) => {
        if (editableDashboard) dispatch(setEditingLayout(EditingLayout.STICKY_HEADER));
        e.stopPropagation();
      }}
      style={{ backgroundColor: config.backgroundColor || 'white' }}>
      <div
        className={cx(styles.headerMainContainer, {
          [styles.headerMainContainerFiltersBelow]:
            !config.enabledExpandableFilterRow && isBelowHeader && !config.headerDisabled,
        })}>
        {headerText}
        {config.enabledExpandableFilterRow ? filterExpandButton : rightAlignedFilterRow}
      </div>
      {filterRowBelowHeader ? (
        <div
          className={cx(styles.filterRowContainer, styles.belowHeaderFilterRow, {
            [styles.filterRowExpanded]: config.enabledExpandableFilterRow,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: config.enabledExpandableFilterRow,
          })}>
          {elemsRow}
        </div>
      ) : null}
    </div>
  );
};
