export const INTRODUCING_REPORT_BUILDER_ICON = (
  <svg fill="none" height="88" viewBox="0 0 88 88" width="88" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2271_96231)">
      <rect fill="white" height="56" rx="8" shapeRendering="crispEdges" width="56" x="16" y="16" />
      <path
        d="M50.6668 39V35.6667H47.3335"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M50.6668 39V35.6667H47.3335"
        stroke="url(#paint0_angular_2271_96231)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        clipRule="evenodd"
        d="M33.6301 29H54.3701C56.0067 29 57.3334 30.4917 57.3334 32.3333V55.6667C57.3334 57.5083 56.0067 59 54.3701 59H33.6301C31.9934 59 30.6667 57.5083 30.6667 55.6667V32.3333C30.6667 30.4917 31.9934 29 33.6301 29Z"
        fillRule="evenodd"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        clipRule="evenodd"
        d="M33.6301 29H54.3701C56.0067 29 57.3334 30.4917 57.3334 32.3333V55.6667C57.3334 57.5083 56.0067 59 54.3701 59H33.6301C31.9934 59 30.6667 57.5083 30.6667 55.6667V32.3333C30.6667 30.4917 31.9934 29 33.6301 29Z"
        fillRule="evenodd"
        stroke="url(#paint1_angular_2271_96231)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M37.3335 46.5H50.6668"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M37.3335 46.5H50.6668"
        stroke="url(#paint2_angular_2271_96231)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M37.3335 52.3333H44.0001"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M37.3335 52.3333H44.0001"
        stroke="url(#paint3_angular_2271_96231)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M50.6668 35.6667L45.6668 40.6667L41.1435 37.3334L37.3335 40.6667"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M50.6668 35.6667L45.6668 40.6667L41.1435 37.3334L37.3335 40.6667"
        stroke="url(#paint4_angular_2271_96231)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="88"
        id="filter0_d_2271_96231"
        width="88"
        x="0"
        y="0">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.24 0"
        />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2271_96231" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2271_96231"
          mode="normal"
          result="shape"
        />
      </filter>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(49.0001 37.3334) rotate(-89.9256) scale(2.02191 2.02192)"
        gradientUnits="userSpaceOnUse"
        id="paint0_angular_2271_96231"
        r="1">
        <stop offset="0.00558535" stopColor="#0075FF" />
        <stop offset="0.337824" stopColor="#907EFF" />
        <stop offset="0.642301" stopColor="#7E9AFF" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.0001 44) rotate(-89.9339) scale(18.1974 16.1755)"
        gradientUnits="userSpaceOnUse"
        id="paint1_angular_2271_96231"
        r="1">
        <stop offset="0.00558535" stopColor="#0075FF" />
        <stop offset="0.337824" stopColor="#907EFF" />
        <stop offset="0.642301" stopColor="#7E9AFF" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.0001 47) rotate(-89.0084) scale(0.60667 8.08653)"
        gradientUnits="userSpaceOnUse"
        id="paint2_angular_2271_96231"
        r="1">
        <stop offset="0.00558535" stopColor="#0075FF" />
        <stop offset="0.337824" stopColor="#907EFF" />
        <stop offset="0.642301" stopColor="#7E9AFF" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(40.6668 52.8333) rotate(-89.5042) scale(0.606602 4.04369)"
        gradientUnits="userSpaceOnUse"
        id="paint3_angular_2271_96231"
        r="1">
        <stop offset="0.00558535" stopColor="#0075FF" />
        <stop offset="0.337824" stopColor="#907EFF" />
        <stop offset="0.642301" stopColor="#7E9AFF" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.0001 38.1667) rotate(-89.8017) scale(3.03291 8.08769)"
        gradientUnits="userSpaceOnUse"
        id="paint4_angular_2271_96231"
        r="1">
        <stop offset="0.00558535" stopColor="#0075FF" />
        <stop offset="0.337824" stopColor="#907EFF" />
        <stop offset="0.642301" stopColor="#7E9AFF" />
      </radialGradient>
    </defs>
  </svg>
);
