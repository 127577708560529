export enum PLAN_TYPES {
  LAUNCH = 'Launch',
  GROWTH_V2 = 'Growth V2',
  GROWTH = 'Growth',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  DEACTIVATED = 'Deactivated',
}

export enum TRIAL_STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
}
