import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportView, BarVisualizationDirection } from 'actions/customerReportActions';
import { sprinkles, Switch } from 'components/ds';
import { DATE_TYPES } from 'constants/dataConstants';
import CollapsibleGroupIcon from 'constants/images/collapsible-group-icon.png';
import ColumnTotalIcon from 'constants/images/column-total-icon.png';
import { GradientType, OPERATION_TYPES } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BarFunnelSortSection } from 'pages/ReportBuilder/ReportView/DataPanel/BarFunnelSortSection';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DirectionSection } from 'pages/ReportBuilder/ReportView/DataPanel/DirectionSection';
import { GroupingSection } from 'pages/ReportBuilder/ReportView/DataPanel/GroupingSection';
import { SortingSection } from 'pages/ReportBuilder/ReportView/DataPanel/SortingSection';
import { OPERATION_NAME_MAP } from 'pages/ReportBuilder/constants';
import {
  setAreaChartOptions,
  setBarFunnelChartOptions,
  setScatterPlotOptions,
  setHeatMapOptions,
  updateCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import { ToggleSection } from './ToggleSection';

type Props = { view: CustomerReportView };

export const FormatTab: FC<Props> = ({ view }) => {
  const dispatch = useDispatch();

  const groupByLen = view.groupBys?.length ?? 0;
  const firstGroupBy = view.groupBys?.[0];

  const canShowTotals = useSelector(
    (state: ReportBuilderReduxState) =>
      state.embeddedReportBuilder.reportBuilderVersion?.config.general?.showTotals,
  );

  switch (view.visualization) {
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      return (
        <>
          <DirectionSection visualizationDirection={view.barChart?.direction} />
          {/* If only one group by then grouping is unnecessary */}
          {groupByLen > 1 ? (
            <GroupingSection visualizationGrouping={view.barChart?.grouping} />
          ) : null}
          {/* If group by is a date then it won't be sorted */}
          {firstGroupBy && !DATE_TYPES.has(firstGroupBy.column.type) ? (
            <SortingSection
              isHorizontal={view.barChart?.direction === BarVisualizationDirection.Horizontal}
              options={view.sortOptions}
            />
          ) : null}
        </>
      );
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2: {
      return (
        <ToggleSection
          onToggle={() => dispatch(setAreaChartOptions({ isPercent: !view.areaChart?.isPercent }))}
          options={[
            { icon: 'chart-area', label: 'Area', isSelected: !view.areaChart?.isPercent },
            { icon: 'chart-area', label: 'Area 100%', isSelected: !!view.areaChart?.isPercent },
          ]}
          title="Area Chart Type"
        />
      );
    }
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2: {
      const isMarkerRadiusLarge = view.scatterPlot?.isMarkerRadiusLarge;
      return (
        <ToggleSection
          onToggle={() =>
            dispatch(setScatterPlotOptions({ isMarkerRadiusLarge: !isMarkerRadiusLarge }))
          }
          options={[
            { label: 'Small', isSelected: !isMarkerRadiusLarge },
            { label: 'Large', isSelected: !!isMarkerRadiusLarge },
          ]}
          title="Marker Radius"
        />
      );
    }
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2: {
      const showCellLabels = view.heatMap?.showCellLabels || false;
      const gradientType = view.heatMap?.gradientType || GradientType.LINEAR;
      const isLinearGradient = gradientType === GradientType.LINEAR;
      return (
        <>
          <ToggleSection
            onToggle={() => dispatch(setHeatMapOptions({ showCellLabels: !showCellLabels }))}
            options={[
              { label: 'Hidden', isSelected: !showCellLabels },
              { label: 'Visible', isSelected: showCellLabels },
            ]}
            title="Cell Labels"
          />
          <ToggleSection
            onToggle={() =>
              dispatch(
                setHeatMapOptions({
                  gradientType: isLinearGradient ? GradientType.DIVERGING : GradientType.LINEAR,
                }),
              )
            }
            options={[
              { label: 'Linear', isSelected: isLinearGradient },
              { label: 'Diverging', isSelected: !isLinearGradient },
            ]}
            title="Color Gradient Type"
          />
        </>
      );
    }
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2: {
      const isCumulative = view.barFunnelChart?.isCumulative !== false;
      return (
        <>
          <ToggleSection
            onToggle={() => dispatch(setBarFunnelChartOptions({ isCumulative: !isCumulative }))}
            options={[
              { label: 'Standard', isSelected: !isCumulative },
              { label: 'Cumulative', isSelected: isCumulative },
            ]}
            title="Cumulative"
          />
          <BarFunnelSortSection view={view} />
        </>
      );
    }
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case undefined:
      return (
        <>
          <DataPanelSection className={itemContainerClassName}>
            <div className={itemLeftClassName}>
              <div className={itemHeaderClassName}>
                <Switch
                  onChange={(usePivot) => dispatch(updateCurrentView({ usePivot }))}
                  switchOn={view.usePivot !== false}
                />
                <EmbedText heading="h4">Collapsible groups</EmbedText>
              </div>
              <EmbedText body="b2">
                Collapse groups when there are 2 or more &quot;Group bys&quot;, showing subtotals
                for each group.
              </EmbedText>
            </div>
            <img
              alt="Collapsible groups"
              className={sprinkles({ flex: 1 })}
              src={CollapsibleGroupIcon}
            />
          </DataPanelSection>
          {canShowTotals ? (
            <DataPanelSection className={itemContainerClassName}>
              <div className={itemLeftClassName}>
                <div className={itemHeaderClassName}>
                  <Switch
                    onChange={(showTotals) => dispatch(updateCurrentView({ showTotals }))}
                    switchOn={!!view.showTotals}
                  />
                  <EmbedText heading="h4">Column totals</EmbedText>
                </div>
                <EmbedText body="b2">
                  Show configurable totals at the bottom of each column.
                </EmbedText>
              </div>
              <img alt="Column totals" className={sprinkles({ flex: 1 })} src={ColumnTotalIcon} />
            </DataPanelSection>
          ) : null}
        </>
      );
    default:
      return (
        <DataPanelSection className={itemContainerClassName}>
          <EmbedText body="b2">
            No format options available for{' '}
            {OPERATION_NAME_MAP[view.visualization || OPERATION_TYPES.VISUALIZE_TABLE]}{' '}
            visualization
          </EmbedText>
        </DataPanelSection>
      );
  }
};

const itemContainerClassName = sprinkles({
  flexItems: 'alignCenterBetween',
  gap: 'sp1',
  padding: 'sp2',
});
const itemLeftClassName = sprinkles({ flexItems: 'column', gap: 'sp1' });
const itemHeaderClassName = sprinkles({ flexItems: 'alignCenter', gap: 'sp1' });
