import cx from 'classnames';
import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { CustomerPermissionsSummary } from 'components/CustomerPermissions/Summary';
import {
  Button,
  IconButton,
  Menu,
  MenuActionItem,
  MenuSeparator,
  sprinkles,
  Tag,
} from 'components/ds';
import { getChartIcon } from 'constants/chartIcons';
import { DataPanelConfig } from 'pages/dashboardPage/DataPanelConfig';
import {
  duplicateEditableSectionChart,
  duplicateEditableSectionChartToMainSection,
  setEditableSectionModal,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  clearSelectedItemThunk,
  selectItemOnDashboardThunk,
} from 'reducers/thunks/dashboardSelectionThunks';
import {
  addChartToEditableSectionThunk,
  removeChartTemplateFromEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { createDashboardItemId } from 'utils/dashboardUtils';
import { isDataPanelConfigReady } from 'utils/dataPanelConfigUtils';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import { sortBy } from 'utils/standard';

import { PreviewChart } from './PreviewChart';
import * as styles from './styles.css';

type Props = { dashboardId: number };

export const ChartsConfig: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const { config, selectedChartId, dashboardColumns } = useSelector(
    (state: ReduxState) => ({
      config: state.dashboardEditConfig.config?.editable_section,
      selectedChartId: state.dashboardInteractions.selectedItem?.id,
      dashboardColumns: state.dashboardStyles.globalStyleConfig.base.numColumns,
    }),
    shallowEqual,
  );

  const orderedCharts = useMemo(
    () => sortBy(Object.values(config?.charts ?? {}), (chart) => chart.name.toLocaleLowerCase()),
    [config?.charts],
  );

  const layout = useMemo(() => config?.default_layout ?? [], [config?.default_layout]);
  const selectedChart = selectedChartId ? config?.charts[selectedChartId] : undefined;

  const chartList = useMemo(
    () =>
      orderedCharts.map((chart: EditableSectionChart) => {
        const chartId = chart.data_panel.id;
        const isSelected = selectedChartId && isChartInstanceOfTemplate(selectedChartId, chart);
        const isAvailableToAll = chart.permissions.allCustomers;
        const isInStartingLayout = layout.find(({ i }) => isChartInstanceOfTemplate(i, chart));

        return (
          <div
            className={cx(styles.chart, isSelected ? styles.selectedChart : styles.unselectedChart)}
            key={chartId}
            onClick={(e) => {
              if (!isSelected) {
                dispatch(selectItemOnDashboardThunk(chartId, { setEditableSection: true }));
              }
              e.stopPropagation();
            }}>
            <div className={styles.chartIcon}>
              {getChartIcon(chart.data_panel.visualize_op.operation_type)}
            </div>
            <div className={styles.chartInfo}>
              <div className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
                {chart.name}
              </div>
              <CustomerPermissionsSummary permissions={chart.permissions} />
            </div>
            <div className={sprinkles({ marginLeft: 'sp1', flexItems: 'center', gap: 'sp1' })}>
              {isDataPanelConfigReady(chart.data_panel.visualize_op) ? null : (
                <Tag intent="warning" leftIcon="circle-exclamation">
                  Incomplete
                </Tag>
              )}
              <div onClick={(e) => e.stopPropagation()}>
                <Menu align="start" side="right" trigger={<IconButton name="ellipsis-vertical" />}>
                  <MenuActionItem
                    iconName="pencil"
                    onSelect={() =>
                      dispatch(setEditableSectionModal({ type: 'EditChartInfo', chartId }))
                    }
                    text="Edit"
                  />
                  <MenuActionItem
                    iconName="copy"
                    onSelect={() =>
                      dispatch(duplicateEditableSectionChart({ newId: v4(), chartId }))
                    }
                    text="Duplicate"
                  />
                  <MenuActionItem
                    iconName="copy"
                    onSelect={() => {
                      dispatch(
                        duplicateEditableSectionChartToMainSection({
                          newId: createDashboardItemId(dashboardId),
                          chartId,
                        }),
                      );
                    }}
                    text="Duplicate to main section"
                  />
                  <MenuActionItem
                    iconName="user"
                    onSelect={() =>
                      dispatch(setEditableSectionModal({ type: 'EditChartPermissions', chartId }))
                    }
                    text="Adjust permissions"
                  />
                  {!isInStartingLayout ? (
                    <MenuActionItem
                      disabled={!isAvailableToAll}
                      iconName="eye-open"
                      onSelect={() =>
                        dispatch(addChartToEditableSectionThunk(layout, chart, dashboardColumns))
                      }
                      text="Add to default layout"
                      tooltipProps={{
                        text: !isAvailableToAll
                          ? 'Chart has to be available to all customers to add.'
                          : '',
                      }}
                    />
                  ) : isAvailableToAll ? (
                    <MenuActionItem
                      iconName="eye-closed"
                      onSelect={() =>
                        dispatch(removeChartTemplateFromEditableSectionThunk(layout, chart))
                      }
                      text="Remove from default layout"
                    />
                  ) : null}
                  <MenuSeparator />
                  <MenuActionItem
                    isDestructive
                    iconName="trash"
                    onSelect={() =>
                      dispatch(setEditableSectionModal({ type: 'DeleteChart', chartId }))
                    }
                    text="Delete"
                  />
                </Menu>
              </div>
            </div>
          </div>
        );
      }),
    [dashboardColumns, dashboardId, dispatch, layout, orderedCharts, selectedChartId],
  );

  return (
    <>
      <Button
        fillWidth
        className={sprinkles({ marginBottom: 'sp1.5' })}
        icon="plus"
        onClick={(e) => {
          dispatch(setEditableSectionModal({ type: 'AddChart' }));
          e.stopPropagation();
        }}>
        Add a chart
      </Button>
      {orderedCharts.length === 0 ? (
        <div className={styles.noCharts}>
          <div className={sprinkles({ heading: 'h3' })}>Add a chart to get started.</div>
          <div className={sprinkles({ body: 'b1' })}>
            You&rsquo;ll see a list of all available charts here
          </div>
        </div>
      ) : (
        <div className={styles.chartList}>{chartList}</div>
      )}
      {selectedChart ? (
        <div className={styles.selectedChartContainer} onClick={(e) => e.stopPropagation()}>
          <div className={styles.selectedChartConfigContainer}>
            <DataPanelConfig
              isEditableSection
              dashboardId={dashboardId}
              dataPanel={selectedChart.data_panel}
            />
          </div>
          <PreviewChart dataPanel={selectedChart.data_panel} />
          <div className={styles.closeButton}>
            <IconButton
              name="arrow-left-to-line"
              onClick={() => dispatch(clearSelectedItemThunk())}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
