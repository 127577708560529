import { createReducer } from '@reduxjs/toolkit';

import { setTestimonialIndex } from 'actions/onboardingActions';

interface OnboardingReducer {
  testimonialIndex: number | null;
}

const onboardingReducerInitialState: OnboardingReducer = {
  testimonialIndex: null,
};

export default createReducer(onboardingReducerInitialState, (builder) => {
  builder
    //Updating
    .addCase(setTestimonialIndex, (state, { payload }) => {
      state.testimonialIndex = payload;
    })
    .addDefaultCase((state) => state);
});
