import { FC, useState, ReactNode } from 'react';

import { Icon, sprinkles, IconButton, Menu, MenuActionItem, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { showSuccessToast } from 'shared/sharedToasts';

import * as styles from './styles.css';

const COPY_ICON: IconName = 'clipboard-reg';
const COMPLETE_ICON: IconName = 'check';

type Props = {
  name: string;
  suffix?: string; // This will be italicized and added to the name
  leftIcon: IconName;
  copiable?: boolean;
  rightElement?: ReactNode;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const PanelListItem: FC<Props> = ({
  leftIcon,
  name,
  copiable,
  rightElement,
  onEdit,
  onDelete,
  suffix,
}) => {
  const [currentLeftIcon, setCurrentLeftIcon] = useState<IconName>(leftIcon);

  const handleClick = () => {
    if (!copiable) return;
    navigator.clipboard.writeText(name);
    setCurrentLeftIcon(COMPLETE_ICON);
    showSuccessToast(`"${name}" copied to clipboard`);
  };

  const renderMenu = () => {
    if (!onEdit && !onDelete) return;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
          {onEdit ? <MenuActionItem onSelect={onEdit} text="Edit" /> : null}
          {onDelete ? <MenuActionItem isDestructive onSelect={onDelete} text="Delete" /> : null}
        </Menu>
      </div>
    );
  };

  return (
    <div
      className={copiable ? styles.copiableVariableListItem : styles.variableListItem}
      onClick={handleClick}
      onMouseEnter={() => (copiable ? setCurrentLeftIcon(COPY_ICON) : null)}
      onMouseLeave={() => (copiable ? setCurrentLeftIcon(leftIcon) : null)}>
      <Tooltip
        delayDuration={DEFAULT_DELAY}
        text={`${copiable ? 'Click to copy ' : ''}${name}${suffix || ''}`}>
        <div className={styles.variableListItemIconName}>
          <Icon className={styles.variableIcon} name={currentLeftIcon} size="md" />
          <span className={styles.variableName}>
            {name}
            {suffix ? <i>{suffix}</i> : null}
          </span>
        </div>
      </Tooltip>
      {rightElement ? (
        <div className={styles.variableListItemRightElement}>
          {typeof rightElement === 'string' ? (
            <Tooltip delayDuration={DEFAULT_DELAY} text={rightElement}>
              <span
                className={sprinkles({ color: 'contentSecondary', truncateText: 'ellipsis' })}
                style={{ maxWidth: 200 }}>
                {rightElement}
              </span>
            </Tooltip>
          ) : (
            rightElement
          )}
          {renderMenu()}
        </div>
      ) : null}
    </div>
  );
};
