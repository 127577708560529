import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';
import { Input, Toggle, ToggleItem, sprinkles } from 'components/ds';
import {
  SECTION_OPTIONS,
  TablePDFFormat,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';

type Props = { options: VisualizeOperationGeneralFormatOptions };

const HEADER_SECTION_OPTIONS = [
  { name: '', id: SECTION_OPTIONS.BLANK },
  { name: 'Image', id: SECTION_OPTIONS.IMAGE },
  { name: 'Text', id: SECTION_OPTIONS.TEXT },
];

export const TablePDFConfig: FC<Props> = ({ options }) => {
  const dispatch = useDispatch();

  const config = options.export ?? {};
  const pdfFormat = config.pdfFormat ?? {};

  const updateConfig = (updates: Partial<TablePDFFormat>) => {
    const newOptions = produce(options, (draft) => {
      if (!draft.export) draft.export = {};

      // If it was not enabled, will set it as enabled and it will always be enabled after
      // But if all 3 sections are blank after that then it will not be rendered
      if (!draft.export.pdfFormat?.headerEnabled) draft.export.pdfFormat = { headerEnabled: true };

      draft.export.pdfFormat = { ...draft.export.pdfFormat, ...updates };
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const renderSectionSelector = (
    label: string,
    optionKey: 'centerOption' | 'leftOption' | 'rightOption',
    contentKey: 'centerContent' | 'leftContent' | 'rightContent',
  ) => {
    // If header is not enabled lets show blank for backwards compatibility
    const currentOption = !pdfFormat.headerEnabled
      ? SECTION_OPTIONS.BLANK
      : pdfFormat[optionKey] ?? SECTION_OPTIONS.BLANK;

    return (
      <div>
        <Toggle
          label={label}
          onValueChange={(newOption) => updateConfig({ [optionKey]: newOption as SECTION_OPTIONS })}
          selectedValue={currentOption}>
          {HEADER_SECTION_OPTIONS.map((option) => (
            <ToggleItem key={option.id} value={option.id}>
              {option.name}
            </ToggleItem>
          ))}
        </Toggle>
        {currentOption !== SECTION_OPTIONS.BLANK ? (
          <Input
            className={sprinkles({ marginTop: 'sp.5' })}
            defaultValue={pdfFormat[contentKey] ?? ''}
            onSubmit={(newVal) => updateConfig({ [contentKey]: newVal })}
          />
        ) : null}
      </div>
    );
  };

  return (
    <ConfigSubSectionWithSwitch
      splitChildren
      label="PDF"
      onChange={() => {
        const newOptions = produce(options, (draft) => {
          if (!draft.export) draft.export = {};
          draft.export.disablePdfDownload = !draft.export.disablePdfDownload;
        });
        dispatch(updateGeneralFormatOptions(newOptions));
      }}
      switchOn={!config.disablePdfDownload}>
      <div>
        <div className={sprinkles({ color: 'contentPrimary', body: 'section' })}>
          Header content
        </div>
        {renderSectionSelector('Left', 'leftOption', 'leftContent')}
        {renderSectionSelector('Center', 'centerOption', 'centerContent')}
        {renderSectionSelector('Right', 'rightOption', 'rightContent')}
      </div>
    </ConfigSubSectionWithSwitch>
  );
};
