import { FC } from 'react';

import { DownloadPanelFunc, DownloadSpreadsheetFunc } from 'actions/exportActions';
import { ChartMenu } from 'components/ChartMenu';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { sprinkles } from 'components/ds';
import { LinkFormat, ColorFilterPlacement, ExportConfig } from 'constants/types';
import {
  ColorCategoryDropdown,
  Props as ColorCategoryProps,
} from 'pages/dashboardPage/DashboardDatasetView/Header/ColorCategoryDropdown';

import { PanelName } from './PanelName';

type Props = {
  dataPanelId: string;
  infoTooltipText?: string;
  isHeaderHidden?: boolean;
  loading: boolean;
  disableMenu: boolean;
  title: string;
  colorCategoryProps?: ColorCategoryProps;
  linkFormat?: LinkFormat;
  hideIcons: boolean;
  enableDrilldownModal?: boolean;
  exportConfig: ExportConfig | undefined;
  onDownloadPanelSpreadsheet: DownloadSpreadsheetFunc;
  onDownloadPanelImage: DownloadPanelFunc;
};

export const ChartHeader: FC<Props> = ({
  dataPanelId,
  loading,
  disableMenu,
  infoTooltipText,
  isHeaderHidden,
  title,
  colorCategoryProps,
  linkFormat,
  hideIcons,
  enableDrilldownModal,
  exportConfig,
  onDownloadPanelSpreadsheet,
  onDownloadPanelImage,
}) => {
  const shouldRenderClickThrough = linkFormat && linkFormat.link && linkFormat.url;

  const categoryDropdownBelowTitle =
    !!colorCategoryProps &&
    colorCategoryProps.instructions?.colorFilterPlacement === ColorFilterPlacement.BELOW_TITLE;
  const categoryDropdownRight = !!colorCategoryProps && !categoryDropdownBelowTitle;

  const reserveIconSpace =
    !hideIcons &&
    (shouldRenderClickThrough || enableDrilldownModal || !!exportConfig || categoryDropdownRight);

  const notEmptyHeader = !isHeaderHidden && (infoTooltipText || title);

  const reserveHeaderSpace = reserveIconSpace || notEmptyHeader;

  if (!reserveHeaderSpace && !categoryDropdownBelowTitle) return null;

  const renderHeaderIcons = () => {
    if (hideIcons) return null;

    return (
      <>
        <UrlClickThroughButton linkFormat={linkFormat} />
        <ChartMenu
          dataPanelId={dataPanelId}
          disabled={disableMenu}
          enableDrilldownModal={enableDrilldownModal}
          exportConfig={exportConfig}
          onDownloadPanelImage={onDownloadPanelImage}
          onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
        />
      </>
    );
  };

  const renderCategoryDropdown = (shouldRender: boolean) => {
    if (!shouldRender || !colorCategoryProps) return null;

    return (
      <div className={categoryDropdownRight ? sprinkles({ marginLeft: 'sp.5' }) : undefined}>
        <ColorCategoryDropdown {...colorCategoryProps} />
      </div>
    );
  };

  const renderHeaderIconsAndCategoryDropdown = () => {
    if (hideIcons && !categoryDropdownRight) return null;

    return (
      <div
        className={sprinkles({ flexItems: 'alignCenter', justifyContent: 'flex-end' })}
        onClick={(e) => e.stopPropagation()}>
        {renderHeaderIcons()}
        {renderCategoryDropdown(categoryDropdownRight)}
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1', paddingBottom: 'sp2' })}>
      {reserveHeaderSpace ? (
        <div
          className={sprinkles({
            justifyContent: !isHeaderHidden ? 'space-between' : 'flex-end',
            display: 'flex',
            alignItems: 'center',
          })}
          style={{ height: 32 }}>
          {!isHeaderHidden ? (
            <PanelName infoTooltipText={infoTooltipText} loading={loading} panelName={title} />
          ) : null}

          {renderHeaderIconsAndCategoryDropdown()}
        </div>
      ) : null}

      {renderCategoryDropdown(categoryDropdownBelowTitle)}
    </div>
  );
};
