import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedButton, EmbedCarousel } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInInfoTooltip } from 'pages/ReportBuilder/HomeView/BuiltInInfoTooltip';
import { BuiltInReportCard } from 'pages/ReportBuilder/HomeView/BuiltInReportCard';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import {
  setSelectedTab,
  getOrderedBuiltIns,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import * as styles from './BuiltInReportCarousel.css';

const NUM_VISIBLE = 4;

export const BuiltInReportCarousel: FC = () => {
  const dispatch = useDispatch();

  const builtInReports = useSelector((state: ReportBuilderReduxState) =>
    getOrderedBuiltIns(state.embeddedReportBuilder),
  );

  const sortedBuiltIns = useMemo(() => {
    const builtIns = Object.values(builtInReports || {});
    return builtIns.slice(0, NUM_VISIBLE);
  }, [builtInReports]);

  if (!sortedBuiltIns.length) return null;

  return (
    <div
      className={sprinkles({
        paddingY: 'sp2',
        backgroundColor: 'elevationMid',
      })}>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
          paddingX: 'sp8',
          gap: 'sp1',
        })}>
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            gap: 'sp1',
          })}>
          <EmbedText
            className={sprinkles({ truncateText: 'ellipsis' })}
            color="contentPrimary"
            heading="h3">
            Get started with these Built In Reports
          </EmbedText>
          <BuiltInInfoTooltip />
        </div>
        <EmbedButton
          linkIcon
          onClick={() => dispatch(setSelectedTab({ tab: ReportBuilderTab.BUILT_IN_REPORTS }))}
          variant="tertiary">
          See all Built In Reports
        </EmbedButton>
      </div>
      <EmbedCarousel
        className={styles.builtInReportCarousel}
        itemsClassName={styles.builtInReportList}
        scrollAmount={300}>
        {sortedBuiltIns.map((builtIn) => (
          <BuiltInReportCard
            builtInReport={builtIn}
            className={styles.builtInReportCard}
            key={builtIn.id}
          />
        ))}
      </EmbedCarousel>
    </div>
  );
};
