export enum DATASET_VIEWS {
  QUERY = 'Query',
  FORMATTING = 'Formatting',
}

export enum ModalStatus {
  DELETE_DATASET = 'delete_dataset',
  EDIT_DATASET = 'edit_dataset',
  CLOSED = 'closed',
}
