import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ParentSchema } from 'actions/dataSourceActions';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { sortBy } from 'utils/standard';

import { APP_PORTAL_ID, sprinkles, Modal, Select, Input } from './ds';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, parentSchemaId: number) => void;
  defaultParentSchema?: ParentSchema;
  errorState?: (textFieldVal: string) => { error: string | undefined };
  datasetName?: string;
  title: string;
  buttonTitle: string;
  // TODO: Remove this prop once we using in-place text editing for renaming the dataset
  hideSchemaDropdown?: boolean;
};

export const DatasetModal: FC<Props> = ({
  errorState,
  defaultParentSchema,
  datasetName,
  onSubmit,
  onClose,
  isOpen,
  title,
  buttonTitle,
  hideSchemaDropdown,
}) => {
  const parentSchemas = useSelector(getParentSchemasList);

  const [textFieldVal, setTextFieldVal] = useState(datasetName || '');
  const [parentSchemaVal, setParentSchemaVal] = useState(
    // autoPopulate if there already is a schema or if there's only one schema to select
    defaultParentSchema
      ? defaultParentSchema
      : parentSchemas.length === 1
      ? parentSchemas[0]
      : undefined,
  );
  const { error } = errorState?.(textFieldVal) ?? {};

  const submitDisabled =
    error !== undefined || !textFieldVal?.trim() || parentSchemaVal === undefined;

  const options = useMemo(
    () =>
      sortBy(
        parentSchemas.map((parentSchema) => ({
          value: String(parentSchema.id),
          label: parentSchema.name,
        })),
        (opt) => opt.label.toLowerCase(),
      ),
    [parentSchemas],
  );

  const onCreate = () => {
    if (submitDisabled) return;

    onSubmit(textFieldVal || '', parentSchemaVal?.id || 0);
    setTextFieldVal('');
    setParentSchemaVal(defaultParentSchema);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{
        text: buttonTitle,
        onClick: onCreate,
        disabled: submitDisabled,
      }}
      size="small"
      title={title}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        <Input
          className={sprinkles({ marginBottom: 'sp1' })}
          errorText={error}
          onChange={setTextFieldVal}
          placeholder="Dataset Name"
          value={textFieldVal}
        />
        {hideSchemaDropdown ? null : (
          <Select
            className={sprinkles({ width: 'fill' })}
            onChange={(value) => {
              const schemaId = parseInt(value);
              const parentSchema = parentSchemas.find((schema) => schema.id === schemaId);
              setParentSchemaVal(parentSchema);
            }}
            placeholder="Select Schema"
            selectedValue={parentSchemaVal?.id.toString()}
            values={options}
          />
        )}
      </div>
    </Modal>
  );
};
