import { v4 as uuidv4 } from 'uuid';

import { JobDefinition } from 'actions/jobQueueActions';
import { JobParams } from 'components/JobQueue/types';

export function createJob(
  jobParams: JobParams,
  onSuccess?: (data: unknown) => void | (() => void),
  onError?: ((errorMessage: string) => void) | (() => void),
): Record<string, JobDefinition> {
  const jobId = uuidv4();
  return {
    [jobId]: {
      job_type: jobParams.job_type,
      job_args: {
        ...jobParams.job_args,
        jobId, // Job ID can be used for cancelling outdated requests
      },
      onError,
      onSuccess,
    },
  };
}
