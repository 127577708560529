import * as Dialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { FC, useContext } from 'react';

import { baseThemeName } from 'components/ds';
import { ModalSize } from 'components/ds/Modal';
import * as modalStyles from 'components/ds/Modal/index.css';
import { GlobalStylesContext } from 'globalStyles';

import * as styles from './index.css';
import { embedModalContent } from './index.css';

type Props = {
  // This matters on how we display overlays
  // Normal overlays disallow scrolling which is not what we want for iframes
  isIframe: boolean;
  // The controlled open state of the dialog. Must be used in conjunction with onOpenChange.
  isOpen: boolean;
  // Event handler called when the dialog closes.
  onClose: () => void;
  usePortal?: boolean;
  size: ModalSize;
};

export const EmbedModal: FC<Props> = ({ isOpen, onClose, children, isIframe, usePortal, size }) => {
  const { globalStyleVars, globalStylesClassName } = useContext(GlobalStylesContext);

  const contentClassName = cx(embedModalContent, modalStyles.dialogContent({ size }));
  const overlay = isIframe ? (
    <div className={styles.embedModalOverlay} />
  ) : (
    <Dialog.Overlay className={styles.embedModalOverlay} />
  );

  return (
    <Dialog.Root onOpenChange={(open) => !open && onClose()} open={isOpen}>
      {usePortal ? (
        <Dialog.Portal>
          <div
            className={cx(sharedClassName, baseThemeName, globalStylesClassName)}
            style={{
              ...globalStyleVars,
              all: 'initial',
              boxSizing: 'border-box',
              font: 'inherit',
              lineHeight: 'normal',
            }}>
            {overlay}
            <Dialog.Content className={contentClassName} style={globalStyleVars}>
              {children}
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      ) : (
        <div className={cx(sharedClassName)}>
          {overlay}
          <Dialog.Content className={contentClassName}>{children}</Dialog.Content>
        </div>
      )}
    </Dialog.Root>
  );
};

const sharedClassName = ['explo-embed', styles.embedModalZIndex];

export { EmbedModalClose } from './EmbedModalClose';
export { EmbedModalFooter } from './EmbedModalFooter';
export { EmbedModalHeader } from './EmbedModalHeader';
export { EmbedModalContent } from './EmbedModalContent';
