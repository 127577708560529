import { FC, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { AlertModal, Modal, sprinkles, APP_PORTAL_ID } from 'components/ds';
import {
  getDashboardElementsPartitionedBySection,
  getDataPanelsPartitionedBySection,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { deleteDatasetThunk } from 'reducers/thunks/dashboardDataThunks/modifyDatasetThunks';
import { getElementsUsingDataset, getDataPanelsUsingDataset } from 'utils/datasetUtils';
import { getDatasetName } from 'utils/naming';

type Props = {
  onClose: () => void;
  datasetConfigs: Record<string, Dataset>;
  deleteDatasetId: string | null;
};

export const DeleteDatasetModal: FC<Props> = ({ datasetConfigs, deleteDatasetId, onClose }) => {
  const dispatch = useDispatch();

  const { partitionedDataPanels, partitionedElements } = useSelector(
    (state: ReduxState) => ({
      partitionedDataPanels: getDataPanelsPartitionedBySection(state.dashboardEditConfig),
      partitionedElements: getDashboardElementsPartitionedBySection(state.dashboardEditConfig),
    }),
    shallowEqual,
  );

  const { mainDataPanels, editableSectionDataPanels } = partitionedDataPanels;
  const { mainElements, stickyHeaderElements } = partitionedElements;

  const {
    dataPanelsInUse,
    elementsInUse,
    editableSectionDataPanelsInUse,
    stickyHeaderElementsInUse,
  } = useMemo(() => {
    if (!deleteDatasetId)
      return {
        dataPanelsInUse: [],
        elementsInUse: [],
        editableSectionDataPanelsInUse: [],
        stickyHeaderElementsInUse: [],
      };

    return {
      dataPanelsInUse: getDataPanelsUsingDataset(mainDataPanels, deleteDatasetId),
      elementsInUse: getElementsUsingDataset(mainElements, deleteDatasetId),
      editableSectionDataPanelsInUse: getDataPanelsUsingDataset(
        editableSectionDataPanels,
        deleteDatasetId,
      ),
      stickyHeaderElementsInUse: getElementsUsingDataset(stickyHeaderElements, deleteDatasetId),
    };
  }, [
    deleteDatasetId,
    mainElements,
    mainDataPanels,
    editableSectionDataPanels,
    stickyHeaderElements,
  ]);

  const datasetToDelete = deleteDatasetId && datasetConfigs[deleteDatasetId];

  if (!datasetToDelete) return null;

  if (
    dataPanelsInUse.length ||
    elementsInUse.length ||
    editableSectionDataPanelsInUse.length ||
    stickyHeaderElementsInUse.length
  ) {
    return (
      <Modal
        isOpen
        onClose={onClose}
        portalContainerId={APP_PORTAL_ID}
        secondaryButtonProps={{ text: 'Cancel', onClick: onClose }}
        size="small"
        title="This dataset cannot be deleted">
        <div
          className={sprinkles({ flexItems: 'column', paddingX: 'sp3', gap: 'sp3', body: 'b2' })}>
          <div>
            This dataset is in use and cannot be deleted until the following elements are deleted.
            This is true even if the <b>Customer Editable Section</b> and the <b>Header</b> are
            disabled.
          </div>
          <ModalSection category="dashboard charts" names={dataPanelsInUse} />
          <ModalSection category="dashboard filters" names={elementsInUse} />
          <ModalSection
            category="customer editable section charts"
            names={editableSectionDataPanelsInUse}
          />
          <ModalSection category="header filters" names={stickyHeaderElementsInUse} />
        </div>
      </Modal>
    );
  }

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        text: `Delete ${getDatasetName(datasetToDelete)}`,
        onClick: () => {
          dispatch(deleteDatasetThunk(datasetToDelete.id));
        },
      }}
      onClose={onClose}
      title="Are you sure you want to delete this dataset?"
    />
  );
};

type ModalSectionProps = {
  names: string[];
  category: string;
};

const ModalSection: FC<ModalSectionProps> = ({ names, category }) => {
  return names.length > 0 ? (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
      <div>
        The following <b>{`${category}`}</b> are using this dataset:
      </div>
      <ul>
        {names.map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </div>
  ) : null;
};
