import {
  CustomerReportAgg,
  CustomerReportGroupBy,
  BarFunnelChartOptions,
  HeatMapOptions,
  ScatterPlotOptions,
  SortableChartOptions,
} from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import {
  DATE_TYPES,
  STRING,
  V2_NUMBER_FORMATS,
  VIZ_TO_NAME,
  VIZ_TO_SECTION,
} from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  V2KPIChartInstructions,
  V2TwoDimensionChartInstructions,
  NumberDisplayOptions,
  LegendPosition,
  Aggregation,
  SortAxis,
  SortOption,
  ChartTypeSections,
  V2ScatterPlotInstructions,
  ColorColumnOption,
} from 'constants/types';
import {
  ROWS_SECTION_ID,
  AGGS_SECTION_ID,
} from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { DatasetRow } from 'types/datasets';
import { getAggColDisplay, getAggColConfig, getGroupByDisplay } from 'utils/V2ColUtils';
import { getAggUniqueId } from 'utils/customerReportUtils';
import { cloneDeep } from 'utils/standard';

// The number of allowed group bys changes based on the current visualization types
export function getGroupByLimit(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
) {
  return visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 && aggregations?.length === 1
    ? 2
    : isTableVisualization(visualization)
    ? undefined
    : 1;
}

export function getColumnGroupByLimit(visualization?: OPERATION_TYPES) {
  switch (visualization) {
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
      return 1;
  }
}

export function getGroupByDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
) {
  const groupByLimit = getGroupByLimit(visualization, aggregations);
  return groupBys != null && groupByLimit != null && groupBys.length >= groupByLimit;
}

export function getScatterPlotGroupingDisabled(
  visualization?: OPERATION_TYPES,
  groupBys?: CustomerReportGroupBy[],
  scatterPlotGrouping?: CustomerReportGroupBy,
) {
  return (
    visualization !== OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 ||
    groupBys?.[0]?.column.type === STRING ||
    !!scatterPlotGrouping
  );
}

export function getColumnGroupByDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
  columnGroupBys?: CustomerReportGroupBy[],
  activeSection?: string,
) {
  const columnGroupByLimit = getColumnGroupByLimit(visualization);
  const atMaxColumnGroupBys =
    columnGroupByLimit != null &&
    columnGroupBys != null &&
    columnGroupBys.length >= columnGroupByLimit;

  if (
    visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 ||
    visualization === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2
  ) {
    // For these charts we don't require other fields to be defined first
    return atMaxColumnGroupBys;
  }

  return (
    !groupBys?.length ||
    !aggregations?.length ||
    (activeSection === ROWS_SECTION_ID
      ? !groupBys || groupBys.length < 2
      : activeSection === AGGS_SECTION_ID && (!aggregations || aggregations.length < 2)) ||
    atMaxColumnGroupBys
  );
}

// The number of allowed aggs changes based on the current visualization types
export function getAggLimit(visualization?: OPERATION_TYPES, groupBys?: CustomerReportGroupBy[]) {
  return !visualization
    ? undefined
    : [OPERATION_TYPES.VISUALIZE_NUMBER_V2, OPERATION_TYPES.VISUALIZE_PIE_CHART_V2].includes(
        visualization,
      )
    ? 1
    : visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 && groupBys && groupBys.length > 1
    ? 1
    : undefined;
}

export function getAggDisabled(
  visualization?: OPERATION_TYPES,
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
) {
  const aggLimit = getAggLimit(visualization, groupBys);
  return aggregations != null && aggLimit != null && aggregations.length >= aggLimit;
}

function getNumberFormat(displayFormatting: NumberDisplayOptions) {
  return Object.values(V2_NUMBER_FORMATS).find(
    (format) => format.name === displayFormatting?.format,
  );
}

// Common instructions shared between all charts
export function customerReportToSharedInstructions(
  aggregations?: CustomerReportAgg[],
  groupBys?: CustomerReportGroupBy[],
  columnConfigs?: ReportBuilderColConfigs,
): Partial<V2TwoDimensionChartInstructions> {
  const firstGroupBy = groupBys?.[0];
  const groupByName = firstGroupBy ? getGroupByDisplay(firstGroupBy, columnConfigs) : undefined;
  const columnConfig = firstGroupBy ? columnConfigs?.[firstGroupBy.column.name] : undefined;
  const numberDisplayOptions = columnConfig?.displayFormatting as NumberDisplayOptions;
  const numberFormat = getNumberFormat(numberDisplayOptions);

  return {
    legendFormat: { position: LegendPosition.BOTTOM },
    aggColumns: aggregations?.map((agg) => {
      const friendlyName = getAggColDisplay(agg, columnConfigs);
      return {
        ...agg,
        column: { ...agg.column, friendly_name: friendlyName },
        yAxisFormatId: getAggUniqueId(agg),
      };
    }),
    categoryColumn: firstGroupBy
      ? {
          column: firstGroupBy.column,
          bucket: firstGroupBy.bucket ? { id: firstGroupBy.bucket } : undefined,
        }
      : undefined,
    xAxisFormat: {
      title: groupByName,
      showTitle: !!groupByName,
      showDecimals: !!numberDisplayOptions?.decimalPlaces,
      decimalPlaces: numberDisplayOptions?.decimalPlaces,
      numberFormat,
      hideTotalValues: true,
    },
    yAxisFormats: aggregations?.map((agg) => {
      const aggColName = getAggColDisplay(agg, columnConfigs);
      const aggDisplayFormatting = getAggColConfig(agg, columnConfigs)
        ?.displayFormatting as NumberDisplayOptions;
      const aggNumberFormat = getNumberFormat(aggDisplayFormatting);
      return {
        id: getAggUniqueId(agg),
        title: aggColName,
        showTitle: !!aggColName,
        showDecimals: !!aggDisplayFormatting?.decimalPlaces,
        decimalPlaces: aggDisplayFormatting?.decimalPlaces,
        numberFormat: aggNumberFormat,
      };
    }),
  };
}

export function getCurrentSortValues(sortableOptions: SortableChartOptions | undefined): {
  sortAxis: SortAxis;
  sortOption: SortOption;
} {
  return {
    sortAxis: sortableOptions?.axis ?? SortAxis.CAT_AXIS,
    sortOption: sortableOptions?.direction ?? SortOption.DESC,
  };
}

const getColorColumnOptions = (
  groupings?: CustomerReportGroupBy[],
): ColorColumnOption[] | undefined =>
  groupings?.map(({ column, bucket }) => ({
    column,
    bucket: bucket ? { id: bucket } : undefined,
  }));

export function customerReportToBarChartInstructions(
  aggregations: CustomerReportAgg[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
  sortableOptions: SortableChartOptions | undefined,
): V2TwoDimensionChartInstructions {
  const instructions = customerReportToSharedInstructions(aggregations, groupBys, columnConfigs);
  return {
    ...instructions,
    xAxisFormat: {
      ...instructions.xAxisFormat,
      ...getCurrentSortValues(sortableOptions),
      enableScroll: true,
    },
    // Only allow a single level of grouping
    colorColumnOptions: getColorColumnOptions(groupBys?.slice(1, 2)),
  };
}

export function customerReportToLineChartInstructions(
  aggregations: CustomerReportAgg[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
  columnGroupBys: CustomerReportGroupBy[] | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
): V2TwoDimensionChartInstructions {
  const instructions = customerReportToSharedInstructions(aggregations, groupBys, columnConfigs);
  return {
    ...instructions,
    colorColumnOptions: getColorColumnOptions(columnGroupBys),
  };
}

export function customerReportToHeatMapInstructions(
  aggregations: CustomerReportAgg[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
  columnGroupBys: CustomerReportGroupBy[] | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
  heatMapOptions: HeatMapOptions | undefined,
): V2TwoDimensionChartInstructions {
  const instructions = customerReportToSharedInstructions(aggregations, groupBys, columnConfigs);
  return {
    ...instructions,
    colorColumnOptions: getColorColumnOptions(columnGroupBys),
    chartSpecificFormat: {
      ...instructions?.chartSpecificFormat,
      heatMap: { ...heatMapOptions, hideZeros: !heatMapOptions?.showCellLabels },
    },
  };
}

export function customerReportToBarFunnelChartInstructions(
  aggregations: CustomerReportAgg[] | undefined,
  groupBys: CustomerReportGroupBy[] | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
  barFunnelChartOptions: BarFunnelChartOptions | undefined,
): V2TwoDimensionChartInstructions {
  const instructions = customerReportToSharedInstructions(aggregations, groupBys, columnConfigs);
  const sortedStages = barFunnelChartOptions?.sortedStages;
  return {
    ...instructions,
    xAxisFormat: {
      ...instructions.xAxisFormat,
      sortAxis: sortedStages?.length ? SortAxis.MANUAL : SortAxis.NONE,
      sortManualCategoriesOrder: sortedStages?.map((s) => ({ category: s, displayName: s })),
    },
    chartSpecificFormat: {
      ...instructions?.chartSpecificFormat,
      barFunnelChart: { isNotCumulative: barFunnelChartOptions?.isCumulative === false },
    },
  };
}

export function customerReportToScatterPlotInstructions(
  groupBys: CustomerReportGroupBy[] | undefined,
  columnGroupBys: CustomerReportGroupBy[] | undefined,
  scatterPlotGrouping: CustomerReportGroupBy | undefined,
  scatterPlotOptions: ScatterPlotOptions | undefined,
  columnConfigs: ReportBuilderColConfigs | undefined,
): V2ScatterPlotInstructions {
  const xAxisColumn = groupBys?.[0];
  const yAxisColumn = columnGroupBys?.[0];
  const xTitle = xAxisColumn ? getGroupByDisplay(xAxisColumn, columnConfigs) : undefined;
  const yTitle = yAxisColumn ? getGroupByDisplay(yAxisColumn, columnConfigs) : undefined;

  return {
    legendFormat: { position: LegendPosition.BOTTOM },
    xAxisColumn: xAxisColumn ? { ...xAxisColumn.column, friendly_name: xTitle } : undefined,
    yAxisColumn: yAxisColumn ? { ...yAxisColumn.column, friendly_name: yTitle } : undefined,
    groupingColumn: scatterPlotGrouping ? scatterPlotGrouping.column : undefined,
    scatterPlotFormat: { radius: scatterPlotOptions?.isMarkerRadiusLarge ? 12 : 8 },
    xAxisFormat: { showTitle: true, title: xTitle },
    yAxisFormat: { showTitle: true, title: yTitle },
  };
}

export function customerReportToSingleNumberChartInstructions(
  aggregations?: CustomerReportAgg[],
  columnConfigs?: ReportBuilderColConfigs,
): V2KPIChartInstructions {
  const firstAgg = aggregations?.[0];
  if (!firstAgg) return {};

  const column = { ...firstAgg.column, friendly_name: getAggColDisplay(firstAgg, columnConfigs) };
  const agg =
    firstAgg.agg.id === Aggregation.FORMULA
      ? // For custom aggs, singleNumberChart expects a formula, but it's only injected when fetching data so give a fake one
        { id: Aggregation.FORMULA, formula: 'fake_formula' }
      : firstAgg.agg;

  return { aggColumn: { column, agg, yAxisFormatId: '' } };
}

export function cloneRowsIfDate(groupBys?: CustomerReportGroupBy[], rows?: DatasetRow[]) {
  // Charts mutate previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const isGroupedByDate = rows && groupBys?.find((groupBy) => DATE_TYPES.has(groupBy?.column.type));
  if (isGroupedByDate) return cloneDeep(rows);
  return rows ?? [];
}

//Section name works in general except in the case of special charts, we want to use the more specific name
export const getVisualizationName = (visualization?: OPERATION_TYPES) => {
  const sectionName = VIZ_TO_SECTION[visualization || OPERATION_TYPES.VISUALIZE_TABLE];
  return visualization && sectionName === ChartTypeSections.SPECIAL
    ? VIZ_TO_NAME[visualization]
    : sectionName;
};

export const getScatterPlotConfigError = (
  xAxis?: CustomerReportGroupBy,
  yAxis?: CustomerReportGroupBy,
  scatterPlotGrouping?: CustomerReportGroupBy,
) => {
  if (yAxis && xAxis?.column.name === yAxis.column.name) {
    return 'X-axis and Y-axis must be distinct';
  }

  if (scatterPlotGrouping) {
    if (xAxis?.column.name === scatterPlotGrouping.column.name) {
      return 'X-axis and Grouping columns must be distinct';
    }
    if (yAxis?.column.name === scatterPlotGrouping.column.name) {
      return 'Y-axis and Grouping columns must be distinct';
    }
  }
};
