import { useDroppable } from '@dnd-kit/core';
import { FC, useMemo } from 'react';

import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { Icon, Tooltip, sprinkles } from 'components/ds';
import UnderlyingDataImg from 'constants/images/report-builder/underlying-data-tooltip.gif';
import { OPERATION_TYPES } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ColumnItem } from 'pages/ReportBuilder/ReportView/DataPanel/ColumnItem';
import { DataPanelList } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelList';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import {
  COL_LIST_SECTION_ID,
  DataPanelData,
} from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { NoDataSelected } from 'pages/ReportBuilder/ReportView/NoDataSelected';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { DraggableColumnInfo } from 'utils/customerReportUtils';

interface ColumnSectionProps {
  columns: DraggableColumnInfo[];
  isPivot: boolean;
  columnConfigs?: ReportBuilderColConfigs;
  visualization?: OPERATION_TYPES;
}

export const ColumnSection: FC<ColumnSectionProps> = ({
  columns,
  isPivot,
  columnConfigs,
  visualization,
}) => {
  const data: DataPanelData = { id: COL_LIST_SECTION_ID, section: COL_LIST_SECTION_ID };
  const { over, isOver, setNodeRef } = useDroppable({ id: COL_LIST_SECTION_ID, data });

  const sortableIds = useMemo(
    () => columns.map((item, index) => `${COL_LIST_SECTION_ID}-${item.name}-${index}`),
    [columns],
  );

  if (columns.length === 0) return <NoDataSelected />;

  const isTable = isTableVisualization(visualization);
  return (
    <DataPanelList
      id={COL_LIST_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader icon="table" title="Columns">
        <EmbedText
          body="b2"
          className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', paddingRight: 'sp1' })}
          color="contentTertiary">
          {isTable && !isPivot ? 'Applies to table' : 'Applies to underlying data'}
          <Tooltip
            align="end"
            text={
              <EmbedText
                body="b2"
                className={sprinkles({ flexItems: 'column', gap: 'sp1' })}
                color="white">
                <img
                  alt="Underlying data"
                  className={sprinkles({ maxWidth: 'fill' })}
                  src={UnderlyingDataImg}
                />
                <b>Underlying data</b>
                <span>
                  {isTable ? 'Click on a table row' : 'Click on a visualization data point'} to
                  reveal the underlying data
                </span>
              </EmbedText>
            }>
            <Icon name="circle-info" />
          </Tooltip>
        </EmbedText>
      </DataPanelSubHeader>

      {columns.map((column, index) => (
        <ColumnItem column={column} columnConfigs={columnConfigs} key={`${column.name}-${index}`} />
      ))}
    </DataPanelList>
  );
};
