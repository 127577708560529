import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { sprinkles, AlertModal } from 'components/ds';
import { EmbedModal, EmbedModalHeader, EmbedModalClose, EmbedModalFooter } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DatasetSelection } from 'pages/ReportBuilder/ModalViews/DataSelection/DatasetSelection';
import { PreviewColumns } from 'pages/ReportBuilder/ModalViews/DataSelection/PreviewColumns';
import { PreviewData } from 'pages/ReportBuilder/ModalViews/DataSelection/PreviewData';
import {
  closeReportModal,
  openDataModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { getDatasetDataId } from 'reportBuilderContent/reducers/reportEditingUtils';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { fetchModalData, updateDataInfoThunk } from 'reportBuilderContent/thunks';
import { getDatasetColumns } from 'utils/customerReportUtils';

import * as styles from './DataSelectionModal.css';

type Props = { datasetId: string | undefined; isIframe: boolean }; // Selected dataset only in the modal

export const DataSelectionModal: FC<Props> = ({ datasetId, isIframe }) => {
  const dispatch = useDispatch();
  const [hasShownConfirmModal, setHasShownConfirmModal] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | undefined>(datasetId);
  const [showColumns, setShowColumns] = useState(false);

  const { currentConfig, reportBuilderConfig, modalDatasetData, reportDatasetId } = useSelector(
    (state: ReportBuilderReduxState) => ({
      currentConfig: state.reportEditing.currentConfig,
      portalId: state.embeddedReportBuilder.portalId,
      reportBuilderConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      reportDatasetId: state.reportEditing.currentConfig?.dataInfo?.datasetId, // Selected dataset in the report
      modalDatasetData: state.reportEditing.reportData[getDatasetDataId(datasetId || '')],
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!datasetId) return;
    setSelectedDatasetId(datasetId);
    setShowColumns(true);
    dispatch(fetchModalData(datasetId));
  }, [datasetId, dispatch]);

  const dataset = reportBuilderConfig?.datasets[datasetId ?? ''];
  const selectableColumns = useMemo(() => (dataset ? getDatasetColumns(dataset) : []), [dataset]);

  const handleSelectDataset = (selectedDataset: ReportBuilderDataset) => {
    setSelectedDatasetId(selectedDataset.id);
    if (reportDatasetId && reportDatasetId !== selectedDataset.id && !hasShownConfirmModal) {
      setConfirmModalVisible(true);
    } else {
      setShowColumns(true);
      dispatch(openDataModal(selectedDataset.id));
    }
  };

  return (
    <>
      <EmbedModal
        isOpen
        isIframe={isIframe}
        onClose={() => dispatch(closeReportModal())}
        size="xlarge">
        <EmbedModalHeader title="Select Dataset" />
        <div className={styles.modalContent}>
          {confirmModalVisible ? (
            <AlertModal
              isOpen
              actionButtonProps={{
                text: `Change dataset`,
                onClick: () => {
                  setHasShownConfirmModal(true);
                  setConfirmModalVisible(false);
                  setShowColumns(true);
                  dispatch(openDataModal(selectedDatasetId));
                },
              }}
              onClose={() => {
                setSelectedDatasetId(datasetId);
                setConfirmModalVisible(false);
              }}
              title="You are attempting to select a new dataset. This will clear your existing report."
            />
          ) : null}

          {showColumns && dataset && currentConfig ? (
            <PreviewColumns
              columns={selectableColumns}
              datasetName={dataset.name}
              onBack={() => setShowColumns(false)}
            />
          ) : (
            <DatasetSelection onClick={handleSelectDataset} selectedDatasetId={selectedDatasetId} />
          )}
          <div className={styles.previewDataContainer}>
            <EmbedText
              className={sprinkles({ padding: 'sp2' })}
              color="contentTertiary"
              heading="h3">
              Dataset Preview
            </EmbedText>
            {dataset ? (
              <div className={styles.previewData}>
                <PreviewData
                  columnConfigs={dataset?.columnConfigs}
                  loading={!!modalDatasetData?.isLoading}
                  rows={modalDatasetData?.rows || []}
                  selectableColumns={selectableColumns}
                />
              </div>
            ) : (
              <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
                <EmbedText
                  body="b1"
                  className={sprinkles({
                    padding: 'sp4',
                    backgroundColor: 'gray1',
                    borderRadius: 4,
                  })}
                  color="contentSecondary">
                  Select a dataset before previewing data
                </EmbedText>
              </div>
            )}
          </div>
        </div>
        <EmbedModalFooter>
          <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
          <EmbedModalClose
            disabled={!dataset}
            onClick={() =>
              dataset &&
              dispatch(
                updateDataInfoThunk({
                  datasetId: dataset.id,
                  columns: selectableColumns.map((col) => col.name),
                }),
              )
            }
            variant="primary">
            {dataset ? `Select ${dataset.name || 'dataset'}` : 'No dataset selected'}
          </EmbedModalClose>
        </EmbedModalFooter>
      </EmbedModal>
    </>
  );
};
