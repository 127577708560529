import { FC, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { sprinkles, Modal, Icon, Spinner, Tag } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { listFolderHierarchy, moveEntry } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { showErrorToast } from 'shared/sharedToasts';
import { Folder, FolderHierarchy, Resource, ResourceType } from 'types/exploResource';

import * as styles from './index.css';

type Props = {
  isExploreProduct: boolean;
  modalOpen: boolean;
  portalContainerId?: string;
  resource: Folder | Resource;
  closeModal: () => void;
};

export const MoveResources: FC<Props> = ({
  isExploreProduct,
  resource,
  modalOpen,
  portalContainerId,
  closeModal,
}) => {
  const dispatch = useDispatch();

  const [newParentId, setNewParentId] = useState<number>();
  const [collapsedFolders, setCollapsedFolders] = useState<number[]>([]);

  const { hierarchy, currentParentId } = useSelector(
    (state: ReduxState) => ({
      hierarchy: state.folder.hierarchy,
      currentParentId: state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1]?.id,
    }),
    shallowEqual,
  );

  const type = isExploreProduct ? ResourceType.DASHBOARD : ResourceType.REPORT;

  useEffect(() => {
    if (RD.isIdle(hierarchy) || (RD.isSuccess(hierarchy) && hierarchy.data.type !== type))
      dispatch(listFolderHierarchy({ type }));
  }, [dispatch, hierarchy, type]);

  const isFolder = (resource as Folder).type === ResourceType.FOLDER;

  const renderFolders = (folder: FolderHierarchy, depth = 1) => {
    const { id, name, sub_folders } = folder;

    if (isFolder && id === resource.id) return null;

    const isRootFolder = depth === 1;

    const isParentFolder = id === currentParentId;
    const isSelected = id === newParentId;

    const spacing = depth * 16;

    const noChildren = sub_folders.length === 0;
    const showChildren = !collapsedFolders.includes(id) && !noChildren;

    const onSelectNewParent = () => {
      // cannot move folder into where it currently is
      if (isParentFolder) return;
      setNewParentId(isSelected ? undefined : id);
    };

    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })} key={`${name}-${id}`}>
        <div
          className={styles.folder({ isSelected, isParentFolder })}
          onClick={onSelectNewParent}
          style={{ paddingLeft: `${isRootFolder ? spacing + 8 : spacing - 8}px` }}>
          {isRootFolder ? undefined : (
            <Icon
              className={sprinkles({ color: noChildren ? 'gray9' : undefined })}
              name={showChildren ? 'caret-down' : 'caret-right'}
              onClick={(e) => {
                e.stopPropagation();
                if (!noChildren)
                  setCollapsedFolders((prev) =>
                    prev.includes(id)
                      ? prev.filter((collapsedId) => collapsedId !== id)
                      : [...prev, id],
                  );
              }}
              size="md"
            />
          )}
          <div className={styles.folderName}>
            <div className={sprinkles({ truncateText: 'ellipsis' })}>
              {isRootFolder ? `All ${type}s` : name}{' '}
            </div>
            {isParentFolder ? (
              <Tag>Parent Folder</Tag>
            ) : isSelected ? (
              <Icon className={sprinkles({ color: 'active' })} name="check" size="md" />
            ) : null}
          </div>
        </div>
        {showChildren ? sub_folders.map((subFolder) => renderFolders(subFolder, depth + 1)) : null}
      </div>
    );
  };

  return (
    <Modal
      contentClassName={styles.modalContent}
      isOpen={modalOpen}
      onClose={() => {
        closeModal();
        setNewParentId(undefined);
        setCollapsedFolders([]);
      }}
      portalContainerId={portalContainerId}
      primaryButtonProps={{
        disabled: !newParentId,
        onClick: () => {
          if (!newParentId) return;
          const id = isFolder ? resource.id : (resource as Resource).entry_id;
          dispatch(
            moveEntry({
              id,
              parent_id: newParentId,
              onError: () => {
                const item = isFolder
                  ? ResourceType.FOLDER
                  : isExploreProduct
                  ? ResourceType.DASHBOARD
                  : ResourceType.REPORT;
                showErrorToast(
                  `There was a problem moving your ${item}. Please make sure that the ${item} name is unique within the new folder context`,
                );
              },
            }),
          );
        },
        text: 'Move',
      }}
      size="small"
      tertiaryButtonProps={{ onClick: closeModal, text: 'Cancel' }}
      title={`Move "${resource.name}"`}>
      <div>
        {RD.isSuccess(hierarchy) ? (
          renderFolders(hierarchy.data)
        ) : RD.isLoading(hierarchy) ? (
          <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
            <Spinner />
          </div>
        ) : undefined}
      </div>
    </Modal>
  );
};
