import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateElementContainerLocation } from 'actions/dashboardV2Actions';
import { SettingHeader } from 'components/SettingHeader';
import { sprinkles } from 'components/ds';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME, ELEM_TYPE_TO_ICON } from 'constants/dashboardConstants';
import { OP_TYPE_TO_BP3_ICON, VIZ_TO_NAME } from 'constants/dataConstants';
import { ReduxState } from 'reducers/rootReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { filterForContainerElements } from 'utils/dashboardUtils';

import { maybeChangeNumberTrendVisualizationType } from '../charts/utils/trendUtils';

import { HeaderElemOptionSimple } from './PageHeaderConfig/HeaderElemOptionSimple';

type Props = {
  containerId: string;
};

export const ContainerElementConfigPanel: FC<Props> = ({ containerId }) => {
  const dispatch = useDispatch();

  const elements = useSelector((state: ReduxState) => state.dashboardEditConfig.config?.elements);
  const panels = useSelector((state: ReduxState) => state.dashboardEditConfig.config?.data_panels);

  const dataPanelsPartitioned = useMemo(
    () => filterForContainerElements(Object.values(panels ?? {}), containerId),
    [panels, containerId],
  );

  const elementsPartitioned = useMemo(() => {
    const nonContainerElements = Object.values(elements ?? {}).filter(
      (elem) => elem.element_type !== DASHBOARD_ELEMENT_TYPES.CONTAINER,
    );
    return filterForContainerElements(nonContainerElements, containerId, true);
  }, [elements, containerId]);

  const updateElementContainerLocationFn = useCallback(
    (elementId: string, addToContainer: boolean, isDataPanel: boolean) => {
      dispatch(
        updateElementContainerLocation({
          elementId,
          containerId: containerId,
          removeElem: !addToContainer,
          isDataPanel,
        }),
      );
    },
    [containerId, dispatch],
  );

  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="Included in Container" />
      <div className={sprinkles({ padding: 'sp1.5', color: 'gray12' })}>
        {dataPanelsPartitioned.elemsInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="minus"
            elemId={element.id}
            icon={
              OP_TYPE_TO_BP3_ICON[
                maybeChangeNumberTrendVisualizationType(
                  element.visualize_op.operation_type,
                  element.visualize_op.instructions.V2_KPI_TREND,
                )
              ]
            }
            key={`filter-header-${element.id}`}
            name={element.provided_id}
            onActionClicked={() => updateElementContainerLocationFn(element.id, false, true)}
            subName={VIZ_TO_NAME[element.visualize_op.operation_type]}
          />
        ))}
        {elementsPartitioned.elemsInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="minus"
            elemId={element.id}
            icon={ELEM_TYPE_TO_ICON[element.element_type]}
            key={`filter-header-${element.id}`}
            name={element.name}
            onActionClicked={() => updateElementContainerLocationFn(element.id, false, false)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[element.element_type]}
          />
        ))}
      </div>
      <SettingHeader name="Excluded from Container" />
      <div className={sprinkles({ padding: 'sp1.5', color: 'gray12' })}>
        {dataPanelsPartitioned.elemsNotInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={element.id}
            icon={
              OP_TYPE_TO_BP3_ICON[
                maybeChangeNumberTrendVisualizationType(
                  element.visualize_op.operation_type,
                  element.visualize_op.instructions.V2_KPI_TREND,
                )
              ]
            }
            key={`filter-header-${element.id}`}
            name={element.provided_id}
            onActionClicked={() => updateElementContainerLocationFn(element.id, true, true)}
            subName={VIZ_TO_NAME[element.visualize_op.operation_type]}
          />
        ))}
        {elementsPartitioned.elemsNotInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={element.id}
            icon={ELEM_TYPE_TO_ICON[element.element_type]}
            key={`filter-header-${element.id}`}
            name={element.name}
            onActionClicked={() => updateElementContainerLocationFn(element.id, true, false)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[element.element_type]}
          />
        ))}
      </div>
    </div>
  );
};
