import { FC } from 'react';

import { Icon, sprinkles, Tooltip } from 'components/ds';

export const BuiltInInfoTooltip: FC = () => (
  <Tooltip text="Built In Reports are pre-built reports that you can use to quickly preview data or create your own reports off of.">
    <Icon
      className={sprinkles({ color: 'contentTertiary', cursor: 'pointer' })}
      name="infoCircle"
    />
  </Tooltip>
);
