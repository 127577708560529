import cx from 'classnames';
import produce from 'immer';

import { CollapsibleMenu } from 'components/CollapsibleMenu';
import { Input, sprinkles } from 'components/ds';
import { GlobalStyleConfig } from 'globalStyles/types';
import LineSelect from 'shared/LineSelect';
import { showErrorToast } from 'shared/sharedToasts';

import ColorPicker from './CustomStylesColorPicker';
import { CustomizedTag } from './CustomizedTag';
import { ConfigSectionHeader } from './configSectionHeader';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export default function BaseConfigSection(props: Props) {
  return (
    <>
      <ConfigSectionHeader title="Base" />
      <NumColumnsInput {...props} />
      <ConfigSectionHeader compact isSubTitle title="Color" />
      <ColorInputs {...props} />
      <CustomInteractionColorMenu {...props} />
      <ConfigSectionHeader isSubTitle className={sprinkles({ marginTop: 'sp3' })} title="Spacing" />
      <SpacingInputs {...props} />
    </>
  );
}

function NumColumnsInput({ styleConfig, updateConfig }: Props) {
  return (
    <div className={sprinkles({ marginBottom: 'sp2' })}>
      <div className={inputLabelClass}>Number of dashboard columns</div>
      <Input
        fillWidth
        defaultValue={styleConfig.base.numColumns.toString()}
        min={1}
        onSubmit={(value) => {
          const numValue = parseInt(value);
          if (isNaN(numValue) || numValue < 1) return;
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.numColumns = numValue;
          });
          updateConfig(newConfig);
        }}
        type="number"
      />
    </div>
  );
}

function ColorInputs({ styleConfig, updateConfig }: Props) {
  return (
    <div className={sprinkles({ display: 'flex', marginBottom: 'sp2', gap: 'sp3' })}>
      <div className={sprinkles({ flex: 1 })}>
        <div className={inputLabelClass}>Background</div>
        <ColorPicker
          fill
          color={styleConfig.base.backgroundColor}
          onClose={(newColor) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.backgroundColor = newColor;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <div className={sprinkles({ flex: 1 })}>
        <div className={inputLabelClass}>Actions</div>
        <ColorPicker
          fill
          color={styleConfig.base.actionColor.default}
          onClose={(newColor) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.actionColor.default = newColor;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
    </div>
  );
}

function CustomInteractionColorMenu({ styleConfig, updateConfig }: Props) {
  return (
    <CollapsibleMenu title="Custom interaction colors">
      <div className={sprinkles({ marginBottom: 'sp1' })}>
        <div className={cx(sprinkles({ flexItems: 'alignCenter' }), inputLabelClass)}>
          <div>Button color</div>
          <CustomizedTag
            hidden={!styleConfig.base.actionColor.buttonColor}
            onCancel={() => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.base.actionColor.buttonColor = undefined;
              });
              updateConfig(newConfig);
            }}
          />
        </div>
        <ColorPicker
          fill
          color={styleConfig.base.actionColor.buttonColor || styleConfig.base.actionColor.default}
          onClose={(newColor) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.actionColor.buttonColor = newColor;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <div className={cx(sprinkles({ flexItems: 'alignCenter' }), inputLabelClass)}>
        <div>Interaction states</div>
        <CustomizedTag
          hidden={!styleConfig.base.actionColor.interactionStateColor}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.actionColor.interactionStateColor = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <ColorPicker
        fill
        alpha={0.1}
        color={
          styleConfig.base.actionColor.interactionStateColor || styleConfig.base.actionColor.default
        }
        onClose={(newColor) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.actionColor.interactionStateColor = newColor;
          });
          updateConfig(newConfig);
        }}
      />
    </CollapsibleMenu>
  );
}

function SpacingInputs({ styleConfig, updateConfig }: Props) {
  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1.5' })}>
      <Input
        defaultValue={`${styleConfig.base.spacing.default}px`}
        onSubmit={(newSpacingString) => {
          const newSpacing = Number(newSpacingString.replace('px', ''));
          if (isNaN(newSpacing) || newSpacing < 0) return;
          if (styleConfig.base.spacing.default !== 48 && newSpacing > 48) {
            /**
             * Setting spacing above 48px messes with the dashboard layout
             */
            showErrorToast('Cannot set spacing above 48px');
          }
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.spacing.default = newSpacing <= 48 ? newSpacing : 48;
          });
          updateConfig(newConfig);
        }}
        style={{ width: 55 }}
      />
      <LineSelect
        fill
        breakpoints={[10, 15, 20, 25, 30]}
        className={sprinkles({ flex: 1 })}
        labels={['Narrow', 'Moderate', 'Wide']}
        setValue={(newSpacing) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.spacing.default = newSpacing;
          });
          updateConfig(newConfig);
        }}
        value={styleConfig.base.spacing.default}
      />
    </div>
  );
}

const inputLabelClass = sprinkles({ body: 'b3', marginBottom: 'sp.5' });
