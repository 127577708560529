import 'react-resizable/css/styles.css';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';

import { WebShareProps } from 'components/ExportOptions/ExportOptions';
import { Icon, sprinkles } from 'components/ds';
import { EmbedButton, EmbedSwitch } from 'components/embed';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { inputContainerBackgroundClass } from 'globalStyles/sharedStyles.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { DashboardStates } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { fetchShareIdThunk } from 'reducers/thunks/dashboardLayoutThunks';
import { isSuccess, isIdle } from 'remotedata';
import InputLabel from 'shared/InputLabel';
import { getUrlSanitizedDashboardVars } from 'utils/dashboardUtils';

import * as styles from './exportStyles.css';

export const DEFAULT_POPOVER_TITLE = 'Share to web';
export const DEFAULT_POPOVER_SUBTITLE_NO_PASS = 'Anyone with the link can view';
export const DEFAULT_POPOVER_SUBTITLE_PASS = 'Only people with the password can view';

type Props = {
  webShareProps: WebShareProps;
  onBackButtonClick: () => void;
};

export const WebShareOption: FC<Props> = ({ webShareProps, onBackButtonClick }: Props) => {
  const dispatch = useDispatch();
  const { config, isSharedView, exportVars } = webShareProps;

  const { shareData, requestInfo, archetypeProperties } = useSelector(
    (state: DashboardStates) => ({
      shareData: state.dashboardLayout.shareData,
      requestInfo: state.dashboardLayout.requestInfo,
      archetypeProperties: getArchetypeProperties(state),
    }),
    shallowEqual,
  );

  const [isStrictViewingMode, setIsStrictViewingMode] = useState(false);
  const [passwordHasBeenSet, setPasswordHasBeenSet] = useState(false);
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const showPasswordElements = !isSharedView && config?.passwordEnabled;
  const hasNoPublishedVersions =
    requestInfo.type === 'app' ? requestInfo.isDraft && requestInfo.versionNumber === 1 : false;
  const showPasswordInput = showPasswordElements && !passwordHasBeenSet;

  const fetchShare = useCallback(
    (isStrict?: boolean) => {
      if (isSharedView || hasNoPublishedVersions) return;
      dispatch(
        fetchShareIdThunk({
          username: inputUsername,
          password: inputPassword,
          isStrictViewingMode: isStrict ?? isStrictViewingMode,
        }),
      );
    },
    [
      dispatch,
      isSharedView,
      inputPassword,
      inputUsername,
      isStrictViewingMode,
      hasNoPublishedVersions,
    ],
  );

  useEffect(() => {
    if (!showPasswordElements && isIdle(shareData)) fetchShare();
  }, [showPasswordElements, shareData, fetchShare]);

  const shareUrl = useMemo(() => {
    if (isSharedView) return window.location.href;
    if (!isSuccess(shareData)) return 'Generating...';

    const domain = shareData.data.shareLinkUrl || process.env.REACT_APP_URL;
    return parse(`${domain}share/${shareData.data.id}`)
      .set('query', getUrlSanitizedDashboardVars(exportVars ?? {}, archetypeProperties))
      .toString();
  }, [exportVars, shareData, isSharedView, archetypeProperties]);

  const inputClass = cx(inputContainerBackgroundClass, GLOBAL_STYLE_CLASSNAMES.text.body.input);

  const renderLinkInput = () => {
    return (
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <InputGroup
          fill
          readOnly
          className={inputClass}
          value={hasNoPublishedVersions ? 'No published versions' : shareUrl}
        />

        <EmbedButton
          disabled={hasNoPublishedVersions}
          onClick={() => copy(shareUrl)}
          variant="primary">
          Copy
        </EmbedButton>
        {showPasswordElements ? (
          <EmbedButton
            icon="refresh"
            onClick={() => {
              setPasswordHasBeenSet(false);
              setInputPassword('');
            }}
            variant="tertiary"
          />
        ) : null}
      </div>
    );
  };

  const renderPasswordInput = () => {
    return (
      <>
        {config?.usernameEnabled ? (
          <div>
            <InputLabel
              className={embedSprinkles({ smallBody: 'secondary' })}
              text="Username (Required)"
            />
            <div className={sprinkles({ flexItems: 'alignCenter' })}>
              <InputGroup
                fill
                onInputChange={setInputUsername}
                placeholder="Enter a username..."
                value={inputUsername}
              />
            </div>
          </div>
        ) : null}
        <div>
          <InputLabel
            className={embedSprinkles({ smallBody: 'secondary' })}
            text="Password (Required)"
          />
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
            <InputGroup
              fill
              className={inputClass}
              onInputChange={setInputPassword}
              placeholder="Enter a password..."
              type="password"
              value={inputPassword}
            />
            <EmbedButton
              disabled={!inputPassword || (config?.usernameEnabled && !inputUsername)}
              icon="lock"
              onClick={() => {
                fetchShare();
                setPasswordHasBeenSet(true);
              }}
              variant="primary">
              Set
            </EmbedButton>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className={embedSprinkles({ smallBody: 'secondary' })}>
          {config?.popoverSubtitle ||
            (config?.passwordEnabled
              ? DEFAULT_POPOVER_SUBTITLE_PASS
              : DEFAULT_POPOVER_SUBTITLE_NO_PASS)}
        </div>
        {showPasswordInput ? renderPasswordInput() : renderLinkInput()}

        {!isSharedView ? (
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
            <EmbedSwitch
              onChange={() => {
                fetchShare(!isStrictViewingMode);
                setIsStrictViewingMode(!isStrictViewingMode);
              }}
              switchOn={!isStrictViewingMode}
            />
            <div className={embedSprinkles({ body: 'secondary' })}>
              Allow viewers to interact with the filters
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderNoPublishError = () => {
    return (
      <div className={embedSprinkles({ body: 'secondary' })}>
        Need to publish a version to create a share link
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={cx(styles.title, embedSprinkles({ body: 'primary' }))}>
        <Icon className={styles.backButton} name="arrow-left" onClick={onBackButtonClick} />
        {config?.popoverTitle || DEFAULT_POPOVER_TITLE}
      </div>
      {hasNoPublishedVersions ? renderNoPublishError() : renderContent()}
    </div>
  );
};
