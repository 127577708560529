import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { saveDraftComputedViewQuery } from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetsByFidoId } from 'reducers/selectors';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { saveComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { DatasetSchema } from 'types/datasets';
import {
  getDatasetConfigFromView,
  getEmbeddoSchemaFromFidoSchema,
  getFidoSchemaFromEmbeddoSchema,
} from 'utils/fido/fidoShims';
import { getViewFromDatasetId } from 'utils/fido/fidoUtils';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';

import { DashboardDatasetEditor } from './dashboardDatasetEditor';

export const FidoDashboardDatasetEditorDataFetcher = () => {
  const dispatch = useDispatch();

  const {
    computedViews,
    parentSchemas,
    datasetData,
    datasets,
    datasetsByFidoId,
    selectedDatasetId,
  } = useSelector(
    (state: ReduxState) => ({
      computedViews: state.fido.computedViews,
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      datasets: state.dashboardEditConfig.config?.datasets,
      datasetsByFidoId: getDatasetsByFidoId(state),
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
    }),
    shallowEqual,
  );

  const getView = useCallback(
    (datasetId: string) => getViewFromDatasetId(computedViews, datasets, datasetId),
    [computedViews, datasets],
  );

  const datasetConfigs = useMemo(
    () =>
      RD.isSuccess(computedViews)
        ? Object.fromEntries(
            // @ts-ignore
            computedViews.data
              .map((view) => {
                const dataset = datasetsByFidoId[view.id];

                if (!dataset) return null;
                return [dataset.id, getDatasetConfigFromView(view, dataset)];
              })
              .filter((e) => e != null),
          )
        : {},
    [computedViews, datasetsByFidoId],
  );

  const onSaveQuery = (query: string) => {
    if (!selectedDatasetId) return;

    getUnderlyingData(query, undefined, (data) => {
      const selectedView = getView(selectedDatasetId);
      if (!selectedView) return;

      let schema: DatasetSchema;

      if ('meta' in data) {
        dispatch(
          saveComputedView({
            ...selectedView,
            query: query,
            columnDefinitions: data.meta.schema.propertySchema,
          }),
        );
        schema = getEmbeddoSchemaFromFidoSchema(data.meta.schema.propertySchema);
      } else {
        dispatch(
          saveComputedView({
            ...selectedView,
            query: query,
            columnDefinitions: getFidoSchemaFromEmbeddoSchema(data.dataset_preview.schema),
          }),
        );
        schema = data.dataset_preview.schema;
      }

      dispatch(fetchSavedDatasetThunk(selectedDatasetId));

      trackEvent(EVENTS.SAVED_QUERY, {
        dataset_id: selectedDatasetId,
        dataset_query: query,
      });

      showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(schema));
    });
  };

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftComputedViewQuery({ queryDraft: query, viewId: selectedDatasetId }));
  };

  const onSelectSchema = (schemaId: number | string) => {
    const schema = parentSchemas.find((s) => s.id === schemaId);
    if (!selectedDatasetId || !schema) return;

    selectedView &&
      dispatch(
        saveComputedView({ ...selectedView, namespaceId: schema.fido_id?.toString() ?? '' }),
      );
  };

  const getUnderlyingData = (
    query: string,
    pageNumber?: number,
    onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
  ) => {
    if (!selectedDatasetId) return;
    const view = getView(selectedDatasetId);

    if (!view) return;

    dispatch(
      fetchEditorDatasetPreviewThunk(
        {
          selectedDatasetId,
          query,
          parentSchemaId: parentSchemas.find((s) => s.fido_id === view.namespaceId)?.id ?? -1,
        },
        pageNumber,
        onSuccess,
      ),
    );
  };

  const selectedView = getView(selectedDatasetId ?? '');
  const selectedDataset = datasets ? datasets[selectedDatasetId ?? ''] : null;
  const data = selectedDatasetId ? datasetData[selectedDatasetId] : undefined;

  const savedSchema = getEmbeddoSchemaFromFidoSchema(selectedView?.columnDefinitions ?? []);

  return (
    <DashboardDatasetEditor
      // @ts-ignore
      activeDatasetConfig={
        selectedView && selectedDataset
          ? getDatasetConfigFromView(selectedView, selectedDataset)
          : null
      }
      activeDatasetData={data ?? null}
      activeDatasetSavedSchema={savedSchema}
      activeDatasetSchema={data?.schema ?? savedSchema}
      activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
      // @ts-ignore
      datasetConfigs={datasetConfigs}
      fetchData={getUnderlyingData}
      onSave={onSaveQuery}
      onSaveDraft={onSaveQueryDraft}
      onSelectSchema={onSelectSchema}
      parentSchemas={parentSchemas}
      selectedDatasetId={selectedDatasetId}
      setSelectedDatasetId={(datasetId: string | null) => {
        dispatch(setSelectedDatasetId(datasetId));

        trackEvent(EVENTS.SELECTED_DATASET, {
          dataset_id: datasetId,
          dataset_name: selectedView?.name,
        });
      }}
    />
  );
};
