import { DashboardElement } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { isInvalidLinkCombo } from 'utils/filterLinking';

/**
 * Constructs a map of the dataset ids to the data panels that are backed by the dataset and can be
 * linked to the element.
 */
export const constructLinkableDataPanelsMap = (
  dataPanels: DataPanel[],
  element: DashboardElement,
) => {
  const datasetIdToDataPanelsMap: Record<string, DataPanel[]> = {};
  dataPanels.forEach((dataPanel) => {
    if (isInvalidLinkCombo(element.element_type, dataPanel.visualize_op.operation_type)) return;
    const dataPanelDatasetId = getDataPanelDatasetId(dataPanel);
    datasetIdToDataPanelsMap[dataPanelDatasetId] = (
      datasetIdToDataPanelsMap[dataPanelDatasetId] ?? []
    ).concat(dataPanel);
  });
  return datasetIdToDataPanelsMap;
};
