import { createAsyncThunk } from '@reduxjs/toolkit';

import { DownloadDashboardBody, ExportScreenshotType } from 'actions/exportActions';
import { JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { DashboardStates } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getUrlParamStringFromDashVars } from 'utils/dashboardUtils';
import { makeThunkRequest, createApiRequestConfigWithRequestInfo } from 'utils/thunkUtils';

import { enqueueDashboardJobsThunk } from './jobsThunks';
import { DashboardLayoutThunk } from './types';

export const downloadDashboardThunk =
  (
    exportType: ExportScreenshotType,
    variables: DashboardVariableMap,
    downloadFileName: string | undefined,
    email?: string,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const requestInfo = state.dashboardLayout.requestInfo;
    const dashboardTheme = state.dashboardStyles.dashboardTheme;
    const archetypeProperties = getArchetypeProperties(state);

    const isEmbed = requestInfo.type === 'embedded';

    const resourceId = isEmbed ? requestInfo.resourceEmbedId : requestInfo.resourceId;

    const jobArgs: DownloadDashboardBody = {
      download_file_name: downloadFileName || String(resourceId),
      version_number: requestInfo.versionNumber,
      variable_query: getUrlParamStringFromDashVars(
        {
          ...variables,
          theme: dashboardTheme ?? undefined,
        },
        archetypeProperties,
      ),
      export_type: exportType,
      email,
    };

    // If email is passed in, we know we want to enqueue email job in backend so frontend does not have to keep track
    if (requestInfo.useJobQueue && email === undefined) {
      const job: JobDefinition = {
        job_type: ACTION.DOWNLOAD_DASHBOARD,
        job_args: jobArgs,
      };
      dispatch(enqueueDashboardJobsThunk({ jobs: [job] }));
    } else {
      dispatch(downloadDashboard(jobArgs));
    }
  };

export const downloadDashboard = createAsyncThunk<
  { url?: string },
  DownloadDashboardBody,
  { state: DashboardStates }
>(ACTION.DOWNLOAD_DASHBOARD, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/export/dashboard/${args.export_type}/`,
    appUrl: `export/dashboard/${args.export_type}/`,
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error downloading dashboard');
});
