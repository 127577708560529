import cx from 'classnames';
import { FC } from 'react';

import { sprinkles, Input } from 'components/ds';
import { SwitchElementConfig } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchValuesConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSwitchConfig);

  return (
    <div className={cx(sprinkles({ display: 'flex' }), styles.configInput)}>
      <Input
        showInputButton
        className={sprinkles({ paddingRight: 'sp.5' })}
        defaultValue={config.onStatusValue}
        label="On Value"
        onSubmit={(newValue) => updateConfig((draft) => (draft.onStatusValue = newValue))}
        placeholder="true"
      />
      <Input
        showInputButton
        className={sprinkles({ paddingLeft: 'sp.5' })}
        defaultValue={config.offStatusValue}
        label="Off Value"
        onSubmit={(newValue) => updateConfig((draft) => (draft.offStatusValue = newValue))}
        placeholder="false"
      />
    </div>
  );
};
