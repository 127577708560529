import { formatDefaultLocale, format, FormatLocaleDefinition } from 'd3-format';
import { DateTime, Settings, StringUnitLength, UnitLength } from 'luxon';

import { SupportedCurrency, SupportedLocale } from 'constants/types';
// internationalization
import CURRENCY_MAPPING from 'localization/currencyMapping';
import LOCALE_MAPPING from 'localization/localeMapping';

export enum TIME_FORMATS {
  'h:mm:ss A',
  'MM/DD/YYYY',
  'MMMM D, YYYY',
  'MMM D, YYYY',
  'MMM D, YYYY h:mm A',
  'MMM D',
  'HH:00 M/D',
  'MM/DD/YYYY HH:00 aa',
  'MM-DD-YY',
  'MM/DD/YYYY h:mm aa',
  'MMM YYYY',
  'YYYY',
  'ddd',
  'D',
  'MMM',
  'YYYY-MM-DD',
  'DD-MM-YYYY',
  'DD/MM/YYYY (HH:mm:ss)',
  'ha',
  'h:mm A',
  'MMMM D, YYYY h:mm A',
  'Relative to Now',
  'Quarter',
}
export const MONTH_PART_FORMAT_DICT: Record<string, UnitLength> = {
  L: 'numeric',
  LL: '2-digit',
  LLLLL: 'narrow',
  LLL: 'short',
};

export const DAY_PART_FORMAT_DICT: Record<string, StringUnitLength> = {
  ccccc: 'narrow',
  ccc: 'short',
};

const SUPPORTED_LOCALES: SupportedLocale[] = [
  { name: 'English', localeCode: 'en-us' },
  { name: 'English - United Kingdom', localeCode: 'en-gb' },
  { name: 'Portuguese - Brazil', localeCode: 'pt-br' },
  { name: 'Spanish', localeCode: 'es' },
  { name: 'French', localeCode: 'fr' },
  { name: 'German', localeCode: 'de' },
  { name: 'Italian', localeCode: 'it' },
  { name: 'Japanese', localeCode: 'ja' },
];

const SUPPORTED_CURRENCIES: SupportedCurrency[] = [
  { name: 'US Dollar', currencyCode: 'usd' },
  { name: 'Euro', currencyCode: 'eur' },
  { name: 'Pound Sterling', currencyCode: 'gbp' },
  { name: 'United Arab Emirates Dirham', currencyCode: 'aed' },
  { name: 'Brazilian Real', currencyCode: 'brl' },
  { name: 'Rupee, Pakistani', currencyCode: 'pkr' },
  { name: 'Indonesia Rupiah', currencyCode: 'idr' },
  { name: 'Rupee, Indian', currencyCode: 'inr' },
  { name: 'Mexican Peso', currencyCode: 'mxn' },
  { name: 'Canadian Dollar', currencyCode: 'cad' },
  { name: 'Japanese Yen', currencyCode: 'jpy' },
];

const getNavigatorLanguage = () => {
  let localeCode: string;
  if (navigator.languages && navigator.languages.length) {
    localeCode = navigator.languages[0];
  } else {
    localeCode = navigator.language;
  }

  return getSupportedLanguageOrUndefined(localeCode);
};

const getSupportedLanguageOrUndefined = (localeCode: string) =>
  Object.keys(LOCALE_MAPPING).find((locale) => localeCode.toLowerCase() === locale);

const getSupportedCurrencyOrUndefined = (code: string) => {
  // str isn't the code for sterling but we accidentally set that up so now we have to convert over
  if (code === 'str') code = 'gbp';
  return SUPPORTED_CURRENCIES.find(({ currencyCode }) => currencyCode.toLowerCase() === code);
};

const loadLocale = (options: {
  passedCurrencyCode?: string;
  passedLocaleCode?: string;
  teamCurrencyCode?: string;
  teamLocaleCode?: string;
  useBrowserLocale?: boolean;
}) => {
  const {
    passedCurrencyCode,
    passedLocaleCode,
    teamCurrencyCode,
    teamLocaleCode,
    useBrowserLocale,
  } = options;
  const defaultCurrencyCode = 'usd';
  const defaultLocaleCode = 'en-us';

  // order of preference is
  // 1) passed locale code from embedded component or via url
  // 2) browser locale, if setting is set by team
  // 3) team's default language
  // 4) en-us (fall-through for safety)
  let localeCode: string | undefined;

  // if one setting gives us a null value, try the next one or default to English if none work
  if (passedLocaleCode) localeCode = getSupportedLanguageOrUndefined(passedLocaleCode);
  if (!localeCode && useBrowserLocale) localeCode = getNavigatorLanguage();
  if (!localeCode && teamLocaleCode) localeCode = teamLocaleCode;
  if (!localeCode) localeCode = defaultLocaleCode;

  // order of preference is
  // 1) passed currency code from embedded component or via url
  // 2) team's default currency
  // 3) en-us (fall-through for safety)
  // this is decoupled from locale code
  let currencyCode: string | undefined;

  if (passedCurrencyCode)
    currencyCode = getSupportedCurrencyOrUndefined(passedCurrencyCode)?.currencyCode;
  if (!currencyCode && teamCurrencyCode) currencyCode = teamCurrencyCode;
  if (!currencyCode) currencyCode = defaultCurrencyCode;

  Settings.defaultLocale = localeCode;

  const numbers = (LOCALE_MAPPING[localeCode] ??
    LOCALE_MAPPING[defaultLocaleCode]) as FormatLocaleDefinition;

  numbers.currency = CURRENCY_MAPPING[currencyCode] ?? LOCALE_MAPPING[defaultCurrencyCode];
  formatDefaultLocale(numbers as FormatLocaleDefinition);

  return localeCode;
};

// ReactDatePicker requires a specific format string, so this is a vaguely hackey
// way of creating that format string for the two formats we use for the date picker
const getFormatForDatePicker = (format: TIME_FORMATS) => {
  const timeFormat =
    format === TIME_FORMATS['MM/DD/YYYY h:mm aa']
      ? DateTime.DATETIME_SHORT_WITH_SECONDS
      : DateTime.DATE_SHORT;
  const formatToParts = Intl.DateTimeFormat(
    Settings.defaultLocale ?? 'en',
    timeFormat,
  ).formatToParts();
  const uses12HourClock = formatToParts.some((part) => part.type === 'dayPeriod');

  let formatString = '';

  for (const part of formatToParts) {
    if (part.type === 'day') {
      formatString = formatString + 'dd';
    } else if (part.type === 'month') {
      formatString = formatString + 'MM';
    } else if (part.type === 'year') {
      formatString = formatString + 'yyyy';
    } else if (part.type === 'hour') {
      formatString = formatString + (uses12HourClock ? 'h' : 'HH');
    } else if (part.type === 'minute') {
      formatString = formatString + 'mm';
    } else if (part.type === 'second') {
      formatString = formatString + 'ss';
    } else if (part.type === 'dayPeriod') {
      formatString = formatString + 'aa';
    } else if (part.type === 'literal') {
      formatString = formatString + part.value;
    }
  }

  return formatString;
};

const formatTime = (dateTime: DateTime, format: TIME_FORMATS | string) => {
  if (typeof format === 'string') {
    return dateTime.toFormat(format);
  }

  switch (format) {
    case TIME_FORMATS['Relative to Now']:
      return dateTime.toRelative() ?? 'Unsupported Format Option'; //toRelative returns null when platform doesn't support Intl.RelativeTimeFormat
    case TIME_FORMATS['h:mm:ss A']:
      return dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS);
    case TIME_FORMATS['MM/DD/YYYY']:
      return dateTime.toLocaleString(DateTime.DATE_SHORT);
    case TIME_FORMATS['MMMM D, YYYY']:
      return dateTime.toLocaleString(DateTime.DATE_FULL);
    case TIME_FORMATS['MMM D, YYYY']:
      return dateTime.toLocaleString(DateTime.DATE_MED);
    case TIME_FORMATS['MMM D, YYYY h:mm A']:
      return dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    case TIME_FORMATS['MMM D']:
      return dateTime.toFormat('LLL d');
    case TIME_FORMATS['HH:00 M/D']:
      return (
        dateTime.toFormat("HH':00 ") + dateTime.toLocaleString({ day: 'numeric', month: 'numeric' })
      );
    case TIME_FORMATS['MM/DD/YYYY HH:00 aa']:
      return dateTime.toLocaleString(DateTime.DATE_SHORT) + ' ' + dateTime.toFormat("HH':00 aa");
    case TIME_FORMATS['MM-DD-YY']:
      return dateTime
        .toLocaleString({ ...DateTime.DATE_SHORT, month: '2-digit' })
        .replace(/\//g, '-');
    case TIME_FORMATS['MM/DD/YYYY h:mm aa']:
      return dateTime.toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, month: '2-digit' });
    case TIME_FORMATS['MMM YYYY']:
      return dateTime.toLocaleString({ month: 'short', year: 'numeric' });
    case TIME_FORMATS.YYYY:
      return dateTime.toLocaleString({ year: 'numeric' });
    case TIME_FORMATS.ddd:
      return dateTime.toLocaleString({ weekday: 'short' });
    case TIME_FORMATS.D:
      return dateTime.toLocaleString({ day: 'numeric' });
    case TIME_FORMATS.MMM:
      return dateTime.toLocaleString({ month: 'short' });
    case TIME_FORMATS['YYYY-MM-DD']:
      return dateTime.toFormat('yyyy-LL-dd');
    case TIME_FORMATS['DD-MM-YYYY']:
      return dateTime.toFormat('dd-LL-yyyy');
    case TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)']:
      return dateTime.toFormat('dd/LL/yyyy (TT)');
    case TIME_FORMATS.ha:
      return dateTime.toLocaleString({ hour: 'numeric' });
    case TIME_FORMATS['h:mm A']:
      return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
    case TIME_FORMATS['MMMM D, YYYY h:mm A']:
      return dateTime.toLocaleString({ ...DateTime.DATETIME_MED_WITH_SECONDS, month: 'long' });
    case TIME_FORMATS['Quarter']:
      return dateTime.toFormat('Qq ') + dateTime.toLocaleString({ year: 'numeric' });
  }
};

export {
  format,
  getFormatForDatePicker,
  loadLocale,
  SUPPORTED_CURRENCIES,
  SUPPORTED_LOCALES,
  formatTime,
};
