import { FC, useMemo, RefObject, useCallback } from 'react';

import { CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { sprinkles, Spinner } from 'components/ds';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { OPERATION_TYPES, PIE_CHART_TYPES } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportBarFunnelChart } from 'pages/ReportBuilder/ReportView/ReportChart//ReportBarFunnelChart';
import { ReportHeatMap } from 'pages/ReportBuilder/ReportView/ReportChart//ReportHeatMap';
import { NoData } from 'pages/ReportBuilder/ReportView/ReportChart/NoData';
import { ReportBarChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportBarChart';
import { ReportDataGrid } from 'pages/ReportBuilder/ReportView/ReportChart/ReportDataGrid';
import { ReportLineChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportLineChart';
import { ReportPieChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportPieChart';
import { ReportScatterPlot } from 'pages/ReportBuilder/ReportView/ReportChart/ReportScatterPlot';
import { ReportSingleNumberChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportSingleNumberChart';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { filterViewParams, isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { DatasetColumn } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import { getSchemaAndColConfigs } from 'utils/customerReportUtils';

interface Props {
  view: CustomerReportView;
  dataset?: ReportBuilderDataset;
  reportData: ReportData;
  containerRef: RefObject<HTMLDivElement>;
  onSelect?: (selection: { column: string; bucket?: PivotAgg; value: string | number }[]) => void;
  onFilter?: (column: DatasetColumn) => void;
}

export const ReportChart: FC<Props> = ({
  view,
  dataset,
  containerRef,
  reportData,
  onSelect,
  onFilter,
}) => {
  const columnOrder = view?.columnOrder;
  const hiddenColumns = view?.hiddenColumns;
  const { schema } = useMemo(
    () => getSchemaAndColConfigs(dataset, columnOrder, hiddenColumns, filterViewParams(view)),
    [dataset, columnOrder, hiddenColumns, view],
  );

  const handleChartSelect = useCallback(
    (chartInfo: ChartMenuInfo | null) => {
      const value = chartInfo?.category;
      const firstGroupBy = view.groupBys?.[0];
      const categoryColumn = firstGroupBy?.column;
      if (value != null && categoryColumn)
        onSelect?.([{ column: categoryColumn.name, bucket: firstGroupBy?.bucket, value }]);
    },
    [onSelect, view.groupBys],
  );

  if (reportData.error) {
    return (
      <div className={errorContainerStyles}>
        <EmbedText body="b1" className={errorHeadingStyles} color="contentTertiary">
          {reportData.error}
        </EmbedText>
      </div>
    );
  }

  if (!reportData.isLoading && reportData.rows?.length === 0) return <NoData />;

  const { visualization } = view;
  if (!visualization || isTableVisualization(visualization))
    return (
      <ReportDataGrid
        containerRef={containerRef}
        dataset={dataset}
        onFilter={onFilter}
        onSelect={onSelect}
        reportData={reportData}
        view={view}
      />
    );

  // Spinner rendered after ReportDataGrid since it has its own spinner and doesn't need this
  if (reportData.isLoading) return <Spinner fillContainer />;

  if (
    visualization === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
    visualization === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2
  ) {
    return (
      <ReportLineChart
        aggregations={view.aggregations}
        columnConfigs={dataset?.columnConfigs}
        columnGroupBys={view.columnGroupBys}
        groupBys={view.groupBys}
        onSelect={handleChartSelect}
        reportData={reportData}
        schema={schema}
        view={view}
        visualization={visualization}
      />
    );
  }

  if (visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2) {
    return (
      <ReportBarChart
        columnConfigs={dataset?.columnConfigs}
        onSelect={handleChartSelect}
        reportData={reportData}
        schema={schema}
        view={view}
      />
    );
  }

  if (PIE_CHART_TYPES.has(visualization)) {
    return (
      <ReportPieChart
        aggregations={view.aggregations}
        groupBys={view.groupBys}
        onSelect={handleChartSelect}
        reportData={reportData}
        schema={schema}
      />
    );
  }

  if (KPI_VIZ_OPS.has(visualization)) {
    return (
      <ReportSingleNumberChart
        aggregations={view.aggregations}
        columnConfigs={dataset?.columnConfigs}
        currentTableData={reportData}
        schema={schema}
      />
    );
  }

  if (visualization === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2) {
    return (
      <ReportHeatMap
        aggregations={view.aggregations}
        columnConfigs={dataset?.columnConfigs}
        columnGroupBys={view.columnGroupBys}
        groupBys={view.groupBys}
        onSelect={handleChartSelect}
        reportData={reportData}
        schema={schema}
        view={view}
      />
    );
  }

  if (visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2) {
    return (
      <ReportBarFunnelChart
        aggregations={view.aggregations}
        columnConfigs={dataset?.columnConfigs}
        groupBys={view.groupBys}
        reportData={reportData}
        schema={schema}
        view={view}
      />
    );
  }

  if (visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    return (
      <ReportScatterPlot
        columnConfigs={dataset?.columnConfigs}
        columnGroupBys={view.columnGroupBys}
        groupBys={view.groupBys}
        reportData={reportData}
        schema={schema}
        view={view}
      />
    );
  }

  return null;
};

const errorContainerStyles = sprinkles({
  borderTop: 1,
  borderColor: 'outline',
  flexItems: 'center',
  parentContainer: 'fill',
});

const errorHeadingStyles = sprinkles({
  padding: 'sp2',
  borderRadius: 4,
  backgroundColor: 'gray1',
});
