import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { sprinkles, Switch, InfoIcon, Select, TextArea, APP_PORTAL_ID } from 'components/ds';
import { EXPLO_PUBLIC_KEY, SSH_AUTH_TYPE } from 'pages/ConnectDataSourceFlow/constants';
import {
  setSSHAuthConfig,
  setSSHConfig,
  setUseSsh,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';
import { showSuccessToast } from 'shared/sharedToasts';

import { CredentialsInput } from './CredentialsInput';
import * as styles from './CredentialsSection.css';

const toggleInfoTextClass = sprinkles({
  flexItems: 'alignCenter',
  flexDirection: 'row',
  marginLeft: 'sp1',
  color: 'gray12',
  body: 'b2',
  gap: 'sp.5',
});

const sshPublicKeyLabel = "Explo's Public Key";

const SSHAuthOptions = [
  { value: SSH_AUTH_TYPE.VENDOR, label: 'Explo Private Key' },
  { value: SSH_AUTH_TYPE.TENANT, label: 'External Private Key' },
];

export const FidoSSHConfigSection: FC = () => {
  const {
    sshConfig: { config, auth, useSsh },
    isUpdatingDataSource,
  } = useSelector(
    (state: ReduxState) => ({
      sshConfig: state.fidoDataSourceConfig.sshConfig,
      isUpdatingDataSource: state.fidoDataSourceConfig.isUpdating,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  return (
    <div>
      <div className={cx(sprinkles({ display: 'flex' }), styles.credentialInput)}>
        <Switch onChange={(value) => dispatch(setUseSsh(value))} switchOn={useSsh} />
        <div className={toggleInfoTextClass}>
          Proxy requests via an SSH Tunnel.
          <InfoIcon text="Toggle this on if you require going through an SSH Tunnel to connect to your datasource" />
        </div>
      </div>
      {useSsh ? (
        <>
          <CredentialsInput
            label="SSH Server Hostname"
            placeholder="e.g. rds.amazonaws.com"
            updateConfig={(host) => dispatch(setSSHConfig({ host }))}
            value={config.host}
          />
          <CredentialsInput
            label="SSH Server Port"
            placeholder="e.g. 22"
            type="number"
            updateConfig={(port) => dispatch(setSSHConfig({ port: Number(port) }))}
            value={config.port}
          />
          <CredentialsInput
            label="SSH Username"
            placeholder="e.g. ec2-user"
            updateConfig={(username) => dispatch(setSSHAuthConfig({ username }))}
            value={auth?.username}
          />
          <Select
            className={styles.credentialInput}
            label="SSH Key"
            onChange={(type) => dispatch(setSSHAuthConfig({ ['@type']: type as SSH_AUTH_TYPE }))}
            portalContainerId={APP_PORTAL_ID}
            selectedValue={auth['@type']}
            values={SSHAuthOptions}
          />
          {auth['@type'] === SSH_AUTH_TYPE.VENDOR ? (
            <TextArea
              readOnly
              className={styles.publicKeyContainer}
              defaultValue={EXPLO_PUBLIC_KEY}
              label={{
                text: sshPublicKeyLabel,
                infoText:
                  "Copy this public key to your bastion, and we'll use our private key to connect.",
              }}
              onClick={() => {
                copy(EXPLO_PUBLIC_KEY);
                showSuccessToast('Public key copied to clipboard');
              }}
            />
          ) : (
            <TextArea
              defaultValue={auth.privateKey || undefined}
              label="SSH RSA Key (PEM format)"
              onSubmit={(value) => dispatch(setSSHAuthConfig({ privateKey: value || undefined }))}
              placeholder={
                isUpdatingDataSource
                  ? 'private key will be unmodified if left blank'
                  : 'e.g. -----BEGIN RSA PRIVATE KEY-----'
              }
            />
          )}
        </>
      ) : null}
    </div>
  );
};
