import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, sprinkles } from 'components/ds';
import { dataPanelLinkUpdatedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { getDatasetName } from 'utils/naming';

import { getUpdateConfigFunc } from '../utils';

import { ChartLinkRow } from './ChartLinkRow';

type Props = {
  dataset: ResourceDataset;
  element: DashboardElement;
  dataPanels: DataPanel[];

  updateConfig: (config: DashboardElementConfig) => void;
};

export const DatasetLink: FC<Props> = ({ element, dataset, dataPanels, updateConfig }) => {
  const dispatch = useDispatch();

  const { config, name: elementName } = element;
  const datasetFilter = config.datasetLinks?.[dataset.id];
  const [column, setColumn] = useState(datasetFilter?.column);

  useEffect(() => {
    const newColumn = datasetFilter?.column;
    if (newColumn === column) return;
    setColumn(newColumn);
    const dataPanelIds = datasetFilter?.dataPanels ?? [];
    dispatch(dataPanelLinkUpdatedThunk(elementName, dataPanelIds));
  }, [datasetFilter, column, elementName, dispatch]);

  const update = getUpdateConfigFunc(config, updateConfig);

  const operatorInfo = config.operator ? FILTER_OPERATOR_TYPES_BY_ID[config.operator] : undefined;

  const columnOptions = (dataset.schema ?? []).reduce((acc, col) => {
    if (operatorInfo && operatorInfo.supported_column_types.has(col.type)) {
      acc.push({ value: col.name, label: col.friendly_name || col.name });
    }
    return acc;
  }, [] as { value: string; label: string }[]);

  const selectedColumn = column ? dataset.schema?.find((col) => col.name === column) : undefined;

  const columnNotSupportedByOperator =
    selectedColumn && config.operator
      ? !columnOptions.find((col) => col.value === selectedColumn.name)
      : false;

  return (
    <div className={sprinkles({ marginTop: 'sp1.5' })}>
      <Select
        className={sprinkles({
          marginBottom: columnNotSupportedByOperator ? 'sp.5' : 'sp1',
        })}
        disabled={config.operator === undefined}
        filterProps={columnOptions.length >= 10 ? {} : undefined}
        label={getDatasetName(dataset)}
        onCancel={() =>
          update((draft) => {
            if (!draft.datasetLinks || !(dataset.id in draft.datasetLinks)) return;
            draft.datasetLinks[dataset.id].column = undefined;
          })
        }
        onChange={(colName) => {
          update((draft) => {
            if (!draft.datasetLinks) draft.datasetLinks = {};
            draft.datasetLinks[dataset.id] = {
              ...draft.datasetLinks[dataset.id],
              column: colName,
            };
          });
        }}
        placeholder="Search or select a field"
        selectedValue={selectedColumn?.name}
        values={columnOptions}
      />
      {columnNotSupportedByOperator ? (
        <div className={sprinkles({ body: 'b3', color: 'error', marginBottom: 'sp1' })}>
          Selected column is not supported by selected operator
        </div>
      ) : null}
      {selectedColumn
        ? dataPanels.map((dp) => (
            <ChartLinkRow
              config={config}
              dataPanel={dp}
              datasetId={dataset.id}
              elementName={elementName}
              key={`${dataset.id}-${dp.id}`}
              updateConfig={updateConfig}
            />
          ))
        : null}
    </div>
  );
};
