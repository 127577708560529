import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { TimeFormatConfig } from 'components/ChartConfigs/TimeFormatConfig';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { SettingHeader } from 'components/SettingHeader';
import { Input } from 'components/ds';
import { V2_NUMBER_FORMATS, NUMBER_FORMATS_WITH_DECIMALS } from 'constants/dataConstants';
import { OPERATION_TYPES, VisualizeOperationInstructions } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { updateMapTooltipFormatting } from 'reducers/thunks/mapThunks';
import { MapTooltipFormat } from 'types/maps';

type Props = {
  instructions: VisualizeOperationInstructions;
  dataPanelId: string;
  operationType:
    | OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP
    | OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP;
  colName?: string;
  hideHeader?: boolean;
};

// TODO(SHIBA-5718): Consolidate this config with the KPI value format config
export const MapTooltipConfig: FC<Props> = ({
  instructions,
  dataPanelId,
  operationType,
  colName,
  hideHeader,
}) => {
  const dispatch = useDispatch();

  let tooltipFormat;

  switch (operationType) {
    case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
      tooltipFormat =
        instructions.V2_TWO_DIMENSION_CHART?.chartSpecificFormat?.choroplethMap?.tooltipFormat ||
        {};
      break;
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      tooltipFormat = colName
        ? instructions.VISUALIZE_GEOSPATIAL_CHART?.mapFormat?.tooltipFormat?.[colName] || {}
        : {};
      break;
    default:
      tooltipFormat = {};
  }

  const selectedFormat = tooltipFormat.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const updateOperation = useCallback(
    (newFormat: MapTooltipFormat) => {
      dispatch(updateMapTooltipFormatting(dataPanelId, newFormat, colName));
    },
    [dispatch, dataPanelId, colName],
  );

  return (
    <div className={configRootClass}>
      {!hideHeader ? <SettingHeader name="Tooltip" /> : null}
      <NumberFormatToggle
        className={configInputClass}
        label="Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateOperation({ numberFormat: { id: numberFormat } })}
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <TimeFormatConfig
          configInputClass={configInputClass}
          updateFormat={updateOperation}
          valueFormat={tooltipFormat}
        />
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(tooltipFormat.decimalPlaces ?? 2)}
          label="Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateOperation({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(tooltipFormat.significantDigits ?? 3)}
          label="Significant Digits"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateOperation({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}

      <Input
        showInputButton
        className={configInputClass}
        defaultValue={tooltipFormat.unit}
        label="Unit"
        onSubmit={(newValue) => updateOperation({ unit: newValue })}
      />
    </div>
  );
};
