import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';

import { passwordResetRequest } from 'actions/authAction';
import { pageView } from 'analytics/exploAnalytics';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { Input, sprinkles, Button } from 'components/ds';
import { showCustomToast } from 'shared/sharedToasts';

export const ForgotPasswordPage: FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);

  useEffect(() => {
    pageView('Forgot password');
  }, []);

  const onPasswordResetRequestSubmitted = () => {
    if (email.trim() === '') {
      setErrorMsg('Please enter an email.');
    } else {
      setErrorMsg('');
      setPasswordResetLoading(true);
      dispatch(
        passwordResetRequest(
          { postData: { email } },
          () => {
            showCustomToast('If the email address is valid, an email was sent successfully.', {
              icon: 'endorsed',
            });
            setEmail('');
            setPasswordResetLoading(false);
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (response: any) => {
            if (Object.keys(response).length > 0) {
              let error;
              if (response.email) {
                error = response.email[0];
              } else if (response.non_field_errors) {
                error = response.non_field_errors[0];
              }

              if (error) setErrorMsg(error);
            }
            setPasswordResetLoading(false);
          },
        ),
      );
    }
  };

  return (
    <OnboardingFlowPage
      subtitle="We’ll email you a link to set a new password."
      title="Password Reset">
      <div className={sprinkles({ flexItems: 'column', width: 'fill', gap: 'sp2' })}>
        <Input
          className={sprinkles({ width: 'fill' })}
          data-testid="forgot-password-email"
          errorText={errorMsg}
          label="Work email"
          onChange={setEmail}
          placeholder="michael@dundermifflin.com"
          value={email}
        />
        <Button
          fillWidth
          data-testid="forgot-password-submit"
          loading={passwordResetLoading}
          onClick={() => onPasswordResetRequestSubmitted()}>
          Send password reset link
        </Button>
      </div>
    </OnboardingFlowPage>
  );
};
