import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedFetchShareData, embedFetchShareData } from 'actions/embedActions';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { VIEW_MODE } from 'types/dashboardTypes';

import { PortalHeader } from './portalHeader';

type Props = {
  dashboardLogoUrl?: string;
  dashboardName: string;
  onSignOut: () => void;
  shareId: string;
};

export const PortalDashboardPage: FC<Props> = ({
  dashboardLogoUrl,
  dashboardName,
  onSignOut,
  shareId,
}) => {
  const dispatch = useDispatch();

  const [shareData, setShareData] = useState<EmbedFetchShareData | null>(null);

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId }, (shareData) => {
        setShareData(shareData);
      }),
    );
  }, [dispatch, shareId]);

  if (!shareData) return <EmbedSpinner fillContainer size="xl" />;

  return (
    <div className={sprinkles({ width: 'fill', height: 'fillViewport' })}>
      <PortalHeader
        dashboardName={dashboardName}
        iconUrl={dashboardLogoUrl}
        onSignOut={onSignOut}
      />
      <EmbeddedDashboard
        customerToken={shareData.customer_token}
        dashboardEmbedId={shareData.dashboard_embed_id}
        embedType="portal"
        isStrict={shareData.is_strict_viewing_mode}
        versionNumber={shareData.version_number}
        viewMode={VIEW_MODE.SHARE}
      />
    </div>
  );
};
