import { FC } from 'react';

import { V2_NUMBER_FORMATS } from 'constants/dataConstants';

import { Toggle, ToggleItem } from './ds';

type Props = {
  className?: string;
  label?: string;
  labelHelpText?: string;
  disableTime?: boolean;
  disableAbbr?: boolean;
  selectedFormat: string;
  updateFormat: (newFormat: string) => void;
};

export const NumberFormatToggle: FC<Props> = ({
  className,
  selectedFormat,
  label,
  labelHelpText,
  updateFormat,
  disableTime,
  disableAbbr,
}) => {
  return (
    <Toggle
      className={className}
      label={label}
      labelHelpText={labelHelpText}
      onValueChange={updateFormat}
      selectedValue={selectedFormat}>
      {Object.values(V2_NUMBER_FORMATS).map((numberFormat) => {
        if (disableTime && numberFormat.id === V2_NUMBER_FORMATS.TIME.id) return null;
        if (disableAbbr && numberFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id) return null;
        return (
          <ToggleItem key={numberFormat.id} value={numberFormat.id}>
            {numberFormat.abbreviation}
          </ToggleItem>
        );
      })}
    </Toggle>
  );
};
