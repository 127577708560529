import cx from 'classnames';
import { FC } from 'react';

import { Icon, sprinkles } from './ds';

type Props = {
  icon?: JSX.Element | null;
  isSelected: boolean;
  name: string;
  onClick: () => void;
};

export const DashboardElementVisibilityCard: FC<Props> = ({ icon, isSelected, name, onClick }) => {
  return (
    <div
      className={cx(rootClass, isSelected ? rootSelectedClass : rootUnselectedClass)}
      onClick={onClick}>
      <div className={sprinkles({ flexItems: 'alignCenter', overflow: 'hidden', flex: 1 })}>
        {icon ? (
          <div className={sprinkles({ flexItems: 'alignCenter', marginRight: 'sp1' })}>{icon}</div>
        ) : null}
        <div className={sprinkles({ truncateText: 'ellipsis', body: 'b1' })}>{name}</div>
      </div>
      <div
        className={sprinkles({ flexItems: 'alignCenter', marginLeft: 'sp1' })}
        style={{ minWidth: 12 }}>
        <Icon
          className={sprinkles({ color: 'gray11' })}
          name={isSelected ? 'eye-open' : 'eye-closed'}
        />
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  backgroundColor: 'white',
  border: 2,
  borderColor: 'outline',
  cursor: 'pointer',
  borderRadius: 4,
  marginBottom: 'sp1',
  flexItems: 'alignCenterBetween',
  padding: 'sp1',
});

const rootSelectedClass = sprinkles({
  backgroundColor: { default: 'white', hover: 'elevationHigh' },
});
const rootUnselectedClass = sprinkles({
  backgroundColor: { default: 'elevationMid', hover: 'elevationLow' },
});
