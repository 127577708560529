import cx from 'classnames';
import produce from 'immer';
import { FC, useMemo } from 'react';

import { CollapsibleMenu } from 'components/CollapsibleMenu';
import { InfoIcon, Input, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import {
  FontFamilyConfig,
  GlobalFontConfig,
  GlobalStyleConfig,
  GlobalStyleFontConfig,
  GlobalStyleTextConfig,
} from 'globalStyles/types';
import { getFontFamilyName } from 'globalStyles/utils';
import LineSelect from 'shared/LineSelect';
import { capitalize } from 'utils/standard';

import { ColorPicker } from '../../components/ColorPicker';
import { getOrphanFonts } from '../../globalStyles/fontConfigUtils';

import { CustomizedTag } from './CustomizedTag';
import { TextWeightSelect } from './TextConfigSection/TextWeightSelect';
import { ConfigSectionHeader } from './configSectionHeader';

type Props = {
  styleConfig: GlobalStyleConfig;
  fontConfig: GlobalFontConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  fonts: SelectItems<string>;
  googleFonts: string[];
};

export const TextConfigSection: FC<Props> = (props) => {
  return (
    <>
      <ConfigSectionHeader className={sprinkles({ marginTop: 'sp5' })} title="Text" />
      <FontRow type="primary" {...props} />
      <FontRow
        className={sprinkles({ marginTop: 'sp2', marginBottom: 'sp2.5' })}
        type="secondary"
        {...props}
      />
      <ConfigSectionHeader isSubTitle title="Text Size" />
      <TextSizeInputs {...props} />
      <TextOverrideCollapsibleMenu {...props} />
    </>
  );
};

function FontRow({
  styleConfig,
  fontConfig,
  updateConfig,
  fonts,
  googleFonts,
  type,
  className,
}: Props & {
  type: 'primary' | 'secondary';
  className?: string;
}) {
  const fontType = type === 'primary' ? 'primaryFont' : 'secondaryFont';
  const fontColor = type === 'primary' ? 'primaryColor' : 'secondaryColor';
  const fontWeight = type === 'primary' ? 'primaryWeight' : 'secondaryWeight';
  const font = styleConfig.text[fontType];

  const handleUpdate: TextUpdater = (key, value) => {
    const newConfig = produce(styleConfig, (draft) => {
      draft.text[key] = value;
    });
    updateConfig(newConfig);
  };

  const { fontOptions, weightOptions } = useFontAndWeightOptions(
    fonts,
    fontConfig,
    font,
    googleFonts,
  );

  return (
    <div className={cx(sprinkles({ display: 'flex', overflow: 'hidden', gap: 'sp1' }), className)}>
      <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
        <div className={inputLabelClassName}>{`${capitalize(type)} Font`}</div>
        <Select
          contentWidth="auto"
          filterProps={{ maxValues: 25, selectedLabel: font && getFontFamilyName(font) }}
          onCancel={() => handleUpdate(fontType, undefined)}
          onChange={(font: string) => handleUpdate(fontType, font)}
          placeholder="Inherit"
          selectedValue={font}
          values={fontOptions}
        />
      </div>
      <div>
        <div className={inputLabelClassName}>Color</div>
        <ColorPicker
          fill
          color={styleConfig.text[fontColor]}
          onClose={(newColor) => handleUpdate(fontColor, newColor)}
        />
      </div>
      <div>
        <div className={inputLabelClassName}>
          Weight <InfoIcon text={WEIGHT_TOOLTIP_TEXT} />
        </div>
        <TextWeightSelect
          onSubmit={(weight) => handleUpdate(fontWeight, weight)}
          selectedValue={
            styleConfig.text[fontWeight] ||
            fontConfig
              .find((f) => f.fontFamily === font)
              ?.fontFaces.find((f) => f.fontWeight === 400)?.fontWeight ||
            400
          }
          values={weightOptions}
        />
      </div>
    </div>
  );
}

function TextSizeInputs({ styleConfig, updateConfig }: Props) {
  const handleUpdate: TextUpdater = (key, value) => {
    const newConfig = produce(styleConfig, (draft) => {
      draft.text[key] = value;
    });
    updateConfig(newConfig);
  };

  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1.5' })}>
      <Input
        defaultValue={`${styleConfig.text.textSize}px`}
        onSubmit={(newTextSizeString) => {
          const newTextSize = parseTextSizeString(newTextSizeString);
          if (!isNaN(newTextSize) && newTextSize >= 1) handleUpdate('textSize', newTextSize);
        }}
        style={{ width: 55 }}
      />
      <LineSelect
        fill
        breakpoints={[10, 12, 14, 16, 18]}
        className={sprinkles({ flex: 1 })}
        labels={['Small', 'Moderate', 'Large']}
        setValue={(newTextSize) => handleUpdate('textSize', newTextSize)}
        value={styleConfig.text.textSize}
      />
    </div>
  );
}

function TextOverrideCollapsibleMenu(props: Props) {
  return (
    <CollapsibleMenu className={sprinkles({ marginTop: 'sp2' })} title="Custom text">
      <CollapsibleMenuRow isFirst override="h1" text="H1" {...props} />
      <CollapsibleMenuRow override="h2" text="Chart Titles" {...props} />
      <CollapsibleMenuRow override="kpiTitle" text="KPI Titles" {...props} />
      <CollapsibleMenuRow override="kpiValue" text="KPI Values" {...props} />
      <CollapsibleMenuRow override="tableColumnHeader" text="Table Column Headers" {...props} />
      <CollapsibleMenuRow override="body" text="Body" {...props} />
      <CollapsibleMenuRow override="smallHeading" text="Small Heading" {...props} />
      <CollapsibleMenuRow override="smallBody" text="Small Body" {...props} />
      <CollapsibleMenuRow override="filterLabel" text="Filter Labels" {...props} />
    </CollapsibleMenu>
  );
}

type CollapsibleMenuRowProps = {
  override: keyof GlobalStyleConfig['text']['overrides'];
  text: string;
  isFirst?: boolean;
};

function CollapsibleMenuRow({
  fonts,
  googleFonts,
  override,
  text,
  isFirst,
  styleConfig,
  fontConfig,
  updateConfig,
}: CollapsibleMenuRowProps & Props) {
  const font = styleConfig.text.overrides[override]?.font;
  const size =
    styleConfig.text.overrides[override]?.size ||
    styleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[override];
  const weight = styleConfig.text.overrides[override]?.weight;

  const handleUpdate: OverrideUpdater = (key, value) => {
    const newConfig = produce(styleConfig, (draft) => {
      const textOverride = draft.text.overrides[override];
      if (textOverride) textOverride[key] = value;
      else draft.text.overrides[override] = { [key]: value };
    });
    updateConfig(newConfig);
  };

  const { fontOptions, weightOptions } = useFontAndWeightOptions(
    fonts,
    fontConfig,
    font,
    googleFonts,
  );

  return (
    <div>
      <div
        className={cx(
          inputLabelClassName,
          sprinkles({ flexItems: 'alignCenter', marginTop: isFirst ? undefined : 'sp2' }),
        )}>
        {text}
        <CustomizedTag
          hidden={!styleConfig.text.overrides[override]}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'flex-end',
          gap: 'sp1',
          overflow: 'hidden',
        })}>
        <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
          <div className={inputLabelClassName}>Font</div>
          <Select
            className={sprinkles({ flex: 1, overflow: 'hidden' })}
            contentWidth="auto"
            filterProps={{ maxValues: MAX_FONTS, selectedLabel: font && getFontFamilyName(font) }}
            onCancel={() => handleUpdate('font', undefined)}
            onChange={(font) => handleUpdate('font', font)}
            placeholder="Inherit"
            selectedValue={font}
            values={fontOptions}
          />
        </div>
        <div>
          <div className={inputLabelClassName}>Color</div>
          <ColorPicker
            fill
            color={styleConfig.text.overrides[override]?.color || styleConfig.text.primaryColor}
            onClose={(newColor) => handleUpdate('color', newColor)}
          />
        </div>
        <div>
          <div className={inputLabelClassName}>
            Weight <InfoIcon text={WEIGHT_TOOLTIP_TEXT} />
          </div>
          <TextWeightSelect
            onSubmit={(weight) => handleUpdate('weight', weight)}
            placeholder="Inherit"
            selectedValue={weight}
            values={weightOptions}
          />
        </div>
        <div>
          <div className={inputLabelClassName}>Size</div>
          <Input
            defaultValue={`${size}px`}
            onSubmit={(newTextSizeString) => {
              const newTextSize = parseTextSizeString(newTextSizeString);
              if (!isNaN(newTextSize) && newTextSize >= 1) handleUpdate('size', newTextSize);
            }}
            style={{ width: 55 }}
          />
        </div>
      </div>
    </div>
  );
}

function useFontAndWeightOptions(
  fonts: SelectItems<string>,
  fontConfig: FontFamilyConfig[],
  font: string | undefined,
  googleFonts: string[],
) {
  const orphanFonts = useMemo(() => {
    const fontValues = fonts.map((font) => font.value);
    return getOrphanFonts(fontValues, fontConfig);
  }, [fontConfig, fonts]);

  const fontOptions = useMemo(() => {
    const familyOptions = fontConfig.map((font) => ({ label: font.fontFamily, value: font.id }));
    const orphanOptions = orphanFonts.map((font) => ({
      label: getFontFamilyName(font),
      value: font,
    }));
    return [...orphanOptions, ...familyOptions];
  }, [fontConfig, orphanFonts]);

  const weightOptions = useMemo(() => {
    if (!font || googleFonts.includes(font)) return;
    if (orphanFonts.includes(font)) return [{ label: '400 (Normal)', value: '400' }];
    return fontConfig
      .find((f) => f.id === font)
      ?.fontFaces.map(({ fontWeight }) => ({
        label: fontWeight?.toString() || 'Default',
        value: fontWeight?.toString() || '',
      }));
  }, [font, fontConfig, googleFonts, orphanFonts]);

  return { fontOptions, weightOptions };
}

const parseTextSizeString = (newTextSizeString: string) =>
  Number(newTextSizeString.replace(/\D/g, ''));

const MAX_FONTS = 25;
const WEIGHT_TOOLTIP_TEXT = 'Text thickness. 400 is normal. 700 is bold';

type TextUpdater = <K extends keyof GlobalStyleTextConfig>(
  key: K,
  value: GlobalStyleTextConfig[K],
) => void;

type OverrideUpdater = <K extends keyof GlobalStyleFontConfig>(
  key: K,
  value: GlobalStyleFontConfig[K],
) => void;

const inputLabelClassName = sprinkles({ body: 'b3', marginBottom: 'sp.5' });
