import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import * as customerActions from 'actions/customerReportActions';
import {
  CustomerReport,
  CustomerReportAgg,
  CustomerReportConfig,
  CustomerReportDataInfo,
  CustomerReportGroupBy,
  CustomerReportView,
  CustomerReportVisualization,
  UpdateColorCategoryTracker,
  UpdateCustomerReportViewName,
  CustomerReportFilter,
  TotalAccumulatorKey,
  BarChartOptions,
  fetchCustomerReportSuccess,
  fetchCustomerReportRequest,
  fetchCustomerReportError,
} from 'actions/customerReportActions';
import { updateReportDefaultTimezoneSuccess } from 'actions/reportBuilderActions';
import { BuiltInReportConfig, ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import {
  createReportBuilderEmailCadenceSuccess,
  deleteReportBuilderEmailCadenceSuccess,
  listReportBuilderEmailCadencesSuccess,
  ReportBuilderEmailCadence,
  updateReportBuilderEmailCadenceSuccess,
} from 'actions/reportBuilderEmailCadenceActions';
import { switchCustomer } from 'actions/teamActions';
import { SCATTER_X_AXIS_TYPES, SCATTER_Y_AXIS_TYPES, STRING } from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  SortInfo,
  ColorCategoryTracker,
  SortOrder,
  Aggregation,
} from 'constants/types';
import {
  DataPanelData,
  COL_LIST_SECTION_ID,
  ROWS_SECTION_ID,
  COLS_SECTION_ID,
  AGGS_SECTION_ID,
  SCATTER_PLOT_GROUPING_SECTION_ID,
} from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { DRAFT_REPORT_ID } from 'pages/ReportBuilder/constants';
import * as navUtils from 'pages/ReportBuilder/utils/navigationUtils';
import { getNextViewName } from 'pages/ReportBuilder/utils/viewUtils';
import {
  getAggLimit,
  getGroupByLimit,
  getGroupByDisabled,
  getColumnGroupByDisabled,
  getAggDisabled,
  getColumnGroupByLimit,
  getScatterPlotGroupingDisabled,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import {
  downloadReportBuilderComputationSpreadsheet,
  fetchFidoEmbedReportBuilderView,
  fetchFidoReportBuilderView,
} from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { isLoading } from 'remotedata';
import * as RD from 'remotedata';
import {
  DATASET_DATA_PREFIX,
  getDrilldownDataId,
  getAggDataId,
  getTotalDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import {
  fetchAppReportDataApi,
  fetchAppReportRowCount,
} from 'reportBuilderContent/thunks/appDataThunks';
import {
  exportCustomerReportError,
  exportCustomerReportSuccess,
  exportReport,
  sendDraftExportRequest,
  sendDraftExportSuccess,
  sendDraftExportError,
  sendTestExportRequest,
  sendTestExportSuccess,
  sendTestExportError,
} from 'reportBuilderContent/thunks/exportThunks';
import { createCustomerReport, saveCustomerReport } from 'reportBuilderContent/thunks/reportThunks';
import { BaseCol, ColumnConfigs } from 'types/columnTypes';
import { DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { getAggColName, getAggColNameBase, getGroupByName } from 'utils/V2ColUtils';
import { setTableColorCategoryData } from 'utils/colorCategorySyncUtils';
import {
  getAggUniqueId,
  getFilterDefaultOperation,
  getGroupByUniqueId,
  getNewGroupBy,
  getNewAgg,
} from 'utils/customerReportUtils';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { getEmbeddoResponseFromFidoResponse } from 'utils/fido/fidoShims';
import { shiftIdsInList, shiftItemsInList } from 'utils/general';
import { columnConfigsToDisplayOptions } from 'utils/reportBuilderConfigUtils';
import { maxBy, isEqual, pick } from 'utils/standard';

import {
  fetchDashboardReportRowCountError,
  fetchDashboardReportRowCountSuccess,
  fetchDashboardReportRowCountRequest,
  fetchDashboardReportDataError,
  fetchDashboardReportDataSuccess,
  fetchDashboardReportDataRequest,
} from '../actions/dashboardJobActions';
import {
  fetchEmbeddedReportDataError,
  fetchEmbeddedReportDataRequest,
  fetchEmbeddedReportDataSuccess,
  fetchEmbeddedRowCountError,
  fetchEmbeddedRowCountRequest,
  fetchEmbeddedRowCountSuccess,
} from '../actions/embeddedJobActions';
import { DRAFT_EMAIL_ID } from '../exportTypes';
import {
  fetchEmbeddedReportDataApi,
  fetchEmbeddedReportRowCount,
} from '../thunks/embeddedDataThunks';

import {
  ReportModal,
  ReportModalType,
  ApplyFilterInfoPayload,
  CreateFilterPayload,
  SelectedReportType,
  ReportType,
  ReportData,
} from './types';

type DrilldownConfig = { filters?: CustomerReportFilter[]; sort?: SortInfo[]; page?: number };
type DrillDownConfigs = Partial<Record<string, DrilldownConfig>>;

interface ReportEditingState {
  selectedReport: SelectedReportType | null;
  currentConfig: CustomerReportConfig | null;
  reportStatus: RD.ResponseData<null>;

  openModal: ReportModal | null;
  currentView: string | null;
  openFilterId: string | number | null;
  isDataPanelOpen: boolean;

  // Key is view ID
  drilldownConfigs: DrillDownConfigs;
  isDrilldownPanelOpen: boolean;

  // Shared by Views, Min Max Filters, Categorical Filters, AI Modal, and Dataset Modal.
  // For views, key is viewId. For others, see reportBuilderContent/reducers/utils for getting ID
  reportData: Partial<Record<string, ReportData>>;
  reportSourceType: string | null;

  // Use consistent colors for Category string values
  colorCategoryTracker: ColorCategoryTracker;

  // Required for updating columnColorTracker
  columnConfigs: ColumnConfigs;

  isExportDownloaded: boolean;
  exportUrl: RD.ResponseData<string>;
  scheduledExport: RD.ResponseData<null>; // Only used for tracking HTTP req status
  exportEmailId: string;
  emailCadences: RD.ResponseData<ReportBuilderEmailCadence[]>;

  // Used for alerting user when their groupBys or aggregations get removed after changing visualization types
  // Key is view ID
  removedViewConfigs: Record<
    string,
    {
      groupBys?: CustomerReportGroupBy[];
      aggregations?: CustomerReportAgg[];
      columnGroupBys?: CustomerReportGroupBy[];
      scatterPlotGrouping?: CustomerReportGroupBy;
    }
  >;
}

const initialState: ReportEditingState = {
  selectedReport: null,
  currentConfig: null,
  reportData: {},
  reportStatus: RD.Idle(),
  openModal: null,
  isExportDownloaded: false,
  exportUrl: RD.Idle(),
  emailCadences: RD.Idle(),
  colorCategoryTracker: {},
  columnConfigs: {},
  currentView: null,
  openFilterId: null,
  isDataPanelOpen: true,
  removedViewConfigs: {},
  drilldownConfigs: {},
  isDrilldownPanelOpen: false,
  reportSourceType: null,
  scheduledExport: RD.Idle(),
  exportEmailId: '',
};

const INITIAL_TABLE_DATA: ReportData = {
  isLoading: false,
  rowCount: RD.Idle(),
  page: 1,
};

function createBlankView(state: ReportEditingState): CustomerReportView {
  const name = getNextViewName(state.currentConfig?.views);

  return {
    id: uuidv4(),
    name,
    columnOrder: state.currentConfig?.dataInfo?.columns ?? [],
    hiddenColumns: [],
    filters: [],
    sort: [],
  };
}

const createCurrentViewIfNone = (state: ReportEditingState): void => {
  if (!state.currentConfig || state.currentConfig.views !== undefined || state.currentView) return;

  const view = createBlankView(state);
  state.currentConfig.views = [view];
  state.currentView = view.id;

  navUtils.goToReportView(state.selectedReport, state.currentView);
};

const getCurrentViewBase = (state: ReportEditingState) =>
  state.currentConfig?.views?.find((view) => view.id === state.currentView);

const updateCurrentViewHelper = (
  state: ReportEditingState,
  updateView: (view: CustomerReportView) => void,
) => {
  const view = getCurrentViewBase(state);
  if (view) updateView(view);
  return view;
};

function updateCurrentViewData(state: ReportEditingState, updateData: (data: ReportData) => void) {
  const viewId = state.currentView;
  if (!viewId) return;
  updateReportData(state, viewId, updateData);
}

function updateReportData(
  state: ReportEditingState,
  viewId: string,
  updateData: (data: ReportData) => void,
) {
  const currentData = state.reportData[viewId] || { ...INITIAL_TABLE_DATA };
  if (!state.reportData[viewId]) state.reportData[viewId] = currentData;
  updateData(currentData);
}

/**
 * When dataset columns, column visibility, group bys, or aggregations change,
 * update group bys, aggregations, sort, and filters to ensure we're only operating on columns that exist
 */
function handleValidateColumns(view: CustomerReportView, dataset?: ReportBuilderDataset) {
  // 1. Group bys must exist in the original dataset
  const datasetColumns = new Set(view.columnOrder);
  if (view.groupBys) {
    const filteredGroupBys = view.groupBys.filter(({ column }) => datasetColumns.has(column.name));
    if (filteredGroupBys.length !== view.groupBys.length) view.groupBys = filteredGroupBys;
  }

  // 1.a. Aggregations must exist in the original dataset, custom must exist in the dataset customAggregations
  if (view.aggregations) {
    const filteredAggs = view.aggregations.filter(({ column, agg }) =>
      agg.id === Aggregation.FORMULA
        ? dataset?.customAggregations?.find((customAgg) => customAgg.column.name === column.name)
        : datasetColumns.has(column.name),
    );
    if (filteredAggs.length !== view.aggregations.length) view.aggregations = filteredAggs;
  }

  // 2. Sort and filters can exist in the dataset, group bys, or aggregations
  // This should be after group bys and aggregations are filtered
  const groupByNames = (view.groupBys ?? []).concat(view.columnGroupBys ?? []).map(getGroupByName);
  const aggNames = (view.aggregations ?? []).map(getAggColName);
  const allColumns = view.columnOrder.concat(groupByNames).concat(aggNames);
  const filterableSet = new Set(allColumns);

  // No sorting on pivot tables or charts
  if (
    (view.visualization && view.visualization !== OPERATION_TYPES.VISUALIZE_TABLE) ||
    (view.aggregations?.length && view.groupBys?.length && view.columnGroupBys?.length)
  ) {
    view.sort = [];
  } else if (view.sort?.length) {
    const sortableSet =
      aggNames.length || groupByNames.length
        ? new Set(groupByNames.concat(aggNames))
        : filterableSet;

    const filteredSort = view.sort.filter(({ column }) => sortableSet.has(column.name));
    if (filteredSort.length !== view.sort.length) view.sort = filteredSort;
  }

  const filteredFilters = view.filters.filter(({ filterColumn }) =>
    filterableSet.has(filterColumn.name),
  );
  if (filteredFilters.length !== view.filters.length) view.filters = filteredFilters;
}

function clearBarFunnelSortedStages(view: CustomerReportView) {
  if (
    view.visualization !== OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2 ||
    !view.barFunnelChart?.sortedStages
  )
    return;

  view.barFunnelChart.sortedStages = undefined;
}

function clearRemovedViewGroupBys(state: ReportEditingState) {
  if (state.currentView && state.removedViewConfigs[state.currentView])
    state.removedViewConfigs[state.currentView].groupBys = undefined;
}
function clearRemovedViewColumnGroupBys(state: ReportEditingState) {
  if (state.currentView && state.removedViewConfigs[state.currentView])
    state.removedViewConfigs[state.currentView].columnGroupBys = undefined;
}

function clearRemovedViewAggregations(state: ReportEditingState) {
  if (state.currentView && state.removedViewConfigs[state.currentView])
    state.removedViewConfigs[state.currentView].aggregations = undefined;
}

function clearRemovedScatterPlotGroupings(state: ReportEditingState) {
  if (state.currentView && state.removedViewConfigs[state.currentView])
    state.removedViewConfigs[state.currentView].scatterPlotGrouping = undefined;
}

/**
 * @param state
 * @param jobId
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 * @param rows
 */
function handleReportDataFulfilled(
  state: ReportEditingState,
  jobId: string,
  dataId: string,
  rows: DatasetRow[],
  unsupportedOperations: DatabaseUnsupportedOperations[],
  source_type: string | null,
) {
  updateReportData(state, dataId, (data) => {
    if (data.loadingRequestId !== jobId) return;

    data.unsupportedOperations = unsupportedOperations;
    data.rows = rows;
    data.isLoading = false;
  });
  state.reportSourceType = source_type ?? null;
}

/**
 * @param state
 * @param jobId
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 * @param page
 */
function handleReportDataPending(
  state: ReportEditingState,
  jobId: string,
  dataId: string,
  page?: number,
) {
  updateReportData(state, dataId, (data) => {
    data.isLoading = true;
    data.loadingRequestId = jobId;
    data.page = page ?? 1;
    if (data.error) {
      data.rows = undefined;
      data.error = undefined;
    }
    if (page === undefined) data.rowCount = RD.Loading();
  });
}

/**
 * @param state
 * @param jobId
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 * @param error
 */
function handleReportDataError(
  state: ReportEditingState,
  jobId: string,
  dataId: string,
  error: string,
) {
  updateReportData(state, dataId, (data) => {
    if (data.loadingRequestId !== jobId) return;

    data.error = error;
    data.isLoading = false;
  });
}

/**
 * @param state
 * @param jobId - RequestId or Job Queue - Job ID
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 */
function handleRowCountPending(state: ReportEditingState, jobId: string, dataId: string) {
  updateReportData(state, dataId, (data) => {
    data.rowCount = RD.Loading();
    data.rowCountRequestId = jobId;
  });
}

/**
 * @param state
 * @param jobId - RequestId or Job Queue - Job ID
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 * @param error
 */
function handleRowCountRejected(
  state: ReportEditingState,
  jobId: string,
  dataId: string,
  error: string,
) {
  updateReportData(state, dataId, (data) => {
    if (data.rowCountRequestId !== jobId) return;
    data.rowCount = RD.Error(error);
  });
}

/**
 * @param state
 * @param jobId - RequestId or Job Queue - Job ID
 * @param dataId - For views, dataId=viewId. For others, see reportBuilderContent/reducers/utils for getting ID
 * @param rowCount
 */
function handleRowCountFulfilled(
  state: ReportEditingState,
  jobId: string,
  dataId: string,
  rowCount: number,
) {
  updateReportData(state, dataId, (data) => {
    if (data.rowCountRequestId !== jobId) return;
    data.rowCount = RD.Success(rowCount);
  });
}

function handleAddGroupBy(
  state: ReportEditingState,
  payload: {
    groupBy: CustomerReportGroupBy;
    isColumnGroupBy: boolean;
  },
) {
  updateCurrentViewHelper(state, (view) => {
    if (payload.isColumnGroupBy) {
      if (!view.columnGroupBys) view.columnGroupBys = [];
      view.columnGroupBys.push(payload.groupBy);
      clearRemovedViewColumnGroupBys(state);
    } else {
      if (!view.groupBys) view.groupBys = [];
      view.groupBys.push(payload.groupBy);
      clearRemovedViewGroupBys(state);
    }
  });
}

function handleAddScatterPlotGrouping(
  state: ReportEditingState,
  payload: {
    scatterPlotGrouping: CustomerReportGroupBy;
  },
) {
  updateCurrentViewHelper(state, (view) => {
    (view as customerActions.ScatterPlotView).scatterPlotGrouping = payload.scatterPlotGrouping;
  });
}

const findGroupByIndex = (groupBys: CustomerReportGroupBy[], groupById: string) =>
  groupBys.findIndex((groupBy) => getGroupByUniqueId(groupBy) === groupById);

const handleDeleteGroupBy = (
  state: ReportEditingState,
  payload: { id: string; isColumnGroupBy: boolean },
) =>
  updateCurrentViewHelper(state, (view) => {
    const groupBys = payload.isColumnGroupBy ? view.columnGroupBys : view.groupBys;
    if (!groupBys) return;

    const index = findGroupByIndex(groupBys, payload.id);
    if (index === -1) return;

    groupBys.splice(index, 1);

    // If we remove data that is required to define bar funnel stage sorts, we need to clear
    // sorting config so that the old sort does not affect the x axis formatting
    if (index === 0) clearBarFunnelSortedStages(view);

    payload.isColumnGroupBy
      ? clearRemovedViewColumnGroupBys(state)
      : clearRemovedViewGroupBys(state);
  });

const handleDeleteScatterPlotGrouping = (state: ReportEditingState) =>
  updateCurrentViewHelper(state, (view) => {
    (view as customerActions.ScatterPlotView).scatterPlotGrouping = undefined;
  });

function handleSwapColumnGroupBys(state: ReportEditingState, oldCol: string, newCol: string) {
  updateCurrentViewHelper(state, (view) => {
    if (!view.columnGroupBys) view.columnGroupBys = [];
    shiftIdsInList(view.columnGroupBys, oldCol, newCol, getGroupByUniqueId);
  });
}

function handleSwapRowGroupBys(state: ReportEditingState, oldCol: string, newCol: string) {
  updateCurrentViewHelper(state, (view) => {
    if (!view.groupBys) view.groupBys = [];
    shiftIdsInList(view.groupBys, oldCol, newCol, getGroupByUniqueId);
  });
  clearRemovedViewGroupBys(state);
}

function handleAddAgg(state: ReportEditingState, payload: CustomerReportAgg) {
  updateCurrentViewHelper(state, (view) => {
    if (!view.aggregations) view.aggregations = [];
    view.aggregations.push(payload);
  });
  clearRemovedViewAggregations(state);
}

function handleDeleteAgg(state: ReportEditingState, aggId: string) {
  updateCurrentViewHelper(state, (view) => {
    view.aggregations = view.aggregations?.filter((agg) => getAggUniqueId(agg) !== aggId);
  });
  clearRemovedViewAggregations(state);
}

function handleSwapAggs(state: ReportEditingState, oldCol: string, newCol: string) {
  updateCurrentViewHelper(state, (view) => {
    if (!view.aggregations) view.aggregations = [];
    shiftIdsInList(view.aggregations, oldCol, newCol, getAggUniqueId);
  });
  clearRemovedViewAggregations(state);
}

function handleSwapColumns(
  state: ReportEditingState,
  payload: { oldCol: string | number; newCol?: string | number },
) {
  updateCurrentViewHelper(state, (view) => {
    if (!payload.newCol) return;
    shiftIdsInList(view.columnOrder, payload.oldCol, payload.newCol, (id) => id);
  });
}

function updateDrilldownConfig(
  state: ReportEditingState,
  updateConfig: (config: DrilldownConfig) => DrilldownConfig,
) {
  if (!state.currentView) return;
  const config: DrilldownConfig = state.drilldownConfigs[state.currentView] || {};
  state.drilldownConfigs[state.currentView] = updateConfig(config);
}

const reportEditingSlice = createSlice({
  name: 'reportEditing',
  initialState,
  reducers: {
    clearSelectedReport: () => {
      return { ...initialState };
    },
    openCustomerReport: (
      state,
      { payload }: PayloadAction<CustomerReport & { viewId?: string }>,
    ) => {
      state.selectedReport = { type: ReportType.CUSTOMER_REPORT, id: payload.id };
      state.currentConfig = payload.config;
      state.currentView = (payload.viewId || payload.config.views?.[0]?.id) ?? null;
      state.removedViewConfigs = {};

      navUtils.goToReportView(state.selectedReport, state.currentView);
      if (!payload.config.dataInfo) state.openModal = { type: ReportModalType.DATA_SELECTION };
    },
    openBuiltInForEdit: (state, { payload }: PayloadAction<BuiltInReportConfig>) => {
      state.selectedReport = { type: ReportType.EDIT_BUILT_IN, id: payload.id };
      state.currentConfig = payload.config;
      state.currentView = payload.config.views?.[0]?.id ?? null;
      state.removedViewConfigs = {};
    },
    openBuiltIn: (state, { payload }: PayloadAction<BuiltInReportConfig>) => {
      state.selectedReport = { type: ReportType.BUILT_IN, id: payload.id };
      state.currentConfig = payload.config;
      state.currentView = payload.config.views?.[0]?.id ?? null;
      state.removedViewConfigs = {};
    },
    closeReportModal: (state) => {
      state.openModal = null;
      if (!isLoading(state.exportUrl)) state.exportUrl = RD.Idle();
      if (!isLoading(state.scheduledExport)) {
        state.scheduledExport = RD.Idle();
        state.exportEmailId = '';
        state.isExportDownloaded = false;
      }
      Object.keys(state.reportData).forEach((id) => {
        if (id.startsWith(DATASET_DATA_PREFIX)) delete state.reportData[id];
      });
    },
    openDataModal: (state, { payload }: PayloadAction<string | null | undefined>) => {
      let datasetId: string | undefined = undefined;
      // Null means reset datasetId, undefined means open with previous datasetId
      if (payload === undefined) datasetId = state.currentConfig?.dataInfo?.datasetId;
      // And if datasetId is passed then set that
      else if (payload) datasetId = payload;

      state.openModal = { type: ReportModalType.DATA_SELECTION, datasetId };
    },
    toggleDataPanelOpen: (state) => {
      state.isDataPanelOpen = !state.isDataPanelOpen;
    },
    updateCurrentView: (state, { payload }: PayloadAction<Partial<CustomerReportView>>) => {
      updateCurrentViewHelper(state, (view) => {
        Object.assign(view, payload);
      });
    },
    swapSelectedColumns: (
      state,
      { payload }: PayloadAction<{ oldCol: string | number; newCol?: string | number }>,
    ) => {
      handleSwapColumns(state, payload);
    },
    setVisualization: (
      state,
      { payload: visualization }: PayloadAction<CustomerReportVisualization>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        state.removedViewConfigs[view.id] = {};

        if (view.groupBys) {
          state.removedViewConfigs[view.id].groupBys = [...view.groupBys];
          const groupByLimit = getGroupByLimit(visualization);
          view.groupBys = view.groupBys?.slice(0, groupByLimit);
        }

        if (view.aggregations) {
          state.removedViewConfigs[view.id].aggregations = [...view.aggregations];
          const aggLimit = getAggLimit(visualization, view.groupBys);
          view.aggregations = view.aggregations?.slice(0, aggLimit);
        }

        if (view.columnGroupBys) {
          state.removedViewConfigs[view.id].columnGroupBys = [...view.columnGroupBys];
          const colGroupByLimit = getColumnGroupByLimit(visualization);
          view.columnGroupBys = view.columnGroupBys?.slice(0, colGroupByLimit);
        }
        if (visualization !== OPERATION_TYPES.VISUALIZE_TABLE) view.sort = [];

        view.visualization = visualization;

        // Edge case: has sorted stages, switching to other chart, removed group by, switch back to bar funnel
        // Now: stages will have never been cleared, so clearing here to be defensive against edge cases
        clearBarFunnelSortedStages(view);

        // Validate Scatter Plot has valid configs
        if (view.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
          let xAxis = view.groupBys?.[0];
          let yAxis = view.columnGroupBys?.[0];

          if (!xAxis && !yAxis) return;

          // Validate types x axis grouping type
          if (xAxis && !SCATTER_X_AXIS_TYPES.includes(xAxis.column.type)) {
            view.groupBys = [];
            xAxis = undefined;
          }

          // Validate types y axis grouping type
          if (yAxis && !SCATTER_Y_AXIS_TYPES.includes(yAxis.column.type)) {
            view.columnGroupBys = [];
            yAxis = undefined;
          }

          if (!view.scatterPlotGrouping) return;

          // Validate grouping column type and uniqueness
          const isGroupingColumnRemoved = xAxis?.column.type === STRING;
          const hasDuplicateAxisGroupingName =
            xAxis?.column.name === view.scatterPlotGrouping.column.name ||
            yAxis?.column.name === view.scatterPlotGrouping.column.name;

          if (isGroupingColumnRemoved || hasDuplicateAxisGroupingName) {
            state.removedViewConfigs[view.id].scatterPlotGrouping = view.scatterPlotGrouping;
            view.scatterPlotGrouping = undefined;
          }
        }
      });
    },
    setBarChartOptions: (state, { payload }: PayloadAction<Partial<BarChartOptions>>) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2) return;
        if (!view.barChart) view.barChart = {};
        view.barChart = { ...view.barChart, ...payload };
      });
    },
    setHeatMapOptions: (
      state,
      { payload }: PayloadAction<Partial<customerActions.HeatMapOptions>>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2) return;
        if (!view.heatMap) view.heatMap = {};
        view.heatMap = { ...view.heatMap, ...payload };
      });
    },
    setAreaChartOptions: (
      state,
      { payload }: PayloadAction<Partial<customerActions.AreaChartOptions>>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_AREA_CHART_V2) return;
        if (!view.areaChart) view.areaChart = {};
        view.areaChart = { ...view.areaChart, ...payload };
      });
    },
    setBarFunnelChartOptions: (
      state,
      { payload }: PayloadAction<Partial<customerActions.BarFunnelChartOptions>>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2) return;
        if (!view.barFunnelChart) view.barFunnelChart = {};
        view.barFunnelChart = { ...view.barFunnelChart, ...payload };
      });
    },
    setScatterPlotOptions: (
      state,
      { payload }: PayloadAction<Partial<customerActions.ScatterPlotOptions>>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) return;
        if (!view.scatterPlot) view.scatterPlot = {};
        view.scatterPlot = { ...view.scatterPlot, ...payload };
      });
    },
    setSortableChartOptions: (
      state,
      { payload }: PayloadAction<Partial<customerActions.SortableChartOptions>>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (view.visualization !== OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2) return;
        if (!view.sortOptions) view.sortOptions = {};
        view.sortOptions = { ...view.sortOptions, ...payload };
      });
    },
    setColumnTotalAgg: (
      state,
      { payload }: PayloadAction<{ path: string; agg: TotalAccumulatorKey }>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        if (!view.totals) view.totals = {};
        view.totals[payload.path] = payload.agg;
      });
    },
    showColumn: (state, { payload: col }: PayloadAction<string>) => {
      updateCurrentViewHelper(state, (view) => {
        view.hiddenColumns = view.hiddenColumns.filter((c) => c !== col);
      });
    },
    hideColumn: (state, { payload: col }: PayloadAction<string>) => {
      updateCurrentViewHelper(state, (view) => {
        view.hiddenColumns.push(col);
      });
    },
    saveDataInfo: (state, { payload }: PayloadAction<CustomerReportDataInfo>) => {
      if (!state.currentConfig) return;

      const newDataset = state.currentConfig.dataInfo?.datasetId !== payload.datasetId;

      state.reportData = {};
      state.currentConfig.views?.forEach((view) => {
        if (newDataset) {
          view.hiddenColumns = [];
          view.columnOrder = payload.columns;
          view.filters = [];
        } else {
          const newOrder = view.columnOrder.filter((col) => payload.columns.includes(col));
          payload.columns.forEach((newCol) => {
            if (!newOrder.includes(newCol)) newOrder.push(newCol);
          });
          view.columnOrder = newOrder;
        }
      });

      state.currentConfig.dataInfo = payload;
      state.openModal = null;

      createCurrentViewIfNone(state);
    },
    setDrilldownPage: (state, { payload: page }: customerActions.SetViewPage) => {
      updateDrilldownConfig(state, (config) => {
        config.page = page;
        return config;
      });
    },
    updateDrilldownFilters: (
      state,
      { payload: filters }: PayloadAction<CustomerReportFilter[]>,
    ) => {
      updateDrilldownConfig(state, (config) => {
        // If the same filter is selected twice, close the drilldown
        // We don't need to compare the entire object for equality
        const paths = ['filterColumn.name', 'filterValue', 'filterOperation.id'];
        if (
          isEqual(
            config.filters?.map((filter) => pick(filter, paths)),
            filters.map((filter) => pick(filter, paths)),
          )
        ) {
          state.isDrilldownPanelOpen = false;
          return {};
        } else {
          state.isDrilldownPanelOpen = true;
          config.filters = filters;
          return config;
        }
      });
    },
    updateDrilldownSort: (state, { payload: sort }: PayloadAction<SortInfo[]>) => {
      updateDrilldownConfig(state, (config) => {
        config.sort = sort;
        return config;
      });
    },
    resetDrilldownConfig: (state) => {
      if (!state.currentView) return;
      state.isDrilldownPanelOpen = false;
      updateDrilldownConfig(state, () => ({}));
    },
    toggleDrilldownPanelOpen: (state) => {
      state.isDrilldownPanelOpen = !state.isDrilldownPanelOpen;
    },
    applyFilter: (state, { payload }: PayloadAction<ApplyFilterInfoPayload>) => {
      if ('filterId' in payload) {
        updateCurrentViewHelper(state, (view) => {
          view.filters = view.filters.map((filter) => {
            if (filter.id !== payload.filterId) return filter;
            return {
              ...filter,
              filterOperation: { id: payload.filterOperator },
              filterValue: payload.value,
            };
          });
        });
      } else {
        createFilterHelper(state, payload);
      }
      state.openFilterId = null;
    },
    createFilter: (state, { payload }: PayloadAction<CreateFilterPayload>) => {
      createFilterHelper(state, payload);
    },
    setOpenFilter: (state, { payload }: PayloadAction<string | number | null>) => {
      state.openFilterId = payload;
    },
    deleteFilter: (state, { payload }: PayloadAction<number>) => {
      updateCurrentViewHelper(state, (view) => {
        view.filters = view.filters.filter((filter) => filter.id !== payload);
      });
      state.openFilterId = null;
    },
    handleTableColumnOrderChange: (state, { payload }: PayloadAction<string[]>) => {
      updateCurrentViewHelper(state, (view) => {
        const newOrder = payload;
        view.columnOrder.forEach((col, idx) => {
          if (!newOrder.includes(col)) newOrder.splice(idx, 0, col);
        });
        view.columnOrder = newOrder;
      });
    },
    reorderColumns: (
      state,
      {
        payload: { activeData, overData, activeColumn, view },
      }: PayloadAction<{
        activeData: DataPanelData;
        overData: DataPanelData;
        activeColumn?: BaseCol;
        view: CustomerReportView;
      }>,
    ) => {
      const activeSection = activeData.section;
      const overSection = overData.section;

      if (activeSection === overSection) {
        if (activeSection === COL_LIST_SECTION_ID) {
          const activeName = activeData.column?.name;
          const overName = overData.column?.name;
          if (activeName == null || overName == null || activeName === overName) return;
          handleSwapColumns(state, { oldCol: activeName, newCol: overName });
        } else if (activeSection === ROWS_SECTION_ID) {
          handleSwapRowGroupBys(state, activeData.id, overData.id);
        } else if (activeSection === COLS_SECTION_ID) {
          handleSwapColumnGroupBys(state, activeData.id, overData.id);
        } else if (activeSection === AGGS_SECTION_ID) {
          handleSwapAggs(state, activeData.id, overData.id);
        }
        return;
      }

      const col = activeColumn;
      if (!col) return;
      if (overSection === ROWS_SECTION_ID) {
        const isGroupByDisabled = getGroupByDisabled(
          view.visualization,
          view.aggregations,
          view.groupBys,
        );
        // Scatter plot requires column type validation that is dependent on the grouping column
        const isScatterPlotValidColType =
          view.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2
            ? ((view as customerActions.ScatterPlotView).scatterPlotGrouping
                ? SCATTER_Y_AXIS_TYPES
                : SCATTER_X_AXIS_TYPES
              ).includes(col.type)
            : true;
        if (isGroupByDisabled || !isScatterPlotValidColType) return;

        const groupBy = getNewGroupBy(col, view, false, state.reportSourceType);
        if (!groupBy) return;

        // If moving from cols to rows, copy over the bucket if it exists
        if (activeSection === COLS_SECTION_ID) {
          const colGroupBys = getCurrentViewBase(state)?.columnGroupBys || [];
          const groupByIndex = findGroupByIndex(colGroupBys, activeData.id);
          if (groupByIndex >= 0) groupBy.bucket = colGroupBys[groupByIndex].bucket;
        }

        handleAddGroupBy(state, { groupBy, isColumnGroupBy: false });
      } else if (overSection === COLS_SECTION_ID) {
        const isColGroupBysDisabled = getColumnGroupByDisabled(
          view.visualization,
          view.aggregations,
          view.groupBys,
          view.columnGroupBys,
          activeSection,
        );
        const isScatterPlotValidColType =
          view.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2
            ? SCATTER_Y_AXIS_TYPES.includes(col.type)
            : true;

        if (isColGroupBysDisabled || !isScatterPlotValidColType) return;

        const groupBy = getNewGroupBy(col, view, true, state.reportSourceType);
        if (!groupBy) return;

        // If moving from rows to cols, copy over the bucket if it exists
        if (activeSection === ROWS_SECTION_ID) {
          const groupBys = getCurrentViewBase(state)?.groupBys || [];
          const groupByIndex = findGroupByIndex(groupBys, activeData.id);
          if (groupByIndex >= 0) groupBy.bucket = groupBys[groupByIndex].bucket;
        }

        handleAddGroupBy(state, { groupBy, isColumnGroupBy: true });
      } else if (overSection === AGGS_SECTION_ID) {
        const isAggDisabled = getAggDisabled(view.visualization, view.aggregations, view.groupBys);
        if (isAggDisabled) return;

        const agg = getNewAgg(col, view.aggregations);
        if (!agg) return;

        handleAddAgg(state, agg);
      } else if (overSection === SCATTER_PLOT_GROUPING_SECTION_ID) {
        const isScatterPlotGroupingDisabled = getScatterPlotGroupingDisabled(
          view.visualization,
          view.groupBys,
          (view as customerActions.ScatterPlotView).scatterPlotGrouping,
        );
        if (isScatterPlotGroupingDisabled) return;

        handleAddScatterPlotGrouping(state, { scatterPlotGrouping: { column: col } });
      }

      if (activeSection === ROWS_SECTION_ID) {
        handleDeleteGroupBy(state, { id: activeData.id, isColumnGroupBy: false });
      } else if (activeSection === COLS_SECTION_ID) {
        handleDeleteGroupBy(state, { id: activeData.id, isColumnGroupBy: true });
      } else if (activeSection === AGGS_SECTION_ID) {
        handleDeleteAgg(state, activeData.id);
      } else if (activeSection === SCATTER_PLOT_GROUPING_SECTION_ID) {
        handleDeleteScatterPlotGrouping(state);
      }
    },
    addGroupBy: (state, { payload }: customerActions.AddGroupByPayload) => {
      handleAddGroupBy(state, payload);
    },
    addScatterPlotGrouping: (state, { payload }: customerActions.AddScatterPlotGroupingPayload) => {
      handleAddScatterPlotGrouping(state, payload);
    },
    updateGroupBy: (state, { payload }: customerActions.UpdateGroupByPayload) => {
      const update = (groupBys?: CustomerReportGroupBy[]) =>
        groupBys?.map((groupBy) =>
          getGroupByUniqueId(groupBy) === payload.id ? payload.groupBy : groupBy,
        );

      updateCurrentViewHelper(state, (view) => {
        if (payload.isColumnGroupBy) {
          view.columnGroupBys = update(view.columnGroupBys);
          clearRemovedViewColumnGroupBys(state);
        } else {
          view.groupBys = update(view.groupBys);
          clearRemovedViewGroupBys(state);
        }
      });
    },
    deleteGroupBy: (state, { payload }: customerActions.DeleteGroupByPayload) => {
      handleDeleteGroupBy(state, payload);
    },
    deleteScatterPlotGrouping: (state) => {
      handleDeleteScatterPlotGrouping(state);
    },
    addAgg: (state, { payload }: PayloadAction<CustomerReportAgg>) => {
      handleAddAgg(state, payload);
    },
    updateAgg: (state, { payload }: PayloadAction<{ id: string; agg: CustomerReportAgg }>) => {
      updateCurrentViewHelper(state, (view) => {
        view.aggregations = view.aggregations?.map((agg) =>
          getAggUniqueId(agg) === payload.id ? payload.agg : agg,
        );
      });
      clearRemovedViewAggregations(state);
    },
    deleteAgg: (state, { payload: aggId }: PayloadAction<string>) => {
      handleDeleteAgg(state, aggId);
    },
    updateSort: (state, { payload: sortInfo }: PayloadAction<SortInfo[]>) => {
      updateCurrentViewHelper(state, (view) => (view.sort = sortInfo));
    },
    openModalType: (state, { payload }: PayloadAction<ReportModal>) => {
      state.openModal = payload;
    },
    /**
     * If a view is provided, duplicates it into a new view. If not, creates a blank view.
     */
    createView: (state, { payload: view }: PayloadAction<CustomerReportView | undefined>) => {
      const currentConfig = state.currentConfig;
      if (!currentConfig) return;

      const viewData = view ? { ...view, id: uuidv4() } : createBlankView(state);
      state.currentView = viewData.id;
      if (!currentConfig.views) currentConfig.views = [viewData];
      else currentConfig.views.push(viewData);

      navUtils.goToReportView(state.selectedReport, state.currentView);
    },
    deleteView: (state, { payload: viewId }: PayloadAction<string>) => {
      const views = state.currentConfig?.views;
      if (!views) return;

      const index = views.findIndex((view) => view.id === viewId);
      if (index < 0) return;
      views.splice(index, 1);

      if (state.currentView === viewId) {
        const nextViewId = views[Math.max(index - 1, 0)]?.id || null;
        state.currentView = nextViewId;

        navUtils.goToReportView(state.selectedReport, state.currentView);
      }
    },
    updateViewName: (state, { payload }: PayloadAction<UpdateCustomerReportViewName>) => {
      const views = state.currentConfig?.views;
      if (!views) return;

      const index = views.findIndex((v) => v.id === payload.viewId);
      if (index < 0) return;

      views[index].name = payload.name;
    },
    setCurrentView: (state, { payload: viewId }: PayloadAction<string>) => {
      state.currentView = viewId;

      navUtils.goToReportView(state.selectedReport, state.currentView);
    },
    swapViews: (state, { payload }: PayloadAction<{ oldId: string; newId: string }>) => {
      const views = state.currentConfig?.views;
      if (!views) return;

      const oldIndex = views.findIndex((view) => view.id === payload.oldId);
      const newIndex = views.findIndex((view) => view.id === payload.newId);
      if (oldIndex < 0 || newIndex < 0) return;

      shiftItemsInList(views, oldIndex, newIndex);
    },
    toggleExportDownloaded: (state) => {
      state.isExportDownloaded = !state.isExportDownloaded;
    },
    setViewPage: (state, { payload: page }: customerActions.SetViewPage) => {
      updateCurrentViewData(state, (data) => {
        data.page = page;
      });
    },
    clearPrevGroupBys: (state) => {
      clearRemovedViewGroupBys(state);
    },
    clearPrevColumnGroupBys: (state) => {
      clearRemovedViewColumnGroupBys(state);
    },
    clearPrevAggregations: (state) => {
      clearRemovedViewAggregations(state);
    },
    clearPrevScatterPlotGroupings: (state) => {
      clearRemovedScatterPlotGroupings(state);
    },
    updateColorCategoryTracker: (
      state,
      { payload: { rows, globalStyleConfig, datasets } }: UpdateColorCategoryTracker,
    ) => {
      const datasetId = state.currentConfig?.dataInfo?.datasetId;
      const dataset = datasetId && datasets?.[datasetId];
      if (dataset) {
        const options = columnConfigsToDisplayOptions(dataset.columnConfigs);
        state.colorCategoryTracker[datasetId] = setTableColorCategoryData({
          globalStyleConfig,
          columnColorTracker: state.colorCategoryTracker[datasetId],
          previewData: rows,
          displayOptions: options,
        });
      }
    },
    updateSortCol: (state, { payload }: PayloadAction<customerActions.UpdateSortCol>) => {
      updateCurrentViewHelper(state, (view) => {
        if (!view.sort) view.sort = [];
        if (payload.toDelete) {
          view.sort = view.sort.filter(({ column }) => column.name !== payload.colName);
        } else if (payload.isNew) {
          if (!payload.newColName) return;
          view.sort.push({ column: { name: payload.newColName }, order: SortOrder.ASC });
        } else {
          const sort = view.sort.find(({ column }) => column.name === payload.colName);
          if (!sort) return;

          if (payload.newColName) sort.column.name = payload.newColName;
          if (payload.newOrder) sort.order = payload.newOrder;
        }
      });
    },
    validateColumns: (
      state,
      { payload: { dataset } }: PayloadAction<{ dataset: ReportBuilderDataset }>,
    ) => {
      updateCurrentViewHelper(state, (view) => {
        handleValidateColumns(view, dataset);
      });
    },
    createDraftCustomerReport: (state, { payload }: PayloadAction<CustomerReportConfig>) => {
      state.selectedReport = { type: ReportType.CUSTOMER_REPORT, id: DRAFT_REPORT_ID };
      state.currentConfig = payload;
      state.reportStatus = RD.Success(null);

      state.currentView = state.currentConfig.views?.[0]?.id ?? null;
      state.emailCadences = RD.Success([]); // New report will never have any email cadences
      state.removedViewConfigs = {};

      navUtils.goToReportView(state.selectedReport, state.currentView);
      state.openModal = { type: ReportModalType.DATA_SELECTION };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCustomerReport.fulfilled, (state, { payload }) => {
        if (
          state.selectedReport?.type === ReportType.CUSTOMER_REPORT &&
          state.selectedReport.id === payload.report.id
        ) {
          state.currentConfig = payload.report.config;
        }
      })
      .addCase(createCustomerReport.fulfilled, (state, { payload }) => {
        // Only update state if we are editing an unsaved report
        if (!state.selectedReport) return;

        state.selectedReport.id = payload.report.id;
        state.currentConfig = payload.report.config;
        state.reportStatus = RD.Success(null);

        state.currentView = state.currentConfig.views?.[0]?.id ?? null;
        state.emailCadences = RD.Success([]);
      })
      .addCase(listReportBuilderEmailCadencesSuccess, (state, { payload }) => {
        state.emailCadences = RD.Success(payload.emails);
      })
      .addCase(createReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        state.emailCadences.data.push(payload.email);
      })
      .addCase(deleteReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        state.emailCadences.data = state.emailCadences.data.filter(
          (email) => email.id !== payload.id,
        );
      })
      .addCase(updateReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        const index = state.emailCadences.data.findIndex((email) => email.id === payload.email.id);
        state.emailCadences.data[index] = payload.email;
      })
      .addCase(exportReport.pending, (state) => {
        state.exportUrl = RD.Loading();
        state.isExportDownloaded = false;
      })
      .addCase(switchCustomer, (state) => {
        state.reportData = {};
      })
      .addCase(sendTestExportRequest, (state, { payload }) => {
        state.exportEmailId = payload.postData.report_builder_email_cadence_id;
        state.scheduledExport = RD.Loading();
      })
      .addCase(sendDraftExportRequest, (state) => {
        state.exportEmailId = DRAFT_EMAIL_ID;
        state.scheduledExport = RD.Loading();
      })
      .addCase(fetchCustomerReportSuccess, (state, { payload }) => {
        state.selectedReport = { type: ReportType.CUSTOMER_REPORT, id: payload.report.id };
        state.currentConfig = payload.report.config;
        state.reportStatus = RD.Success(null);

        state.currentView = state.currentConfig.views?.[0]?.id ?? null;
        state.emailCadences = RD.Success([]); // New report will never have any email cadences

        navUtils.goToReportView(state.selectedReport, state.currentView);

        state.openModal = payload.report.config.dataInfo
          ? null
          : { type: ReportModalType.DATA_SELECTION };
      })
      .addCase(fetchCustomerReportRequest, (state) => {
        state.reportStatus = RD.Loading();
      })
      .addCase(fetchCustomerReportError, (state) => {
        state.reportStatus = RD.Error('Failed to load report');
      })
      .addCase(updateReportDefaultTimezoneSuccess, (state) => {
        state.reportData = {};
      })
      .addMatcher(isAnyOf(sendDraftExportSuccess, sendTestExportSuccess), (state) => {
        state.scheduledExport = RD.Success(null);
      })
      .addMatcher(isAnyOf(sendDraftExportError, sendTestExportError), (state) => {
        state.scheduledExport = RD.Error('');
      })
      // FIDO Reducers
      .addMatcher(
        isAnyOf(fetchFidoReportBuilderView.pending, fetchFidoEmbedReportBuilderView.pending),
        (state, { meta }) => {
          if (meta.arg.primaryRequestId) {
            handleRowCountPending(state, meta.arg.primaryRequestId, meta.arg.id);
          } else {
            handleReportDataPending(
              state,
              meta.requestId,
              meta.arg.id,
              (meta.arg.body.dataRequestParameters.pagingConfiguration?.page ?? 0) + 1,
            );
          }
        },
      )
      .addMatcher(
        isAnyOf(fetchFidoReportBuilderView.fulfilled, fetchFidoEmbedReportBuilderView.fulfilled),
        (state, { payload, meta }) => {
          const { rows } = getEmbeddoResponseFromFidoResponse(payload, meta.arg.timezone);

          if (meta.arg.primaryRequestId) {
            const totalResults = parseInt(Object.values(rows[0])[0].toString());
            isNaN(totalResults)
              ? handleRowCountRejected(
                  state,
                  meta.arg.primaryRequestId,
                  meta.arg.id,
                  'Error loading total results',
                )
              : handleRowCountFulfilled(
                  state,
                  meta.arg.primaryRequestId,
                  meta.arg.id,
                  totalResults,
                );
          } else {
            // TODO: Get Unsupported Operations from Fido response
            handleReportDataFulfilled(state, meta.requestId, meta.arg.id, rows, [], null);
          }
        },
      )
      .addMatcher(
        isAnyOf(fetchFidoReportBuilderView.rejected, fetchFidoEmbedReportBuilderView.rejected),
        (state, { meta, error }) => {
          if (meta.arg.primaryRequestId) {
            handleRowCountRejected(
              state,
              meta.arg.primaryRequestId,
              meta.arg.id,
              error.message ?? 'Something went wrong',
            );
          } else {
            handleReportDataError(
              state,
              meta.requestId,
              meta.arg.id,
              error.message ?? 'Something went wrong',
            );
          }
        },
      )
      .addMatcher(
        isAnyOf(downloadReportBuilderComputationSpreadsheet.fulfilled),
        (state, { payload }) => {
          state.exportUrl = RD.Success(payload.url);
        },
      )
      .addMatcher(isAnyOf(downloadReportBuilderComputationSpreadsheet.rejected), (state) => {
        state.exportUrl = RD.Error('Error exporting report');
      }) // End of FIDO Reducers
      .addMatcher(isAnyOf(exportCustomerReportSuccess), (state, { payload }) => {
        state.exportUrl = RD.Success(payload.export_url);
      })
      .addMatcher(isAnyOf(exportReport.rejected, exportCustomerReportError), (state) => {
        state.exportUrl = RD.Error('Error exporting report');
      })
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataApi.pending, fetchAppReportDataApi.pending),
        (state, { meta }) =>
          handleReportDataPending(state, meta.requestId, meta.arg.id, meta.arg.page),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataApi.fulfilled, fetchAppReportDataApi.fulfilled),
        (state, { payload, meta }) =>
          handleReportDataFulfilled(
            state,
            meta.requestId,
            meta.arg.id,
            payload.rows,
            payload.unsupported_operations,
            payload.source_type,
          ),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataApi.rejected, fetchAppReportDataApi.rejected),
        (state, { meta, error }) =>
          handleReportDataError(
            state,
            meta.requestId,
            meta.arg.id,
            error.message ?? 'Something went wrong',
          ),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.pending, fetchAppReportRowCount.pending),
        (state, { meta }) => handleRowCountPending(state, meta.requestId, meta.arg.id),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.rejected, fetchAppReportRowCount.rejected),
        (state, { meta, error }) =>
          handleRowCountRejected(
            state,
            meta.requestId,
            meta.arg.id,
            error.message ?? 'Something went wrong',
          ),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.fulfilled, fetchAppReportRowCount.fulfilled),
        (state, { payload, meta }) =>
          handleRowCountFulfilled(state, meta.requestId, meta.arg.id, payload.row_count),
      )
      // Job Queue Reducers
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataRequest, fetchDashboardReportDataRequest),
        (state, { payload }) => {
          handleReportDataPending(
            state,
            payload.postData.jobId,
            payload.postData.id,
            payload.postData.page,
          );
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataSuccess, fetchDashboardReportDataSuccess),
        (state, { payload }) => {
          handleReportDataFulfilled(
            state,
            payload.jobArgs.jobId,
            payload.jobArgs.id,
            payload.rows,
            payload.unsupported_operations,
            payload.source_type,
          );
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportDataError, fetchDashboardReportDataError),
        (state, { payload }) => {
          handleReportDataError(
            state,
            payload.job.jobArgs.jobId,
            payload.job.jobArgs.id,
            payload.error,
          );
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedRowCountRequest, fetchDashboardReportRowCountRequest),
        (state, { payload }) =>
          handleRowCountPending(state, payload.postData.jobId, payload.postData.id),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedRowCountSuccess, fetchDashboardReportRowCountSuccess),
        (state, { payload }) =>
          handleRowCountFulfilled(
            state,
            payload.jobArgs.jobId,
            payload.jobArgs.id,
            payload.row_count,
          ),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedRowCountError, fetchDashboardReportRowCountError),
        (state, { payload }) =>
          handleRowCountRejected(
            state,
            payload.job.jobArgs.jobId,
            payload.job.jobArgs.id,
            payload.error,
          ),
      );
    // End of Job Queue Reducers
  },
});

const createFilterHelper = (state: ReportEditingState, payload: CreateFilterPayload) => {
  updateCurrentViewHelper(state, (view) => {
    const incompleteFilter = view.filters.find(
      (filter) =>
        filter.filterColumn.name === payload.column.name && isFilterClauseIncomplete(filter),
    );
    if (incompleteFilter) {
      state.openFilterId = incompleteFilter.id;
      return;
    }

    const filterOperator = getFilterDefaultOperation(payload.column.type, payload.filterOperator);

    const maxId = maxBy(view.filters, 'id')?.id ?? 0;
    const id = maxId + 1;
    view.filters.push({
      id,
      isPostFilter: payload.isPostFilter,
      filterColumn: payload.column,
      filterOperation: { id: filterOperator },
      filterValue: payload.value,
    });
    state.openFilterId = id;
  });
};

export const getCanDeleteView = createSelector(
  (state: ReportEditingState) => state.currentConfig?.views?.length,
  (viewsLength) => (viewsLength || 0) > 1,
);

export const getCurrentView = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.currentConfig?.views,
  (currentView, views) => views?.find((view) => view.id === currentView),
);

export const getCurrentViewData = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.reportData,
  (currentView, reportData) => (currentView && reportData[currentView]) || INITIAL_TABLE_DATA,
);

export const getCurrentViewRemovedAggregations = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.removedViewConfigs,
  (currentView, removedViewConfigs) => currentView && removedViewConfigs[currentView]?.aggregations,
);

export const getCurrentViewRemovedGroupBys = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.removedViewConfigs,
  (currentView, removedViewConfigs) => currentView && removedViewConfigs[currentView]?.groupBys,
);

export const getCurrentViewRemovedColumnGroupBys = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.removedViewConfigs,
  (currentView, removedViewConfigs) =>
    currentView && removedViewConfigs[currentView]?.columnGroupBys,
);

export const getCurrentViewRemovedScatterPlotGroupings = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.removedViewConfigs,
  (currentView, removedViewConfigs) =>
    currentView && removedViewConfigs[currentView]?.scatterPlotGrouping,
);

export const getCurrentColorTracker = createSelector(
  (state: ReportEditingState) => state.colorCategoryTracker,
  (state: ReportEditingState) => state.currentConfig?.dataInfo?.datasetId,
  (colorCategoryTracker, datasetId) => (datasetId ? colorCategoryTracker[datasetId] : undefined),
);

export const getDrilldownConfig = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.currentConfig?.views,
  (state: ReportEditingState) => state.drilldownConfigs,
  (currentView, views, drilldownConfigs) => {
    if (!currentView) return;

    const drilldownConfig = drilldownConfigs[currentView];
    if (!drilldownConfig) return;

    const view = views?.find((view) => view.id === currentView);
    const viewFilters = (view?.filters || [])?.filter(
      (filter) => !filter.isPostFilter && !isFilterClauseIncomplete(filter),
    );

    return {
      ...drilldownConfig,
      filters: [...viewFilters, ...(drilldownConfig.filters || [])],
    };
  },
);

export const getDrilldownData = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.reportData,
  (currentView, reportData) =>
    (currentView && reportData[getDrilldownDataId(currentView)]) || INITIAL_TABLE_DATA,
);

export const getAggDataLoading = createSelector(
  (state: ReportEditingState) => state.reportData,
  (state: ReportEditingState) => state.currentConfig?.dataInfo?.datasetId,
  (reportData, datasetId) => {
    if (!datasetId) return true;
    const aggDataId = getAggDataId(datasetId);
    const aggData = reportData[aggDataId];
    return aggData?.isLoading;
  },
);

export const getAggData = createSelector(
  (state: ReportEditingState) => state.reportData,
  (state: ReportEditingState) => state.currentConfig?.dataInfo?.datasetId,
  (_state: ReportEditingState, columnName: string) => columnName,
  (_state: ReportEditingState, _columnName: string, aggId: Aggregation) => aggId,
  (reportData, datasetId, columnName, aggId) => {
    if (!datasetId) return;
    const aggDataId = getAggDataId(datasetId);
    const aggData = reportData[aggDataId];
    const aggColumnName = getAggColNameBase(columnName, aggId);
    return aggData?.rows?.[0]?.[aggColumnName];
  },
);

export const getTotalDataLoading = createSelector(
  (state: ReportEditingState) => state.reportData,
  (state: ReportEditingState) => state.currentView,
  (reportData, currentView) => {
    if (!currentView) return;
    const totalDataId = getTotalDataId(currentView);
    const data = reportData[totalDataId];
    return data?.isLoading;
  },
);

export const getTotalData = createSelector(
  (state: ReportEditingState) => state.reportData,
  (state: ReportEditingState) => state.currentView,
  (_state: ReportEditingState, columnName: string) => columnName,
  (_state: ReportEditingState, _columnName: string, aggId: Aggregation) => aggId,
  (reportData, currentView, columnName, aggId) => {
    if (!currentView) return;
    const totalDataId = getTotalDataId(currentView);
    const data = reportData[totalDataId];
    const aggColumnName = getAggColNameBase(columnName, aggId);
    return data?.rows?.[0]?.[aggColumnName];
  },
);

export const getTotalAggId = createSelector(
  (state: ReportEditingState) => state.currentConfig?.views,
  (state: ReportEditingState) => state.currentView,
  (_state: ReportEditingState, pivotPath: string) => pivotPath,
  (views, currentView, pivotPath) =>
    views?.find((view) => view.id === currentView)?.totals?.[pivotPath],
);

export const reportEditingReducer = reportEditingSlice.reducer;

export const {
  openCustomerReport,
  openBuiltIn,
  openBuiltInForEdit,
  clearSelectedReport,
  closeReportModal,
  saveDataInfo,
  openDataModal,
  updateCurrentView,
  swapSelectedColumns,
  showColumn,
  hideColumn,
  createFilter,
  deleteFilter,
  setOpenFilter,
  createView,
  updateViewName,
  deleteView,
  setCurrentView,
  toggleDataPanelOpen,
  handleTableColumnOrderChange,
  openModalType,
  toggleExportDownloaded,
  addGroupBy,
  addScatterPlotGrouping,
  updateGroupBy,
  deleteGroupBy,
  deleteScatterPlotGrouping,
  addAgg,
  updateAgg,
  deleteAgg,
  updateSort,
  swapViews,
  setBarChartOptions,
  setHeatMapOptions,
  setAreaChartOptions,
  setBarFunnelChartOptions,
  setScatterPlotOptions,
  setSortableChartOptions,
  setVisualization,
  clearPrevGroupBys,
  clearPrevColumnGroupBys,
  clearPrevScatterPlotGroupings,
  clearPrevAggregations,
  updateColorCategoryTracker,
  setDrilldownPage,
  updateDrilldownFilters,
  updateDrilldownSort,
  resetDrilldownConfig,
  toggleDrilldownPanelOpen,
  reorderColumns,
  setViewPage,
  applyFilter,
  updateSortCol,
  setColumnTotalAgg,
  validateColumns,
  createDraftCustomerReport,
} = reportEditingSlice.actions;
