import { UserPermissions } from 'actions/userActions';
import { doesUserHavePermission, doesUserHaveSomePermission } from 'utils/permissionUtils';

import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from './roleConstants';

export enum SETTINGS_SUB_SECTION {
  PROFILE = 'Profile',
  MEMBERS = 'Members',
  API_TOKENS = 'Data Visibility Groups',
  ANALYTICS = 'Analytics',
  DOMAIN_ALLOWLISTING = 'Domain Allowlisting',
  INTERNATIONALIZATION = 'Internationalization',
  DASHBOARD_ATTRIBUTES = 'Dashboard Attributes',
  CUSTOM_FONTS = 'Custom Fonts',
  ENVIRONMENTS = 'Environment Tags',
  EMAILS = 'Support Emails',
  PORTALS_AND_SHARE_LINKS = 'Portals & Share Links',
  BILLING = 'Billing',
  GROUP_TAGS = 'Group Tags',
}

export const SUB_SECTION_ROUTE_MAP: Record<SETTINGS_SUB_SECTION, string> = {
  [SETTINGS_SUB_SECTION.PROFILE]: 'profile',
  [SETTINGS_SUB_SECTION.MEMBERS]: 'team-members',
  [SETTINGS_SUB_SECTION.API_TOKENS]: 'api-tokens',
  [SETTINGS_SUB_SECTION.ANALYTICS]: 'analytics',
  [SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING]: 'domain-allowlisting',
  [SETTINGS_SUB_SECTION.INTERNATIONALIZATION]: 'i18n',
  [SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES]: 'dashboard-attributes',
  [SETTINGS_SUB_SECTION.CUSTOM_FONTS]: 'custom-fonts',
  [SETTINGS_SUB_SECTION.ENVIRONMENTS]: 'environment-tags',
  [SETTINGS_SUB_SECTION.PORTALS_AND_SHARE_LINKS]: 'portals-and-share-links',
  [SETTINGS_SUB_SECTION.BILLING]: 'billing',
  [SETTINGS_SUB_SECTION.EMAILS]: 'emails',
  [SETTINGS_SUB_SECTION.GROUP_TAGS]: 'group_tags',
};

const ACCOUNT_GROUP: SettingLeftMenuSection = {
  groupName: 'Account',
  subsections: [SETTINGS_SUB_SECTION.PROFILE],
};

const TEAM_GROUP: SettingLeftMenuSection = {
  groupName: 'Team',
  subsections: [SETTINGS_SUB_SECTION.MEMBERS, SETTINGS_SUB_SECTION.BILLING],
};

const DASHBOARD_ADMIN_GROUP: SettingLeftMenuSection = {
  groupName: 'Dashboard Admin',
  subsections: [
    SETTINGS_SUB_SECTION.EMAILS,
    SETTINGS_SUB_SECTION.CUSTOM_FONTS,
    SETTINGS_SUB_SECTION.ENVIRONMENTS,
    SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES,
    SETTINGS_SUB_SECTION.INTERNATIONALIZATION,
    SETTINGS_SUB_SECTION.PORTALS_AND_SHARE_LINKS,
    SETTINGS_SUB_SECTION.GROUP_TAGS,
  ],
};

const DEVELOPER_GROUP: SettingLeftMenuSection = {
  groupName: 'Developer',
  subsections: [
    SETTINGS_SUB_SECTION.API_TOKENS,
    SETTINGS_SUB_SECTION.ANALYTICS,
    SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING,
  ],
};

type SettingLeftMenuSection = {
  groupName: string;
  subsections: SETTINGS_SUB_SECTION[];
};

export const SETTINGS_LEFT_MENU_SECTIONS = [
  ACCOUNT_GROUP,
  TEAM_GROUP,
  DASHBOARD_ADMIN_GROUP,
  DEVELOPER_GROUP,
];

export const SETTINGS_SUB_SECTIONS_PERMISSION_MAP: Record<
  SETTINGS_SUB_SECTION,
  ((userPermissions: UserPermissions) => boolean) | null
> = {
  [SETTINGS_SUB_SECTION.PROFILE]: null,
  [SETTINGS_SUB_SECTION.MEMBERS]: null,
  [SETTINGS_SUB_SECTION.ENVIRONMENTS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.DASHBOARD],
      PERMISSIONED_ACTIONS.PUBLISH,
    ),
  [SETTINGS_SUB_SECTION.API_TOKENS]: (userPermissions) =>
    doesUserHaveSomePermission(userPermissions[PERMISSIONED_ENTITIES.ROLE], [
      PERMISSIONED_ACTIONS.UPDATE,
      PERMISSIONED_ACTIONS.DELETE,
      PERMISSIONED_ACTIONS.CREATE,
    ]),
  [SETTINGS_SUB_SECTION.ANALYTICS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_DEVELOPER_SETTING,
    ),
  [SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_DEVELOPER_SETTING,
    ),
  [SETTINGS_SUB_SECTION.INTERNATIONALIZATION]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_ADMIN_SETTING,
    ),
  [SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.DASHBOARD],
      PERMISSIONED_ACTIONS.PUBLISH,
    ),
  [SETTINGS_SUB_SECTION.CUSTOM_FONTS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_ADMIN_SETTING,
    ),
  [SETTINGS_SUB_SECTION.PORTALS_AND_SHARE_LINKS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_ADMIN_SETTING,
    ),
  [SETTINGS_SUB_SECTION.BILLING]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_BILLING,
    ),
  [SETTINGS_SUB_SECTION.EMAILS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_ADMIN_SETTING,
    ),
  [SETTINGS_SUB_SECTION.GROUP_TAGS]: (userPermissions) =>
    doesUserHavePermission(
      userPermissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_ADMIN_SETTING,
    ),
};
