import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { passwordResetRequest } from 'actions/authAction';
import { updateUserName } from 'actions/userActions';
import { getUserInitials } from 'components/Sidebar/utils';
import { Button, Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorContactSupportToast, showSuccessToast } from 'shared/sharedToasts';

export const SettingsProfileSection: FC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);

  useEffect(() => {
    setFirstName(currentUser.first_name);
  }, [currentUser.first_name]);

  useEffect(() => {
    setLastName(currentUser.last_name);
  }, [currentUser.last_name]);

  const onPasswordResetRequestSubmitted = () => {
    if (!currentUser.email) return;
    dispatch(
      passwordResetRequest(
        { postData: { email: currentUser.email } },
        () => showSuccessToast('Reset password email sent successfully.'),
        () => showErrorContactSupportToast(),
      ),
    );
  };

  return (
    <div>
      <div className={sprinkles({ heading: 'h2' })}>Profile</div>
      <div className={sprinkles({ display: 'flex', gap: 'sp4', marginTop: 'sp2' })}>
        <div className={sprinkles({ marginTop: 'sp2' })}>
          <div
            className={sprinkles({
              backgroundColor: 'blueDark6',
              color: 'white',
              flexItems: 'center',
            })}
            style={{ height: 112, width: 112, fontSize: 52, borderRadius: '12%' }}>
            {getUserInitials(currentUser)}
          </div>
        </div>
        <div className={sprinkles({ flex: 1, flexItems: 'column', gap: 'sp2' })}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', width: 'fill' })}>
            <Input
              fillWidth
              defaultValue={firstName}
              label="First Name"
              onSubmit={(newName) => {
                setFirstName(newName);
                dispatch(
                  updateUserName({
                    postData: {
                      first_name: newName,
                      last_name: currentUser.last_name,
                      id: currentUser.id,
                    },
                  }),
                );
              }}
            />
            <Input
              fillWidth
              defaultValue={lastName}
              label="Last Name"
              onSubmit={(newName) => {
                setLastName(newName);
                dispatch(
                  updateUserName({
                    postData: {
                      first_name: currentUser.first_name,
                      last_name: newName,
                      id: currentUser.id,
                    },
                  }),
                );
              }}
            />
          </div>
          <Input disabled defaultValue={currentUser.email} label="Email" onSubmit={() => null} />
          <Button onClick={onPasswordResetRequestSubmitted} variant="secondary">
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  );
};
