import cx from 'classnames';
import { FC, useState } from 'react';

import { camelCase } from 'utils/standard';

import { IconButton, Label, sprinkles } from './ds';
import * as inputStyles from './ds/Input/index.css';

type Props = {
  // optional class to wrap entire component
  className?: string;
  // label shown above the input in the top left
  label: string;
  // Current password value
  value: string;
  // Function that runs on change events
  onChange: (value: string) => void;
};

export const PasswordInput: FC<Props> = ({ className, label, value, onChange, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const name = camelCase(label);

  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <div className={sprinkles({ position: 'relative' })}>
        <input
          {...props}
          //  fs-exclude is required to protect privacy on full-story
          className={cx('fs-exclude', inputStyles.base, sprinkles({ paddingRight: 'sp3.5' }))}
          id={name}
          onChange={(e) => onChange(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          value={value}
        />
        <IconButton
          className={inputStyles.inputButton}
          name={showPassword ? 'eye-open' : 'eye-closed'}
          onClick={() => setShowPassword(!showPassword)}
          variant="tertiary"
        />
      </div>
    </div>
  );
};
