import { FC, ReactNode, useState } from 'react';

import { sprinkles, IconButton, Switch, InfoIcon } from 'components/ds';

type Props = {
  defaultEnabled: boolean;
  title: string;
  onAdd?: () => void;
  onDisable?: () => void;
  adderText?: string;
  infoText?: string;
  children: ReactNode;
};

export const MultiCardConfig: FC<Props> = ({
  defaultEnabled,
  title,
  adderText,
  onAdd,
  onDisable,
  infoText,
  children,
}) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);

  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Switch
          onChange={(switchOn) => {
            setIsEnabled(switchOn);
            if (!switchOn) onDisable?.();
          }}
          switchOn={isEnabled}
        />
        <div className={sortToggleStyle}>
          {title}
          {infoText ? <InfoIcon text={infoText} /> : null}
        </div>
      </div>
      {isEnabled ? (
        <div className={sprinkles({ paddingLeft: 'sp5' })}>
          {onAdd ? (
            <div className={adderStyle}>
              {adderText}
              <IconButton name="plus" onClick={onAdd} variant="secondary" />
            </div>
          ) : null}
          <div className={sprinkles({ gap: 'sp1.5', flexItems: 'column' })}>{children}</div>
        </div>
      ) : null}
    </div>
  );
};

const adderStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  body: 'b3',
  paddingY: 'sp2',
  borderTop: 1,
  borderTopColor: 'outline',
});

const sortToggleStyle = sprinkles({
  heading: 'h4',
  width: 'fill',
  paddingY: 'sp2',
  flexItems: 'alignCenter',
  gap: 'sp1',
});
