import cx from 'classnames';
import produce from 'immer';
import { Component, ContextType, Suspense, lazy } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { ExportSpreadsheetType, ExportType } from 'actions/exportActions';
import { DemoWatermark } from 'components/DemoWatermark';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import {
  COLOR_CATEGORY_FILTER_SUFFIX,
  UNSUPPORTED_CHART_ERROR,
} from 'constants/dashboardConstants';
import { SELECTABLE_CHARTS, VIZ_OP_WITH_CSV_DOWNLOAD } from 'constants/dataConstants';
import {
  BAR_CHART_TYPES,
  FilterOperationInstructions,
  GROUPED_OPERATION_TYPES,
  HORIZONTAL_OPERATION_TYPES,
  NO_HEADER_OPERATION_TYPES,
  OPERATION_TYPES,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
  VISUALIZE_TABLE_OPERATIONS_SET,
  ColorPalette,
  ColorPaletteV2,
} from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import DataTable from 'pages/dashboardPage/DashboardDatasetView/DataTable';
import { ChartHeader } from 'pages/dashboardPage/DashboardDatasetView/Header/ChartHeader';
import { BarFunnel } from 'pages/dashboardPage/charts/BarFunnel';
import { ScatterPlot } from 'pages/dashboardPage/charts/ScatterPlot';
import SpiderChart from 'pages/dashboardPage/charts/SpiderChart';
import BarChart from 'pages/dashboardPage/charts/barChart';
import { BoxPlot } from 'pages/dashboardPage/charts/boxPlot';
import FunnelChartV2 from 'pages/dashboardPage/charts/funnelChart';
import { HeatMap } from 'pages/dashboardPage/charts/heatMap';
import LineChart from 'pages/dashboardPage/charts/lineChart';
import { NumberTrend } from 'pages/dashboardPage/charts/numberTrend';
import { PieChart } from 'pages/dashboardPage/charts/pieChart';
import { SingleNumberChart } from 'pages/dashboardPage/charts/singleNumberChart';
import { setChartMenu } from 'reducers/dashboardLayoutReducer';
import { embedUpdateTableColWidths } from 'reducers/embedDashboardReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { saveCustomerDashboardStateThunk } from 'reducers/thunks/customerDashboardStateThunks';
import { adHocInstructionUpdatedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import {
  selectKpiThunk,
  setVariableThunk,
} from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import {
  downloadDataPanelScreenshotThunk,
  downloadDataPanelSpreadsheetThunk,
} from 'reducers/thunks/dashboardLayoutThunks/';
import {
  DashboardVariable,
  DashboardVariableMap,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getColorPaletteTracker } from 'utils/colorCategorySyncUtils';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';
import { getTableState } from 'utils/customerDashboardStateUtils';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { isChartExportEnabled } from 'utils/exportUtils';
import { getChartTooltipText } from 'utils/graphUtils';
import { getDatasetName } from 'utils/naming';
import { cloneDeep, uniqueId } from 'utils/standard';
import {
  getSortedUserTransformedSchema,
  getTableChangedSchema,
  sortSchemaByOrderedColumnNames,
} from 'utils/tableSchemaUtils';

import { CalendarHeatmap } from '../charts/CalendarHeatmap';
import { SankeyChart } from '../charts/sankeyChart';
import { CAN_USE_MULTI_Y_AXIS_OPS } from '../charts/utils/multiYAxisUtils';
import {
  getRangeAggregateValues,
  isNumberTrendTextPanelVisualizationType,
} from '../charts/utils/trendUtils';

import { DashboardCollapsibleList } from './CollapsibleList/DashboardCollapsibleList';
import { DashboardChartError } from './DashboardChartError';
import { Props as ColorCategoryProps } from './Header/ColorCategoryDropdown';
import { DataTableHeader } from './Header/DataTableHeader';
import { NoDataChartView } from './NoDataChartView';
import { PanelError } from './PanelError';
import { PivotTable } from './PivotTable/PivotTable';
import { PivotTableV2 } from './PivotTableV2';
import { ReportBuilder } from './ReportBuilder';

const MapboxChart = lazy(
  () =>
    import(
      /* webpackChunkName: "MapboxChartComponent" */ 'pages/dashboardPage/DashboardDatasetView/MapboxChart'
    ),
);

export const HEADER_HEIGHT = 63;

const chartContainerClass = sprinkles({
  parentContainer: 'fill',
  overflow: 'hidden',
  flexItems: 'column',
  flex: 1,
  position: 'relative',
});

export type PassedProps = {
  canDownloadDataPanel: boolean;
  dataPanel: DataPanel;
  displayDemoWatermark?: boolean;
  editableDashboard?: boolean;
  dpEndsOnRightSide?: boolean;
  isInContainer?: boolean;
  isSelected?: boolean;
  isUpdatingPosition?: boolean;
  isViewOnly: boolean;
  pageType: PAGE_TYPE;
  isDrilldownModal?: boolean;
  variables: DashboardVariableMap;
  defaultUserTransformedSchema?: UserTransformedSchema;
  datasets: Record<string, ResourceDataset>;
  // Used for replacing text with dataset data
  datasetNamesToId: Record<string, string>;
  viewMode?: VIEW_MODE;
  drilldownDatasetName?: string;
  editableChartPreview?: boolean;
};

type Props = PropsFromRedux & PassedProps;

/**
 * TODO: We should create a DataTable component that is entirely self contained (its own loading and error logic)
 * to maintain this state
 */
type State = {
  userTransformedSchema: UserTransformedSchema;
  endUserTransformedSchema?: UserTransformedSchema;
};

/**
 * TODO: We should investigate bringing loading state logic out from individual charts so that changes to this logic
 * don't need to be copied to every single chart.
 */
class DashboardDatasetView extends Component<Props, State> {
  static contextType = GlobalStylesContext;
  context!: ContextType<typeof GlobalStylesContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      userTransformedSchema: this.getUserTransformedSchema(props.defaultUserTransformedSchema),
      endUserTransformedSchema: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { dataPanel, dataPanelData } = this.props;

    if (dataPanel.visualize_op.operation_type !== OPERATION_TYPES.VISUALIZE_TABLE) return;
    const instructions = dataPanel.visualize_op.instructions.VISUALIZE_TABLE;
    const prevInstructions = prevProps.dataPanel.visualize_op.instructions.VISUALIZE_TABLE;
    const schemaListChanged = instructions.changeSchemaList !== prevInstructions.changeSchemaList;
    const orderedColumnNamesChanged =
      instructions.orderedColumnNames !== prevInstructions.orderedColumnNames;
    const schemaChanged = dataPanelData?.schema !== prevProps.dataPanelData?.schema;

    if (!schemaChanged && !schemaListChanged && !orderedColumnNamesChanged) return;

    const userTransformedSchema = this.getUserTransformedSchema();

    if (this.state.userTransformedSchema.length !== userTransformedSchema.length) {
      return this.setState({ userTransformedSchema });
    }

    if (
      instructions.changeSchemaList !== prevInstructions.changeSchemaList ||
      instructions.orderedColumnNames !== prevInstructions.orderedColumnNames
    ) {
      this.setState({
        // Reset end user settings so that the updates are visible
        endUserTransformedSchema: undefined,
        userTransformedSchema,
      });
    }
  }

  getUserTransformedSchema = (defaultUserTransformedSchema?: UserTransformedSchema) => {
    if (defaultUserTransformedSchema) return defaultUserTransformedSchema;
    const { isDrilldownModal, dataPanelData, dataPanel, customerDashboardState } = this.props;
    const dataset = isDrilldownModal ? this.getDataset() : undefined;

    const tableState =
      customerDashboardState &&
      // Use customer table state on first load
      !this.state?.userTransformedSchema.length &&
      this.shouldPersistCustomerState()
        ? customerDashboardState.panelStates[dataPanel.id]
        : undefined;

    return getSortedUserTransformedSchema(
      dataPanelData?.schema,
      dataPanel.visualize_op,
      dataset,
      tableState,
    );
  };

  render() {
    const { dataPanel, isDrilldownModal, isInContainer, editableChartPreview } = this.props;

    const operationType = dataPanel.visualize_op.operation_type;
    const isTableOperation = VISUALIZE_TABLE_OPERATIONS_SET.has(operationType);

    const selectedKpiClasses = this.getSelectedKpiClasses();
    const shouldRenderBorder =
      !isDrilldownModal && !selectedKpiClasses?.borderClass && (!isInContainer || isTableOperation);
    const isKpiNumber = this.isKpiNumberOp();

    return (
      <div
        className={cx(
          sprinkles({
            position: 'relative',
            height: 'fill',
            overflow: 'hidden',
            flexItems: 'column',
          }),
          selectedKpiClasses?.containerClass,
          selectedKpiClasses?.borderClass,
          editableChartPreview ? sprinkles({ width: 'fill' }) : undefined,
          embedSprinkles({ color: 'primaryFont', borderRadius: 'container' }),
          {
            [embedSprinkles({ backgroundColor: 'containerFill' })]:
              !isTableOperation && !selectedKpiClasses,
            [embedSprinkles({ padding: 'container' })]:
              !isTableOperation && !isInContainer && !isKpiNumber,
            [embedSprinkles({ padding: 'singleNumber' })]: isKpiNumber,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: shouldRenderBorder,
            [GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow]: !isInContainer,
          },
        )}
        onClick={selectedKpiClasses?.onClick}>
        {this.renderHeader()}
        {this.renderChartOrTable()}
      </div>
    );
  }

  getSelectedKpiClasses = () => {
    const { dataPanel, variables, selectKpiThunk } = this.props;
    const { operation_type, instructions } = dataPanel.visualize_op;

    if (
      !SELECTABLE_CHARTS.has(operation_type) ||
      !instructions.V2_KPI?.actionConfig?.selectionEnabled
    ) {
      return;
    }

    const isSelected = !!variables[dataPanel.provided_id];

    const borderClass = isSelected
      ? embedSprinkles({ border: 'primary', borderColor: 'cardSelected' })
      : undefined;

    const containerClass = cx(
      sprinkles({ cursor: 'pointer' }),
      embedSprinkles({
        backgroundColor: isSelected
          ? { default: 'cardSelected', hover: 'cardSelectedHover' }
          : { default: 'containerFill', hover: 'cardHover' },
      }),
    );

    const onClick = () => selectKpiThunk(dataPanel.provided_id);

    return { borderClass, containerClass, onClick };
  };

  isKpiNumberOp = () => {
    const { operation_type, instructions } = this.props.dataPanel.visualize_op;
    return (
      operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      isNumberTrendTextPanelVisualizationType(operation_type, instructions.V2_KPI)
    );
  };

  shouldShowFullChartLoading = () => {
    const { editableDashboard, dataPanelData } = this.props;

    // On initial load, we unconditionally show the no display state for a chart
    if (!dataPanelData?.rows?.length) return true;

    // On subsequent reloads (because of config changes), we have more conditional behavior.
    // This is because in non-editable dashboards, we want to show stale data while the new
    // data is being fetch. In editable dashboards, we can't show stale data in the interim
    // because the config changes immediately and so the old data often will not be compatible
    // with the new config
    return editableDashboard && this.isLoading();
  };

  renderChartOrTable = () => {
    const {
      updateVisualizeOperation,
      isSelected = false,
      isDrilldownModal,
      dataPanel,
      downloadDataPanelScreenshotThunk,
      variables,
      isViewOnly,
      datasets,
      embedUpdateTableColWidths,
      editableDashboard,
      pageType,
      setChartMenu,
      displayDemoWatermark,
      dataPanelData,
      datasetNamesToId,
      datasetData,
    } = this.props;

    const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

    const backgroundColor = this.context.globalStyleConfig.container.fill;

    const loading = this.isLoading();
    if (dataPanelData?.error) {
      return (
        <DashboardChartError
          dataPanel={this.props.dataPanel}
          error={dataPanelData.error}
          loading={loading}
        />
      );
    }

    const dataset = datasets[getDataPanelDatasetId(dataPanel)];
    if (!dataset)
      return (
        <PanelError
          description="Update dataset to view chart"
          title="The underlying data is no longer available."
        />
      );

    const isNumberTrendAgg =
      operation_type !== OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
      !instructions.V2_KPI_TREND?.hideTrendLines;

    if (
      !loading &&
      dataPanelData?.rows?.length === 0 &&
      operation_type !== OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      isNumberTrendAgg &&
      operation_type !== OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP
    ) {
      return (
        <NoDataChartView
          dataPanel={dataPanel}
          dataPanelData={dataPanelData}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          displayDemoWatermark={displayDemoWatermark ?? false}
          globalStyleConfig={this.context.globalStyleConfig}
          loading={loading}
          processString={this.processString}
          variables={variables}
        />
      );
    }

    const showFullChartLoading = this.shouldShowFullChartLoading();

    const selectedColorColName = variables[dataPanel.provided_id + COLOR_CATEGORY_FILTER_SUFFIX] as
      | string
      | undefined;

    const schema = dataPanelData?.schema ?? [];
    const secondaryData = dataPanelData?.secondaryData ?? [];
    // Have to do this since some charts sort the data
    const rows = cloneDeep(dataPanelData?.rows ?? []);
    const adHocInstructions = dataPanelData?.adHocOperationInstructions ?? {};

    if (BAR_CHART_TYPES.has(operation_type)) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarChart
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            colorTracker={this.getColorPaletteTracker()}
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            filterOperation={dataPanel.filter_op}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            grouped={GROUPED_OPERATION_TYPES.has(operation_type)}
            horizontal={HORIZONTAL_OPERATION_TYPES.has(operation_type)}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            normalize={
              operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2
            }
            operationType={operation_type}
            previewData={rows}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            setVariable={this.setVariable}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <PieChart
            backgroundColor={backgroundColor}
            colorTracker={this.getColorPaletteTracker()}
            dataPanelId={dataPanel.id}
            dataPanelProvidedId={dataPanel.provided_id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            donut={operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            operationType={operation_type}
            previewData={rows}
            schema={schema}
            setChartMenu={setChartMenu}
            setVariable={this.setVariable}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <LineChart
            area={
              operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
            }
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            colorTracker={this.getColorPaletteTracker()}
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            filterOperation={dataPanel.filter_op}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            normalize={operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2}
            operationType={operation_type}
            previewData={rows}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            setVariable={this.setVariable}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarChart
            grouped
            isComboChart
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            colorTracker={this.getColorPaletteTracker()}
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            operationType={operation_type}
            previewData={rows}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <HeatMap
            backgroundColor={backgroundColor}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={rows}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <CalendarHeatmap
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={!!loading}
            previewData={rows}
            processString={this.processString}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <ScatterPlot
            backgroundColor={backgroundColor}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_SCATTER_PLOT}
            loading={showFullChartLoading}
            previewData={rows}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_SPIDER_CHART) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SpiderChart
            backgroundColor={backgroundColor}
            colorTracker={this.getColorPaletteTracker()}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={rows}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_FUNNEL_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          {
            // @ts-ignore - FunnelChartV2 expects a classes prop for some reason
            <FunnelChartV2
              backgroundColor={backgroundColor}
              dataPanelTemplateId={dataPanel.id}
              globalStyleConfig={this.context.globalStyleConfig}
              instructions={instructions.V2_TWO_DIMENSION_CHART}
              // Need to use a key so that the Funnel rerenders on chart reload because
              // a limitation of the funnel library is that it doesn't reload smartly unless the
              // entire component reloads
              key={uniqueId('funnel-chart')}
              loading={showFullChartLoading}
              previewData={rows}
              schema={schema}
              variables={variables}
            />
          }
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarFunnel
            dataPanelId={dataPanel.id}
            generalFormatOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={rows}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SingleNumberChart
            dataPanelTemplateId={dataPanel.id}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            hideChartMenu={this.hideIcons()}
            infoTooltipText={getChartTooltipText(
              operation_type,
              instructions,
              variables,
              datasetNamesToId,
              datasetData,
            )}
            instructions={instructions.V2_KPI}
            loading={showFullChartLoading}
            newDataLoading={loading && !showFullChartLoading} // only show the header loading state if we're not showing the full chart loading state
            operationType={operation_type}
            previewData={rows}
            processString={this.processString}
            schema={schema}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SingleNumberChart
            dataPanelTemplateId={dataPanel.id}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            hideChartMenu={this.hideIcons()}
            infoTooltipText={getChartTooltipText(
              operation_type,
              instructions,
              variables,
              datasetNamesToId,
              datasetData,
            )}
            instructions={instructions.V2_KPI}
            loading={showFullChartLoading}
            // only show the header loading state if we're not showing the full chart loading state
            newDataLoading={loading}
            operationType={operation_type}
            previewData={rows}
            processString={this.processString}
            schema={schema}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BoxPlot
            backgroundColor={backgroundColor}
            dataPanelTemplateId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_BOX_PLOT}
            loading={showFullChartLoading}
            previewData={rows}
            schema={schema}
            secondaryData={secondaryData}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <NumberTrend
            aggValuesLoading={!dataPanelData || !!dataPanelData.outstandingSecondaryDataRequests}
            aggregatedValues={getRangeAggregateValues(secondaryData)}
            dataPanelTemplateId={dataPanel.id}
            editableDashboard={editableDashboard}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            hideIcons={this.hideIcons()}
            infoTooltipText={getChartTooltipText(
              operation_type,
              instructions,
              variables,
              datasetNamesToId,
              datasetData,
            )}
            instructions={instructions.V2_KPI_TREND}
            loading={showFullChartLoading}
            // only show the header loading state if we're not showing the full chart loading state
            newDataLoading={loading && !showFullChartLoading}
            operationType={operation_type}
            previewData={rows}
            processString={this.processString}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP) {
      return (
        <>
          {this.renderDemoWatermark()}
          <Suspense fallback={<EmbedSpinner fillContainer />}>
            <MapboxChart
              data={dataPanelData?.rows}
              dpId={dataPanel.id}
              instructions={instructions}
              loading={loading}
              operationType={OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP}
              schema={schema}
            />
          </Suspense>
        </>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP) {
      return (
        <>
          {this.renderDemoWatermark()}
          <Suspense fallback={<EmbedSpinner fillContainer />}>
            <MapboxChart
              data={dataPanelData?.rows}
              dpId={dataPanel.id}
              instructions={instructions}
              loading={loading}
              operationType={OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP}
              schema={schema}
            />
          </Suspense>
        </>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_DENSITY_MAP) {
      return (
        <>
          {this.renderDemoWatermark()}
          <Suspense fallback={<EmbedSpinner fillContainer />}>
            <MapboxChart
              data={dataPanelData?.rows}
              dpId={dataPanel.id}
              instructions={instructions}
              loading={loading}
              operationType={OPERATION_TYPES.VISUALIZE_DENSITY_MAP}
              schema={schema}
            />
          </Suspense>
        </>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_SANKEY_CHART) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SankeyChart
            backgroundColor={backgroundColor}
            dataPanelId={dataPanel.id}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            panelData={dataPanelData}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER ||
      operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
    ) {
      return (
        <ReportBuilder
          adHocOperationInstructions={adHocInstructions}
          dataPanel={dataPanel}
          dataPanelData={dataPanelData}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={showFullChartLoading}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          onDownloadPanelPdf={(adHocOperationInstructions, schema, reportName) =>
            downloadDataPanelScreenshotThunk(
              dataPanel,
              variables,
              adHocOperationInstructions,
              schema,
              ExportType.PDF,
              undefined,
              reportName,
            )
          }
          onDownloadPanelSpreadsheet={(fileFormat, userTransformedSchema) =>
            this.downloadDataPanelSpreadsheet({ fileFormat, userTransformedSchema })
          }
          schema={schema}
          secondaryData={secondaryData}
          updateDataTableOperation={(newVisualizeOperation) => {
            pageType === PAGE_TYPE.EXPLO_APP
              ? updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE)
              : embedUpdateTableColWidths({
                  dataPanelTemplateId: dataPanel.id,
                  visualizeInstructions: newVisualizeOperation,
                });
          }}
          variables={variables}
          visualizeOperation={dataPanel.visualize_op}
        />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
      const pivotTableInstructions = instructions.VISUALIZE_PIVOT_TABLE;

      const sortedSchema = pivotTableInstructions
        ? sortSchemaByOrderedColumnNames(
            getTableChangedSchema(schema, pivotTableInstructions),
            pivotTableInstructions.orderedColumnNames,
          )
        : schema;

      return (
        <PivotTable
          adHocOperationInstructions={adHocInstructions}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          generalOptions={generalFormatOptions}
          instructions={pivotTableInstructions}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={showFullChartLoading}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          previewData={rows}
          schema={sortedSchema}
          secondaryData={secondaryData}
          totalRowCount={dataPanelData?.totalRowCount}
          unsupportedOperations={dataPanelData?.unsupportedOperations}
          variables={variables}
        />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
      return (
        <PivotTableV2 data={dataPanelData} instructions={instructions.VISUALIZE_PIVOT_TABLE_V2} />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
      const numRowCols = instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns?.length || 0;

      const sortedSchema = schema
        .slice(0, numRowCols)
        .concat(
          sortSchemaByOrderedColumnNames(
            schema.slice(numRowCols),
            instructions.VISUALIZE_COLLAPSIBLE_LIST?.orderedColumnNames,
          ),
        );

      return (
        <DashboardCollapsibleList
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          generalOptions={generalFormatOptions}
          instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
          loading={showFullChartLoading}
          previewData={rows}
          schema={sortedSchema}
          variables={variables}
        />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      const userTransformedSchema = (
        this.state.endUserTransformedSchema ?? this.state.userTransformedSchema
      ).map((s) => ({
        ...s,
        friendly_name: s.friendly_name && this.processString(s.friendly_name),
      }));

      return (
        <DataTable
          colorTracker={this.getColorPaletteTracker(ColorPalette.CUSTOM)}
          dataPanel={dataPanel}
          dataPanelData={dataPanelData}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          drilldownDataset={isDrilldownModal ? (dataset as Dataset) : undefined}
          enableColumnResizing={isSelected || isViewOnly}
          enableNewGrid={this.props.enableNewGrid}
          isDrilldownModal={isDrilldownModal}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={loading}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          processString={this.processString}
          schema={userTransformedSchema}
          secondaryData={secondaryData}
          updateDataTableOperation={(newVisualizeOperation) => {
            pageType === PAGE_TYPE.EXPLO_APP
              ? updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE)
              : embedUpdateTableColWidths({
                  dataPanelTemplateId: dataPanel.id,
                  visualizeInstructions: newVisualizeOperation,
                });
          }}
          userTransformedSchema={userTransformedSchema}
          variables={variables}
        />
      );
    } else {
      return (
        <PanelError
          description={UNSUPPORTED_CHART_ERROR.DESCRIPTION}
          title={UNSUPPORTED_CHART_ERROR.TITLE}
        />
      );
    }
  };

  getColorPaletteTracker = (palette?: ColorPalette | ColorPaletteV2) => {
    const { dataPanel, colorCategoryTracker } = this.props;
    const instructions = dataPanel.visualize_op.instructions;
    return getColorPaletteTracker({
      dataPanelId: dataPanel.id,
      paletteName: palette || instructions.V2_TWO_DIMENSION_CHART?.colorFormat?.selectedPalette,
      colorCategoryTracker,
    });
  };

  canDownloadCSV = (): boolean => {
    const { dataPanel, dataPanelData, canDownloadDataPanel } = this.props;
    return (
      canDownloadDataPanel &&
      VIZ_OP_WITH_CSV_DOWNLOAD.has(dataPanel.visualize_op.operation_type) &&
      !!dataPanelData?.rows?.length
    );
  };

  hideIcons = () =>
    this.props.viewMode === VIEW_MODE.PDF || this.props.viewMode === VIEW_MODE.EMAIL;

  updateEndUserTransformedSchema = (endUserTransformedSchema: UserTransformedSchema) => {
    const { saveCustomerDashboardStateThunk, dataPanel } = this.props;
    this.setState({ endUserTransformedSchema });

    if (!this.shouldPersistCustomerState()) return;

    const tableState = getTableState(
      endUserTransformedSchema,
      this.state.userTransformedSchema,
      dataPanel.visualize_op,
    );

    saveCustomerDashboardStateThunk(dataPanel.id, tableState);
  };

  // Only want to persist for non drilldown tables that allow schema customization
  // Will extend this in the future to allow for more options
  shouldPersistCustomerState = () => {
    const { dataPanel, shouldPersistCustomerState, isDrilldownModal } = this.props;
    if (isDrilldownModal || !shouldPersistCustomerState) return false;

    const { operation_type, instructions } = dataPanel.visualize_op;
    return (
      operation_type === OPERATION_TYPES.VISUALIZE_TABLE &&
      instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled
    );
  };

  getDataset = (): Dataset | undefined => {
    const { datasets, dataPanel } = this.props;
    return datasets[getDataPanelDatasetId(dataPanel)];
  };

  renderHeader = () => {
    const {
      canDownloadDataPanel,
      dataPanel,
      datasets,
      dpEndsOnRightSide,
      variables,
      isDrilldownModal,
      downloadDataPanelScreenshotThunk,
      dataPanelData,
      datasetNamesToId,
      datasetData,
    } = this.props;
    const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

    if (NO_HEADER_OPERATION_TYPES.has(operation_type)) return null;

    const loading = this.isLoading();
    // only show the header loading state if we're not showing the full chart loading state
    const showLoadingState = loading && !this.shouldShowFullChartLoading();

    const replacedVarsTooltipText = getChartTooltipText(
      operation_type,
      instructions,
      variables,
      datasetNamesToId,
      datasetData,
    );

    const error = !!dataPanelData?.error;
    const title = generalFormatOptions?.headerConfig?.title;

    if (operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
      if (generalFormatOptions?.headerConfig?.isHeaderHidden) return;
      return (
        <DataTableHeader
          isFilteringDisabled
          adHocOperationInstructions={dataPanelData?.adHocOperationInstructions ?? {}}
          dataPanelId={dataPanel.id}
          dpEndsOnRightSide={dpEndsOnRightSide}
          error={error}
          exportConfig={canDownloadDataPanel ? generalFormatOptions?.export ?? {} : undefined}
          exportCurrentlyDisabled={loading || error}
          hideFilterAndDownloadButtonsIfNoData={
            dataPanel.visualize_op.generalFormatOptions?.noDataState?.hideFilterAndDownloadButtons
          }
          infoTooltipText={replacedVarsTooltipText}
          linkFormat={generalFormatOptions?.linkFormat}
          loading={showLoadingState}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onDownloadPanelSpreadsheet={(fileFormat, email) =>
            this.downloadDataPanelSpreadsheet({
              fileFormat,
              email,
              userTransformedSchema: this.state.userTransformedSchema,
            })
          }
          schema={dataPanelData?.schema ?? []}
          title={title ? this.processString(title) : ''}
        />
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_TABLE ||
      operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE ||
      operation_type === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    ) {
      if (
        dataPanel.visualize_op.generalFormatOptions?.headerConfig?.isHeaderHidden &&
        !isDrilldownModal
      )
        return;

      const isVisualizeTableOp = operation_type === OPERATION_TYPES.VISUALIZE_TABLE;
      const isCollapsibleListOp = operation_type === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST;

      const schema = dataPanelData?.schema ?? [];

      const adHocOperationInstructions = dataPanelData?.adHocOperationInstructions ?? {};
      const isSchemaCustomizationEnabled = isVisualizeTableOp
        ? instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled || isDrilldownModal
        : false;

      const userTransformedSchema =
        this.state.endUserTransformedSchema ?? this.state.userTransformedSchema;

      return (
        <DataTableHeader
          adHocOperationInstructions={adHocOperationInstructions}
          dataPanelId={dataPanel.id}
          dpEndsOnRightSide={dpEndsOnRightSide}
          drilldownDatasetName={
            isDrilldownModal
              ? getDatasetName(datasets[getDataPanelDatasetId(dataPanel)])
              : undefined
          }
          error={error}
          exportConfig={canDownloadDataPanel ? generalFormatOptions?.export ?? {} : undefined}
          exportCurrentlyDisabled={loading || error}
          hideFilterAndDownloadButtonsIfNoData={
            dataPanel.visualize_op.generalFormatOptions?.noDataState?.hideFilterAndDownloadButtons
          }
          infoTooltipText={replacedVarsTooltipText}
          isDrilldownModal={isDrilldownModal}
          isFilteringDisabled={
            isCollapsibleListOp
              ? true
              : isVisualizeTableOp
              ? instructions.VISUALIZE_TABLE.isFilteringDisabled
              : instructions.VISUALIZE_PIVOT_TABLE?.isFilteringDisabled
          }
          linkFormat={generalFormatOptions?.linkFormat}
          loading={showLoadingState}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onDownloadPanelPdf={(email) =>
            downloadDataPanelScreenshotThunk(
              dataPanel,
              variables,
              adHocOperationInstructions,
              userTransformedSchema,
              ExportType.PDF,
              email,
            )
          }
          onDownloadPanelSpreadsheet={(fileFormat, email) =>
            this.downloadDataPanelSpreadsheet({ fileFormat, email, userTransformedSchema })
          }
          schema={schema}
          title={title ? this.processString(title) : ''}
          updateUserTransformedSchema={
            isSchemaCustomizationEnabled ? this.updateEndUserTransformedSchema : undefined
          }
          userTransformedSchema={userTransformedSchema}
        />
      );
    }

    const exportConfig = generalFormatOptions?.export;

    const shouldRenderColorCategoryDropdown = isChartUsingMultipleColorCategories(
      dataPanel.visualize_op,
    );

    const colorCategoryProps: ColorCategoryProps | undefined = shouldRenderColorCategoryDropdown
      ? {
          instructions: instructions.V2_TWO_DIMENSION_CHART,
          dashboardVariables: variables,
          dpProvidedId: dataPanel.provided_id,
          setVariable: this.setVariable,
        }
      : undefined;

    return (
      <ChartHeader
        colorCategoryProps={colorCategoryProps}
        dataPanelId={dataPanel.id}
        disableMenu={loading || error}
        enableDrilldownModal={generalFormatOptions?.enableRawDataDrilldown}
        exportConfig={
          this.canDownloadCSV() && isChartExportEnabled(exportConfig, false)
            ? exportConfig ?? {}
            : undefined
        }
        hideIcons={this.hideIcons()}
        infoTooltipText={replacedVarsTooltipText}
        isHeaderHidden={generalFormatOptions?.headerConfig?.isHeaderHidden}
        linkFormat={generalFormatOptions?.linkFormat}
        loading={showLoadingState}
        onDownloadPanelImage={() => {
          downloadDataPanelScreenshotThunk(
            dataPanel,
            variables,
            dataPanelData?.adHocOperationInstructions ?? {},
            undefined,
            ExportType.Image,
            undefined,
            undefined,
          );
        }}
        onDownloadPanelSpreadsheet={(fileFormat, email) =>
          this.downloadDataPanelSpreadsheet({
            fileFormat,
            email,
            userTransformedSchema: this.state.userTransformedSchema,
          })
        }
        title={title ? this.processString(title) : ''}
      />
    );
  };

  setVariable = (newValue: DashboardVariable, name?: string, clearData?: boolean) => {
    this.props.setVariableThunk({
      varName: name || this.props.dataPanel.provided_id,
      value: newValue,
      options: { clearData },
    });
  };

  onAdHocSortOrPageUpdate = (adHocOperationInstructions: AdHocOperationInstructions) => {
    const { adHocInstructionUpdatedThunk, dataPanel } = this.props;

    adHocInstructionUpdatedThunk(dataPanel.id, adHocOperationInstructions, true);
  };

  isLoading = (): boolean => {
    const { dataPanelData } = this.props;
    return (
      !dataPanelData || dataPanelData.loading || !!dataPanelData.outstandingSecondaryDataRequests
    );
  };

  onAdHocFilterInfoUpdate = (adHocFilterInfo: FilterOperationInstructions) => {
    const { adHocInstructionUpdatedThunk, dataPanel, sendAnalyticsEvent, dataPanelData } =
      this.props;
    const newAdHocOperationInstructions = produce(
      dataPanelData?.adHocOperationInstructions ?? {},
      (draft) => {
        draft.filterInfo = adHocFilterInfo;
        draft.currentPage = 1;
      },
    );

    adHocInstructionUpdatedThunk(dataPanel.id, newAdHocOperationInstructions);
    sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_FILTERED);
  };

  processString = (s: string, builtInVars?: DashboardVariableMap) => {
    const { variables, datasetNamesToId, datasetData } = this.props;
    const inputVariables = builtInVars !== undefined ? { ...variables, ...builtInVars } : variables;
    return replaceVariablesInString(s, inputVariables, datasetNamesToId, datasetData);
  };

  downloadDataPanelSpreadsheet = (args: {
    fileFormat: ExportSpreadsheetType;
    userTransformedSchema?: UserTransformedSchema;
    email?: string;
  }) => {
    const { downloadDataPanelSpreadsheetThunk, dataPanel, variables, datasets, isDrilldownModal } =
      this.props;

    downloadDataPanelSpreadsheetThunk(
      dataPanel,
      variables,
      datasets,
      args.fileFormat,
      args.userTransformedSchema,
      args.email,
      !!isDrilldownModal,
    );
  };

  renderDemoWatermark = () => {
    const { displayDemoWatermark } = this.props;
    return displayDemoWatermark ? <DemoWatermark /> : null;
  };
}

const mapStateToProps = (state: DashboardStates, ownProps: PassedProps) => {
  return {
    colorCategoryTracker: state.dashboardStyles.colorCategoryTracker,
    enableNewGrid: state.dashboardLayout.enableNewGrid,
    dataPanelData: state.dashboardData.dataPanelData[ownProps.dataPanel.id],
    datasetData: state.dashboardData.datasetData,
    shouldPersistCustomerState:
      state.dashboardInteractions.interactionsInfo.shouldPersistCustomerState,
    customerDashboardState: state.embedDashboard.customerDashboardState,
  };
};

const mapDispatchToProps = {
  updateVisualizeOperation,
  setChartMenu,
  sendAnalyticsEvent,
  downloadDataPanelScreenshotThunk,
  downloadDataPanelSpreadsheetThunk,
  adHocInstructionUpdatedThunk,
  setVariableThunk,
  embedUpdateTableColWidths,
  saveCustomerDashboardStateThunk,
  selectKpiThunk,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

// Using this allows correct typing of thunk props
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DashboardDatasetView);
