import cx from 'classnames';
import { FC, useContext, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { Customer } from 'actions/teamActions';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import EmailBuilderTextEditor from 'components/EmailBuilder/EmailBuilderTextEditor';
import { GlobalStylesContext } from 'globalStyles';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { getTeamDataSources } from 'reducers/dataSourceReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import { PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { VersionInfo } from 'types/exploResource';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { getTimezone } from 'utils/timezoneUtils';

import * as styles from './editDashboardPage.css';

type Props = {
  dashboard: Dashboard;
  versionInfo: VersionInfo;
  width: number | undefined;
};

export const EditDashboardPageBody: FC<Props> = ({ dashboard, versionInfo, width }) => {
  const globalStylesClassName = useContext(GlobalStylesContext).globalStylesClassName;

  const { editableDashboard, viewMode, team, customer, dataSources, schemas, paneStates } =
    useSelector(
      (state: ReduxState) => ({
        editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
        team: state.currentUser.team,
        customer: getSelectedCustomer(state.customers),
        viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
        dataSources: getTeamDataSources(state),
        schemas: getParentSchemasList(state),
        paneStates: state.dashboardInteractions.paneOpenStates,
      }),
      shallowEqual,
    );

  const requestInfo: DashboardLayoutRequestInfo | undefined = useMemo(() => {
    if (!customer) return;
    return {
      type: 'app',
      resourceId: dashboard.id,
      timezone: getTimezone(dashboard.default_timezone),
      useJobQueue: team?.feature_flags.use_job_queue ?? false,
      useFido: team?.feature_flags.use_fido ?? false,
      versionNumber: versionInfo.version_number,
      customerId: customer.id,
      isDraft: versionInfo.is_draft,
      parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
      dataSources,
      schemas,
      datasetMaxRows: team?.configuration?.dataset_max_rows,
      dataPanelMaxDataPoints: team?.configuration?.data_panel_max_data_points,
    };
  }, [
    dashboard,
    team,
    versionInfo.version_number,
    versionInfo.is_draft,
    customer,
    dataSources,
    schemas,
  ]);

  const renderDashboardPreview = () => {
    if (customer === null || !requestInfo) {
      return (
        <div className={styles.noCustomer} style={{ fontSize: 20 }}>
          Create a Customer to Populate Dashboard Data
        </div>
      );
    }

    return (
      <div
        className={cx(
          styles.dashboardPreview,
          {
            [styles.fullSizeDashboard]: !editableDashboard || viewMode === VIEW_MODE.MOBILE,
            [styles.pdfOrEmailDashboard]:
              viewMode === VIEW_MODE.PDF || viewMode === VIEW_MODE.EMAIL,
          },
          globalStylesClassName,
        )}>
        <DashboardLayoutWrapper
          customer={customer}
          dashboard={dashboard}
          requestInfo={requestInfo}
          viewMode={viewMode}
          width={width}
        />
      </div>
    );
  };

  return (
    <div
      className={cx(styles.mainPageBody, {
        leftPaneOpen: paneStates.left && editableDashboard,
        rightPaneOpen: paneStates.right && editableDashboard,
      })}>
      {renderDashboardPreview()}
    </div>
  );
};

type LayoutProps = Pick<Props, 'dashboard' | 'width'> & {
  customer: Customer;
  requestInfo: DashboardLayoutRequestInfo;
  viewMode: VIEW_MODE;
};

const DashboardLayoutWrapper: FC<LayoutProps> = ({
  dashboard,
  customer,
  requestInfo,
  viewMode,
  width,
}) => {
  const {
    config,
    datasets,
    isEditableSectionEnabled,
    archetypeProperties,
    teamName,
    selectedCustomer,
  } = useSelector(
    (state: ReduxState) => ({
      // We should not be here unless the config is defined. This makes rest a lot cleaner
      config: state.dashboardEditConfig.config as DashboardVersionConfig,
      datasets: getEditableDatasets(state),
      archetypeProperties: state.teamData.data?.archetype_properties,
      isEditableSectionEnabled:
        state.currentUser.team?.entitlements.enable_editable_section &&
        state.dashboardEditConfig.config?.editable_section?.enabled,
      teamName: state.currentUser.team?.team_name,
      selectedCustomer: getSelectedCustomer(state.customers),
    }),
    shallowEqual,
  );

  const dashboardElements = useMemo(() => Object.values(config.elements), [config.elements]);
  const dataPanels = useMemo(() => Object.values(config.data_panels), [config.data_panels]);

  const dashboardLayout = useMemo(
    () => getLayoutFromDashboardVersionConfig(config, viewMode),
    [config, viewMode],
  );

  return (
    <>
      {viewMode === VIEW_MODE.EMAIL ? (
        <EmailBuilderTextEditor
          isHeader
          initialValue={config.email_header_html}
          key={
            // we re-render quill content when customer changes
            // (some inputs, e.g images, can use variables)
            selectedCustomer?.id
          }
        />
      ) : null}
      <DashboardLayout
        archetypeProperties={archetypeProperties}
        customer={customer}
        dashboardElements={dashboardElements}
        dashboardLayout={dashboardLayout}
        dataPanels={dataPanels}
        datasets={datasets}
        editableSectionConfig={config.editable_section}
        isEditableSectionEnabled={isEditableSectionEnabled}
        isViewOnly={false}
        pageLayoutConfig={config.dashboard_page_layout_config}
        pageType={PAGE_TYPE.EXPLO_APP}
        params={config.params}
        requestInfo={requestInfo}
        resourceId={dashboard.id}
        teamName={teamName}
        width={width}
      />
      {viewMode === VIEW_MODE.EMAIL ? (
        <EmailBuilderTextEditor initialValue={config.email_footer_html} isHeader={false} />
      ) : null}
    </>
  );
};
