import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  DEFAULT_NO_DATA_FONT_SIZE,
  KPIValueFormat,
  VisualizeOperationGeneralFormatOptions,
  KPITrendDisplayFormat,
  KPITitleFormat,
} from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { TrendChange } from 'shared/charts/TrendChange';
import { TextElemHorizAlignments, TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';

import { formatValue } from '../../pages/dashboardPage/charts/utils';

interface Props {
  aggValuesLoading?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  headerActions: JSX.Element | null;
  infoTooltipText?: string;
  currentPeriodValue: number;
  subtitle: string;
  /**
   * This should be how much the trend changed as either an absolute (e.g. 400,000) or percentage (e.g. 0.4) value
   */
  trendChangeVal?: number;
  /**
   * This is shown after the percent change text e.g. +40% <Comparision Period>
   */
  trendChangeValLabel: string;
  trendChangeValFormatId: string;
  noData: boolean;
  noDataPrevPeriod: boolean;
  valueFormat: KPIValueFormat | undefined;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  displayFormat: KPITrendDisplayFormat | undefined;
  titleFormat: KPITitleFormat | undefined;
  processString: (s: string) => string;
}

export default function NumberTrendTextPanel(props: Props) {
  const {
    aggValuesLoading,
    infoTooltipText,
    headerActions,
    currentPeriodValue,
    subtitle,
    noData,
    noDataPrevPeriod,
    generalOptions,
    titleFormat,
    valueFormat,
    processString,
  } = props;
  const classes = useStyles(props);
  const formattedNumber = formatValue({
    value: currentPeriodValue,
    decimalPlaces: valueFormat?.decimalPlaces ?? 2,
    formatId: valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
    hasCommas: true,
    customTimeFormat: valueFormat?.timeCustomerFormat,
    customDurationFormat: valueFormat?.customDurationFormat,
    timeFormatId: valueFormat?.timeFormat?.id,
    significantDigits: valueFormat?.significantDigits ?? 3,
    multiplier: valueFormat?.multiplyFactor,
  });

  const renderHeader = () => {
    if (generalOptions?.headerConfig?.isHeaderHidden) return;

    return (
      <div
        className={cx(titleClass, embedSprinkles({ otherText: 'kpiTitle' }))}
        style={{
          fontSize: titleFormat?.fontSize,
          textAlign: getTextAlignment(titleFormat?.horizAlignment),
        }}>
        {processString(generalOptions?.headerConfig?.title || '')}
        {aggValuesLoading ? (
          <EmbedSpinner className={classes.titleLoadingSpinner} size="md" />
        ) : null}
        {infoTooltipText ? <EmbedInfoIcon text={infoTooltipText} /> : null}
      </div>
    );
  };

  return (
    <>
      {headerActions ? <div className={headerActionsStyle}>{headerActions}</div> : null}
      <div
        className={cx(
          sprinkles({
            flex: 2,
            parentContainer: 'fill',
            flexItems: 'centerColumn',
          }),
          embedSprinkles({ body: 'primaryWithoutColor' }),
        )}>
        <div className={cx(classes.titleContainer, titleFormat?.horizAlignment)}>
          {renderHeader()}
          {subtitle ? (
            <div className={cx(classes.subtitle, embedSprinkles({ body: 'secondary' }))}>
              {subtitle}
            </div>
          ) : null}
        </div>
        <div className={cx(classes.valueContainer, valueFormat?.horizAlignment)}>
          {noData ? (
            <div className={classes.numberTrendContainer}>
              <span
                className={embedSprinkles({ body: 'primary' })}
                style={{
                  fontSize:
                    generalOptions?.noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
                }}>
                {processString(generalOptions?.noDataState?.noDataText || 'No Data')}
              </span>
            </div>
          ) : (
            <div
              className={cx(
                classes.numberTrendContainer,
                embedSprinkles({ otherText: 'kpiValue' }),
                {
                  bold: valueFormat?.bold,
                  italic: valueFormat?.italic,
                },
              )}>
              {formattedNumber}
              {valueFormat?.units && (
                <span className={classes.numberUnit}>{valueFormat?.units}</span>
              )}
            </div>
          )}
          {!noData && (
            <TrendChange
              currentValue={currentPeriodValue}
              displayFormat={props.displayFormat}
              globalStyleConfig={props.globalStyleConfig}
              noDataPrevPeriod={noDataPrevPeriod}
              trendChangeVal={props.trendChangeVal}
              trendChangeValFormatId={props.trendChangeValFormatId}
              trendChangeValLabel={props.trendChangeValLabel}
              valueFormat={props.valueFormat}
            />
          )}
        </div>
      </div>
      {/* Dummy div to balance the header and vertically center the main content */}
      <div className={sprinkles({ flex: 1 })} />
    </>
  );
}

function getTextAlignment(
  textAlignment: TextElemHorizAlignments | undefined,
): 'center' | 'left' | 'right' {
  if (textAlignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT) return 'left';
  if (textAlignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT) return 'right';
  return 'center';
}

const titleClass = sprinkles({
  flexItems: 'center',
  maxWidth: 'fill',
  overflow: 'hidden',
  marginBottom: 'sp.5',
  fontWeight: 700,
});

const useStyles = makeStyles((theme: Theme) => ({
  numberTrendContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '&.bold': {
      fontWeight: 600,
    },
    '&.italic': {
      fontStyle: 'italic',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  numberUnit: (props: Props) => ({
    marginLeft: props.valueFormat?.unitPadding ? theme.spacing(2) : theme.spacing(0),
  }),
  trend: {
    fontWeight: 600,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',

    '&.tag': {
      padding: '2px 4px',
      borderRadius: 2,
      backgroundColor: '#e3e8ee',

      '&.positive': {
        backgroundColor: '#cbf4c9',
      },
      '&.negative': {
        backgroundColor: theme.palette.ds.lightRed,
      },
    },
  },
  valueContainer: (props: Props) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0px ${props.globalStyleConfig.container.padding.default / 2}px`,

    '&.LEFT_ALIGN': {
      alignItems: 'flex-start',
      padding: `0px ${props.globalStyleConfig.container.padding.default}px`,
    },
    '&.CENTER_ALIGN': {
      alignItems: 'center',
    },
    '&.RIGHT_ALIGN': {
      alignItems: 'flex-end',
      padding: `0px ${props.globalStyleConfig.container.padding.default}px`,
    },
  }),
  titleContainer: (props: Props) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0px ${props.globalStyleConfig.container.padding.default / 2}px`,

    '&.LEFT_ALIGN': {
      alignItems: 'flex-start',
      padding: `0px ${props.globalStyleConfig.container.padding.default}px`,
    },
    '&.CENTER_ALIGN': {
      alignItems: 'center',
    },
    '&.RIGHT_ALIGN': {
      alignItems: 'flex-end',
      padding: `0px ${props.globalStyleConfig.container.padding.default}px`,
    },
  }),
  titleLoadingSpinner: {
    marginLeft: theme.spacing(3),
  },
}));

const headerActionsStyle = sprinkles({
  width: 'fill',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flex: 1,
});
