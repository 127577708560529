import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { SortableChartOptions } from 'actions/customerReportActions';
import { Icon, sprinkles } from 'components/ds';
import { SortAxis, SortOption } from 'constants/types';
import { getCurrentSortValues } from 'pages/ReportBuilder/utils/visualizationUtils';
import { setSortableChartOptions } from 'reportBuilderContent/reducers/reportEditingReducer';

import { DataPanelSection } from './DataPanelSection';
import { DataPanelSubHeader } from './DataPanelSubHeader';
import { ToggleButton } from './ToggleButton';

type Props = { isHorizontal: boolean; options: SortableChartOptions | undefined };

export const SortingSection: FC<Props> = ({ options, isHorizontal }) => {
  const dispatch = useDispatch();

  const { sortAxis, sortOption } = getCurrentSortValues(options);

  const renderAxisButton = (axis: SortAxis) => {
    const label =
      axis === SortAxis.CAT_AXIS
        ? isHorizontal
          ? 'Y-Axis'
          : 'X-Axis'
        : isHorizontal
        ? 'X-Axis'
        : 'Y-Axis';

    return (
      <ToggleButton
        onClick={() => dispatch(setSortableChartOptions({ axis }))}
        selected={sortAxis === axis}>
        {label}
      </ToggleButton>
    );
  };

  const renderDirectionButton = (direction: SortOption) => {
    return (
      <ToggleButton
        onClick={() => dispatch(setSortableChartOptions({ direction }))}
        selected={direction === sortOption}>
        {direction === SortOption.ASC ? 'Ascending' : 'Descending'}
      </ToggleButton>
    );
  };

  return (
    <DataPanelSection>
      <DataPanelSubHeader title="Sorting" />
      <div className={containerClass}>
        <div className={rowClass}>
          <Icon className={iconClass} name="chart-column" />
          {renderAxisButton(SortAxis.CAT_AXIS)}
          {renderAxisButton(SortAxis.AGG_AXIS)}
        </div>
        <div className={rowClass}>
          <Icon className={iconClass} name="arrow-down-arrow-up" />
          {renderDirectionButton(SortOption.DESC)}
          {renderDirectionButton(SortOption.ASC)}
        </div>
      </div>
    </DataPanelSection>
  );
};

const iconClass = sprinkles({ color: 'contentTertiary', marginRight: 'sp.5' });

const rowClass = sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' });

const containerClass = sprinkles({
  flexItems: 'column',
  paddingX: 'sp2',
  width: 'fill',
  gap: 'sp2',
});
