import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';

export const NoData: FC = () => {
  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
      <Icon
        className={sprinkles({ marginBottom: 'sp2', color: 'contentTertiary' })}
        name="empty-set"
        size="lg"
      />
      <EmbedText body="b1" color="contentTertiary">
        No Data
      </EmbedText>
    </div>
  );
};
