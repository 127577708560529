import { Entitlements, FeatureFlags, Configuration } from 'flags/flags';

import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { ACTION } from './types';

export type AdminTeam = {
  id: number;
  team_name: string;
  tenant_id?: string;
  entitlements: Entitlements;
  feature_flags: FeatureFlags;
  configuration: Configuration;
};

export type AdminUser = {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
};

export const { actionFn: superuserGetTeams, ...superuserGetTeamsActions } = defineAPIAction<{
  teams: { id: number; team_name: string }[];
}>(ACTION.GET_SUPERUSER_TEAMS, 'superuser', 'teams', 'GET');

export const { actionFn: superuserGetTeamUsers, ...superuserGetTeamUsersActions } =
  defineAPIPostAction<
    {
      team_id: number;
    },
    {
      team_id: number;
      users: AdminUser[];
    }
  >(ACTION.GET_SUPERUSER_USERS, 'superuser', 'users', 'POST');

export const { actionFn: superuserGetTeam, ...superuserGetTeamActions } = defineAPIPostAction<
  { team_id: number },
  {
    team: AdminTeam;
  }
>(ACTION.GET_SUPERUSER_TEAM, 'superuser', 'team', 'POST');

export const { actionFn: superuserRemoveUser, ...superuserRemoveUserActions } = defineAPIPostAction<
  { user_id: number },
  {}
>(ACTION.SUPERUSER_REMOVE_USER, 'superuser', 'team', 'POST');

export const { actionFn: superuserUpdateTeam, ...superuserUpdateTeamActions } = defineAPIPostAction<
  { team_id: number; type: string; name: string; value: string | number | boolean },
  {
    team: AdminTeam;
  }
>(ACTION.SUPERUSER_UPDATE_TEAM, 'superuser', 'update_team', 'POST');

export const { actionFn: superuserExtendTrial } = defineAPIPostAction<
  { team_id: number; days: number },
  {}
>(ACTION.SUPERUSER_EXTEND_TRIAL, 'superuser', 'extend_trial', 'POST');

export const { actionFn: superuserStartTrial } = defineAPIPostAction<{ team_id: number }, {}>(
  ACTION.SUPERUSER_START_TRIAL,
  'superuser',
  'start_trial',
  'POST',
);

// TODO get rid of this when we mvoe off of payment plans
export const paymentPlanOptions = [
  'Launch',
  'Growth',
  'Growth V2',
  'Pro',
  'Enterprise',
  'Trial Completed',
];
export type paymentPlanType =
  | 'Launch'
  | 'Growth'
  | 'Growth V2'
  | 'Pro'
  | 'Enterprise'
  | 'Trial Completed';

export const { actionFn: superuserUpdatePaymentPlan } = defineAPIPostAction<
  {
    team_id: number;
    tier: paymentPlanType;
    maintain_trial_status: boolean;
  },
  {}
>(ACTION.SUPERUSER_EXTEND_TRIAL, 'superuser', 'update_payment_plan', 'POST');

export const { actionFn: superuserVerifyEmail } = defineAPIPostAction<
  {
    email: string;
  },
  {}
>(ACTION.SUPERUSER_VERIFY_EMAIL, 'superuser', 'verify_email', 'POST');
