import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';

type Props = {
  title: string;
  className?: string;
  isSubTitle?: boolean;
  compact?: boolean;
};

export const ConfigSectionHeader: FC<Props> = ({ title, className, isSubTitle, compact }) => {
  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'alignCenterBetween', marginBottom: compact ? 'sp1' : 'sp2' }),
        className,
      )}>
      <div className={sprinkles({ heading: isSubTitle ? 'h4' : 'h3' })}>{title}</div>
    </div>
  );
};
