import cx from 'classnames';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ResizeObserver from 'react-resize-observer';

import { Customer, EmbedCustomer } from 'actions/teamActions';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { Tooltip, sprinkles } from 'components/ds';
import { EmbedButton, EmbedInfoIcon, EmbedPopover } from 'components/embed';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EditingLayout, setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { getEditableSectionConfig, getEditableSectionLayout } from 'reducers/selectors';
import { sendEditableSectionUpdatedEventThunk } from 'reducers/thunks/customEventThunks';
import { handleEditableSectionClickThunk } from 'reducers/thunks/dashboardSelectionThunks';
import {
  addChartToEditableSectionThunk,
  removeChartFromEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import {
  AddEditableSectionChartPayload,
  INPUT_EVENT,
  RemoveEditableSectionChartPayload,
  ToggleEditableSectionPayload,
} from 'types/customEventTypes';
import { DashboardElement, VIEW_MODE } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { ResourceDataset } from 'types/exploResource';
import { useCustomEvent } from 'utils/customEvent/useCustomEvent';
import { replaceVariablesInString } from 'utils/dataPanelConfigUtils';
import * as utils from 'utils/editableSectionUtils';
import {
  editableSectionChartToEventChart,
  isChartInstanceOfTemplate,
} from 'utils/editableSectionUtils';
import { getLayoutMargin } from 'utils/layoutUtils';

import { AddChartMenu } from './AddChartMenu';
import { EditableSectionLayout } from './EditableSectionLayout';
import * as styles from './index.css';

type Props = {
  customer: EmbedCustomer | Customer | undefined;
  datasets: Record<string, ResourceDataset>;
  elements: DashboardElement[];
  globalStyleConfig: GlobalStyleConfig;
  isEditingDashboard: boolean;
  viewMode: VIEW_MODE;
};

export const EditableSection: FC<Props> = ({
  customer,
  elements,
  datasets,
  globalStyleConfig,
  isEditingDashboard,
  viewMode,
}) => {
  const dispatch = useDispatch();

  // Better to use the layout and config from store so only this part rerenders
  const layout = useSelector(getEditableSectionLayout);
  const config = useSelector(getEditableSectionConfig);

  const context = useContext(DashboardLayoutContext);

  const { isEmbed, editingLayout, isEditing, isEditingDisabled, variables } = useSelector(
    (state: DashboardStates) => ({
      isEmbed: state.dashboardLayout.requestInfo.type === 'embedded',
      editingLayout: state.dashboardInteractions.editingLayout,
      isEditing: state.dashboardInteractions.isEditingEditableSection,
      isEditingDisabled: state.dashboardInteractions.interactionsInfo.disableEditingEditableSection,
      variables: state.dashboardData.variables,
    }),
    shallowEqual,
  );

  const [isChartMenuOpen, setIsChartMenuOpen] = useState(false);
  const [isMouseInSection, setIsMouseInSection] = useState(false);
  const [width, setWidth] = useState<number>();

  const shouldRender = utils.shouldRenderEditableSection(layout, viewMode);
  const cols = globalStyleConfig.base.numColumns;

  // If view mode or editing mode changes turn off editing mode
  useEffect(() => {
    dispatch(setIsEditingEditableSection(false));
  }, [dispatch, viewMode, isEditingDashboard]);

  const chartsToAdd: EditableSectionChart[] = useMemo(
    () => utils.filterChartsForCustomer(config?.charts, customer),
    [config?.charts, customer],
  );

  useEffect(() => {
    if (config && shouldRender) {
      const charts =
        layout?.map((elem) => {
          const chart = chartsToAdd.find((c) => isChartInstanceOfTemplate(elem.i, c));
          return {
            ...(chart ? editableSectionChartToEventChart(chart) : { name: '', type: '' }),
            id: elem.i,
          };
        }) ?? [];
      const availableCharts = chartsToAdd.map(editableSectionChartToEventChart);
      dispatch(sendEditableSectionUpdatedEventThunk(charts, availableCharts));
    }
  }, [chartsToAdd, config, dispatch, layout, shouldRender]);

  const handleAddChartEvent = useCallback(
    ({ detail: { chartTemplateId } }: CustomEvent<AddEditableSectionChartPayload>) => {
      const chart = chartsToAdd.find((c) => c.data_panel.id === chartTemplateId);
      if (chart) dispatch(addChartToEditableSectionThunk(layout, chart, cols));
    },
    [chartsToAdd, cols, dispatch, layout],
  );

  const handleRemoveChartEvent = useCallback(
    (event: CustomEvent<RemoveEditableSectionChartPayload>) => {
      const { chartId } = event.detail;
      if (layout) dispatch(removeChartFromEditableSectionThunk(layout, chartId));
    },
    [dispatch, layout],
  );

  const handleToggleEditing = useCallback(
    (event: CustomEvent<ToggleEditableSectionPayload>) => {
      const { canEdit } = event.detail;
      dispatch(setIsEditingEditableSection(canEdit));
    },
    [dispatch],
  );

  useCustomEvent(INPUT_EVENT.ADD_EDITABLE_SECTION_CHART, handleAddChartEvent);
  useCustomEvent(INPUT_EVENT.REMOVE_EDITABLE_SECTION_CHART, handleRemoveChartEvent);
  useCustomEvent(INPUT_EVENT.TOGGLE_EDITABLE_SECTION, handleToggleEditing);

  // Config should always be defined up to this point but checking for TS
  if (!config || !shouldRender) return null;

  const disableAddCharts = chartsToAdd.length === 0;
  const margin = getLayoutMargin(viewMode, globalStyleConfig);
  const isEditingAllowed = utils.canEditEditableSection(viewMode) && !isEditingDisabled;

  const renderEmptySection = () => {
    return (
      <div style={{ padding: margin }}>
        <div
          className={styles.emptySection}
          style={{ height: utils.getEmptySectionHeight(margin) }}>
          <div className={embedSprinkles({ body: 'secondary' })}>
            {isEditingDashboard
              ? 'Add a chart to set the default layout for your customers'
              : 'Add a chart to customize this section of your dashboard.'}
          </div>
        </div>
      </div>
    );
  };

  // TODO(zifanxiang): Remove this function and use a better paradigm for tooltips.
  const wrapInTooltip = (button: JSX.Element, tooltipText: string | undefined) => {
    if (!tooltipText) return button;
    return <Tooltip text={tooltipText}>{button}</Tooltip>;
  };

  const renderEditButton = () => {
    return wrapInTooltip(
      <EmbedButton
        disabled={isEditingDashboard}
        onClick={() => dispatch(setIsEditingEditableSection(!isEditing))}
        variant="primary">
        Edit
      </EmbedButton>,
      isEditing || isEmbed
        ? undefined
        : 'To edit the default layout use the config panel on the left.',
    );
  };

  const renderAddChartPopoverButton = () => {
    return wrapInTooltip(
      <div>
        <EmbedPopover
          align="end"
          className={cx(
            styles.addChartMenu,
            embedSprinkles({ backgroundColor: 'containerFill', body: 'primary' }),
            GLOBAL_STYLE_CLASSNAMES.container.outline.border,
            GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
          )}
          isOpen={isChartMenuOpen}
          onOpenChange={(isOpen) => setIsChartMenuOpen(isOpen && !disableAddCharts)}
          portalContainerId={context.dashboardLayoutTagId}
          trigger={
            <EmbedButton disabled={disableAddCharts} icon="plus" variant="primary">
              Add Chart
            </EmbedButton>
          }
          width="large">
          <AddChartMenu
            chartsToAdd={chartsToAdd}
            isEditing={isEditing}
            layout={layout}
            numCols={cols}
            onItemClicked={() => setIsChartMenuOpen(false)}
            variables={variables}
          />
        </EmbedPopover>
      </div>,
      isEditing || isEmbed
        ? undefined
        : 'To toggle charts from the default layout use the config panel on the left.',
    );
  };

  const renderDoneButton = () => {
    return (
      <EmbedButton
        icon="check"
        onClick={() => dispatch(setIsEditingEditableSection(!isEditing))}
        variant="primary">
        Done
      </EmbedButton>
    );
  };

  const renderEditButtons = () => (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
      {isEditing ? (
        <>
          {renderAddChartPopoverButton()}
          {renderDoneButton()}
        </>
      ) : (
        renderEditButton()
      )}
    </div>
  );

  // This section is used to highlight when editable section is being edited. Needed to
  // be like this because hover states are funky with react grid layout
  const renderBorderSection = () => {
    const isEditingLayout = editingLayout === EditingLayout.EDITABLE_SECTION;
    // Dividing by two to move the border away from the edge of the dashboard.
    // The two pixels is so that data panel outlines don't touch the border.
    // Came to this solution with Carly.
    const borderMargin = margin / 2 - 2;

    return (
      <div
        className={cx(styles.borderContainer, {
          [styles.selectedBorderContainer]: isEditingLayout,
          [styles.hoverBorderContainer]: isMouseInSection && !isEditingLayout,
        })}
        style={{
          // Needed the opposite for the top margin so this is the reverse of borderMargin
          marginTop: -(margin / 2 + 2),
          marginLeft: borderMargin,
          marginRight: borderMargin,
          marginBottom: borderMargin,
        }}
      />
    );
  };

  const settings = config.settings;

  // Used for border section above
  const onMouseEnter = isEditingDashboard ? () => setIsMouseInSection(true) : undefined;
  const onMouseLeave = isEditingDashboard ? () => setIsMouseInSection(false) : undefined;

  return (
    <div
      className={cx(
        embedSprinkles({ backgroundColor: 'background' }),
        sprinkles({ position: 'relative' }),
      )}
      onClick={(e) => {
        dispatch(handleEditableSectionClickThunk());
        e.stopPropagation();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {/* Used to show selection state of section */}
      {isEditingDashboard ? renderBorderSection() : null}
      <div className={styles.headerContainer} style={{ margin, marginBottom: 0 }}>
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <div className={embedSprinkles({ heading: 'h1' })}>
            {replaceVariablesInString(settings.title, variables)}
          </div>
          {settings.tooltipText ? <EmbedInfoIcon text={settings.tooltipText} /> : null}
        </div>
        {isEditingAllowed ? renderEditButtons() : null}
      </div>
      {layout?.length ? (
        <EditableSectionLayout
          cols={cols}
          config={config}
          datasets={datasets}
          elements={elements}
          isEditing={isEditing}
          isEditingDashboard={isEditingDashboard}
          isViewOnly={!isEditingAllowed}
          layout={layout}
          margin={margin}
          variables={variables}
          viewMode={viewMode}
          width={width}
        />
      ) : (
        renderEmptySection()
      )}
      <ResizeObserver onResize={(resize) => setWidth(resize.width)} />
    </div>
  );
};
