import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

import { Props as SwitchProps, Switch } from '../Switch';
import { sprinkles } from '../sprinkles.css';

import { MenuItemProps } from './MenuItem';
import * as styles from './index.css';

type MenuSwitchItemProps = Omit<MenuItemProps, 'onSelect' | 'isDestructive'> & {
  switchProps: SwitchProps;
};

export const MenuSwitchItem = forwardRef<HTMLDivElement, MenuSwitchItemProps>(
  function MenuSwitchItem({ disabled, switchProps, text }, ref) {
    return (
      <DropdownMenu.Item
        className={styles.menuItem}
        disabled={disabled}
        onSelect={(e) => e.preventDefault()}
        ref={ref}>
        {/* Label in menu is different so can't use DS label */}
        <span className={sprinkles({ color: 'contentPrimary', truncateText: 'ellipsis' })}>
          {text}
        </span>
        <Switch className={styles.menuSwitch} {...switchProps} />
      </DropdownMenu.Item>
    );
  },
);
