import { FC } from 'react';

import { sprinkles, Icon } from 'components/ds';
import * as styles from 'pages/ReportBuilderEditor/Preview/TotalFooterExample.css';

export const TotalFooterExample: FC = () => (
  <table cellSpacing={0} className={styles.totalTable}>
    <thead className={sprinkles({ backgroundColor: 'elevationLow' })}>
      <tr>
        <th className={styles.totalTableHeaderCell}>Name</th>
        <th className={styles.totalTableHeaderCell}>Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr className={sprinkles({ color: 'contentSecondary' })}>
        <td className={styles.totalTableCell}>
          Count Distinct <Icon name="chevron-down" size="sm" />
        </td>
        <td className={styles.totalTableCell}>
          Average <Icon name="chevron-down" size="sm" />
        </td>
      </tr>
      <tr className={sprinkles({ fontWeight: 700 })}>
        <td className={styles.totalTableCell}>536</td>
        <td className={styles.totalTableCell}>106</td>
      </tr>
    </tbody>
  </table>
);
