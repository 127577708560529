import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { Aggregation, OPERATION_TYPES, VisualizePivotTableInstructions } from 'constants/types';
import { AppToaster } from 'toaster';
import { DatasetSchema } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import { resolveCategoryColDropped, resolveAggColDropped } from 'utils/dataPanelColUtils';

import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: VisualizePivotTableInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export const PivotTableConfig: FC<Props> = ({ instructions, chartType, loading, schema }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <SettingHeader name="Columns" />
      <DroppableColumnSection
        required
        columns={instructions.colColumn ? [instructions.colColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.colColumn = { column: col }; // colCol doesn't use resolveCategoryColDropped because it doesn't support bucketing
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.colColumn) draft.colColumn.bucket = { id: option.id as PivotAgg };
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.colColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />

      <SettingHeader name="Rows" />
      <DroppableColumnSection
        required
        columns={instructions.rowColumn ? [instructions.rowColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.rowColumn = resolveCategoryColDropped(col, draft.rowColumn);
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.rowColumn) draft.rowColumn.bucket = { id: option.id as PivotAgg };
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.rowColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />

      <SettingHeader name="Pivot Value" />
      <DroppableColumnSection
        includeFirst
        required
        columns={instructions.aggregation ? [instructions.aggregation] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.aggregation = resolveAggColDropped(
              col,
              (toastInfo) => AppToaster.show(toastInfo),
              draft.aggregation ? [draft.aggregation] : [],
              1,
            )[0];
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const col = draft.aggregation;
            if (!col) return;
            if (col.column.name === name && (!aggType || col.agg.id === aggType.id)) {
              col.agg = { id: option.id as Aggregation, formula: option.formula };
            }
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.aggregation = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
    </div>
  );
};
