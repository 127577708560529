import produce from 'immer';
import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import validator from 'validator';

import {
  createDashboardEmail,
  editDashboardEmail,
  deleteDashboardEmail,
  sendTestEmail,
} from 'actions/emailActions';
import { bulkEnqueueJobs } from 'actions/jobQueueActions';
import { ExploreEmailCadence } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { CustomerSelector } from 'components/CustomerSelector';
import { DeleteConfirmationButton } from 'components/DeleteConfirmationButton';
import { InfoCard } from 'components/InfoCard';
import { Poller } from 'components/JobQueue/Poller';
import { Jobs } from 'components/JobQueue/types';
import {
  APP_PORTAL_ID,
  Input,
  Select,
  sprinkles,
  Button,
  Intent,
  Modal,
  Toggle,
  ToggleItem,
} from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { EMAIL_FREQUENCY, EmailCadenceTime } from 'constants/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { showSuccessToast, showErrorContactSupportToast } from 'shared/sharedToasts';
import { getFrequency, getHour, getMilitaryTimeHour } from 'utils/emailUtils';
import { isEmailReportingEnabled } from 'utils/paymentPlanUtils';

import { EmailDaySelection } from './emailDaySelection';
import { EmailTimeSelection } from './emailTimeSelection';

type Props = {
  closeModal: () => void;
  dashboardId: number;
  emailCadence?: ExploreEmailCadence;
  modalTitle: string;
};

enum LinkOption {
  DASHBOARD = 'Link to dashboard',
  CUSTOM = 'Link to custom URL',
  NONE = 'No link',
}

const EMAIL_FREQUENCY_OPTIONS = Object.values(EMAIL_FREQUENCY).map((emailFrequency) => ({
  value: emailFrequency,
}));

export const SetupEmailModal: FC<Props> = ({
  closeModal,
  modalTitle,
  emailCadence,
  dashboardId,
}) => {
  const dispatch = useDispatch();

  const { currentUser, emailSetupLoading, deleteEmailLoading, shouldUseJobQueue, fromEmail } =
    useSelector(
      (state: ReduxState) => ({
        currentUser: state.currentUser,
        emailSetupLoading: createLoadingSelector(
          [ACTION.CREATE_DASHBOARD_EMAIL, ACTION.EDIT_DASHBOARD_EMAIL],
          false,
        )(state),
        deleteEmailLoading: createLoadingSelector([ACTION.DELETE_DASHBOARD_EMAIL], false)(state),
        shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
        fromEmail: state.currentUser.team?.send_emails_as ?? DEFAULT_SUPPORT_EMAIL,
      }),
      shallowEqual,
    );

  const [subject, setSubject] = useState(emailCadence?.subject || '');
  const [frequency, setFrequency] = useState<EMAIL_FREQUENCY | undefined>(
    getFrequency(emailCadence),
  );
  const [dayOfWeek, setDayOfWeek] = useState<number | undefined>(emailCadence?.day_of_week);
  const [time, setTime] = useState<EmailCadenceTime>({
    hour: getHour(emailCadence),
    minute: emailCadence?.minute,
    isPm: (emailCadence?.hour ?? 0) >= 12,
    timezone: emailCadence?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [weekOfMonth, setWeekOfMonth] = useState<number | undefined>(emailCadence?.week_of_month);
  const [testEmail, setTestEmail] = useState('');
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined);
  const [customUrl, setCustomUrl] = useState(emailCadence?.custom_url || undefined);
  const [errorText, setErrorText] = useState<string | undefined>();

  const initialLinkOption = emailCadence?.disable_link
    ? LinkOption.NONE
    : emailCadence?.custom_url
    ? LinkOption.CUSTOM
    : LinkOption.DASHBOARD;
  const [linkOption, setLinkOption] = useState<LinkOption>(initialLinkOption);

  const inputLinkUrl = linkOption === LinkOption.CUSTOM ? customUrl : null;
  const isLinkDisabled = linkOption === LinkOption.NONE;

  const validateFrequency = () =>
    frequency === EMAIL_FREQUENCY.DAILY ||
    (frequency === EMAIL_FREQUENCY.MONTHLY &&
      weekOfMonth !== undefined &&
      dayOfWeek !== undefined) ||
    (frequency === EMAIL_FREQUENCY.WEEKLY && dayOfWeek !== undefined);

  const validateTime = () =>
    time && time.hour && time.minute !== undefined && time.isPm !== undefined && time.timezone;

  const validateLink = () =>
    linkOption !== LinkOption.CUSTOM || (customUrl && validator.isURL(customUrl));

  const isFormReadyToSubmit = () => {
    return (
      validator.isEmail(fromEmail) &&
      !validator.isEmpty(subject ?? '', { ignore_whitespace: true }) &&
      validateTime() &&
      validateFrequency() &&
      validateLink()
    );
  };

  const sendTestEmailWrapper = () => {
    const postData = {
      dashboard_template_id: dashboardId,
      email: testEmail,
      subject: !validator.isEmpty(subject) ? subject : 'Explo Test Email',
      customer_id: selectedCustomerId,
      custom_url: inputLinkUrl,
      disable_link: isLinkDisabled,
    };

    const onSuccess = () => {
      showSuccessToast(
        `An email has been sent to ${testEmail}. Emails usually arrive within a couple of minutes.`,
        5,
      );
      setIsTestEmailSending(false);
    };

    const onError = (errorMessage?: string) => {
      setErrorText(
        errorMessage ??
          `Something went wrong. Please try again or contact ${DEFAULT_SUPPORT_EMAIL} if the error continues.`,
      );
      setIsTestEmailSending(false);
    };

    setIsTestEmailSending(true);
    setErrorText(undefined);

    if (!shouldUseJobQueue)
      dispatch(sendTestEmail({ postData }, onSuccess, (response) => onError(response.error_msg)));
    else {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_EMAIL,
          job_args: postData,
          onSuccess,
          onError,
        },
      };

      dispatch(
        bulkEnqueueJobs({ jobs }, (jobs) => {
          setAwaitedJobs(
            produce(awaitedJobs, (draft) => {
              return {
                ...draft,
                ...jobs,
              };
            }),
          );
        }),
      );
    }
  };

  const onConfirm = () => {
    const hour = getMilitaryTimeHour(time);
    const postData = {
      dashboard_template_id: dashboardId,
      from_email: fromEmail,
      subject: subject,
      hour: hour,
      minute: time.minute,
      day_of_week: dayOfWeek,
      week_of_month: weekOfMonth,
      timezone: time.timezone,
      custom_url: inputLinkUrl,
      disable_link: isLinkDisabled,
    };
    emailCadence
      ? dispatch(
          editDashboardEmail(
            { postData },
            () => {
              closeModal();
              showSuccessToast('Email edited successfully.');
            },
            (response) => showErrorContactSupportToast(response.error_msg),
          ),
        )
      : dispatch(
          createDashboardEmail(
            { postData },
            () => {
              closeModal();
              showSuccessToast('Email configured successfully.');
            },
            (response) => showErrorContactSupportToast(response.error_msg),
          ),
        );
  };

  const deleteButton = (
    <DeleteConfirmationButton
      disabled={!emailCadence}
      loading={deleteEmailLoading}
      onDelete={() => {
        if (!emailCadence) return;
        dispatch(
          deleteDashboardEmail(
            {
              postData: { dashboard_template_id: dashboardId },
            },
            () => {
              closeModal();
            },
          ),
        );
      }}
    />
  );

  return (
    <Modal
      isOpen
      customButton={deleteButton}
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{
        disabled: !isFormReadyToSubmit(),
        loading: emailSetupLoading,
        text: 'Confirm',
        onClick: onConfirm,
      }}
      size="medium"
      title={modalTitle}>
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0)
            setAwaitedJobs((currentAwaitedJobs) => {
              const newAwaitedJobs = produce(currentAwaitedJobs, (draft) =>
                finishedJobIds.forEach((jobId) => delete draft[jobId]),
              );
              return newAwaitedJobs;
            });

          onComplete();
        }}
      />
      <div className={sprinkles({ paddingX: 'sp3' })}>
        <div className={sprinkles({ color: 'contentPrimary' })}>
          Set up recurring emails to be sent to dashboard users.
        </div>
        {!isEmailReportingEnabled(currentUser) ? (
          <InfoCard
            error
            text="Email is not enabled in your plan. You can still send test emails and create email
          reports, but they will be disabled. Please upgrade to start sending email reports!"
          />
        ) : null}
        <div className={sprinkles({ marginTop: 'sp1.5' })}>
          <Input disabled defaultValue={fromEmail} label="From Address" onSubmit={() => null} />
          <div className={sprinkles({ body: 'b3', color: 'contentTertiary' })}>
            This is the address the recipient will be receiving the email from. Contact Explo
            support if you would like the email to be sent from your own domain
          </div>
        </div>
        <Input
          className={sprinkles({ marginTop: 'sp1.5' })}
          defaultValue={subject}
          label="Email Subject"
          onSubmit={setSubject}
          placeholder="Scheduled Report"
        />
        <div className={sprinkles({ marginY: 'sp1.5' })}>
          <Select
            label="Frequency"
            onChange={(frequency) => setFrequency(frequency as EMAIL_FREQUENCY)}
            placeholder="Select Frequency"
            selectedValue={frequency}
            values={EMAIL_FREQUENCY_OPTIONS}
          />
          {frequency === EMAIL_FREQUENCY.MONTHLY || frequency === EMAIL_FREQUENCY.WEEKLY ? (
            <EmailDaySelection
              dayOfWeek={dayOfWeek}
              frequency={frequency}
              onSetDayOfWeek={setDayOfWeek}
              onSetWeekOfMonth={setWeekOfMonth}
              weekOfMonth={weekOfMonth}
            />
          ) : null}
        </div>
        <EmailTimeSelection emailCadenceTime={time} onSetTime={setTime} />

        <Toggle
          className={sprinkles({ marginTop: 'sp3' })}
          label="Email Link"
          onValueChange={(val) => setLinkOption(val as LinkOption)}
          selectedValue={linkOption}>
          <ToggleItem value={LinkOption.DASHBOARD} />
          <ToggleItem value={LinkOption.CUSTOM} />
          <ToggleItem value={LinkOption.NONE} />
        </Toggle>
        {linkOption === LinkOption.CUSTOM ? (
          <Input
            className={sprinkles({ marginTop: 'sp1' })}
            defaultValue={customUrl}
            intent={validateLink() ? undefined : Intent.ERROR}
            label="Custom URL"
            onSubmit={(value) => setCustomUrl(value)}
          />
        ) : null}

        <div className={testEmailContainerClass}>
          <div className={sprinkles({ heading: 'h4' })}>Test Email</div>
          <div className={sprinkles({ marginY: 'sp1.5', color: 'contentPrimary' })}>
            The test email will be populated with data from one of your customers.
          </div>
          <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1' })}>
            <Input
              className={sprinkles({ flex: 3 })}
              defaultValue={testEmail}
              intent={testEmail && !validator.isEmail(testEmail) ? Intent.ERROR : undefined}
              label="Email Address"
              onSubmit={setTestEmail}
              placeholder="recipient@your_email.com"
            />
            <CustomerSelector
              label="Viewing As"
              onSelect={(id) => setSelectedCustomerId(parseInt(id))}
              selectedCustomerId={selectedCustomerId}
            />
            <div className={sprinkles({ display: 'flex', justifyContent: 'flex-end' })}>
              <Button
                disabled={!validator.isEmail(testEmail)}
                loading={isTestEmailSending}
                onClick={sendTestEmailWrapper}
                variant="secondary">
                Send Test
              </Button>
            </div>
          </div>
          <div className={sprinkles({ body: 'b3', color: 'contentTertiary' })}>
            This may take up to 30 seconds.
          </div>
          {errorText && (
            <div className={sprinkles({ body: 'b3', color: 'error' })}>{errorText}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const testEmailContainerClass = sprinkles({
  backgroundColor: 'elevationMid',
  padding: 'sp1.5',
  borderRadius: 8,
  marginTop: 'sp4',
});
