import { FC, ReactNode } from 'react';

import { CustomerReportVisualization } from 'actions/customerReportActions';
import { Icon, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { getVisualizationName } from 'pages/ReportBuilder/utils/visualizationUtils';

type Props = {
  operationType: CustomerReportVisualization;
  children: ReactNode;
};

export const InvalidData: FC<Props> = ({ operationType, children }) => {
  return (
    <div
      className={sprinkles({
        parentContainer: 'fill',
        flexItems: 'centerColumn',
        gap: 'sp.5',
        color: 'contentTertiary',
      })}>
      <Icon name="circle-exclamation" size="lg" />
      <EmbedText body="b1">Data not valid for {getVisualizationName(operationType)}</EmbedText>
      <EmbedText body="b2">{children}</EmbedText>
    </div>
  );
};
