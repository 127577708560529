import cx from 'classnames';
import { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { FolderBreadcrumbs, Icon, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { ResourceConfigurationMenu } from 'shared/ExploResource/ResourceConfigurationMenu';
import { useBreadcrumbItems } from 'shared/ExploResource/useBreadcrumbItems';
import { Breadcrumb, ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { isCreateResourceDisabled } from 'utils/paymentPlanUtils';
import { canUserViewResourceConfigurationMenu } from 'utils/permissionUtils';

import * as styles from './styles.css';

type Props = {
  className?: string;
  createResourceDisabled?: boolean;
  dashboardAttributes?: DashboardAttribute[];
  resource: Dashboard | ReportBuilder;
  breadcrumbs: Breadcrumb[];
  emailCadence?: ExploreEmailCadence;
  pageType: ResourcePageType;

  openConfigurabilityModal?: () => void;
};

export const EditResourceBannerDropdown: FC<Props> = ({
  className,
  resource,
  breadcrumbs,
  emailCadence,
  dashboardAttributes,
  pageType,
  openConfigurabilityModal,
}) => {
  const { currentUser, numDashboards } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      numDashboards: state.dashboard.numDashboards,
    }),
    shallowEqual,
  );

  const createResourceDisabled = isCreateResourceDisabled(
    pageType === ResourcePageType.EXPLORE,
    numDashboards || 0,
    currentUser.team?.payment_plan,
  );

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const breadcrumbItems = useBreadcrumbItems(breadcrumbs, pageType);
  const title = (
    <div style={{ minWidth: 0 }}>
      {breadcrumbItems.length > 1 ? (
        <div
          // so clicking on the breadcrumbs doesn't open the menu
          onPointerDown={(e) => e.stopPropagation()}>
          <FolderBreadcrumbs items={breadcrumbItems} />
        </div>
      ) : null}
      <div
        className={sprinkles({
          truncateText: 'ellipsis',
          heading: breadcrumbItems.length > 1 ? 'h3' : 'h2',
        })}>
        {resource.name}
      </div>
    </div>
  );

  if (!canUserViewResourceConfigurationMenu(currentUser, resourcePermissionEntity)) {
    return <div className={cx(styles.resourceBannerDropdown, className)}>{title}</div>;
  }

  const trigger = (
    <div
      className={cx(
        styles.resourceBannerDropdown,
        sprinkles({
          cursor: 'pointer',
          backgroundColor: { hover: 'elevationMid', active: 'elevationLow' },
        }),
        className,
      )}>
      {title}
      <Icon name="caret-down" />
    </div>
  );

  return (
    <ResourceConfigurationMenu
      showDeveloperSettings
      createResourceDisabled={createResourceDisabled}
      dashboardAttributes={dashboardAttributes}
      emailCadence={emailCadence}
      onDelete={() => (window.location.href = '/home')}
      openConfigurabilityModal={openConfigurabilityModal}
      pageType={pageType}
      resource={resource}
      resourcePermissionEntity={resourcePermissionEntity}
      trigger={trigger}
    />
  );
};
