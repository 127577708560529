import Highcharts from 'highcharts';
import { useEffect } from 'react';

const BASE_DEFAULT_BOOST_THRESHOLD = 5000; // Highcharts default value

function addBoost(chartOptions?: Highcharts.Options): void {
  if (!chartOptions) return;
  const boostThreshold = getBoostThreshold(chartOptions);

  if (!boostThreshold || isNaN(boostThreshold)) return;
  // boost does not support spline chart type
  if (chartOptions?.chart?.type == 'spline' && chartOptions.series) {
    chartOptions.chart.type = 'line';
    chartOptions.series.forEach((series) => {
      series.type = 'line';
    });
  }
  chartOptions.boost = {
    enabled: true, // will only be effective if series data length is above threshold
    seriesThreshold: boostThreshold,
    useGPUTranslations: true,
  };
}

function getBoostThreshold(chartOptions: Highcharts.Options): number | null {
  const defaultBoostThresholds: Record<string, number> = {
    line: BASE_DEFAULT_BOOST_THRESHOLD,
    column: BASE_DEFAULT_BOOST_THRESHOLD,
    columnrange: BASE_DEFAULT_BOOST_THRESHOLD,
    bar: BASE_DEFAULT_BOOST_THRESHOLD,
    area: BASE_DEFAULT_BOOST_THRESHOLD,
    arearange: BASE_DEFAULT_BOOST_THRESHOLD,
    areaspline: BASE_DEFAULT_BOOST_THRESHOLD,
    scatter: BASE_DEFAULT_BOOST_THRESHOLD,
    heatmap: BASE_DEFAULT_BOOST_THRESHOLD,
    treemap: BASE_DEFAULT_BOOST_THRESHOLD,
    bubble: BASE_DEFAULT_BOOST_THRESHOLD,
  };
  return defaultBoostThresholds[chartOptions?.chart?.type ?? ''] ?? null;
}

function useBoost(chartOptions?: Highcharts.Options): void {
  useEffect(() => {
    addBoost(chartOptions);
  }, [chartOptions]);
}

export { useBoost };
