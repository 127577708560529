import produce from 'immer';
import { FC } from 'react';

import { Input, sprinkles } from 'components/ds';
import { DEFAULT_COMPONENTS_CONFIG } from 'globalStyles/constants';
import { GlobalStyleConfig, GlobalStyleSingleNumberConfig } from 'globalStyles/types';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const SingleNumberConfig: FC<Props> = ({ styleConfig, updateConfig }) => {
  const paddingConfig =
    styleConfig.components?.singleNumber?.padding ??
    Math.round(styleConfig.container.padding.default / 2);

  const handleChange = (prop: Partial<GlobalStyleSingleNumberConfig>) => {
    const newConfig = produce(styleConfig, (draft) => {
      if (!draft.components) draft.components = { ...DEFAULT_COMPONENTS_CONFIG };

      draft.components.singleNumber = { ...draft.components.singleNumber, ...prop };
    });
    updateConfig(newConfig);
  };

  return (
    <div className={sprinkles({ padding: 'sp2' })}>
      <Input
        fillWidth
        className={sprinkles({ flex: 1 })}
        defaultValue={`${paddingConfig}px`}
        label="Padding"
        onSubmit={(paddingString) => {
          const padding = Number(paddingString.replace('px', ''));

          if (isNaN(padding) || padding < 0) return;
          handleChange({ padding });
        }}
        style={{ width: 55 }}
      />
    </div>
  );
};
