import { FC } from 'react';

import { Input, sprinkles } from 'components/ds';
import { ConfigSection } from 'components/resource/ConfigSection';
import * as sharedStyles from 'components/resource/index.css';
import { showErrorToast } from 'shared/sharedToasts';

type ValueParams = {
  name?: string;
  description?: string;
  provided_id?: string;
};

type Props = {
  defaultIsOpen: boolean;
  initialId?: string;
  initialDescription: string;
  initialName: string;

  handleNewValueSubmitted: (value: ValueParams) => void;
};

export const MetadataInputs: FC<Props> = ({
  defaultIsOpen,
  initialId,
  initialDescription,
  initialName,

  handleNewValueSubmitted,
}) => {
  return (
    <ConfigSection defaultIsOpen={defaultIsOpen} title="General">
      <div className={sharedStyles.configSection}>
        <div className={sprinkles({ display: 'flex', gap: 'sp1.5' })}>
          <Input
            fillWidth
            defaultValue={initialName}
            label={{
              text: 'Name',
              infoText: 'This is the name your customers will see in the template selection modal',
            }}
            onSubmit={(newName) => {
              const trimmedName = newName.trim();
              if (trimmedName === '') return showErrorToast('Name cannot be empty.');
              handleNewValueSubmitted({ name: trimmedName });
            }}
          />
          {initialId ? (
            <Input
              fillWidth
              defaultValue={initialId}
              label={{
                text: 'Provided ID',
                infoText: 'This is the variable that you reference in your dataset queries',
              }}
              onSubmit={(newId) => {
                const trimmedId = newId.trim();
                if (trimmedId === '') return showErrorToast('Provided ID cannot be empty.');
                handleNewValueSubmitted({ provided_id: trimmedId });
              }}
            />
          ) : null}
        </div>

        <Input
          fillWidth
          defaultValue={initialDescription}
          label={{
            text: 'Description',
            infoText: 'This is the description your customers will see',
          }}
          onSubmit={(newDescription) => handleNewValueSubmitted({ description: newDescription })}
        />
      </div>
    </ConfigSection>
  );
};
