import { FC } from 'react';

import FiltersImg from 'constants/images/report-builder/filters.gif';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ImageAndText } from 'pages/ReportBuilder/Onboarding/Blocks/ImageAndText';

export const FiltersDoc: FC = () => (
  <ImageAndText alt="Filters" img={FiltersImg}>
    <EmbedText body="b2">
      Filters allow you to look at more specific slices of your data. Each visualization can be
      filtered individually. Filters are tied to the columns you have included in your data
      selection (link to Data Selection info) and based off what you have included in your report,
      you may have access to the following filters:
      <ul>
        <li>Category filters</li>
        <li>Number filters</li>
        <li>Date filters</li>
      </ul>
    </EmbedText>
  </ImageAndText>
);
