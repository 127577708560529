import { FC } from 'react';

import { Input, Label } from 'components/ds';
import {
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
} from 'constants/dashboardConstants';
import { ChartShapeBorderFormat } from 'constants/types';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  chartShapeBorderConfig?: ChartShapeBorderFormat;
  updateConfig: (newConfig: ChartShapeBorderFormat) => void;
};

export const ChartShapeBorderConfig: FC<Props> = ({ chartShapeBorderConfig, updateConfig }) => {
  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(chartShapeBorderConfig?.borderWidth ?? chartShapeBorderDefaultWidth)}
        label="Shape border width"
        onSubmit={(newValue) => {
          const newNum = Number(newValue);
          if (isNaN(newNum)) return;

          updateConfig({ ...chartShapeBorderConfig, borderWidth: newNum });
        }}
      />
      <div className={configInputClass}>
        <Label htmlFor="">Shape border color</Label>
        <CustomStylesColorPicker
          fill
          color={chartShapeBorderConfig?.borderColor || ChartShapeBorderDefaultColor}
          onClose={(newColor) => updateConfig({ ...chartShapeBorderConfig, borderColor: newColor })}
        />
      </div>
    </div>
  );
};
