import { FC } from 'react';

import { NonIdealState } from 'components/ChartLayout/NonIdealState';
import { EmbedSpinner } from 'components/embed';
import { OP_TYPE_TO_BP3_ICON } from 'constants/dataConstants';
import { DEFAULT_NO_DATA_FONT_SIZE, NoDataConfig, OPERATION_TYPES } from 'constants/types';

type Props = {
  fullHeight?: boolean;
  loading?: boolean;
  noDataState?: NoDataConfig;
  operationType?: OPERATION_TYPES;
  processString: (s: string) => string;
};

export const NoDataPanel: FC<Props> = ({
  fullHeight,
  loading,
  noDataState,
  operationType,
  processString,
}) => (
  <NonIdealState
    fullHeight={fullHeight}
    icon={
      loading ? (
        <EmbedSpinner size="lg" />
      ) : noDataState?.hideChartIcon || !operationType ? undefined : (
        OP_TYPE_TO_BP3_ICON[operationType]
      )
    }
    title={
      <span
        style={{
          fontSize: noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
        }}>
        {processString(noDataState?.noDataText || 'No Data')}
      </span>
    }
  />
);
