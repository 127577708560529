import cx from 'classnames';
import { FC } from 'react';

import { sprinkles, Icon } from 'components/ds';
import { EmbedSpinner, EmbedInfoIcon } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type Props = {
  panelName: string;
  drilldownDatasetName?: string;
  loading?: boolean;
  infoTooltipText?: string;
};

export const PanelName: FC<Props> = ({
  panelName,
  drilldownDatasetName,
  loading,
  infoTooltipText,
}) => {
  const renderInfoIconAndLoadingSpinner = () => {
    return (
      <>
        {infoTooltipText ? <EmbedInfoIcon text={infoTooltipText} /> : null}
        {loading && <EmbedSpinner className={sprinkles({ marginLeft: 'sp3' })} size="md" />}
      </>
    );
  };

  if (drilldownDatasetName) {
    return (
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {panelName ? (
          <>
            <div className={sprinkles({ body: 'b1' })}> {panelName}</div>
            <Icon
              className={sprinkles({ color: 'contentTertiary', margin: 'sp1' })}
              name="chevron-right"
              size="sm"
            />
          </>
        ) : undefined}
        <div className={sprinkles({ heading: 'h3' })}> {drilldownDatasetName}</div>
        {renderInfoIconAndLoadingSpinner()}
      </div>
    );
  }

  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'alignCenter', truncateText: 'ellipsis', fontWeight: 700 }),
        embedSprinkles({ heading: 'h2' }),
      )}>
      {panelName}
      {renderInfoIconAndLoadingSpinner()}
    </div>
  );
};
