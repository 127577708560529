import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SharedYAxisConfigs } from 'components/ChartConfigs/YAxisConfigs';
import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  YAxisFormat,
} from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import {
  getYAxisFormat,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions | V2ScatterPlotInstructions;
  yAxisFormatId?: string;
};

export const YAxisConfig: FC<Props> = ({ visualizationType, instructions, yAxisFormatId }) => {
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions, visualizationType);

  const updateAxisFormat = (yAxisUpdates: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...yAxisUpdates };
    dispatch(
      updateVisualizeOperation(
        updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
        visualizationType,
      ),
    );
  };

  return (
    <div className={configRootClass}>
      <SharedYAxisConfigs
        configInputClass={configInputClass}
        isNormalized={
          visualizationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
          visualizationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2
        }
        updateAxisFormat={updateAxisFormat}
        visualizationType={visualizationType}
        yAxisFormat={yAxisFormat}
      />
    </div>
  );
};
