import { RumInitConfiguration } from '@datadog/browser-rum';

import { getEnvironment } from 'utils/environmentUtils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM: any;
  }
}

const shouldInitializeDataDog =
  !!window.DD_RUM &&
  ['staging', 'production'].includes(getEnvironment()) &&
  process.env.REACT_APP_ENVIRONMENT !== 'production-medme';

const shouldReportErrors =
  shouldInitializeDataDog &&
  // in the embed, it's possible that the embedder has initialized datadog, so we
  // need to make sure to only report if it's our datadog
  window.DD_RUM?.getInitConfiguration?.()?.service === 'embeddo';

const initDataDog = () => {
  if (!shouldInitializeDataDog) return;

  const sampleRate = getEnvironment() === 'staging' ? 100 : 10;

  const sharedConfig = {
    site: 'datadoghq.com',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    // This needs to match EMBEDDO_ENVIRONMENT in Heroku
    // REACT_APP_ENVIRONMENT should match in every case except for preview envs
    // REACT_APP_ENV_TAG is generated for preview envs only
    env: process.env.REACT_APP_ENV_TAG ?? process.env.REACT_APP_ENVIRONMENT,
    version: process.env.PKG_VERSION,
    sampleRate,
    allowedTracingOrigins: [process.env.REACT_APP_API_URL ?? ''],
    tracingSampleRate: sampleRate,
  };

  const appConfig: RumInitConfiguration = {
    ...sharedConfig,
    applicationId: '465ae197-1316-43f4-b293-a8ba17e7e945',
    clientToken: 'pub4332059a181bb617916141bd0751432b',
    service: 'embeddo',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  window.DD_RUM.init(appConfig);
  window.DD_RUM.startSessionReplayRecording();
};

type RumUser = {
  email?: string;
  endUserId?: number;
  endUserName?: string;
  teamId?: number;
  teamName?: string;
};

const setUser = (user: RumUser) => {
  if (!shouldReportErrors) return;

  window.DD_RUM.setUser(user);
};

const clearUser = () => {
  if (!shouldReportErrors) return;

  window.DD_RUM.clearUser();
};

const reportError = (error: unknown, errorInfo?: object | undefined) => {
  if (!shouldReportErrors) return;

  window.DD_RUM.addError(error, { ...errorInfo, manual: true });
};

export { initDataDog, reportError, setUser, clearUser };
