import cx from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import { TOOLTIP_SIDE, TOOLTIP_SIDE_OFFSET } from './constants';
import * as styles from './styles.css';

export type NavItemInfo = {
  id: string;
  href: string;
  name: string;
  additionalInfo?: string;
  selectedIcon?: IconName;
  icon: IconName;
};

type Props = {
  navItemInfo: NavItemInfo;
  isSelected?: boolean;
  isExpanded?: boolean;
};

export const SidebarItem: FC<Props> = ({ navItemInfo, isSelected, isExpanded }) => {
  const iconName =
    isSelected && navItemInfo.selectedIcon ? navItemInfo.selectedIcon : navItemInfo.icon;

  const renderSidebarItem = () => {
    return (
      <Link
        className={cx({
          [styles.selectedItem]: isSelected,
          [styles.hoverableItem]: !isSelected,
          [styles.sidebarItemOpen]: isExpanded,
          [styles.sidebarItemClosed]: !isExpanded,
        })}
        to={navItemInfo.href}>
        <div className={styles.sidebarItemRoot}>
          <div className={styles.sidebarItemIconName}>
            <Icon className={styles.leftIcon} name={iconName} />
            {isExpanded ? <p className={styles.itemText}>{navItemInfo.name}</p> : null}
          </div>
          {navItemInfo?.additionalInfo && isExpanded ? (
            <p className={styles.itemAdditionInfoText}>{navItemInfo?.additionalInfo}</p>
          ) : null}
        </div>
      </Link>
    );
  };
  return isExpanded ? (
    renderSidebarItem()
  ) : (
    <Tooltip side={TOOLTIP_SIDE} sideOffset={TOOLTIP_SIDE_OFFSET} text={navItemInfo.name}>
      {renderSidebarItem()}
    </Tooltip>
  );
};
