import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { DashboardButtonElemConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';

type Props = {
  config: DashboardButtonElemConfig;
  datasetNamesToId: Record<string, string>;
};

export const DashboardRefreshElement: FC<Props> = ({ config, datasetNamesToId }) => {
  const dispatch = useDispatch();
  const iconOnly = config.buttonText?.trim() == '';

  const buttonText = useStringWithVariablesReplaced(
    config.buttonText || 'Refresh Dashboard',
    datasetNamesToId,
  );

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <EmbedButton
        fillWidth
        icon={config.hideRefreshIcon ? undefined : 'refresh'}
        onClick={() => {
          dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
        }}
        variant="primary">
        {!iconOnly ? <div className={buttonTextClass}>{buttonText}</div> : null}
      </EmbedButton>
    </div>
  );
};

const buttonTextClass = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
  truncateText: 'ellipsis',
  flex: 1,
});
