import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { TablePDFFormat, SECTION_OPTIONS } from 'constants/types';

type Props = {
  pdfConfig: TablePDFFormat;
  processString: (string: string) => string;
};

export const PDFHeader: FC<Props> = ({ pdfConfig, processString }) => {
  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenterBetween',
        marginBottom: 'sp2',
        overflow: 'hidden',
      })}
      style={{ height: 40 }}>
      <PDFHeaderSection
        sectionContent={processString(pdfConfig.leftContent || '')}
        sectionType={pdfConfig.leftOption}
      />
      <PDFHeaderSection
        className={sprinkles({ justifyContent: 'center' })}
        sectionContent={processString(pdfConfig.centerContent || '')}
        sectionType={pdfConfig.centerOption}
      />
      <PDFHeaderSection
        className={sprinkles({ justifyContent: 'flex-end' })}
        sectionContent={processString(pdfConfig.rightContent || '')}
        sectionType={pdfConfig.rightOption}
      />
    </div>
  );
};

type SectionProps = {
  className?: string;
  sectionType: SECTION_OPTIONS | undefined;
  sectionContent: string;
};

const PDFHeaderSection: FC<SectionProps> = ({ className, sectionType, sectionContent }) => {
  return (
    <div
      className={cx(sprinkles({ flexItems: 'alignCenter', height: 'fill' }), className)}
      style={{ width: '30%' }}>
      {sectionType === SECTION_OPTIONS.IMAGE ? (
        <img alt="section" className={sprinkles({ height: 'fill' })} src={sectionContent} />
      ) : null}
      {sectionType === SECTION_OPTIONS.TEXT ? sectionContent : null}
    </div>
  );
};
