import { Draft } from 'immer';

import { SelectElemConfig } from 'types/dashboardTypes';

export function getValuePlaceholderText(isBooleanFilterOperator: boolean, isToggle: boolean) {
  if (isBooleanFilterOperator) {
    return BOOLEAN_FILTER_PLACEHOLDER_TEXT;
  } else if (isToggle) {
    return TOGGLE_PLACEHOLDER_TEXT;
  }

  return DEFAULT_PLACEHOLDER_TEXT;
}

export function getValueInputButtonClickFn(
  isBooleanFilterOperator: boolean,
  updateConfig: (updateFn: (draft: Draft<SelectElemConfig>) => void) => void,
) {
  // For boolean operators, the input button is a swap button which swaps the first and second
  // values. For all other types, use the default input button handlers which set the value when
  // focused and clear when unfocused.
  return isBooleanFilterOperator
    ? (inputValue: string) => {
        updateConfig((config) => {
          swapBooleanFilterValue(inputValue, config);
        });
      }
    : undefined;
}

export function swapBooleanFilterValue(filterValue: string, config: Draft<SelectElemConfig>) {
  validateBooleanFilterValue(filterValue);

  const commaIndex = filterValue.indexOf(',');

  if (commaIndex === -1) {
    throw new Error('No comma found in input value');
  }

  // Swap the first and second values within the stringified array.
  const firstValue = filterValue.slice(1, commaIndex).trim();
  const secondValue = filterValue.slice(commaIndex + 1, filterValue.length - 1).trim();
  config.valuesConfig.manualValues = `[${secondValue}, ${firstValue}]`;
}

function validateBooleanFilterValue(filterValue: string) {
  if (filterValue[0] !== LEFT_ARRAY_BRACKET) {
    throw Error('Invalid boolean filter value: missing left array bracket');
  } else if (filterValue[filterValue.length - 1] !== RIGHT_ARRAY_BRACKET) {
    throw Error('Invalid boolean filter value: missing right array bracket');
  }
}

const LEFT_ARRAY_BRACKET = '[';

const RIGHT_ARRAY_BRACKET = ']';

const BOOLEAN_FILTER_PLACEHOLDER_TEXT = '["true", "false"]';

const TOGGLE_PLACEHOLDER_TEXT = '["day", "week", "month"]';

const DEFAULT_PLACEHOLDER_TEXT = '["One", "Two", "Three"]';
