import { FC } from 'react';

import { Select, ToggleItem, sprinkles, Toggle } from 'components/ds';
import { EMAIL_FREQUENCY } from 'constants/types';

type Props = {
  frequency: EMAIL_FREQUENCY;
  dayOfWeek: number | undefined;
  weekOfMonth: number | undefined;
  onSetDayOfWeek: (dayOfWeek: number) => void;
  onSetWeekOfMonth: (weekOfMonth: number) => void;
};

const WEEKS_OF_MONTH = ['First', 'Second', 'Third', 'Fourth', 'Last'];
const DAYS_OF_WEEK = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

const WEEKS_OF_MONTH_OPTIONS = WEEKS_OF_MONTH.map((week, index) => ({
  value: String(index),
  label: week,
}));

export const EmailDaySelection: FC<Props> = ({
  frequency,
  dayOfWeek,
  weekOfMonth,
  onSetDayOfWeek,
  onSetWeekOfMonth,
}) => {
  return (
    <div className={sprinkles({ flexItems: 'alignCenterBetween', gap: 'sp1', marginTop: 'sp1' })}>
      {frequency === EMAIL_FREQUENCY.MONTHLY ? (
        <Select
          className={sprinkles({ flex: 1 })}
          onChange={(newWeek) => onSetWeekOfMonth(Number(newWeek))}
          selectedValue={weekOfMonth?.toString()}
          values={WEEKS_OF_MONTH_OPTIONS}
        />
      ) : null}
      <Toggle
        className={sprinkles({ flex: 5 })}
        onValueChange={(newDay) => onSetDayOfWeek(Number(newDay))}
        selectedValue={dayOfWeek?.toString()}>
        {DAYS_OF_WEEK.map((weekday, index) => (
          <ToggleItem key={weekday} value={String(index)}>
            {weekday}
          </ToggleItem>
        ))}
      </Toggle>
    </div>
  );
};
