import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { selectBuiltInReport } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import {
  clearEmbeddedReportBuilderReducer,
  setVersionForInAppPreview,
  setAiEnabled,
  setIsPreview,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import {
  clearSelectedReport,
  openBuiltInForEdit,
} from 'reportBuilderContent/reducers/reportEditingReducer';

// Thunks for preview mode in app

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const setUpReportBuilderPreview = (): Thunk => (dispatch) => {
  dispatch(setIsPreview(true));
  dispatch(setVersionForPreviewThunk());
};

export const setVersionForPreviewThunk = (): Thunk => (dispatch, getState) => {
  const {
    reportBuilderEdit: { versionInfo, config },
    currentUser: { team },
  } = getState();
  if (!versionInfo || !RD.isSuccess(config)) return;

  // In embedded, the user/team are loaded along with customer reports,
  // but when editing we need to sync the team's feature flag to the embeddedReportBuilderReducer
  dispatch(setAiEnabled(!!team?.entitlements.report_builder_ai_enabled));
  dispatch(
    setVersionForInAppPreview({ config: config.data, version_number: versionInfo.version_number }),
  );
};

export const clearReportBuilderPreview = (): Thunk => (dispatch) => {
  dispatch(clearSelectedReport());
  dispatch(clearEmbeddedReportBuilderReducer());
};

export const selectBuiltInForEdit =
  (builtIn: BuiltInReportConfig): Thunk =>
  (dispatch) => {
    dispatch(selectBuiltInReport(builtIn.id));
    dispatch(openBuiltInForEdit(builtIn));
  };
