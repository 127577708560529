export const EXPLO_HOMEPAGE_LOGO = () => (
  <svg fill="none" height="24" viewBox="0 0 87 24" width="87" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.2605 18.3315H39.9315V20.2154H32.9121V4.06055H39.7508V5.94442H35.2605V10.9251H38.9766V12.8089H35.2605V18.3315ZM48.674 11.9573L52.1321 20.2154H49.5514L47.3837 14.0735L45.2159 20.2154H42.7127L46.1192 11.9573L42.945 4.06055H45.5256L47.3837 9.867L49.2675 4.06055H51.7966L48.674 11.9573ZM55.772 4.06055H61.0623C62.6623 4.06055 63.4623 4.81754 63.4623 6.33151V11.6993C63.4623 13.2132 62.6623 13.9702 61.0623 13.9702H58.0946V20.2154H55.772V4.06055ZM61.1397 11.338V6.69281C61.1397 6.38313 61.0881 6.18528 60.9849 6.09926C60.8817 5.99603 60.6752 5.94442 60.3655 5.94442H58.0946V12.1122H60.3655C60.6752 12.1122 60.8817 12.0605 60.9849 11.9573C61.0881 11.8541 61.1397 11.6476 61.1397 11.338ZM70.0411 18.3315H74.0411V20.2154H67.7186V4.06055H70.0411V18.3315ZM79.9709 4.06055H82.8355C84.4355 4.06055 85.2355 4.81754 85.2355 6.33151V17.9444C85.2355 19.4584 84.4355 20.2154 82.8355 20.2154H79.9709C78.3537 20.2154 77.5451 19.4584 77.5451 17.9444V6.33151C77.5451 4.81754 78.3537 4.06055 79.9709 4.06055ZM82.9129 17.5831V6.69281C82.9129 6.38313 82.8613 6.18528 82.758 6.09926C82.6548 5.99603 82.4484 5.94442 82.1387 5.94442H80.6419C80.3322 5.94442 80.1258 5.99603 80.0225 6.09926C79.9365 6.18528 79.8935 6.38313 79.8935 6.69281V17.5831C79.8935 17.8928 79.9365 18.0993 80.0225 18.2025C80.1258 18.2885 80.3322 18.3315 80.6419 18.3315H82.1387C82.4484 18.3315 82.6548 18.2885 82.758 18.2025C82.8613 18.0993 82.9129 17.8928 82.9129 17.5831Z"
      fill="#0A2979"
    />
    <path
      d="M35.2605 18.3315H39.9315V20.2154H32.9121V4.06055H39.7508V5.94442H35.2605V10.9251H38.9766V12.8089H35.2605V18.3315ZM48.674 11.9573L52.1321 20.2154H49.5514L47.3837 14.0735L45.2159 20.2154H42.7127L46.1192 11.9573L42.945 4.06055H45.5256L47.3837 9.867L49.2675 4.06055H51.7966L48.674 11.9573ZM55.772 4.06055H61.0623C62.6623 4.06055 63.4623 4.81754 63.4623 6.33151V11.6993C63.4623 13.2132 62.6623 13.9702 61.0623 13.9702H58.0946V20.2154H55.772V4.06055ZM61.1397 11.338V6.69281C61.1397 6.38313 61.0881 6.18528 60.9849 6.09926C60.8817 5.99603 60.6752 5.94442 60.3655 5.94442H58.0946V12.1122H60.3655C60.6752 12.1122 60.8817 12.0605 60.9849 11.9573C61.0881 11.8541 61.1397 11.6476 61.1397 11.338ZM70.0411 18.3315H74.0411V20.2154H67.7186V4.06055H70.0411V18.3315ZM79.9709 4.06055H82.8355C84.4355 4.06055 85.2355 4.81754 85.2355 6.33151V17.9444C85.2355 19.4584 84.4355 20.2154 82.8355 20.2154H79.9709C78.3537 20.2154 77.5451 19.4584 77.5451 17.9444V6.33151C77.5451 4.81754 78.3537 4.06055 79.9709 4.06055ZM82.9129 17.5831V6.69281C82.9129 6.38313 82.8613 6.18528 82.758 6.09926C82.6548 5.99603 82.4484 5.94442 82.1387 5.94442H80.6419C80.3322 5.94442 80.1258 5.99603 80.0225 6.09926C79.9365 6.18528 79.8935 6.38313 79.8935 6.69281V17.5831C79.8935 17.8928 79.9365 18.0993 80.0225 18.2025C80.1258 18.2885 80.3322 18.3315 80.6419 18.3315H82.1387C82.4484 18.3315 82.6548 18.2885 82.758 18.2025C82.8613 18.0993 82.9129 17.8928 82.9129 17.5831Z"
      fill="white"
    />
    <mask
      height="24"
      id="mask0_1312_13737"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="24"
      x="0"
      y="0">
      <path
        d="M11.9994 23.9988C18.6265 23.9988 23.9988 18.6265 23.9988 11.9994C23.9988 5.37231 18.6265 0 11.9994 0C5.37231 0 0 5.37231 0 11.9994C0 18.6265 5.37231 23.9988 11.9994 23.9988Z"
        fill="#115BB8"
      />
    </mask>
    <g mask="url(#mask0_1312_13737)">
      <path
        d="M11.7337 24.3024C18.5053 24.3024 23.9948 18.813 23.9948 12.0413C23.9948 5.26974 18.5053 -0.219727 11.7337 -0.219727C4.96213 -0.219727 -0.527344 5.26974 -0.527344 12.0413C-0.527344 18.813 4.96213 24.3024 11.7337 24.3024Z"
        fill="#0B3D91"
      />
      <path
        d="M18.7874 20.9078C26.9629 20.9078 33.5904 14.2802 33.5904 6.10477C33.5904 -2.07071 26.9629 -8.69824 18.7874 -8.69824C10.6119 -8.69824 3.98438 -2.07071 3.98438 6.10477C3.98438 14.2802 10.6119 20.9078 18.7874 20.9078Z"
        fill="#0053BB"
      />
      <path
        d="M20.1052 15.5542C25.7207 15.5542 30.273 11.002 30.273 5.38649C30.273 -0.228997 25.7207 -4.78125 20.1052 -4.78125C14.4898 -4.78125 9.9375 -0.228997 9.9375 5.38649C9.9375 11.002 14.4898 15.5542 20.1052 15.5542Z"
        fill="#0069ED"
      />
      <path
        d="M19.969 2.77018C19.965 2.77378 19.9611 2.7775 19.9573 2.78134L6.91759 16.0745C6.82596 16.168 6.81874 16.3151 6.90079 16.4171C6.98284 16.519 7.12818 16.5434 7.23901 16.4739L22.6334 6.81127C22.9406 6.66937 23.2148 6.46535 23.4387 6.21372C23.7702 5.84113 23.9912 5.36385 24.0441 4.83009C24.1773 3.48503 23.1942 2.28691 21.8486 2.15369C21.1351 2.08306 20.4626 2.32625 19.969 2.77018Z"
        fill="#FC3D21"
        stroke="#FC3D21"
        strokeLinejoin="round"
        strokeWidth="0.691849"
      />
    </g>
    <path
      d="M19.969 2.77018C19.965 2.77378 19.9611 2.7775 19.9573 2.78134L6.91759 16.0745C6.82596 16.168 6.81874 16.3151 6.90079 16.4171C6.98284 16.519 7.12818 16.5434 7.23901 16.4739L22.6334 6.81127C22.9406 6.66937 23.2148 6.46535 23.4387 6.21372C23.7702 5.84113 23.9912 5.36385 24.0441 4.83009C24.1773 3.48503 23.1942 2.28691 21.8486 2.15369C21.1351 2.08306 20.4626 2.32625 19.969 2.77018Z"
      fill="#FC3D21"
      stroke="#FC3D21"
      strokeLinejoin="round"
      strokeWidth="0.691849"
    />
  </svg>
);
