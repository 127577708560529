import cx from 'classnames';
import { FC, DetailedHTMLProps, HTMLAttributes, useState, useEffect, ReactNode } from 'react';

import { sprinkles } from 'components/ds';
import { Tab } from 'components/ds/Tabs/Tab';

import { Icon, IconName } from '../Icon';

import * as styles from './index.css';

type HTMLDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;

export type TabOption = {
  id: string;
  icon: IconName;
  tooltip?: string;
};

export type Props = HTMLDivProps & {
  hideUnderline?: boolean;
  tabs: string[] | TabOption[];
  selectedTabId?: string;
  onTabSelect: (tabId: string) => void;
};

export const Tabs: FC<Props> = ({
  className,
  hideUnderline,
  selectedTabId,
  tabs,
  onTabSelect,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(selectedTabId ?? getTabId(tabs[0]));

  useEffect(() => {
    setSelectedTab(selectedTabId ?? getTabId(tabs[0]));
  }, [selectedTabId, tabs]);

  const handleClick = (tab: string) => {
    if (tab === selectedTab) return;
    setSelectedTab(tab);
    onTabSelect(tab);
  };

  return (
    <div
      {...props}
      className={cx(
        hideUnderline ? sprinkles({ flexItems: 'alignCenter' }) : styles.navTabs,
        className,
      )}>
      {tabs.map((tab) => {
        const tabId = getTabId(tab);
        return (
          <Tab
            isSelected={selectedTab === tabId}
            key={tabId}
            onClick={() => handleClick(tabId)}
            tooltip={getTabTooltip(tab)}>
            {getTabBody(tab)}
          </Tab>
        );
      })}
    </div>
  );
};

const getTabId = (tab: string | TabOption): string => (typeof tab === 'string' ? tab : tab.id);

const getTabBody = (tab: string | TabOption): ReactNode => (
  <div className={sprinkles({ paddingX: 'sp2' })}>
    {typeof tab === 'string' ? tab : <Icon name={tab.icon} />}
  </div>
);
const getTabTooltip = (tab: string | TabOption): string | undefined =>
  typeof tab === 'string' ? undefined : tab.tooltip;
