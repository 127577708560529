import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ReduxState } from 'reducers/rootReducer';
import { ComponentSection, setOpenComponentSection } from 'reducers/styleConfigReducer';

import { ButtonConfig } from './ButtonConfig';
import { ModalConfig } from './ModalConfig';
import { PanelErrorConfig } from './PanelErrorConfig';
import { SingleNumberConfig } from './SingleNumberConfig';
import { SpinnerConfig } from './SpinnerConfig';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const ComponentConfigs: FC<Props> = ({ styleConfig, updateConfig }) => {
  const dispatch = useDispatch();
  const openSection = useSelector((state: ReduxState) => state.styleConfig.openComponentSection);

  const onOpen = (section: ComponentSection) => (isOpen: boolean) =>
    dispatch(setOpenComponentSection(isOpen ? section : null));

  return (
    <>
      <ConfigSection
        icon="spinner"
        isOpen={openSection === ComponentSection.Spinner}
        onOpen={onOpen(ComponentSection.Spinner)}
        title="Loading Spinner"
        variant="header1">
        <SpinnerConfig styleConfig={styleConfig} updateConfig={updateConfig} />
      </ConfigSection>
      <ConfigSection
        icon="circle-exclamation"
        isOpen={openSection === ComponentSection.PanelError}
        onOpen={onOpen(ComponentSection.PanelError)}
        title="Panel Error"
        variant="header1">
        <PanelErrorConfig styleConfig={styleConfig} updateConfig={updateConfig} />
      </ConfigSection>
      <ConfigSection
        icon="square-b"
        isOpen={openSection === ComponentSection.Button}
        onOpen={onOpen(ComponentSection.Button)}
        title="Button"
        variant="header1">
        <ButtonConfig styleConfig={styleConfig} updateConfig={updateConfig} />
      </ConfigSection>
      <ConfigSection
        icon="square-m"
        isOpen={openSection === ComponentSection.Modal}
        onOpen={onOpen(ComponentSection.Modal)}
        title="Modal"
        variant="header1">
        <ModalConfig styleConfig={styleConfig} updateConfig={updateConfig} />
      </ConfigSection>
      <ConfigSection
        icon="report-builder-kpi"
        isOpen={openSection === ComponentSection.SingleNumber}
        onOpen={onOpen(ComponentSection.SingleNumber)}
        title="KPI"
        variant="header1">
        <SingleNumberConfig styleConfig={styleConfig} updateConfig={updateConfig} />
      </ConfigSection>
    </>
  );
};
