import cx from 'classnames';
import produce from 'immer';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  FetchDatasetRowCountBody,
  listTeamDataSources,
  fetchDatasetPreview,
  fetchDatasetRowCount,
} from 'actions/dataSourceActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { fetchDatasets, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { ACTION } from 'actions/types';
import { pageView, trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { ErrorState } from 'components/ErrorState';
import { Poller } from 'components/JobQueue/Poller';
import { Jobs } from 'components/JobQueue/types';
import { DataGrid, Select, Spinner, Icon, Intent, Button, sprinkles, Tooltip } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { ROUTES } from 'constants/routes';
import { getTeamDataSources } from 'reducers/dataSourceReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { showCustomToast } from 'shared/sharedToasts';
import { parseErrorMessage } from 'utils/queryUtils';
import { sortBy } from 'utils/standard';

const SIDEBAR_WIDTH = 250;

export const ManageDataTablesPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { dataSourceId } = useParams<{ dataSourceId: string }>();

  const [selectedDataSourceId, setSelectedDataSourceId] = useState(dataSourceId);
  const [selectedSchemaId, setSelectedSchemaId] = useState<number>();
  const [selectedDatasetId, setSelectedDatasetId] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, datasets, parentSchemas, dataSources, datasetData, shouldUseJobQueue } =
    useSelector((state: ReduxState) => ({
      loading: RD.isLoadingMulti(
        [
          state.parentSchemas.usedParentSchemas,
          state.dataSource.dataSources,
          state.datasets.datasets,
        ],
        true,
      ),
      datasets: state.datasets.datasets,
      parentSchemas: getParentSchemasList(state),
      dataSources: getTeamDataSources(state),
      datasetData: state.datasets.datasetData,
      shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
    }));

  useEffect(() => {
    pageView('Manage Data Tables');
    document.title = 'Explo | Manage Data';
  }, []);

  const bulkEnqueueJobsWrapper = useCallback(
    (jobs: JobDefinition[] | undefined) => {
      if (jobs === undefined || jobs.length === 0) return;

      const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

      dispatch(
        bulkEnqueueJobs({ jobs: jobMap }, (jobs) => {
          setAwaitedJobs({ ...awaitedJobs, ...jobs });
        }),
      );
    },
    [dispatch, awaitedJobs],
  );

  const fetchDatasetWrapper = useCallback(
    (postData: FetchDatasetRowCountBody, offset?: number) => {
      if (!offset) setCurrentPage(1);

      const previewPostData = { ...postData, offset };
      if (!shouldUseJobQueue) {
        dispatch(fetchDatasetPreview({ postData: previewPostData }));
        if (!offset) dispatch(fetchDatasetRowCount({ postData }));
      } else {
        const jobs: JobDefinition[] = [
          {
            job_type: ACTION.FETCH_DATASET_PREVIEW,
            job_args: { ...previewPostData, id: selectedDatasetId },
          },
        ];
        if (!offset) {
          jobs.push({
            job_type: ACTION.FETCH_DATASET_ROW_COUNT,
            job_args: { ...postData, id: selectedDatasetId },
          });
        }
        bulkEnqueueJobsWrapper(jobs);
      }
    },
    [dispatch, bulkEnqueueJobsWrapper, shouldUseJobQueue, selectedDatasetId],
  );

  useEffect(() => {
    if (selectedSchemaId) return;
    dispatch(fetchUsedParentSchemas());
    const dataSourceInt = parseInt(dataSourceId);
    dispatch(
      listTeamDataSources(
        {},
        (results) => {
          const selectedDataSource = results.dataSources.find((ds) => ds.id === dataSourceInt);
          if (!selectedDataSource) return;
          setSelectedSchemaId(selectedDataSource.parent_schema_id);

          dispatch(
            fetchDatasets({ id: selectedDataSource.parent_schema_id }, (results) => {
              const dataset = sortBy(results.datasets, (dataset) => dataset.table_name)[0];
              if (!dataset) return;

              setSelectedDatasetId(dataset.id);
              fetchDatasetWrapper({
                data_source_id: dataSourceId,
                dataset_id: dataset.id,
              });
            }),
          );
        },
        (error) => setErrorMessage(error.error_msg),
      ),
    );
  }, [dispatch, dataSourceId, selectedSchemaId, fetchDatasetWrapper]);

  useEffect(() => {
    const isEmpty = RD.isSuccess(datasets) ? Object.keys(datasets.data).length === 0 : false;
    if (!isEmpty) return;

    showCustomToast(
      <div>
        This schema has no source tables.
        <br />
        <br />
        Consider sync source tables for updated schema at the following link:
        <ul>
          <li>
            <a href={`/sync-tables/${selectedSchemaId}`} rel="noopener noreferrer" target="_blank">
              {`https://app.explo.co/sync-tables/${selectedSchemaId}`}
            </a>
          </li>
        </ul>
      </div>,
      { timeoutInSeconds: 10, icon: 'info-sign', intent: Intent.SUCCESS },
    );
  }, [selectedSchemaId, datasets]);

  if (RD.isError(datasets)) return <ErrorState text={datasets.error} />;
  if (errorMessage) return <ErrorState text={errorMessage} />;

  const renderDatasetsSidebar = () => {
    if (loading || !selectedSchemaId || !dataSourceId) {
      return <Spinner fillContainer size="lg" />;
    }

    const schemaName =
      parentSchemas.find((schema) => schema.id === (selectedSchemaId ?? -1))?.name ?? '';
    const schemaDataSources = dataSources
      ? dataSources.filter((ds) => ds.parent_schema_id === selectedSchemaId)
      : [];

    const sourceDatasetsOrdered = RD.isSuccess(datasets)
      ? sortBy(Object.values(datasets.data), (dataset) => dataset.table_name)
      : [];

    return (
      <>
        <div className={sprinkles({ padding: 'sp1' })}>
          <span className={sprinkles({ heading: 'h4' })}>{schemaName.toUpperCase()}</span>
          <Select
            className={sprinkles({ marginTop: 'sp1' })}
            onChange={(value) => {
              if (!selectedDatasetId) return;
              history.replace(`/datasources/${value}`);
              setSelectedDataSourceId(dataSourceId);

              fetchDatasetWrapper({
                data_source_id: dataSourceId,
                dataset_id: selectedDatasetId,
              });
            }}
            placeholder="Select DataSource"
            selectedValue={dataSourceId}
            values={schemaDataSources.map((dataSource) => ({
              value: String(dataSource.id),
              label: dataSource.name,
            }))}
          />
        </div>
        <div
          className={sprinkles({ backgroundColor: 'outline', marginY: 'sp.5' })}
          style={{ minHeight: 1 }}
        />
        <div
          className={sprinkles({
            padding: 'sp1',
            flex: 1,
            flexItems: 'column',
            overflow: 'hidden',
          })}>
          <div className={sprinkles({ heading: 'h4', marginBottom: 'sp1' })}>Source Datasets</div>
          <div className={sprinkles({ flexItems: 'column', overflowY: 'auto', flex: 1 })}>
            {sourceDatasetsOrdered.map((dataset) => {
              const selected = selectedDatasetId === dataset.id;
              const tableName =
                !dataset.table_name || dataset.table_name.length === 0
                  ? 'Untitled'
                  : dataset.table_name;
              return (
                <Tooltip key={dataset.id} side="right" text={tableName}>
                  <div
                    className={cx(
                      datasetItemClass,
                      sprinkles({
                        color: selected ? 'white' : 'contentPrimary',
                        backgroundColor: selected ? 'active' : { hover: 'elevationLow' },
                      }),
                    )}
                    onClick={() => {
                      if (selected) return;
                      fetchDatasetWrapper({
                        data_source_id: selectedDataSourceId,
                        dataset_id: dataset.id,
                      });

                      setSelectedDatasetId(dataset.id);
                      trackEvent(EVENTS.SELECTED_DATASET, {
                        dataset_id: dataset.id,
                        dataset_name: tableName,
                      });
                    }}>
                    {tableName}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderDatasetViewer = () => {
    const selectedDataset =
      selectedDatasetId && RD.isSuccess(datasets) ? datasets.data[selectedDatasetId] : undefined;
    if (!selectedDataset) return <DataGrid loading />;

    const data = datasetData[selectedDataset.id];

    if (data?.error) {
      const errorDescription = parseErrorMessage(data.error);
      return (
        <div
          className={sprinkles({ backgroundColor: 'white', flexItems: 'center', height: 'fill' })}>
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2' })}>
            <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="xl" />
            <div className={sprinkles({ heading: 'h2' })}>
              There was an error fetching the results
            </div>
            {errorDescription ? (
              <div className={sprinkles({ body: 'b2' })}>{errorDescription}</div>
            ) : null}
            <Button
              icon="arrow-up-right-from-square"
              onClick={() => alert(data.error)}
              variant="tertiary">
              View full error
            </Button>
          </div>
        </div>
      );
    }

    const isLoading = loading || !data || data.loading;

    const paginatorProps: PaginatorProps = {
      totalRowCount: data?.totalRowCount,
      currentPage: currentPage,
      loading: isLoading,
      isPagingDisabled: isPagingDisabled(data?.unsupportedOperations),
      goToPage: ({ page, offset }) => {
        fetchDatasetWrapper(
          {
            data_source_id: dataSourceId,
            dataset_id: selectedDataset.id,
          },
          offset,
        );
        setCurrentPage(page);
      },
    };

    return (
      <DataGrid
        loading={isLoading}
        paginatorProps={paginatorProps}
        rows={data?.rows ?? []}
        schema={data?.schema ?? []}
      />
    );
  };

  const datasetsIsEmpty = Object.keys(datasets).length === 0;
  return (
    <div className={rootClass}>
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) {
            const newAwaitedJobs = produce(awaitedJobs, (draft) =>
              finishedJobIds.forEach((jobId) => delete draft[jobId]),
            );
            setAwaitedJobs(newAwaitedJobs);
          }

          onComplete();
        }}
      />
      <div className={sidebarClass} style={{ width: SIDEBAR_WIDTH }}>
        {renderDatasetsSidebar()}
      </div>
      <div className={sprinkles({ parentContainer: 'fill', flex: 1, overflow: 'auto' })}>
        {datasetsIsEmpty && !loading ? (
          <div className={unSyncedTablesClass}>
            <Link to={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${selectedSchemaId}`}>
              <div>No source datasets were found, you may need to first sync your tables.</div>
            </Link>
          </div>
        ) : null}
        {!datasetsIsEmpty ? renderDatasetViewer() : null}
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'alignCenter',
  justifyContent: 'flex-start',
  overflow: 'hidden',
});

const sidebarClass = sprinkles({
  height: 'fill',
  backgroundColor: 'white',
  overflow: 'hidden',
  borderRight: 1,
  borderColor: 'outline',
  flexItems: 'column',
});

const unSyncedTablesClass = sprinkles({
  flexItems: 'center',
  textAlign: 'center',
  parentContainer: 'fill',
  heading: 'h4',
});

const datasetItemClass = sprinkles({
  cursor: 'pointer',
  borderRadius: 8,
  height: 32,
  flexItems: 'alignCenter',
  paddingX: 'sp1',
  truncateText: 'ellipsis',
  minHeight: 32,
});
