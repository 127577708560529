import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { Switch, sprinkles, Input, Label } from 'components/ds';
import { DATE_TYPES } from 'constants/dataConstants';
import { OPERATION_TYPES, VisualizePivotTableInstructions } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { DateFormatDescriptiveText } from '../DateFormatDescriptiveText';

type Props = {
  instructions: VisualizePivotTableInstructions;
  globalStyleConfig: GlobalStyleConfig;
};

export const PivotTableRowsConfig: FC<Props> = ({ instructions, globalStyleConfig }) => {
  const dispatch = useDispatch();

  const updatePivotTableInstructions = (
    update: (pivotTableUpdates: VisualizePivotTableInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, update);

    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIVOT_TABLE));
  };

  const rowColType = instructions?.rowColumn?.column?.type;

  return (
    <div className={sprinkles({ marginTop: 'sp1.5' })}>
      <Switch
        className={configInputClass}
        label="Display Sum Row"
        onChange={() => {
          updatePivotTableInstructions((draft) => {
            draft.displaySumRow = !draft.displaySumRow;
          });
        }}
        switchOn={instructions.displaySumRow}
      />
      {DATE_TYPES.has(rowColType ?? '') ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={instructions?.dateFormat}
            label="Date Format"
            onSubmit={(newValue) =>
              updatePivotTableInstructions((draft) => {
                draft.dateFormat = newValue.trim() === '' ? undefined : newValue;
              })
            }
          />
          <DateFormatDescriptiveText />
        </>
      ) : null}
      {instructions.displaySumRow ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={instructions.sumRowText || 'Totals'}
            label="Sum Row Label"
            onSubmit={(newValue) =>
              updatePivotTableInstructions((draft) => {
                draft.sumRowText = newValue;
              })
            }
          />
          <Switch
            className={configInputClass}
            label="Bold Sum Row"
            onChange={() => {
              updatePivotTableInstructions((draft) => {
                draft.sumRowBold = !draft.sumRowBold;
              });
            }}
            switchOn={instructions.sumRowBold}
          />
          <div className={configInputClass}>
            <Label htmlFor="">Sum Row Background Color</Label>
            <ColorPickerButton
              fillWidth
              color={instructions.sumRowBackgroundColor ?? '#ffffff'}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor) =>
                updatePivotTableInstructions((draft) => {
                  draft.sumRowBackgroundColor = newColor;
                })
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
