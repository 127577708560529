import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles, Input } from 'components/ds';
import {
  EmbedModal,
  EmbedModalHeader,
  EmbedModalClose,
  EmbedModalFooter,
  EmbedModalContent,
} from 'components/embed';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import { EmbedText } from '../EmbedText';

type Props = {
  reportName: string;
  onSubmit: (reportName: string) => void;
  allowSameName?: boolean;
  isIframe: boolean;
};

export const BaseSaveModal: FC<Props> = ({ reportName, onSubmit, allowSameName, isIframe }) => {
  const dispatch = useDispatch();
  const [newReportName, setNewReportName] = useState(reportName);

  useEffect(() => {
    if (reportName) setNewReportName(reportName);
  }, [reportName]);

  const handleClose = () => dispatch(closeReportModal());

  return (
    <EmbedModal isOpen isIframe={isIframe} onClose={handleClose} size="small">
      <EmbedModalHeader title="Save as new report" />
      <EmbedModalContent
        className={sprinkles({ flexItems: 'column', gap: 'sp.5', paddingX: 'sp3' })}
        size="small">
        <EmbedText body="b3" color="contentPrimary">
          Report name
        </EmbedText>
        <Input onChange={setNewReportName} placeholder="Untitled Report" value={newReportName} />
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        <EmbedModalClose
          disabled={newReportName.trim() === '' || (!allowSameName && reportName === newReportName)}
          onClick={() => onSubmit(newReportName)}
          variant="primary">
          Save new report
        </EmbedModalClose>
      </EmbedModalFooter>
    </EmbedModal>
  );
};
