import { FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BarFunnelChartView } from 'actions/customerReportActions';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { sprinkles } from 'components/ds';
import { DATE_TYPES } from 'constants/dataConstants';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert.css';
import { DataPanelSection } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSection';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { setBarFunnelChartOptions } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

type Props = {
  view: BarFunnelChartView;
};

export const BarFunnelSortSection: FC<Props> = ({ view }) => {
  const dispatch = useDispatch();
  const rows = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.reportData[view.id]?.rows,
  );

  const error = useMemo(
    () =>
      !view?.groupBys?.length
        ? 'No stages'
        : DATE_TYPES.has(view.groupBys[0].column.type)
        ? 'Cannot sort stages of type Date, Datetime, or Timestamp'
        : undefined,
    [view.groupBys],
  );

  const sortedStages = useMemo(() => {
    const sortedStages = view.barFunnelChart?.sortedStages || [];
    if (error || !view.groupBys?.length || !rows?.length) return sortedStages;
    const xAxisColName = view.groupBys[0].column.name;

    const stages = new Set<string>();
    rows.forEach((row) => {
      const stage = row[xAxisColName];
      if (stage) stages.add(String(stage));
    });

    const sortedStagesSet = new Set(sortedStages);
    stages.forEach((s) => !sortedStagesSet.has(s) && sortedStagesSet.add(s));

    return Array.from(sortedStagesSet);
  }, [error, view.groupBys, view.barFunnelChart?.sortedStages, rows]);

  return (
    <DataPanelSection>
      <DataPanelSubHeader title="Order of Stages" />
      <div className={stagesListClass}>
        {error || sortedStages.length > 100 ? (
          <EmbedText body="b2" className={styles.noDataAlert}>
            {error ? error : 'Stage sorting disabled on a chart with more than 100 stages'}
          </EmbedText>
        ) : (
          <SortableList
            getIdFromElem={(id) => id}
            onListUpdated={(newSort) =>
              dispatch(setBarFunnelChartOptions({ sortedStages: newSort }))
            }
            sortableItems={sortedStages}>
            {sortedStages.map((stage) => (
              <SortableListItem key={stage} sortId={stage}>
                <div className={draggableItemClass} style={{ height: 32 }}>
                  <SortableListItemDragHandle
                    className={sprinkles({ color: 'gray10', cursor: 'grab' })}
                  />
                  <EmbedText
                    body="b2"
                    className={sprinkles({ truncateText: 'ellipsis', marginLeft: 'sp1' })}
                    color="contentPrimary">
                    {stage}
                  </EmbedText>
                </div>
              </SortableListItem>
            ))}
          </SortableList>
        )}
      </div>
    </DataPanelSection>
  );
};

const stagesListClass = sprinkles({
  paddingX: 'sp2',
  width: 'fill',
  flexItems: 'column',
  gap: 'sp1',
});

const draggableItemClass = sprinkles({
  flexItems: 'alignCenter',
  paddingY: 'sp.5',
});
