import produce from 'immer';
import { FC } from 'react';

import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { ColorPicker } from 'components/ColorPicker';
import { getCellAlignment } from 'components/dataTable/utils';
import { Label, Toggle, ToggleItem, sprinkles } from 'components/ds';
import { BOOLEAN_DISPLAY_FORMATS } from 'constants/dataConstants';
import {
  BooleanColorCellOptions,
  BooleanDisplayFormat,
  BooleanDisplayOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DatasetColumn } from 'types/datasets';
import { getCurrentBooleanIcons } from 'utils/formatConfigUtils';

type Props = {
  column: DatasetColumn;
  displayOptions: BooleanDisplayOptions | undefined;
  isNewDataGrid: boolean;
  globalStyleConfig: GlobalStyleConfig;

  updateBooleanOptions: (updates: BooleanDisplayOptions) => void;
};

export const BooleanFormatConfig: FC<Props> = ({
  column,
  displayOptions,
  updateBooleanOptions,
  isNewDataGrid,
  globalStyleConfig,
}) => {
  const { trueIcon, falseIcon } = getCurrentBooleanIcons(displayOptions);
  const currentAlignment = getCellAlignment(displayOptions, column.type);

  const colors = displayOptions?.colorCell ?? {};

  const onButtonClick = (
    displayProperty: 'trueIcon' | 'falseIcon',
    newIcon: BooleanDisplayFormat,
  ) => {
    const currentIcon = displayProperty === 'trueIcon' ? trueIcon : falseIcon;
    if (newIcon === currentIcon) return;
    const oppositeIcon = displayProperty === 'trueIcon' ? falseIcon : trueIcon;
    const oppositeDisplayProperty = displayProperty === 'trueIcon' ? 'falseIcon' : 'trueIcon';

    const newOptions = produce(displayOptions ?? {}, (draft) => {
      draft[displayProperty] = newIcon;

      if (newIcon === oppositeIcon) {
        draft[oppositeDisplayProperty] = getOppositeBooleanIcon(newIcon);
      }
    });

    updateBooleanOptions(newOptions);
  };

  const updateColorCell = (updates: Partial<BooleanColorCellOptions>) => {
    const newOptions = produce(displayOptions ?? {}, (draft) => {
      if (!draft.colorCell) draft.colorCell = updates;
      else draft.colorCell = { ...draft.colorCell, ...updates };
    });
    updateBooleanOptions(newOptions);
  };

  const renderBooleanOptions = (isTrue: boolean) => {
    const iconColor =
      (isTrue ? colors.trueTextColor : colors.falseTextColor) ??
      globalStyleConfig.base.actionColor.default;
    const cellColor =
      (isTrue ? colors.trueBackgroundColor : colors.falseBackgroundColor) ??
      globalStyleConfig.container.fill;

    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1', flex: 1, marginBottom: 'sp1' })}>
        <Toggle
          label={isTrue ? 'True' : 'False'}
          onValueChange={(value) =>
            onButtonClick(isTrue ? 'trueIcon' : 'falseIcon', value as BooleanDisplayFormat)
          }
          selectedValue={isTrue ? trueIcon : falseIcon}>
          {BOOLEAN_DISPLAY_FORMATS.map((format) => (
            <ToggleItem icon={format} key={format} value={format} />
          ))}
        </Toggle>
        {isNewDataGrid ? (
          <>
            <div className={sprinkles({ width: 'fill' })}>
              <Label htmlFor="">Icon Color</Label>
              <ColorPicker
                fill
                color={iconColor}
                onClose={(newColor) =>
                  updateColorCell({
                    [isTrue ? 'trueTextColor' : 'falseTextColor']: newColor,
                  })
                }
              />
            </div>
            <div className={sprinkles({ width: 'fill' })}>
              <Label htmlFor="">Cell Color</Label>
              <ColorPicker
                fill
                color={cellColor}
                onClose={(newColor) =>
                  updateColorCell({
                    [isTrue ? 'trueBackgroundColor' : 'falseBackgroundColor']: newColor,
                  })
                }
              />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1', marginTop: 'sp1' })}>
      <HorizontalAlignmentToggle
        label="Alignment"
        selectedAlignment={currentAlignment}
        updateAlignment={(newAlignment) => {
          const newOptions = produce(displayOptions ?? {}, (draft) => {
            draft.alignment = newAlignment;
          });

          updateBooleanOptions(newOptions);
        }}
      />
      <div className={sprinkles({ width: 'fill', display: 'flex', gap: 'sp1' })}>
        {renderBooleanOptions(true)}
        {renderBooleanOptions(false)}
      </div>
    </div>
  );
};

function getOppositeBooleanIcon(format: BooleanDisplayFormat): BooleanDisplayFormat {
  switch (format) {
    case BooleanDisplayFormat.TICK:
    case BooleanDisplayFormat.CROSS:
      return BooleanDisplayFormat.BLANK;
    case BooleanDisplayFormat.BLANK:
      return BooleanDisplayFormat.TICK;
  }
}
