import { Settings } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { fetchReportBuilder } from 'actions/reportBuilderActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { fetchReportBuilderVersions } from 'actions/reportBuilderVersionActions';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { sprinkles } from 'components/ds';
import { loadFonts } from 'globalStyles/utils';
import { ReportBuilderBuiltIns } from 'pages/ReportBuilderEditor/BuiltIns';
import { getCurrentTheme } from 'reducers/dashboardStylesReducer';
import { clearFolders } from 'reducers/folderReducer';
import { clearReportBuilders } from 'reducers/reportBuilderReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { getOrDefault, hasNotReturned, isSuccess } from 'remotedata';
import { useLoadEditMetadata } from 'utils/hookUtils';

import { ReportBuilderPoller } from '../ReportBuilder/ReportBuilderPoller';

import { DatasetEditor } from './DatasetEditor';
import { Header } from './Header';
import { ReportBuilderPreview } from './Preview';
import { VIEW_MODE } from './types';

Settings.defaultZone = 'UTC';

export const ReportBuilderEditorPage: FC = () => {
  const dispatch = useDispatch();
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();
  const { reportBuilder, config, fontConfig, team, globalStyleConfig, portalId } = useSelector(
    (state: ReduxState) => ({
      reportBuilder: state.reportBuilder.currentReportBuilder,
      config: state.reportBuilderEdit.config,
      team: state.currentUser?.team,
      globalStyleConfig: getCurrentTheme(state.dashboardStyles),
      fontConfig: state.dashboardStyles.fontConfig,
      portalId: state.embeddedReportBuilder.portalId,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const [initialViewIds, setInitialViewIds] = useState<string[]>();

  const metadataLoading = useLoadEditMetadata(initialViewIds);

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [globalStyleConfig, fontConfig, team]);

  useEffect(() => {
    const id = parseInt(reportBuilderId);
    if (isNaN(id)) return;
    dispatch(
      fetchReportBuilderVersions({ id }, (data) => {
        const version = [...data.versions].sort((a, b) => b.version_number - a.version_number)[0];
        setInitialViewIds(Object.values(version.config.datasets).map((v) => v.fido_id ?? ''));
      }),
    );
    dispatch(fetchReportBuilder({ id }));

    // clear folders so if we click back to a folder via the breadcrumbs,
    // we don't show a flicker of the stale stored folder
    dispatch(clearFolders());
    dispatch(clearReportBuilders());
  }, [dispatch, reportBuilderId]);

  const renderPage = (reportBuilderId: number, config: ReportBuilderConfig) => {
    switch (view) {
      case VIEW_MODE.BUILT_INS:
        return <ReportBuilderBuiltIns />;
      case VIEW_MODE.SETTINGS:
        return <ReportBuilderPreview />;
      default:
        return <DatasetEditor config={config} reportBuilderId={reportBuilderId} />;
    }
  };

  const renderReportBuilderEditor = () => {
    if (!RD.isSuccess(reportBuilder) || !isSuccess(config) || metadataLoading) {
      return <ExploLoadingSpinner />;
    }

    return (
      <div className={containerClass}>
        <Header breadcrumbs={reportBuilder.data.breadcrumbs} reportBuilder={reportBuilder.data} />
        <div className={sprinkles({ display: 'flex', flex: 1, width: 'fill', overflow: 'hidden' })}>
          {renderPage(reportBuilder.data.id, config.data)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={sprinkles({ display: 'flex', parentContainer: 'fill', overflowX: 'auto' })}
      id={portalId}>
      {renderReportBuilderEditor()}
      <ReportBuilderPoller />
    </div>
  );
};

const containerClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  position: 'relative',
  overflow: 'hidden',
  widthConstraints: 'minOnly',
});
