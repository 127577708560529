declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

export enum EVENTS {
  VIEWED_NOTION_PAGE = 'Viewed notion page',
  CREATED_DEFAULT_SCHEMA = 'Created default schema',
  ADDED_DATA_PANEL = 'Added data panel',
  ADDED_DASHBOARD_ELEMENT = 'Added dashboard element',
  DELETED_DASHBOARD_ELEMENT = 'Deleted dashboard element',
  DELETED_DATA_PANEL = 'Deleted data panel',
  SELECTED_DATASET = 'Selected dataset',
  CREATED_NEW_DATASET = 'Created new dataset',
  EDITED_DATASET_NAME = 'Edited dataset name',
  DELETED_DATASET = 'Deleted dataset',
  RAN_CODE = 'Ran code',
  SAVED_QUERY = 'Saved query',
  RENAMED_DATA_PANEL = 'Renamed data panel',
  RENAMED_DASHBOARD_ELEMENT = 'Renamed dashboard element',
  RENAMED_DASHBOARD_TEMPLATE_NAME = 'Renamed dashboard template name',
  DELETED_DASHBOARD = 'Deleted dashboard',
  CREATED_DASHBOARD = 'Created dashboard',
  LANDING_PAGE_SIGN_UP = 'Landing page sign up',
  AI_OPTIMIZED_QUERY = 'Clicked optimize query button',
  AI_DEBUGGED_QUERY = 'Clicked debug query button',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function identify(userId: number, payload: any) {
  if (!window.analytics) return;
  window.analytics.identify(userId, payload);
}

export function resetUser() {
  if (!window.analytics) return;
  window.analytics.reset();
}

export function pageView(pageName: string) {
  if (!window.analytics) return;
  window.analytics.page(pageName);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackEvent(eventName: string, eventProperties: any) {
  if (!window.analytics) return;
  window.analytics.track(eventName, eventProperties);
}
