import { DateTime } from 'luxon';

import { MONTH_SUFFIX, YEAR_SUFFIX } from 'constants/dashboardConstants';
import { FilterClause, OPERATION_TYPES } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';

export const attachMonthFilterToDataPanel = (
  dp: DataPanel,
  variables: DashboardVariableMap,
  timezone?: string,
): void => {
  const { operation_type, instructions } = dp.visualize_op;
  if (operation_type !== OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP) return;

  const dateCol = instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column;
  if (!dateCol?.name || !dateCol.type) return;

  const { monthKey, yearKey } = getCalendarHeatmapKeys(dp.provided_id);

  const currentDate = new Date();
  const selectedDate = DateTime.fromObject({
    year: (variables[yearKey] ?? currentDate.getFullYear()) as number,
    month: ((variables[monthKey] ?? currentDate.getMonth()) as number) + 1, // DateTime months are 1 indexed
  }).setZone(timezone ?? 'UTC');

  const dateFilter: FilterClause = {
    filterColumn: { name: dateCol.name, type: dateCol.type },
    filterOperation: { id: FilterOperator.DATE_IS_BETWEEN },
    filterValue: {
      startDate: selectedDate.startOf('month').toISO(),
      endDate: selectedDate.endOf('month').toISO(),
    },
  };
  dp.filter_op.instructions.filterClauses.push(dateFilter);
};

export const getCalendarHeatmapKeys = (dpProvidedId: string) => {
  return { monthKey: dpProvidedId + MONTH_SUFFIX, yearKey: dpProvidedId + YEAR_SUFFIX };
};
