import { FC, useState } from 'react';

import { IconButton, sprinkles } from 'components/ds';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import * as styles from './BuiltInMenu.css';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

export const BuiltInMenu: FC<Props> = ({ onDelete, onEdit, onDuplicate }) => {
  const [open, setOpen] = useState(false);

  const menuItems: DropdownOption[] = [
    {
      name: 'Settings',
      onClick: () => {
        // Manually control modal open state to fix Radix Bug where body pointer-events: none stays even when modal is closed
        // https://github.com/radix-ui/primitives/issues/1241
        setOpen(false);
        onEdit();
      },
    },
    { name: 'Duplicate', onClick: onDuplicate },
    { name: 'Delete', className: sprinkles({ color: 'error' }), onClick: onDelete },
  ];

  return (
    <div
      onClick={(e) => {
        // Prevent the document blur handler from firing
        // stopPropagation is dangerous: https://css-tricks.com/dangers-stopping-event-propagation/
        e.preventDefault();
      }}>
      <EmbeddedDropdownMenu menuOptions={menuItems} onOpenChange={setOpen} open={open}>
        <IconButton className={styles.dropdownIcon} name="ellipsis-vertical" />
      </EmbeddedDropdownMenu>
    </div>
  );
};
