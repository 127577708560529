import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { listTeamDataSources, DataSource, ParentSchema } from 'actions/dataSourceActions';
import { fetchUsedParentSchemas, fetchAllSchemaTables } from 'actions/parentSchemaActions';
import { fetchUserTeam, AccessGroup } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { PageHeader } from 'components/PageHeader';
import { AlertModal } from 'components/ds';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ROUTES } from 'constants/routes';
import { SyncTablesModal } from 'pages/DataPage/modals/SyncTablesModal';
import { createLoadingSelector } from 'reducers/api/selectors';
import { areParentSchemasLoading } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getNamespaces } from 'reducers/thunks/fidoThunks';
import { deleteDataSource, syncSchema } from 'reducers/thunks/schemaManagementThunks';
import { shouldUseFidoForDefaultDataSource } from 'reducers/thunks/syncSchemaFlowThunks';
import * as RD from 'remotedata';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { isCreateDataSourceDisabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { groupBy, isEmpty, sortBy } from 'utils/standard';

import { DataSourceItem } from './DataSourceItem';
import { SchemaDataSourceGroup } from './SchemaDataSourceGroup';
import { TOAST_TIMEOUT } from './constants';
import { ManageSchemasModal } from './modals/ManageSchemasModal';
import * as styles from './styles.css';

enum ModalStatus {
  DELETE_DATASOURCE = 'delete_datasource',
  DELETE_SCHEMA = 'delete_schema',
  SYNC_TABLES = 'sync_tables',
  MANAGE_SCHEMA = 'manage_schema',
  CLOSED = 'closed',
}

const getAccessGroupNamesForDataSource = (accessGroups: AccessGroup[], dataSourceId: number) => {
  return accessGroups.filter((accessGroup) =>
    accessGroup.default_data_source_ids.includes(dataSourceId),
  );
};

const getAccessGroupsForSchema = (
  accessGroups: AccessGroup[] | undefined,
  schemaDataSources: DataSource[],
) => {
  if (!accessGroups) return [];
  return schemaDataSources.map((dataSource) =>
    getAccessGroupNamesForDataSource(accessGroups, dataSource.id).map(
      (accessGroup) => accessGroup.name,
    ),
  );
};

export const DataPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    dataSource,
    parentSchemas,
    parentSchemaTablesMap,
    parentSchemasLoading,
    pageDataLoading,
    team,
    permissions,
    fidoDaos,
    useFido,
    currentUser,
  } = useSelector(
    (state: ReduxState) => ({
      dataSource: state.dataSource,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap,
      parentSchemasLoading: areParentSchemasLoading(state),
      team: state.teamData,
      pageDataLoading: createLoadingSelector([ACTION.FETCH_USER_TEAM], true)(state),
      permissions: state.currentUser.permissions,
      fidoDaos: state.fido.fidoDaos,
      useFido: state.currentUser.team?.feature_flags.use_fido,
      currentUser: state.currentUser,
    }),
    shallowEqual,
  );

  const dataSources = dataSource.dataSources;
  const teamData = team.data;

  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>();
  const [selectedParentSchema, setSelectedParentSchema] = useState<ParentSchema>();
  const [searchString, setSearchString] = useState('');
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);
  const [dataSourceDeleteLoading, setDataSourceDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch(getNamespaces());
  }, [dispatch, useFido, parentSchemas, dataSources, parentSchemaTablesMap]);

  useEffect(() => {
    if (RD.isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (RD.isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (RD.isIdle(parentSchemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, parentSchemaTablesMap]);

  useEffect(() => dispatch(fetchUserTeam()), [dispatch]);

  const dataSourcesBySchemaId = useMemo(
    () => groupBy(RD.getOrDefault(dataSources, []), 'parent_schema_id'),
    [dataSources],
  );

  const fidoDataSourcesBySchemaId = useMemo(
    () => groupBy(RD.isSuccess(fidoDaos) ? fidoDaos.data.dataSources : [], 'parent_schema_id'),
    [fidoDaos],
  );

  const dataSourcePermissions = permissions[PERMISSIONED_ENTITIES.DATA_SOURCE];

  const isCreateDataSourceDisabledForPaymentPlan = isCreateDataSourceDisabled(
    RD.getOrDefault(dataSources, []),
    teamData?.payment_plan,
  );

  const userCanEditDataSource = doesUserHavePermission(
    dataSourcePermissions,
    PERMISSIONED_ACTIONS.UPDATE,
  );

  const userCanDeleteDataSource = doesUserHavePermission(
    dataSourcePermissions,
    PERMISSIONED_ACTIONS.DELETE,
  );

  const namespaces = useMemo(
    () => (RD.isSuccess(fidoDaos) ? fidoDaos.data.namespaces : []),
    [fidoDaos],
  );

  const resolvedNamespaces = useFido ? namespaces : RD.getOrDefault(parentSchemas, []);
  const schemaTablesMap = RD.getOrDefault(parentSchemaTablesMap, {});

  const filteredDataSourcesBySchema = useMemo(() => {
    return resolvedNamespaces.map((schema) => {
      let dataSources = useFido
        ? fidoDataSourcesBySchemaId[schema.id]
        : dataSourcesBySchemaId[schema.id];

      if (!isEmpty(searchString)) {
        dataSources = dataSources.filter(
          (dataSource) =>
            dataSource.name.toLowerCase().includes(searchString) ||
            (dataSource.provided_id ?? dataSource.id).toString().includes(searchString) ||
            schema.name.toLowerCase().includes(searchString),
        );
      }

      return sortBy(dataSources, 'id') ?? [];
    });
  }, [resolvedNamespaces, dataSourcesBySchemaId, fidoDataSourcesBySchemaId, useFido, searchString]);

  const renderSchemaGroups = () => {
    return filteredDataSourcesBySchema.map((dataSources, schemaIndex) => {
      if (dataSources.length === 0) return null;
      const parentSchema = resolvedNamespaces[schemaIndex];

      const fidoTablesRequireSync = RD.getOrDefault(fidoDaos, {
        namespaces: [],
        dataSources: [],
        tables: [],
        schemaTablesMap: {},
      }).tables.every((table) => table.namespaceId !== parentSchema.fido_id);
      const embeddoTablesRequireSync = isEmpty(schemaTablesMap[parentSchema.id]);

      const useFidoForSchema = shouldUseFidoForDefaultDataSource(parentSchema.id.toString(), {
        dataSource,
        teamData: team,
        currentUser,
      });

      const shouldSyncDataTables =
        (useFidoForSchema ? fidoTablesRequireSync : embeddoTablesRequireSync) &&
        userCanEditDataSource;

      // Need to choose the default Data Source tag labels at the schema level
      const schemaAccessGroups = getAccessGroupsForSchema(teamData?.access_groups, dataSources);
      const schemaHasOneAccessGroup = schemaAccessGroups.flat().length === 1;

      const dataSourceItems = dataSources.map((dataSource, index) => {
        return (
          <DataSourceItem
            accessGroupNames={schemaAccessGroups[index]}
            dataSource={dataSource}
            deleteLoading={dataSourceDeleteLoading && dataSource.id === selectedDataSource?.id}
            key={dataSource.id}
            onDelete={
              userCanDeleteDataSource
                ? () => {
                    setSelectedDataSource(dataSource);
                    setModalStatus(ModalStatus.DELETE_DATASOURCE);
                  }
                : undefined
            }
            onSyncTables={
              shouldSyncDataTables
                ? () => {
                    setSelectedParentSchema(parentSchema);
                    setModalStatus(ModalStatus.SYNC_TABLES);
                  }
                : undefined
            }
            schemaHasOneAccessGroup={schemaHasOneAccessGroup}
            userCanEdit={userCanEditDataSource}
          />
        );
      });

      return (
        <SchemaDataSourceGroup
          id={parentSchema.id}
          isSearching={!isEmpty(searchString)}
          key={parentSchema.id}
          name={parentSchema.name}
          onDelete={
            userCanDeleteDataSource
              ? () => {
                  setSelectedParentSchema(parentSchema);
                  setModalStatus(ModalStatus.DELETE_SCHEMA);
                }
              : undefined
          }
          userCanEdit={userCanEditDataSource}>
          {dataSourceItems}
        </SchemaDataSourceGroup>
      );
    });
  };

  if (pageDataLoading || parentSchemasLoading || RD.isLoading(fidoDaos))
    return <ExploLoadingSpinner />;

  const closeModal = () => setModalStatus(ModalStatus.CLOSED);

  const onDeleteDataSource = () => {
    if (!selectedDataSource?.id) return;

    setDataSourceDeleteLoading(true);
    dispatch(
      deleteDataSource(
        selectedDataSource?.id,
        () => {
          setSelectedDataSource(undefined);
          setDataSourceDeleteLoading(false);

          showSuccessToast('Data source successfully deleted.', TOAST_TIMEOUT);
        },
        (error) => {
          setSelectedDataSource(undefined);
          setDataSourceDeleteLoading(false);

          showErrorToast(error.detail, TOAST_TIMEOUT);
        },
      ),
    );

    setModalStatus(ModalStatus.CLOSED);
  };

  const onDeleteSchema = () => {
    const newSchemas = resolvedNamespaces.filter((s) => s.id !== selectedParentSchema?.id);

    dispatch(
      syncSchema(
        { edited_schemas: newSchemas },
        () => {
          setSelectedParentSchema(undefined);

          showSuccessToast('Schema successfully deleted.', TOAST_TIMEOUT);
        },
        (error) => {
          setSelectedParentSchema(undefined);

          showErrorToast(error.error_msg ?? 'Deletion failed.', TOAST_TIMEOUT);
        },
      ),
    );

    setModalStatus(ModalStatus.CLOSED);
  };

  return (
    <div className={styles.outerContainer}>
      <PageHeader
        pageTitle="Data Sources"
        primaryActionProps={
          doesUserHavePermission(dataSourcePermissions, PERMISSIONED_ACTIONS.CREATE)
            ? {
                disabled: isCreateDataSourceDisabledForPaymentPlan,
                text: 'Connect Data Source',
                tooltipText: isCreateDataSourceDisabledForPaymentPlan
                  ? 'Upgrade your plan to add more data sources.'
                  : undefined,
                to: ROUTES.CONNECT_DATA_SOURCE,
              }
            : undefined
        }
        searchBarPlaceholderText="Search by schema name, data source name, or data source ID"
        searchBarSubmit={(searchStringSupplied) =>
          setSearchString(searchStringSupplied.toLowerCase().trim())
        }
        secondaryActionProps={
          userCanEditDataSource
            ? {
                text: 'Manage Schemas',
                onClick: () => setModalStatus(ModalStatus.MANAGE_SCHEMA),
              }
            : undefined
        }
      />
      <div className={styles.scrollContainer}>
        <div className={styles.dataPageContainer}>
          <div className={styles.contentContainer}>{renderSchemaGroups()}</div>
          {modalStatus === ModalStatus.MANAGE_SCHEMA ? (
            <ManageSchemasModal
              closeModal={() => {
                setSelectedParentSchema(undefined);
                setModalStatus(ModalStatus.CLOSED);
              }}
              modalOpen={modalStatus === ModalStatus.MANAGE_SCHEMA}
              parentSchemas={resolvedNamespaces}
              selectedSchemaId={selectedParentSchema?.id}
            />
          ) : null}
          {modalStatus === ModalStatus.DELETE_DATASOURCE ? (
            <AlertModal
              actionButtonProps={{
                text: `Delete ${selectedDataSource?.name}`,
                onClick: onDeleteDataSource,
              }}
              isOpen={modalStatus === ModalStatus.DELETE_DATASOURCE}
              onClose={closeModal}
              title="Are you sure you want to delete this data source?"
            />
          ) : null}
          {modalStatus === ModalStatus.DELETE_SCHEMA ? (
            <AlertModal
              actionButtonProps={{
                text: `Delete ${selectedParentSchema?.name}`,
                onClick: onDeleteSchema,
              }}
              isOpen={modalStatus === ModalStatus.DELETE_SCHEMA}
              onClose={closeModal}
              title="Are you sure you want to delete this schema?"
            />
          ) : null}
          {modalStatus === ModalStatus.SYNC_TABLES ? (
            <SyncTablesModal
              closeModal={() => {
                setModalStatus(ModalStatus.CLOSED);
                setSelectedParentSchema(undefined);
              }}
              modalOpen={modalStatus === ModalStatus.SYNC_TABLES}
              onSync={() =>
                history.push(ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${selectedParentSchema?.id}`)
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
