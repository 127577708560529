import '@inovua/reactdatagrid-enterprise/index.css';
import { TypeCellProps, TypeFooterRow } from '@inovua/reactdatagrid-community/types';
import { TypeSummaryReducer } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import { FC, Suspense, lazy, useCallback, ReactNode, useMemo } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { DatasetSchema } from 'types/datasets';

import * as gridStyles from '../EmbedDataGrid/index.css';

import * as styles from './index.css';

export type EmbedPivotTableRow = Record<string, ReactNode>;

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  activeIndex?: number;
  className?: string;
  rowClassName?: string;
  showHoverRows?: boolean;

  groupByColumns: string[];
  loading: boolean;
  pivotColumns: string[];
  schema: DatasetSchema;
  rows: EmbedPivotTableRow[];
  // Specify columns and groupColumn to override default columns generated from schema
  columns: TypeColumn[];
  groupColumn: TypeColumn;
  onCellClick?: (event: MouseEvent, cellProps: TypeCellProps) => void;
  summaryReducer?: TypeSummaryReducer;
  footerRows?: TypeFooterRow[];
  isRowLimitReached: boolean | undefined;
};

export const EmbedPivotTable: FC<Props> = ({
  schema,
  rows,
  groupByColumns,
  pivotColumns,
  isRowLimitReached,
  ...props
}) => {
  const renderLoadMask = useCallback(({ visible }: { visible: boolean }) => {
    return visible ? <EmbedSpinner fillContainer className={gridStyles.loadMask} /> : null;
  }, []);

  const columnOrder = useMemo(() => schema?.map((col) => col.name), [schema]);

  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'column' })}>
      <Suspense fallback={<EmbedSpinner fillContainer />}>
        <ReactDataGrid
          nativeScroll
          {...props}
          disableGroupByToolbar
          columnOrder={columnOrder}
          dataSource={rows}
          groupBy={groupByColumns}
          idProperty="id"
          licenseKey={process.env.REACT_APP_DATAGRID_KEY}
          pivot={pivotColumns}
          renderLoadMask={renderLoadMask}
          reorderColumns={false}
          showColumnMenuTool={false}
          sortable={false}
        />
        {isRowLimitReached ? (
          <div className={styles.rowLimitContainer}>
            Pivot row limit reached, please filter your data or reduce the number of groups.
          </div>
        ) : null}
      </Suspense>
    </div>
  );
};
