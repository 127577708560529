import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { EmbedFilterLabel, EmbedSwitch } from 'components/embed';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEvent } from 'reducers/thunks/analyticsThunks';
import { DashboardVariable, SwitchElementConfig, DashboardVariableMap } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';

type Props = {
  config: SwitchElementConfig;
  disabled?: boolean;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value: DashboardVariable;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  variables: DashboardVariableMap;
};

export default function DashboardSwitchElement({
  config,
  datasetNamesToId,
  datasetData,
  disabled,
  onNewValueSelect,
  value,
  variables,
}: Props) {
  const dispatch = useDispatch();

  const isVarOn =
    value === config.onStatusLabel || value === config.onStatusValue || value === 'true';
  const tooltipText = useMemo(
    () => resolveTooltipVariables(config, variables, datasetNamesToId, datasetData),
    [config, datasetData, datasetNamesToId, variables],
  );

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginTop: 'sp2.5',
      })}
      style={{ height: 32 }}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ flexItems: 'alignCenter', marginRight: 'sp1' })}>
          <EmbedFilterLabel noBottomMargin helpText={tooltipText}>
            {config.showSwitchLabel ? label : undefined}
          </EmbedFilterLabel>
        </div>
        <EmbedSwitch
          disabled={disabled}
          onChange={() => {
            const newValue = getOppositeSwitchValue(config, isVarOn);
            onNewValueSelect(newValue);
            dispatch(sendAnalyticsEvent(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
          }}
          switchOn={value === undefined ? false : isVarOn}
        />
      </div>
      {config.showStatusLabel ? (
        <EmbedFilterLabel noBottomMargin className={sprinkles({ marginLeft: 'sp1' })}>
          {isVarOn ? config.onStatusLabel : config.offStatusLabel}
        </EmbedFilterLabel>
      ) : null}
    </div>
  );
}

const getOppositeSwitchValue = (config: SwitchElementConfig, isVarOn: boolean) => {
  if (isVarOn) {
    return config.offStatusValue || 'false';
  } else {
    return config.onStatusValue || 'true';
  }
};
