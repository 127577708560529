import { ThunkAction } from '@reduxjs/toolkit';

import { duplicateDashboardItem } from 'actions/dashboardV2Actions';
import { DashboardStates } from 'reducers/rootReducer';
import { showSuccessToast } from 'shared/sharedToasts';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import {
  DEFAULT_TOAST_DURATION,
  getDuplicatedToMainSectionToastText,
} from './dataPanelConfigConstants';

type DuplicateThunkAction = ThunkAction<
  void,
  // The state is unused. This is a placeholder.
  DashboardStates,
  never,
  ReturnType<typeof duplicateDashboardItem>
>;

export const duplicateToMainDashboardAction = (
  dataPanel: DataPanelTemplate,
  dashboardId: number,
  onStartFn: () => void,
  onToastDismissedFn: () => void,
): DuplicateThunkAction => {
  return (dispatch) => {
    onStartFn();
    dispatch(
      duplicateDashboardItem({
        dashboardItem: dataPanel,
        itemType: DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
        dashId: dashboardId,
      }),
    );

    showSuccessToast(
      getDuplicatedToMainSectionToastText(dataPanel.provided_id),
      DEFAULT_TOAST_DURATION,
      () => onToastDismissedFn(),
    );
  };
};
