import './global.css';
// eslint-disable-next-line import/order
import { sprinkles } from './sprinkles.css';
// eslint-disable-next-line import/order
import { Intent, baseThemeName, vars } from './vars.css';

import { AlertModal } from './AlertModal';
import { Breadcrumbs, FolderBreadcrumbs } from './Breadcrumbs';
import { Button } from './Button';
import { CalloutLink } from './CalloutLink';
import { Checkbox } from './Checkbox';
import { DataGrid } from './DataGrid';
import { ErrorFallback } from './ErrorFallback';
import { Icon } from './Icon';
import { IconButton } from './IconButton';
import { InfoIcon } from './InfoIcon';
import { Input } from './Input';
import { Label } from './Label';
import { Link } from './Link';
import { Menu } from './Menu';
import { MenuActionItem } from './Menu/MenuActionItem';
import { MenuItem } from './Menu/MenuItem';
import { MenuLabel } from './Menu/MenuLabel';
import { MenuSeparator } from './Menu/MenuSeparator';
import { MenuSub } from './Menu/MenuSub';
import { MenuSwitchItem } from './Menu/MenuSwitchItem';
import { Modal } from './Modal';
import { ModalSection } from './Modal/ModalSection';
import { NavigationToggle } from './NavigationToggle';
import { PageError } from './PageError';
import { Popover } from './Popover';
import { Select } from './Select';
import { SideSheet } from './SideSheet';
import { Spinner } from './Spinner';
import { Switch } from './Switch';
import { Tabs } from './Tabs';
import { Tag } from './Tag';
import { TextArea } from './TextArea';
import { Toggle, ToggleItem, BooleanToggle } from './Toggle';
import { Tooltip } from './Tooltip';

export {
  baseThemeName,
  sprinkles,
  vars,
  AlertModal,
  Breadcrumbs,
  Button,
  Checkbox,
  DataGrid,
  ErrorFallback,
  FolderBreadcrumbs,
  Icon,
  IconButton,
  InfoIcon,
  Input,
  Intent,
  Label,
  Link,
  Menu,
  MenuSub,
  MenuActionItem,
  MenuSwitchItem,
  MenuItem,
  MenuLabel,
  MenuSeparator,
  Modal,
  ModalSection,
  PageError,
  Popover,
  Select,
  Spinner,
  Switch,
  Tag,
  Tooltip,
  SideSheet,
  NavigationToggle,
  Tabs,
  Toggle,
  ToggleItem,
  CalloutLink,
  BooleanToggle,
  TextArea,
};

export const APP_PORTAL_ID = 'explo-app';

export type Colors = keyof typeof vars.colors;
