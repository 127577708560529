import { usePrevious } from '@radix-ui/react-use-previous';
import { animate, motion, useTransform, useMotionValue } from 'framer-motion';
import { FC, useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { DrilldownContent } from 'pages/ReportBuilder/ReportView/DrilldownContent';
import { DrilldownFooter } from 'pages/ReportBuilder/ReportView/DrilldownFooter';
import * as styles from 'pages/ReportBuilder/ReportView/DrilldownPanel.css';
import { PanelHandle, PanelSide } from 'pages/ReportBuilder/ReportView/PanelHandle';
import {
  setLocalMotionValue,
  getLocalMotionValue,
} from 'pages/ReportBuilder/ReportView/persistentMotionValue';
import {
  resetDrilldownConfig,
  toggleDrilldownPanelOpen,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ColumnConfigsWithName } from 'types/columnTypes';
import { createDebouncedFn } from 'utils/general';

const MAX_HEIGHT_MARGIN = 48;
const MIN_HEIGHT = 48;
const SNAP_HEIGHT = MIN_HEIGHT + 16;
const DEFAULT_HEIGHT = 300;
const DRILLDOWN_PANEL_HEIGHT_ID = 'drilldown_panel_height'; // For local storage

type Props = {
  dataInfo: CustomerReportDataInfo;
  view: CustomerReportView;
  columnConfigs: ColumnConfigsWithName;
  height: number;
};

export const DrilldownPanel: FC<Props> = ({ view, dataInfo, columnConfigs, height }) => {
  const dispatch = useDispatch();

  const [debouncedHeight, setDebouncedHeight] = useState(0);
  const footerRef = useRef<HTMLDivElement>(null);

  const isDrilldownPanelOpen = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.isDrilldownPanelOpen,
  );

  // TODO: TBD on whether this should be in reportBuilderDataMiddleware or not
  const { visualization, id: viewId, groupBys } = view;
  useEffect(() => {
    // Reset drilldown when view or visualization changes
    if (viewId || visualization || groupBys) dispatch(resetDrilldownConfig());
  }, [dispatch, viewId, visualization, groupBys]);

  // Negative since it's relative to bottom, but motionHeight should be positive (since it's a height)
  const motionValue = useMotionValue(0);
  const motionHeight = useTransform(motionValue, (v) => -v);
  const prevIsDrilldownPanelOpen = usePrevious(isDrilldownPanelOpen);
  useEffect(() => {
    // Animate the drilldown panel opening and closing
    if (prevIsDrilldownPanelOpen !== isDrilldownPanelOpen) {
      const to = isDrilldownPanelOpen
        ? -getLocalMotionValue(DRILLDOWN_PANEL_HEIGHT_ID, DEFAULT_HEIGHT)
        : 0;
      animate(motionValue, to);
    }
  }, [motionValue, isDrilldownPanelOpen, prevIsDrilldownPanelOpen]);

  useEffect(
    () => motionHeight.onChange((latest) => debounceFn(() => setDebouncedHeight(latest))),
    [motionHeight],
  );

  const handleStopResizing = useCallback(() => {
    const val = motionValue.get();
    setLocalMotionValue(DRILLDOWN_PANEL_HEIGHT_ID, -val); // Handle offset is negative, invert for width

    // Automatically close the drilldown panel if it's close to the bottom
    if (isDrilldownPanelOpen && Math.abs(val) < SNAP_HEIGHT) dispatch(toggleDrilldownPanelOpen());
  }, [dispatch, isDrilldownPanelOpen, motionValue]);

  return (
    <>
      {isDrilldownPanelOpen ? (
        <motion.div className={styles.panel} style={{ height: motionHeight }}>
          {/* Height is hardcoded and debounced to prevent lag when resizing grid */}
          <div
            className={sprinkles({ flexItems: 'column', justifyContent: 'stretch' })}
            style={{ height: debouncedHeight }}>
            <DrilldownFooter
              columnConfigs={columnConfigs}
              hasGroupBys={!!view.groupBys?.length}
              ref={footerRef}
              viewName={view.name}
              visualization={view.visualization}
            />
            <DrilldownContent dataInfo={dataInfo} view={view} />
          </div>
        </motion.div>
      ) : undefined}
      <PanelHandle
        dragConstraints={{ top: -height + MAX_HEIGHT_MARGIN, bottom: -MIN_HEIGHT }}
        isVisible={isDrilldownPanelOpen}
        motionValue={motionValue}
        onStop={handleStopResizing}
        side={PanelSide.Bottom}
      />
    </>
  );
};

const debounceFn = createDebouncedFn(300);
