import { FC, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ExportType, EXPORT_TYPE_NAME_MAP } from 'actions/exportActions';
import { sprinkles, Tag } from 'components/ds';
import {
  EmbedRadioGroup,
  EmbedButton,
  EmbedModal,
  EmbedModalHeader,
  EmbedModalFooter,
  EmbedModalClose,
  EmbedModalContent,
} from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  allExportOptions,
  tabularExportOptions,
  DEFAULT_EXPORT_TYPE,
} from 'pages/ReportBuilder/ReportView/ExportButton/types';
import { isLoading, isError, isSuccess } from 'remotedata';
import {
  closeReportModal,
  getCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { exportReport } from 'reportBuilderContent/thunks/exportThunks';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';

type Props = { reportName: string; isIframe: boolean };

export const ExportNowModal: FC<Props> = ({ reportName, isIframe }) => {
  const [exportType, setExportType] = useState(DEFAULT_EXPORT_TYPE);
  const dispatch = useDispatch();
  const { selectedReport, exportUrl, enableImageExport, currentView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      selectedReport: state.reportEditing.selectedReport,
      exportUrl: state.reportEditing.exportUrl,
      enableImageExport:
        state.embeddedReportBuilder.team?.feature_flags.enable_report_builder_image_export,
      currentView: getCurrentView(state.reportEditing),
    }),
    shallowEqual,
  );

  const loading = isLoading(exportUrl);
  const exportOptions = enableImageExport ? allExportOptions : tabularExportOptions;

  return (
    <EmbedModal
      isOpen
      isIframe={isIframe}
      onClose={() => dispatch(closeReportModal())}
      size="small">
      <EmbedModalHeader title="Export now" />
      <EmbedModalContent
        className={sprinkles({ flexItems: 'column', gap: 'sp1', paddingX: 'sp3' })}
        size="small">
        <EmbedText body="b1">
          Immediately export and download the current view. Unsaved changes will be exported.
        </EmbedText>
        <EmbedRadioGroup
          defaultValue={DEFAULT_EXPORT_TYPE}
          disabled={loading}
          onValueChange={(value) => setExportType(value as ExportType)}
          renderValue={(value) => {
            const formattedValue = EXPORT_TYPE_NAME_MAP[value as ExportType];
            if (value === ExportType.CSV && isTableVisualization(currentView?.visualization))
              return (
                <div className={sprinkles({ flexItems: 'center', gap: 'sp.5' })}>
                  {formattedValue}
                  <Tag>Recommended for tables</Tag>
                </div>
              );

            return formattedValue;
          }}
          value={exportType}
          values={exportOptions}
        />
        {isError(exportUrl) ? (
          <EmbedText body="b3" color="contentSecondary">
            Unable to export report. Please try again then contact support if the issue persists.
          </EmbedText>
        ) : isSuccess(exportUrl) ? (
          <EmbedText body="b3" color="contentSecondary">
            Export complete. Your download will start in a few seconds.{' '}
            <a href={exportUrl.data} rel="noreferrer" target="_blank">
              If not, click here.
            </a>
          </EmbedText>
        ) : null}
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        <EmbedButton
          loading={loading}
          onClick={() =>
            selectedReport && dispatch(exportReport({ selectedReport, reportName, exportType }))
          }
          variant="primary">
          Export now
        </EmbedButton>
      </EmbedModalFooter>
    </EmbedModal>
  );
};
