import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import { setAuthentication } from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';

import { CredentialsInput } from './CredentialsInput';

export const FidoBigQueryCredentialsSection: FC = () => {
  const dispatch = useDispatch();

  const { type, auth, isUpdating } = useSelector(
    (state: ReduxState) => ({
      type: state.fidoDataSourceConfig.dataSourceConfig.type,
      auth: state.fidoDataSourceConfig.dataSourceConfig.auth,
      isUpdating: state.fidoDataSourceConfig.isUpdating,
    }),
    shallowEqual,
  );

  if (type !== DATABASES.BIGQUERY) {
    return null;
  }

  return (
    <>
      <CredentialsInput
        helpTooltip="You can find this in the Google Cloud console."
        label="Project ID"
        optional={isUpdating}
        updateConfig={(projectId) => dispatch(setAuthentication({ projectId }))}
        value={auth?.projectId}
      />
      <CredentialsInput
        helpTooltip="We recommend generating a new service account user for Explo. Generate a JSON Key File on creation and provide it here."
        label="JSON Key File"
        optional={isUpdating}
        updateConfig={(jsonKeyFile) => dispatch(setAuthentication({ jsonKeyFile }))}
        value={auth?.jsonKeyFile}
      />
    </>
  );
};
