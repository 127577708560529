import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { saveFromEmailName, uploadSupportEmail } from 'actions/teamActions';
import { Input, sprinkles } from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { ReduxState } from 'reducers/rootReducer';

export const SettingsEmailSection: FC = () => {
  const dispatch = useDispatch();

  const { data, emailFromName } = useSelector(
    (state: ReduxState) => ({
      data: state.teamData.data,
      emailFromName: state.currentUser.team?.configuration.email_from_name,
    }),
    shallowEqual,
  );

  const [newSupportEmail, setNewSupportEmail] = useState<string>();

  return (
    <div>
      <div className={sprinkles({ heading: 'h1', marginBottom: 'sp1' })}>Support Email</div>
      <div className={sprinkles({ color: 'contentTertiary' })}>
        This affects the look of emails shown in the embedded application or directly sent to your
        users
      </div>
      <Input
        showInputButton
        className={sprinkles({ marginY: 'sp3' })}
        defaultValue={data?.support_email ?? undefined}
        errorText={
          newSupportEmail && !validator.isEmail(newSupportEmail)
            ? 'Email is not formatted correctly'
            : undefined
        }
        label="Support Email for App Errors"
        onSubmit={(newString) =>
          dispatch(
            uploadSupportEmail({ postData: { support_email: newString } }, () => {
              setNewSupportEmail(newString);
            }),
          )
        }
        placeholder={DEFAULT_SUPPORT_EMAIL}
      />
      <Input
        defaultValue={emailFromName ?? undefined}
        label="Display Name for Sent Emails"
        onSubmit={(newString) => dispatch(saveFromEmailName({ postData: { name: newString } }))}
        showInputButton={true}
      />
    </div>
  );
};
