import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { Modal } from 'components/ds';
import { OPERATION_TYPES } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets, getParentSchemaSourceTypes } from 'reducers/selectors';
import { isVizOptionDisabled } from 'utils/aggUtils';
import { sortBy } from 'utils/standard';

import * as styles from './dataPanelModal.css';

type Props = {
  initialDatasetId?: string | undefined;
  opType: OPERATION_TYPES;
  onClose: () => void;
  onConfirm: (selectedDatasetId: string | undefined) => void;
  primaryButtonText: string;
  headerText: string;
  title: string;
};

export const DataPanelModal: FC<Props> = ({
  initialDatasetId,
  opType,
  onClose,
  onConfirm,
  primaryButtonText,
  headerText,
  title,
}) => {
  const { datasets, parentSchemaSourceTypes } = useSelector(
    (state: ReduxState) => ({
      datasets: getEditableDatasets(state),
      parentSchemaSourceTypes: getParentSchemaSourceTypes(state),
    }),
    shallowEqual,
  );

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | undefined>(initialDatasetId);

  const orderedDatasets = useMemo(
    () => sortBy(Object.values(datasets ?? {}), 'table_name'),
    [datasets],
  );

  const renderDatasetItem = (dataset: Dataset) => {
    if (!dataset.schema?.length) return null;

    const isDisabled = isVizOptionDisabled(
      opType,
      parentSchemaSourceTypes[dataset.parent_schema_id],
    );

    return (
      <MenuItem
        className={dataset.id === selectedDatasetId ? styles.selectedItem : undefined}
        // TODO we really should have a tooltip saying "box plot is disabled for rockset datasets"
        disabled={isDisabled}
        icon="th"
        key={`dataset-add-data-panel-${dataset.id}`}
        onClick={() => !isDisabled && setSelectedDatasetId(dataset.id)}
        text={dataset.table_name || 'Untitled'}
      />
    );
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={{
        disabled: !selectedDatasetId,
        onClick: () => onConfirm(selectedDatasetId),
        text: primaryButtonText,
      }}
      secondaryButtonProps={{
        onClick: onClose,
        text: 'Cancel',
      }}
      size="small"
      title={title}>
      <div className={styles.modalBody}>
        <div className={styles.modalSubHeader}>{headerText}</div>
        <div className={styles.modalBodyListClass} style={{ height: '40vh' }}>
          <Menu>
            <MenuDivider title="Dashboard Datasets" />
            {orderedDatasets.length === 0 ? (
              <MenuItem disabled text="No datasets. Create one!" />
            ) : (
              orderedDatasets.map(renderDatasetItem)
            )}
          </Menu>
        </div>
      </div>
    </Modal>
  );
};
