import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'components/ds';
import { EmbedButton, EmbedTab, EmbedTabs } from 'components/embed';
import { AllReportTab } from 'pages/ReportBuilder/HomeView/AllReportTab';
import { BuiltInReportTab } from 'pages/ReportBuilder/HomeView/BuiltInReportTab';
import { StarredReportTab } from 'pages/ReportBuilder/HomeView/StarredReportTab';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { DocsPopover } from 'pages/ReportBuilder/Onboarding/DocsPopover';
import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import { setSelectedTab } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { createDraftCustomerReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import { EmbedText } from '../EmbedText';

import * as styles from './index.css';

export const ReportBuilderHomeView: FC = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.selectedTab,
  );

  const createReport = useCallback(() => {
    dispatch(createDraftCustomerReport({ views: [createBlankView()] }));
  }, [dispatch]);

  const renderTabBody = () => {
    switch (selectedTab) {
      case ReportBuilderTab.BUILT_IN_REPORTS:
        return <BuiltInReportTab />;
      case ReportBuilderTab.STARRED:
        return <StarredReportTab createReport={createReport} />;
      default:
        return <AllReportTab createReport={createReport} />;
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerTitleContainer}>
          <EmbedText heading="h1">Reports</EmbedText>
          <EmbedButton icon="plus" onClick={createReport} variant="primary">
            New Report
          </EmbedButton>
        </div>

        <div className={styles.filterContainer}>
          <EmbedTabs>
            {Object.values(ReportBuilderTab).map((tab) => {
              return (
                <EmbedTab
                  className={styles.tab}
                  isSelected={tab === selectedTab}
                  key={tab}
                  onClick={() => dispatch(setSelectedTab({ tab }))}>
                  {tab === ReportBuilderTab.STARRED ? (
                    <Icon color="contentTertiary" name="star" />
                  ) : null}
                  <EmbedText body="b2">{tab}</EmbedText>
                </EmbedTab>
              );
            })}
          </EmbedTabs>
          <DocsPopover />
        </div>
      </div>
      {renderTabBody()}
    </>
  );
};
