import { FC, ReactNode } from 'react';

import { Icon, sprinkles, Popover } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';

type Props = {
  icon?: IconName;
  title: string;
  popoverChildren?: ReactNode;
  children?: ReactNode;
};

export const DataPanelSubHeader: FC<Props> = ({ icon, title, popoverChildren, children }) => {
  return (
    <Popover
      className={sprinkles({ backgroundColor: 'black', paddingY: 'sp1', paddingX: 'sp1.5' })}
      isOpen={!!popoverChildren}
      onOpenChange={() => null}
      side="bottom"
      trigger={
        <div className={subHeaderStyle}>
          <div className={titleStyle}>
            {icon ? <Icon name={icon} /> : null}
            <EmbedText heading="h4">{title}</EmbedText>
          </div>
          {children}
        </div>
      }>
      {popoverChildren ? (
        <EmbedText body="b2" color="white">
          {popoverChildren}
        </EmbedText>
      ) : null}
    </Popover>
  );
};

const subHeaderStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  paddingLeft: 'sp2',
  paddingRight: 'sp1',
  paddingBottom: 'sp1',
  flexShrink: 0,
});

const titleStyle = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
});
