import cx from 'classnames';
import { FC } from 'react';

import { DateGroupToggleConfig, DateGroupToggleSelectTypes } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: DateGroupToggleConfig;

  updateConfig: (newConfig: DateGroupToggleConfig) => void;
};

export const DateGroupFilterTypeConfig: FC<Props> = ({ config, updateConfig }) => {
  const { filterType } = config;
  const selectedFilterType = filterType || DateGroupToggleSelectTypes.TOGGLE;

  const updateElemConfig = getUpdateConfigFunc(config, updateConfig);

  return (
    <div className={cx(styles.optionRow, styles.configInput)}>
      {Object.values(DateGroupToggleSelectTypes).map((selectType) => (
        <div
          className={cx(styles.option, {
            [styles.selectedOption]: selectedFilterType === selectType,
          })}
          key={`option-${selectType}`}
          onClick={() => {
            updateElemConfig((draft) => (draft.filterType = selectType));
          }}>
          {selectType}
        </div>
      ))}
    </div>
  );
};
