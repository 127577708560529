import produce from 'immer';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { STRING, SCATTER_X_AXIS_TYPES, SCATTER_Y_AXIS_TYPES } from 'constants/dataConstants';
import { OPERATION_TYPES, V2ScatterPlotInstructions } from 'constants/types';
import { DatasetSchema } from 'types/datasets';

import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: V2ScatterPlotInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function ScatterPlotConfig({ instructions, chartType, loading, schema }: Props) {
  const dispatch = useDispatch();

  const updateInstructions = (
    modifyInstructions: (instructions: V2ScatterPlotInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      modifyInstructions(draft);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <div>
      <SettingHeader name="X-Axis" />
      <DroppableColumnSection
        required
        allowedTypes={SCATTER_X_AXIS_TYPES}
        columns={instructions.xAxisColumn ? [{ column: instructions.xAxisColumn }] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => updateInstructions((instructions) => (instructions.xAxisColumn = col))}
        onRemoveCol={() =>
          updateInstructions((instructions) => (instructions.xAxisColumn = undefined))
        }
        schema={schema}
      />

      <SettingHeader name="Y-Axis" />
      <DroppableColumnSection
        required
        allowedTypes={SCATTER_Y_AXIS_TYPES}
        columns={instructions.yAxisColumn ? [{ column: instructions.yAxisColumn }] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => updateInstructions((instructions) => (instructions.yAxisColumn = col))}
        onRemoveCol={() =>
          updateInstructions((instructions) => (instructions.yAxisColumn = undefined))
        }
        schema={schema}
      />

      {instructions.xAxisColumn?.type === STRING ? null : (
        <>
          <SettingHeader name="Grouping" />
          <DroppableColumnSection
            columns={instructions.groupingColumn ? [{ column: instructions.groupingColumn }] : []}
            disableEdits={loading}
            maxCols={1}
            onColAdded={(col) =>
              updateInstructions((instructions) => (instructions.groupingColumn = col))
            }
            onRemoveCol={() =>
              updateInstructions((instructions) => (instructions.groupingColumn = undefined))
            }
            schema={schema}
          />
        </>
      )}
    </div>
  );
}
