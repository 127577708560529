import * as Dialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Breadcrumbs, Button, IconButton, Link, sprinkles } from 'components/ds';

import * as styles from './index.css';

type BaseProps = {
  /**
   * Title to place in header of side sheet.
   */
  title: string;
  /**
   * Children to render inside side sheet content.
   */
  children: JSX.Element | JSX.Element[];
  /**
   * Component that triggers visibility of side sheet when selected.
   */
  trigger?: JSX.Element;
  /**
   * ClassName to override base style of side sheet.
   */
  className?: string;
  /**
   * Test ID for selecting component in tests.
   */
  'data-testid'?: string;
  /**
   * Render side sheet in a portal contained by the ID.
   */
  portalContainerId?: string;
};

type ControlProps = {
  /**
   * Internal path to navigate to when `up` navigation button is clicked.
   * If not provided, button is not rendered.
   */
  prevNavRoute?: string;
  /**
   * Internal path to navigate to when `down` navigation button is clicked.
   * If not provided, button is not rendered.
   */
  nextNavRoute?: string;
  /**
   * Action to perform when close button is clicked.
   */
  onCloseClick?: () => void;
  /**
   * Action to perform when clicking outside side sheet.
   */
  onClickOutside?: () => void;
  /**
   * Gives manual control over whether the side sheet is open. If not provided, side sheet
   * uses Radix's built-in trigger API for open and close actions.
   */
  isOpen?: boolean;
};

type HeaderProps = {
  /**
   * Breadcrumb text items. Rendered using `Breadcrumbs` Design System component.
   */
  breadcrumbs?: string[];
  /**
   * Props for top link button. Route is internal path to navigate to when top link button is clicked,
   * and text is custom text to add to the link button. If provided, side sheet has link style button
   * in header. Otherwise, button is not present at all.
   */
  topLink?: { route: string; text: string };
};

type FooterButtonProps = {
  /**
   * Function for footer button click action.
   */
  onClick: () => void;
  /**
   * Custom text to add to footer button.
   */
  text?: string;
  /**
   * State describing whether the action is still in progress.
   * Used to set visual state of footer button.
   */
  loading?: boolean;
  /**
   * State describing whether the footer button is clickable.
   * Used to set visual state of footer button.
   */
  disabled?: boolean;
};

type FooterProps = {
  /**
   * Primary action button drawn on the right side of the side sheet's footer.
   * If both `primaryButtonProps` and `secondaryButtonProps` are not provided, this button is not drawn.
   */
  primaryButtonProps?: FooterButtonProps;
  /**
   * Secondary action button drawn on the left side of the side sheet's footer.
   * If both `primaryButtonProps` and `secondaryButtonProps` are not provided, this button is not drawn.
   */
  secondaryButtonProps?: FooterButtonProps;
};

export type Props = BaseProps & ControlProps & HeaderProps & FooterProps;

const SideSheet: FC<Props> = ({
  'data-testid': testId,
  title,
  children,
  className,
  portalContainerId,
  breadcrumbs,
  topLink,
  trigger,
  prevNavRoute,
  nextNavRoute,
  onCloseClick,
  onClickOutside,
  isOpen,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const sideSheetContent = (
    <Dialog.Content
      className={cx(styles.dialogContainer, className)}
      onInteractOutside={onClickOutside}>
      <div className={styles.outerContainer} data-testid={testId}>
        <div className={styles.controlButtonContainer}>
          <Dialog.Close asChild>
            <IconButton
              className={styles.controlButton}
              data-testid="side-sheet-close-button"
              name="cross"
              onClick={onCloseClick}
            />
          </Dialog.Close>
          {prevNavRoute ? (
            <RouterLink data-testid="side-sheet-up-button" to={prevNavRoute}>
              <IconButton className={styles.controlButton} name="arrow-up" />
            </RouterLink>
          ) : null}
          {nextNavRoute ? (
            <RouterLink data-testid="side-sheet-down-button" to={nextNavRoute}>
              <IconButton className={styles.controlButton} name="arrow-down" />
            </RouterLink>
          ) : null}
        </div>
        <div className={styles.sheetContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
              {breadcrumbs ? <Breadcrumbs items={breadcrumbs.map((text) => ({ text }))} /> : null}
              <p
                className={sprinkles({
                  heading: 'h2',
                  marginBottom: 'sp0',
                  truncateText: 'ellipsis',
                })}
                title={title}>
                {title}
              </p>
            </div>
            {topLink ? (
              <Link data-testid="side-sheet-link-button" to={topLink.route} variant="primary">
                {topLink.text}
              </Link>
            ) : null}
          </div>

          <div className={styles.contentContainer}>{children}</div>

          {primaryButtonProps && secondaryButtonProps ? (
            <div className={styles.footerContainer}>
              <Button
                fillWidth
                data-testid="side-sheet-secondary-button"
                variant="secondary"
                {...secondaryButtonProps}>
                {secondaryButtonProps?.text ?? 'Discard'}
              </Button>
              <Button
                fillWidth
                data-testid="side-sheet-primary-button"
                variant="primary"
                {...primaryButtonProps}>
                {primaryButtonProps?.text ?? 'Update'}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Dialog.Content>
  );

  return (
    <Dialog.Root modal open={isOpen}>
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      {portalContainerId ? <Dialog.Portal>{sideSheetContent}</Dialog.Portal> : sideSheetContent}
    </Dialog.Root>
  );
};

export { SideSheet };
