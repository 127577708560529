import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { UpdateSortCol } from 'actions/customerReportActions';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { Popover, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { SortInfo, SortOrder } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { updateSort, updateSortCol } from 'reportBuilderContent/reducers/reportEditingReducer';
import { SortableColumn } from 'utils/customerReportUtils';
import { isEqual, sortBy } from 'utils/standard';

type Props = { columns: SortableColumn[]; sort: SortInfo[] | undefined };

export const SortMenu: FC<Props> = ({ columns, sort }) => {
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openCol, setOpenCol] = useState<string>();
  const [addingSort, setAddingSort] = useState(false);

  useEffect(() => setAddingSort(!sort?.length), [sort?.length]);

  const colDisplayNames = useMemo(() => {
    const nameToDisplay: Record<string, string> = {};
    columns.forEach((col) => (nameToDisplay[col.name] = col.display));
    return nameToDisplay;
  }, [columns]);

  const colOptions = useMemo(() => {
    const selectedCols = new Set(sort?.map((sortItem) => sortItem.column.name));
    const options: DropdownOption[] = [];
    columns.forEach((col) => {
      if (selectedCols.has(col.name)) return;
      options.push({ name: col.display, value: col.name, icon: SCHEMA_TYPE_ICON_MAP[col.type] });
    });
    return sortBy(options, 'name');
  }, [columns, sort]);

  const { icon, sortText } = useMemo(() => {
    let icon: IconName | undefined = 'arrow-down-arrow-up';
    let sortText = 'Sort';

    if (sort?.length === 1) {
      const currSort = sort[0];
      icon = currSort.order === SortOrder.ASC ? 'arrow-up' : 'arrow-down';
      sortText = `Sorted by ${colDisplayNames[currSort.column.name]}`;
    } else if (sort && sort?.length > 1) {
      sortText = `Sorted by ${sort.length} columns`;
    }
    return { icon, sortText };
  }, [sort, colDisplayNames]);

  const sortList = useMemo(() => {
    return [...(sort || []), ...(addingSort ? [EMPTY_COL] : [])];
  }, [addingSort, sort]);

  const renderSortableItem = (colName: string, sortOrder: SortOrder) => {
    const isEmptyCol = colName === EMPTY_NAME;
    const isAsc = sortOrder === SortOrder.ASC;

    const update = (update: Partial<UpdateSortCol>) =>
      dispatch(updateSortCol({ colName, ...update }));

    return (
      <SortableListItem key={colName} sortId={colName}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          <SortableListItemDragHandle />
          <EmbeddedDropdownMenu
            canSearch
            menuOptions={colOptions}
            onClick={(val) => update({ newColName: val, isNew: isEmptyCol })}
            // When colName changes it rerenders menu and causes leak if not done like this
            onOpenChange={(open) => setOpenCol(open ? colName : undefined)}
            open={openCol === colName}>
            <EmbeddedDropdownButton
              className={sprinkles({ flex: 1 })}
              placeholder="Column"
              selectedName={colDisplayNames[colName]}
            />
          </EmbeddedDropdownMenu>
          <EmbedButton
            disabled={isEmptyCol}
            icon={isAsc ? 'arrow-up-short-wide' : 'arrow-down-wide-short'}
            onClick={() => update({ newOrder: isAsc ? SortOrder.DESC : SortOrder.ASC })}
            variant="tertiary"
          />
          <EmbedButton
            disabled={isEmptyCol && !sort?.length}
            icon="trash"
            onClick={() => (isEmptyCol ? setAddingSort(false) : update({ toDelete: true }))}
            variant="tertiary"
          />
        </div>
      </SortableListItem>
    );
  };

  return (
    <Popover
      align="end"
      isOpen={isMenuOpen}
      onOpenChange={(open) => !openCol && setIsMenuOpen(open)}
      trigger={
        <EmbedButton icon={icon} variant="tertiary">
          {sortText}
        </EmbedButton>
      }
      width="large">
      <div className={sprinkles({ paddingX: 'sp2', paddingY: 'sp3' })}>
        <EmbedText heading="h3">Sort by</EmbedText>
      </div>
      <div className={sortableListClass}>
        <SortableList
          getIdFromElem={(sortItem) => sortItem.column.name}
          onListUpdated={(newList) => {
            const filtered = newList.filter(({ column }) => column.name !== EMPTY_NAME);
            if (!isEqual(filtered, sort)) dispatch(updateSort(filtered));
          }}
          sortableItems={sortList}>
          {sortList.map(({ column, order }) => renderSortableItem(column.name, order))}
        </SortableList>
      </div>
      <div className={sprinkles({ padding: 'sp2' })}>
        <EmbedButton fillWidth icon="plus" onClick={() => setAddingSort(true)} variant="secondary">
          Add sort
        </EmbedButton>
      </div>
    </Popover>
  );
};

const EMPTY_NAME = '_empty';
const EMPTY_COL: SortInfo = { column: { name: EMPTY_NAME }, order: SortOrder.ASC };

const sortableListClass = sprinkles({
  overflowY: 'auto',
  flexItems: 'column',
  gap: 'sp1',
  paddingX: 'sp2',
});
