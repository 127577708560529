import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { EmbeddedRequestInfo } from 'reducers/dashboardLayoutReducer';
import { updateCustomerDashboardState } from 'reducers/embedDashboardReducer';
import { CustomerDashboardState, CustomerTableState } from 'types/customerDashboardStateTypes';
import { createDebouncedFn } from 'utils/general';
import { cloneDeep } from 'utils/standard';
import { makeThunkRequest } from 'utils/thunkUtils';

type Thunk = ThunkAction<void, EmbedReduxState, unknown, AnyAction>;

const debounceFn = createDebouncedFn(2000);

export const saveCustomerDashboardStateThunk =
  (dpId: string, tableState: CustomerTableState | null): Thunk =>
  (dispatch, getState) => {
    const { embedDashboard, dashboardInteractions, dashboardLayout } = getState();
    const requestInfo = dashboardLayout.requestInfo;
    const customerState = embedDashboard.customerDashboardState;

    if (
      !dashboardInteractions.interactionsInfo.shouldPersistCustomerState ||
      requestInfo.type !== 'embedded'
    )
      return;

    const newState: CustomerDashboardState = customerState
      ? cloneDeep(customerState)
      : { panelStates: {} };

    if (tableState === null) {
      if (dpId in newState.panelStates) delete newState.panelStates[dpId];
    } else newState.panelStates[dpId] = tableState;

    dispatch(updateCustomerDashboardState(newState));
    debounceFn(() => dispatch(saveCustomerDashboardState({ newState, requestInfo })));
  };

type SaveCustomerDashboardStateParams = {
  newState: CustomerDashboardState;
  requestInfo: EmbeddedRequestInfo;
};

type SaveCustomerDashboardStateBody = {
  config: CustomerDashboardState;
  dashboard_embed_id: string;
};

const saveCustomerDashboardState = createAsyncThunk<
  {},
  SaveCustomerDashboardStateParams,
  { state: EmbedReduxState }
>(ACTION.SAVE_CUSTOMER_DASHBOARD_STATE, async ({ newState, requestInfo }) => {
  const postData: SaveCustomerDashboardStateBody = {
    config: newState,
    dashboard_embed_id: requestInfo.resourceEmbedId,
  };

  const requestConfig = createApiRequestConfig(
    'embed/save_customer_state/',
    'POST',
    postData,
    requestInfo.customerToken,
    requestInfo.jwt,
  );

  return makeThunkRequest(requestConfig, 'Error saving editable section');
});
