import { produce } from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { Select, Switch, Input, sprinkles, Button } from 'components/ds';
import {
  ColumnWidths,
  OPERATION_TYPES,
  COLUMN_FITS,
  VisualizeTableInstructions,
  ColumnFitOptions,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { DatasetSchema } from 'types/datasets';
import { sortSchemaByOrderedColumnNames } from 'utils/tableSchemaUtils';

import * as styles from './index.css';

type Props = {
  instructions: VisualizeTableInstructions;
  schema: DatasetSchema;
};

export const TableLayoutConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();

  const enableNewGrid = useSelector(
    (state: ReduxState) => !!state.currentUser?.team?.feature_flags.enable_new_grid,
  );

  const orderedColumnNames = instructions.orderedColumnNames;
  const orderedSchema = sortSchemaByOrderedColumnNames(schema, orderedColumnNames);
  const columnFit = instructions.columnFit ?? COLUMN_FITS.FILL;
  const columnWidths = instructions.columnWidths as ColumnWidths | undefined;

  const hasAWidthSet = Object.values(columnWidths ?? {}).some((width) => width > 0);

  const updateInstructions = (instructions: VisualizeTableInstructions) => {
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_TABLE));
  };

  return (
    <>
      <div className={sprinkles({ marginY: 'sp1.5' })}>
        <div className={sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1.5' })}>
          {enableNewGrid ? (
            <Select
              infoText='"Fill" sizes columns to fill the table and cannot be resized. "Fit" sizes columns to their content and may result in a scrollbar. "Wrap text" may only be turned on with "Fill".'
              label="Column Fit"
              onChange={(value) =>
                updateInstructions({ ...instructions, columnFit: value as COLUMN_FITS })
              }
              selectedValue={columnFit}
              values={ColumnFitOptions.filter((option) => {
                if (instructions.shouldTruncateText) return true;

                // disallow cell and header if wrap text set
                return option.value === COLUMN_FITS.FILL;
              })}
            />
          ) : null}
        </div>

        {enableNewGrid ? (
          <Switch
            className={sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' })}
            disabled={
              instructions.columnFit !== undefined && instructions.columnFit !== COLUMN_FITS.FILL
            }
            label={{
              text: 'Wrap text',
              infoText:
                'Some features may not work as intended when on. It is strongly recommended to use a fixed row height when on. Applies to header and body cells. "Wrap text" may only be turned on with "Column Fit" set to "Fill".',
            }}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.shouldTruncateText = !draft.shouldTruncateText;
              });

              updateInstructions(newInstructions);
            }}
            switchOn={!instructions.shouldTruncateText}
          />
        ) : null}

        <div className={sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' })}>
          <Button
            fillWidth
            disabled={!hasAWidthSet}
            onClick={() => updateInstructions({ ...instructions, columnWidths: {} })}
            variant="secondary">
            Clear Column Widths
          </Button>
        </div>

        <SortableList
          getIdFromElem={(col) => col.name}
          onListUpdated={(newList) =>
            updateInstructions({
              ...instructions,
              orderedColumnNames: newList.map((col) => col.name),
            })
          }
          sortableItems={orderedSchema}>
          {orderedSchema.map((col) => (
            <SortableListItem key={col.name} sortId={col.name}>
              <div className={styles.item}>
                <SortableListItemDragHandle className={styles.dragIcon} />
                <div
                  className={sprinkles({
                    flex: 2,
                    paddingRight: 'sp.5',
                    truncateText: 'ellipsis',
                  })}>
                  <span className={styles.itemText}>{col.friendly_name || col.name}</span>
                </div>
                <Input
                  showInputButton
                  className={sprinkles({ flex: 1 })}
                  defaultValue={columnWidths?.[col.name] ? String(columnWidths[col.name]) : ''}
                  onSubmit={(newValue) => {
                    const newWidth = parseInt(newValue);

                    const newInstructions = produce(instructions, (draft) => {
                      if (!draft.columnWidths) draft.columnWidths = {};
                      const colWidths = draft.columnWidths as ColumnWidths;
                      if (newWidth > 0) {
                        colWidths[col.name] = newWidth;
                      } else if (col.name in draft.columnWidths) {
                        delete colWidths[col.name];
                      }
                    });
                    updateInstructions(newInstructions);
                  }}
                  placeholder="Width"
                />
              </div>
            </SortableListItem>
          ))}
        </SortableList>
      </div>
    </>
  );
};
