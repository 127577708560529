import { TypeFooterRow } from '@inovua/reactdatagrid-community/types';
import { TypeSummaryReducer } from '@inovua/reactdatagrid-community/types/TypeColumn';
import {
  TypeColumn,
  TypeComputedColumn,
  TypeComputedProps,
  TypeSortInfo,
  TypeRowProps,
} from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';
import {
  FC,
  MutableRefObject,
  Suspense,
  useMemo,
  lazy,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCopyToClipboard } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { DataGridPaginator, PaginatorProps } from 'components/ds/DataGrid/paginator';
import { EmbedSpinner } from 'components/embed';
import { MENU } from 'constants/dataConstants';
import {
  ColumnColorTracker,
  RowLevelAction,
  COLUMN_FITS,
  ColumnWidths,
  SortInfo,
} from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { TABLE_ROW_HEIGHT, ROW_LEVEL_ACTIONS_ROW_HEIGHT } from 'styles/useBaseDataTableStyles';
import { ColumnConfigs } from 'types/columnTypes';
import { DashboardVariableMap, MetricsByColumn } from 'types/dashboardTypes';
import { DatasetSchema, DatasetRow, DatasetColumn } from 'types/datasets';

import * as styles from './index.css';
import { EmbedPaginator } from './paginator';
import { useEmbedColumns } from './useEmbedColumns';
import { useAutosize } from './utils';
import '@inovua/reactdatagrid-enterprise/index.css';

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  className?: string;
  // Allows an override of default columns that are generated from schema, rows, and columnConfigs
  columns?: TypeColumn[];
  columnConfigs?: ColumnConfigs;
  // Config for autosizing columns
  columnFit?: COLUMN_FITS;
  columnWidths?: ColumnWidths;
  // Datasets for joining tables
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  // TODO: Doesn't disable cell border or tooltip custom styles. Remove when RB fully supports custom styles
  disableCustomStyles?: boolean;
  enableBoldFirstColumn?: boolean;
  enableBoldHeader?: boolean;
  // Lock first column position
  enableLockedFirstColumn?: boolean;
  // Fixes height of table based on row height
  fixedHeight?: boolean;
  footerRows?: TypeFooterRow[];
  hideHeaderMenu?: boolean;
  loading?: boolean;
  // Locks position of first row of data grid
  lockFirstRow?: boolean;
  // Schema for column metadata (e.g. name, type)
  schema: DatasetSchema;
  rowHeight?: number;
  rows: DatasetRow[];
  showCellBorders?: CellBordersType;
  shouldTruncateText?: boolean;
  ignoreInvalidDates?: boolean;
  // Whether or not to group by first column and only show one instance of the grouped value
  shouldVisuallyGroupByFirstColumn?: boolean;
  // Metrics for each column based on secondary data, for progress bar and gradient cells
  metricsByColumn?: MetricsByColumn;
  // Props to show/configure DataGridPaginator
  paginatorProps?: PaginatorProps;
  // Tooltips for column headers
  columnTooltips?: ColumnTooltips;
  variables?: DashboardVariableMap;
  onColumnOrderChange?: (newOrder: string[]) => void;
  // Callback when column widths change
  onColumnResize?: (columnInfo: ColumnResizeType[]) => void;
  onSortColumn?: (sort: SortInfo[]) => void;
  onFilterColumn?: (column: DatasetColumn) => void;
  // Manually control data grid sorting
  sortInfo?: TypeSortInfo;
  isInitialSortDesc?: boolean;

  summaryReducer?: TypeSummaryReducer;
  colorTracker?: ColumnColorTracker;
  onRowSelectionChange?: (row: DatasetRow | undefined) => void;
  rowLevelActions?: RowLevelAction[];
};

export type CellBordersType = boolean | 'vertical' | 'horizontal';
export type ColumnResizeType = { column: TypeColumn; size?: number; width?: number; flex?: number };
export type ColumnTooltips = Record<string, string>;

const ROW_LEVEL_ACTION_COL_NAME = '_rowLevelActions';

type SelectedCellInfo = { index: [number, number]; value: string | number | undefined };

export const EmbedDataGrid: FC<Props> = ({
  className,
  columns,
  columnFit,
  columnWidths,
  loading,
  lockFirstRow,
  schema,
  fixedHeight,
  footerRows,
  rowHeight,
  rows,
  summaryReducer,
  ignoreInvalidDates,
  onColumnOrderChange,
  onColumnResize,
  onRowSelectionChange,
  paginatorProps,
  sortInfo,
  showCellBorders = true,
  shouldTruncateText,
  shouldVisuallyGroupByFirstColumn,
  disableCustomStyles,
  onSortColumn,
  onFilterColumn,
  colorTracker,
  rowLevelActions,
  ...rest
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();
  const [selectedCell, setSelectedCell] = useState<SelectedCellInfo>();
  const copy = useCopyToClipboard()[1];

  useHotkeys('mod+c', () => copy(String(selectedCell?.value)), {
    enabled: !onRowSelectionChange && !!selectedCell,
  });

  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const isRowLevelActionsEnabled = !!rowLevelActions?.length;

  const displaySchema = isRowLevelActionsEnabled
    ? [...schema, { name: ROW_LEVEL_ACTION_COL_NAME, type: MENU }]
    : schema;

  const disableActiveCell = () => setSelectedCell(undefined);

  const gridColumns = useEmbedColumns({
    columns,
    rows,
    schema: displaySchema,
    showCellBorders,
    containerRef: container,
    shouldTruncateText,
    shouldVisuallyGroupByFirstColumn,
    disableCustomStyles,
    onFilterColumn,
    onSortColumn,
    colorTracker,
    columnFit,
    columnWidths,
    rowLevelActions,
    disableActiveCell,
    ignoreInvalidDates,
    ...rest,
  });

  useAutosize(container, gridRef, gridColumns, columnFit, columnWidths, shouldTruncateText);

  const lockedRows = useMemo(() => {
    if (!lockFirstRow || rows.length < 1) return;

    const firstRowData = rows[0];

    return [
      {
        position: 'start' as 'start' | 'end',
        render: ({ column }: { column: TypeComputedColumn }) => {
          if (column.computedAbsoluteIndex === undefined || !column.name) return;
          const columnInfo = gridColumns[column.computedAbsoluteIndex];
          const value = firstRowData[column.name];
          return columnInfo.render?.({ isLockedFirstRow: true, rowIndex: 0, value });
        },
      },
    ];
  }, [lockFirstRow, rows, gridColumns]);

  const renderLoadMask = ({ visible }: { visible: boolean }) => {
    return visible ? <EmbedSpinner fillContainer className={styles.loadMask} /> : null;
  };

  // Unselect rows when new rows are loaded
  useEffect(() => {
    setSelectedCell(undefined);
    setSelectedRowIndex(undefined);
    onRowSelectionChange?.(undefined);
  }, [rows, onRowSelectionChange]);

  const handleRowSelectionChange = useCallback(
    ({ rowIndex, data }: TypeRowProps) => {
      if (!onRowSelectionChange) return;
      if (rowIndex === selectedRowIndex) {
        setSelectedRowIndex(undefined);
        onRowSelectionChange(undefined);
      } else {
        onRowSelectionChange(data);
        setSelectedRowIndex(rowIndex);
      }
    },
    [onRowSelectionChange, selectedRowIndex],
  );

  const minTableRowHeight = isRowLevelActionsEnabled
    ? ROW_LEVEL_ACTIONS_ROW_HEIGHT
    : TABLE_ROW_HEIGHT;

  const currRowHeight = Math.max(rowHeight || minTableRowHeight, minTableRowHeight);

  const height = !shouldTruncateText || isRowLevelActionsEnabled ? null : currRowHeight;
  // Height = number of rows + height of header * row height
  const containerHeight = fixedHeight ? (rows.length + 1) * currRowHeight : undefined;

  // Update key re-render when the values in the dep array change for virtualization purposes
  const key = useMemo(() => {
    return uuidv4();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFit,
    rowHeight,
    shouldTruncateText,
    columnWidths,
    shouldVisuallyGroupByFirstColumn,
    rows,
    ignoreInvalidDates,
  ]);

  // Attach a unique id to each row for selection purposes
  const indexedRows = useMemo(() => {
    const currRows = rows.map((row, idx) => ({ ...row, _id: idx }));
    return lockFirstRow ? currRows.slice(1) : currRows;
  }, [rows, lockFirstRow]);

  return (
    <div
      className={cx(styles.container, sprinkles({ parentContainer: 'fill', flexItems: 'column' }))}
      style={{ height: containerHeight }}>
      <Suspense
        fallback={
          <EmbedSpinner
            fillContainer
            className={embedSprinkles({ backgroundColor: 'containerFill' })}
          />
        }>
        <div className={sprinkles({ flex: 1 })} ref={container}>
          <ReactDataGrid
            columnUserSelect
            enableColumnAutosize
            nativeScroll
            activeCell={selectedCell?.index ?? null}
            activeIndex={selectedRowIndex ?? -1}
            className={cx(styles.grid, sprinkles({ height: 'fill' }), className)}
            columnOrder={displaySchema.map((col) => col.name)}
            columns={gridColumns}
            dataSource={indexedRows}
            footerRows={footerRows}
            // @ts-ignore - ReactDataGrid type doesn't allow null even though it's allowed
            headerHeight={height}
            idProperty={'_id'}
            key={key}
            licenseKey={process.env.REACT_APP_DATAGRID_KEY}
            loading={loading}
            lockedRows={lockedRows}
            minRowHeight={currRowHeight} // Variable row heights require a min row height
            onBatchColumnResize={onColumnResize}
            onBlur={onRowSelectionChange ? undefined : () => setSelectedCell(undefined)}
            onCellClick={
              onRowSelectionChange
                ? undefined
                : (_, props) =>
                    setSelectedCell({
                      index: [props.rowIndex, props.columnIndex],
                      value: props.data?.[props.name ?? ''],
                    })
            }
            onColumnOrderChange={onColumnOrderChange}
            onReady={setGridRef}
            onRowClick={onRowSelectionChange ? handleRowSelectionChange : undefined}
            renderLoadMask={renderLoadMask}
            reorderColumns={onColumnOrderChange !== undefined}
            rowClassName={cx({
              [styles.row]: !disableCustomStyles,
              [cx(sprinkles({ cursor: 'pointer' }), styles.hoverRow)]: !!onRowSelectionChange,
              [styles.someRowIsSelected]: selectedRowIndex !== undefined && !!onRowSelectionChange,
            })}
            rowHeight={height}
            // TODO LOOK INTO FOR COL RESIZING
            shareSpaceOnResize={columnFit === COLUMN_FITS.FILL}
            showActiveRowIndicator={false}
            showCellBorders={showCellBorders}
            showColumnMenuTool={false}
            showHoverRows={false}
            sortInfo={sortInfo}
            sortable={!!onSortColumn}
            summaryReducer={summaryReducer}
            // If we are using autosize, need to bump the column threshold to make sure hidden columns are sized appropriately
            virtualizeColumnsThreshold={
              !columnFit || columnFit === COLUMN_FITS.FILL ? undefined : 30
            }
          />
        </div>
        {paginatorProps ? (
          disableCustomStyles ? (
            <DataGridPaginator {...paginatorProps} currentRowCount={rows.length} />
          ) : (
            <EmbedPaginator {...paginatorProps} currentRowCount={rows.length} />
          )
        ) : null}
      </Suspense>
    </div>
  );
};
