import * as RadixPopover from '@radix-ui/react-popover';
import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { ModalSize } from 'components/ds/Modal';
import { FooterProps } from 'components/ds/Popover';
import { EmbedButton } from 'components/embed/EmbedButton';

import * as popoverStyles from '../../ds/Popover/index.css';

import * as styles from './index.css';

type ExternalStateProps = { isOpen: boolean; onOpenChange: (open: boolean) => void };
type InternalStateProps = { isOpen?: never; onOpenChange?: (open: boolean) => void };

type InteractionProps = ExternalStateProps | InternalStateProps;

type Props = InteractionProps &
  FooterProps & {
    disabled?: boolean;
    portalContainerId?: string;
    side?: 'top' | 'right' | 'bottom' | 'left';
    align?: 'start' | 'center' | 'end';
    width?: ModalSize;
    trigger: JSX.Element;
    title?: string;
    onBack?: () => void;
    showCloseButton?: boolean;
    className?: string;
  };

export const EmbedPopover: FC<Props> = ({
  disabled,
  portalContainerId,
  trigger,
  children,
  side = 'bottom',
  align = 'center',
  isOpen,
  onOpenChange,
  width,
  title,
  onBack,
  showCloseButton,
  primaryButtonProps,
  secondaryButtonProps,
  className,
}) => {
  const renderHeader = title || onBack || showCloseButton;
  const header = (
    <div className={popoverStyles.popoverTitle}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1.5' })}>
        {onBack ? <EmbedButton icon="arrow-left" onClick={onBack} variant="tertiary" /> : null}
        {title}
      </div>
      <RadixPopover.Close asChild>
        <EmbedButton icon="cross" variant="tertiary" />
      </RadixPopover.Close>
    </div>
  );

  const footer = (
    <div className={popoverStyles.popoverFooter}>
      {secondaryButtonProps ? (
        <EmbedButton
          fillWidth
          variant={secondaryButtonProps.type || 'tertiary'}
          {...secondaryButtonProps}>
          {secondaryButtonProps.text}
        </EmbedButton>
      ) : null}
      {primaryButtonProps ? (
        <EmbedButton fillWidth variant="primary" {...primaryButtonProps}>
          {primaryButtonProps.text}
        </EmbedButton>
      ) : null}
    </div>
  );

  const content = (
    <RadixPopover.Content
      align={align}
      className={cx(popoverStyles.animationStyles, styles.embedPopover({ width }), className)}
      side={side}
      sideOffset={4}>
      {renderHeader ? header : null}
      {children}
      {primaryButtonProps ? footer : null}
    </RadixPopover.Content>
  );

  return (
    <RadixPopover.Root onOpenChange={onOpenChange} open={isOpen}>
      <RadixPopover.Trigger asChild disabled={disabled}>
        {trigger}
      </RadixPopover.Trigger>
      {portalContainerId ? (
        <RadixPopover.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </RadixPopover.Portal>
      ) : (
        content
      )}
    </RadixPopover.Root>
  );
};
