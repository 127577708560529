import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { UpdateReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { AlertModal } from 'components/ds';
import { DeleteCustomAggModal } from 'pages/ReportBuilderEditor/DatasetEditor/DeleteCustomAggModal';
import { DeleteDatasetModal } from 'pages/ReportBuilderEditor/DatasetEditor/DeleteModal';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';

export enum DatasetModalType {
  DELETE = 'DELETE',
  SAVE = 'SAVE',
  SELECT_SCHEMA = 'SELECT_SCHEMA',
  DELETE_CUSTOM_AGG = 'DELETE_CUSTOM_AGG',
}

export type DatasetModal =
  | { type: DatasetModalType.DELETE }
  | { type: DatasetModalType.SAVE; isUnsavedSchema: boolean }
  | {
      type: DatasetModalType.SELECT_SCHEMA;
      schemaIdToSelect: number;
      isUnsavedSchema: boolean;
    }
  | { type: DatasetModalType.DELETE_CUSTOM_AGG; aggId: string };

type Props = {
  datasetId: string;
  openModal?: DatasetModal;
  setOpenModal: (openModal?: DatasetModal) => void;
  setHasShownSaveModal: (hasShownSaveModal: boolean) => void;
  updateDataset: (payload: UpdateReportBuilderDataset) => void;
};

export const DatasetModal: FC<Props> = ({
  openModal,
  setOpenModal,
  setHasShownSaveModal,
  datasetId,
  updateDataset,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => setOpenModal(undefined);

  if (!openModal) return null;

  switch (openModal.type) {
    case DatasetModalType.DELETE:
      return <DeleteDatasetModal datasetId={datasetId} onClose={handleClose} />;
    case DatasetModalType.SAVE:
      return (
        <AlertModal
          isOpen
          actionButtonProps={{
            onClick: () => {
              dispatch(fetchAppDataset({ datasetId: datasetId, save: true }));
              if (!openModal.isUnsavedSchema) setHasShownSaveModal(true);
            },
            text: 'Save',
            variant: 'primary',
          }}
          onClose={handleClose}
          title="Are you sure you want to save this query?">
          If your users have created reports using this dataset and you change the schema, or
          add/remove columns, their reports will not be able to load the correct data anymore.
        </AlertModal>
      );
    case DatasetModalType.SELECT_SCHEMA:
      return (
        <AlertModal
          isOpen
          actionButtonProps={{
            onClick: () => {
              updateDataset({
                datasetId: datasetId,
                schemaId: openModal.schemaIdToSelect,
              });
              if (!openModal.isUnsavedSchema) setHasShownSaveModal(true);
            },
            text: 'Save',
            variant: 'primary',
          }}
          onClose={handleClose}
          title="Are you sure you want to change the schema?">
          If your users have created reports using this dataset and you change the schema, or
          add/remove columns, their reports will not be able to load the correct data anymore.
        </AlertModal>
      );
    case DatasetModalType.DELETE_CUSTOM_AGG:
      return (
        <DeleteCustomAggModal aggId={openModal.aggId} datasetId={datasetId} onClose={handleClose} />
      );
    default:
      return null;
  }
};
