import * as RadixSwitch from '@radix-ui/react-switch';
import { FC } from 'react';

import * as styles from './index.css';

export type Props = {
  disabled?: boolean;
  switchOn: boolean;

  onChange: (newValue: boolean) => void;
};

export const EmbedSwitch: FC<Props> = ({ disabled, switchOn, onChange }) => {
  return (
    <RadixSwitch.Root
      checked={switchOn}
      className={styles.switchRoot}
      disabled={disabled}
      onCheckedChange={onChange}>
      <RadixSwitch.Thumb className={styles.switchThumb} />
    </RadixSwitch.Root>
  );
};
