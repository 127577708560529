import cx from 'classnames';
import { MouseEventHandler, FC } from 'react';

import * as styles from 'components/embed/EmbedTabs/index.css';

type Props = {
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
};
export const EmbedTab: FC<Props> = ({ isSelected, onClick, className, children }) => {
  return (
    <div
      className={cx(
        styles.tabOption,
        isSelected ? styles.tabSelected : styles.tabDefault,
        className,
      )}
      onClick={onClick}>
      {children}
    </div>
  );
};
