import { FC } from 'react';

import emptyStateArrow from 'images/app/emptyStateArrow.png';

import { sprinkles } from './ds';

type Props = {
  text: string;
};

export const EmptyPageActionCallout: FC<Props> = ({ text }) => {
  return (
    <div
      className={sprinkles({ marginLeft: 'auto', position: 'relative' })}
      style={{ marginRight: 100 }}>
      <img
        alt="call out indicating an action to take on an empty page"
        className={sprinkles({ marginRight: 'sp1.5' })}
        src={emptyStateArrow}
        style={{ float: 'right' }}
      />
      <p className={textClass} style={{ top: 40, right: 100, width: 175 }}>
        {text}
      </p>
    </div>
  );
};

const textClass = sprinkles({
  position: 'absolute',
  textAlign: 'right',
  color: 'gray11',
  body: 'b2',
});
