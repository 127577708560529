import { Button, Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedInput } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import * as styles from './styles.css';

type Props = {
  className?: string;
  currentPage: number;
  maxPageNumber: number;
  onNewPage: (newPage: string) => void;
};

// This component is only used in the old data table
export const EmbedTablePager: FC<Props> = ({ currentPage, maxPageNumber, onNewPage }) => {
  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === maxPageNumber;

  return (
    <div className={cx(rootClass, embedSprinkles({ body: 'secondary' }))}>
      <Button
        minimal
        disabled={onFirstPage}
        icon={<Icon className={styles.pagerButton} icon="chevron-left" />}
        onClick={() => !onFirstPage && onNewPage(String(currentPage - 1))}
      />
      <span>Page</span>
      <EmbedInput
        className={styles.embedPagerInput}
        defaultValue={String(currentPage)}
        onSubmit={onNewPage}
      />
      <div>{`of ${maxPageNumber}`}</div>
      <Button
        minimal
        disabled={onLastPage}
        icon={<Icon className={styles.pagerButton} icon="chevron-right" />}
        onClick={() => !onLastPage && onNewPage(String(currentPage + 1))}
      />
    </div>
  );
};

const rootClass = sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', whiteSpace: 'nowrap' });
