import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { TableDataset } from 'actions/dataSourceActions';
import { Button, Select, Input, sprinkles } from 'components/ds';
import { SchemaTableViewer } from 'components/resource/SchemaTableViewer';
import { FidoTableView } from 'reducers/fidoReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getSchemaTablesMap } from 'reducers/selectors';

type Props = {
  selectedDatasetSchemaId: number | string | undefined;
  onSelectSchema?: (schemaId: number) => void;
  isReportBuilder?: boolean;
};

/**
 * Lets the user choose a schema from a dropdown list, and then displays the tables in that schema
 * Also provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const SchemaViewer: FC<Props> = ({
  selectedDatasetSchemaId,
  onSelectSchema,
  isReportBuilder,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { parentSchemas, schemaTablesMap } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      parentSchemas: getParentSchemasList(state),
    }),
    shallowEqual,
  );

  const schemaId: number | undefined = useMemo(() => {
    if (typeof selectedDatasetSchemaId === 'string') {
      return parentSchemas.find((s) => s.fido_id === String(selectedDatasetSchemaId))?.id ?? -1;
    }
    return selectedDatasetSchemaId as number | undefined;
  }, [selectedDatasetSchemaId, parentSchemas]);

  const schemaOptions = useMemo(
    () => parentSchemas.map((schema) => ({ value: String(schema.id), label: schema.name })),
    [parentSchemas],
  );

  const tableDatasets: TableDataset[] = useMemo(() => {
    if (!schemaId || !schemaTablesMap || !selectedDatasetSchemaId) return [];

    return Object.values(
      // TODO when we clean up FIDO, we should really clean up when the embeddo ID is used vs the fido id....
      schemaTablesMap[schemaId === selectedDatasetSchemaId ? schemaId : selectedDatasetSchemaId] ??
        {},
    ).sort((a: TableDataset | FidoTableView, b) => {
      if ('table_name' in a) return a.table_name.localeCompare(b.table_name);
      return a.tableName.localeCompare(b.tableName);
    });
  }, [schemaTablesMap, schemaId, selectedDatasetSchemaId]);

  const rootStyle = isReportBuilder ? { maxHeight: 480 } : { minWidth: 320, width: 320 };
  const className = isReportBuilder
    ? sprinkles({ border: 1, borderRadiusBottom: 8, backgroundColor: 'white' })
    : undefined;

  return (
    <div className={cx(rootClass, className)} style={rootStyle}>
      <div className={sprinkles({ flexItems: 'column', overflow: 'hidden' })}>
        {onSelectSchema ? (
          <Select
            className={sprinkles({ borderBottom: 1, borderColor: 'outline', padding: 'sp1.5' })}
            onChange={(value) => onSelectSchema(parseInt(value))}
            selectedValue={schemaId?.toString()}
            values={schemaOptions}
          />
        ) : null}
        <Input
          className={sprinkles({ padding: 'sp2' })}
          leftIcon="search"
          onChange={setSearchQuery}
          placeholder="Search..."
          value={searchQuery}
        />
        <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>
          {tableDatasets.length === 0 ? (
            <div className={sprinkles({ flexItems: 'centerColumn', padding: 'sp1.5' })}>
              <div className={sprinkles({ marginBottom: 'sp1', textAlign: 'center' })}>
                This schema has not been synced. Click below to sync the tables.
              </div>
              <Button to={`/sync-tables/${schemaId}`} variant="primary">
                Sync Tables
              </Button>
            </div>
          ) : (
            tableDatasets.map((table) => (
              <SchemaTableViewer
                key={table.id}
                searchQuery={searchQuery.toLowerCase()}
                table={table}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'column',
  overflow: 'hidden',
  borderColor: 'outline',
});
