import produce from 'immer';
import { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';

import { Dashboard } from 'actions/dashboardActions';
import { EndUser } from 'actions/teamActions';
import { InfoCard } from 'components/InfoCard';
import { Intent, Switch, sprinkles, Input, IconButton, Button, Select } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { some } from 'utils/standard';

type Props = {
  dashboards: Dashboard[];
  endUsers: EndUser[];
  updateEndUsers: (newEndUsers: EndUser[]) => void;
  selectedDashboardId?: number;
  updateSelectedDashboardId: (id: number | undefined) => void;
};

export const EndUserPortalConfiguration: FC<Props> = ({
  endUsers,
  dashboards,
  updateEndUsers,
  updateSelectedDashboardId,
  selectedDashboardId,
}) => {
  const enableCustomerPortal = useSelector(
    (state: ReduxState) => state.currentUser.team?.entitlements.enable_customer_portal,
  );

  const [showEndUserPortalConfig, setShowEndUserPortalConfig] = useState(
    !!selectedDashboardId || some(endUsers, (endUser) => !!endUser.permissioned_dashboard_id),
  );
  const [newUserId, setNewUserId] = useState(-1);

  const dashboardOptions = dashboards.map((dashboard) => ({
    value: String(dashboard.id),
    label: dashboard.name,
  }));

  const addUser = () => {
    updateEndUsers(
      produce(endUsers, (draft) => {
        draft.push({ id: newUserId, email: '' } as EndUser);
      }),
    );
    setNewUserId(newUserId - 1);
  };

  return (
    <>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
          width: 'fill',
          heading: 'h3',
        })}>
        Customer Portal
        <Switch
          onChange={(newValue) => {
            // if the section is being closed, delete all users and deselect the dashboard
            if (!newValue) {
              updateEndUsers([]);
              updateSelectedDashboardId(undefined);
            } else if (endUsers.length === 0) addUser();

            setShowEndUserPortalConfig(newValue);
          }}
          switchOn={showEndUserPortalConfig}
        />
      </div>
      {showEndUserPortalConfig ? (
        <div
          className={sprinkles({
            backgroundColor: 'elevationMid',
            padding: 'sp1.5',
            marginTop: 'sp1.5',
            flexItems: 'column',
            borderRadius: 8,
          })}>
          {!enableCustomerPortal ? (
            <InfoCard
              error
              className={sprinkles({ marginBottom: 'sp2' })}
              text="Customer Portal will not be accessible on your current plan. You may manage it and add end users to customers, but you will need to upgrade to access the portal."
            />
          ) : null}
          <Select
            label="Dashboard"
            onChange={(value) => updateSelectedDashboardId(parseInt(value))}
            placeholder="Select a Dashboard"
            selectedValue={selectedDashboardId?.toString()}
            values={dashboardOptions}
          />
          <div className={sprinkles({ marginTop: 'sp2' })}>
            <div className={sprinkles({ heading: 'h4', color: 'contentPrimary' })}>
              User&rsquo;s Emails
            </div>
            <div className={sprinkles({ color: 'contentSecondary', body: 'b3' })}>
              Removing an email will revoke your user&rsquo;s access.
            </div>
            {endUsers.map((endUser) => (
              <div
                className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', marginTop: 'sp1' })}
                key={endUser.id}>
                <Input
                  className={sprinkles({ flex: 1 })}
                  defaultValue={endUser.email}
                  intent={validator.isEmail(endUser.email) ? Intent.SUCCESS : Intent.ERROR}
                  onSubmit={(value) => {
                    updateEndUsers(
                      produce(endUsers, (draft) => {
                        const user = draft.find((user) => user.id === endUser.id);
                        if (user) user.email = value;
                      }),
                    );
                  }}
                  placeholder="Enter an email"
                />
                <IconButton
                  name="cross"
                  onClick={() => updateEndUsers(endUsers.filter((user) => user.id !== endUser.id))}
                  variant="destructive"
                />
              </div>
            ))}
            <Button className={sprinkles({ marginTop: 'sp2' })} onClick={addUser}>
              Add An Email
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};
