import cx from 'classnames';
import {
  forwardRef,
  MouseEvent as ReactMouseEvent,
  DetailedHTMLProps,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
  Ref,
} from 'react';

import { Tooltip } from 'components/ds';
import { ButtonVariant } from 'components/ds/Button';
import { Icon, IconName } from 'components/ds/Icon';
import { Props as TooltipProps } from 'components/ds/Tooltip';
import { sprinkles } from 'components/ds/sprinkles.css';

import * as styles from './index.css';

type BaseProps = {
  children?: ReactNode;
  icon?: IconName;
  fillWidth?: boolean;
  variant: ButtonVariant;
  // Shows an arrow to the right of the content for links
  linkIcon?: boolean;
  // Iff tooltipProps.text
  tooltipProps?: TooltipProps;
};

// Allows component to take in any props a <button> can take
// Omits props that we use for this component
type HTMLButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'disabled' | 'children' | 'ref'
>;

interface ButtonProps extends BaseProps, HTMLButtonProps {
  disabled?: boolean;
  href?: never;
  loading?: boolean;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  openInNewTab?: never;
  to?: never;
}

// Allows component to take in any props that an <a> can take
// Omits props that we use for this component
type HTMLAnchorProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'onClick' | 'disabled' | 'children' | 'ref'
>;

interface LinkProps extends BaseProps, HTMLAnchorProps {
  disabled?: never;
  loading?: never;
  onClick?: never;
  openInNewTab?: boolean;
  to: string;
}

export type Props = ButtonProps | LinkProps;

export const EmbedButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      linkIcon,
      className,
      children,
      disabled,
      fillWidth,
      icon,
      loading,
      onClick,
      openInNewTab,
      to,
      variant,
      tooltipProps,
      ...props
    },
    ref,
  ) => {
    const iconOnly = icon && !children;

    const sharedProps = {
      ...props,
      className: cx(
        styles.base({
          fillWidth,
          disabled,
          iconOnly,
          variant,
        }),
        className,
      ),
    };

    const childElements = (
      <>
        {loading ? (
          <Icon spin name="spinner" size="md" />
        ) : (
          <>
            {icon ? <Icon name={icon} size={variant === 'secondary' ? 'sm' : 'md'} /> : null}
            {children ? (
              <span className={sprinkles({ truncateText: 'ellipsis' })}>{children}</span>
            ) : null}
            {linkIcon ? (
              <Icon className={sprinkles({ paddingLeft: 'sp1' })} name="arrow-right" size="md" />
            ) : null}
          </>
        )}
      </>
    );

    let baseElement: JSX.Element;
    if (to) {
      baseElement = (
        <>
          <a
            {...(sharedProps as LinkProps)}
            href={to}
            ref={ref as Ref<HTMLAnchorElement>}
            rel="noopener noreferrer"
            target={openInNewTab ? '_blank' : '_top'}>
            {childElements}
          </a>
        </>
      );
    } else {
      baseElement = (
        <>
          <button
            {...(sharedProps as ButtonProps)}
            aria-label={`${icon} icon`}
            disabled={disabled || loading}
            onClick={onClick}
            ref={ref as Ref<HTMLButtonElement>}>
            {childElements}
          </button>
        </>
      );
    }

    if (tooltipProps?.text) {
      return (
        <Tooltip {...tooltipProps}>
          {disabled ? <span tabIndex={0}>{baseElement}</span> : baseElement}
        </Tooltip>
      );
    }

    return baseElement;
  },
);
EmbedButton.displayName = 'EmbedButton';
