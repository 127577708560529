import { ColorPalette } from 'constants/types';

export const DEFAULT_CATEGORY_COLORS = [
  '#FFC940',
  '#AD99FF',
  '#FF7373',
  '#FFB366',
  '#3DCC91',
  '#3DCC91',
  '#48AFF0',
  '#A67908',
  '#5642A6',
  '#A82A2A',
  '#A66321',
  '#0A6640',
  '#0069ED',
];

export const PALETTE_TO_COLORS: Record<string, string[]> = {
  [ColorPalette.DEFAULT]: [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  [ColorPalette.CUSTOM]: [],
  [ColorPalette.BLUE]: [
    '#0069ed',
    '#1a78ef',
    '#3387f1',
    '#4d96f2',
    '#66a5f4',
    '#80b4f6',
    '#99c3f8',
    '#b3d2fa',
    '#cce1fb',
  ],
  [ColorPalette.GREEN]: [
    '#29a634',
    '#3eaf48',
    '#54b85d',
    '#69c171',
    '#7fca85',
    '#94d39a',
    '#a9dbae',
    '#bfe4c2',
    '#d4edd6',
  ],
};
