import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { FilterClause, FilterValueType } from 'constants/types';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { FilterOperator } from 'types/filterOperations';

import { FilterRow } from './FilterRow';

type Props = {
  filterConfigs: FilterClause[];
  onColumnSelect: (column: DatasetColumn, idx: number) => void;
  onOperatorSelect: (operator: FilterOperator, idx: number) => void;
  onFilterValueUpdate: (value: FilterValueType, idx: number) => void;
  addFilter: () => void;
  deleteFilter: (idx: number) => void;
  schema: DatasetSchema;
};

export const FilterDropdownMenu: FC<Props> = ({
  filterConfigs,
  onColumnSelect,
  onOperatorSelect,
  onFilterValueUpdate,
  addFilter,
  deleteFilter,
  schema,
}) => {
  const singleFilterRow = filterConfigs.length === 1;
  const firstFilterEmpty = singleFilterRow ? !filterConfigs[0].filterColumn?.name : undefined;

  return (
    <div className={cx(sprinkles({ padding: 'sp1.5' }))}>
      {filterConfigs.length === 0 ? (
        <div className={sprinkles({ color: 'black', fontSize: 12 })}>No filters applied</div>
      ) : (
        filterConfigs.map((currentFilterConfig, idx) => (
          <FilterRow
            baseSchema={schema}
            deleteDisabled={singleFilterRow && firstFilterEmpty}
            filterValue={currentFilterConfig.filterValue}
            key={idx}
            onColumnSelect={(column) => onColumnSelect(column, idx)}
            onDelete={() => {
              deleteFilter(idx);
              if (singleFilterRow && !firstFilterEmpty) addFilter();
            }}
            onFilterValueUpdate={(value) => onFilterValueUpdate(value, idx)}
            onOperatorSelect={(operator) => onOperatorSelect(operator, idx)}
            selectedColumn={currentFilterConfig.filterColumn}
            selectedOperator={currentFilterConfig.filterOperation?.id}
          />
        ))
      )}
      <EmbedButton icon="plus" onClick={addFilter} variant="primary">
        Add filter
      </EmbedButton>
    </div>
  );
};
