import { OPERATION_TYPES, UserTransformedSchema, SchemaChange } from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { DataPanel } from 'types/exploResource';
import { cloneDeep, keyBy } from 'utils/standard';

import { sortAggregationsByOrderedColumnNames } from './general';
import { sortSchemaChangeByOrderedColumnNames } from './tableSchemaUtils';

export const getTransformedDataPanelForCsv = (
  dataPanel: DataPanel,
  dpSchema: DatasetSchema | undefined,
  userTransformedSchema: UserTransformedSchema | undefined,
): DataPanel => {
  const operationType = dataPanel.visualize_op.operation_type;
  const shouldUseTransformedSchemaForTable =
    operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
    (dataPanel.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled ||
      dataPanel.id === '_drilldown_data_panel');
  const shouldUseTransformedSchema =
    userTransformedSchema &&
    (shouldUseTransformedSchemaForTable ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER);

  const newDp = cloneDeep(dataPanel);

  if (shouldUseTransformedSchema) {
    // We check the truthiness of userTransformedSchema above
    incorporateUserSchemaOverrides(newDp, userTransformedSchema);
  } else {
    incorporateNewColumns(newDp, dpSchema ?? []);
  }

  return newDp;
};

const incorporateUserSchemaOverrides = (
  dp: DataPanel,
  userTransformedSchema: UserTransformedSchema,
): void => {
  const changeSchemaList = dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = keyBy(changeSchemaList, 'col');

  dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList = userTransformedSchema.map(
    (userTransformedCol) => {
      const originalChangeSchema = changeSchemaByColName[userTransformedCol.name];
      return {
        col: userTransformedCol.name,
        newColName: userTransformedCol?.friendly_name || originalChangeSchema.newColName,
        keepCol: userTransformedCol.isVisible,
      };
    },
  );
};

const incorporateNewColumns = (dp: DataPanel, dpSchema: DatasetSchema): void => {
  const operationType = dp.visualize_op.operation_type;

  if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    const instructions = dp.visualize_op.instructions.VISUALIZE_TABLE;
    const newChangeSchemaList: SchemaChange[] = instructions.changeSchemaList;

    // Add in the columns to changeSchemaList that never changed from default
    dpSchema.forEach(({ name }) => {
      const foundChangeSchemaItem = instructions.changeSchemaList.find(({ col }) => col === name);
      if (!foundChangeSchemaItem) newChangeSchemaList.push({ col: name, keepCol: true });
    });

    dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList =
      sortSchemaChangeByOrderedColumnNames(newChangeSchemaList, instructions.orderedColumnNames);
  }

  // Ensures csv download for collapsible list reflects orderedColumnNames
  if (
    operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST &&
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations !== undefined
  ) {
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations =
      sortAggregationsByOrderedColumnNames(
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations,
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.orderedColumnNames,
      );
  }
};
