import minimatch from 'minimatch';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
import validator from 'validator';

import { createAllowlistDomain, fetchAllowlistDomains } from 'actions/allowlistDomainActions';
import { AllowlistDomain } from 'actions/teamActions';
import { Intent, Spinner, sprinkles, CalloutLink, Button, Input } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

import { SettingsDomainAllowlistRule } from './settingsDomainAllowlistRule';

const URL_RULE = /^http(s)?:\/{2}([A-z0-9\-*]+\.)+[A-z*]+$/;
const URL_WITH_PORT_RULE = /^http(s)?:\/{2}localhost:[0-9]+$/;

export const SettingsDomainAllowlistSection: FC = () => {
  const dispatch = useDispatch();

  const { currentUser, allowlistDomains } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      allowlistDomains: state.allowlistDomains.domains,
    }),
    shallowEqual,
  );

  const [isAddAllowlistDomainModalOpen, setAddAllowlistDomainModalOpen] = useState(false);
  const [urlIntent, setUrlIntent] = useState<Intent>();
  const [testedUrl, setTestedUrl] = useState('');

  useEffect(() => {
    if (RD.isIdle(allowlistDomains)) dispatch(fetchAllowlistDomains());
  }, [dispatch, allowlistDomains]);

  const onTestUrlChange = useCallback(
    (url: string) => {
      if (!RD.isSuccess(allowlistDomains)) return;
      let newIntent: Intent | undefined = undefined;

      if (url) {
        newIntent = testUrl(url, allowlistDomains.data) ? Intent.SUCCESS : Intent.ERROR;
      }

      setTestedUrl(url);
      setUrlIntent(newIntent);
    },
    [allowlistDomains],
  );

  return (
    <div>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ heading: 'h2' })}>Domain Allowlisting</div>
        <Button onClick={() => setAddAllowlistDomainModalOpen(true)} variant="primary">
          Add a Rule
        </Button>
      </div>
      <div className={sprinkles({ marginBottom: 'sp1.5', marginTop: 'sp.5', color: 'gray10' })}>
        Allow your domains to view your dashboards using either hard-coded urls or * wildcards.
      </div>
      <div className={sprinkles({ paddingBottom: 'sp3', paddingTop: 'sp4' })}>
        <Input
          intent={urlIntent}
          label={{
            text: 'Test a URL',
            infoText: 'Test a URL to see if it matches any of your rules.',
          }}
          onChange={onTestUrlChange}
          placeholder="http://test.example.com"
          value={testedUrl}
        />
      </div>
      <div>
        <div className={sprinkles({ heading: 'h3' })}>Rules</div>
        {RD.isSuccess(allowlistDomains) ? (
          allowlistDomains.data.map((domain) => (
            <SettingsDomainAllowlistRule key={`allowlist-domain-rule=${domain.id}`} rule={domain} />
          ))
        ) : (
          <Spinner className={sprinkles({ marginTop: 'sp1' })} size="lg" />
        )}
      </div>
      <TextFieldModal
        buttonName="Save rule"
        closeModal={() => setAddAllowlistDomainModalOpen(false)}
        getErrorMessage={(textFieldVal) => {
          if (
            !textFieldVal ||
            (textFieldVal !== '*' &&
              !validator.matches(textFieldVal, URL_RULE) &&
              !validator.matches(textFieldVal, URL_WITH_PORT_RULE))
          ) {
            return 'Invalid Rule';
          }
        }}
        modalOpen={isAddAllowlistDomainModalOpen}
        modalTitle="Add new allowlist rule"
        onSubmit={(rule) => {
          if (currentUser.team === null) return;
          dispatch(
            createAllowlistDomain(
              { postData: { name: rule, team_id: currentUser.team.id } },
              () => setAddAllowlistDomainModalOpen(false),
              (response) => showErrorContactSupportToast(response.error_msg),
            ),
          );
        }}
        textFieldPlaceholder="https://*.domain.com"
      />
      <CalloutLink
        className={sprinkles({ marginTop: 'sp2' })}
        text="Review the developer documentation to learn more"
        url="https://docs.explo.co/embedding-documentation/allowlisting-your-domain"
      />
    </div>
  );
};

const testUrl = (url: string, allowlistDomains: AllowlistDomain[]) => {
  // pass in an empty base url so it doesn't use the browser's location
  const parsedTestUrl = parse(url, {});

  // duplicates the logic in request_handlers/handlers.py in the backend
  for (const allowlistDomain of allowlistDomains) {
    // short circuit if allow-all
    if (allowlistDomain.name === '*') return true;

    // less important to pass in an empty base url here
    // but doing it for consistency and safety
    const parsedPattern = parse(allowlistDomain.name, {});

    if (parsedPattern.protocol !== parsedTestUrl.protocol) continue;

    let splitTestUrl = parsedTestUrl.host.split('.');
    let splitPattern = parsedPattern.host.split('.');

    // it's valid for either to not include a subdomain,
    // so if xor either has www, remove it so that we can
    // check the other parts of the url
    if (splitPattern[0] === 'www' && splitTestUrl[0] !== 'www') {
      splitPattern = splitPattern.slice(1);
    } else if (splitTestUrl[0] === 'www' && splitPattern[0] !== 'www') {
      splitTestUrl = splitTestUrl.slice(1);
    }

    // now we know that the two should have the same number of nested domains
    // so if they don't we can short-circuit checking against this pattern
    if (splitPattern.length !== splitTestUrl.length) continue;

    let match = true;

    // iterate through the subdomains,
    // checking each part for a match
    for (let i = 0; i < splitPattern.length; i++) {
      if (!minimatch(splitTestUrl[i], splitPattern[i])) match = false;
    }

    // if we've made it to the end of iteration
    // and haven't found a mistmatch, then we've
    // found a matching domain!
    if (match) return true;
  }

  return false;
};
