import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { CustomerReport, CustomerReportConfig } from 'actions/customerReportActions';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import {
  ToggleFavoriteCustomerReportBuilderMetadataResponse,
  ToggleFavoriteCustomerReportBuilderMetadataBody,
  SaveCustomerReportBody,
} from 'reportBuilderContent/apiTypes';
import {
  createFilter,
  openBuiltIn,
  openCustomerReport,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  ReportType,
  CreateFilterPayload,
  SelectedReportType,
} from 'reportBuilderContent/reducers/types';
import {
  sendCustomerReportAnalyticsEvent,
  sendBuiltInAnalyticsEvent,
  sendReportBuilderAnalyticsEvent,
} from 'reportBuilderContent/thunks/analyticsThunks';
import { getCurrentISOString } from 'utils/dateUtils';
import { makeThunkRequest } from 'utils/thunkUtils';

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;

export const openCustomerReportThunk =
  (report: CustomerReport & { viewId?: string }): Thunk =>
  (dispatch) => {
    dispatch(openCustomerReport(report));
    dispatch(
      sendCustomerReportAnalyticsEvent(
        report.id,
        REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_OPENED,
      ),
    );
  };

export const openBuiltInThunk =
  (builtIn: BuiltInReportConfig): Thunk =>
  (dispatch) => {
    dispatch(openBuiltIn(builtIn));
    dispatch(
      sendBuiltInAnalyticsEvent(builtIn.id, REPORTED_ANALYTIC_ACTION_TYPES.BUILT_IN_REPORT_OPENED),
    );
  };

type CreateCustomerReportThunkBody = {
  name: string;
  config: CustomerReportConfig | null;
  builtInId?: string;
  reportId?: number;
};

export const createCustomerReportThunk =
  ({ name, config, builtInId, reportId }: CreateCustomerReportThunkBody): Thunk =>
  (dispatch) => {
    dispatch(createCustomerReport({ name, config }));

    if (builtInId)
      dispatch(
        sendBuiltInAnalyticsEvent(builtInId, REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_COPIED),
      );
    else if (reportId)
      dispatch(
        sendCustomerReportAnalyticsEvent(
          reportId,
          REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_COPIED,
        ),
      );
    else
      dispatch(
        sendReportBuilderAnalyticsEvent(
          null,
          REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_CREATED,
        ),
      );
  };

type CreateCustomerReportBody = {
  resource_embed_id: string | undefined;
  name: string;
  config: CustomerReportConfig | null;
};

export const createCustomerReport = createAsyncThunk<
  { report: CustomerReport },
  { name: string; config: CustomerReportConfig | null },
  { state: ReportBuilderReduxState }
>(ACTION.CREATE_CUSTOMER_REPORT, async ({ name, config }, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const now = getCurrentISOString();
  if (embeddedReportBuilder.isPreview) {
    const nextId = Math.max(...embeddedReportBuilder.reports.map((report) => report.id), 0) + 1;
    return Promise.resolve({
      report: {
        id: nextId,
        name,
        config: { views: [createBlankView()], ...config },
        is_starred: false,
        modified: now,
      },
    });
  }

  const createCustomerReportBody: CreateCustomerReportBody = {
    resource_embed_id: embeddedReportBuilder.requestInfo.embedId,
    name,
    config,
  };

  const requestConfig = createApiRequestConfig(
    'customer_reports/create_report/',
    'POST',
    createCustomerReportBody,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const saveCustomerReportThunk =
  (body: SaveCustomerReportBody): Thunk =>
  (dispatch) => {
    dispatch(saveCustomerReport(body));
    dispatch(
      sendCustomerReportAnalyticsEvent(
        body.report_id,
        REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_SAVED,
      ),
    );
  };

export const saveCustomerReport = createAsyncThunk<
  { report: CustomerReport },
  SaveCustomerReportBody,
  { state: ReportBuilderReduxState }
>(ACTION.SAVE_CUSTOMER_REPORT, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/save_report/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const createFilterThunk =
  (body: CreateFilterPayload): Thunk =>
  (dispatch, getState) => {
    const { selectedReport } = getState().reportEditing;
    if (!selectedReport) return;

    dispatch(createFilter(body));
    dispatch(
      sendReportBuilderAnalyticsEvent(
        selectedReport,
        REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_FILTER_OPENED,
        {
          column_name: body.column.name,
          filter_operator: body.filterOperator,
        },
      ),
    );
  };

export const favoriteCustomerReportThunk =
  (isStarred: boolean, reportInfo: SelectedReportType): Thunk =>
  (dispatch, getState) => {
    const { embedId } = getState().embeddedReportBuilder.requestInfo;
    if (reportInfo.type === ReportType.CUSTOMER_REPORT) {
      dispatch(favoriteCustomerReport({ report_id: reportInfo.id }));
    } else if (reportInfo.type === ReportType.BUILT_IN) {
      dispatch(favoriteBuiltIn({ resource_embed_id: embedId, built_in_id: reportInfo.id }));
    }

    const event = isStarred
      ? REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_UNFAVORITED
      : REPORTED_ANALYTIC_ACTION_TYPES.CUSTOMER_REPORT_FAVORITED;
    dispatch(sendReportBuilderAnalyticsEvent(reportInfo, event));
  };

export const favoriteCustomerReport = createAsyncThunk<
  { report_id: number },
  { report_id: number },
  { state: ReportBuilderReduxState }
>(ACTION.STAR_CUSTOMER_REPORT, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/star_report/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});

export const favoriteBuiltIn = createAsyncThunk<
  ToggleFavoriteCustomerReportBuilderMetadataResponse,
  ToggleFavoriteCustomerReportBuilderMetadataBody,
  { state: ReportBuilderReduxState }
>(ACTION.TOGGLE_FAVORITE_BUILT_IN, async (body, { getState }) => {
  const { embeddedReportBuilder } = getState();
  const requestConfig = createApiRequestConfig(
    'customer_reports/toggle_favorite_built_in/',
    'POST',
    body,
    embeddedReportBuilder.requestInfo.customerToken,
    embeddedReportBuilder.requestInfo.embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error creating report');
});
