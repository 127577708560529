import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetDataId } from 'reportBuilderContent/reducers/reportEditingUtils';
import {
  fetchAppDistinctColumnData,
  fetchDashboardDatasetModalData,
  fetchAppAiData,
} from 'reportBuilderContent/thunks/appDataThunks';
import { getDrilldownView } from 'reportBuilderContent/thunks/utils';
import { BaseCol } from 'types/columnTypes';
import { getCurrentView } from 'utils/customerReportUtils';
import { xor } from 'utils/standard';

import { closeReportModal, saveDataInfo, createView } from './reducers/reportEditingReducer';
import {
  fetchEmbeddedDistinctColumn,
  fetchEmbeddedDatasetModalData,
  fetchEmbeddedAiData,
} from './thunks/embeddedDataThunks';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const fetchModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const {
      embeddedReportBuilder: { isInApp },
      reportEditing: { reportData },
    } = getState();
    if (getDatasetDataId(datasetId) in reportData) return;

    if (isInApp) dispatch(fetchDashboardDatasetModalData(datasetId));
    else dispatch(fetchEmbeddedDatasetModalData(datasetId));
  };

export const createViewFromDrilldown = (): Thunk => (dispatch, getState) => {
  const { currentConfig, currentView, drilldownConfigs } = getState().reportEditing;
  const viewConfig = getCurrentView(currentConfig?.views, currentView);
  const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
  if (!viewConfig) return;

  const filteredView = getDrilldownView(
    viewConfig,
    currentConfigs?.filters || [],
    currentConfigs?.sort,
  );
  dispatch(createView(filteredView));
};

export const fetchDistinctColumnData =
  (column: BaseCol): Thunk =>
  (dispatch, getState) => {
    const { isInApp } = getState().embeddedReportBuilder;
    if (isInApp) dispatch(fetchAppDistinctColumnData(column));
    else dispatch(fetchEmbeddedDistinctColumn(column));
  };

export const fetchAiData =
  (viewConfig: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { isInApp } = getState().embeddedReportBuilder;
    if (isInApp) dispatch(fetchAppAiData(viewConfig));
    else dispatch(fetchEmbeddedAiData(viewConfig));
  };

export const updateDataInfoThunk =
  (newInfo: CustomerReportDataInfo): Thunk =>
  (dispatch, getState) => {
    // Should only access embedded state
    const { currentConfig } = getState().reportEditing;
    if (!currentConfig) return;

    const dataInfo = currentConfig.dataInfo;
    if (
      !dataInfo ||
      dataInfo.datasetId !== newInfo.datasetId ||
      xor(dataInfo.columns, newInfo.columns).length > 0
    ) {
      dispatch(saveDataInfo(newInfo));
    } else {
      dispatch(closeReportModal());
    }
  };
