import produce from 'immer';
import { FC } from 'react';

import { Input } from 'components/ds';
import { DateGroupToggleConfig } from 'types/dashboardTypes';
import { TREND_GROUPING_OPTIONS } from 'types/dateRangeTypes';

import * as styles from '../styles.css';

type Props = {
  config: DateGroupToggleConfig;

  updateToggleConfig: (config: DateGroupToggleConfig) => void;
};

export const DateGroupGroupLabelsConfig: FC<Props> = ({ config, updateToggleConfig }) => {
  return (
    <>
      {Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
        const configOptionsForType = config.groupingOptionByType?.[groupingOption.id] || {
          exclude: false,
        };
        if (configOptionsForType.exclude) return null;
        return (
          <Input
            showInputButton
            className={styles.configInput}
            defaultValue={configOptionsForType.name || groupingOption.name}
            key={groupingOption.id}
            label={groupingOption.name}
            onSubmit={(newValue) => {
              const newConfig = produce(config, (draft) => {
                if (!draft.groupingOptionByType) draft.groupingOptionByType = {};
                draft.groupingOptionByType[groupingOption.id] = {
                  ...draft.groupingOptionByType[groupingOption.id],
                  name: newValue,
                };
              });
              updateToggleConfig(newConfig);
            }}
          />
        );
      })}
    </>
  );
};
