import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import { DatasetSchema } from 'types/datasets';

import { DataTable } from './datasetActions';
import { FetchDashboardDatasetPreviewData } from './responseTypes';

export interface TableDataset {
  id: number;
  table_name: string;
  schema: DatasetSchema;
  parent_schema_id: number;
}

export interface DataSource {
  id: number;
  provided_id?: string;
  source_type: string;
  name: string;
  parent_schema_id: number;
  user_viewable_credentials: DataSourceConfiguration;
  access_groups: number[];

  fido_id?: string;
}

export type DataSourcePropertyOptions = Record<string, DataSourcePropertyOption>;

export type DataSourcePropertyOption = {
  type: string;
  label: string;
  placeholder: string;
  default?: string;
  help_tooltip: string;
  checked_label: string;
  depends_on?: string;
  optional?: boolean;
  security_page?: boolean;
  options?: Record<string, DataSourcePropertyOptions>;
  readonly?: boolean;
};

export type DataSourceConfigurationSchema = {
  order: string[];
  properties: DataSourcePropertyOptions;
};

export interface SupportedDataSource {
  type: string;
  name: DATABASES;
  configuration_schema: DataSourceConfigurationSchema;
}

export type DataSourceConfiguration = Record<string, number | boolean | string>;

export interface ParentSchema {
  id: number;
  name: string;

  fido_id?: string;
}

// Requests

type FetchSupportedDataSourcesData = {
  supported_sources: SupportedDataSource[];
};

export const { actionFn: fetchSupportedDataSources, ...fetchSupportedDataSourcesActions } =
  defineAPIAction<FetchSupportedDataSourcesData>(
    ACTION.FETCH_SUPPORTED_DATA_SOURCES,
    'datasources',
    'supported_data_sources',
    'GET',
  );

export type FetchAllDataSourceTablesData = { schema: DataTable[]; error?: string };

export const fetchAllDataSourceTablesActions = defineAPIAction<FetchAllDataSourceTablesData>(
  ACTION.FETCH_ALL_DATA_SOURCE_TABLES,
  'datasources',
  'all_tables',
  'GET',
);

type SyncSourceTablesBody = {
  ignore_table_names: string[];
};

export const { requestAction: syncSourceTablesRequest, successAction: syncSourceTablesSuccess } =
  defineAPIPostAction<SyncSourceTablesBody, {}>(
    ACTION.SYNC_SOURCE_TABLES,
    'datasources',
    'sync_source_tables',
    'POST',
  );

type TestDataSourceConnectionBody = {
  name: string;
  type: string;
  configuration: DataSourceConfiguration;
};

type TestUpdatedDataSourceConnectionBody = {
  configuration: DataSourceConfiguration;
  id: number;
};

export enum Quantification {
  AtLeast = 'at_least',
  Exact = 'exact',
}

export type TestDataSourceConnectionData = {
  num_tables: number;
  quantification?: Quantification;
};

export const { actionFn: testDataSourceConnection } = defineAPIPostAction<
  TestDataSourceConnectionBody,
  TestDataSourceConnectionData
>(ACTION.TEST_DATA_SOURCE_CONNECTION, 'datasources', 'test_connection', 'POST');

export const { actionFn: testUpdatedDataSourceConnection } = defineAPIPostAction<
  TestUpdatedDataSourceConnectionBody,
  TestDataSourceConnectionData
>(ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION, 'datasources', 'test_updated_connection', 'POST');

type ConnectDataSourceBody = {
  name: string;
  provided_id?: string;
  type: string;
  configuration: DataSourceConfiguration;
  schema: ParentSchema;
  access_group_ids: number[];
};

type ConnectDataSourceData = {
  data_source: DataSource;
  new_schema: ParentSchema | null;
};

export const { actionFn: connectDataSource, successAction: connectDataSourceSuccess } =
  defineAPIPostAction<ConnectDataSourceBody, ConnectDataSourceData>(
    ACTION.CONNECT_DATA_SOURCE,
    'datasources',
    'connect',
    'POST',
  );

type FetchTeamDataSourcesData = {
  dataSources: DataSource[];
};

export const { actionFn: listTeamDataSources, ...listTeamDataSourcesActions } =
  defineAPIAction<FetchTeamDataSourcesData>(
    ACTION.LIST_TEAM_DATA_SOURCES,
    'datasources',
    '',
    'GET',
  );

export type EditDataSourceBody = {
  name?: string;
  provided_id?: string;
  credentials?: DataSourceConfiguration;
  access_group_ids?: number[];
};

type EditDataSourceData = {
  data_source: DataSource;
};

export const { actionFn: editDataSource, successAction: editDataSourceSuccess } =
  defineAPIPostAction<EditDataSourceBody, EditDataSourceData>(
    ACTION.EDIT_DATA_SOURCE,
    'datasources',
    '',
    'PATCH',
  );

type FetchDatasetPreviewBody = {
  dataset_id: number;
  offset?: number;
  data_source_id: string;
};

export const {
  actionFn: fetchDatasetPreview,
  requestAction: fetchDatasetPreviewRequest,
  successAction: fetchDatasetPreviewSuccess,
  errorAction: fetchDatasetPreviewError,
} = defineAPIPostAction<FetchDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.FETCH_DATASET_PREVIEW,
  'datasets',
  'get_preview',
  'POST',
);

export type FetchDatasetRowCountBody = {
  dataset_id: number;
  data_source_id: string;
};

export const {
  actionFn: fetchDatasetRowCount,
  successAction: fetchDatasetRowCountSuccess,
  errorAction: fetchDatasetRowCountError,
} = defineAPIPostAction<FetchDatasetRowCountBody, { _total_row_count: number }>(
  ACTION.FETCH_DATASET_ROW_COUNT,
  'datasets',
  'get_row_count',
  'POST',
);
