import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch, Input } from 'components/ds';
import {
  BarFunnelChartFormat,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const BarFunnelChartConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const barFunnelConfig = instructions.chartSpecificFormat?.barFunnelChart;

  const updateFunnelConfig = (newConfig: BarFunnelChartFormat) => {
    dispatch(
      updateVisualizeOperation(
        {
          ...instructions,
          chartSpecificFormat: {
            ...instructions.chartSpecificFormat,
            barFunnelChart: {
              ...instructions.chartSpecificFormat?.barFunnelChart,
              ...newConfig,
            },
          },
        },
        visualizationType,
      ),
    );
  };

  return (
    <div className={configRootClass}>
      <Switch
        className={configInputClass}
        label="Cumulative"
        onChange={() => updateFunnelConfig({ isNotCumulative: !barFunnelConfig?.isNotCumulative })}
        switchOn={!barFunnelConfig?.isNotCumulative}
      />

      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(barFunnelConfig?.wonLabel || 'Converted')}
        label="Converted Tooltip Label"
        onSubmit={(newValue) => updateFunnelConfig({ wonLabel: newValue })}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(barFunnelConfig?.lostLabel || 'Lost')}
        label="Lost Tooltip Label"
        onSubmit={(newValue) => updateFunnelConfig({ lostLabel: newValue })}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(barFunnelConfig?.entityName || 'Uniques')}
        label="Entity Name"
        onSubmit={(newValue) => updateFunnelConfig({ entityName: newValue })}
      />
      <Input
        className={configInputClass}
        defaultValue={String(barFunnelConfig?.minBarWidth || 50)}
        label={{
          text: 'Min Bar Width',
          infoText: 'Bars will scroll if not enough horizontal space on chart',
        }}
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          updateFunnelConfig({ minBarWidth: intValue >= 0 ? intValue : undefined });
        }}
      />
    </div>
  );
};
