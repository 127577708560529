import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { SupportedDataSource, DataSourceConfiguration } from 'actions/dataSourceActions';
import { sprinkles, CalloutLink } from 'components/ds';
import { ConnectDataSourceContainer } from 'pages/ConnectDataSourceFlow/ConnectDataSourceContainer';
import { ReduxState } from 'reducers/rootReducer';
import {
  buildSSHTunnelConfigForFido,
  dataSourceSupportsSSH,
  shouldUseFidoForConnectingOrUpdating,
} from 'utils/fido/dataSources/utils';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { areCredentialsComplete, getPropertyNames } from '../utils';

import { CredentialsSection } from './shared/CredentialsSection';
import { FidoSSHConfigSection } from './shared/FidoSSHConfigSection';

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  testingConnLoading?: boolean;
  headerClassName?: string;
};

export const SecurityConfiguration: FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnLoading,
  headerClassName,
}) => {
  const { fidoDataSourceType, sshConfig, useFido } = useSelector(
    (state: ReduxState) => ({
      fidoDataSourceType: state.fidoDataSourceConfig.dataSourceConfig.type,
      sshConfig: state.fidoDataSourceConfig.sshConfig,
      useFido: shouldUseFidoForConnectingOrUpdating(state),
    }),
    shallowEqual,
  );

  if (useFido ? !fidoDataSourceType : !config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;
  const configuration = config.dataSourceConfig || {};

  return (
    <ConnectDataSourceContainer
      backBtnOnClick={onBackClicked}
      bodyContent={
        <SecurityConfigurationBody
          config={config}
          headerClassName={headerClassName}
          updateConfig={updateConfig}
        />
      }
      currentStep={ConnectDataSourceStep.SECURITY}
      headerTitle="Security"
      primaryActionConfig={{
        text: 'Next',
        disabled: useFido
          ? !buildSSHTunnelConfigForFido(sshConfig, true)
          : dataSource &&
            !areCredentialsComplete(
              dataSource.configuration_schema.properties,
              configuration,
              true,
            ),
        onClick: onNextClicked,
        loading: testingConnLoading,
      }}
    />
  );
};

type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  selectedDataSource?: SupportedDataSource;
  userViewableCredentials?: DataSourceConfiguration;
  headerClassName?: string;
};

export const SecurityConfigurationBody: FC<BodyProps> = ({
  config,
  updateConfig,
  selectedDataSource,
  userViewableCredentials,
  headerClassName,
}) => {
  const { fidoDataSourceType, useFido } = useSelector(
    (state: ReduxState) => ({
      fidoDataSourceType: state.fidoDataSourceConfig.dataSourceConfig.type,
      useFido: shouldUseFidoForConnectingOrUpdating(state),
    }),
    shallowEqual,
  );

  const dataSource = config.selectedDataSource ?? selectedDataSource;
  if (useFido ? !fidoDataSourceType : !dataSource) return null;

  const securityPropertyConfigs = useFido
    ? []
    : dataSource && getPropertyNames(dataSource.configuration_schema, true);

  const sshTunnelHeader = (
    <div className={headerClassName ?? sprinkles({ heading: 'h3', marginBottom: 'sp2' })}>
      SSH Tunnel
    </div>
  );

  return (
    <div data-testid="connect-datasource-security-config">
      <div className={headerClassName ?? sprinkles({ heading: 'h3', marginBottom: 'sp.5' })}>
        Allowlisting
      </div>
      <div className={sprinkles({ body: 'b2', marginBottom: 'sp2' })}>
        If your database is behind a firewall, please allowlist the egress IPs for the Explo
        Connectivity Region you use.
      </div>
      <CalloutLink
        className={sprinkles({ marginBottom: 'sp4' })}
        text="Explo Connectivity Region IPs"
        url="https://docs.explo.co/data-sources/connecting-to-data-sources/connectivity-regions"
      />

      {/* eslint-disable  @typescript-eslint/no-non-null-assertion */}
      {useFido && dataSourceSupportsSSH(fidoDataSourceType!) ? (
        <>
          {sshTunnelHeader}
          <FidoSSHConfigSection />
        </>
      ) : securityPropertyConfigs == undefined || securityPropertyConfigs.length === 0 ? null : (
        <>
          {sshTunnelHeader}
          <CredentialsSection
            config={config}
            dataSource={dataSource}
            propertyNames={securityPropertyConfigs}
            updateConfig={updateConfig}
            userViewableCredentials={userViewableCredentials}
          />
        </>
      )}
    </div>
  );
};
