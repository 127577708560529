import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Icon, sprinkles } from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { DownloadInfo } from 'reducers/dashboardLayoutReducer';
import { AllStates } from 'reducers/rootReducer';
import { isError, isLoading, isSuccess } from 'remotedata';
import { WindowState } from 'utils/exportUtils';

type Props = {
  windowState: WindowState;
  downloadInfo?: DownloadInfo;
};

export const DownloadStatus: FC<Props> = ({ windowState, downloadInfo }: Props) => {
  const supportEmail = useSelector((state: AllStates) =>
    'dashboardInteractions' in state
      ? state.dashboardInteractions.interactionsInfo.supportEmail
      : undefined,
  );

  if (downloadInfo === undefined) return null;

  const isProblem =
    isError(downloadInfo.status) || windowState.downloadBlocked || windowState.popupBlocked;

  const isEmail = downloadInfo.type === 'email';

  if (!isProblem) {
    return (
      <div className={cx(rootClass, embedSprinkles({ body: 'primary' }))}>
        <div className={headingClass}>
          {isLoading(downloadInfo.status) ? (
            isEmail ? (
              'Email will be sent shortly'
            ) : (
              'Downloading...'
            )
          ) : (
            <>
              {isEmail ? 'Email will be sent shortly' : 'Download Complete'}
              <Icon className={sprinkles({ color: 'success' })} name="check" />
            </>
          )}
        </div>
      </div>
    );
  }

  const supportEmailText = supportEmail || DEFAULT_SUPPORT_EMAIL;

  if (isError(downloadInfo.status)) {
    return (
      <div className={cx(rootClass, embedSprinkles({ body: 'primary' }))}>
        <div className={headingClass}>
          {isEmail ? 'Email failed to send' : 'Download failed'}
          <Icon className={sprinkles({ color: 'error' })} name="cross" />
        </div>
        <div className={sprinkles({ body: 'b2' })}>
          If this continues, please contact{' '}
          <a href={'mailto:' + supportEmailText} rel="noopener noreferrer" target="_blank">
            {supportEmailText}
          </a>
        </div>
      </div>
    );
  }
  if (!isEmail && isSuccess(downloadInfo.status)) {
    const link = downloadInfo.status.data;
    const showLink = windowState.downloadBlocked;
    return (
      <div className={cx(rootClass, embedSprinkles({ body: 'primary' }))}>
        <div className={headingClass}>
          Download Complete
          <Icon className={sprinkles({ color: 'success' })} name="check" />
        </div>
        <div className={sprinkles({ body: 'b2' })}>
          If the download did not start automatically,{' '}
          <a
            href={showLink ? undefined : link}
            onClick={() => {
              if (showLink) copy(link || '');
            }}
            rel="noopener noreferrer"
            target="_blank">
            {showLink ? 'click here to copy link.' : 'click here.'}
          </a>
        </div>
      </div>
    );
  }
  return null;
};

const rootClass = sprinkles({
  flexItems: 'centerColumn',
  color: 'gray10',
  gap: 'sp1',
  textAlign: 'center',
});
const headingClass = sprinkles({ body: 'b2', gap: 'sp1', flexItems: 'alignCenter' });
