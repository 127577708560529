import cx from 'classnames';
import { FC, useMemo } from 'react';

import { ColorPaletteDisplay } from 'components/ColorPaletteDisplay';
import { Select, sprinkles } from 'components/ds';
import {
  ColorFormat,
  ColorPalette,
  ColorPaletteV2,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';

import { ColorZonesConfig } from './ColorZonesConfig';

type Props = {
  configInputClass: string;
  globalStyleConfig: GlobalStyleConfig;
  zonesAllowed?: boolean;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions;

  updateColorFormat: (colorFormat: ColorFormat) => void;
};

export const COLOR_ZONES = 'Color Zones';

export const SharedColorConfigs: FC<Props> = ({
  configInputClass,
  zonesAllowed,
  instructions,
  updateColorFormat,
  globalStyleConfig,
}) => {
  const colorFormat = instructions.colorFormat;
  const isUsingZones = colorFormat?.useZones;

  const selectedPalette = colorFormat?.selectedPalette || ColorPaletteV2.CATEGORICAL;
  const selectedPaletteOption = isUsingZones ? COLOR_ZONES : selectedPalette;

  const paletteOptions = useMemo(() => {
    const options: { value: string }[] = [];
    addPaletteToList(options, Object.values(ColorPalette));
    addPaletteToList(options, Object.values(ColorPaletteV2));
    if (zonesAllowed) addPaletteToList(options, [COLOR_ZONES]);
    return options;
  }, [zonesAllowed]);

  return (
    <>
      <Select
        className={configInputClass}
        label="Color Palette"
        onChange={(value) => {
          if (value === COLOR_ZONES) {
            updateColorFormat({ useZones: true });
          } else {
            updateColorFormat({
              selectedPalette: value as ColorPalette | ColorPaletteV2,
              useZones: false,
            });
          }
        }}
        placeholder="Select Palette"
        selectedValue={selectedPaletteOption}
        values={paletteOptions}
      />
      {isUsingZones ? (
        <ColorZonesConfig
          configInputClass={configInputClass}
          instructions={instructions}
          updateColorFormat={updateColorFormat}
        />
      ) : (
        <ColorPaletteDisplay
          className={cx(configInputClass, sprinkles({ marginY: 'sp2' }))}
          customColors={colorFormat?.customColors}
          globalStyleConfig={globalStyleConfig}
          palette={selectedPalette}
          saveCustomColorConfig={(colorPalette) =>
            updateColorFormat({
              selectedPalette: ColorPalette.CUSTOM,
              customColors: colorPalette.join(','),
            })
          }
        />
      )}
    </>
  );
};

const addPaletteToList = (list: { value: string }[], palettes: string[]) =>
  palettes.forEach((palette) => list.push({ value: palette }));
