import { Draft } from 'immer';

import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { INPUT_TYPE } from 'constants/types';
import { DASHBOARD_ELEMENT_TYPES, SelectElemConfig } from 'types/dashboardTypes';
import { FilterOperator } from 'types/filterOperations';

export const updateConfigIfSelectElement = (
  elementType: DASHBOARD_ELEMENT_TYPES,
  filterOperator: FilterOperator,
  config: Draft<SelectElemConfig>,
) => {
  // For select elements, if the operator is BOOLEAN_IS, set the values source to manual and set the
  // manual values to "['true', 'false']""
  if (SELECT_ELEMENT_SET.has(elementType) && filterOperator === FilterOperator.BOOLEAN_IS) {
    config.valuesConfig.manualValues = BOOLEAN_IS_OPERATOR_MANUAL_VALUES;
    config.valuesConfig.valuesSource = INPUT_TYPE.MANUAL;
  }
};

const BOOLEAN_IS_OPERATOR_MANUAL_VALUES = '["true", "false"]';
