import { FC } from 'react';

import { sprinkles, Tag } from 'components/ds';
import Markdown from 'components/markdown';
import { AIReducerMessage } from 'reportBuilderContent/reducers/types';

type Props = { message: AIReducerMessage };

export const AIChatMessage: FC<Props> = ({ message: { content, role }, children }) => {
  const roleName = roleNameMap[role] || 'AI Assistant';
  return (
    <div className={containerClassName}>
      <Tag
        intent={role === 'assistant' ? 'active' : 'success'}
        leftIcon={role === 'assistant' ? 'wand' : undefined}>
        {roleName}
      </Tag>
      {content ? <Markdown markdownText={content} /> : null}
      {children}
    </div>
  );
};

const roleNameMap: Partial<Record<string, string>> = {
  assistant: 'AI Assistant',
  user: 'You',
};

const containerClassName = sprinkles({
  borderRadius: 8,
  border: 1,
  borderColor: 'outline',
  padding: 'sp2',
  backgroundColor: 'elevationLow',
  flexItems: 'column',
  gap: 'sp1',
  body: 'b1',
});
