import Cookies from 'js-cookie';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import validator from 'validator';

import {
  customerPortalSignIn,
  customerPortalAuthentication,
  fetchEndUserPortalMetadata,
} from 'actions/authAction';
import { sprinkles } from 'components/ds';
import { EmbedButton, EmbedInput, EmbedSpinner } from 'components/embed';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

import { PortalDashboardPage } from './portalDashboardPage';

type MatchParams = {
  token: string;
};

export const PortalBasePage: FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [shareId, setShareId] = useState('');
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
  const [dashboardName, setDashboardName] = useState('');
  const [dashboardLogoUrl, setDashboardLogoUrl] = useState<string>();
  const [faviconUrl, setFaviconUrl] = useState('');
  const [siteTitle, setSiteTitle] = useState('');
  const [loginText, setLoginText] = useState('');
  const [loginEmailSent, setLoginEmailSent] = useState(false);

  let {
    params: { token },
  } = useRouteMatch<MatchParams>();

  const isEmailReroute = !!token;
  token = token ?? Cookies.get('portal_auth');

  useEffect(() => {
    if (token) {
      setIsLoadingAuth(true);
      dispatch(
        customerPortalAuthentication(
          { postData: { token, isEmailReroute } },
          (response) => {
            Cookies.set('portal_auth', token, { expires: 7 });
            setShareId(response.share_id);
            setDashboardName(response.dashboard_name);
            setIsLoadingAuth(false);

            window.history.replaceState(null, 'Dashboard', '/portal');
          },
          (e) => {
            showErrorToast(e.error_msg ?? 'Something went wrong. Please try again.');
            Cookies.remove('portal_auth');
            setIsLoadingAuth(false);
            window.history.replaceState(null, 'Dashboard', '/portal');
          },
        ),
      );
    } else {
      setIsLoadingAuth(false);
    }
  }, [dispatch, token, isEmailReroute]);

  useEffect(() => {
    dispatch(
      fetchEndUserPortalMetadata(
        { postData: {} },
        (response) => {
          setDashboardLogoUrl(response.dashboard_logo_url);
          setFaviconUrl(response.favicon_url ?? '');
          setSiteTitle(response.portal_site_title ?? '');
          setLoginText(
            response.portal_login_text ?? 'Contact your administrator for login information',
          );
          setIsLoadingMetadata(false);
        },
        () => {
          console.error('Invalid URL, redirecting...');
          // if there's no metadata, then the portal isn't set up and we should send the user back
          // to whatever origin they started at
          window.location.href = `${window.location.protocol}//${window.location.hostname}`;
        },
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && faviconUrl)
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
    if (siteTitleElement && siteTitle) siteTitleElement.innerHTML = `${siteTitle}`;
  }, [faviconUrl, siteTitle]);

  if (isLoadingAuth || isLoadingMetadata) {
    return <EmbedSpinner fillContainer size="xl" />;
  }

  const renderLoginBody = () => {
    if (loginEmailSent) {
      return (
        <div className={sprinkles({ textAlign: 'center' })}>
          <div className={titleClass}>Check your email</div>
          <div className={subtitleClass}>
            A sign-in link has been sent to your inbox at {email}.
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className={sprinkles({ textAlign: 'center', marginBottom: 'sp4' })}>
            <div className={titleClass}>Sign-In</div>
            <div className={subtitleClass}>{loginText}</div>
          </div>
          <div>
            <EmbedInput
              className={sprinkles({ marginBottom: 'sp2' })}
              label="Email"
              onChange={setEmail}
              value={email}
            />
            <EmbedButton
              fillWidth
              disabled={!validator.isEmail(email)}
              onClick={() =>
                dispatch(
                  customerPortalSignIn(
                    { postData: { email: email } },
                    () => {
                      showSuccessToast(
                        'An email has been sent with a magic link to sign in if an account is set up for that email.',
                      );
                      setLoginEmailSent(true);
                    },
                    (error) => showErrorToast(error.error_msg ?? 'Something went wrong'),
                  ),
                )
              }
              variant="primary">
              Enter
            </EmbedButton>
          </div>
        </>
      );
    }
  };

  if (!shareId) {
    return (
      <div className={rootClass}>
        <div className={sprinkles({ flexItems: 'center', paddingX: 'sp3.5', minHeight: 'fill' })}>
          <div className={cardClass} style={{ width: 400 }}>
            {dashboardLogoUrl ? (
              <div
                className={sprinkles({ position: 'absolute', top: 'spn5' })}
                style={{ left: 'calc(50% - 40px)' }}>
                <img alt="explo icon" className={sprinkles({ width: 80 })} src={dashboardLogoUrl} />
              </div>
            ) : null}
            {renderLoginBody()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={rootClass}>
      <PortalDashboardPage
        dashboardLogoUrl={dashboardLogoUrl}
        dashboardName={dashboardName}
        onSignOut={() => {
          Cookies.remove('portal_auth');
          setShareId('');
        }}
        shareId={shareId}
      />
    </div>
  );
};

const cardClass = sprinkles({
  backgroundColor: 'white',
  paddingY: 'sp5',
  paddingX: 'sp8',
  border: 1,
  borderColor: 'gray7',
  position: 'relative',
  borderRadius: 4,
});

const rootClass = sprinkles({
  backgroundColor: 'gray1',
  height: 'fillViewport',
  overflowY: 'auto',
});

const titleClass = sprinkles({ heading: 'h2', textAlign: 'center', marginBottom: 'sp2' });

const subtitleClass = sprinkles({ body: 'b3', color: 'contentTertiary' });
