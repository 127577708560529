import { createErrorAction, createRequestAction, createSuccessAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import {
  FetchDashboardDatasetJob,
  FetchDashboardDatasetJobRequest,
  FetchDashboardDatasetJobSuccess,
  FetchDashboardDatasetRowCountJob,
  FetchDashboardDatasetRowCountJobRequest,
  FetchDashboardDatasetRowCountJobSuccess,
  FetchDashboardRowCountJobSuccess,
  FetchReportBuilderPreviewReportDataJob,
  FetchReportBuilderPreviewReportDataJobSuccess,
  FetchReportBuilderPreviewReportDataJobRequest,
  FetchDashboardRowCountJobRequest,
  ReportBuilderJobError,
  FetchDashboardRowCountJob,
} from 'components/JobQueue/types';

export const fetchDashboardReportDataRequest =
  createRequestAction<FetchReportBuilderPreviewReportDataJobRequest>(
    ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB,
  );

export const fetchDashboardReportDataSuccess =
  createSuccessAction<FetchReportBuilderPreviewReportDataJobSuccess>(
    ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB,
  );

export const fetchDashboardReportDataError = createErrorAction<
  ReportBuilderJobError<FetchReportBuilderPreviewReportDataJob>
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB);

export const fetchDashboardReportRowCountRequest =
  createRequestAction<FetchDashboardRowCountJobRequest>(
    ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB,
  );

export const fetchDashboardReportRowCountSuccess =
  createSuccessAction<FetchDashboardRowCountJobSuccess>(
    ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB,
  );

export const fetchDashboardReportRowCountError = createErrorAction<
  ReportBuilderJobError<FetchDashboardRowCountJob>
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB);

export const fetchDashboardDatasetRequest = createRequestAction<FetchDashboardDatasetJobRequest>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB,
);

export const fetchDashboardDatasetSuccess = createSuccessAction<FetchDashboardDatasetJobSuccess>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB,
);

export const fetchDashboardDatasetError = createErrorAction<
  ReportBuilderJobError<FetchDashboardDatasetJob>
>(ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB);

export const fetchDashboardDatasetRowCountRequest =
  createRequestAction<FetchDashboardDatasetRowCountJobRequest>(
    ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB,
  );

export const fetchDashboardDatasetRowCountSuccess =
  createSuccessAction<FetchDashboardDatasetRowCountJobSuccess>(
    ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB,
  );

export const fetchDashboardDatasetRowCountError = createErrorAction<
  ReportBuilderJobError<FetchDashboardDatasetRowCountJob>
>(ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB);
