import { Draft } from 'immer';
import { FC } from 'react';

import { Select, Toggle, ToggleItem } from 'components/ds';
import { DateElemConfig } from 'types/dashboardTypes';
import { DATE_RANGE_TYPES, RELATIVE_DATE_RANGES } from 'types/dateRangeTypes';

import * as styles from '../styles.css';

import { ExactDatesConfig } from './ExactDatesConfig';

interface Props {
  config: DateElemConfig;
  shouldRenderHeader: boolean;
  updateConfigFn: (updateFunc: (draft: Draft<DateElemConfig>) => void) => void;
}

export const DatePickerConfig: FC<Props> = ({ config, shouldRenderHeader, updateConfigFn }) => {
  return (
    <>
      {shouldRenderHeader ? (
        <div className={styles.headerClass}>Calendar Selector Values</div>
      ) : null}
      <Toggle
        className={styles.configInput}
        label="Selectable Range"
        onValueChange={(value) =>
          updateConfigFn((draft) => (draft.dateRangeType = value as DATE_RANGE_TYPES))
        }
        selectedValue={config.dateRangeType ?? DATE_RANGE_TYPES.RELATIVE}>
        <ToggleItem key="exact" value={DATE_RANGE_TYPES.EXACT}>
          Exact Range
        </ToggleItem>
        <ToggleItem key="relative" value={DATE_RANGE_TYPES.RELATIVE}>
          Relative Range
        </ToggleItem>
      </Toggle>
      {config.dateRangeType === DATE_RANGE_TYPES.EXACT ? (
        <ExactDatesConfig config={config} updateConfigFn={updateConfigFn} />
      ) : (
        <Select
          onCancel={() => updateConfigFn((draft) => (draft.relativeDateRange = undefined))}
          onChange={(value) =>
            updateConfigFn((draft) => (draft.relativeDateRange = value as RELATIVE_DATE_RANGES))
          }
          placeholder="Select relative range"
          selectedValue={config.relativeDateRange}
          values={RELATIVE_DATE_RANGE_OPTIONS}
        />
      )}
    </>
  );
};

const RELATIVE_DATE_RANGE_OPTIONS = Object.values(RELATIVE_DATE_RANGES).map((date) => ({
  value: date,
}));
