import { useEffect, useState } from 'react';

import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { ColumnColorTracker } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DatasetRow } from 'types/datasets';
import { setTableColorCategoryData } from 'utils/colorCategorySyncUtils';
import { columnConfigsToDisplayOptions } from 'utils/reportBuilderConfigUtils';

export function useColorCategoryTracker(
  globalStyleConfig: GlobalStyleConfig,
  columnConfigs?: ReportBuilderColConfigs,
  previewData?: DatasetRow[],
) {
  const [colorCategoryTracker, setColorCategoryTracker] = useState<ColumnColorTracker>({});

  useEffect(() => {
    if (!columnConfigs) return;
    setColorCategoryTracker({});
  }, [columnConfigs]);

  useEffect(() => {
    if (!columnConfigs || !previewData?.length) return;
    setColorCategoryTracker((prev) =>
      setTableColorCategoryData({
        columnColorTracker: prev,
        previewData: previewData,
        displayOptions: columnConfigsToDisplayOptions(columnConfigs),
        globalStyleConfig,
      }),
    );
  }, [previewData, columnConfigs, globalStyleConfig]);
  return colorCategoryTracker;
}
