import { FC, useContext } from 'react';

import { DownloadSpreadsheetFunc, DownloadPanelFunc } from 'actions/exportActions';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { ExportOptions } from 'components/ExportOptions/ExportOptions';
import { EmbedButton, EmbedPopover } from 'components/embed';
import { ExportConfig } from 'constants/types';
import { useDownload } from 'utils/exportUtils';

type Props = {
  dataPanelId: string;
  disabled?: boolean;
  exportConfig: ExportConfig;
  isDrilldownModal: boolean;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadPanelFunc;
};

export const DownloadTableButton: FC<Props> = ({
  dataPanelId,
  disabled,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  exportConfig,
  isDrilldownModal,
}) => {
  const context = useContext(DashboardLayoutContext);

  const { windowState, downloadInfo } = useDownload(
    dataPanelId,
    exportConfig.disableDownloadExport,
  );

  return (
    <EmbedPopover
      align="end"
      portalContainerId={isDrilldownModal ? undefined : context.dashboardLayoutTagId}
      trigger={<EmbedButton disabled={disabled} icon="download" variant="tertiary" />}
      width="medium">
      <ExportOptions
        dataPanelId={dataPanelId}
        downloadInfo={downloadInfo}
        exportConfig={exportConfig}
        onDownloadPanelPdf={onDownloadPanelPdf}
        onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
        windowState={windowState}
      />
    </EmbedPopover>
  );
};
