import { ReactNode, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { Icon, Spinner, sprinkles, Tag } from 'components/ds';
import { renderCell, RenderCellParams } from 'components/ds/DataGrid/columnUtils';
import { EmbedButton, EmbedCheckbox } from 'components/embed';
import { INTEGER_DATA_TYPE, STRING, UNSIGNED_INTEGER } from 'constants/dataConstants';
import { StringDisplayFormat, StringDisplayOptions } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { getCurrentColorTracker } from 'reportBuilderContent/reducers/reportEditingReducer';
import { getDistinctDataId } from 'reportBuilderContent/reducers/reportEditingUtils';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { fetchDistinctColumnData } from 'reportBuilderContent/thunks';
import { DISTINCT_COLUMN_LIMIT } from 'reportBuilderContent/thunks/utils';
import { ColumnConfigWithName } from 'types/columnTypes';
import { FilterableColumn } from 'utils/customerReportUtils';

import * as styles from './styles.css';

type Props<T extends string | number> = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  columnConfig: ColumnConfigWithName;
  onChange: (value: T[]) => void;
};

export const MultiNumberAndStringFilter = <T extends string | number>({
  clause,
  column,
  columnConfig,
  onChange,
}: Props<T>) => {
  const [value, setValue] = useState(getFilterArrayValue<T>(clause));
  const [search, setSearch] = useState('');

  const isStringCol = column.type === STRING;
  const isUnsignedIntCol = column.type === UNSIGNED_INTEGER;
  const isIntCol = column.type === INTEGER_DATA_TYPE || isUnsignedIntCol;

  const dispatch = useDispatch();
  const { distinctColData, colorCategoryTracker } = useSelector(
    (state: ReportBuilderReduxState) => ({
      distinctColData: state.reportEditing.reportData[getDistinctDataId(column)],
      colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
    }),
    shallowEqual,
  );

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  useEffect(() => {
    // Aggs are not supported in this filter
    if (
      !column.isPostFilter &&
      (!distinctColData ||
        !(distinctColData.rows || distinctColData.isLoading || distinctColData.error))
    ) {
      dispatch(fetchDistinctColumnData(column));
    }
  }, [column, dispatch, distinctColData]);

  const filteredCategories = useMemo(() => {
    if (!distinctColData?.rows) return [];

    const searchQuery = search.trim().toLowerCase();
    const filtered: T[] = [];
    distinctColData.rows.forEach((row) => {
      const value = row[column.name];
      const searchValue = String(value).toLowerCase();
      if (searchValue.includes(searchQuery)) {
        filtered.push(value as T);
      }
    });
    return filtered;
  }, [column.name, distinctColData, search]);

  const toggleCategory = (category: T) =>
    setValue((prev) => {
      if (!Array.isArray(prev)) return [category];

      const index = prev.findIndex((r) => r === category);
      if (index === -1) return [...prev, category];

      const next = [...prev];
      next.splice(index, 1);
      return next;
    });

  const parsedSearch = useMemo(() => {
    return (isStringCol ? search : isIntCol ? parseInt(search) : parseFloat(search)) as T;
  }, [isIntCol, isStringCol, search]);

  const addCustomCategory = () => {
    // number type=input mostly shouldn't allow numbers, but we have this check just in case
    if (parsedSearch === '' || (typeof parsedSearch === 'number' && isNaN(parsedSearch))) return;

    setValue((prev) => {
      const index = prev.findIndex((r) => r === parsedSearch);
      if (index !== -1) return prev;
      return [...prev, parsedSearch];
    });
  };

  const valueSet = useMemo(() => new Set(value), [value]);

  return (
    <>
      <div className={styles.multiStringContent}>
        {value?.length ? (
          <div className={styles.categoryTabsContainer}>
            <div className={styles.categoryTabs}>
              {value.map((category) => (
                <Tag
                  className={sprinkles({ margin: 'sp.5' })}
                  key={category}
                  onClose={() => toggleCategory(category)}>
                  {renderFilter({
                    value: String(category),
                    config: columnConfig,
                    columnInfo: column,
                    colorTracker: colorCategoryTracker,
                  })}
                </Tag>
              ))}
            </div>
          </div>
        ) : null}

        <div className={searchContainerStyle}>
          <div className={sprinkles({ position: 'relative' })}>
            <Icon className={styles.searchIcon} name="search" />
            <input
              className={styles.searchInput}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search value"
              step={isStringCol ? undefined : isIntCol ? 1 : 0.1}
              type={isStringCol ? 'text' : 'number'}
              value={search}
            />
          </div>
        </div>

        {distinctColData?.isLoading ? (
          <Spinner fillContainer className={sprinkles({ padding: 'sp2' })} size="lg" />
        ) : distinctColData?.error ? (
          <EmbedText body="b3" className={sprinkles({ padding: 'sp2' })}>
            Failed to load categories. Please refresh
          </EmbedText>
        ) : (
          <div className={styles.categoryList}>
            {filteredCategories.map((category) => {
              return (
                <div
                  className={styles.categoryItem}
                  key={category}
                  onClick={() => toggleCategory(category)}>
                  <EmbedCheckbox isChecked={valueSet.has(category)} onChange={() => null} />
                  {renderFilter({
                    value: String(category),
                    config: columnConfig,
                    columnInfo: column,
                    colorTracker: colorCategoryTracker,
                  })}
                </div>
              );
            })}
            {filteredCategories.length === 0 && (
              <div className={styles.categoryItem}>
                <EmbedButton
                  disabled={valueSet.has(parsedSearch as T)}
                  onClick={addCustomCategory}
                  variant="secondary">{`Search for "${search}"`}</EmbedButton>
              </div>
            )}
          </div>
        )}

        {distinctColData?.rows?.length === DISTINCT_COLUMN_LIMIT && (
          <EmbedText
            body="b3"
            className={sprinkles({
              textAlign: 'center',
              paddingX: 'sp2',
              paddingBottom: 'sp1',
              color: 'contentTertiary',
              borderBottom: 1,
              borderColor: 'outline',
            })}>
            <Icon name="circle-info" size="sm" /> For performance, only the first 1000 values are
            shown in this list. If you do not see the value you are looking for, type it in and
            click the Search button.
          </EmbedText>
        )}
      </div>
    </>
  );
};

const linkOrImage: Set<string> = new Set([StringDisplayFormat.LINK, StringDisplayFormat.IMAGE]);

function renderFilter({ config, ...params }: RenderCellParams): ReactNode {
  const stringFormat = config?.displayFormatting as StringDisplayOptions;

  // It doesn't make sense to render links or images because we are aggregating values together
  const filterColumnConfig = linkOrImage.has(stringFormat?.format ?? '')
    ? { displayFormatting: { ...stringFormat, format: StringDisplayFormat.NORMAL } }
    : config;

  return renderCell({ ...params, config: filterColumnConfig });
}

// If the user switches between "multiselect" filtering and single filtering, we have to reset the value
function getFilterArrayValue<T>(clause?: CustomerReportFilter): T[] {
  if (Array.isArray(clause?.filterValue)) return clause?.filterValue as T[];
  return [];
}

const searchContainerStyle = sprinkles({
  flex: 1,
  backgroundColor: 'gray1',
  paddingX: 'sp2',
});
