import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { ParentSchema } from 'actions/dataSourceActions';
import { sprinkles, Tag, Select } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { cloneDeep, find, groupBy } from 'utils/standard';

import * as styles from '../styles.css';

import type { CustomerContentProps } from '.';

type Props = Pick<CustomerContentProps, 'accessGroups' | 'editorGroup' | 'setEditorGroup'>;

export const SchemasSection: FC<Props> = ({ accessGroups, editorGroup, setEditorGroup }) => {
  const { dataSources, parentSchemas, selectedParent } = useSelector(
    (state: ReduxState) => ({
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      selectedParent: state.customers.selectedParent,
    }),
    shallowEqual,
  );
  const selectedAccessGroup = accessGroups.find(({ id }) => id === editorGroup.accessGroupId);
  const accessGroupDataSources = useMemo(
    () =>
      selectedAccessGroup?.data_source_ids
        ? RD.getOrDefault(dataSources, []).filter((dataSource) =>
            selectedAccessGroup?.data_source_ids.includes(dataSource.id),
          )
        : [],
    [selectedAccessGroup, dataSources],
  );

  const dataSourcesBySchemaId = useMemo(
    () => groupBy(accessGroupDataSources, (datasource) => datasource.parent_schema_id),
    [accessGroupDataSources],
  );

  const defaultDataSources = useMemo(
    () =>
      accessGroupDataSources.filter((ds) =>
        selectedAccessGroup?.default_data_source_ids.includes(ds.id),
      ),
    [accessGroupDataSources, selectedAccessGroup],
  );

  const defaultDataSourcesBySchemaId = useMemo(
    () =>
      Object.fromEntries(
        RD.getOrDefault(parentSchemas, []).map((schema) => [
          schema.id,
          defaultDataSources.find((ds) => ds.parent_schema_id === schema.id),
        ]),
      ),
    [parentSchemas, defaultDataSources],
  );

  const handleCancelClick = (schemaId: number) => {
    const newMapping = cloneDeep(editorGroup.mapping);
    delete newMapping[schemaId];
    setEditorGroup((draft) => {
      draft.mapping = newMapping;
    });
  };
  const inheritedDataSources = selectedParent?.computed_parent_schema_datasource_mapping;

  const renderSchemaInfoTag = (
    schemaId: number,
    selectedDataSourceId: number | undefined,
    inheritedDataSourceId: number | undefined,
    defaultDataSourceId: number | undefined,
  ) => {
    const hasSelectedDS = selectedDataSourceId !== undefined;
    const hasInheritedDS = inheritedDataSourceId !== undefined;
    // this should never be false
    const hasDefaultDS = defaultDataSourceId !== undefined;

    // inheriting from parent
    if (!hasSelectedDS && hasInheritedDS)
      return <Tag className={styles.schemaInfoTag}>Inheriting From Parent</Tag>;
    // overwriting parent or overwriting default
    else if (hasSelectedDS) {
      return (
        <Tag intent="active" onClose={() => handleCancelClick(schemaId)}>
          {hasInheritedDS ? 'Overwriting Parent' : 'Overwriting Default'}
        </Tag>
      );
    }
    // using default (note that this is really an else, but keeping it as an else if for safety)
    else if (!hasSelectedDS && hasDefaultDS)
      return <Tag className={styles.schemaInfoTag}>Visibility Group Default</Tag>;
  };

  const renderSchema = (schema: ParentSchema) => {
    const dataSourcesForSchema = dataSourcesBySchemaId[schema.id];

    if (!dataSourcesForSchema) return;

    const selectedDataSource =
      schema.id in editorGroup.mapping
        ? find(
            dataSourcesForSchema,
            (dataSource) => String(dataSource.id) === editorGroup.mapping[schema.id],
          )
        : undefined;

    const inheritedDataSource =
      inheritedDataSources && schema.id in inheritedDataSources
        ? find(
            dataSourcesForSchema,
            (dataSource) => String(dataSource.id) === inheritedDataSources?.[schema.id],
          )
        : undefined;

    const defaultDataSource = defaultDataSourcesBySchemaId[schema.id];

    return (
      <div className={styles.sectionRowContainer} key={schema.id}>
        <div className={cx(styles.label, sprinkles({ justifyContent: 'space-between' }))}>
          <div className={sprinkles({ truncateText: 'ellipsis' })} title={schema.name}>
            {schema.name}
          </div>
          {renderSchemaInfoTag(
            schema.id,
            selectedDataSource?.id,
            inheritedDataSource?.id,
            defaultDataSource?.id,
          )}
        </div>
        <Select
          onChange={(id) =>
            setEditorGroup((draft) => {
              draft.mapping[schema.id] = id;
            })
          }
          placeholder="Select data source"
          selectedValue={
            selectedDataSource !== undefined
              ? String(selectedDataSource.id)
              : inheritedDataSource !== undefined
              ? String(inheritedDataSource.id)
              : defaultDataSource !== undefined
              ? String(defaultDataSource.id)
              : undefined
          }
          values={dataSourcesForSchema.map((dataSource) => ({
            value: String(dataSource.id),
            label: dataSource.name,
          }))}
        />
      </div>
    );
  };

  return (
    <div className={sprinkles({ gap: 'sp1', flexItems: 'column', paddingY: 'sp1.5' })}>
      {RD.getOrDefault(parentSchemas, []).map(renderSchema)}
    </div>
  );
};
