import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  EnvTagUsage,
  createEnvironmentTag,
  deleteEnvironmentTag,
  fetchEnvironmentTags,
  getEnvironmentTagUsage,
  updateEnvironmentTag,
} from 'actions/environmentTagActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import {
  AlertModal,
  Button,
  CalloutLink,
  IconButton,
  Input,
  Spinner,
  sprinkles,
} from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { sortBy } from 'utils/standard';

import * as styles from './styles.css';

type CurrentInteraction =
  | { type: 'create'; name?: string; hex?: string }
  | { type: 'edit'; tagId: number }
  | { type: 'delete'; tagId: number; tagName: string };

export const SettingsEnvironmentsSection: FC = () => {
  const dispatch = useDispatch();

  const [currentInteraction, setCurrentInteraction] = useState<CurrentInteraction>();

  const tags = useSelector((state: ReduxState) => state.environmentTags.tags);

  useEffect(() => {
    if (RD.isIdle(tags)) dispatch(fetchEnvironmentTags());
  }, [tags, dispatch]);

  const updateTagColor = (tagId: number, colorHex: string) =>
    dispatch(updateEnvironmentTag({ postData: { tag_id: tagId, color_hex: colorHex } }));

  const updateTagName = (tagId: number, name: string) =>
    dispatch(updateEnvironmentTag({ postData: { tag_id: tagId, name } }));

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
        <div className={sprinkles({ heading: 'h2' })}>Environment Tags</div>
        <div className={sprinkles({ body: 'b2', color: 'gray11' })}>
          Environment tags are a way of managing which version of your dashboard or report builder
          is being shown in your application
        </div>
        <CalloutLink
          className={sprinkles({ marginTop: 'sp1' })}
          text="Check out the docs to find more"
          url="https://docs.explo.co/dashboard-features/version-control/managing-environment-tags"
        />
      </div>
      {!RD.isSuccess(tags) ? (
        <div className={sprinkles({ flexItems: 'centerColumn' })}>
          <Spinner size="xl" />
        </div>
      ) : (
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
          {sortBy(tags.data, 'id').map((tag) => {
            const isEditing =
              currentInteraction?.type === 'edit' && currentInteraction.tagId === tag.id;

            return (
              <div className={tagContainerClass} key={tag.id}>
                <ColorPickerButton
                  btnClassName={styles.colorPickerButton}
                  className={styles.colorPickerWrapper}
                  color={tag.color_hex}
                  colorPalette={[]}
                  onColorChange={(newHex) => updateTagColor(tag.id, newHex)}
                  size={32}
                />
                <Input
                  className={sprinkles({ flex: 1 })}
                  defaultValue={tag.name}
                  disabled={!isEditing}
                  onSubmit={(newName) => {
                    if (!newName.trim()) return;
                    updateTagName(tag.id, newName.toLowerCase().replace(/[\W\s+]+/g, '_'));
                    setCurrentInteraction(undefined);
                  }}
                />
                <IconButton
                  name="pencil"
                  onClick={() =>
                    setCurrentInteraction(isEditing ? undefined : { type: 'edit', tagId: tag.id })
                  }
                  variant="primary"
                />
                <IconButton
                  disabled={isEditing}
                  name="trash"
                  onClick={() =>
                    setCurrentInteraction({ type: 'delete', tagId: tag.id, tagName: tag.name })
                  }
                  variant="destructive"
                />
              </div>
            );
          })}
          {currentInteraction?.type === 'create' ? (
            <div className={tagContainerClass}>
              <ColorPickerButton
                btnClassName={styles.colorPickerButton}
                className={styles.colorPickerWrapper}
                color={currentInteraction.hex ?? '#E51D00'}
                colorPalette={[]}
                onColorChange={(newHex) =>
                  setCurrentInteraction({ ...currentInteraction, hex: newHex })
                }
                size={32}
              />
              <Input
                autoFocus
                className={sprinkles({ flex: 1 })}
                defaultValue={currentInteraction.name}
                onSubmit={(newName) => {
                  if (!newName.trim()) return;
                  setCurrentInteraction({
                    ...currentInteraction,
                    name: newName.toLowerCase().replace(/[\W\s+]+/g, '_'),
                  });
                }}
              />
              <IconButton
                disabled={!currentInteraction.name}
                name="check"
                onClick={() => {
                  if (!currentInteraction.name) return;
                  dispatch(
                    createEnvironmentTag(
                      {
                        postData: { name: currentInteraction.name, hex: currentInteraction.hex },
                      },
                      () => setCurrentInteraction(undefined),
                    ),
                  );
                }}
                variant="primary"
              />
            </div>
          ) : null}
          <Button
            className={sprinkles({ marginTop: 'sp1' })}
            disabled={!!currentInteraction}
            onClick={() => setCurrentInteraction({ type: 'create' })}>
            Add new Environment Tag
          </Button>
        </div>
      )}
      {currentInteraction?.type === 'delete' ? (
        <DeleteEnvTagModal
          onClose={() => setCurrentInteraction(undefined)}
          tagId={currentInteraction.tagId}
          tagName={currentInteraction.tagName}
        />
      ) : null}
    </div>
  );
};

const tagContainerClass = sprinkles({
  padding: 'sp1',
  backgroundColor: 'elevationMid',
  borderRadius: 4,
  flexItems: 'alignCenter',
  gap: 'sp1',
});

type DeleteProps = {
  tagId: number;
  tagName: string;
  onClose: () => void;
};

const DeleteEnvTagModal: FC<DeleteProps> = ({ tagId, tagName, onClose }) => {
  const dispatch = useDispatch();

  const [usage, setUsage] = useState<RD.ResponseData<EnvTagUsage>>(RD.Loading());

  useEffect(() => {
    dispatch(
      getEnvironmentTagUsage(
        { id: tagId },
        (data) => setUsage(RD.Success(data)),
        () =>
          setUsage(
            RD.Error('Error loading environment tag usage. Please contact support for help.'),
          ),
      ),
    );
  }, [dispatch, tagId]);

  const title = `Are you sure you want to delete ${tagName}?`;
  const confirmText = `Delete ${tagName}`;

  const renderOneUsage = (name: string, type: string) => (
    <div>
      The {type} <b>{name}</b> is assigned to this environment tag.
    </div>
  );

  const renderMultipleUsage = (names: string[], type: string) => (
    <div>
      <b>{names.length}</b> {type}s are assigned to this environment tag including{' '}
      {names.slice(0, 5).join(', ')}
    </div>
  );

  const renderUsageText = ({ dashboard_names, report_builder_names }: EnvTagUsage) => {
    const dashCount = dashboard_names.length;
    const rbCount = report_builder_names.length;

    return (
      <>
        {!dashCount && !rbCount ? (
          'This environment tag is not being used.'
        ) : (
          <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
            {dashCount
              ? dashCount > 1
                ? renderMultipleUsage(dashboard_names, 'dashboard')
                : renderOneUsage(dashboard_names[0], 'dashboard')
              : ''}
            {rbCount
              ? rbCount > 1
                ? renderMultipleUsage(report_builder_names, 'report builder')
                : renderOneUsage(report_builder_names[0], 'report builder')
              : ''}
          </div>
        )}
      </>
    );
  };

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled: !RD.isSuccess(usage),
        text: confirmText,
        onClick: () => dispatch(deleteEnvironmentTag({ id: tagId })),
      }}
      onClose={onClose}
      title={title}>
      <div className={sprinkles({ paddingX: 'sp4' })}>
        <RD.RemoteComponent
          Error={(errorMsg) => <span>{errorMsg}</span>}
          Loading={() => <Spinner fillContainer />}
          Success={renderUsageText}
          data={usage}
        />
      </div>
    </AlertModal>
  );
};
