import * as Dialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

import { sprinkles, IconButton } from 'components/ds';
import { modalHeader } from 'components/ds/Modal/index.css';

type Props = {
  title?: string;
  onBack?: () => void;
};

export const EmbedModalHeader = forwardRef<HTMLDivElement, Props>(({ title, onBack }, ref) => {
  return (
    <div className={modalHeader} ref={ref}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
        {onBack ? (
          <IconButton data-testid="modal-back-button" name="arrow-left" onClick={onBack} />
        ) : null}
        {title ? (
          <Dialog.Title className={sprinkles({ heading: 'h2', margin: 'sp0' })}>
            {title}
          </Dialog.Title>
        ) : null}
      </div>
      <Dialog.Close asChild>
        <IconButton data-testid="modal-close-button" name="cross" />
      </Dialog.Close>
    </div>
  );
});

EmbedModalHeader.displayName = 'EmbedModalHeader';
