import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Icon, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { sortBy } from 'utils/standard';

import * as styles from './DatasetSelection.css';

type Props = {
  selectedDatasetId?: string;
  onClick: (dataset: ReportBuilderDataset) => void;
};

export const DatasetSelection: FC<Props> = ({ onClick, selectedDatasetId }) => {
  const reportBuilderConfig = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.reportBuilderVersion?.config,
  );

  const sortedPopulatedDatasets = useMemo(() => {
    const populatedDatasets = Object.values(reportBuilderConfig?.datasets ?? {}).filter(
      (dataset) => dataset.schema?.length,
    );
    return sortBy(populatedDatasets, (dataset) => dataset.name);
  }, [reportBuilderConfig]);

  if (!reportBuilderConfig) return null;

  const renderDataset = (dataset: ReportBuilderDataset) => {
    return (
      <div
        className={cx(styles.datasetItem, {
          [styles.selectedDatasetItem]: dataset.id === selectedDatasetId,
        })}
        key={dataset.id}
        onClick={() => onClick(dataset)}>
        <div>
          <EmbedText heading="h3">{dataset.name}</EmbedText>
          {dataset.description.trim() === '' ? null : (
            <div className={sprinkles({ marginTop: 'sp1' })}>
              <EmbedText body="b1">{dataset.description}</EmbedText>
            </div>
          )}
        </div>
        <Icon name="angle-right" />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <EmbedText color="contentTertiary" heading="h3">
        Select a dataset
      </EmbedText>
      <div className={styles.datasetList}>{sortedPopulatedDatasets.map(renderDataset)}</div>
    </div>
  );
};
