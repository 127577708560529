import * as RadixSlider from '@radix-ui/react-slider';
import cx from 'classnames';
import { FC } from 'react';

import * as styles from './index.css';

export type Props = RadixSlider.SliderProps & {
  numThumbs: number;
};

export const Slider: FC<Props> = ({ numThumbs, className, ...rootProps }) => {
  return (
    <RadixSlider.Root className={cx(styles.SliderRoot, className)} {...rootProps}>
      <RadixSlider.Track className={styles.SliderTrack}>
        <RadixSlider.Range className={styles.SliderRange} />
      </RadixSlider.Track>
      {[...Array(numThumbs)].map((_, i) => (
        <RadixSlider.Thumb className={styles.SliderThumb} key={i} />
      ))}
    </RadixSlider.Root>
  );
};
