import { IProps, IconName, Icon } from '@blueprintjs/core';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  RenderMode,
  IRegion,
  Regions,
} from '@blueprintjs/table';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import Color from 'color';
import { ReactElement, useState, useContext, useRef, useEffect, memo } from 'react';
import ResizeObserver from 'react-resize-observer';

import { DatasetDataObject } from 'actions/datasetActions';
import { TableProgressBar } from 'components/TableProgressBar';
import { ColumnHeaderText } from 'components/dataTable/columnHeaderText';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { BOOLEAN, DATE_TYPES, DECIMAL_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  ColumnWidths,
  LegacyWidths,
  DateDisplayOptions,
  ImageShapeFormat,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  SchemaDisplayOptions,
  SortOrder,
  StringDisplayFormat,
  StringDisplayOptions,
  SchemaChange,
  StringFormat,
} from 'constants/types';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { formatNumberValue, formatDateField } from 'pages/dashboardPage/charts/utils';
import useBaseDataTableStyles, { TABLE_ROW_HEIGHT } from 'styles/useBaseDataTableStyles';
import { DashboardVariableMap, MetricsByColumn } from 'types/dashboardTypes';
import { SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import {
  getCurrentBooleanIcons,
  getCurrentStringFormat,
  getLinkInfo,
} from 'utils/formatConfigUtils';
import { mixColors } from 'utils/general';
import { getGradientColor, isGradientEnabled } from 'utils/gradientUtils';
import { convertHexToRGBA } from 'utils/graphUtils';
import { uniqueId } from 'utils/standard';
import {
  resolveTooltipVariables,
  isJoinConfigReady,
  resolveNumberInputWithVariables,
} from 'utils/variableUtils';

import * as styles from './styles.css';
import {
  defaultFormatCellData,
  getLegacyColumnWidths,
  getDefaultColumnWidth,
  getCellAlignment,
} from './utils';

const GENERIC_TABLE_HEIGHT = 277;
const TABLE_CORNER_DIMENSIONS = 30;

const useStyles = makeStyles((theme: Theme) => ({
  tableHeight: {
    height: GENERIC_TABLE_HEIGHT,
  },
  noBorderRadius: {
    borderRadius: 0,
  },
  table: {
    flex: 1,
  },
  tableCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    cursor: 'default !important',
    '&.drilldownCell': {
      cursor: 'pointer !important',
      '&:hover': {
        backgroundColor: convertHexToRGBA(
          styleConfigAndProps.base.actionColor.interactionStateColor ||
            styleConfigAndProps.base.actionColor.default,
          0.15,
        ),
      },
    },

    '&.selectedCell': {
      cursor: 'pointer !important',
      backgroundColor: convertHexToRGBA(
        styleConfigAndProps.base.actionColor.interactionStateColor ||
          styleConfigAndProps.base.actionColor.default,
        0.15,
      ),
    },
  }),
  columnHeaderCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    height: styleConfigAndProps.rowHeight || TABLE_ROW_HEIGHT,

    '&:hover .columnSortIcon': {
      visibility: 'initial',
    },
  }),
  columnHeaderCellWithTooltip: {
    '& .bp3-table-column-name-text': {
      pointerEvents: 'auto',
    },
  },
  categoryCellData: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 16,
    display: 'inline-block',
  },
  cellImageContainer: {
    height: '100%',
    width: '100%',
    padding: 5,
  },
  cellImageDisplay: {
    height: '100%',

    '&.circle': {
      clipPath: 'circle()',
    },
  },
}));

type Props = {
  className?: string;
  schema: DatasetSchema;
  loading?: boolean;
  isSortable: boolean;
  isInitialSortDesc?: boolean;
  maxRows: number;
  rows: DatasetRow[];
  disableRowHeader?: boolean;
  fill?: boolean;
  ignoreInvalidDates?: boolean;
  noBorderRadius?: boolean;
  truncateEmptyRowSpace?: boolean;
  unrestrictedHeight?: boolean;
  useFriendlyNameForHeader?: boolean;
  shouldTruncateText?: boolean;
  sortInfo?: SortInfo_DEPRECATED;
  metricsByColumn?: MetricsByColumn;
  onColumnSelect?: (colIndex: number, schema: DatasetSchema) => void;
  enableColumnResizing?: boolean;
  columnWidths?: ColumnWidths | LegacyWidths;
  onColumnWidthChanged?: (index: number, size: number) => void;
  schemaDisplayOptions?: SchemaDisplayOptions;
  rowHeight?: number;
  rowLinesDisabled?: boolean;
  columnLinesEnabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  isFirstColumnFrozen?: boolean;
  isFirstRowBolded?: boolean;
  firstRowBackgroundColor?: string;
  shouldVisuallyGroupByFirstColumn?: boolean;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  isDashboardTable?: boolean;
  isPDFTable?: boolean;
  changeSchemaList?: SchemaChange[];
  firstColumnTitle?: string;
  dateFormat?: string;
  stringFormat?: StringFormat;
  isExpandedDrilldownFormatting?: boolean;
  selectedColumnIndex?: number;
  onColumnUnselect?: () => void;
  variables: DashboardVariableMap;
  onRowSelection?: (row: DatasetRow | undefined) => void;
};

const BaseDataTable = memo(function BaseDataTable(props: Props) {
  const {
    schema,
    rows,
    ignoreInvalidDates,
    sortInfo,
    isSortable,
    onColumnWidthChanged,
    shouldTruncateText,
    schemaDisplayOptions,
    metricsByColumn,
    columnLinesEnabled,
    firstRowBackgroundColor,
    datasetData,
    datasetNamesToId,
    isDashboardTable,
    isPDFTable,
    changeSchemaList,
    firstColumnTitle,
    dateFormat,
    stringFormat,
    isExpandedDrilldownFormatting,
    selectedColumnIndex,
    variables,
    onRowSelection,
    isInitialSortDesc,
  } = props;

  const context = useContext(GlobalStylesContext);
  const classes = useStyles({ ...context.globalStyleConfig, ...props });
  const sharedClasses = useBaseDataTableStyles({ ...context.globalStyleConfig });
  const theme: Theme = useTheme();
  const tableInstance = useRef<Table>(null);
  const [tableWidth, setTableWidth] = useState(-1);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [columnToCategoryToColorMap, setColumnToCategoryToColorMap] = useState<
    Record<string, Record<string | number, string>>
  >({});

  useEffect(() => setSelectedRow(undefined), [rows]);

  const getSchemaChange = (index: number) =>
    changeSchemaList?.find((schemaChange) => schemaChange.col === schema[index].name);

  const addCategoryToColorMap = (
    columnName: string,
    category: string | number,
    assignedColor?: string,
  ) => {
    const numKeys = Object.keys(columnToCategoryToColorMap[columnName] || {}).length;
    const color = assignedColor || DEFAULT_CATEGORY_COLORS[numKeys % 12];
    setColumnToCategoryToColorMap((current) => {
      if (!current[columnName]) current[columnName] = {};
      current[columnName][category] = color;
      return current;
    });

    return color;
  };

  useEffect(() => {
    // after the table renders, resize it for any text that needs to wrap
    !shouldTruncateText && resizeRowHeightToFitContent();
  });

  const joinMapping: Record<string, Record<string, Record<string | number, string | number>>> = {};

  const resizeRowHeightToFitContent = () => {
    tableInstance.current &&
      tableInstance.current.resizeRowsByApproximateHeight((rowIndex: number, colIndex: number) =>
        String(getCellData(rowIndex, colIndex)),
      );
  };

  const getVisualSortingValues = (rowIndex: number, colIndex: number, rows: DatasetRow[]) => {
    let isGroupedRow = false;
    let shouldHideText = false;

    if (props.shouldVisuallyGroupByFirstColumn) {
      const firstColCellData = getCellData(rowIndex, 0);

      if (rowIndex < rows.length - 1) {
        const firstColNextCellData = getCellData(rowIndex + 1, 0);

        isGroupedRow = firstColCellData === firstColNextCellData;
      }

      if (rowIndex > 0 && colIndex === 0) {
        const firstColPrevCellData = getCellData(rowIndex - 1, 0);

        shouldHideText = firstColCellData === firstColPrevCellData;
      }
    }

    return [isGroupedRow, shouldHideText];
  };

  const getCellData = (rowIndex: number, colIndex: number) => {
    const header = schema[colIndex].name;
    const cellData = rows[rowIndex][header];
    return cellData === undefined ? '' : cellData;
  };

  const cellRenderer = (rowIndex: number, colIndex: number) => {
    const cellData = getCellData(rowIndex, colIndex);

    const [isGroupedRow, shouldHideText] = getVisualSortingValues(rowIndex, colIndex, rows);

    const column = schema[colIndex];
    let backgroundColor, color;
    const displayOptions = schemaDisplayOptions?.[column.name];

    if (firstRowBackgroundColor && rowIndex === 0) backgroundColor = firstRowBackgroundColor;

    const gradientType = displayOptions && (displayOptions as NumberDisplayOptions).gradientType;

    if (isGradientEnabled(gradientType)) {
      const { gradient, gradientOptions, displayType } = displayOptions as NumberDisplayOptions;
      const value = Number(cellData);
      if (
        cellData !== null &&
        !isNaN(value) &&
        displayType !== NumberDisplayDisplayType.PROGRESS_BAR
      ) {
        backgroundColor = getGradientColor({
          value,
          gradient,
          gradientType,
          gradientOptions,
          metrics: metricsByColumn?.[column.name],
          variables,
          datasetNamesToId,
          datasetData,
        });
        color = new Color(backgroundColor).isDark()
          ? theme.palette.ds.white
          : theme.palette.ds.black;
      }
    }

    const stringFormat =
      column.type === STRING
        ? getCurrentStringFormat(displayOptions as StringDisplayOptions)
        : undefined;

    const isBold =
      (props.isFirstColumnBolded && colIndex === 0) || (props.isFirstRowBolded && rowIndex === 0);
    const selectedCell = selectedRow === rowIndex;
    const unselectedCell = selectedRow !== undefined && selectedRow >= 0 && !selectedCell;

    const showFirstColumnLine = props.isFirstColumnFrozen && colIndex === 0;

    return (
      <Cell
        className={cx(
          sharedClasses.tableCell,
          classes.tableCell,
          styles.cellText,
          getCellAlignment(displayOptions, column.type),
          {
            firstCell: colIndex === 0,
            imageCell: stringFormat === StringDisplayFormat.IMAGE,
            drilldownCell: onRowSelection && !selectedCell,
            selectedCell,
            [GLOBAL_STYLE_CLASSNAMES.container.fill.deeperOffsetBackgroundColor]: unselectedCell,
            [embedSprinkles({ backgroundColor: 'containerFill' })]:
              !unselectedCell && !selectedCell,
          },
        )}
        style={{
          boxShadow: `inset ${columnLinesEnabled || showFirstColumnLine ? '-1px' : '0'} ${
            props.rowLinesDisabled || isGroupedRow ? '0' : '-1px'
          } 0 ${context.globalStyleConfig.container.outline.color}, inset 0px 0 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...(backgroundColor && { backgroundColor, color }),
          fontWeight: isBold ? 'bold' : undefined,
        }}
        wrapText={!shouldTruncateText}>
        {formatCellData(cellData, column, rowIndex, shouldHideText)}
      </Cell>
    );
  };

  const formatCellData = (
    cellData: string | number,
    column: DatasetColumn,
    rowIndex: number,
    shouldHideText: boolean,
  ) => {
    if (cellData === null || cellData === undefined || shouldHideText) return '';

    // If cellData is an empty string, it indicates that the cell data was undefined or null.
    // Instead of coercing it into 0 with Number("") in formatter, we maintain it blank to differentiate between the two cases.
    if (NUMBER_TYPES.has(column.type) && cellData === '') return '';

    if (!schemaDisplayOptions?.[column.name]) {
      return defaultFormatCellData(
        cellData,
        column,
        isDashboardTable,
        ignoreInvalidDates,
        dateFormat,
      );
    }

    const colConfig = schemaDisplayOptions[column.name];

    let colType = column.type;

    if (isJoinConfigReady(colConfig) && colConfig.joinDisplayColumn && colConfig.joinTable?.id) {
      const datasetId = colConfig.joinTable.id;
      const joinColName = colConfig.joinDisplayColumn.name;
      if (!joinMapping[datasetId]?.[joinColName]) {
        if (!joinMapping[datasetId]) joinMapping[datasetId] = {};
        if (!joinMapping[datasetId][joinColName]) joinMapping[datasetId][joinColName] = {};

        datasetData[datasetId]?.rows?.forEach((row) => {
          if (!colConfig.joinDisplayColumn || !colConfig.joinColumn) return;
          joinMapping[datasetId][joinColName][row[colConfig.joinColumn.name]] = row[joinColName];
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const joinColumnCellData = rows[rowIndex][colConfig.joinColumn!.name];
      cellData =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        joinMapping[colConfig.joinTable.id][colConfig.joinDisplayColumn!.name][
          joinColumnCellData
        ] ?? '';

      colType = colConfig.joinDisplayColumn.column.type;
    }

    if (DATE_TYPES.has(colType)) {
      return formatDateField(
        cellData as string,
        colType,
        colConfig as DateDisplayOptions,
        ignoreInvalidDates,
        isDashboardTable,
      );
    } else if (NUMBER_TYPES.has(colType)) {
      const value = Number(cellData);
      const numberDisplayOptions = schemaDisplayOptions[column.name] as NumberDisplayOptions;
      const {
        goal,
        useColumnMaxForGoal,
        gradientType,
        gradient,
        gradientOptions,
        displayType,
        displayTypeOptions,
        disableHoverTooltip,
      } = numberDisplayOptions;
      const goalOption = useColumnMaxForGoal
        ? metricsByColumn?.[column.name]?.max
        : resolveNumberInputWithVariables(goal, variables, datasetNamesToId, datasetData);

      const formattedValue = formatNumberValue(
        numberDisplayOptions,
        value,
        goalOption,
        DECIMAL_TYPES.has(column.type),
      );

      if (displayType === NumberDisplayDisplayType.PROGRESS_BAR) {
        let progressBarGoal = 0;
        if (displayTypeOptions?.useOtherColumnAsMax && displayTypeOptions?.goalColumnName) {
          progressBarGoal = rows[rowIndex][displayTypeOptions.goalColumnName] as number;
        } else {
          progressBarGoal = (
            displayTypeOptions?.useColumnMaxForProgressBarGoal
              ? metricsByColumn?.[column.name]?.max
              : resolveNumberInputWithVariables(
                  displayTypeOptions?.progressBarGoal,
                  variables,
                  datasetNamesToId,
                  datasetData,
                )
          ) as number;
        }

        const gradientColor = isGradientEnabled(gradientType)
          ? getGradientColor({
              value,
              gradient,
              gradientType,
              gradientOptions,
              metrics: metricsByColumn?.[column.name],
              variables,
              datasetNamesToId,
              datasetData,
            })
          : undefined;

        const backgroundColor =
          gradientColor && metricsByColumn?.[column.name]
            ? mixColors(gradientColor, theme.palette.ds.white, 0.5).rgb().string()
            : theme.palette.ds.lightBlue;
        const color = gradientColor ?? theme.palette.ds.blue;
        return (
          <TableProgressBar
            backgroundColor={backgroundColor}
            color={color}
            disableTooltip={isPDFTable || disableHoverTooltip}
            formattedValue={formattedValue}
            progressBarGoal={progressBarGoal}
            value={value}
          />
        );
      }

      return formattedValue;
    } else if (colType === STRING) {
      const stringDisplayOptions = colConfig as StringDisplayOptions;
      const { categoryColorAssignments, addedCategories, imageShape } = stringDisplayOptions;
      const stringFormat = getCurrentStringFormat(stringDisplayOptions);
      const cellDataString = String(cellData);

      switch (stringFormat) {
        case StringDisplayFormat.CATEGORY: {
          const categoryToColorMap = columnToCategoryToColorMap?.[column.name] || {};
          const addedColor = addedCategories?.find((cat) => cat.name === cellDataString);

          let backgroundColor: string | undefined;
          if (addedColor) {
            backgroundColor = addedColor.color;
            if (backgroundColor !== categoryToColorMap[cellData]) {
              addCategoryToColorMap(column.name, cellData, backgroundColor);
            }
          } else if (categoryColorAssignments?.[cellData]) {
            backgroundColor = categoryColorAssignments?.[cellData];
            if (backgroundColor !== categoryToColorMap[cellData]) {
              addCategoryToColorMap(column.name, cellData, backgroundColor);
            }
          } else if (categoryToColorMap[cellData]) {
            backgroundColor = categoryToColorMap[cellData];
          } else {
            backgroundColor = addCategoryToColorMap(column.name, cellData);
          }

          return (
            <span className={classes.categoryCellData} style={{ backgroundColor }}>
              {cellDataString}
            </span>
          );
        }
        case StringDisplayFormat.LINK: {
          if (!cellDataString) return null;
          const { urlLabel, linkColor, target } = getLinkInfo(stringDisplayOptions, rows[rowIndex]);

          return (
            <a
              href={cellDataString}
              rel="noopener noreferrer"
              style={{ color: linkColor }}
              target={target}>
              {urlLabel}
            </a>
          );
        }
        case StringDisplayFormat.IMAGE:
          return (
            <div className={classes.cellImageContainer}>
              <img
                alt="table cell view"
                className={cx(classes.cellImageDisplay, {
                  circle: imageShape === ImageShapeFormat.CIRCLE,
                })}
                src={cellDataString}
              />
            </div>
          );
      }
    } else if (colType === BOOLEAN) {
      const cellDataString = String(cellData);
      if (!['true', 'false'].includes(cellDataString)) return 'Invalid boolean';

      const { trueIcon, falseIcon } = getCurrentBooleanIcons(colConfig as BooleanDisplayOptions);

      return (
        <Icon
          className={embedSprinkles({ color: 'action' })}
          icon={cellDataString === 'true' ? trueIcon : falseIcon}
        />
      );
    }

    return String(cellData);
  };

  const renderDefaultNameRenderer = (header: string, index?: number) => {
    let rightIcon: IconName | undefined = undefined;
    if (index === undefined) return <div></div>;

    if (sortInfo && schema[index].name === sortInfo.column_name) {
      rightIcon = sortInfo.order === SortOrder.ASC ? 'arrow-up' : 'arrow-down';
    }
    const column = schema[index];
    const schemaChange = getSchemaChange(index);

    return (
      <ColumnHeaderText
        alignment={getCellAlignment(schemaDisplayOptions?.[column.name], column.type)}
        header={header}
        isBold={!!props.isColumnHeadersBolded}
        isInitialSortDesc={isInitialSortDesc}
        isSortable={isSortable}
        rightIcon={rightIcon}
        stringFormat={stringFormat}
        tooltipText={resolveTooltipVariables(
          {
            showTooltip: !isPDFTable && schemaChange?.showTooltip,
            infoTooltipText: schemaChange?.tooltipText,
          },
          variables,
          datasetNamesToId,
          datasetData,
        )}
      />
    );
  };

  const nameRenderer = (
    columnName: string,
    columnInfo: DatasetColumn,
    index: number,
    renderColumnHeaderTextFunction: (header: string, index?: number) => ReactElement<IProps>,
  ) => {
    const resultFn = () => (
      <ColumnHeaderCell
        className={cx(
          sharedClasses.columnHeaderCell,
          classes.columnHeaderCell,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
          getCellAlignment(schemaDisplayOptions?.[columnInfo.name], columnInfo.type),
          {
            firstCell: index === 0,
            [classes.columnHeaderCellWithTooltip]: getSchemaChange(index)?.showTooltip,
          },
        )}
        name={columnName}
        nameRenderer={renderColumnHeaderTextFunction}
        style={{
          boxShadow: columnLinesEnabled
            ? `0 1px 0 ${context.globalStyleConfig.container.outline.color}, inset -1px 0 0 ${context.globalStyleConfig.container.outline.color}`
            : `0 1px 0 ${context.globalStyleConfig.container.outline.color}`,
        }}
        // @ts-ignore
        truncated={false}
      />
    );

    return resultFn;
  };

  let handleSelection;
  if (isSortable || onRowSelection) {
    handleSelection = (selectedRegions: IRegion[]) => {
      const region = selectedRegions[0];
      if (!region) return;
      if (isSortable && !region.rows && region.cols && region.cols[0] === region.cols[1]) {
        props.onColumnSelect && props.onColumnSelect(region.cols[0], schema);
      } else if (onRowSelection && region.rows) {
        const newSelectedRow = region.rows[0] === selectedRow ? -1 : region.rows[0];
        onRowSelection(newSelectedRow === -1 ? undefined : rows[region.rows[0]]);
        setSelectedRow(newSelectedRow);
      }
    };
  } else if (isExpandedDrilldownFormatting && props.onColumnSelect) {
    handleSelection = (selectedRegions: IRegion[]) => {
      const region = selectedRegions[0];
      if (!region || !region.cols) return;
      props.onColumnSelect && props.onColumnSelect(region.cols[0], schema);
    };
  }

  const columns = schema?.map((columnInfo, index) => {
    const columnName =
      index === 0 && firstColumnTitle !== undefined
        ? firstColumnTitle
        : props.useFriendlyNameForHeader
        ? columnInfo.friendly_name
        : columnInfo.name;
    return (
      <Column
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={nameRenderer(
          columnName || '',
          columnInfo,
          index,
          renderDefaultNameRenderer,
        )}
        key={index}
        name={columnInfo.name}
      />
    );
  });

  const columnWidths = getLegacyColumnWidths(schema, props.columnWidths);

  return (
    <div
      className={cx(
        sharedClasses.root,
        {
          [classes.noBorderRadius]: props.noBorderRadius,
          [classes.tableHeight]: !props.unrestrictedHeight,
        },
        props.className,
      )}
      onClick={() => props.onColumnUnselect?.()}
      style={
        props.truncateEmptyRowSpace && rows.length < 13
          ? { height: rows.length * TABLE_ROW_HEIGHT + TABLE_CORNER_DIMENSIONS }
          : undefined
      }>
      <Table
        className={cx(
          { [classes.table]: props.fill },
          sharedClasses.tableTheme,
          sharedClasses.tableContext,
          embedSprinkles({ backgroundColor: 'containerFill' }),
        )}
        columnWidths={columnWidths}
        defaultColumnWidth={getDefaultColumnWidth(schema.length, tableWidth, columnWidths)}
        defaultRowHeight={props.rowHeight || TABLE_ROW_HEIGHT}
        enableColumnResizing={props.enableColumnResizing}
        enableRowHeader={!props.disableRowHeader}
        getCellClipboardData={(row, col) => {
          const cellData = getCellData(row, col);
          return cellData === null ? '' : String(cellData);
        }}
        key={uniqueId('table')}
        numFrozenColumns={props.isFirstColumnFrozen ? 1 : undefined}
        numRows={rows ? Math.min(rows.length, props.maxRows) : props.maxRows}
        onColumnWidthChanged={(index: number, size: number) => {
          onColumnWidthChanged && onColumnWidthChanged(index, size);
          !shouldTruncateText && resizeRowHeightToFitContent();
        }}
        onSelection={handleSelection}
        ref={tableInstance}
        renderMode={RenderMode.BATCH_ON_UPDATE}
        // Passing empty list removes default styling for selection
        // but allows onSelection to still be called
        selectedRegions={
          selectedColumnIndex !== undefined
            ? [Regions.column(selectedColumnIndex)]
            : onRowSelection
            ? []
            : undefined
        }
        selectionModes={isPDFTable ? SelectionModes.NONE : SelectionModes.COLUMNS_AND_CELLS}>
        {columns}
      </Table>
      <ResizeObserver onResize={(rect) => setTableWidth(rect.width)} />
    </div>
  );
});

export default BaseDataTable;
