import { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';

import { reportError } from 'analytics/datadog';
import { Sidebar } from 'components/Sidebar';
import { PageError } from 'components/ds';
import RedirectToDesktop from 'components/pages/redirectToDesktop';

import * as styles from './styles.css';

type Props = {
  activeTabId?: string;
  children: ReactNode;
};

const PageWithNavbar = ({ activeTabId, children }: Props) => {
  if (isMobile) return <RedirectToDesktop />;

  return (
    <div className={styles.appContainer}>
      <Sidebar activeItemId={activeTabId} />
      <div className={styles.pageContainer}>
        <ErrorBoundary FallbackComponent={PageError} onError={reportError}>
          {children}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export { PageWithNavbar };
