import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';

import * as styles from './styles.css';

type Props = { title: string; isComplete?: boolean };

export const FilterSectionHeader: FC<Props> = ({ title, isComplete }) => {
  return (
    <div className={styles.filterSectionHeader}>
      <div className={styles.sectionHeaderText}>{title}</div>
      {isComplete !== undefined ? (
        <Icon
          className={sprinkles({ color: isComplete ? 'green' : 'gray7' })}
          name="circle-check"
        />
      ) : null}
    </div>
  );
};
