import { createSlice } from '@reduxjs/toolkit';

import {
  createNewDashboardVersionSuccess,
  deleteCurrentDashboardDraftSuccess,
  fetchDashboardVersionsRequest,
  fetchDashboardVersionsSuccess,
  publishNewDashboardVersionSuccess,
} from 'actions/dashboardV2Actions';
import { DashboardVersion } from 'types/dashboardVersion';

import { saveExploreDraft } from './thunks/resourceSaveThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';

interface DashboardVersionsReducerState {
  versions: DashboardVersion[];
}

const initialState: DashboardVersionsReducerState = {
  versions: [],
};

const dashboardVersionsSlice = createSlice({
  name: 'dashboardVersions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardVersionsRequest, (state) => {
        state.versions = [];
      })
      .addCase(fetchDashboardVersionsSuccess, (state, { payload }) => {
        state.versions = [...payload.versions].sort((a, b) => b.version_number - a.version_number);
      })
      .addCase(createNewDashboardVersionSuccess, (state, { payload }) => {
        state.versions.unshift(payload.dashboard_version);
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;

        if (!meta.arg.isExplore || !('configuration' in new_version)) return;

        const index = state.versions.findIndex(
          (version) => version.version_number === new_version.version_number,
        );

        // Remove an active draft if present, since we are pushing a new draft based on the revert
        if (index >= 0) {
          state.versions.splice(index, 1);
        }
        state.versions.unshift(new_version);
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        state.versions[0].edit_version_number = payload.edit_version_number;
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload }) => {
        const publishedVersion = state.versions.find(
          ({ id }) => id === payload.dashboard_version.id,
        );

        if (publishedVersion) {
          publishedVersion.is_draft = payload.dashboard_version.is_draft;
          publishedVersion.published_by_id = payload.dashboard_version.published_by_id;
          publishedVersion.change_comments = payload.dashboard_version.change_comments;
          publishedVersion.version_saved_at = payload.dashboard_version.version_saved_at;
          publishedVersion.configuration = payload.dashboard_version.configuration;
        }
      })
      .addCase(deleteCurrentDashboardDraftSuccess, (state, { payload }) => {
        const deletedVersionNumber = payload.postData.version_number;

        const index = state.versions.findIndex(
          (version) => version.version_number === deletedVersionNumber,
        );

        if (index >= 0) state.versions.splice(index, 1);
      });
  },
});

export const dashboardVersionsReducer = dashboardVersionsSlice.reducer;
