import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { samlGetRedirectUrl } from 'actions/authAction';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { Button, Input, sprinkles } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { showErrorToast } from 'shared/sharedToasts';

import * as styles from './SamlInitiateAuthPage.css';

export const SamlInitiateAuthPage: FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Go Back', to: ROUTES.LOGIN },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      title="What's your email?">
      <div className={sprinkles({ width: 'fill', flexItems: 'column', gap: 'sp2' })}>
        <Input fillWidth defaultValue={email} label="Work email" onSubmit={setEmail} />
        <Button
          fillWidth
          className={styles.submitButton}
          onClick={() =>
            dispatch(
              samlGetRedirectUrl(
                { postData: { email } },
                ({ redirect_url }) => (window.location.href = redirect_url),
                () => showErrorToast('No SAML workspace found for this account'),
              ),
            )
          }>
          Sign In with SAML
        </Button>
      </div>
    </OnboardingFlowPage>
  );
};
