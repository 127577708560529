import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch, Toggle, ToggleItem } from 'components/ds';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import {
  ColorFilterPlacement,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { updateVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';

type Props = {
  dataPanelProvidedId: string;
  instructions: V2TwoDimensionChartInstructions;
  visualizationType: OPERATION_TYPES;
};

export const ColorCategoryConfig: FC<Props> = ({
  dataPanelProvidedId,
  instructions,
  visualizationType,
}) => {
  const dispatch = useDispatch();

  const selected = instructions.colorFilterPlacement ?? ColorFilterPlacement.RIGHT_CORNER;
  return (
    <div className={configRootClass}>
      <Toggle
        className={configInputClass}
        label="Placement"
        onValueChange={(value) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.colorFilterPlacement = value as ColorFilterPlacement;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        selectedValue={selected}>
        <ToggleItem value={ColorFilterPlacement.RIGHT_CORNER}>Right Corner</ToggleItem>
        <ToggleItem value={ColorFilterPlacement.BELOW_TITLE}>Below Title</ToggleItem>
      </Toggle>
      <Switch
        className={configInputClass}
        label="Default Group By Off"
        onChange={() => {
          if (instructions.defaultColorGroupingOff && instructions.colorColumnOptions?.length) {
            dispatch(
              updateVariableThunk({
                varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
                newValue: instructions.colorColumnOptions[0].column.name,
              }),
            );
          }
          const newInstructions = produce(instructions, (draft) => {
            draft.defaultColorGroupingOff = !draft.defaultColorGroupingOff;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        switchOn={instructions.defaultColorGroupingOff}
      />
    </div>
  );
};
