/**
 * Fake full text search on the frontend
 *
 * @param query - String to search for
 * @param options - List of options to search through
 * @param getValue - If options are strings, not needed. Otherwise, gets the string value of each option
 */
export const fullTextSearch = <T>(
  query: string,
  options: T[],
  getValue: T extends string ? undefined : (option: T) => string,
) => {
  const parsed = query.trim().toLowerCase();
  return parsed == ''
    ? options
    : options.filter((option) =>
        (getValue ? getValue(option) : String(option)).toLowerCase().includes(parsed),
      );
};
