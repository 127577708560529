// any changes here need to be reflected in FeatureFlags in team.py
export type FeatureFlags = {
  use_job_queue: boolean;
  use_fido: boolean;
  enable_dashboard_sticky_header?: boolean;
  enable_report_builder_image_export?: boolean;
  enable_dashboard_timezones?: boolean;
  show_unfiltered_dataset_flag?: boolean;
  enable_new_grid?: boolean;
  use_archetype_variable_filters?: boolean;
  enable_new_pivot_table?: boolean;
  allow_dashboard_state_persistance?: boolean;
  enable_fill_missing_date_range?: boolean;
};

// any changes here need to be reflected in Configuration in team.py
export type Configuration = {
  pdf_max_rows?: number;
  email_from_name?: string;
  require_dataset_customer_id?: boolean;
  // Incident needs 100k rows in their dropdowns, this is hopefully temporary until we support some kind of look-ahead search
  dataset_max_rows?: number;
  // Together needs more data points on their charts, this is hopefully temporary until we support more dynamic groupings
  data_panel_max_data_points?: number;
};

// any changes here need to be reflected in Entitlements in team.py
export type Entitlements = {
  product_report_builder_enabled: boolean;
  report_builder_ai_enabled: boolean;
  enable_ai?: boolean;
  enable_dashboard_emails?: boolean;
  enable_editable_section?: boolean;
  enable_additional_style_configs?: boolean;
  enable_customer_portal?: boolean;
  enable_cache?: boolean;
};

export const enableSSO = process.env.REACT_APP_ENVIRONMENT !== 'production-medme';
