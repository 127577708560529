import { InputGroup as BP3InputGroup, IInputGroupProps, HTMLInputProps } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { ChangeEvent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  inputGroupContainer: {
    '& .bp3-input': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.grey300}`,
      height: 32,
    },

    '& .bp3-input:focus, .bp3-input.bp3-active': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.blue}`,
    },
  },
}));

type Props = IInputGroupProps &
  // Set onChange to undefined so its not passed in because it will be overwritten
  HTMLInputProps & { onInputChange?: (val: string) => void; onChange?: undefined };

export default function InputGroup({ className, onInputChange, ...otherProps }: Props) {
  const classes = useStyles();

  return (
    <BP3InputGroup
      {...otherProps}
      className={cx(classes.inputGroupContainer, className)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onInputChange?.(e.target.value)}
    />
  );
}
