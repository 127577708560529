import { IconName, Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { ReactNode, FC } from 'react';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';

type Props = {
  className?: string;
  title?: ReactNode;
  icon?: IconName | JSX.Element;
  description?: ReactNode;
  fullHeight?: boolean;
  action?: ReactNode;
};

export const NonIdealState: FC<Props> = ({
  className,
  title,
  icon,
  description,
  fullHeight,
  action,
}) => (
  <div
    className={cx(
      embedSprinkles({ backgroundColor: 'containerFill', body: 'primaryWithoutColor' }),
      sprinkles({
        flexItems: 'centerColumn',
        gap: 'sp1',
        padding: 'sp2',
        parentContainer: 'fill',
        overflow: 'hidden',
      }),
      className,
    )}
    style={{ height: fullHeight ? undefined : `calc(100% - ${HEADER_HEIGHT}px)` }}>
    {icon ? (
      // SVG Icons need to be styled using a container since they have height and width 100%
      // Blueprint Icons need the size prop
      <div className={sprinkles({ flexItems: 'alignCenter' })} style={{ height: 50 }}>
        <Icon icon={icon} size={50} />
      </div>
    ) : null}
    {title ? <div className={embedSprinkles({ heading: 'h2' })}>{title}</div> : null}
    {description ? <div className={embedSprinkles({ body: 'primary' })}>{description}</div> : null}
    {action || null}
  </div>
);
