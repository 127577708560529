import cx from 'classnames';
import { FC } from 'react';

import * as styles from 'components/embed/EmbedTabs/index.css';

type Props = { className?: string };

export const EmbedTabs: FC<Props> = ({ className, children }) => {
  return <div className={cx(styles.tabOptions, className)}>{children}</div>;
};

export { EmbedTab } from './EmbedTab';
