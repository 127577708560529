import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { AlertModal, Input, Select, Toggle, ToggleItem, sprinkles } from 'components/ds';
import { OPERATION_TYPES } from 'constants/types';
import {
  addEditableSectionChart,
  setEditableSectionModal,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { sortBy } from 'utils/standard';

export const AddChartModal: FC = () => {
  const dispatch = useDispatch();

  const [chartName, setChartName] = useState('Chart');
  const [datasetId, setDatasetId] = useState<string>();
  const [vizType, setVizType] = useState(OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const { datasets, isNewPivotTableEnabled } = useSelector(
    (state: ReduxState) => ({
      datasets: state.dashboardEditConfig.config?.datasets,
      isNewPivotTableEnabled: !!state.currentUser.team?.feature_flags.enable_new_pivot_table,
    }),
    shallowEqual,
  );

  const orderedDatasetOptions = useMemo(() => {
    const datasetOptions: { label: string; value: string }[] = [];
    Object.values(datasets ?? {}).forEach((ds) => {
      if (!ds.schema?.length) return;
      datasetOptions.push({ label: ds.table_name, value: ds.id });
    });

    return sortBy(datasetOptions, 'label');
  }, [datasets]);

  const disabled = !chartName.trim() || !datasetId;

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled,
        text: 'Add',
        onClick: () => {
          if (disabled) return;
          dispatch(addEditableSectionChart({ id: v4(), name: chartName, datasetId, vizType }));
        },
        variant: 'primary',
      }}
      onClose={() => dispatch(setEditableSectionModal(null))}
      title="Add chart">
      <div className={sprinkles({ paddingX: 'sp2' })} ref={setContainer}>
        <Input defaultValue={chartName} label="Chart Name" onSubmit={setChartName} />
        <Toggle
          className={sprinkles({ marginY: 'sp2' })}
          label="Chart Type"
          onValueChange={(newType) => setVizType(newType as OPERATION_TYPES)}
          selectedValue={vizType}>
          <ToggleItem value={OPERATION_TYPES.VISUALIZE_TABLE}>Table</ToggleItem>
          {isNewPivotTableEnabled ? (
            <ToggleItem value={OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2}>Pivot Table</ToggleItem>
          ) : null}
          <ToggleItem value={OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}>Other Chart</ToggleItem>
        </Toggle>
        <Select
          collisionBoundary={container}
          label="Dataset"
          onChange={setDatasetId}
          placeholder="Select a dataset..."
          selectedValue={datasetId}
          values={orderedDatasetOptions}
        />
      </div>
    </AlertModal>
  );
};
