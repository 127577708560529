import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { forwardRef } from 'react';

import * as styles from './index.css';

type Props = { className?: string; children: string };

export const MenuLabel = forwardRef<HTMLDivElement, Props>(function MenuLabel(
  { className, children },
  ref,
) {
  return (
    <DropdownMenu.Label className={cx(styles.menuLabel, className)} ref={ref}>
      {children}
    </DropdownMenu.Label>
  );
});
