import { useCallback, useEffect, useState } from 'react';

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  } else {
    return '';
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

function getIsDocumentHidden(): boolean {
  const browserDocumentHiddenProp = getBrowserDocumentHiddenProp();
  // @ts-ignore
  return browserDocumentHiddenProp && !document[browserDocumentHiddenProp];
}

function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
  const onVisibilityChange = useCallback(() => setIsVisible(getIsDocumentHidden()), []);

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return isVisible;
}

export default usePageVisibility;
