import { TypeColumn, TypeComputedProps } from '@inovua/reactdatagrid-enterprise/types';
import produce from 'immer';
import { MutableRefObject, useEffect } from 'react';

import { COLUMN_FITS, ColumnWidths, VisualizeTableInstructions } from 'constants/types';
import { TableColumnConfigs } from 'types/columnTypes';
import { DatasetSchema } from 'types/datasets';
import { debounce, isEqual } from 'utils/standard';

import { CellBordersType, ColumnResizeType } from './index';

export function getCellBorders(instructions: VisualizeTableInstructions): CellBordersType {
  let showCellBorders: CellBordersType = true;
  if (instructions.isColumnLinesEnabled && instructions.isRowLinesDisabled) {
    showCellBorders = 'vertical';
  } else if (!instructions.isColumnLinesEnabled && !instructions.isRowLinesDisabled) {
    showCellBorders = 'horizontal';
  } else if (!instructions.isColumnLinesEnabled && instructions.isRowLinesDisabled) {
    showCellBorders = false;
  }

  return showCellBorders;
}

export function convertColumnConfigs(instructions: VisualizeTableInstructions): TableColumnConfigs {
  const schemaDisplayOptions = instructions.schemaDisplayOptions ?? {};

  // Match SchemaDisplayOptions type to ColumnConfigs type
  return Object.fromEntries(
    Object.entries(schemaDisplayOptions).map(([key, value]) => {
      return [key, { displayFormatting: value }];
    }),
  );
}

export function convertLegacyWidths(
  instructions: VisualizeTableInstructions,
  tableSchema: DatasetSchema,
) {
  const existingWidths = instructions.columnWidths || {};
  if (!existingWidths || !Array.isArray(existingWidths)) return;

  const newWidths: ColumnWidths = {};
  existingWidths.forEach((width, i) => {
    if (!width || !tableSchema[i]) return;
    newWidths[tableSchema[i].name] = Math.round(width);
  });

  return produce(instructions, (draft) => {
    draft.columnWidths = newWidths;
  });
}

export function generateResizedColumnInstructions(
  instructions: VisualizeTableInstructions,
  columns: ColumnResizeType[],
) {
  const newWidths = { ...(instructions.columnWidths as ColumnWidths) };
  columns.forEach((columnInfo) => {
    const columnName = columnInfo.column.name;
    if (!columnName || !columnInfo.width) return;

    newWidths[columnName] = Math.round(columnInfo.width);
  });

  if (isEqual(newWidths, instructions.columnWidths)) return;

  return produce(instructions, (draft) => {
    draft.columnWidths = newWidths;
  });
}

export function useAutosize(
  containerRef: MutableRefObject<HTMLElement | null>,
  gridRef: MutableRefObject<TypeComputedProps | null> | null,
  gridColumns: TypeColumn[],
  columnFit?: COLUMN_FITS,
  columnWidths?: ColumnWidths,
  shouldTruncateText?: boolean,
) {
  const setColumnsSizesAuto = gridRef?.current?.setColumnsSizesAuto;
  // Making this a var that the effect can track will cause the effect to run when columns change on resize
  const columnLength = gridRef?.current?.columns.length || 0;

  const currFit = columnFit || COLUMN_FITS.FILL;

  useEffect(() => {
    if (!containerRef.current || !gridRef?.current || currFit === COLUMN_FITS.FILL) return;

    function autosize() {
      if (!setColumnsSizesAuto || columnLength === 0) return;
      if (currFit === COLUMN_FITS.HEADER) {
        setColumnsSizesAuto({});
      } else if (currFit === COLUMN_FITS.CELL) {
        setColumnsSizesAuto({ skipHeader: true });
      }
    }

    const resizeObserver = new ResizeObserver(debounce(autosize, 100));

    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [
    currFit,
    containerRef,
    gridRef,
    setColumnsSizesAuto,
    columnLength,
    gridColumns,
    columnWidths,
    shouldTruncateText,
  ]);
}
