import { FC } from 'react';

import { Tag, sprinkles } from 'components/ds';

type Props = {
  hidden: boolean;

  onCancel: () => void;
};

export const CustomizedTag: FC<Props> = ({ onCancel, hidden }) => {
  // Return this so that when the tag is hidden, it still takes up same space
  if (hidden) return <div style={{ height: 20 }}></div>;
  return (
    <Tag className={sprinkles({ marginLeft: 'sp1' })} intent="active" onClose={onCancel}>
      <span style={{ fontSize: 10, lineHeight: '100%' }}>Customized</span>
    </Tag>
  );
};
