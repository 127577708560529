import { FC, useEffect, useState } from 'react';

import { sprinkles, Button, Input } from 'components/ds';

type PassedProps = {
  fetchShareData: (password: string, onFailure: () => void, username?: string) => void;
  isUsernameProtected?: boolean;
};

type Props = PassedProps;

export const SharePasswordRequiredPage: FC<Props> = ({ fetchShareData, isUsernameProtected }) => {
  const [inputPassword, setInputPassword] = useState('');
  const [inputUsername, setInputUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVerificationFailed, setPasswordVerificationFailed] = useState(false);

  const errorText = passwordVerificationFailed
    ? `Incorrect password ${isUsernameProtected ? 'or username' : ''}, please try again.`
    : undefined;

  useEffect(() => setPasswordVerificationFailed(false), [inputPassword, inputUsername]);

  return (
    <div className={rootClass}>
      <div className={cardClass} style={{ width: 400 }}>
        <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
          <div
            className={sprinkles({ heading: 'h3', color: 'contentPrimary', marginBottom: 'sp3' })}>
            This dashboard is password protected.
          </div>
          {isUsernameProtected ? (
            <Input
              fillWidth
              className={sprinkles({ marginBottom: 'sp1' })}
              defaultValue={inputUsername}
              onSubmit={setInputUsername}
              placeholder="Enter username here..."
            />
          ) : null}
          <Input
            fillWidth
            className={sprinkles({ marginBottom: 'sp3' })}
            defaultValue={inputPassword}
            errorText={errorText}
            onSubmit={setInputPassword}
            placeholder="Enter password here..."
            type="password"
          />
          <Button
            fillWidth
            loading={loading}
            onClick={() => {
              setLoading(true);
              fetchShareData(
                inputPassword,
                () => {
                  setPasswordVerificationFailed(true);
                  setLoading(false);
                },
                inputUsername,
              );
            }}
            variant="primary">
            Enter
          </Button>
        </div>
      </div>
    </div>
  );
};

const cardClass = sprinkles({
  paddingX: 'sp5',
  paddingY: 'sp3',
  backgroundColor: 'white',
  borderRadius: 4,
  boxShadow: 'sm',
});

const rootClass = sprinkles({
  width: 'fill',
  height: 'fillViewport',
  backgroundColor: 'gray1',
  flexItems: 'centerColumn',
});
