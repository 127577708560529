import { FC, MouseEvent, useMemo } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import * as styles from './ViewTabMenu.css';

interface Props {
  canDelete: boolean;
  onDelete: (e: MouseEvent<HTMLDivElement>) => void;
  onDuplicate: (e: MouseEvent<HTMLDivElement>) => void;
  onRename: (e: MouseEvent<HTMLDivElement>) => void;
}

export const ViewTabMenu: FC<Props> = ({ canDelete, onDelete, onDuplicate, onRename }) => {
  const menuItems: DropdownOption[] = useMemo(
    () => [
      { name: 'Duplicate', onClick: onDuplicate },
      { name: 'Rename', onClick: onRename },
      ...(canDelete ? [{ name: 'Delete', className: deleteClassName, onClick: onDelete }] : []),
    ],
    [canDelete, onDelete, onDuplicate, onRename],
  );

  return (
    <div
      onClick={(e) => {
        // Prevent the document blur handler from firing
        // stopPropagation is dangerous: https://css-tricks.com/dangers-stopping-event-propagation/
        e.preventDefault();
      }}>
      <EmbeddedDropdownMenu menuOptions={menuItems}>
        <Icon className={styles.dropdownIcon} name="caret-down" />
      </EmbeddedDropdownMenu>
    </div>
  );
};

const deleteClassName = sprinkles({ color: 'error' });
