import { FC, useContext } from 'react';

import { ColorPickerButton } from 'components/ColorPickerButton';
import { sprinkles, Button, Input, IconButton, Label } from 'components/ds';
import { StringDisplayOptions } from 'constants/types';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DatasetColumn, DatasetRow } from 'types/datasets';
import { getTableColors } from 'utils/colorCategorySyncUtils';
import { cloneDeep, map } from 'utils/standard';

type Props = {
  updateStringOptions: (newFields: StringDisplayOptions) => void;
  column: DatasetColumn;
  displayOptions: StringDisplayOptions | undefined;
  dataPanelData: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
};

export const CategoryFieldColorAssignment: FC<Props> = ({
  updateStringOptions,
  column,
  displayOptions,
  dataPanelData,
  globalStyleConfig,
}) => {
  const context = useContext(GlobalStylesContext);
  const { categoryColorAssignments, addedCategories } = displayOptions ?? {};

  const columnCategories = new Set(map(dataPanelData, column.name));

  const colors = getTableColors(globalStyleConfig);
  return (
    <>
      <Label className={sprinkles({ marginY: 'sp1' })} htmlFor="">
        Color Palette
      </Label>
      {Array.from(columnCategories).map((category, index) => {
        return (
          <div className={categoryRowClass} key={`color-category-${category}`}>
            <ColorPickerButton
              color={categoryColorAssignments?.[category] || colors[index % 12]}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newCategoryColorAssignments = cloneDeep(categoryColorAssignments || {});
                newCategoryColorAssignments[category] = newColor;

                updateStringOptions({ categoryColorAssignments: newCategoryColorAssignments });
              }}
              size={32}
            />
            <div className={sprinkles({ color: 'black' })}>{String(category)}</div>
          </div>
        );
      })}
      {addedCategories?.map(({ name, color }, index) => {
        return (
          <div className={categoryRowClass} key={`color-category-${name}-${index}`}>
            <ColorPickerButton
              color={color}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].color = newColor;

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
              size={32}
            />
            <Input
              className={sprinkles({ flex: 1 })}
              defaultValue={name}
              onSubmit={(newName) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].name = newName;

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
            />
            <IconButton
              name="cross"
              onClick={() => {
                const newAddedCategories = cloneDeep(addedCategories || []);

                newAddedCategories.splice(index, 1);

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
            />
          </div>
        );
      })}
      <Button
        fillWidth
        className={sprinkles({ marginY: 'sp1' })}
        icon="plus"
        onClick={() => {
          const newAddedCategories = cloneDeep(addedCategories || []);
          newAddedCategories.push({
            name: '',
            color: context.globalStyleConfig.visualizations.categoricalPalette.hue1,
          });
          updateStringOptions({ addedCategories: newAddedCategories });
        }}
        variant="secondary">
        Add a category
      </Button>
    </>
  );
};

const categoryRowClass = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
  marginBottom: 'sp.5',
});
