import { Dispatch } from 'redux';

import { ROUTES } from 'constants/routes';
import { Action } from 'reducers/rootReducer';
import { isExternalFacingDashboard } from 'utils/environmentUtils';

const sessionExpiredMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
  const errorDataStatus = action.payload?.errorData?.status;
  if ([404, 401].includes(errorDataStatus) && !isExternalFacingDashboard()) {
    return (window.location.href = `${ROUTES.LOGIN}#expired`);
  }
  return next(action);
};

export default sessionExpiredMiddleware;
