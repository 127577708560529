import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import { EmbedModal } from 'components/embed';
import * as styles from 'pages/dashboardPage/charts/DrilldownModal.css';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { getIsIframe } from 'reducers/dashboardLayoutReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { PAGE_TYPE, DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { cloneDeep } from 'utils/standard';

import { DashboardDatasetView } from '../DashboardDatasetView';

type Props = {
  dataPanels: DataPanel[];
  datasets: Record<string, ResourceDataset>;
  pageType: PAGE_TYPE;
  variables: DashboardVariableMap;
};

export const DrilldownModal: FC<Props> = ({ dataPanels, datasets, pageType, variables }) => {
  const dispatch = useDispatch();

  const { isOpen, isIframe } = useSelector(
    (state: DashboardStates) => ({
      isOpen: state.dashboardLayout.isDrilldownModalOpen,
      isIframe: getIsIframe(state.dashboardLayout),
    }),
    shallowEqual,
  );

  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  if (!isOpen) return null;

  const drilldownDPT = dataPanels.find((dp) => dp.id === DRILLDOWN_DATA_PANEL_ID);
  if (!drilldownDPT) return null;

  return (
    <EmbedModal
      isOpen
      usePortal
      isIframe={isIframe}
      onClose={() => dispatch(updateDrilldownDataPanel())}
      size="xlarge">
      {/* Plain div instead of EmbedModalContent because we control our own height in styles.modalContent */}
      <div>
        <div className={styles.modalContent}>
          <DashboardDatasetView
            canDownloadDataPanel
            isDrilldownModal
            isViewOnly
            dataPanel={cloneDeep(drilldownDPT)}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            isInContainer={false}
            isSelected={false}
            pageType={pageType}
            variables={variables}
          />
        </div>
      </div>
    </EmbedModal>
  );
};
