import copy from 'copy-to-clipboard';
import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { InvitedUser, inviteTeammates } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { sprinkles, Tooltip, CalloutLink, Button } from 'components/ds';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { InviteTeammatesModal } from 'pages/settingsPage/inviteMembersModal';
import { SettingsTeamMemberList } from 'pages/settingsPage/settingsTeamMemberList';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorContactSupportToast, showSuccessToast } from 'shared/sharedToasts';
import { isMaxTeammatesForPlan } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { isExploSuperuser } from 'utils/superuserUtils';

export const SettingsMembersSection: FC = () => {
  const dispatch = useDispatch();
  const {
    currentUser,
    teamPaymentPlan,
    currentTeamMembers,
    invitedMembers,
    inviteTeammateLoading,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      teamPaymentPlan: state.currentUser.team?.payment_plan,
      currentTeamMembers: state.teamData.data?.team_members,
      invitedMembers: state.teamData.invitedUsers,
      inviteTeammateLoading: createLoadingSelector([ACTION.INVITE_TEAMMATE], false)(state),
    }),
    shallowEqual,
  );
  const { team } = currentUser;

  const [isInviteTeammatesModalOpen, setInviteTeammatesModal] = useState<boolean>(false);

  const isInviteDisabledFromPlan = isMaxTeammatesForPlan(
    currentTeamMembers ?? [],
    invitedMembers,
    teamPaymentPlan,
  );

  const inviteTeamMembers = (invites: InvitedUser[]) => {
    if (!team) return;
    // filter out invites without an email
    const filteredInvites = invites.filter((invite) => invite.email.trim() !== '');
    // convert emails to lowercase
    const lowercaseInvites = filteredInvites.map((invite) => {
      return { ...invite, email: invite.email.toLowerCase().trim() };
    });

    dispatch(
      inviteTeammates(
        {
          postData: {
            invites: lowercaseInvites,
            team_id: team.id,
            inviter: currentUser.first_name,
            resend: false,
          },
        },
        () => {
          showSuccessToast(`Invite${filteredInvites.length > 1 ? 's' : ''} sent successfully`);
          setInviteTeammatesModal(false);
        },
        (response) => showErrorContactSupportToast(response.error_msg),
      ),
    );
  };

  return (
    <div>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ heading: 'h2' })}>
          Members
          {team ? (
            isExploSuperuser(currentUser) ? (
              <Tooltip
                className={sprinkles({ body: 'b3' })}
                side="bottom"
                text="Click to copy Team ID">
                <span
                  className={sprinkles({ cursor: 'pointer' })}
                  onClick={() => copy(String(team.id))}>
                  {' '}
                  ({team.team_name})
                </span>
              </Tooltip>
            ) : (
              ` (${team.team_name})`
            )
          ) : null}
        </div>
        {doesUserHavePermission(
          currentUser.permissions[PERMISSIONED_ENTITIES.TEAM],
          PERMISSIONED_ACTIONS.CREATE,
        ) ? (
          <Button
            disabled={isInviteDisabledFromPlan}
            onClick={() => setInviteTeammatesModal(true)}
            tooltipProps={{
              text: isInviteDisabledFromPlan
                ? 'Upgrade your plan to have more than 3 users in your workspace.'
                : undefined,
            }}
            variant="primary">
            Invite Teammates
          </Button>
        ) : null}
      </div>
      <CalloutLink
        className={sprinkles({ marginTop: 'sp4' })}
        text="See more information about roles here"
        url="https://docs.explo.co/managing-permissions/user-roles"
      />
      <SettingsTeamMemberList />
      <InviteTeammatesModal
        closeModal={() => setInviteTeammatesModal(false)}
        inviteTeammateLoading={inviteTeammateLoading}
        modalOpen={isInviteTeammatesModalOpen}
        onSubmit={(invites) => !isInviteDisabledFromPlan && inviteTeamMembers(invites)}
      />
    </div>
  );
};
