import { FC, useEffect, useState, useRef } from 'react';

import * as styles from 'components/Onboarding/styles.css';
import { sprinkles } from 'components/ds';
import { Testimonial } from 'constants/testimonials';

type Props = {
  testimonial: Testimonial;
};

export const TestimonialLayout: FC<Props> = ({ testimonial }) => {
  const { companyLogo, personName, personTitle, personImage, quote } = testimonial;
  const [borderHeight, setBorderHeight] = useState<number>(0);
  const adjacentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const recalculateHeight = () => {
      if (adjacentDivRef.current) {
        setBorderHeight(adjacentDivRef.current.clientHeight + 20);
      }
    };

    recalculateHeight();

    // Text styles sometimes impact initial height, so need to recheck after styles are applied
    const timeoutId = setTimeout(() => {
      recalculateHeight();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={sprinkles({ flexItems: 'alignCenter' })}>
      <div className={styles.testimonialBorder} style={{ height: borderHeight }} />
      <div className={styles.testimonialRoot} ref={adjacentDivRef}>
        <img alt="testimonial company logo" src={companyLogo} style={{ height: 32 }} />
        <div className={sprinkles({ fontWeight: 500 })}>&ldquo;{quote}&rdquo;</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2' })}>
          <img alt="testimonial person" className={styles.headShot} src={personImage} />
          <span>
            <div className={sprinkles({ heading: 'h4', fontWeight: 600 })}>{personName}</div>
            <div className={sprinkles({ body: 'b2', fontWeight: 400 })}>{personTitle}</div>
          </span>
        </div>
      </div>
    </div>
  );
};
