import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Dashboard,
  deleteDashboard,
  renameDashboard,
  updateDashboardDefaultTimezone,
  updateDashboardDisableFiltersWhileLoading,
  updateDashboardCacheConfig,
  updateDashboardStatePersistance,
} from 'actions/dashboardActions';
import { assignDashboardValue } from 'actions/dashboardAttributesActions';
import {
  deleteReportBuilder,
  renameReportBuilder,
  ReportBuilder,
  updateReportDefaultTimezone,
} from 'actions/reportBuilderActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { MoveResources } from 'components/MoveResources';
import {
  AlertModal,
  APP_PORTAL_ID,
  Menu,
  MenuActionItem,
  MenuLabel,
  MenuSeparator,
  MenuSwitchItem,
  Select,
  MenuItem,
  sprinkles,
} from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { DASHBOARD_CACHE_OPTIONS, DEFAULT_CRON } from 'constants/cachingConstants';
import { Timezones, TIMEZONES } from 'constants/dashboardConstants';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { DELETE_MODAL_CONFIRMATION_TEXT } from 'pages/constants';
import { SetupEmailModal } from 'pages/homeAppPage/setupEmailModal';
import { toggleUseFido } from 'reducers/authReducer';
import { getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { cloneResource } from 'reducers/thunks/versionManagementThunks';
import { showErrorToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { getResourceNameErrorMessage } from 'utils/exploResourceUtils';
import { isEmbeddingDisabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission, doesUserHaveSomePermission } from 'utils/permissionUtils';

import { ResourceEmbedModal } from './ResourceEmbedModal';
import { SyncColorsModal } from './SyncColorsModal';
import * as styles from './styles.css';

type Props = {
  trigger: JSX.Element;
  createResourceDisabled?: boolean;
  dashboardAttributes?: DashboardAttribute[];
  emailCadence?: ExploreEmailCadence;
  pageType: ResourcePageType;
  resource: Dashboard | ReportBuilder;
  resourcePermissionEntity: PERMISSIONED_ENTITIES;
  showDeveloperSettings?: boolean;

  onDelete?: () => void;
  openConfigurabilityModal?: () => void;
  setLoadingStateForResource?: (isLoading: boolean) => void;
};

type ResourceModal = 'embed' | 'rename' | 'delete' | 'duplicate' | 'email' | 'colors' | 'move';

const TIMEZONE_DROPDOWN_MIN_WIDTH = 168;

export const ResourceConfigurationMenu: FC<Props> = ({
  createResourceDisabled,
  resource,
  emailCadence,
  dashboardAttributes,
  showDeveloperSettings,
  pageType,
  resourcePermissionEntity,
  onDelete,
  openConfigurabilityModal,
  setLoadingStateForResource,
  trigger,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const {
    permissions,
    enableCache,
    enable_dashboard_timezones,
    paymentPlan,
    shouldUseFido,
    userEmail,
    uniqueNames,
    allowDashboardStatePersistance,
  } = useSelector(
    (state: ReduxState) => ({
      permissions: state.currentUser.permissions,
      enableCache: state.currentUser.team?.entitlements.enable_cache,
      enable_dashboard_timezones: state.currentUser.team?.feature_flags.enable_dashboard_timezones,
      paymentPlan: state.teamData.data?.payment_plan,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      userEmail: state.currentUser.email,
      uniqueNames: getUniqueNames(state, isExploreProduct),
      allowDashboardStatePersistance:
        !!state.currentUser.team?.feature_flags.allow_dashboard_state_persistance,
    }),
    shallowEqual,
  );

  const [openModal, setOpenModal] = useState<ResourceModal>();
  const selectedTimezoneId = resource.default_timezone || Timezones.UTC;

  const resourcePermissions = permissions[resourcePermissionEntity];

  const userCanConfigureEmails = doesUserHaveSomePermission(resourcePermissions, [
    PERMISSIONED_ACTIONS.UPDATE,
    PERMISSIONED_ACTIONS.CREATE,
    PERMISSIONED_ACTIONS.DELETE,
  ]);

  const renderDeveloperSettings = () => {
    if (
      !showDeveloperSettings ||
      !doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH)
    )
      return null;

    if (!isExploreProduct) {
      if (!enable_dashboard_timezones || !shouldUseFido) return null;
      return (
        <div style={{ minWidth: TIMEZONE_DROPDOWN_MIN_WIDTH }}>
          <MenuSeparator />
          <MenuLabel>Timezone</MenuLabel>
          <MenuItem>
            <Select
              className={sprinkles({ width: 'fill' })}
              onChange={(newTz) =>
                dispatch(
                  updateReportDefaultTimezone(
                    {
                      id: resource.id,
                      postData: { default_timezone: newTz as Timezones },
                    },
                    undefined,
                    () => showErrorToast('Failed to update default timezone'),
                  ),
                )
              }
              placeholder="Select a timezone"
              selectedValue={selectedTimezoneId}
              values={TIMEZONES}
            />
          </MenuItem>
        </div>
      );
    }
    const dashboard = resource as Dashboard;
    return (
      <>
        {enable_dashboard_timezones ? (
          <>
            <MenuSeparator />
            <MenuLabel>Timezone</MenuLabel>
            <MenuItem>
              <Select
                className={sprinkles({ width: 'fill' })}
                onChange={(newTz) =>
                  dispatch(
                    updateDashboardDefaultTimezone(
                      {
                        id: resource.id,
                        postData: { default_timezone: newTz as Timezones },
                      },
                      undefined,
                      () => showErrorToast('Failed to update default timezone'),
                    ),
                  )
                }
                placeholder="Select a timezone"
                selectedValue={selectedTimezoneId}
                values={TIMEZONES}
              />
            </MenuItem>
          </>
        ) : null}
        {enableCache ? (
          <>
            <MenuSeparator />
            <MenuLabel>Caching</MenuLabel>
            <MenuSwitchItem
              switchProps={{
                switchOn: dashboard.is_cache_enabled,
                onChange: () =>
                  dispatch(
                    updateDashboardCacheConfig({
                      id: resource.id,
                      postData: {
                        is_cache_enabled: !dashboard.is_cache_enabled,
                        // if we're turning on the cache, then set the timeout to be the last timeout,
                        // or 60 minutes if unset
                        cache_cron: !dashboard.is_cache_enabled
                          ? dashboard.cache_cron ?? DEFAULT_CRON
                          : undefined,
                      },
                    }),
                  ),
              }}
              text="Enable caching"
            />
          </>
        ) : null}
        {enableCache && dashboard.is_cache_enabled ? (
          <MenuItem>
            Timeout
            <Select
              onChange={(newCacheCron) =>
                dispatch(
                  updateDashboardCacheConfig({
                    id: resource.id,
                    postData: { cache_cron: newCacheCron },
                  }),
                )
              }
              placeholder="Select an option"
              selectedValue={dashboard.cache_cron ?? '0 * * * *'}
              side="top"
              values={DASHBOARD_CACHE_OPTIONS}
            />
          </MenuItem>
        ) : null}
        <MenuSeparator />
        <MenuLabel>Miscellaneous</MenuLabel>
        <MenuSwitchItem
          switchProps={{
            switchOn: dashboard.disable_filters_while_loading,
            onChange: () =>
              dispatch(
                updateDashboardDisableFiltersWhileLoading({
                  id: resource.id,
                  postData: {
                    disable_filters_while_loading: !dashboard.disable_filters_while_loading,
                  },
                }),
              ),
          }}
          text="Disable filters while loading"
        />
        <MenuActionItem
          iconName="palette"
          onSelect={() => setOpenModal('colors')}
          text="Set color categories"
        />
        {allowDashboardStatePersistance ? (
          <MenuSwitchItem
            switchProps={{
              switchOn: dashboard.should_persist_customer_state,
              onChange: () =>
                dispatch(
                  updateDashboardStatePersistance({
                    id: resource.id,
                    postData: {
                      should_persist_customer_state: !dashboard.should_persist_customer_state,
                    },
                  }),
                ),
            }}
            text="Persist customer state"
          />
        ) : null}
      </>
    );
  };

  const renderFidoDevModeSetting = () => {
    if (!userEmail.endsWith('@explo.co')) return null;

    return (
      <>
        <MenuSeparator />
        <MenuLabel>FIDO</MenuLabel>
        <MenuSwitchItem
          switchProps={{
            switchOn: shouldUseFido,
            onChange: () => dispatch(toggleUseFido()),
          }}
          text="Use fido"
        />
      </>
    );
  };

  const renderDashboardAttributes = () => {
    if (
      !isExploreProduct ||
      !dashboardAttributes ||
      dashboardAttributes.length === 0 ||
      !doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH)
    )
      return null;

    const dashboard = resource as Dashboard;
    return (
      <>
        <MenuSeparator />
        <MenuLabel>Attributes</MenuLabel>
        {dashboardAttributes.map((attr) => {
          if (attr.values.length === 0) return null;
          const options = attr.values.map((val) => ({
            value: val.id.toString(),
            label: val.value,
          }));
          const selectedValue = dashboard.dashboard_attributes.find(
            (curr) => curr.attribute_id === attr.id,
          );

          return (
            <MenuItem key={attr.id}>
              <div className={sprinkles({ flex: 1, truncateText: 'ellipsis' })}>{attr.name}</div>
              <Select
                className={sprinkles({ flex: 2 })}
                onChange={(newVal) =>
                  dispatch(
                    assignDashboardValue({
                      postData: {
                        template_id: dashboard.id,
                        attribute_id: attr.id,
                        value_id: newVal,
                      },
                    }),
                  )
                }
                placeholder="No Tag"
                selectedValue={selectedValue?.value_id?.toString()}
                values={options}
              />
            </MenuItem>
          );
        })}
      </>
    );
  };

  const renderAdminOptions = () => {
    return (
      <>
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.UPDATE) ? (
          <MenuActionItem iconName="edit" onSelect={() => setOpenModal('rename')} text="Rename" />
        ) : null}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.CREATE) &&
        !createResourceDisabled ? (
          <MenuActionItem
            iconName="copy"
            onSelect={() => setOpenModal('duplicate')}
            text="Duplicate"
          />
        ) : null}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.UPDATE) ? (
          <MenuActionItem
            iconName="arrow-right"
            onSelect={() => setOpenModal('move')}
            text="Move"
          />
        ) : null}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.VIEW_EMBED_SNIPPET) &&
        !isEmbeddingDisabled(paymentPlan) ? (
          <MenuActionItem
            iconName="square-plus"
            onSelect={() => {
              window.location.hash = '#embed-clicked';
              setOpenModal('embed');
            }}
            text="Embed"
          />
        ) : null}
        {openConfigurabilityModal &&
        doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH) ? (
          <MenuActionItem
            iconName="gear"
            onSelect={() => openConfigurabilityModal()}
            text="Manage Configurability"
          />
        ) : null}
        {isExploreProduct && userCanConfigureEmails ? (
          <MenuActionItem
            iconName="envelope"
            onSelect={() => setOpenModal('email')}
            text="Configure emails"
          />
        ) : null}
      </>
    );
  };

  const onDeleteForAllUsers = () => {
    setLoadingStateForResource?.(true);
    setOpenModal(undefined);

    if (isExploreProduct) {
      dispatch(
        deleteDashboard({ id: resource.id }, () => {
          setLoadingStateForResource?.(false);
          trackEvent(EVENTS.DELETED_DASHBOARD, {
            dashboard_template_id: resource.id,
          });
          onDelete?.();
        }),
      );
    } else {
      dispatch(
        deleteReportBuilder({ id: resource.id }, () => {
          setLoadingStateForResource?.(false);
          onDelete?.();
        }),
      );
    }
  };

  const onDuplicate = () => {
    setLoadingStateForResource?.(true);
    setOpenModal(undefined);

    dispatch(
      cloneResource(isExploreProduct, { id: resource.id }, (data) => {
        if (isExploreProduct) {
          trackEvent(EVENTS.CREATED_DASHBOARD, {
            dashboard_template_id: data.new_resource.id,
            dashboard_name: `${resource} Copy`,
          });
        }

        setLoadingStateForResource?.(false);
        history.push(
          `/${isExploreProduct ? 'dashboard' : 'report-builder'}/${data.new_resource.id}${
            isExploreProduct ? '' : '/datasets'
          }`,
        );
      }),
    );
  };

  return (
    <>
      <Menu
        align="end"
        className={styles.resourceConfigMenu}
        portalContainerId={APP_PORTAL_ID}
        trigger={trigger}>
        {renderAdminOptions()}
        {renderDashboardAttributes()}
        {renderDeveloperSettings()}
        {renderFidoDevModeSetting()}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.DELETE) ? (
          <>
            <MenuSeparator />
            <MenuActionItem
              isDestructive
              iconName="trash"
              onSelect={() => setOpenModal('delete')}
              text="Delete"
            />
          </>
        ) : null}
      </Menu>
      {openModal === 'embed' ? (
        <ResourceEmbedModal
          modalOpen
          embedId={resource.embed_id}
          embedName={resource.name}
          onClose={() => setOpenModal(undefined)}
          pageType={pageType}
        />
      ) : null}
      {openModal === 'move' ? (
        <MoveResources
          modalOpen
          closeModal={() => setOpenModal(undefined)}
          isExploreProduct={isExploreProduct}
          portalContainerId={APP_PORTAL_ID}
          resource={resource}
        />
      ) : undefined}
      <TextFieldModal
        buttonName="Save"
        closeModal={() => setOpenModal(undefined)}
        getErrorMessage={(input) => getResourceNameErrorMessage(input, uniqueNames, resource.name)}
        modalOpen={openModal === 'rename'}
        modalTitle={`Rename ${isExploreProduct ? 'Dashboard' : 'Report Builder'}`}
        onSubmit={(name) => {
          setLoadingStateForResource?.(true);
          setOpenModal(undefined);

          if (isExploreProduct) {
            dispatch(
              renameDashboard({ id: resource.id, postData: { name } }, () => {
                setLoadingStateForResource?.(false);
                trackEvent(EVENTS.RENAMED_DASHBOARD_TEMPLATE_NAME, {
                  dashboard_template_id: resource.id,
                  dashboard_template_name: name,
                });
              }),
            );
          } else {
            dispatch(
              renameReportBuilder({ id: resource.id, postData: { name } }, () => {
                setLoadingStateForResource?.(false);
              }),
            );
          }
        }}
        portalContainerId={APP_PORTAL_ID}
        resourceName={resource.name}
        textFieldPlaceholder="Dashboard name"
      />
      <AlertModal
        actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteForAllUsers }}
        confirmationText={DELETE_MODAL_CONFIRMATION_TEXT}
        isOpen={openModal === 'delete'}
        onClose={() => setOpenModal(undefined)}
        portalContainerId={APP_PORTAL_ID}
        title={`Are you sure you want to delete '${resource.name}'? Type ${DELETE_MODAL_CONFIRMATION_TEXT} to confirm.`}
      />
      <AlertModal
        actionButtonProps={{ text: 'Duplicate', onClick: onDuplicate, variant: 'primary' }}
        isOpen={openModal === 'duplicate'}
        onClose={() => setOpenModal(undefined)}
        portalContainerId={APP_PORTAL_ID}
        title={`Do you want to duplicate: ${resource.name}?`}
      />
      {openModal === 'email' ? (
        <SetupEmailModal
          closeModal={() => setOpenModal(undefined)}
          dashboardId={resource.id}
          emailCadence={emailCadence}
          modalTitle={`Email Configuration for ${resource.name}`}
        />
      ) : null}
      {openModal === 'colors' ? <SyncColorsModal onClose={() => setOpenModal(undefined)} /> : null}
    </>
  );
};
