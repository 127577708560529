import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { createDashboardDataPanel } from 'actions/dashboardV2Actions';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { setAddDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets, getParentSchemaSourceTypes } from 'reducers/selectors';
import { createDashboardItemId } from 'utils/dashboardUtils';
import { titleCase } from 'utils/graphUtils';

import { DataPanelModal } from './dataPanelModal';

type Props = {
  dashboardId: number;
};

export const AddDataPanelModal: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const { addDataPanelState, datasets } = useSelector(
    (state: ReduxState) => ({
      addDataPanelState: state.dashboardInteractions.addDataPanelState,
      datasets: getEditableDatasets(state),
      parentSchemaSourceTypes: getParentSchemaSourceTypes(state),
    }),
    shallowEqual,
  );

  if (!addDataPanelState) return null;

  return (
    <DataPanelModal
      headerText="Select a table."
      onClose={() => dispatch(setAddDataPanelState(null))}
      onConfirm={(selectedDatasetId) => {
        const dataset = datasets?.[selectedDatasetId ?? ''];
        if (!dataset) return;

        const { opType, layout, containerId } = addDataPanelState;

        const id = createDashboardItemId(dashboardId);
        // we want the title to be undefined for KPIs so that we can make the title
        // dynamically default to the aggregation selected by the user
        const name = KPI_VIZ_OPS.has(opType) ? undefined : titleCase(dataset.table_name);

        dispatch(
          createDashboardDataPanel({
            id,
            name,
            newLayout: layout,
            datasetId: dataset.id,
            vizType: opType,
            containerId,
          }),
        );
        trackEvent(EVENTS.ADDED_DATA_PANEL, {
          dashboard_template_id: dashboardId,
          selected_dataset_id: dataset.id,
        });
      }}
      opType={addDataPanelState.opType}
      primaryButtonText="Create"
      title="Add a new data panel"
    />
  );
};
